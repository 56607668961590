import HttpClient from '@/api/utils/HttpClient'
import RenewToken from '@/features/auth/domain/renewToken.service'
import { apiMkplUrl } from '@/utils/url'

const httpClientMkplAPI = new HttpClient({
    baseURL: `${apiMkplUrl}/api/v3/`
})

RenewToken.addClient(httpClientMkplAPI)

export default httpClientMkplAPI
