import repository from '../api/calendar.settings.api'

class CalendarSettingsDoctorService {
    addSetting(key: string, value: number | string | boolean) {
        return repository.addSetting(key, value)
    }

    getSetting(key: string): Promise<any> {
        return repository.getSetting(key)
    }
}

export default new CalendarSettingsDoctorService()
