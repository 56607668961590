import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { FORM_BLUEPRINTS } from '../domain/types/constants'

const FormBlueprints = () => import(/* webpackChunkName: "form-blueprints" */ '@/features/formBlueprints/ui/index.vue')

export const formBlueprintsRoute = {
    path: FORM_BLUEPRINTS,
    name: FORM_BLUEPRINTS,
    component: FormBlueprints,
    meta: {
        middleware: uninav,
        uninavName: FORM_BLUEPRINTS,
        title: FORM_BLUEPRINTS
    }
}
