import { getUrlFromTemplate } from '@dp-vue/utils'
import { Dayjs } from 'dayjs'

import httpClientPatientRequestAPI from '@/api/utils/httpClientPatientRequestAPI'
import { BookingRequestDTO } from '@/features/calendar/api/interfaces/bookingRequests.interfaces'

const API_VERSION = 'v1'

const URL_BASE_BOOKING_REQUESTS = `${API_VERSION}/BookingRequest/:docplannerDoctorId`
const URL_GET_BOOKING_REQUEST = `${URL_BASE_BOOKING_REQUESTS}/:requestId`
const URL_CONFIRM_BOOKING_REQUEST = `${URL_BASE_BOOKING_REQUESTS}/:requestId/confirm`
const URL_REJECT_BOOKING_REQUEST = `${URL_BASE_BOOKING_REQUESTS}/:requestId/decline`

class CalendarBookingRequestsRepository {
    async getBookingRequests(
        start: string | Date | Dayjs,
        end: string | Date | Dayjs,
        docplannerDoctorId: string
    ): Promise<BookingRequestDTO[]> {
        const url = getUrlFromTemplate({
            template: URL_BASE_BOOKING_REQUESTS,
            params: {
                docplannerDoctorId
            },
            query: {
                start: start as string,
                end: end as string
            }
        })
        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async getBookingRequestById(requestId: string, docplannerDoctorId: string): Promise<BookingRequestDTO> {
        const url = getUrlFromTemplate({
            template: URL_GET_BOOKING_REQUEST,
            params: {
                requestId,
                docplannerDoctorId
            }
        })
        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async acceptBookingRequest(requestId: string, docplannerDoctorId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CONFIRM_BOOKING_REQUEST,
            params: {
                requestId,
                docplannerDoctorId
            }
        })
        const { data } = await httpClientPatientRequestAPI.put(url)
        return data
    }

    async rejectBookingRequest(requestId: string, docplannerDoctorId: string, content?: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REJECT_BOOKING_REQUEST,
            params: {
                requestId,
                docplannerDoctorId
            }
        })
        const { data } = await httpClientPatientRequestAPI.put(url, { content })
        return data
    }
}

export default new CalendarBookingRequestsRepository()
