import httpAPI from '@/api/utils/httpClientAPI'
import httpClientAPIAnonymous from '@/api/utils/httpClientAPIAnonymous'
import { toString } from '@/utils/string'

import { prepareRemoteToggles } from './utils/prepareRemoteToggles'
import { ToggleStore } from './utils/ToggleStore'

const toggleInstance = new ToggleStore()

const ENDPOINT_URL = '/unleash/features'

export const fetchUnleashAnonymously = togglesToLoad =>
    httpClientAPIAnonymous.post(ENDPOINT_URL, {
        names: togglesToLoad
    })

export const fetchUnleash = togglesToLoad =>
    httpAPI.post(ENDPOINT_URL, {
        names: togglesToLoad
    })

const setToggles = (togglesToLoad, data) => {
    let remoteValues = data

    if (Object.keys(togglesToLoad).length === 1 && typeof data === 'boolean') {
        remoteValues = {}
        remoteValues[togglesToLoad[0]] = data
    }

    toggleInstance.set(remoteValues)
}

export const initialiseUnleashAnonymously = async ({ toggles }) => {
    const { togglesToLoad, defaultToggles } = prepareRemoteToggles(toggles)
    toggleInstance.set(defaultToggles)

    try {
        const { data } = await fetchUnleashAnonymously(togglesToLoad)
        setToggles(togglesToLoad, data)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }
}

export const initialiseUnleash = async ({ toggles }) => {
    const { togglesToLoad, defaultToggles } = prepareRemoteToggles(toggles)
    toggleInstance.set(defaultToggles)

    try {
        const { data } = await fetchUnleash(togglesToLoad)
        setToggles(togglesToLoad, data)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }
}

export const isRemoteToggleEnabled = (key, value) => {
    const toggleValue = toggleInstance.get(key)

    return toString(toggleValue) === toString(value)
}
