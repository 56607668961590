import { Quote, QuotesFilters } from '@/features/quotes/domain/quotes.interfaces'

import { QuotesState } from './state'

export enum MutationTypes {
    SetCurrentPage = 'setCurrentPage',
    SetFilters = 'setFilters',
    SetLoading = 'setLoading',
    SetQuotes = 'setQuotes',
    SetTotalPages = 'setTotalPages'
}

export type QuotesMutations = {
    [MutationTypes.SetLoading](state: QuotesState, isLoading: boolean): void
    [MutationTypes.SetQuotes](state: QuotesState, quotes: Quote[]): void
    [MutationTypes.SetCurrentPage](state: QuotesState, currentPage: number): void
    [MutationTypes.SetFilters](state: QuotesState, filters: QuotesFilters): void
    [MutationTypes.SetTotalPages](state: QuotesState, totalPages: number): void
}
