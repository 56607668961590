import { DateFormat, dateIsBetweenDateRange, DateUnit, parseDate } from '@/core/ui/utils'
import { DateRange } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import { Resource } from '@/features/calendar/domain/interfaces/resource.interfaces'
import { Device } from '@/features/devices/domain/interfaces/devices.interface'
import { Doctor } from '@/features/doctor/domain/interfaces/doctor.interfaces'
import { FacilityRoomEntity } from '@/features/rooms/domain/interfaces/rooms.interface'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'
import { Service } from '@/features/servicesLegacy/domain/interfaces/services.interfaces'
import { Staff } from '@/features/staff/domain/interfaces/staff.interface'
import { orderByAttributes } from '@/utils/array'
import { normalizeString } from '@/utils/string'

type Filter<T> = {
    name: string
    nameNormalized?: string
} & T
export default class CalendarFilter<T> {
    orderListBySelectedAndNameNormalized = (list: Filter<T>[]): T[] => {
        const servicesWithNameNormalized = list
            .map(({ name, ...rest }) => ({ ...rest, name: name.trim() }))
            .map(service => ({
                ...service,
                nameNormalized: normalizeString(service.name.toLowerCase())
            }))
        const listSorted = orderByAttributes(servicesWithNameNormalized, ['selected', 'nameNormalized'], ['desc'])
        return listSorted.map(({ nameNormalized, ...rest }) => rest)
    }

    orderListBySelectedAndName(list: T[], atributes: string[] = ['selected', 'name']): T[] {
        return orderByAttributes(list, atributes, ['desc'])
    }

    matchSelectedPropertyBetweenLists(
        list: (Doctor | FacilityRoomEntity | Device | Service | Staff | Schedule)[],
        storeData: (Doctor | FacilityRoomEntity | Device | Service | Staff | Schedule)[]
    ): (Doctor | FacilityRoomEntity | Device | Service | Staff | Schedule)[] {
        return list.map(item => {
            return {
                ...item,
                selected: storeData.some(({ id, selected }) => item.id === id && selected)
            }
        })
    }

    getAvailableSchedulesWorkingCurrentPeriod(
        resources: Resource[],
        dateRange: DateRange,
        selectedFacility: number | undefined
    ): number[] {
        return resources.reduce((ids: number[], { id, availableDates, facilityId }) => {
            const isAvailable =
                availableDates.some(({ date }) => {
                    const parsedDate = parseDate(date, DateFormat.DateDashedFormat)

                    if (!parsedDate) {
                        return false
                    }

                    return dateIsBetweenDateRange(parsedDate, dateRange, DateUnit.Day, '[]')
                }) && (selectedFacility ? selectedFacility === facilityId : true)

            if (isAvailable) {
                ids.push(id)
            }

            return ids
        }, [])
    }
}
