import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { PatientConversation, SessionToken } from '@/features/chat/domain/interfaces/conversations.interfaces'

import {
    ExternalIdDTO,
    PatientLinkDTO,
    PatientVideoConsultationDTO,
    VideoConsultationData
} from '../domain/interfaces/videoConsultation.interfaces'

class VideoConsultationRepository {
    private URL_BASE_V1 = 'videoconsultation'

    private URL_BASE_V2 = 'videoconsultation/v2'

    private URL_DOCTOR_V2 = `${this.URL_BASE_V2}/doctor`

    private URL_DOCTOR_V2_START_VIDEO_CONSULTATION = `${this.URL_DOCTOR_V2}/event/:eventId/start`

    private URL_PATIENT_V2 = `${this.URL_BASE_V2}/patient`

    private URL_PATIENT_V2_START_VIDEO_CONSULTATION = `${this.URL_PATIENT_V2}/start`

    private URL_EXTERNAL = `${this.URL_BASE_V1}/external`

    private URL_GET_ID_FROM_EXTERNAL_ID = `${this.URL_EXTERNAL}/:bookingId/eventid`

    private URL_PATIENT_V1 = `${this.URL_BASE_V1}/patient`

    private URL_PATIENT_DATA_PRIVACY = `${this.URL_PATIENT_V1}/dataPrivacy`

    private URL_PATIENT_LINK = `${this.URL_PATIENT_V1}/event/:eventId/link`

    private URL_PATIENT_CHAT = `${this.URL_PATIENT_V1}/chat`

    private URL_PATIENTS_CHAT_CONVERSATION = `${this.URL_PATIENT_CHAT}/conversation`

    private URL_PATIENTS_CHAT_TOKEN = `${this.URL_PATIENT_CHAT}/session-token`

    async startDoctorVideoConsultation(eventId: number): Promise<VideoConsultationData> {
        const url = getUrlFromTemplate({
            template: this.URL_DOCTOR_V2_START_VIDEO_CONSULTATION,
            params: {
                eventId
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async startPatientVideoConsultation(token: string): Promise<VideoConsultationData> {
        const { data } = await httpAPI.get(this.URL_PATIENT_V2_START_VIDEO_CONSULTATION, {
            params: {
                token
            }
        })
        return data
    }

    async getByExternalId(externalId: number): Promise<ExternalIdDTO> {
        const url = getUrlFromTemplate({
            template: this.URL_GET_ID_FROM_EXTERNAL_ID,
            params: {
                bookingId: externalId
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async patientDataPrivacy(token: string): Promise<PatientVideoConsultationDTO> {
        const { data } = await httpAPI.put(this.URL_PATIENT_DATA_PRIVACY, {
            token
        })
        return data
    }

    async getPatientLink(eventId: number): Promise<PatientLinkDTO> {
        const url = getUrlFromTemplate({
            template: this.URL_PATIENT_LINK,
            params: { eventId }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getConversationByPatientToken(token: string): Promise<PatientConversation> {
        const url = getUrlFromTemplate({
            template: this.URL_PATIENTS_CHAT_CONVERSATION,
            query: {
                token
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getPatientSessionToken(token: string): Promise<SessionToken> {
        const url = getUrlFromTemplate({
            template: this.URL_PATIENTS_CHAT_TOKEN,
            query: {
                token
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new VideoConsultationRepository()
