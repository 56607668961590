const modules = [
    {
        name: 'plugins',
        inject: false,
        package: () => import(/* webpackChunkName: "bootstrap-plugins" */ '@/plugins')
    },
    {
        name: 'directives',
        inject: false,
        package: () => import(/* webpackChunkName: "bootstrap-directives" */ '@/core/ui/directives/registerDirectives')
    },
    {
        name: 'router',
        inject: true,
        package: () => import(/* webpackChunkName: "bootstrap-router" */ '@/router')
    },
    {
        name: 'store',
        inject: true,
        package: () => import(/* webpackChunkName: "bootstrap-store" */ '@/store')
    },
    {
        name: 'i18n',
        inject: true,
        package: () => import(/* webpackChunkName: "bootstrap-i18n" */ '@/plugins/i18n')
    }
]

export default modules
