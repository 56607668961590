import { repository } from '../../api'
import { mapTaskFromDTO } from '../mappers'

export const getTaskList = async () => {
    const { data, error, message } = await repository.getTaskList()

    if (error) {
        return { data: null, error, message }
    }

    return { data: data.map(mapTaskFromDTO), error, message }
}

export const setTaskCompleted = async (taskId: string) => {
    const { data, error, message } = await repository.setTaskCompleted(taskId)

    if (error) {
        return { data: null, error, message }
    }

    return { data, error, message }
}
