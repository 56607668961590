import { date as dayjs, DateFormat, DateUnit, endOf, startOf } from '@/core/ui/utils'

import { DateRange } from '../interfaces/calendar.interfaces'

export const getWeeksRange = (date: Date, weeksOffset: number, startDayOffset = 0, endDayOffset = 0): DateRange => {
    // TO CHECK: comment from angular 👉🏻 take 1 day before to retrieve also sundays (for calendars starting on sundays)

    const offset = Math.max(weeksOffset, 0)

    return {
        from: dayjs(date)
            .startOf(DateUnit.Week)
            .add(startDayOffset, DateUnit.Day)
            .subtract(offset, DateUnit.Week)
            .format(DateFormat.DateDashedFormat),
        to: dayjs(date)
            .startOf(DateUnit.Week)
            .add(offset + 1, DateUnit.Week)
            .subtract(endDayOffset, DateUnit.Day)
            .format(DateFormat.DateDashedFormat)
    }
}

export const isDateInCalendarEventsDateRange = (date: Date, { from = '', to = '' }: DateRange): boolean => {
    if (from === '' || to === '') {
        return false
    }

    const fromDate = dayjs(from, DateFormat.DateDashedFormat)
    const toDate = dayjs(to, DateFormat.DateDashedFormat)

    return dayjs(date).isBetween(fromDate, toDate, 'day', '[]')
}

export const getDateRangeByUnitType = (date: Date, unit: DateUnit): DateRange => {
    return {
        from: startOf(date, unit).toDate(),
        to: endOf(date, unit).toDate()
    }
}

export const convertTimeToMinutes = (time: string): number => {
    const [hour, minutes] = time.split(':')
    return +hour * 60 + +minutes
}
