import { ChatTemplate } from '@/features/settings/domain/interfaces/messages.interfaces'

import { QuickReminder } from '../../../domain/interfaces/chatQuickReminders.interfaces'
import { SessionToken, UnreadConversation } from '../../../domain/interfaces/conversations.interfaces'

export enum GetterTypes {
    GetChatSessionToken = 'GET_CHAT_SESSION_TOKEN',
    GetChatTemplates = 'GET_CHAT_TEMPLATES',
    GetChatTotalUnreadConversationsCount = 'GET_CHAT_TOTAL_UNREAD_CONVERSATIONS_COUNT',
    GetIsContactFromProfileAllowed = 'GET_IS_CONTACT_FROM_PROFILE_ALLOWED',
    GetIsGlobalMessageBlockActive = 'GET_IS_GLOBAL_BLOCK_ACTIVE',
    GetPatientHasUnread = 'GET_PATIENT_HAS_UNREAD',
    GetQuickReminders = 'GET_QUICK_REMINDERS',
    GetSaasInternalChatDisclaimer = 'GET_SAAS_INTERNAL_CHAT_DISCLAIMER',
    GetUnreadPatientConversations = 'GET_UNREAD_PATIENT_CONVERSATIONS',
    GetUnreadSaasInternalConversations = 'GET_UNREAD_SAAS_INTERNAL_CONVERSATIONS'
}
export interface ChatGetters {
    [GetterTypes.GetChatSessionToken]: SessionToken['sessionToken'] | null
    [GetterTypes.GetChatTotalUnreadConversationsCount]: number
    [GetterTypes.GetPatientHasUnread]: number
    [GetterTypes.GetQuickReminders]: QuickReminder[]
    [GetterTypes.GetUnreadPatientConversations]: number
    [GetterTypes.GetUnreadSaasInternalConversations]: UnreadConversation[]
    [GetterTypes.GetSaasInternalChatDisclaimer]: boolean
    [GetterTypes.GetIsContactFromProfileAllowed]: boolean | null
    [GetterTypes.GetChatTemplates]: ChatTemplate[] | null
    [GetterTypes.GetIsGlobalMessageBlockActive]: boolean
}
