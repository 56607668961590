export const ROUTE_PAYMENTS_BASE_NAME = 'payments'
export const ROUTE_PAYMENTS_SETTINGS_NAME = 'payments-settings'
export const ROUTE_PAYMENTS_SCHEDULE_NAME = 'payments-schedule'
export const ROUTE_PAYMENTS_OVERVIEW_NAME = 'payments-overview'
export const ROUTE_PAYMENTS_REQUESTS_NAME = 'payments-requests'
export const ROUTE_PAYMENTS_ACTIVATION_NAME = 'payments-activation'

export const ROUTE_PAYMENTS_BASE_PATH = 'payments'
export const ROUTE_PAYMENTS_SETTINGS_PATH = 'settings'
export const ROUTE_PAYMENTS_SCHEDULES_PATH = 'schedules'
export const ROUTE_PAYMENTS_OVERVIEW_PATH = 'overview'
export const ROUTE_PAYMENTS_REQUESTS_PATH = 'requests'
export const ROUTE_PAYMENTS_ACTIVATION_PATH = 'activation'

export const ROUTE_PAYMENTS_SETTINGS_SCHEDULES_PATH = `${ROUTE_PAYMENTS_SETTINGS_PATH}/${ROUTE_PAYMENTS_SCHEDULES_PATH}`
