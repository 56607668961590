const SettingsWizard = () =>
    import(/* webpackChunkName: "settings-wizard" */ '@/components/Settings/General/SettingsWizard/SettingsWizard.vue')

export default [
    {
        path: '/settings-wizard/:id',
        name: 'settings-wizard',
        component: SettingsWizard
    }
]
