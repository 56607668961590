import Facility from '@/api/Facility'
import Schedules from '@/api/Schedules'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import DoctorInvitationService from '@/features/doctorInvitation/domain/doctorInvitation.service'
import PluginsService from '@/features/dpPlugins/domain/plugins.service'
import UsersService from '@/features/settings/domain/users.service'
import { PERMISSIONS_LEVEL } from '@/features/settings/ui/constants/users.constants'

import {
    ACTION_USERS_FETCH_USERS,
    ACTION_USERS_LOAD_AVAILABLE_PLUGINS,
    ACTION_USERS_LOAD_FACILITY_NOTIFICATIONS,
    ACTION_USERS_LOAD_SCHEDULES
} from './types/action'
import {
    MUTATION_USERS_UPDATE_AVAILABLE_PLUGINS,
    MUTATION_USERS_UPDATE_FACILITY_NOTIFICATIONS,
    MUTATION_USERS_UPDATE_SCHEDULES,
    MUTATION_USERS_UPDATE_USERS,
    MUTATION_USERS_UPDATE_USERS_LOADING
} from './types/mutation'

const facilityApi = Facility()
const schedulesApi = Schedules()

const actions = {
    async [ACTION_USERS_FETCH_USERS]({ state, commit, rootGetters }) {
        if (state.users.length === 0) {
            commit(MUTATION_USERS_UPDATE_USERS_LOADING, true)
        }

        const userGroups = rootGetters[AuthGetterTypes.IsDoctor]
            ? await UsersService.getRelatedUsers()
            : await Promise.all([
                  UsersService.getMedicalCenterRelatedUsers(),
                  DoctorInvitationService.getSpecialistList()
              ])

        const users = userGroups.flat()

        commit(MUTATION_USERS_UPDATE_USERS, users)
        commit(MUTATION_USERS_UPDATE_USERS_LOADING, false)

        return users
    },
    async [ACTION_USERS_LOAD_FACILITY_NOTIFICATIONS]({ commit }) {
        const { data } = await facilityApi.getFacilities()

        const facilities = data.map(({ id, name }) => ({
            facilityId: id,
            facilityName: name,
            notificationsEnabled: true
        }))

        commit(MUTATION_USERS_UPDATE_FACILITY_NOTIFICATIONS, facilities)
    },

    async [ACTION_USERS_LOAD_AVAILABLE_PLUGINS]({ commit }) {
        const plugins = await PluginsService.availablePlugins()

        commit(MUTATION_USERS_UPDATE_AVAILABLE_PLUGINS, plugins)
    },

    async [ACTION_USERS_LOAD_SCHEDULES]({ commit }) {
        const { data } = await schedulesApi.getAll()

        const schedules = data
            .filter(({ permissionLevel }) => permissionLevel === PERMISSIONS_LEVEL.OWNER)
            .map(schedule => ({
                address: schedule.address,
                scheduleId: schedule.id,
                scheduleName: schedule.displayName,
                scheduleColor: schedule.guiColor,
                scheduleEnabled: true,
                medicalCenterId: schedule.medicalCenterId,
                permissionLevel: PERMISSIONS_LEVEL.WRITE
            }))

        commit(MUTATION_USERS_UPDATE_SCHEDULES, schedules)
    }
}

export default actions
