import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { CalendarReminder } from '@/features/calendar/domain/interfaces/reminder.interfaces'

const URL_BASE = 'reminders'

const URL_REMINDER_ID = `${URL_BASE}/:id`
const URL_MARK_AS_READ = `${URL_REMINDER_ID}/done`
const URL_MARK_AS_NOT_READ = `${URL_REMINDER_ID}/undone`

class CalendarRemindersRepository {
    async getReminder(id: number): Promise<CalendarReminder> {
        const url = getUrlFromTemplate({
            template: URL_REMINDER_ID,
            params: { id }
        })
        const { data: dataResult } = await httpAPI.get(url, {
            validateStatus(status) {
                return status === 200 || status === 404
            },
            transformResponse: [
                function (data) {
                    const currentData = JSON.parse(data)
                    if (currentData.exception) {
                        return null
                    }
                    return currentData
                }
            ]
        })

        return dataResult
    }

    async createReminder(reminder: CalendarReminder): Promise<CalendarReminder> {
        const { data } = await httpAPI.post(URL_BASE, reminder)

        return data
    }

    async updateReminder(id: number, reminder: CalendarReminder): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REMINDER_ID,
            params: { id }
        })
        await httpAPI.put(url, reminder)
    }

    async deleteReminder(id: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REMINDER_ID,
            params: { id }
        })
        await httpAPI.delete(url)
    }

    async toggleReminderAsRead(id: number, done: boolean): Promise<void> {
        const path = done ? URL_MARK_AS_READ : URL_MARK_AS_NOT_READ
        const url = getUrlFromTemplate({ template: path, params: { id } })
        await httpAPI.post(url)
    }
}

export default new CalendarRemindersRepository()
