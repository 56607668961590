import httpAPI from '@/api/utils/httpClientAPI'

const URL_BASE = 'patients/validate/fiscal-code'

class FiscalCodeRepository {
    validateFiscalCode(fiscalCode: string): Promise<void> {
        return httpAPI.post(URL_BASE, { fiscalCode })
    }
}

export default new FiscalCodeRepository()
