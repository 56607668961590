import { cashRegisterReport, comissionsReport, expensesReport, report } from '@docplanner/one-tracking'

import { HintVisitStatus } from '@/features/hints/domain/enums/hints.enums'
import { STATUS_ORDER } from '@/features/hints/ui/constants/hints.constants'
import AggregateService from '@/features/reports/domain/aggregate.service'
import ExpensesService from '@/features/reports/domain/expenses.service'
import { PaymentStatus, PaymentStatusOption } from '@/features/reports/domain/interfaces/expenses.interfaces'

import {
    ReportColumnProperty,
    ReportPathname,
    ReportQueryParam,
    ReportStatisticName,
    ReportType
} from '../../domain/enums/reports.enums'
import { ReportsTableColumnStylesByProperty, ReportTypeByPathName } from '../../domain/interfaces/reports.interfaces'

export const TAG_STATUS = 'status'
export const TAG_PAYMENT_METHODS = 'paymentmethod'

export const FILTER_TYPE_STATUS = TAG_STATUS
export const FILTER_PAYMENT_METHODS = TAG_PAYMENT_METHODS
export const FILTER_PAYMENT_STATUS_OPTIONS: PaymentStatusOption[] = [
    {
        text: 'expense-control-report-modal-new-expense-selector-payment-status-unpaid',
        value: PaymentStatus.Unpaid
    },
    {
        text: 'expense-control-report-modal-new-expense-selector-payment-status-paid',
        value: PaymentStatus.Paid
    }
]

export const CHECKED_STATE = 'check'
export const UNCHECKED_STATE = 'uncheck'

export const UNCHECKED_STATUS_BY_DEFAULT = [
    HintVisitStatus.CanceledByUser,
    HintVisitStatus.CanceledByPatient,
    HintVisitStatus.NoShow
]
export const CHECKED_STATUS_BY_DEFAULT = STATUS_ORDER.filter(id => !UNCHECKED_STATUS_BY_DEFAULT.includes(id))

export const TRACKING_REGISTRY_BY_REPORT = {
    [ReportPathname.Visits]: report,
    [ReportPathname.Cash]: cashRegisterReport,
    [ReportPathname.Commissions]: comissionsReport,
    [ReportPathname.Expenses]: expensesReport
}

export const TRACKING_CATEGORIES_BY_REPORT = {
    [ReportPathname.Visits]: 'REPORT_OF_VISITS',
    [ReportPathname.Cash]: 'CASH_REGISTER_REPORT',
    [ReportPathname.Commissions]: 'COMISSIONS_REPORT',
    [ReportPathname.Expenses]: 'EXPENSES_REPORT'
}

export const REPORT_TYPE_BY_PATH_NAME: ReportTypeByPathName = {
    [ReportPathname.Visits]: ReportType.Reports,
    [ReportPathname.Cash]: ReportType.Payments,
    [ReportPathname.Commissions]: ReportType.Commissions,
    [ReportPathname.Expenses]: ReportType.Expenses
}

export const REPORT_QUERY_PARAM_KEYS = Object.values(ReportQueryParam)

export const TABLE_COLUMN_STYLES_BY_PROPERTY: ReportsTableColumnStylesByProperty = {
    bold: ['patient'],
    textCapitalize: ['patient'],
    ellipsis: []
}

export const LAST_COLUMN_CLASS = 'last-column'
export const REPORT_DASHBOARD_VIEW_COUNT = 'REPORT_DASHBOARD_VIEW_COUNT'

export const REPORTS_EXPENSES_EDITION_MODAL = 'REPORTS_EXPENSES_EDITION_MODAL'

export const REPORTS_CATEGORY_TYPE_KEY = 'expense-control-report-category-type'

export const REPORT_STATISTICS_ITEM_DEFAULT_PAID = {
    name: ReportStatisticName.Paid,
    icon: {
        name: 'DpIconCheckFilled',
        props: {
            color: 'secondary',
            size: 24
        },
        wrapperClasses: 'bg-secondary-light'
    }
}

export const FIRST_COLUMN = 0

export const REPORT_STATISTICS_CONFIG = {
    [ReportPathname.Cash]: {
        fetchStatistics: AggregateService.getPayments,
        sort: [ReportStatisticName.Paid, ReportStatisticName.Unpaid, ReportStatisticName.PaymentMethods],
        defaultItems: [
            REPORT_STATISTICS_ITEM_DEFAULT_PAID,
            {
                name: ReportStatisticName.Unpaid,
                icon: {
                    name: 'DpIconInProgress',
                    props: {
                        color: 'gray-500',
                        size: 24
                    },
                    wrapperClasses: 'bg-gray-200'
                }
            }
        ]
    },
    [ReportPathname.Expenses]: {
        fetchStatistics: ExpensesService.getExpensesAggregates,
        sort: [ReportStatisticName.Total, ReportStatisticName.Paid, ReportStatisticName.Unpaid],
        defaultItems: [
            REPORT_STATISTICS_ITEM_DEFAULT_PAID,
            {
                name: ReportStatisticName.Unpaid,
                icon: {
                    name: 'DpIconNegativeFilled',
                    props: {
                        color: 'danger',
                        size: 24
                    },
                    wrapperClasses: 'bg-danger-light'
                }
            },
            {
                name: ReportStatisticName.Total,
                icon: {
                    name: 'DpIconBankNote',
                    props: {
                        color: 'gray-500',
                        size: 24
                    },
                    wrapperClasses: 'bg-gray-200'
                }
            }
        ]
    }
}
