export const parseParams = (params: Record<string, any>): Record<string, string> => {
    return Object.entries(params).reduce((parameters, [key, value]) => {
        const isValueValid = typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string'

        if (isValueValid) {
            return {
                ...parameters,
                ...Object.fromEntries(new Map([[key, value.toString()]]))
            }
        }

        return parameters
    }, {})
}
