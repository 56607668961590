import { getUrlFromTemplate } from '@dp-vue/utils'

import { get } from '@/api/utils/httpClientAPI'

import { MkplProfileUrlDTO } from '../interfaces'

const URL_BASE_PROFILE = 'profile'
const URL_VIEW_MKPL_PROFILE = `${URL_BASE_PROFILE}/host-user-profile-url`

export const getMarketplaceProfileUrl = async () => {
    const url = getUrlFromTemplate({
        template: URL_VIEW_MKPL_PROFILE
    })

    return get<MkplProfileUrlDTO>(url)
}
