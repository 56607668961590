import { getUrlFromTemplate } from '@dp-vue/utils'
import { DynamicFormFieldValidator } from '@lib/dynamicForm/interfaces/dynamic.form.interfaces'
import { AxiosRequestConfig } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import { InvoiceVatRate } from '@/features/invoice/domain/interfaces/invoices.interfaces'
import { Schedule } from '@/features/settings/domain/interfaces/schedule.interfaces'

import {
    PaymentMethod,
    QuoteDetails,
    QuoteDetailsPayload,
    QuoteFile,
    QuoteList,
    QuoteSendEmail,
    QuotesFilters
} from '../domain/quotes.interfaces'

const URL_BASE_QUOTES = 'quotes'
const URL_BASE_QUOTES_ALL = `${URL_BASE_QUOTES}/all`
const URL_QUOTE_ADDRESSES = `${URL_BASE_QUOTES}/addresses`
const URL_BASE_SCHEDULES = 'schedules'
const URL_BASE_PAYMENT_METHODS = 'paymentMethods'

const URL_NEXT_NUMBER = `${URL_BASE_QUOTES}/next-number`
const URL_VAT_RATES = `${URL_BASE_QUOTES}/vat-rates`
const URL_MAIL_VALIDATORS = `${URL_BASE_QUOTES}/mail/validators`

const URL_QUOTE_ID = `${URL_BASE_QUOTES}/:quoteId`
const URL_APPROVAL_STATUS = `${URL_QUOTE_ID}/status/:status`
const URL_PDF = `${URL_QUOTE_ID}/pdf`
const URL_MAIL = `${URL_QUOTE_ID}/mail`

class QuotesRepository {
    async getQuotes(filters: QuotesFilters, page: number): Promise<QuoteList> {
        const { data } = await httpAPI.post(URL_BASE_QUOTES_ALL, {
            ...filters,
            page
        })
        const { pageNumber: currentPage, pagesAmount: totalPages, page: quotes } = data

        return { totalPages, currentPage, quotes }
    }

    async getQuote(quoteId: number): Promise<QuoteDetails> {
        const url = getUrlFromTemplate({
            template: URL_QUOTE_ID,
            params: { quoteId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async updateApprovalStatus(quoteId: number, status: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_APPROVAL_STATUS,
            params: {
                quoteId,
                status
            }
        })

        await httpAPI.patch(url)
    }

    async deleteQuote(quoteId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_QUOTE_ID,
            params: {
                quoteId
            }
        })

        await httpAPI.delete(url)
    }

    getQuoteFile(quoteId: number): Promise<QuoteFile> {
        const url = getUrlFromTemplate({
            template: URL_PDF,
            params: {
                quoteId
            }
        })
        const config: AxiosRequestConfig = { responseType: 'blob' as const }
        return httpAPI.get(url, config)
    }

    async getSchedulesAddress(): Promise<Schedule[]> {
        const { data } = await httpAPI.get(URL_BASE_SCHEDULES)
        return data
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        const { data } = await httpAPI.get(URL_BASE_PAYMENT_METHODS)
        return data
    }

    async getQuoteNumber(): Promise<number> {
        const { data } = await httpAPI.get(URL_NEXT_NUMBER)
        return data
    }

    async saveQuote(quote: QuoteDetailsPayload, quoteId?: number): Promise<QuoteDetails> {
        let url = URL_BASE_QUOTES

        if (quoteId) {
            url = getUrlFromTemplate({
                template: URL_QUOTE_ID,
                params: { quoteId }
            })
        }
        const { data } = quoteId ? await httpAPI.put(url, { id: quoteId, ...quote }) : await httpAPI.post(url, quote)
        return data
    }

    async getVatRates(): Promise<InvoiceVatRate[]> {
        const { data } = await httpAPI.get(URL_VAT_RATES)
        return data
    }

    async getQuoteMail(quoteId: number): Promise<QuoteSendEmail> {
        const url = getUrlFromTemplate({
            template: URL_MAIL,
            params: { quoteId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async sendQuoteMail(quoteId: number, payload: QuoteSendEmail): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_MAIL,
            params: { quoteId }
        })
        await httpAPI.post(url, payload)
    }

    async getQuoteMailValidators(): Promise<DynamicFormFieldValidator[]> {
        const { data } = await httpAPI.get(URL_MAIL_VALIDATORS)
        return data
    }

    async getQuoteAddresses(): Promise<string[]> {
        const { data } = await httpAPI.get(URL_QUOTE_ADDRESSES)
        return data
    }
}

export default new QuotesRepository()
