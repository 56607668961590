import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { parseParams } from '@/utils/parseParams'

import { BlockDTO, BlocksPaginatedParams, BlocksParams, PaginatedBlockDTO } from '../domain/interfaces/block.interfaces'

const URL_BASE = 'blocks'
const URL_BLOCK_ID = `${URL_BASE}/:blockId`
const URL_MEDICAL_CENTER_BLOCKS = `${URL_BASE}/medicalcenterblockslist`
const URL_MEDICAL_CENTER_BLOCK = `${URL_BASE}/blockmedicalcenter`
const URL_MEDICAL_CENTER_BLOCK_ID = `${URL_MEDICAL_CENTER_BLOCK}/:blockId`

class CalendarBlocksRepository {
    async getBlocksPaginated(params: BlocksPaginatedParams, isMedicalCenter: boolean): Promise<PaginatedBlockDTO> {
        const url = getUrlFromTemplate({
            template: isMedicalCenter ? URL_MEDICAL_CENTER_BLOCKS : URL_BASE,
            query: parseParams({ ...params })
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async getBlock(blockId: number, isMedicalCenter: boolean): Promise<BlockDTO> {
        const url = getUrlFromTemplate({
            template: isMedicalCenter ? URL_MEDICAL_CENTER_BLOCK_ID : URL_BLOCK_ID,
            params: {
                blockId
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async saveBlock(params: BlocksParams, isMedicalCenter: boolean): Promise<BlockDTO> {
        const url = isMedicalCenter ? URL_MEDICAL_CENTER_BLOCK : URL_BASE
        const { data } = await httpAPI.post(url, params)

        return data
    }

    async updateBlock(blockId: number, params: BlocksParams, isMedicalCenter: boolean): Promise<void> {
        const url = getUrlFromTemplate({
            template: isMedicalCenter ? URL_MEDICAL_CENTER_BLOCK_ID : URL_BLOCK_ID,
            params: {
                blockId
            }
        })
        const { data } = await httpAPI.put(url, params)

        return data
    }

    async deleteBlock(blockId: number, isMedicalCenter: boolean) {
        const url = getUrlFromTemplate({
            template: isMedicalCenter ? URL_MEDICAL_CENTER_BLOCK_ID : URL_BLOCK_ID,
            params: {
                blockId
            }
        })

        await httpAPI.delete(url)
    }
}

export default new CalendarBlocksRepository()
