import { Request } from '../../../../domain/interfaces/requests.interfaces'
import { RequestCategory } from '../../../interfaces/requests.interfaces'

export enum GetterTypes {
    GetAllowRequestsFromNewPatient = 'GET_ALLOW_REQUESTS_FROM_NEW_PATIENTS',
    GetIsRequestFeatureEnabled = 'GET_IS_PRESCRIPTION_FEATURE_ENABLED',
    GetPageCount = 'GET_PAGE_COUNT',
    GetRequestCategories = 'GET_REQUEST_CATEGORIES',
    GetRequestChannel = 'GET_REQUESTS_CHANNEL',
    GetRequests = 'GET_REQUESTS',
    GetUnreadRequestsCount = 'GET_UNREAD_REQUESTS_COUNT'
}
export interface RequestGetters {
    [GetterTypes.GetPageCount]: number
    [GetterTypes.GetRequests]: Request[]
    [GetterTypes.GetUnreadRequestsCount]: number | null
    [GetterTypes.GetIsRequestFeatureEnabled]: boolean
    [GetterTypes.GetRequestChannel]: number | null
    [GetterTypes.GetRequestCategories]: RequestCategory[] | null
    [GetterTypes.GetAllowRequestsFromNewPatient]: boolean
}
