// Requirement: this middleware should be executed after auth middleware

import httpAPI from '@/api/utils/httpClientAPI'
import httpClientCacheAPI from '@/api/utils/httpClientCacheAPI'
import { setUserInfoHeaders } from '@/features/auth/ui/router/auth.utils'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'

export default function httpHeaders({ next, store }) {
    httpAPI.setCountryHeader(store.getters[AuthGetterTypes.GetCountryCode])
    httpClientCacheAPI.setCountryHeader(store.getters[AuthGetterTypes.GetCountryCode])

    const userInfo = store.getters[AuthGetterTypes.GetUserInfoForSentryTags]
    setUserInfoHeaders(httpAPI, userInfo)
    setUserInfoHeaders(httpClientCacheAPI, userInfo)

    next()
}
