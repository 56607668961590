export enum CalendarEventType {
    Appointment = 'appointment',
    Block = 'block',
    BookingRequest = 'bookingRequest',
    Holiday = 'holiday',
    Reminder = 'reminder',
    Workperiod = 'workperiod'
}

export enum CalendarEventGroups {
    Appointments = 'appointments',
    Blocks = 'blocks',
    BookingRequests = 'bookingRequests',
    Holidays = 'holidays',
    Reminders = 'reminders',
    Resources = 'resources',
    Workperiods = 'workperiods'
}

export enum CalendarEventDisplayType {
    Auto = 'auto',
    Background = 'background',
    Block = 'block',
    InverseBackground = 'inverse-background',
    ListItem = 'list-item',
    None = 'none'
}
