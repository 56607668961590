const ExternalCalendarSettings = () =>
    import(
        /* webpackChunkName: "external-calendar-settings" */ '@/components/Settings/ExternalCalendar/ExternalCalendarSettings.vue'
    )

export default [
    {
        path: 'settings-external-calendar',
        name: 'settings-external-calendar',
        component: ExternalCalendarSettings
    }
]
