import {
    DisablePaymentTracking,
    FeatureDeactivationReason,
    PaymentRequestStatus,
    PaymentRequestStatusTranslationKeys,
    PaymentRequestType,
    PaymentRequestTypeTranslationKeys,
    PaymentsBadgeType,
    PaymentStatus,
    PaymentStatusTranslationKeys,
    SchedulePaymentStatus,
    SchedulePaymentStatusDescriptionTranslationKeys,
    SchedulePaymentStatusTranslationKeys
} from '../ui/enums'

export const PAYMENT_STATUS = {
    [PaymentStatus.Unpaid]: {
        value: PaymentStatus.Unpaid,
        translationKey: PaymentStatusTranslationKeys.Unpaid,
        badgeType: PaymentsBadgeType.Danger
    },
    [PaymentStatus.Paid]: {
        value: PaymentStatus.Paid,
        translationKey: PaymentStatusTranslationKeys.Paid,
        badgeType: PaymentsBadgeType.Success
    },
    [PaymentStatus.Refunded]: {
        value: PaymentStatus.Refunded,
        translationKey: PaymentStatusTranslationKeys.Refunded,
        badgeType: PaymentsBadgeType.Info
    },
    [PaymentStatus.RefundFailed]: {
        value: PaymentStatus.RefundFailed,
        translationKey: PaymentStatusTranslationKeys.RefundFailed,
        badgeType: PaymentsBadgeType.Danger
    },
    [PaymentStatus.PartiallyPaid]: {
        value: PaymentStatus.PartiallyPaid,
        translationKey: PaymentStatusTranslationKeys.PartiallyPaid,
        badgeType: PaymentsBadgeType.Warning
    }
}

export const SCHEDULE_PAYMENT_STATUS = {
    [SchedulePaymentStatus.Active]: {
        value: SchedulePaymentStatus.Active,
        translationKey: SchedulePaymentStatusTranslationKeys.Active,
        descriptionTranslationKey: SchedulePaymentStatusDescriptionTranslationKeys.Active,
        badgeType: PaymentsBadgeType.Success
    },
    [SchedulePaymentStatus.Inactive]: {
        value: SchedulePaymentStatus.Inactive,
        translationKey: SchedulePaymentStatusTranslationKeys.Inactive,
        descriptionTranslationKey: SchedulePaymentStatusDescriptionTranslationKeys.Inactive,
        badgeType: PaymentsBadgeType.Neutral
    },
    [SchedulePaymentStatus.ActiveByDefault]: {
        value: SchedulePaymentStatus.ActiveByDefault,
        translationKey: SchedulePaymentStatusTranslationKeys.ActiveByDefault,
        descriptionTranslationKey: SchedulePaymentStatusDescriptionTranslationKeys.ActiveByDefault,
        badgeType: PaymentsBadgeType.Success
    },
    [SchedulePaymentStatus.Required]: {
        value: SchedulePaymentStatus.Required,
        translationKey: SchedulePaymentStatusTranslationKeys.Required,
        descriptionTranslationKey: SchedulePaymentStatusDescriptionTranslationKeys.Required,
        badgeType: PaymentsBadgeType.Warning
    }
}

export const PAYMENT_REQUEST_STATUS = {
    [PaymentRequestStatus.NotPaid]: {
        value: PaymentRequestStatus.NotPaid,
        translationKey: PaymentRequestStatusTranslationKeys.NotPaid,
        badgeType: PaymentsBadgeType.Danger
    },
    [PaymentRequestStatus.Paid]: {
        value: PaymentRequestStatus.Paid,
        translationKey: PaymentRequestStatusTranslationKeys.Paid,
        badgeType: PaymentsBadgeType.Success
    },
    [PaymentRequestStatus.Refunded]: {
        value: PaymentRequestStatus.Refunded,
        translationKey: PaymentRequestStatusTranslationKeys.Refunded,
        badgeType: PaymentsBadgeType.Info
    },
    [PaymentRequestStatus.WaitingForConfirmation]: {
        value: PaymentRequestStatus.WaitingForConfirmation,
        translationKey: PaymentRequestStatusTranslationKeys.WaitingForConfirmation,
        badgeType: PaymentsBadgeType.Warning
    },
    [PaymentRequestStatus.Canceled]: {
        value: PaymentRequestStatus.Canceled,
        translationKey: PaymentRequestStatusTranslationKeys.Canceled,
        badgeType: PaymentsBadgeType.Neutral
    }
}

export const PAYMENT_REQUEST_TYPE = {
    [PaymentRequestType.Visit]: {
        value: PaymentRequestType.Visit,
        translationKey: PaymentRequestTypeTranslationKeys.Visit
    },
    [PaymentRequestType.Voucher]: {
        value: PaymentRequestType.Voucher,
        translationKey: PaymentRequestTypeTranslationKeys.Voucher
    },
    [PaymentRequestType.None]: {
        value: PaymentRequestType.None,
        translationKey: PaymentRequestTypeTranslationKeys.None
    }
}

export const TRACK_REASON = {
    [FeatureDeactivationReason.DontLike]: DisablePaymentTracking.DontLike,
    [FeatureDeactivationReason.DoesntWork]: DisablePaymentTracking.DoesntWork,
    [FeatureDeactivationReason.DontNeed]: DisablePaymentTracking.DontNeed,
    [FeatureDeactivationReason.Other]: DisablePaymentTracking.Other
}
