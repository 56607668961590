import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'

const IncomeSplit = () =>
    import(
        /* webpackChunkName: "income-split-settings" */ '@/features/settings/ui/components/incomeSplit/IncomeSplitSettings.vue'
    )

export default [
    {
        path: '/settings-income-split',
        name: 'settings-income-split',
        component: IncomeSplit,
        meta: {
            middleware: featureToggleMiddleware({
                key: 'IncomeSplit',
                value: 'true',
                fallbackRouteName: ROUTE_CALENDAR_BASE_NAME
            })
        }
    }
]
