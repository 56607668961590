import { Appointment, PatientusVideoConfig } from '@patientus/video-lib-wc'

import { PatientConversation, SessionToken } from '@/features/chat/domain/interfaces/conversations.interfaces'
import { ASSETS_PATH, FEATURE_CONFIG } from '@/features/videoConsultation/domain/constants/videoConsultation.config'
import { $t } from '@/utils/i18n'
import { apiUrl } from '@/utils/url'

import repository from '../api/videoConsultation.api'
import {
    ExternalIdDTO,
    PatientLinkDTO,
    PatientVideoConsultationDTO,
    VideoConsultationConfigParams,
    VideoConsultationCountryConfigParams,
    VideoConsultationData
} from './interfaces/videoConsultation.interfaces'

class VideoConsultationService {
    async prepareAppointmentData(data: VideoConsultationData, isDoctor = false): Promise<Appointment> {
        const { dataPrivacyAccepted, eventId, patientProfileId, ...videoConsultationData } = data
        const appointmentData = {
            ...videoConsultationData,
            isEbm: true
        }

        // // todo: remove when v2 API is updated
        if (isDoctor) {
            const { link } = await this.getPatientLink(eventId)
            const patientParticipant = appointmentData.otherParticipants[0] || {}
            patientParticipant.inviteUrl = link
        }

        return appointmentData
    }

    async startDoctorVideoConsultation(eventId: number): Promise<Appointment> {
        const data = await repository.startDoctorVideoConsultation(eventId)
        return this.prepareAppointmentData(data, true)
    }

    async startPatientVideoConsultation(token: string): Promise<Appointment> {
        const data = await repository.startPatientVideoConsultation(token)
        return this.prepareAppointmentData(data)
    }

    getSessionApiEndpoint(isDoctor: boolean, eventId: number, patientToken: string): string {
        const url = `${apiUrl}/api/videoconsultation`
        return isDoctor
            ? `${url}/doctor/event/${eventId}/ovssession`
            : `${url}/patient/ovssession?token=${patientToken}`
    }

    getCountryConfig(countryConfigParams: VideoConsultationCountryConfigParams): Partial<PatientusVideoConfig> {
        const ipProxy = countryConfigParams?.ipProxy
        const iceConfig = countryConfigParams?.iceConfig
        const dataPrivacy = {
            ...(countryConfigParams.dataPrivacy || {}),
            applicationName: $t('CFBundleDisplayName').toString()
        }

        return {
            ...(ipProxy && { ipProxy }),
            ...(iceConfig && { iceConfig }),
            ...(dataPrivacy && { feature: { dataPrivacy, ...FEATURE_CONFIG } })
        }
    }

    getConfig(
        configParams: VideoConsultationConfigParams,
        countryConfigParams: VideoConsultationCountryConfigParams
    ): PatientusVideoConfig {
        const config: PatientusVideoConfig = {
            appointment: configParams.appointment,
            sessionApiEndpoint: configParams.sessionApiEndpoint,
            assetsPath: ASSETS_PATH,
            logoUrl: require('@dp-ui-kit/logos/svg/plain.svg'),
            language: configParams.locale,
            ...(configParams.isDoctor && { jwt: configParams.token }),
            ...{ feature: FEATURE_CONFIG }
        }

        const countryConfig = this.getCountryConfig(countryConfigParams)

        return { ...config, ...countryConfig }
    }

    getPatientChatConfig(patientToken: string): { token: string } {
        return {
            token: patientToken
        }
    }

    getByExternalId(externalId: number): Promise<ExternalIdDTO> {
        return repository.getByExternalId(externalId)
    }

    patientDataPrivacy(token: string): Promise<PatientVideoConsultationDTO> {
        return repository.patientDataPrivacy(token)
    }

    getPatientLink(eventId: number): Promise<PatientLinkDTO> {
        return repository.getPatientLink(eventId)
    }

    getConversationByPatientToken(token: string): Promise<PatientConversation> {
        return repository.getConversationByPatientToken(token)
    }

    getPatientSessionToken(token: string): Promise<SessionToken> {
        return repository.getPatientSessionToken(token)
    }
}

export default new VideoConsultationService()
