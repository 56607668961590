import { CalendarEventGroups } from '../../domain/enums/calendarEvent.enums'
import { CALENDAR_VIEW_DAILY } from '../constants/calendar.constants'
import defaultCalendarSidebarState from './calendarSidebar/state'
import defaultCalendarPerformanceSurveyState from './performanceSurvey/state'

export const defaultCalendarCreationState = {
    calendarCreationType: '',
    calendarCreationAddress: -1,
    calendarCreationSelectedServices: [],
    calendarCreationAppointments: [],
    calendarCreationWorkperiods: [],
    calendarCreationBlocks: [],
    calendarCreationReminders: [],
    calendarCreationHolidays: [],
    calendarCreationResources: [],
    calendarCreationName: '',
    calendarCreationColorId: 1
}

export const defaultCalendarEventsState = () => ({
    [CalendarEventGroups.Appointments]: [],
    [CalendarEventGroups.Workperiods]: [],
    [CalendarEventGroups.Blocks]: [],
    [CalendarEventGroups.Reminders]: [],
    [CalendarEventGroups.Holidays]: [],
    [CalendarEventGroups.Resources]: [],
    [CalendarEventGroups.BookingRequests]: []
})

export const state = {
    loading: false,
    view: CALENDAR_VIEW_DAILY,
    selectedDate: new Date(),
    selectedFacility: undefined,
    selectedSpeciality: undefined,
    selectedSpecialityGroup: undefined,
    selectedFreeSlotsDays: [],
    selectedFreeSlotsDaytime: null,
    selectedFreeSlotsInsuranceIds: [],
    selectedFreeSlotsPatientType: null,
    selectedFreeSlotsService: [],
    calendarRoutePath: undefined,
    calendarEvents: defaultCalendarEventsState(),
    calendarEventsDateRange: {},
    calendarEventsWeekRangeNumbers: [],
    colorSchemas: null,
    colorSchemasArray: null,
    calendarRooms: [],
    calendarServices: [],
    calendarEventsCurrentViewServicesIds: [],
    facilities: [],
    specialities: [],
    doctors: [],
    doctorsWorkingCurrentPeriod: true,
    sidebarCalendarsQuery: '',
    sidebarServicesQuery: '',
    calendarDevices: [],
    devices: [],
    calendarStaff: [],
    freeSlotsAvailableDays: [],
    freeSlotsSelectedDateEnd: new Date(),
    agendaEvents: {
        days: []
    },
    agendaRange: {
        startDate: null,
        endDate: null
    },
    schedules: [],
    schedulesOrder: [],
    agendaSelectedPatient: null,
    agendaSelectedDuration: null,
    rightSideBarProps: null,
    clipboard: {
        event: null,
        isCut: false,
        timestamp: null
    },
    showResourceWeek: false,
    hideBookedSlots: false,
    hidePatientData: false,
    doctorSchedules: [],
    ...defaultCalendarCreationState,
    ...defaultCalendarSidebarState,
    ...defaultCalendarPerformanceSurveyState
}
