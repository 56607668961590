import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { ReportExportType, ReportPathname } from '../../domain/enums/reports.enums'
import hasAccessToRevenue from '../middlewares/hasAccessToRevenue.middleware'
import { setDefaultReportsQueryParams } from '../middlewares/setDefaultReportsQueryParams'
import viewCounter from '../middlewares/viewCounter'

const Reports = () => import(/* webpackChunkName: "reports" */ '@/features/reports/ui/views/Reports.vue')
const ReportsVisits = () =>
    import(/* webpackChunkName: "reports-visits" */ '@/features/reports/ui/views/tabs/ReportsVisits.vue')
const ReportsCashRegister = () =>
    import(/* webpackChunkName: "reports-cash-register" */ '@/features/reports/ui/views/tabs/ReportsCashRegister.vue')
const ReportsDoctorsCommissions = () =>
    import(
        /* webpackChunkName: "reports-doctors-commissions" */ '@/features/reports/ui/views/tabs/ReportsDoctorsCommissions.vue'
    )
const ReportsExpenses = () =>
    import(/* webpackChunkName: "reports-expenses" */ '@/features/reports/ui/views/tabs/ReportsExpenses.vue')

export default {
    path: 'reports',
    redirect: 'reports/visits',
    component: Reports,
    meta: {
        title: 'visits-report-navigation-title',
        middleware: [
            uninav,
            featureToggleMiddleware({
                key: 'Reports',
                value: 'true',
                fallbackRouteName: ROUTE_CALENDAR_BASE_NAME
            }),
            setDefaultReportsQueryParams
        ],
        uninavName: 'dashboard'
    },
    children: [
        {
            path: 'visits',
            name: ReportPathname.Visits,
            component: ReportsVisits,
            beforeEnter: viewCounter,
            meta: {
                exportType: ReportExportType.Reports,
                trackView: true
            }
        },
        {
            path: 'cash-register',
            name: ReportPathname.Cash,
            component: ReportsCashRegister,
            beforeEnter: viewCounter,
            meta: {
                showCashStatistics: true,
                exportType: ReportExportType.Payments,
                trackView: true,
                middleware: [hasAccessToRevenue]
            }
        },
        {
            path: 'commissions',
            name: ReportPathname.Commissions,
            component: ReportsDoctorsCommissions,
            beforeEnter: viewCounter,
            meta: {
                // exportType: ReportExportType.Payments,
                trackView: true,
                middleware: [hasAccessToRevenue]
            }
        },
        {
            path: 'expenses',
            name: ReportPathname.Expenses,
            component: ReportsExpenses,
            beforeEnter: viewCounter,
            meta: {
                trackView: true,
                middleware: [hasAccessToRevenue]
            }
        }
    ]
}
