const generateTitle = ({ matched, currentRoute, translate, companyName }) => {
    const companyNameTitle = translate(companyName || 'email-dp-company-name')

    if (!matched.some(record => record?.meta?.title)) {
        const currentTitle = translate(currentRoute?.meta?.title) || ''
        return currentTitle.length ? `${currentTitle} | ${companyNameTitle}` : companyNameTitle
    }

    let metaTitle = ''

    const titleTree = matched.map(({ meta }) => meta.title).filter(item => !!item)

    if (titleTree.length) {
        metaTitle = titleTree
            .map(title => translate(title))
            .reverse()
            .join(' | ')
    }

    metaTitle += (metaTitle.length ? ' | ' : '') + companyNameTitle || ''

    return metaTitle
}

export default generateTitle
