import {
    SET_APP_STATE,
    SET_AUTH_PROVIDER,
    SET_COUNTRY_SETTINGS,
    SET_CULTURE,
    SET_DATE,
    SET_FEATURES
} from '@/store/mutation-types'

const mutations = {
    [SET_DATE](state, date) {
        state.selectedDate = date
    },
    [SET_FEATURES](state, features) {
        state.features = features
    },
    [SET_CULTURE](state, culture) {
        state.culture = culture
    },
    [SET_APP_STATE](state, appState) {
        state.appState = appState
    },
    [SET_COUNTRY_SETTINGS](state, settings) {
        state.countrySettings = settings
    },
    [SET_AUTH_PROVIDER](state, provider) {
        state.authProvider = provider
    }
}

export default mutations
