import { Patient } from '@/features/patient/domain/interfaces/patient.interfaces'

import { BookingEvent } from './state'

export enum GetterTypes {
    GetBookingEvent = 'getBookingEvent',
    GetBookingPatient = 'getBookingPatient'
}

export interface BookingGetters {
    [GetterTypes.GetBookingPatient]: Patient | undefined
    [GetterTypes.GetBookingEvent]: BookingEvent | undefined
}
