import { ColorSchema } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import { Resource } from '@/features/calendar/domain/interfaces/resource.interfaces'

import { sanitize } from '../../../core/ui/utils/sanitize.utils'

class CalendarResourceService {
    public processCalendarResource(resource: Resource, colorSchemas: Record<number, ColorSchema>): Resource {
        if (!resource.colorSchemas && resource.colorSchemaId) {
            resource.colorSchemas = colorSchemas[resource.colorSchemaId]
        }
        return resource
    }

    public mapResourcesCollectionToCalendarResources(
        resources: Resource[],
        colorSchemas: Record<number, ColorSchema>
    ): Resource[] {
        return resources.map((resource: Resource) => ({
            ...this.processCalendarResource(resource, colorSchemas),
            title: sanitize(resource.title)
        }))
    }
}

export default new CalendarResourceService()
