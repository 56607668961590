import axios, {
    AxiosError,
    AxiosInstance,
    AxiosResponse,
    Canceler,
    CancelToken,
    CreateAxiosDefaults,
    InternalAxiosRequestConfig
} from 'axios'
import { buildWebStorage, setupCache } from 'axios-cache-interceptor'

import { NAME_PREFIX } from '@/store/storage'

import { handleErrorInterceptors } from './interceptors/handleErrorInterceptors'

const { mergeConfig } = require('axios') // eslint-disable-line @typescript-eslint/no-var-requires

interface HttpClientOptions {
    cache: boolean
    emitEventOnError: boolean
}

const DEFAULT_OPTIONS: HttpClientOptions = {
    cache: false,
    emitEventOnError: true
}

const getDefaultOptions = (options?: Partial<HttpClientOptions>): HttpClientOptions => ({
    ...DEFAULT_OPTIONS,
    ...options
})

class HttpClient {
    instance: AxiosInstance

    get: typeof axios.get

    delete: typeof axios.delete

    post: typeof axios.post

    put: typeof axios.put

    patch: typeof axios.patch

    emitEventOnError: boolean

    constructor(config: CreateAxiosDefaults, httpClientOptions?: Partial<HttpClientOptions>) {
        const { cache, emitEventOnError } = getDefaultOptions(httpClientOptions)

        this.instance = axios.create(config)

        if (cache) {
            setupCache(this.instance, {
                storage: buildWebStorage(localStorage, NAME_PREFIX)
            })
        }

        this.get = this.instance.get
        this.delete = this.instance.delete
        this.post = this.instance.post
        this.put = this.instance.put
        this.patch = this.instance.patch
        this.emitEventOnError = emitEventOnError

        this.setupInterceptors()
    }

    setupInterceptors() {
        this.instance.interceptors.response.use(
            function (response) {
                return response
            },
            function (error: AxiosError) {
                const actualError = handleErrorInterceptors(error)

                return Promise.reject(actualError || error)
            }
        )
    }

    getConfig() {
        return this.instance.defaults
    }

    updateConfig(config: CreateAxiosDefaults) {
        this.instance.defaults = mergeConfig(this.instance.defaults, config)
        return this.instance.defaults
    }

    setAuthorizationToken(token: string) {
        this.instance.defaults.headers.common.Authorization = token
    }

    getAuthorizationToken() {
        return this.instance.defaults.headers.common.Authorization
    }

    clearAuthorizationToken() {
        delete this.instance.defaults.headers.common.Authorization
    }

    setCountryHeader(countryCode: string) {
        this.instance.defaults.headers.common['x-country-id'] = countryCode
    }

    setCustomHeader(headerName: string, value: string) {
        this.instance.defaults.headers.common[headerName] = value
    }

    createCancelToken(executor: (cancel: Canceler) => void): CancelToken {
        return new axios.CancelToken(executor)
    }

    addRequestInterceptor(
        fulfilled: (
            value: InternalAxiosRequestConfig<any>
        ) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
        rejected: (error: any) => any
    ) {
        return this.instance.interceptors.request.use(fulfilled, rejected)
    }

    removeRequestInterceptor(id: number) {
        this.instance.interceptors.request.eject(id)
    }

    addResponseInterceptor(
        fulfilled: (value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
        rejected: (error: any) => any
    ) {
        return this.instance.interceptors.response.use(fulfilled, rejected)
    }

    removeResponseInterceptor(id: number) {
        this.instance.interceptors.response.eject(id)
    }
}

export default HttpClient
