export enum CalendarRoutePathname {
    Agenda = 'calendarAgenda',
    DailyList = 'calendarDailyList',
    Day = 'calendarDay',
    FreeSlots = 'calendarFreeTerms',
    Week = 'calendarWeek'
}

export enum CalendarPeriod {
    Day = 'day',
    Week = 'week'
}

export enum CalendarTimeBoundaries {
    End = 'end',
    Start = 'start'
}

export enum CalendarEventSizeThreshold {
    XS = 15,
    SM = 28,
    MD = 30,
    LG = 46
}

export enum CalendarEventWidthThreshold {
    ABSTRACT = 40,
    MINIMUM = 85,
    MEDIUM = 135
}

export enum CalendarEventWidthClassName {
    ABSTRACT = 'abstract',
    MAXIMUM = 'maximum',
    MEDIUM = 'medium',
    MINIMUM = 'minimum'
}

export enum CalendarWizardSteps {
    CalendarTypeStep = 0,
    AddressStep = 1,
    ServicesStep = 2,
    WorkperiodsStep = 3,
    NameColorStep = 4
}

export enum EventHook {
    EventChanged = 'EVENT_CHANGED',
    EventCreated = 'EVENT_CREATED',
    EventRemoved = 'EVENT_REMOVED',
    EventResent = 'EVENT_RESENT'
}

export enum CalendarDensityType {
    Comfortable = 'comfortable',
    Compact = 'compact',
    Default = 'default'
}

export enum CalendarOrderType {
    Alphabetical = 'alphabetical',
    Availability = 'availability',
    Custom = 'custom'
}
