import Cookies from 'js-cookie'

import uninav from '@/features/navigation/ui/router/uninav.middleware'
import store from '@/store'

import { CALENDAR_VIEW } from '../constants/calendar.constants'
import {
    CALENDAR_VIEW_NAMES_MAP,
    ROUTE_CALENDAR_AGENDA,
    ROUTE_CALENDAR_BASE,
    ROUTE_CALENDAR_BASE_CLINIC,
    ROUTE_CALENDAR_BASE_NAME,
    ROUTE_CALENDAR_CREATE,
    ROUTE_CALENDAR_CREATE_WIZARD_PATH,
    ROUTE_CALENDAR_DAY,
    ROUTE_CALENDAR_FREE_SLOTS,
    ROUTE_CALENDAR_PATH_AGENDA,
    ROUTE_CALENDAR_PATH_CALENDAR_DAY,
    ROUTE_CALENDAR_PATH_CALENDAR_DAY_EVENT,
    ROUTE_CALENDAR_PATH_DAY,
    ROUTE_CALENDAR_PATH_SECRETARY_DAILY_LIST,
    ROUTE_CALENDAR_PATH_SLOTS,
    ROUTE_CALENDAR_PATH_WEEK,
    ROUTE_CALENDAR_SECRETARY_DAILY_LIST,
    ROUTE_CALENDAR_WEEK
} from '../constants/routes.constants'
import {
    ACTION_FETCH_CALENDAR_COLOR_SCHEMAS,
    ACTION_FETCH_CALENDAR_SCHEDULES,
    ACTION_FETCH_CALENDAR_SCHEDULES_ORDER,
    MODULE_CALENDAR,
    MUTATION_SET_CALENDAR_ROUTE_PATH,
    MUTATION_SET_CALENDAR_SELECTED_DATE
} from '../store/types'

const CalendarBase = () => import(/* webpackChunkName: "calendar-base-view" */ '../views/CalendarBase.vue')
const Calendar = () => import(/* webpackChunkName: "calendar-view" */ '../views/Calendar.vue')
const CalendarSecretaryView = () =>
    import(/* webpackChunkName: "calendar-secretary-view" */ '../views/CalendarSecretaryView.vue')
const CalendarAgendaView = () => import(/* webpackChunkName: "calendar-agenda-view" */ '../views/CalendarAgenda.vue')
const CalendarCreationWizard = () =>
    import(/* webpackChunkName: "calendar-creation-wizard-view" */ '../views/CalendarCreationWizard.vue')
const CalendarFreeSlotsView = () =>
    import(/* webpackChunkName: "calendar-freeslots-view" */ '../views/CalendarFreeSlots.vue')

const beforeEnterCalendarBaseChildren = ({ name }, from, next) => {
    store.commit(`${MODULE_CALENDAR}/${MUTATION_SET_CALENDAR_ROUTE_PATH}`, name)
    Cookies.set(CALENDAR_VIEW, CALENDAR_VIEW_NAMES_MAP[name] || Cookies.get('calendarView'))
    next()
}

export default [
    {
        path: '',
        name: ROUTE_CALENDAR_BASE_NAME,
        alias: [ROUTE_CALENDAR_BASE, ROUTE_CALENDAR_BASE_CLINIC],
        meta: {
            middleware: [uninav],
            uninavName: 'calendar',
            title: 'calendario'
        },
        component: CalendarBase,
        beforeEnter: (to, from, next) => {
            const calendarDate = new Date()
            const isFromCalendar = from.matched.some(({ name }) => name === ROUTE_CALENDAR_BASE_NAME)

            if (!isFromCalendar) {
                store.commit(`${MODULE_CALENDAR}/${MUTATION_SET_CALENDAR_SELECTED_DATE}`, calendarDate)
                store.dispatch(`${MODULE_CALENDAR}/${ACTION_FETCH_CALENDAR_COLOR_SCHEMAS}`)
                store.dispatch(`${MODULE_CALENDAR}/${ACTION_FETCH_CALENDAR_SCHEDULES}`)
                store.dispatch(`${MODULE_CALENDAR}/${ACTION_FETCH_CALENDAR_SCHEDULES_ORDER}`)
            }

            next()
        },
        children: [
            {
                path: ROUTE_CALENDAR_PATH_DAY,
                name: ROUTE_CALENDAR_DAY,
                alias: [
                    `${ROUTE_CALENDAR_BASE_CLINIC}/${ROUTE_CALENDAR_PATH_DAY}`,
                    ROUTE_CALENDAR_PATH_CALENDAR_DAY_EVENT,
                    ROUTE_CALENDAR_PATH_CALENDAR_DAY
                ],
                meta: { title: 'dia' },
                component: Calendar,
                beforeEnter: beforeEnterCalendarBaseChildren
            },
            {
                path: ROUTE_CALENDAR_PATH_SECRETARY_DAILY_LIST,
                name: ROUTE_CALENDAR_SECRETARY_DAILY_LIST,
                alias: [`${ROUTE_CALENDAR_BASE_CLINIC}/${ROUTE_CALENDAR_PATH_SECRETARY_DAILY_LIST}`],
                meta: { title: 'secretary-view-list-view-in-calendar' },
                component: CalendarSecretaryView,
                beforeEnter: beforeEnterCalendarBaseChildren
            },
            {
                path: ROUTE_CALENDAR_PATH_WEEK,
                name: ROUTE_CALENDAR_WEEK,
                alias: `${ROUTE_CALENDAR_BASE_CLINIC}/${ROUTE_CALENDAR_PATH_WEEK}`,
                meta: { title: 'semana' },
                component: Calendar,
                beforeEnter: beforeEnterCalendarBaseChildren
            },
            {
                path: ROUTE_CALENDAR_PATH_AGENDA,
                name: ROUTE_CALENDAR_AGENDA,
                alias: [`${ROUTE_CALENDAR_BASE_CLINIC}/${ROUTE_CALENDAR_PATH_AGENDA}`],
                meta: { title: 'agenda' },
                component: CalendarAgendaView,
                beforeEnter: beforeEnterCalendarBaseChildren
            },
            {
                path: ROUTE_CALENDAR_PATH_SLOTS,
                name: ROUTE_CALENDAR_FREE_SLOTS,
                alias: `${ROUTE_CALENDAR_BASE_CLINIC}/${ROUTE_CALENDAR_PATH_SLOTS}`,
                meta: { title: 'horarios-disponibles' },
                component: CalendarFreeSlotsView,
                beforeEnter: beforeEnterCalendarBaseChildren
            }
        ]
    },
    {
        path: ROUTE_CALENDAR_CREATE_WIZARD_PATH,
        name: ROUTE_CALENDAR_CREATE,
        component: CalendarCreationWizard
    }
]
