import { ChatTemplate } from '@/features/settings/domain/interfaces/messages.interfaces'

import { ChatType } from '../../../domain/enums/chat.enums'
import { SessionToken, UnreadConversation } from '../../../domain/interfaces/conversations.interfaces'
import { ChatState } from './state'

export enum MutationTypes {
    SetChatTemplates = 'SET_CHAT_TEMPLATES',
    SetIsContactFromProfileAllowed = 'SET_IS_CONTACT_FROM_PROFILE_ALLOWED',
    SetPatientHasUnread = 'SET_PATIENT_HAS_UNREAD',
    SetSaasInternalChatDisclaimer = 'SET_SAAS_INTERNAL_CHAT_DISCLAIMER',
    UpdateCurrentUserSessionToken = 'UPDATE_CURRENT_USER_SESSION_TOKEN',
    UpdateStatusGlobalMessageBlock = 'UPDATE_STATUS_GLOBAL_MESSAGE_BLOCK',
    UpdateUnreadConversations = 'UPDATE_UNREAD_CONVERSATIONS'
}

export type ChatMutations = {
    [MutationTypes.SetSaasInternalChatDisclaimer](state: ChatState, approval: boolean): void
    [MutationTypes.SetPatientHasUnread](
        state: ChatState,
        { patientId, hasUnread }: { hasUnread: boolean; patientId: number }
    ): void
    [MutationTypes.UpdateCurrentUserSessionToken](state: ChatState, sessionToken: SessionToken): void
    [MutationTypes.UpdateUnreadConversations](
        state: ChatState,
        {
            chatType,
            unreadConversations
        }: {
            chatType: ChatType
            unreadConversations: UnreadConversation[] | number
        }
    ): void
    [MutationTypes.SetIsContactFromProfileAllowed](state: ChatState, isAllowed: boolean | null): void
    [MutationTypes.SetChatTemplates](state: ChatState, chatTemplates: ChatTemplate[]): void
    [MutationTypes.UpdateStatusGlobalMessageBlock](state: ChatState, canReplyToChat: boolean): void
}
