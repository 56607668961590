import { ConsentFileType } from '@/features/privacyPolicy/domain/enums/privacyPolicy.enums'
import PrivacyPolicyService from '@/features/privacyPolicy/domain/privacyPolicy.service'
import {
    FETCH_SETTINGS,
    REMOVE_PRIVACY_POLICY_FILE,
    RESET_STATE,
    UPDATE_SETTINGS,
    UPLOAD_PRIVACY_POLICY_FILE
} from '@/features/privacyPolicy/ui/store/types/actions'
import {
    CLEAR_STATE,
    DELETE_PRIVACY_POLICY_FILE,
    SAVE_SETTINGS,
    SET_SETTINGS
} from '@/features/privacyPolicy/ui/store/types/mutations'

export const actions = {
    [UPDATE_SETTINGS]({ commit }, updatedSettings) {
        commit(SET_SETTINGS, updatedSettings)
    },
    async [FETCH_SETTINGS]({ commit }) {
        const { data: settings } = await PrivacyPolicyService.getSettings()

        commit(SET_SETTINGS, settings)
        commit(SAVE_SETTINGS, settings)
    },
    async [UPLOAD_PRIVACY_POLICY_FILE]({ dispatch }, file) {
        const { guid } = await PrivacyPolicyService.uploadPrivacyPolicy(file)

        dispatch(UPDATE_SETTINGS, {
            privacyFileIds: [
                {
                    idString: guid,
                    fileType: ConsentFileType.PrivacyPolicy
                }
            ]
        })
    },
    async [REMOVE_PRIVACY_POLICY_FILE]({ commit }, fileId) {
        await PrivacyPolicyService.removePrivacyPolicy(fileId)
        commit(DELETE_PRIVACY_POLICY_FILE)
    },
    [RESET_STATE]({ commit }) {
        commit(CLEAR_STATE)
    }
}
