import download from 'downloadjs'

import ExtraFeatures from '@/api/ExtraFeatures'
import repository from '@/features/privacyPolicy/api/privacyPolicy.api'
import { ConsentFileType } from '@/features/privacyPolicy/domain/enums/privacyPolicy.enums'
import { PrivacyPolicySettings } from '@/features/privacyPolicy/domain/interfaces/privacyPolicy.interfaces'
import userFilesRepository from '@/features/settings/api/userFiles.api'
import { UserFileType } from '@/features/settings/domain/enums/userFiles.enums'
import { UserFileGuid } from '@/features/settings/domain/interfaces/userFiles.interfaces'
import { PRE_CHECKIN_ID } from '@/features/settings/ui/constants/internalFeatures.constants'
import { getFileNameFromContentDisposition } from '@/utils/http'

const ExtraFeaturesAPI = ExtraFeatures()

class PrivacyPolicyService {
    uploadPrivacyPolicy(file: File): Promise<UserFileGuid> {
        return userFilesRepository.uploadFile(file, '', UserFileType.PrivacyPolicy)
    }

    fetchPrivacyFileById(guid: string) {
        return userFilesRepository.fetchFileById(guid)
    }

    async removePrivacyPolicy(id: string): Promise<void> {
        await userFilesRepository.removeFile(id)
    }

    async isPreCheckinEnabled(): Promise<boolean> {
        const { data: features } = await ExtraFeaturesAPI.getExtraFeatures()
        const { isActivated } = features.find((feature: any) => feature.id === PRE_CHECKIN_ID) || {}
        return isActivated
    }

    async downloadFileTemplate(fileType: ConsentFileType): Promise<void> {
        const response = await repository.downloadFileTemplate(fileType)
        download(response.data, getFileNameFromContentDisposition(response))
    }

    updateSettings(updatedSettings: PrivacyPolicySettings) {
        return repository.updateSettings(updatedSettings)
    }

    async activateFeature() {
        return repository.activateFeature()
    }

    getSettings() {
        return repository.getSettings()
    }

    getGdprConfigurationTracking(settings: PrivacyPolicySettings): string {
        const { hasPrivacyPolicy, hasMarketingConsent } = settings

        // TODO: use property values from one-tracking 2.0 when ready
        switch (true) {
            case hasPrivacyPolicy && hasMarketingConsent:
                return 'both'
            case hasPrivacyPolicy:
                return 'privacy'
            case hasMarketingConsent:
                return 'marketing'
            default:
                return ''
        }
    }
}

export default new PrivacyPolicyService()
