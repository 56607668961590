import Cookies from 'js-cookie'

// DOUBT: does this util belong to data access layer (currently only for API) instead of UI/presentation?

const SAME_SITE_LAX = 'Lax'

export const getCookie = (name: string) => {
    return Cookies.get(name)
}

/**
 * Expires (Date): Timestamp when the Cookie will be disabled
 * Expires (Number): Number of Days that the Cookie will be active from the day of creation
 *
 * https://github.com/js-cookie/js-cookie/blob/main/src/api.mjs#L13
 */
export const setCookie = (name: string, value: string, expires?: number | Date) => {
    return Cookies.set(name, value, {
        secure: true,
        sameSite: SAME_SITE_LAX,
        ...(expires && { expires })
    })
}

export const removeCookie = (name: string) => {
    return Cookies.remove(name)
}

export const removeAllCookies = () => {
    Object.keys(Cookies.get()).forEach(cookieName => {
        removeCookie(cookieName)
    })
}

export const removeAllCookiesExcept = (list: string[]) => {
    Object.keys(Cookies.get()).forEach(cookieName => {
        if (!list.includes(cookieName)) {
            removeCookie(cookieName)
        }
    })
}
