import VuexPersist from 'vuex-persist'

import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import { STORE_CALENDAR_FILTERS_STORAGE_KEY } from '@/features/calendar/ui/constants/calendar.constants'
import { STORE_CALENDAR_FILTERS_KEY } from '@/features/calendar/ui/constants/calendar.settings.constants'
import Storage from '@/store/storage'
import { parseJSON } from '@/utils/parse-json'

import {
    MODULE_CALENDAR,
    MUTATION_SET_CALENDAR_SELECTED_ROOMS,
    MUTATION_SET_CALENDAR_SELECTED_SCHEDULES,
    MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD,
    MUTATION_SET_SELECTED_FACILITY,
    MUTATION_UPDATE_DOCTORS_SELECTED
} from './types'

const LISTENED_MUTATIONS = [
    MUTATION_SET_CALENDAR_SELECTED_ROOMS,
    MUTATION_SET_CALENDAR_SELECTED_SCHEDULES,
    MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD,
    MUTATION_SET_SELECTED_FACILITY,
    MUTATION_UPDATE_DOCTORS_SELECTED
].map(elem => `${MODULE_CALENDAR}/${elem}`)

const dataMapper = entity => {
    return entity
        .map(({ id, name, selected, userSchedules = [] }) => ({
            id,
            name,
            selected,
            ...(userSchedules.length && {
                userSchedules: userSchedules.map(({ facilityId }) => ({
                    facilityId
                }))
            })
        }))
        .filter(({ selected }) => selected)
}

export default new VuexPersist({
    key: Storage.getParamName(STORE_CALENDAR_FILTERS_STORAGE_KEY),
    restoreState: (key, storage) => {
        const storageItem = parseJSON(storage[key], {})

        if (window.location.pathname.includes(ROUTE_SELECT_USER)) {
            return {}
        }

        return storageItem
    },
    reducer: state => {
        const {
            module_calendar: { calendarRooms, doctors, schedules, selectedFacility, doctorsWorkingCurrentPeriod },
            auth: {
                user: { userSettings }
            }
        } = state
        const storeCalendarFiltersEnabled = userSettings[STORE_CALENDAR_FILTERS_KEY] === 'true'

        if (!storeCalendarFiltersEnabled) {
            return {}
        }

        return {
            module_calendar: {
                calendarRooms: dataMapper(calendarRooms),
                doctors: dataMapper(doctors),
                schedules: dataMapper(schedules),
                doctorsWorkingCurrentPeriod,
                selectedFacility
            }
        }
    },
    filter: mutation => {
        const isStoreCalendarFiltersUserSettingMutation = mutation.payload?.key === STORE_CALENDAR_FILTERS_KEY

        return LISTENED_MUTATIONS.includes(mutation.type) || isStoreCalendarFiltersUserSettingMutation
    }
}).plugin
