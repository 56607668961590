import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import eventsService from '@/features/patient/domain/events.service'
import InsuranceService from '@/features/patient/domain/insurance.service'
import patientService from '@/features/patient/domain/patient.service'
import { setValueFromPath } from '@/utils/object'

import { TabCounter } from '../utils/tabCounter'
import {
    ACTION_FETCH_APPOINTMENT_PROPOSALS,
    ACTION_FETCH_CHECKIN_INFO,
    ACTION_FETCH_EVENT_DATA,
    ACTION_FETCH_FINANCIAL_SUMMARY,
    ACTION_FETCH_FUTURE_EVENT_DATA,
    ACTION_FETCH_INSURANCES,
    ACTION_FETCH_MERGE_PATIENT_DATA,
    ACTION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE,
    ACTION_FETCH_NEXT_EVENT_DATA,
    ACTION_FETCH_PATIENT_COUNTERS,
    ACTION_FETCH_PATIENT_DATA,
    ACTION_FETCH_PATIENT_REVIEW_STATUS,
    ACTION_MERGE_TWO_PATIENTS_WITH_OVERRIDES,
    ACTION_RESET_EVENT_STATE,
    ACTION_RESET_SELECTED_PATIENT_IDS,
    ACTION_SET_ALL_PATIENTS_SELECTED,
    ACTION_SET_PAGE_ALL_PATIENT_IDS,
    ACTION_SET_PATIENTS_PAGE_DATA,
    ACTION_SET_SELECTED_PATIENT_ID,
    ACTION_SET_TABLE_EDIT_STATE,
    ACTION_UNSET_SELECTED_PATIENT_ID,
    ACTION_UPDATE_PATIENT_DATA
} from './types/actions'
import {
    MUTATION_FETCH_APPOINTMENT_PROPOSALS,
    MUTATION_FETCH_CHECKIN_INFO,
    MUTATION_FETCH_EVENT_DATA,
    MUTATION_FETCH_EVENT_DATA_CLEAR,
    MUTATION_FETCH_FINANCIAL_SUMMARY,
    MUTATION_FETCH_FUTURE_EVENT_DATA,
    MUTATION_FETCH_INSURANCES,
    MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE,
    MUTATION_FETCH_NEXT_EVENT_DATA,
    MUTATION_FETCH_PATIENT_COUNTERS,
    MUTATION_FETCH_PATIENT_DATA,
    MUTATION_NOMORE_EVENT_DATA,
    MUTATION_RESET_SELECTED_PATIENT_IDS,
    MUTATION_SET_ALL_PATIENTS_SELECTED,
    MUTATION_SET_PAGE_ALL_PATIENT_IDS,
    MUTATION_SET_PATIENT_REVIEW_STATUS,
    MUTATION_SET_PATIENTS_PAGE_DATA,
    MUTATION_SET_SELECTED_PATIENT_ID,
    MUTATION_SET_TABLE_EDIT_STATE,
    MUTATION_UNSET_SELECTED_PATIENT_ID,
    MUTATION_UPDATE_PATIENT_DATA
} from './types/mutations'

const actions = {
    async [ACTION_UPDATE_PATIENT_DATA]({ commit, state }, data) {
        const { property, value } = data

        const updatedData = setValueFromPath({}, property, value)

        try {
            await patientService.updatePatient(state.patient.id, updatedData)
            commit(MUTATION_UPDATE_PATIENT_DATA, data)
        } catch (e) {}
    },
    async [ACTION_FETCH_PATIENT_DATA]({ commit, rootGetters }, id) {
        const data = await patientService.getPatient(id, {
            showNameBeforeSurname: rootGetters[AuthGetterTypes.ShowNameBeforeSurname]
        })
        commit(MUTATION_FETCH_PATIENT_DATA, data)
    },

    async [ACTION_FETCH_FINANCIAL_SUMMARY]({ commit }, patientId) {
        if (!patientId) {
            return
        }

        try {
            const visitSummary = await patientService.getFinancialSummary(patientId)

            commit(MUTATION_FETCH_FINANCIAL_SUMMARY, visitSummary)
        } catch {}
    },

    async [ACTION_FETCH_MERGE_PATIENT_DATA]({ rootGetters }, [patient1Id, patient2Id]) {
        const patient1 = await patientService.getPatient(patient1Id, {
            showNameBeforeSurname: rootGetters[AuthGetterTypes.ShowNameBeforeSurname]
        })
        const patient2 = await patientService.getPatient(patient2Id, {
            showNameBeforeSurname: rootGetters[AuthGetterTypes.ShowNameBeforeSurname]
        })

        const {
            conflicts,
            patientA: { pastAppointments: patient1PastAppointments },
            patientB: { pastAppointments: patient2PastAppointments }
        } = await patientService.getPatientComparisionForMerge(patient1Id, patient2Id)

        return {
            patients: [
                {
                    ...patient1,
                    pastAppointments: patient1PastAppointments
                },
                {
                    ...patient2,
                    pastAppointments: patient2PastAppointments
                }
            ],
            conflicts
        }
    },

    async [ACTION_MERGE_TWO_PATIENTS_WITH_OVERRIDES](_, { patient1Id, patient2Id, overrides }) {
        await patientService.mergePatients(patient1Id, patient2Id, overrides)
    },

    [ACTION_SET_SELECTED_PATIENT_ID]({ commit }, patientId) {
        commit(MUTATION_SET_SELECTED_PATIENT_ID, patientId)
    },
    [ACTION_UNSET_SELECTED_PATIENT_ID]({ commit }, patientId) {
        commit(MUTATION_UNSET_SELECTED_PATIENT_ID, patientId)
    },
    [ACTION_SET_PATIENTS_PAGE_DATA]({ commit }, payload) {
        commit(MUTATION_SET_PATIENTS_PAGE_DATA, payload)
    },
    [ACTION_SET_PAGE_ALL_PATIENT_IDS]({ commit }, payload) {
        commit(MUTATION_SET_PAGE_ALL_PATIENT_IDS, payload)
    },
    [ACTION_SET_ALL_PATIENTS_SELECTED]({ commit }) {
        commit(MUTATION_SET_ALL_PATIENTS_SELECTED)
    },
    [ACTION_RESET_SELECTED_PATIENT_IDS]({ commit }) {
        commit(MUTATION_RESET_SELECTED_PATIENT_IDS)
    },
    async [ACTION_FETCH_EVENT_DATA]({ commit }, { pageNumber, pageSize, patientId }) {
        const data = await eventsService.getEvents(
            {
                pageNumber,
                pageSize
            },
            patientId
        )

        const { page, lastPage } = data

        commit(MUTATION_FETCH_EVENT_DATA, page)

        commit(MUTATION_NOMORE_EVENT_DATA, lastPage)
    },
    [ACTION_SET_TABLE_EDIT_STATE]({ commit }, payload) {
        commit(MUTATION_SET_TABLE_EDIT_STATE, payload)
    },
    async [ACTION_FETCH_FUTURE_EVENT_DATA]({ commit }, { pageNumber, pageSize, patientId }) {
        const data = await eventsService.getFutureEvents(
            {
                pageNumber,
                pageSize
            },
            patientId
        )

        const formattedData = {
            count: data.total,
            items: data.page
        }

        commit(MUTATION_FETCH_FUTURE_EVENT_DATA, formattedData)
    },

    async [ACTION_FETCH_NEXT_EVENT_DATA]({ commit }, { patientId }) {
        if (!patientId) {
            return
        }

        const data = await eventsService.getNextEvent(patientId)

        commit(MUTATION_FETCH_NEXT_EVENT_DATA, data)
    },

    async [ACTION_FETCH_CHECKIN_INFO]({ commit }, checkinId) {
        const data = await patientService.getCheckinInfo(checkinId)

        commit(MUTATION_FETCH_CHECKIN_INFO, data)
    },

    async [ACTION_FETCH_INSURANCES]({ commit }) {
        try {
            const insurances = await InsuranceService.getAllInsurances()

            commit(MUTATION_FETCH_INSURANCES, insurances)
        } catch {}
    },

    async [ACTION_RESET_EVENT_STATE]({ commit }) {
        commit(MUTATION_FETCH_EVENT_DATA_CLEAR)
    },

    async [ACTION_FETCH_PATIENT_COUNTERS]({ commit }, patientId) {
        const tabCounter = new TabCounter()

        const { episodesCount, filesCount, financialCount, visitsCount } = await patientService.getPatientTabsCounts(
            patientId
        )

        tabCounter.setEventCounter(visitsCount)
        tabCounter.setEpisodeCounter(episodesCount)
        tabCounter.setFileCounter(filesCount)
        tabCounter.setPaymentCounter(financialCount)

        commit(MUTATION_FETCH_PATIENT_COUNTERS, tabCounter.getAll())
    },

    async [ACTION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE]({ commit }, patientId) {
        if (!patientId) {
            return
        }

        const event = await eventsService.getNearestEventWithoutEpisode(patientId)

        commit(MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE, event)
    },

    async [ACTION_FETCH_PATIENT_REVIEW_STATUS]({ commit }, patientId) {
        if (!patientId) return

        try {
            const data = await patientService.getPatientReviewStatus(patientId)
            commit(MUTATION_SET_PATIENT_REVIEW_STATUS, data)
        } catch (e) {}
    },

    async [ACTION_FETCH_APPOINTMENT_PROPOSALS]({ commit }, patientId) {
        if (!patientId) {
            return
        }

        const data = await patientService.getProposals(patientId)

        commit(MUTATION_FETCH_APPOINTMENT_PROPOSALS, data)
    }
}

export default actions
