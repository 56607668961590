import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { URL_DATA_VISITS_REPORT_DE } from '../domain/constans/reports.constants'
import { CashReportCategory, ReportExportType, ReportPathname, ReportType } from '../domain/enums/reports.enums'
import { ReportsData, ReportsDataPage, ReportsHeader, ReportsParameters } from '../domain/interfaces/reports.interfaces'
import { isAppointmentCategory } from '../ui/utils/isAppointmentCategory'

const URL_BASE_REPORTS = 'reports'
const URL_BASE_EXPENSES = 'expenses'

const URL_REPORTS_HEADERS = `${URL_BASE_REPORTS}/:reportType/headers`
const URL_REPORTS_DATA = `${URL_BASE_REPORTS}/data`
const URL_REPORTS_DATA_DE = `${URL_BASE_REPORTS}/visits`
const URL_REPORTS_DATA_CASH_REGISTER = `${URL_BASE_REPORTS}/datacashregister`
const URL_REPORTS_EXPORT = `${URL_BASE_REPORTS}/:exportType/export`

const URL_CASH_REPORT_APPOINTMENT = `${URL_BASE_REPORTS}/appointment`
const URL_CASH_REPORT_APPOINTMENT_AMOUNT_PAID = `${URL_CASH_REPORT_APPOINTMENT}/:cashReportId/amount-paid/:amountPaid`
const URL_CASH_REPORT_PAYMENT_STATUS_STATUS_ID = ':cashReportId/payment-status/:paymentStatusId'
const URL_CASH_REPORT_PAYMENT_METHOD = ':cashReportId/paymentmethod'
const URL_CASH_REPORT_PAYMENT_METHOD_ID = `${URL_CASH_REPORT_PAYMENT_METHOD}/:paymentMethodId`
const URL_CASH_REPORT_VOUCHER = `${URL_BASE_REPORTS}/voucher`
const URL_CASH_REPORT_VOUCHER_AMOUNT_PAID = `${URL_CASH_REPORT_VOUCHER}/:cashReportId/amount-paid/:amountPaid`

const URL_EXPENSES_DATA = `${URL_BASE_EXPENSES}/data`
const URL_EXPENSES_HEADERS = `${URL_BASE_EXPENSES}/headers`

const URLS_DATA = {
    [ReportPathname.Visits as string]: URL_REPORTS_DATA,
    [URL_DATA_VISITS_REPORT_DE]: URL_REPORTS_DATA_DE,
    [ReportPathname.Cash as string]: URL_REPORTS_DATA_CASH_REGISTER,
    [ReportPathname.Expenses as string]: URL_EXPENSES_DATA
}

const mapHeaders = (header: ReportsHeader) => ({
    ...header,
    order: header.order || 0
})

class ReportsRepository {
    cancelGetData = () => {}

    async getHeaders(reportType: ReportType = ReportType.Reports): Promise<ReportsHeader[]> {
        let url = getUrlFromTemplate({
            template: URL_REPORTS_HEADERS,
            params: {
                reportType
            }
        })

        if (reportType === ReportType.Expenses) {
            url = URL_EXPENSES_HEADERS
        }

        const { data } = await httpAPI.get(url)
        return data.map(mapHeaders)
    }

    async updateHeaders(
        headers: ReportsHeader[],
        reportType: ReportType = ReportType.Reports
    ): Promise<ReportsHeader[]> {
        const url = getUrlFromTemplate({
            template: URL_REPORTS_HEADERS,
            params: {
                reportType
            }
        })
        const { data } = await httpAPI.put(url, headers)
        return data
    }

    async updatePaymentMethod(
        cashReportId: string,
        paymentMethodId: string,
        categoryId: number
    ): Promise<ReportsDataPage> {
        const cashReportCategoryUrl = isAppointmentCategory(categoryId)
            ? URL_CASH_REPORT_APPOINTMENT
            : URL_CASH_REPORT_VOUCHER

        const url = getUrlFromTemplate({
            template: `${cashReportCategoryUrl}/${URL_CASH_REPORT_PAYMENT_METHOD_ID}`,
            params: {
                cashReportId,
                paymentMethodId
            }
        })

        const { data } = await httpAPI.put(url)
        return data
    }

    async updateAmountPaid(cashReportId: string, amountPaid: number, categoryId: number): Promise<ReportsDataPage> {
        const cashReportCategoryUrl = isAppointmentCategory(categoryId)
            ? URL_CASH_REPORT_APPOINTMENT_AMOUNT_PAID
            : URL_CASH_REPORT_VOUCHER_AMOUNT_PAID
        const url = getUrlFromTemplate({
            template: cashReportCategoryUrl,
            params: {
                cashReportId,
                amountPaid
            }
        })

        const { data } = await httpAPI.put(url)

        return data
    }

    async updatePaymentStatus(
        cashReportId: string,
        paymentStatusId: string,
        categoryId: number
    ): Promise<ReportsDataPage> {
        const cashReportCategoryUrl = isAppointmentCategory(categoryId)
            ? URL_CASH_REPORT_APPOINTMENT
            : URL_CASH_REPORT_VOUCHER

        const url = getUrlFromTemplate({
            template: `${cashReportCategoryUrl}/${URL_CASH_REPORT_PAYMENT_STATUS_STATUS_ID}`,
            params: {
                cashReportId,
                paymentStatusId
            }
        })

        const { data } = await httpAPI.put(url)
        return data
    }

    async deletePaymentMethod(cashReportId: string, categoryId: number): Promise<ReportsDataPage> {
        const cashReportCategoryUrl = isAppointmentCategory(categoryId)
            ? URL_CASH_REPORT_APPOINTMENT
            : URL_CASH_REPORT_VOUCHER

        const url = getUrlFromTemplate({
            template: `${cashReportCategoryUrl}/${URL_CASH_REPORT_PAYMENT_METHOD}`,
            params: {
                cashReportId
            }
        })

        const { data } = await httpAPI.delete(url)
        return data
    }

    async getData(args: ReportsParameters, reportPathName: string): Promise<ReportsData> {
        this.cancelGetData()
        const url = URLS_DATA[reportPathName]
        const { data } = await httpAPI.post(url, args, {
            cancelToken: httpAPI.createCancelToken((cancelExecutor: any) => {
                this.cancelGetData = cancelExecutor
            })
        })

        return data
    }

    async getExportDocument(exportType: ReportExportType, args: ReportsParameters): Promise<Blob> {
        const url = getUrlFromTemplate({
            template: URL_REPORTS_EXPORT,
            params: {
                exportType: exportType.toString()
            }
        })
        const { data } = await httpAPI.post(url, args, {
            responseType: 'blob'
        })
        return data
    }
}

export default new ReportsRepository()
