import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { DoctorInvitationInterface } from '@/features/doctorInvitation/domain/doctorInvitation.interface'
import { DoctorAddress } from '@/features/settings/domain/interfaces/addresses.interfaces'

const URL_BASE = 'doctor-invitation'
const URL_REMOVE_DOCTOR_INVITATION = `${URL_BASE}/:invitationId`
const URL_RESEND_DOCTOR_INVITATION = `${URL_BASE}/:invitationId/resent`
const URL_ACCEPT_DOCTOR_INVITATION_WITH_EXISTING_ADDRESS = `${URL_BASE}/:invitationId/accept-existing`
const URL_ACCEPT_DOCTOR_INVITATION_WITH_NEW_ADDRESS = `${URL_BASE}/:invitationId/accept-new`
const URL_GET_SPECIALIST_LIST = `${URL_BASE}/list`
const URL_GET_DOCTOR_INVITATION_ADDRESSES = `${URL_BASE}/addresses`

class DoctorInvitationRepository {
    async createDoctorInvitations(emails: string[], addressIds: string[]): Promise<any> {
        const { data } = await httpAPI.post(URL_BASE, {
            emails,
            docplannerClinicAddressIds: addressIds
        })

        return data
    }

    async removeDoctorInvitation(invitationId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REMOVE_DOCTOR_INVITATION,
            params: { invitationId }
        })

        await httpAPI.delete(url)
    }

    async resendDoctorInvitation(invitationId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_RESEND_DOCTOR_INVITATION,
            params: { invitationId }
        })

        return httpAPI.post(url)
    }

    async getDoctorInvitation(invitationId: string): Promise<DoctorInvitationInterface> {
        const url = `${URL_BASE}?invitationId=${invitationId}`
        const { data } = await httpAPI.get(url)
        return data
    }

    async acceptDoctorInvitation(invitationId: string, docplannerDoctorAddressId: string | null): Promise<void> {
        const baserUrl = docplannerDoctorAddressId
            ? URL_ACCEPT_DOCTOR_INVITATION_WITH_EXISTING_ADDRESS
            : URL_ACCEPT_DOCTOR_INVITATION_WITH_NEW_ADDRESS
        const url = getUrlFromTemplate({
            template: baserUrl,
            params: { invitationId }
        })

        return httpAPI.post(url, { docplannerDoctorAddressId })
    }

    async getSpecialistList(): Promise<void> {
        const { data } = await httpAPI.get(URL_GET_SPECIALIST_LIST)
        return data
    }

    async getDoctorInvitationAddresses(invitationId: string): Promise<DoctorAddress[]> {
        const url = `${URL_GET_DOCTOR_INVITATION_ADDRESSES}?invitationId=${invitationId}`
        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new DoctorInvitationRepository()
