import isEqual from 'lodash/isEqual'

import { PaymentStatus } from '@/enums/paymentStatus.enums'
import CalendarAppointmentsService from '@/features/calendar/domain/calendar.appointments.service'
import {
    SecretaryPaymentMethod,
    SecretaryViewFilters
} from '@/features/calendar/domain/interfaces/secretaryView.interfaces'
import SecretaryViewService from '@/features/calendar/domain/secretaryView.service'
import { getPaymentMethodCellData } from '@/features/calendar/ui/utils/getPaymentMethodCellData'
import patientService from '@/features/patient/domain/patient.service'
import { ActionsAdaptor } from '@/interfaces'

import { ActionTypes, MutationTypes, SecretaryViewActions, SecretaryViewMutations } from './types'
import { SecretaryViewState } from './types/state'

export const actions: ActionsAdaptor<SecretaryViewActions, SecretaryViewMutations, SecretaryViewState> = {
    async [ActionTypes.FetchSecretaryAppointments]({ commit, state }, { selectedDate }) {
        const fetchedAppointments = await SecretaryViewService.getRows(selectedDate, state.filters)

        if (!isEqual(state.appointments, fetchedAppointments)) {
            commit(MutationTypes.SetSecretaryViewAppointments, fetchedAppointments)
        }
    },

    async [ActionTypes.FetchSecretaryHeaders]({ commit }) {
        const headers = await SecretaryViewService.getHeaders()
        commit(MutationTypes.SetSecretaryViewHeaders, headers)
    },

    async [ActionTypes.FetchPaymentMethods]({ commit }) {
        const paymentMethods = await SecretaryViewService.getPaymentMethods()
        commit(MutationTypes.SetPaymentMethods, paymentMethods)
    },

    async [ActionTypes.SaveColumns]({ state }) {
        await SecretaryViewService.saveColumns(state.headers)
    },

    [ActionTypes.ChangeColumnVisibility]({ commit, state }, { tag, hidden }) {
        const headers = SecretaryViewService.changeColumnVisibility(state.headers, tag, hidden)

        commit(MutationTypes.SetSecretaryViewHeaders, headers)
    },

    // This action is triggered by signalR after receiving a notificaton
    [ActionTypes.UpdateCheckingStatus](
        { state: { appointments }, commit },
        { appointmentId, attendance, patientArrivalTime, patientExitTime }
    ) {
        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? {
                      ...appointment,
                      attendance,
                      patientArrivalTime,
                      patientExitTime
                  }
                : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },

    async [ActionTypes.UpdatePriceAction]({ state: { appointments }, commit }, { appointmentId, price }) {
        const {
            paymentStatus,
            paymentMethod: paymentMethodInfo,
            price: priceUpdated
        } = await CalendarAppointmentsService.updateVisitPrice({
            visitId: appointmentId,
            priceValue: price
        })
        let paymentMethod = null as SecretaryPaymentMethod | null

        if (paymentMethodInfo) {
            paymentMethod = getPaymentMethodCellData(paymentMethodInfo)
        }

        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? {
                      ...appointment,
                      price: priceUpdated,
                      paymentStatus,
                      paymentMethod
                  }
                : appointment
        )
        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },

    async [ActionTypes.UpdatePaymentMethod](
        { state: { appointments }, commit },
        { appointmentId, paymentMethodId, paymentMethod }
    ) {
        const { paymentStatus, amountPaid } = await SecretaryViewService.updatePaymentMethods(
            appointmentId,
            paymentMethodId
        )
        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? { ...appointment, paymentMethod, paymentStatus, amountPaid }
                : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },

    async [ActionTypes.DeletePaymentMethod]({ state: { appointments }, commit }, appointmentId) {
        await SecretaryViewService.deletePaymentMethods(appointmentId)
        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? {
                      ...appointment,
                      paymentMethod: null,
                      paymentStatus: PaymentStatus.Unpaid
                  }
                : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },
    async [ActionTypes.UpdatePaymentStatus]({ state: { appointments }, commit }, { appointmentId, paymentStatusId }) {
        const { paymentStatus, paymentMethod, amountPaid } = await SecretaryViewService.updatePaymentStatus(
            appointmentId,
            paymentStatusId
        )

        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? {
                      ...appointment,
                      paymentStatus,
                      paymentMethod,
                      amountPaid
                  }
                : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },
    async [ActionTypes.DeleteAppointment]({ dispatch }, appointmentId) {
        await SecretaryViewService.deleteAppointment(appointmentId)

        dispatch(ActionTypes.RemoveAppointment, appointmentId)
    },
    async [ActionTypes.UpdatePatientPhoneNumber](
        { state: { appointments }, commit },
        { appointmentId, patientId, patientPhoneNumber }
    ) {
        await patientService.updatePatient(patientId, {
            phone: patientPhoneNumber
        })
        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId ? { ...appointment, patientPhoneNumber } : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },
    async [ActionTypes.UpdateAppointmentComment]({ state: { appointments }, commit }, { appointmentId, comment }) {
        await SecretaryViewService.updateAppointmentComment(appointmentId, comment)
        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId ? { ...appointment, comments: comment } : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },

    async [ActionTypes.UpdateServices]({ state: { appointments }, commit }, { appointmentId, servicesUpdate }) {
        const { eventServices, price, endDateTime } = await SecretaryViewService.updateAppointmentServices(
            appointmentId,
            servicesUpdate
        )

        const appointmentsUpdated = appointments.map(appointment =>
            appointment.id === appointmentId
                ? {
                      ...appointment,
                      eventServices,
                      price,
                      endDateTime
                  }
                : appointment
        )

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    },

    async [ActionTypes.ConfirmAppointment]({ commit }, appointmentId) {
        const { status, bookingStatusTooltip } = await SecretaryViewService.confirmAppointment(appointmentId)
        commit(MutationTypes.UpdateSecretaryViewAppointment, { appointmentId, status, bookingStatusTooltip })
    },

    [ActionTypes.RemoveAppointment]({ state: { appointments }, commit }, appointmentId) {
        const appointmentsUpdated = appointments.filter(({ id }) => id !== appointmentId)

        commit(MutationTypes.SetSecretaryViewAppointments, appointmentsUpdated)
    }
}
