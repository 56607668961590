const state = {
    selectedDate: null,
    features: null,
    culture: null,
    appState: false,
    countrySettings: null,
    authProvider: null
}

export default state
