import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { EpisodeEvent, PatientEvent } from '@/features/patient/domain/interfaces/event.interface'

import { ListPageRequest } from './interfaces/patient.interfaces'

const URL_BASE = 'v2/patients/:patientId/events'

const CURRENT_DATE = new Date().toISOString()
class EventsRepository {
    async getEvents(page: ListPageRequest, patientId: string): Promise<EpisodeEvent[]> {
        const url = getUrlFromTemplate({
            template: URL_BASE,
            params: { patientId }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                page: page.pageNumber,
                pageSize: page.pageSize,
                olderThan: CURRENT_DATE
            }
        })

        return data
    }

    async getNextEvent(patientId: string): Promise<PatientEvent> {
        const url = getUrlFromTemplate({
            template: URL_BASE,
            params: { patientId }
        })

        const {
            data: { page }
        } = await httpAPI.get(url, {
            params: {
                page: 0,
                pageSize: 1,
                newerThan: CURRENT_DATE,
                orderingDirection: 'asc',
                notCanceled: true
            }
        })

        return page[0]
    }

    async getEventsForDates(patientId: string, newerThan: string, olderThan: string): Promise<PatientEvent[]> {
        const url = getUrlFromTemplate({
            template: URL_BASE,
            params: { patientId }
        })

        const {
            data: { page }
        } = await httpAPI.get(url, {
            params: {
                newerThan,
                olderThan
            }
        })

        return page
    }

    async getFutureEvents(page: ListPageRequest, patientId: string): Promise<EpisodeEvent[]> {
        const url = getUrlFromTemplate({
            template: URL_BASE,
            params: { patientId }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                page: page.pageNumber,
                pageSize: page.pageSize,
                newerThan: CURRENT_DATE,
                orderingDirection: 'asc'
            }
        })

        return data
    }
}

export default new EventsRepository()
