import { ChatType } from '@/features/chat/domain/enums/chat.enums'
import { SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE } from '@/features/chat-overlay/ui/constants/constants'
import { APPOINTMENT_BOOKING_TEMPLATE } from '@/features/settings/ui/constants/templates.constants'
import Storage from '@/store/storage'

import { QUICK_REMINDERS, QUICK_REPLIES_TYPES } from '../constants/chat.constants'
import { ChatState } from './types/state'

const saasInternalDisclaimer = Storage.get(SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE) || false

export const getDefaultState = (): ChatState => ({
    isGlobalBlockMessageActive: false,
    unreadConversations: {
        [ChatType.PatientChat]: 0,
        [ChatType.SaasInternalChat]: []
    },
    chatTemplates: null,
    isAcceptedSaasInternalChatDisclaimer: Boolean(saasInternalDisclaimer),
    patientHasUnread: {},
    quickReminders: [
        {
            id: QUICK_REMINDERS.ATTENDANCE_CONFIRMATION,
            type: QUICK_REPLIES_TYPES.TEMPLATE_FUTURE_EVENT,
            title: 'chat-for-doctors-send-quick-reminders-confirm-appointment',
            icon: 'verified',
            isReminder: true,
            isEnabled: true,
            trackingType: 'confirmation'
        },
        {
            id: APPOINTMENT_BOOKING_TEMPLATE,
            type: QUICK_REPLIES_TYPES.TEMPLATE_ONLINE_BOOKING,
            title: 'mobile-send-message-templates-next-time-book-online-title',
            isReminder: true,
            isEnabled: true,
            icon: 'calendar',
            trackingType: 'booking'
        }
    ],
    sessionToken: {
        expiresAt: '',
        sessionToken: ''
    },
    isContactFromProfileAllowed: null
})

export const state = getDefaultState()
