import { parse, stringify } from 'query-string'

import { PRODUCTION } from '@/bootstrap/environment'
import { SSO_LOGIN_STATE, SSO_RENEW_STATE } from '@/features/auth/domain/constants/auth.constants'
import store from '@/store'
import Storage from '@/store/storage'
import * as STORAGE from '@/store/storage-types'
import { apiMkplUrl, getDecodedUrl, redirectUri } from '@/utils/url'

const routeBase = '/#'
const paramsToExpect = ['redirectUrl', 'redirect_uri']
const MKPL_LOGOUT_PATH = '/auth/logout'

const getExpectedParam = params => {
    return paramsToExpect.find(item => {
        return params[item]
    })
}

const getRedirectURL = to => {
    let redirectTo = Storage.get(STORAGE.REDIRECT_URL)

    if (redirectTo) {
        Storage.remove(STORAGE.REDIRECT_URL)
        return redirectTo
    }

    if (location.hash) {
        const search = location.hash.split('?')[1]

        if (search) {
            const params = parse(search)

            redirectTo = params[getExpectedParam(params)]
        }
    }

    return redirectTo
}

const getPathMatched = to => {
    return to.params.pathMatch
}

const getRedirectPath = to => {
    let redirectTo = to.fullPath
    const redirection = getRedirectURL(to)

    if (redirection) {
        redirectTo = redirection
    }

    return getDecodedUrl(redirectTo)
}

const getProviderPath = () => {
    const { url_login: urlLogin, client_id: clientId, scope, response_type: responseType } = store.getters.authProvider

    const state = SSO_LOGIN_STATE
    const qs = stringify({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        scope,
        state
    })

    return `${urlLogin}?${qs}`
}

const getRenewTokenPath = () => {
    return getProviderPath().replace(SSO_LOGIN_STATE, SSO_RENEW_STATE)
}

const goToProviderPath = () => {
    window.location.href = getProviderPath()
}

const getApiLogoutPath = to => {
    const { url_logout: urlLogout } = store.getters.authProvider
    const ssoLoginWithSaaSRedirect = getProviderPath()
    const ssoLogoutPath = `${urlLogout}?redirect_uri=${encodeURIComponent(ssoLoginWithSaaSRedirect)}`
    const mkplLogoutPath = `${apiMkplUrl}${MKPL_LOGOUT_PATH}?redirect_uri=${encodeURIComponent(ssoLogoutPath)}`

    return mkplLogoutPath
}

const ID_MATCHER = /^\d+$/
const DATE_MATCHER = /\d{1,2}-\d{1,2}-\d{2,4}/
const GUID_MATCHER = /[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/
const EMAIL_MATCHER = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
const PART_MATCHERS = [ID_MATCHER, DATE_MATCHER, GUID_MATCHER, EMAIL_MATCHER]

const getViewNameForDataDog = to => {
    let viewName = to.path

    const parts = viewName.split('/')
    const replaced = parts.map(part => (PART_MATCHERS.some(matcher => matcher.test(part)) ? '?' : part))
    viewName = replaced.join('/')

    return viewName
}

export {
    getApiLogoutPath,
    getDecodedUrl,
    getExpectedParam,
    getPathMatched,
    getProviderPath,
    getRedirectPath,
    getRedirectURL,
    getRenewTokenPath,
    getViewNameForDataDog,
    goToProviderPath,
    paramsToExpect,
    routeBase
}
