import { DateFormat, formatDate, toDate } from '@/core/ui/utils'
import { CalendarSearchResultType } from '@/features/calendar/domain/enums/calendarSearch.enums'
import { AppointmentEventData } from '@/features/calendar/domain/interfaces/appointment.interfaces'
import { Proposal, ProposalResponse } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import {
    IPatientRequestOptions,
    MassiveDeleteRequest,
    PatientAppointmentsParams,
    PatientListPage,
    UTMParameters
} from '@/features/patient/api/interfaces/patient.interfaces'
import { APPOINTMENT_STATUS, ATTENDANCE_STATUS } from '@/features/patient/ui/constants/events.constants'
import { decodeFileName } from '@/utils/file'

import repository from '../api/patient.api'
import { PatientSlotsQuery } from '../api/types/patient.types'
import { PAGE_SIZE, PAID_STATUS, UNPAID_STATUS } from '../ui/constants/finance.constants'
import { PaymentStatusFilterKeys } from '../ui/enums/patient.enums'
import { getPatientFullName } from '../ui/utils/getPatientFullName'
import { PatientGender, PatientStatus } from './enums/patient-options.enums'
import {
    CheckinInfo,
    CreatePatient,
    IFinancialSummaryResponse,
    IFinancialVisit,
    IFinancialVisitPagedResponse,
    IFinancialVisitResponse,
    IPatientExtended,
    ISearchResultOptions,
    PatientAvailableSlots,
    PatientConflicts,
    PatientContactInformation,
    PatientEvent,
    PatientListPageRequest,
    PatientLog,
    PatientLogFilter,
    PatientReviewStatus,
    PatientScreenshot,
    PatientScreenshotProps,
    PatientSearchAppointmentEntity,
    PatientSearchResultsEntity,
    PatientSearchWithAppointmentsEntity,
    SearchCriteria,
    SearchPatient,
    SearchResultItem
} from './interfaces/patient.interfaces'

const SEARCH_DUPLICATION_LIMIT = 1
const MERGE_SIMILIARITY = 0.9
const DELAY_SEND_MESSAGE = 300
const CURRENT_DATE = formatDate(new Date(), DateFormat.DateStringNoUtcOffset)

const DefaultPatientAppointmentsParams = {
    date: CURRENT_DATE,
    notCanceled: true,
    page: 0,
    pageSize: 1
}
class PatientService {
    async getPatients(
        page: PatientListPageRequest,
        options: IPatientRequestOptions = { showNameBeforeSurname: false }
    ): Promise<PatientListPage> {
        const patientResponse = await repository.getPatients(page)
        const foundPatients = patientResponse?.page || []

        const patients = foundPatients.map(patient => ({
            ...patient,
            fullName: getPatientFullName({
                patient,
                showNameBeforeSurname: options?.showNameBeforeSurname || false
            })
        }))

        return {
            ...patientResponse,
            page: patients
        }
    }

    async getPatient(
        patientId: number,
        options: IPatientRequestOptions = { showNameBeforeSurname: false }
    ): Promise<IPatientExtended> {
        if (!patientId) {
            return Promise.reject(new Error('Invalid patientId'))
        }

        const foundPatient = await repository.getPatient(patientId)

        if (foundPatient) {
            return {
                ...foundPatient,
                fullName: getPatientFullName({
                    patient: foundPatient,
                    showNameBeforeSurname: options?.showNameBeforeSurname || false
                })
            }
        }

        return foundPatient
    }

    createPatient(patient: CreatePatient): Promise<number> {
        return repository.createPatient(patient)
    }

    updatePatient(patientId: number, data: any): Promise<string> {
        return repository.updatePatient(patientId, data)
    }

    requestPatientReview(patientId: number): Promise<any> {
        return repository.requestPatientReview(patientId)
    }

    getPatientPicture(patientId: number): Promise<string> {
        return repository.getPatientPicture(patientId)
    }

    changePatientPicture(patientId: number, file: File): Promise<any> {
        const decodedName = decodeFileName(file.name)
        return repository.changePatientPicture(patientId, decodedName, file)
    }

    async getFinancialVisits(
        patientId: number,
        pageIndex: number,
        pageSize: number = PAGE_SIZE
    ): Promise<IFinancialVisitPagedResponse> {
        const response: IFinancialVisitResponse = await repository.getFinancialVisits(patientId, pageIndex, pageSize)

        const unpaidVisits = response.page.filter(
            ({ paymentStatus }) => paymentStatus?.filterKey === PaymentStatusFilterKeys.Unpaid
        )

        const paidVisits = response.page.filter(
            ({ paymentStatus }) => paymentStatus?.filterKey === PaymentStatusFilterKeys.Paid
        )

        const hasUnpaidVisits = unpaidVisits.length > 0
        const hasHeaderType: any = {}
        const isFirstPage = pageIndex === 0
        response.page = [...unpaidVisits, ...paidVisits]

        const rows = response.page.reduce((output: any, row: any, index: number): any => {
            const previousRow: any = output[output.length - 1] || null
            const rowFilterKey = row?.paymentStatus?.filterKey
            const filterKeyIsDifferent =
                previousRow && previousRow?.paymentStatus?.filterKey !== row?.paymentStatus?.filterKey
            const isFirstAndHasUnPaidVisits = index === 0 && hasUnpaidVisits && isFirstPage
            const isTopOfPageAndDifferentGroup = unpaidVisits.length % pageSize === 0 && pageIndex !== 0

            if (
                !hasHeaderType[rowFilterKey] &&
                (filterKeyIsDifferent || isFirstAndHasUnPaidVisits || isTopOfPageAndDifferentGroup)
            ) {
                Object.keys(hasHeaderType).forEach(key => {
                    hasHeaderType[key] = false
                })

                hasHeaderType[rowFilterKey] = true
                return [
                    ...output,
                    {
                        id: `header-${index}`,
                        isHeader: true,
                        paymentStatus:
                            row.paymentStatus?.filterKey === PaymentStatusFilterKeys.Unpaid
                                ? UNPAID_STATUS
                                : PAID_STATUS
                    },
                    row
                ]
            }
            return [...output, row]
        }, [])

        return {
            rows,
            payload: response
        }
    }

    getFinancialSummary(patientId: number): Promise<IFinancialSummaryResponse> {
        return repository.getFinancialSummary(patientId)
    }

    getFinancialVisitById(patientId: number, visitId: number): Promise<IFinancialVisit> {
        return repository.getFinancialVisitById(patientId, visitId)
    }

    searchSuggestions(patient: SearchCriteria): Promise<SearchResultItem[]> {
        return repository.searchSuggestions(patient)
    }

    async searchWithAppointments(filter: string, results: number): Promise<PatientSearchResultsEntity[]> {
        const patients = await repository.searchWithAppointments(filter, results)
        const appointments = patients.reduce(
            (
                acc: PatientSearchAppointmentEntity[],
                {
                    firstName,
                    lastName,
                    referenceId,
                    nextAppointment,
                    previousAppointment
                }: PatientSearchWithAppointmentsEntity
            ) => {
                let previousAppointmentData: PatientSearchAppointmentEntity[] = []
                let nextAppointmentData: PatientSearchAppointmentEntity[] = []

                if (previousAppointment) {
                    previousAppointmentData = [
                        {
                            ...previousAppointment,
                            firstName,
                            lastName,
                            referenceId
                        }
                    ]
                }

                if (nextAppointment) {
                    nextAppointmentData = [
                        {
                            ...nextAppointment,
                            firstName,
                            lastName,
                            referenceId
                        }
                    ]
                }

                return [...acc, ...previousAppointmentData, ...nextAppointmentData]
            },
            []
        )

        return [
            {
                type: CalendarSearchResultType.Patient,
                values: patients
            },
            {
                type: CalendarSearchResultType.Appointment,
                values: appointments
            }
        ]
    }

    searchSuggestionsFilter(filter: string, options?: ISearchResultOptions): Promise<SearchPatient[]> {
        return repository.searchSuggestionsFilter(filter, options)
    }

    async canImportPatients(): Promise<boolean> {
        const facilitites = await repository.getFacilitiesWhereCanImport()
        return facilitites && facilitites.length > 0
    }

    getCheckinInfo(checkinId: string): Promise<CheckinInfo> {
        return repository.getCheckinInfo(checkinId)
    }

    deletePatient(patientId: number): Promise<any> {
        return repository.deletePatient(patientId)
    }

    deletePatients(patientsIds: string[]): Promise<void> {
        return repository.deletePatients(patientsIds)
    }

    massiveDelete(payload: MassiveDeleteRequest): Promise<void> {
        return repository.massiveDelete(payload)
    }

    isPatientDeceased(patientStatus: number): boolean {
        return patientStatus === PatientStatus.Deceased
    }

    isPatientNonBinary(gender: number): boolean {
        return gender === PatientGender.NonBinary
    }

    getPatientComparisionForMerge(patient1Id: string, patient2Id: string): Promise<PatientConflicts> {
        return repository.getPatientComparisionForMerge(patient1Id, patient2Id)
    }

    mergePatients(patient1Id: string, patient2Id: string, overrides: string[]): Promise<any> {
        return repository.mergePatients(patient1Id, patient2Id, overrides)
    }

    getLogs(patientId: number, filter: PatientLogFilter): Promise<PatientLog[]> {
        return repository.getLogs(patientId, filter)
    }

    getLogsPatient(patientId: number, filter: PatientLogFilter): Promise<PatientLog[]> {
        return repository.getLogsPatient(patientId, filter)
    }

    blockOnlineBooking(patientId: number): Promise<any> {
        return repository.blockOnlineBooking(patientId)
    }

    unBlockOnlineBooking(patientId: number): Promise<any> {
        return repository.unBlockOnlineBooking(patientId)
    }

    blockConversationOption(patientId: number): Promise<any> {
        return repository.blockConversationOption(patientId)
    }

    unblockConversationOption(patientId: number): Promise<any> {
        return repository.unblockConversationOption(patientId)
    }

    getProposals(patientId: number): Promise<any> {
        return repository.getProposals(patientId)
    }

    deleteProposal(patientId: number, proposalId: number): Promise<any> {
        return repository.deleteProposal(patientId, proposalId)
    }

    updateProposal(proposalId: number, patientId: number, proposal: Proposal): Promise<ProposalResponse> {
        return repository.updateProposal(proposalId, patientId, proposal)
    }

    createProposal(patientId: number, proposal: Proposal): Promise<ProposalResponse> {
        return repository.createProposal(patientId, proposal)
    }

    savePatientScreenshot(props: PatientScreenshotProps): Promise<PatientScreenshot> {
        return repository.savePatientScreenshot(props)
    }

    getEventBasicInfo(patientId: number, eventId: number, utm?: UTMParameters): Promise<PatientEvent> {
        return repository.getEventBasicInfo(patientId, eventId, utm)
    }

    async sendChatMessages(message: string, recipes: Array<{ id: number; name: string }>): Promise<string[]> {
        const patientNamesNoSentMessage = [] as string[]

        await Promise.all(
            recipes.map(async ({ id, name }, index) => {
                try {
                    await new Promise(resolve => setTimeout(resolve, index * DELAY_SEND_MESSAGE))
                    await repository.sendChatMessage(id, message)
                } catch {
                    patientNamesNoSentMessage.push(name)
                }
            })
        )

        return patientNamesNoSentMessage
    }

    async getNextPrevAvailableSlotByPatient(
        patientId: number,
        params?: PatientSlotsQuery
    ): Promise<PatientAvailableSlots | undefined> {
        const data = await repository.getNextPrevAvailableSlotByPatient(patientId, params)

        if (!data) {
            return undefined
        }

        return {
            ...data,
            end: toDate(data.end),
            start: toDate(data.start)
        }
    }

    async getPatientContactInformation(patientId: number): Promise<PatientContactInformation> {
        return repository.getPatientContactInformation(patientId)
    }

    async getNextAppointment(patientId: number): Promise<AppointmentEventData> {
        const params: PatientAppointmentsParams = {
            ...DefaultPatientAppointmentsParams,
            eventTime: 'newerThan',
            orderingDirection: 'asc'
        }

        const { page: [appointment] = [] } = await repository.getPatientAppointments(patientId, params)
        return appointment
    }

    async getPrevAppointment(patientId: number): Promise<AppointmentEventData> {
        const params: PatientAppointmentsParams = {
            ...DefaultPatientAppointmentsParams,
            eventTime: 'olderThan',
            orderingDirection: 'desc'
        }

        const { page: [appointment] = [] } = await repository.getPatientAppointments(patientId, params)
        return appointment
    }

    async getPatientDocumentationData(patientId: number): Promise<Record<string, string>> {
        return repository.getPatientDocumentationData(patientId)
    }

    getPatientTabsCounts(patientId: number): Promise<Record<string, number>> {
        return repository.getPatientTabsCounts(patientId)
    }

    visitHasStatusCancelled(attendance: number, status: number): boolean {
        return (
            attendance === ATTENDANCE_STATUS.NOT_VISITED ||
            status === APPOINTMENT_STATUS.CANCELED_BY_USER ||
            status === APPOINTMENT_STATUS.CANCELED_BY_PATIENT
        )
    }

    visitHasStatusVisited(attendance: number, status: number): boolean {
        return (
            attendance === ATTENDANCE_STATUS.VISITED &&
            status !== APPOINTMENT_STATUS.CANCELED_BY_USER &&
            status !== APPOINTMENT_STATUS.CANCELED_BY_PATIENT
        )
    }

    visitHasStatusPaid(attendance: number): boolean {
        return attendance === ATTENDANCE_STATUS.PAID
    }

    getStatusCancelledTranslationKey(attendance: number, status: number): string {
        if (status === APPOINTMENT_STATUS.CANCELED_BY_USER || status === APPOINTMENT_STATUS.CANCELED_BY_PATIENT) {
            return 'cita-cancelada'
        }

        if (attendance === ATTENDANCE_STATUS.NOT_VISITED) {
            return 'calendar-status-did-not-come'
        }

        return ''
    }

    getPatientReviewStatus(patientId: number): Promise<PatientReviewStatus> {
        return repository.getPatientReviewStatus(patientId)
    }
}

export default new PatientService()
