import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { IAccountUser, ISecretaryTypes, IUser } from '@/features/settings/domain/interfaces/users.interfaces'

const URL_BASE = 'users'
const URL_BASE_V2 = 'v2/users'

const URL_USER_ID = `${URL_BASE}/:userId`
const URL_PERMISSIONS = `${URL_USER_ID}/permissions`
const URL_USER_DOCUMENTATION_DATA = `documentation-templates/data`

const URL_RELATED_MEDICAL_CENTER_USERS = `${URL_BASE}/relatedMedicalCenterUsers`
const URL_SECRETARY_TYPES = `${URL_BASE}/secretarytypes`
const URL_ACCOUNT_USERS = `${URL_BASE}/accountusers`
const URL_ACCOUNT_USERS_V2 = `${URL_BASE_V2}/accountusers`

class UsersRepository {
    updatePermissions(userId: number, payload: any): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PERMISSIONS,
            params: {
                userId
            }
        })

        return httpAPI.patch(url, payload)
    }

    async getRelatedUsers(): Promise<IUser[]> {
        const { data } = await httpAPI.get(URL_BASE)

        return data
    }

    async getMedicalCenterRelatedUsers(): Promise<IUser[]> {
        const { data } = await httpAPI.get(URL_RELATED_MEDICAL_CENTER_USERS)

        return data
    }

    async getSecretaryTypes(): Promise<ISecretaryTypes[]> {
        const { data } = await httpAPI.get(URL_SECRETARY_TYPES)

        return data
    }

    async getAccountUsers({ includeCurrentUser } = { includeCurrentUser: false }): Promise<IAccountUser[]> {
        const url = ensureToggleIsEnabled('UseSSOTokenOnSaaS', true) ? URL_ACCOUNT_USERS_V2 : URL_ACCOUNT_USERS

        const { data } = await httpAPI.get(url, {
            params: {
                includeCurrentUser
            }
        })

        return data
    }

    async getUserDocumentationData(userId: number): Promise<Record<string, string>> {
        const url = getUrlFromTemplate({
            template: URL_USER_DOCUMENTATION_DATA,
            params: { userId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }
}

export default new UsersRepository()
