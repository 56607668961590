import { MessagesNotificationsSettingsState } from './types'

const getDefaultState = (): MessagesNotificationsSettingsState => ({
    isAlwaysOn: false,
    isOnEditMode: false,
    notificationsSchedule: [],
    outOfOfficeEvent: null
})

export const state = getDefaultState()
