/* eslint-disable import/extensions */
import OneTracking, { OneTrackingEvent, OneTrackingOptions } from '@docplanner/one-tracking/dist/track'

import { ProfileEntity } from '@/features/auth/domain/interfaces/profile.interfaces'

// @ts-expect-error: can't resolve, module not found when angular+vue build
import * as gtm from './gtm.ts'
/* eslint-enable import/extensions */

let DpGTM: null | OneTracking = null

const trackUser = (user: ProfileEntity) => {
    const userRecords = {
        userType: user?.userType,
        userId: user?.id,
        userCreatedAt: user?.createdAt,
        userEmail: user?.loginUsername,
        doctorCountInClinic: user?.doctorCountInClinic,
        countryCode: user?.countryCode,
        culture: user?.culture,
        hasChatEnabled: user?.hasChatEnabled,
        hasRequestsEnabled: user?.hasRequestsEnabled,
        isDoctorInGruppo: user?.isDoctorInGruppo,
        isGruppo: user?.isGruppo,
        isSecretaryOfGruppo: user?.isSecretaryOfGruppo,
        isPublicPractitioner: user?.userSettings?.isPublicPractitioner || 'false',
        blockDistractors: user?.userSettings?.blockDistractors || 'false',
        hasVirtualSchedules: user?.hasVirtualSchedules || 'false',
        hasDpVideo: user?.hasDpVideo || 'false',
        hasPaymentsEnabled: user?.userSettings?.isOnlinePaymentsEnabled === 'true',
        impostorEmail: user?.impersonatorUser,
        firstName: user?.firstName,
        lastName: user?.lastName
    }

    gtm.trackUser(userRecords)
}

export const initialize = (user: ProfileEntity | undefined) => {
    if (!user) return
    if (!DpGTM) {
        DpGTM = new OneTracking({
            defaultProperties: {
                country_code: user.countryCode,
                userType: user.userType,
                userId: user.docplannerUserId,
                doctorId: user.docplannerDoctorId!,
                facilityId: user.userType === 'medicalcenter' ? user.id : user.medicalCenterId!,
                saasUserId: user.id
            }
        })
    }

    trackUser(user)
    if (typeof window.ga === 'function') {
        window.ga('set', 'dimension1', `int_user-${user?.id}-${user?.culture}`)
        window.ga('set', 'dimension2', user?.userType)
        window.ga('set', 'dimension3', user?.specialityName)
        window.ga('set', 'dimension4', user?.culture)
    } else {
        // eslint-disable-next-line no-console
        console.warn('window.ga is not a function')
    }
}

export const trackEvent = function (
    eventName: OneTrackingEvent,
    eventProperties?: OneTrackingOptions<Record<string, unknown>>
) {
    if (!DpGTM) return
    DpGTM.track(eventName, eventProperties)
}
