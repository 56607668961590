import VoicemailService from '@/features/settings/domain/voicemail.service'
import store from '@/store'

import { AuthGetterTypes } from '../../../auth/ui/store/types'
import { ConfigurationStatuses } from '../../domain/enums/voicemail.enums'
import { SETTINGS_GENERAL } from '../constants/router.constants'

export function canShowVoicemailSelfService(to, from, next) {
    if (store.getters[AuthGetterTypes.VoicemailSelfService] === false) {
        return next({ name: SETTINGS_GENERAL })
    }

    return next()
}

export async function voicemailConfiguration(to, from, next) {
    try {
        const { docplannerDoctorId } = store.getters[AuthGetterTypes.GetAuthUser]
        let currentConfigurationStatus = ''
        try {
            const { data } = await VoicemailService.getDoctorConfiguration(docplannerDoctorId)
            currentConfigurationStatus = data.status
        } catch (e) {
            currentConfigurationStatus = ConfigurationStatuses.Failed
        }
        if (currentConfigurationStatus !== ConfigurationStatuses.Activated) {
            return next({ name: 'voicemail-new-configuration' })
        }
    } catch (e) {
        if (e.response.status === 404) {
            return next({ name: 'voicemail-new-configuration' })
        }
    }
    return next()
}
