import { addDataDogAction, addDataDogTiming } from '@/bootstrap/monitor/datadog-poc/datadog'
import { DatadogCustomMetricName, DatadogFakeCustomActionName } from '@/bootstrap/monitor/datadog-poc/datadog.types'

export const startMeasure = (startMark: string) => {
    performance.mark(startMark)
    addDataDogTiming(startMark as DatadogCustomMetricName)
}

interface measureIds {
    endMark: string
    measureName: string
    startMark: string
}

export const finishAndTrackMeasure = (
    featureTogglePlugin: any,
    { measureName, startMark, endMark }: measureIds,
    flowType: string
) => {
    const isFirstMarkMissing = performance.getEntriesByName(startMark).length === 0
    if (isFirstMarkMissing) {
        return
    }

    performance.mark(endMark)
    addDataDogTiming(endMark as DatadogCustomMetricName)

    const measure = performance.measure(measureName, startMark, endMark)

    const featureEnabled = featureTogglePlugin.ensure('NewRelic_PageAction_Middlewares', true)
    const isNotOutdatedBrowser = typeof measure === 'object'
    if (featureEnabled && isNotOutdatedBrowser) {
        // TODO: refactor to integrate with monitor (WSTB-503)
        addDataDogAction(measure.name as DatadogFakeCustomActionName, { duration: measure.duration, flowType })
    }
}
