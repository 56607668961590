import { QuotesMutations } from './types'
import { MutationTypes } from './types/mutations'

export const mutations: QuotesMutations = {
    [MutationTypes.SetLoading](state, isLoading) {
        state.isLoading = isLoading
    },
    [MutationTypes.SetQuotes](state, quotes) {
        state.quotes = quotes
    },
    [MutationTypes.SetCurrentPage](state, currentPage) {
        state.currentPage = currentPage
    },
    [MutationTypes.SetFilters](state, filters) {
        state.filters = filters ? { ...state.filters, ...filters } : {}
    },
    [MutationTypes.SetTotalPages](state, totalPages) {
        state.totalPages = totalPages
    }
}
