import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import StaffRepository from '@/features/staff/api/staff.api'

import { Staff, StaffProperties } from './interfaces/staff.interface'

const staffMapping = (staff: Staff) => ({
    ...staff,
    hidden: false,
    id: staff.id,
    isStaff: true,
    selected: false,
    title: staff.name
})

class StaffService extends CalendarFilter<Staff> {
    async getStaff(): Promise<Staff[]> {
        const staffDTOs = await StaffRepository.getStaff()
        return staffDTOs.map(staffMapping)
    }

    filterStaffByProperties(staff: Staff[], properties: StaffProperties): Staff[] {
        const regExp = new RegExp(properties.name, 'i')
        const facilityStaff = staff.filter(el => {
            return (
                (properties.facilityId ? el.facilityId === properties.facilityId : true) &&
                (properties.name?.length ? el.name.match(regExp) : true)
            )
        })

        return this.orderListBySelectedAndName(facilityStaff)
    }
}

export default new StaffService()
