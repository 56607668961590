import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { COLOR_TRANSPARENT } from '@/features/calendar/domain/constants/colorSchemas.constants'
import { CalendarEventDisplayType, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'

import CalendarBlocksRepository from '../api/calendar.blocks.api'
import {
    BlockDTO,
    BlockEvent,
    BlocksPaginatedParams,
    BlocksParams,
    PaginatedBlockDTO
} from './interfaces/block.interfaces'

class CalendarBlocksService {
    async getBlocksPaginated(params: BlocksPaginatedParams, isMedicalCenter = false): Promise<PaginatedBlockDTO> {
        return CalendarBlocksRepository.getBlocksPaginated(params, isMedicalCenter)
    }

    async getBlock(blockId: number, isMedicalCenter = false): Promise<BlockDTO> {
        return CalendarBlocksRepository.getBlock(blockId, isMedicalCenter)
    }

    async saveBlock(params: BlocksParams, isMedicalCenter = false): Promise<BlockDTO> {
        return CalendarBlocksRepository.saveBlock(params, isMedicalCenter)
    }

    updateBlock(id: number, params: BlockEvent, isMedicalCenter = false): Promise<void> {
        const { start, end } = CalendarEventService.processStartEndToISODate({
            start: params.start,
            end: params.end
        })
        return CalendarBlocksRepository.updateBlock(
            id,
            {
                ...params,
                allDay: !!params.isAllDay,
                start,
                end
            },
            isMedicalCenter
        )
    }

    async deleteBlock(blockId: number, isMedicalCenter: boolean) {
        await CalendarBlocksRepository.deleteBlock(blockId, isMedicalCenter)
    }

    mapBlocksCollectionToEvent(blocks: BlockDTO[]): BlockEvent[] {
        return blocks.map(block => this.mapBlockToEvent(block))
    }

    mapBlockToEvent(block: BlockDTO): BlockEvent {
        const relatedSchedules = (block.relatedSchedules as Array<number | Schedule>)?.map(schedule => {
            return typeof schedule === 'number' ? schedule : schedule.id
        })

        return {
            ...block,
            ...CalendarEventService.processStartEndToDate(block),
            displayDefault: CalendarEventDisplayType.Auto,
            allDay: null,
            isAllDay: block.allDay,
            type: CalendarEventType.Block,
            id: block.id || (block.relatedBlocks ? block.relatedBlocks[0].id : -1),
            isBlock: true,
            editable: true,
            resourceIds: relatedSchedules,
            resourceId: block.schedule?.id,
            color: COLOR_TRANSPARENT,
            className: [`block-id-${block.id}`],
            groupId: ''
        }
    }
}

export default new CalendarBlocksService()
