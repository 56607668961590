/* eslint-disable @typescript-eslint/naming-convention */
// NOTE: enumMembers must match QuotesHeaderCustomPropsAllowed
export enum CellMap {
    createdAt = 'IssueDateCell',
    status = 'ApprovalStatusCell',
    total = 'PriceCell'
}

export enum StatusTranslationKeys {
    Accepted = 'quotes-feature-filters-status-accepted',
    Pending = 'quotes-feature-filters-status-pending',
    Rejected = 'quotes-feature-filters-status-rejected'
}

export enum StatusColors {
    Danger = 'danger',
    Success = 'success',
    Warning = 'warning'
}

export enum ActionsTranslationsKeys {
    Delete = 'quotes-feature-listing-more-delet',
    DownloadPDF = 'quotes-feature-listing-more-download',
    Duplicate = 'quotes-feature-listing-more-duplicate',
    Edit = 'quotes-feature-listing-more-edit',
    SendByChat = 'quotes-feature-listing-send-chat',
    SendByEmail = 'quotes-feature-listing-send-email'
}

export enum ActionsCommands {
    Delete = 'delete',
    DownloadPDF = 'downloadPDF',
    Duplicate = 'duplicate',
    Edit = 'edit',
    SendByChat = 'sendByChat',
    SendByEmail = 'sendByEmail'
}

export enum TrackProperties {
    Channel = 'channel',
    EditLocation = 'edit_location',
    Origin = 'origin',
    QuoteId = 'quote_id',
    StatusFrom = 'quote_status_from',
    StatusTo = 'quote_status_to'
}

export enum SendingChannels {
    Chat = 'chat',
    Email = 'email'
}
