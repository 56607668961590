import httpAPI from '@/api/utils/httpClientAPI'
import { Speciality } from '@/features/speciality/domain/interfaces/speciality.interfaces'

const URL_BASE = 'centers'
const URL_SPECIALITIES = `${URL_BASE}/specialities`

class SpecialityRepository {
    async getSpecialities(): Promise<Speciality[]> {
        const { data } = await httpAPI.get(URL_SPECIALITIES)
        return data
    }
}

export default new SpecialityRepository()
