import { GettersAdaptor } from '@/interfaces'

import { BookingGetters, BookingState, GetterTypes } from './types'

export const getters: GettersAdaptor<BookingGetters, BookingState, void> = {
    [GetterTypes.GetBookingEvent]: ({ event }) => event,
    [GetterTypes.GetBookingPatient]: ({ patient }) => {
        return patient?.id ? patient : undefined
    }
}
