export const getQueryStringFromUrl = () => {
    const path = window.location.search || window.location.hash

    if (path.includes('?')) {
        const [, queryString] = path.split('?')

        return `?${queryString}`
    }

    return ''
}
