import { SetupGuideTaskAction, SetupGuideTaskScope } from '../../enums'
import { TASK_NAME_AVAILABLE_LIST } from '../constants'
import { SetupGuideTask } from '../interfaces'

/**
 * Return the first non completed task name from the given list
 * @param taskList - Task list
 */
export const getTaskFirstNonCompleteName = (taskList: SetupGuideTask[]) => {
    const task = taskList.find(t => !t.completed)

    if (!task) {
        return ''
    }

    return task.name
}

/**
 * Return true if the given name is a task name
 * @param name - Task name
 */
export const isTaskName = (name: string) => {
    if (!name.includes('::')) {
        return false
    }

    const [scope, action] = name.split('::')

    if (!scope || !action) {
        return false
    }

    return (
        Object.values(SetupGuideTaskScope).includes(scope as SetupGuideTaskScope) &&
        Object.values(SetupGuideTaskAction).includes(action as SetupGuideTaskAction)
    )
}

/**
 * Return true if the given task is available
 * @param task - Task
 */
export const isTaskAvailable = (task: SetupGuideTask) => {
    return TASK_NAME_AVAILABLE_LIST.includes(task.name)
}
