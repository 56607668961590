export const SET_FEATURES = 'set_features'
export const SET_DATE = 'set_date'
export const SET_CULTURE = 'set_culture'
export const SET_APP_STATE = 'set_app_state'
export const SET_COUNTRY_SETTINGS = 'set_country_settings'
export const SET_AUTH_PROVIDER = 'set_auth_provider'
export const FETCH_FEATURES = 'fetch_features'
export const FETCH_COUNTRY_SETTINGS = 'fetch_country_settings'
export const SET_MOBILE_REDIRECT = 'set_mobile_redirect'
export const RESET_MODULE_STORES = 'reset_module_stores'
