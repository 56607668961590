import { getUrlFromTemplate } from '@dp-vue/utils'

import { get } from '@/api/utils/httpClientAPI'

import { PatientImportFacilityDTO } from '../interfaces'

const URL_BASE = 'patients'
const URL_IMPORT = `${URL_BASE}/import/for`

export const getPatientImportFacilities = async () => {
    const url = getUrlFromTemplate({
        template: URL_IMPORT
    })

    return get<PatientImportFacilityDTO[]>(url)
}
