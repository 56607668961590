import { uniqBy } from 'lodash'

import { SecretaryAppointment, SecretaryHeader } from '@/features/calendar/domain/interfaces/secretaryView.interfaces'
import { GettersAdaptor } from '@/interfaces'

import { CellMap } from '../../enums/secretaryView.enums'
import { SecretaryColumns, secretaryRow } from '../../interfaces/secretaryView.interfaces'
import { GETTER_SELECTED_DATE, MODULE_CALENDAR } from '../types'
import { GetterTypes, SecretaryViewGetters } from './types'
import { SecretaryViewState } from './types/state'

const DEFAULT_COMPONENT = 'DefaultCell'

function buildHeaders(columns: SecretaryHeader[]): SecretaryColumns {
    return columns
        .filter(({ enabled }) => enabled)
        .sort(
            (columnA, columnB) =>
                Number(columnB.isFrozen) - Number(columnA.isFrozen) || // frozen columns should come on top
                Number(columnB.isFixed) - Number(columnA.isFixed) || // then put on the top the pinned columns
                columnA.order - columnB.order // finally sort by ASC order
        )
}

function buildRows(rows: SecretaryAppointment[], headers: SecretaryHeader[]): secretaryRow[] {
    return rows.map(data => {
        return buildHeaders(headers).map(({ property, order }) => {
            return {
                value: data[property] !== undefined ? data[property] : null,
                data,
                order,
                component: CellMap[property] || DEFAULT_COMPONENT
            }
        })
    }, [])
}

export const getters: GettersAdaptor<SecretaryViewGetters, SecretaryViewState, any> = {
    [GetterTypes.GetSecretaryAppointments]: ({ headers, appointments }) => buildRows(appointments, headers),
    [GetterTypes.GetSecretaryAppointment]:
        ({ appointments }) =>
        (appointmentId: number | string) =>
            appointments.find(({ id }) => id === appointmentId),
    [GetterTypes.GetSecretaryViewHeader]: ({ headers }) => buildHeaders(headers),
    [GetterTypes.GetTableLoading]: state => state.isTableLoading,
    [GetterTypes.GetPaymentMethods]: state => state.paymentMethods,
    [GetterTypes.GetAllRowsSelected]: state => state.rowsSelected,
    [GetterTypes.GetPatientsSelected]: state =>
        uniqBy(
            state.appointments
                .filter(({ id }) => state.rowsSelected.includes(id))
                .map(({ patientId: id, patientName: name, patientPhoneNumber: phoneNumber, patientEmail: email }) => ({
                    id,
                    name,
                    phoneNumber,
                    email
                })),
            'id'
        ),
    [GetterTypes.GetMaxRowsSelectable]: state => state.appointments.length,
    [GetterTypes.GetAppointmentComment]: state => appointmentId =>
        state.appointments.find(({ id }) => id === appointmentId)?.comments,
    [GetterTypes.GetFilters]: state => state.filters,
    [GetterTypes.GetIsChatPopoverOpen]: state => state.isChatPopoverOpen,
    [GetterTypes.GetIsBulkCancelationPopoverOpen]: state => state.isBulkCancelationPopoverOpen,
    [GetterTypes.CalendarSelectedDayIsPast]: (state, otherGetters, rootState, rootGetters) => {
        const calendarSelectedDate = rootGetters[`${MODULE_CALENDAR}/${GETTER_SELECTED_DATE}`]
        const calendarSelectedDay = calendarSelectedDate.getDate()
        const todayDay = new Date().getDate()
        return calendarSelectedDay < todayDay
    }
}
