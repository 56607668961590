export const PRINT_POPUP_SIZE = {
    WIDTH: 960,
    HEIGHT: 1200
}

export const TYPE_LAYOUT = 'layout'
export const LAYOUT_SCOPES = {
    HEADER: 'header',
    FOOTER: 'footer',
    BODY: 'body'
}

export const ENTITY_SCOPES = {
    PATIENT: 'patient',
    DOCTOR: 'doctor',
    CLINIC: 'clinic',
    CLINIC_CUSTOM_DATA: 'clinicCustomData',
    DOCTOR_CUSTOM_DATA: 'doctorCustomData',
    CLINIC_ADDITIONAL_DATA: 'clinicAdditionalData',
    DOCTOR_ADDITIONAL_DATA: 'doctorAdditionalData'
}

export const BODY_FIELDS = {
    FONT_SIZE: 'fontSize',
    PAPER_SIZE: 'paperSize'
}

export const ENTITY_CLINIC = 'clinic'
export const LEAVE_CONFIRMATION_SAVE = 1

export const PRINT_LAYOUTS_ROUTE_NAMES = {
    list: 'print-layouts',
    editor: 'print-layouts-editor'
}
