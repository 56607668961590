import configManager from '@/bootstrap/configurationManager'

/**
 * NOTES:
 * - From tracking production usage, destinations in use are domains expressed with or without protocol (http:// or //). See tests for more details.
 * - I couldn't use regular url parsers like URL or url-parse because we must support destination starting with `//`
 * - Possible characters after domain based on https://nodejs.org/api/url.html#url-strings-and-url-objects
 */
export const isDestinationAllowed = (destination: string): boolean => {
    const allowedList = configManager.getSupportedDomains()
    const usesSupportedSchema = destination.startsWith('https://') || destination.startsWith('//')

    if (!usesSupportedSchema) {
        return false
    }

    const domainStartPos = destination.indexOf('//') + 2

    const possibleCharsAfterDomain = [':', '/', '#', '?']
    const possibleCharsAfterDomainPos = possibleCharsAfterDomain
        .filter(char => destination.indexOf(char, domainStartPos) !== -1)
        .map(char => destination.indexOf(char, domainStartPos))

    const nothingAfterHostname = possibleCharsAfterDomainPos.length === 0

    if (nothingAfterHostname) {
        return allowedList.includes(destination.substring(domainStartPos))
    }

    return allowedList.includes(destination.substring(domainStartPos, Math.min(...possibleCharsAfterDomainPos)))
}
