import httpCDN from '@/api/utils/httpClientCDN'

const Config = () => {
    const url = {
        path: '/config',
        countrySettings: '/country'
    }

    return {
        fetchCountrySettings(culture) {
            const filename = `settings${culture ? `_${culture}` : ''}.json`
            return httpCDN.get(`${url.path}${url.countrySettings}/${filename}?nocache=${new Date().getTime()}`)
        }
    }
}

export default Config
