import '@/bootstrap/monitor'
import '@/bootstrap/monitor/datadog-poc/datadog'
import './styles/dp-ui-kit/index.scss'
import './styles/index.scss'

import Vue from 'vue'

import ModuleLoader from '@/bootstrap/ModuleLoader'
import monitor from '@/bootstrap/monitor/monitor'
import warnHandler from '@/bootstrap/vue.config.warnhandler'
import { initialiseUnleashAnonymously } from '@/core/ui/plugins/featureToggle/providers/unleash'
import { SSO_RENEW_STATE } from '@/features/auth/domain/constants/auth.constants'
import MFLoader from '@/features/moduleFederation/domain/moduleFederationLoader.service'
import SSORenew from '@/ssoRenew'
import { getQueryParamsFromLocationSearch } from '@/utils/url'

import App from './App.vue'

Vue.config.productionTip = false
Vue.config.warnHandler = warnHandler
Vue.config.ignoredElements = ['patientus-video']

async function bootstrap() {
    const { state, code } = getQueryParamsFromLocationSearch()

    if (state === SSO_RENEW_STATE) {
        return SSORenew(code as string)
    }

    const moduleLoader = new ModuleLoader()

    await moduleLoader.loadModules()

    const injectableModules = moduleLoader.getInjectableModules()

    try {
        await initialiseUnleashAnonymously({
            toggles: {
                UseSSOTokenOnSaaS: {
                    value: false,
                    isRemote: true
                }
            }
        })
    } catch (e) {
        monitor.sendException(e)
    }

    const VueApp = new Vue({
        ...injectableModules,
        data() {
            return {
                loadingProgress: 100
            }
        },
        render: h => h(App)
    })

    VueApp.$mount(`#${process.env.VUE_APP_ELEMENT_ID}`)

    window.MFLoader = new MFLoader()

    return VueApp
}

export default bootstrap()
