import dayjs from 'dayjs'

import repository from '@/features/chat/api/session.api'
import { CHAT_SESSION_STORAGE_KEY } from '@/features/chat/domain/constants/sessionStorage.constants'
import { ChatSessionType } from '@/features/chat/domain/enums/chat.enums'
import { ChatSession, ChatSessionUser, ChatUser } from '@/features/chat/domain/interfaces/session.interface'
import Storage from '@/store/storage'

const MAX_NUMBER_OF_RETRIES = 3

type FetchSessionFn = (hostId?: number, hostDocplannerDoctorId?: string) => Promise<any>

class ChatSessionService {
    fetchSession(forceFetch = false): Promise<ChatSessionUser> {
        return this.fetchWithRetries(ChatSessionType.DefaultSession, repository.fetchSession, forceFetch)
    }

    fetchImpersonatorSession(
        forceFetch = false,
        hostId = undefined,
        hostDocplannerDoctorId = undefined
    ): Promise<ChatSessionUser> {
        return this.fetchWithRetries(
            ChatSessionType.ImpersonatorSession,
            repository.fetchImpersonatorSession,
            forceFetch,
            hostId,
            hostDocplannerDoctorId
        )
    }

    async fetchWithRetries(
        type: ChatSessionType,
        fetchSessionFn: FetchSessionFn,
        forceFetch: boolean,
        hostId?: number,
        hostDocplannerDoctorId?: string,
        numberOfRetries = 0
    ): Promise<ChatSessionUser> {
        try {
            const sessionUserFromStorage: ChatSessionUser | null = this.getSessionFromStorage(type)

            if (sessionUserFromStorage && !forceFetch) {
                return sessionUserFromStorage
            }

            const {
                avatarUrl,
                displayName,
                providerId,
                providerSessionToken: { expiresAt, token }
            } = await fetchSessionFn(hostId)

            const user: ChatUser = {
                id: providerId,
                name: displayName,
                avatar: avatarUrl,
                hostImpersonatorId: hostId,
                hostImpersonatorDocplannerDoctorId: hostDocplannerDoctorId
            }

            const session: ChatSession = {
                expiresAt,
                token
            }

            const userSession: ChatSessionUser = {
                user,
                session
            }

            this.storeSessionInStorage(type, userSession)

            return userSession
        } catch (e) {
            if (numberOfRetries < MAX_NUMBER_OF_RETRIES) {
                return this.fetchWithRetries(
                    type,
                    fetchSessionFn,
                    forceFetch,
                    hostId,
                    hostDocplannerDoctorId,
                    numberOfRetries + 1
                )
            }

            throw e
        }
    }

    getSessionFromStorage(type: ChatSessionType): ChatSessionUser | null {
        const sessionUser: ChatSessionUser | null = Storage.get(`${CHAT_SESSION_STORAGE_KEY}${type}`)
        if (!sessionUser) {
            return null
        }

        if (this.validateSession(sessionUser.session)) {
            return sessionUser
        }

        return null
    }

    storeSessionInStorage(type: ChatSessionType, sessionUser: ChatSessionUser): void {
        Storage.set(`${CHAT_SESSION_STORAGE_KEY}${type}`, sessionUser)
    }

    validateSession(session: ChatSession) {
        const currentDate = dayjs().add(4, 'h')
        const expiresAt = dayjs(session.expiresAt)

        return currentDate.isBefore(expiresAt)
    }

    clearSessionFromStorage(type: ChatSessionType): void {
        Storage.remove(`${CHAT_SESSION_STORAGE_KEY}${type}`)
    }
}

export default new ChatSessionService()
