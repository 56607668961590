import { isPast } from '@/core/ui/utils'
import SecretaryViewRepository from '@/features/calendar/api/secretaryView.api'
import { getPaymentMethodCellData } from '@/features/calendar/ui/utils/getPaymentMethodCellData'
import MessagesService from '@/features/settings/domain/messages.service'
import { orderByAttributes } from '@/utils/array'

import { ACTION_BAR_ACTIONS } from '../ui/constants/secretaryView.constants'
import { ActionBarActionsIds } from '../ui/enums/secretaryView.enums'
import { ActionBarAction } from '../ui/interfaces/secretaryActionBar.interfaces'
import { EventStatus } from './enums/eventStatus.enums'
import {
    PaymentMethod,
    SecretaryAppointment,
    SecretaryAppointmentResponse,
    SecretaryChatTemplate,
    SecretaryHeader,
    SecretaryPaymentMethod,
    SecretaryViewFilters,
    SecretaryViewUpdateServices,
    UpdatePaymentStatus
} from './interfaces/secretaryView.interfaces'
import { swapProperty } from './utils/swapProperty'

class SecretaryViewService {
    async getRows(selectedDate: Date, filters?: SecretaryViewFilters): Promise<SecretaryAppointment[]> {
        return SecretaryViewRepository.getAll(selectedDate, filters)
    }

    async getHeaders(): Promise<SecretaryHeader[]> {
        return SecretaryViewRepository.getHeaders()
    }

    async saveColumns(headers: SecretaryHeader[]): Promise<void> {
        await SecretaryViewRepository.saveColumns(headers)
    }

    sortHeadersByHiddenAndOrder(headers: SecretaryHeader[]): SecretaryHeader[] {
        return orderByAttributes(headers, ['hidden', 'order']).map((column: SecretaryHeader, index: number) => ({
            ...column,
            order: index + 1
        }))
    }

    sortHeadersByFixedAndHiddenAndOrder(headers: SecretaryHeader[]): SecretaryHeader[] {
        return orderByAttributes(headers, ['isFixed', 'hidden', 'order'], ['desc', 'asc', 'asc']).map(
            (column: SecretaryHeader, index: number) => ({
                ...column,
                order: index + 1
            })
        )
    }

    sortHeaders(headers: SecretaryHeader[], attributes: string[], orders?: ('asc' | 'desc')[]): SecretaryHeader[] {
        return orderByAttributes(headers, attributes, orders).map((column: SecretaryHeader, index: number) => ({
            ...column,
            order: index + 1
        }))
    }

    swapColumns(headers: SecretaryHeader[], currentColumnIndex: number, columnIndexToSwap: number) {
        if (currentColumnIndex === columnIndexToSwap) {
            return headers
        }
        const swappedHeaders = swapProperty<SecretaryHeader>(headers, currentColumnIndex, columnIndexToSwap, 'order')
        return this.sortHeaders(swappedHeaders, ['isFixed', 'hidden', 'order'], ['desc', 'asc', 'asc'])
    }

    changeColumnVisibility(headers: SecretaryHeader[], columnTag: string, hidden: boolean): SecretaryHeader[] {
        return this.sortHeadersByHiddenAndOrder(
            headers.map(header => ({
                ...header,
                hidden: header.tag === columnTag ? hidden : header.hidden
            }))
        )
    }

    async updateCheckInStatus(appointmentId: number, attendance: number, selectedDate: Date): Promise<void> {
        await SecretaryViewRepository.updateCheckInStatus(appointmentId, attendance, selectedDate)
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        return SecretaryViewRepository.getPaymentMethods()
    }

    async updatePaymentMethods(appointmentId: number, paymentMethod: number): Promise<Record<string, number>> {
        return SecretaryViewRepository.updatePaymentMethods(appointmentId, paymentMethod)
    }

    async deletePaymentMethods(appointmentId: number): Promise<void> {
        await SecretaryViewRepository.deletePaymentMethods(appointmentId)
    }

    async updatePaymentStatus(appointmentId: number | string, paymentStatus: number): Promise<UpdatePaymentStatus> {
        const {
            paymentStatus: paymentStatusId,
            paymentMethod,
            amountPaid
        } = await SecretaryViewRepository.updatePaymentStatus(appointmentId, paymentStatus)
        let secretaryPaymentMethod: SecretaryPaymentMethod | null = null

        if (paymentMethod) {
            secretaryPaymentMethod = getPaymentMethodCellData(paymentMethod)
        }
        return {
            paymentStatus: paymentStatusId,
            paymentMethod: secretaryPaymentMethod,
            amountPaid
        }
    }

    async deleteAppointment(appointmentId: number): Promise<void> {
        await SecretaryViewRepository.deleteAppointment(appointmentId)
    }

    async updateAppointmentComment(appointmentId: number, comment: string): Promise<void> {
        await SecretaryViewRepository.updateAppointmentComment(appointmentId, comment)
    }

    async getTemplates(): Promise<SecretaryChatTemplate[]> {
        const templates = await MessagesService.getTemplates()
        const customTemplates = MessagesService.getYourTemplates(templates)

        return customTemplates.reduce<SecretaryChatTemplate[]>((acc, { title, customText, defaultText, type, id }) => {
            const text = customText || defaultText || ''
            return text ? [...acc, { uid: `${type}${id}`, title, text }] : acc
        }, [])
    }

    async updateAppointmentServices(
        scheduleId: number | string,
        servicesUpdate: SecretaryViewUpdateServices[]
    ): Promise<SecretaryAppointmentResponse> {
        const appointment = await SecretaryViewRepository.updateAppointmentServices(scheduleId, servicesUpdate)

        return {
            ...appointment,
            endDateTime: appointment.end
        }
    }

    getActionBarMenuItems(
        showInvoices: boolean,
        showPayments: boolean,
        isImportantDataMissing: boolean
    ): ActionBarAction[] {
        return ACTION_BAR_ACTIONS.reduce((result: ActionBarAction[], action) => {
            switch (action.id) {
                case ActionBarActionsIds.Invoices:
                    if (!showInvoices) return [...result, action]
                    break
                case ActionBarActionsIds.Payment:
                    if (!showPayments) return [...result, action]
                    break
                case ActionBarActionsIds.UpdatePatientData:
                    return [
                        ...result,
                        {
                            ...action,
                            isError: isImportantDataMissing,
                            isSuccess: !isImportantDataMissing
                        }
                    ]
                default:
                    return [...result, action]
            }
            return result
        }, [])
    }

    isVisitWaitingToBeConfirmed(status: EventStatus): boolean {
        return [EventStatus.Scheduled, EventStatus.WaitingForConfirmation, EventStatus.SentToThirdParty].includes(
            status
        )
    }

    isVisitConfirmed(status: EventStatus): boolean {
        return [EventStatus.ConfirmedByPatient, EventStatus.ConfirmedByAdmin].includes(status)
    }

    isVisitPast(date: Date | null): boolean {
        return date ? isPast(date) : false
    }

    confirmAppointment(appointmentId: number): Promise<SecretaryAppointmentResponse> {
        return SecretaryViewRepository.confirmAppointment(appointmentId)
    }
}

export default new SecretaryViewService()
