import { MessagesNotificationsSettingsMutations, MutationTypes } from './types'

export const mutations: MessagesNotificationsSettingsMutations = {
    [MutationTypes.UpdateIsAlwaysOn](state, isAlwaysOn) {
        state.isAlwaysOn = isAlwaysOn
    },
    [MutationTypes.UpdateOnEditMode](state, isOnEditMode) {
        state.isOnEditMode = isOnEditMode
    },
    [MutationTypes.UpdateSessionSchedule](state, schedule) {
        state.notificationsSchedule = schedule
    },
    [MutationTypes.UpdateOutOfOfficeEvent](state, event) {
        state.outOfOfficeEvent = event
    }
}
