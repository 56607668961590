import { SETTINGS_PATHS, SETTINGS_ROUTES } from '../constants/router.constants'

const FileImportationHome = () =>
    import(
        /* webpackChunkName: "file-importation-home" */ '@/features/settings/ui/views/fileImportation/FileImportationHome.vue'
    )

export default [
    {
        name: SETTINGS_ROUTES.routes.fileImportation.home,
        path: SETTINGS_PATHS.fileImportation.home,
        component: FileImportationHome,
        children: [
            {
                name: SETTINGS_ROUTES.routes.fileImportation.wizard.import,
                path: SETTINGS_PATHS.fileImportation.wizard.import
            }
        ]
    }
]
