import sanitizeHtml from 'sanitize-html'

interface SanitizeOptions {
    allowedAttributes?: Record<string, sanitizeHtml.AllowedAttribute[]> | false
    allowedSchemesByTag?: { [index: string]: string[] }
    allowedTags?: string[] | false
    replace: boolean
    textFilter?: (text: string, tagName: string) => string
}

const DEFAULT_ALLOWED_TAGS: SanitizeOptions['allowedTags'] = ['p', 'strong', 'br', 'span', 'a', 'b']
const DEFAULT_ALLOWED_ATTRIBUTES: SanitizeOptions['allowedAttributes'] = {
    a: ['href', 'name', 'target', 'rel', 'tabindex']
}
const DEFAULT_ALLOWED_SCHEMES_BY_TAG: SanitizeOptions['allowedSchemesByTag'] = {}

const DEFAULT_SANITIZE_OPTIONS: SanitizeOptions = {
    allowedTags: DEFAULT_ALLOWED_TAGS,
    allowedAttributes: DEFAULT_ALLOWED_ATTRIBUTES,
    allowedSchemesByTag: DEFAULT_ALLOWED_SCHEMES_BY_TAG,
    replace: false
}

/**
 * Sanitize html content
 * @param content Dirty content to sanitize
 * @param options Options to sanitize the content, like tags allowed, attributes allowed, etc
 * @returns Dirty content sanitized
 * @example
 * ```ts
 * // With Defaults
 * const content = '<p>text with a <strong>keyword</strong> inside and <img src='url-to-src' /> an image</p>'
 * const contentSanitized = sanitize(content) // '<p>text with a <strong>keyword</strong> inside and an image</p>'
 * ```
 */
export const sanitize = (content = '', options = DEFAULT_SANITIZE_OPTIONS) => {
    if (options.replace) {
        return sanitizeHtml(content, {
            ...options,
            allowedTags: options.allowedTags,
            allowedAttributes: options.allowedAttributes,
            allowedSchemesByTag: options.allowedSchemesByTag
        })
    }

    return sanitizeHtml(content, {
        ...options,
        allowedTags: options.allowedTags ? DEFAULT_ALLOWED_TAGS.concat(options.allowedTags) : DEFAULT_ALLOWED_TAGS,
        allowedAttributes: {
            ...DEFAULT_ALLOWED_ATTRIBUTES,
            ...options.allowedAttributes
        },
        allowedSchemesByTag: {
            ...DEFAULT_ALLOWED_SCHEMES_BY_TAG,
            ...options.allowedSchemesByTag
        }
    })
}
