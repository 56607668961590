import {
    INVOICE_CORRECTIVE_ROUTE,
    INVOICE_CREATE_ROUTE,
    INVOICE_DETAILS_ROUTE,
    INVOICE_EDIT_ROUTE,
    INVOICE_EMPTY_CREATE_ROUTE,
    INVOICE_TUTORIAL_ROUTE,
    INVOICES_LIST_ROUTE,
    INVOICES_ROUTE
} from '@/features/invoice/ui/constants/routes.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'

const Invoices = () => import(/* webpackChunkName: "invoices" */ '@/features/invoice/ui/views/Invoices.vue')
const InvoicesList = () =>
    import(/* webpackChunkName: "invoices-list" */ '@/features/invoice/ui/views/InvoicesList.vue')
const InvoiceDetails = () =>
    import(/* webpackChunkName: "invoice-details" */ '@/features/invoice/ui/views/InvoiceDetails.vue')
const InvoiceReceiveTutorial = () =>
    import(/* webpackChunkName: "invoice-receive-tutorial" */ '@/features/invoice/ui/views/InvoiceReceiveTutorial.vue')
const InvoiceForm = () => import(/* webpackChunkName: "invoice-form" */ '@/features/invoice/ui/views/InvoiceForm.vue')

const NAVIGATION_TITLE = 'invoices-navigation-title'

export default {
    path: INVOICES_ROUTE,
    component: Invoices,
    children: [
        {
            path: '',
            name: INVOICES_LIST_ROUTE,
            component: InvoicesList,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: 'receive-tutorial',
            name: INVOICE_TUTORIAL_ROUTE,
            component: InvoiceReceiveTutorial,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: 'empty',
            name: INVOICE_EMPTY_CREATE_ROUTE,
            component: InvoiceForm,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: ':id/new/:patientId?',
            name: INVOICE_CREATE_ROUTE,
            component: InvoiceForm,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: ':invoiceId/edit',
            name: INVOICE_EDIT_ROUTE,
            component: InvoiceForm,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: ':invoiceId/corrective',
            name: INVOICE_CORRECTIVE_ROUTE,
            component: InvoiceForm,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        },
        {
            path: ':invoiceId/:patientId?',
            name: INVOICE_DETAILS_ROUTE,
            component: InvoiceDetails,
            meta: {
                title: NAVIGATION_TITLE,
                middleware: uninav,
                uninavName: 'billing_main'
            }
        }
    ]
}
