import ChatSessionService from '@/features/chat/domain/chatSession.service'
import {
    FETCH_CHAT_IMPERSONATOR_SESSION,
    FETCH_CHAT_SESSION,
    UPDATE_CHAT_SESSION_LOADING_STATE
} from '@/features/chat/ui/store/session/types/actions'
import {
    SET_CHAT_IMPERSONATOR_SESSION,
    SET_CHAT_IMPERSONATOR_USER,
    SET_CHAT_SESSION,
    SET_CHAT_SESSION_LOADING,
    SET_CHAT_USER
} from '@/features/chat/ui/store/session/types/mutations'

const actions = {
    async [FETCH_CHAT_IMPERSONATOR_SESSION]({ commit }, { hostId, hostDocplannerDoctorId }) {
        const { user, session } = await ChatSessionService.fetchImpersonatorSession(
            false,
            hostId,
            hostDocplannerDoctorId
        )
        const impersonatorUser = {
            ...user,
            hostImpersonatorId: hostId,
            hostImpersonatorDocplannerDoctorId: hostDocplannerDoctorId
        }
        commit(SET_CHAT_IMPERSONATOR_SESSION, session)

        commit(SET_CHAT_IMPERSONATOR_USER, impersonatorUser)
    },
    async [FETCH_CHAT_SESSION]({ commit }) {
        const { user, session } = await ChatSessionService.fetchSession()

        commit(SET_CHAT_SESSION, session)

        commit(SET_CHAT_USER, user)
    },
    async [UPDATE_CHAT_SESSION_LOADING_STATE]({ commit }, isLoading) {
        commit(SET_CHAT_SESSION_LOADING, isLoading)
    }
}

export default actions
