import { ClinicSize } from './enums/userType.enums'
import { ProfileEntity } from './interfaces/profile.interfaces'

class SegmentationService {
    getClinicSizeSegment(user: ProfileEntity): ClinicSize {
        let sizeSegment: ClinicSize

        // DOUBT: confirm <= or <
        if (user.schedulesCount <= 10) {
            sizeSegment = ClinicSize.From0To10
        } else if (user.schedulesCount <= 15) {
            sizeSegment = ClinicSize.From10To15
        } else if (user.schedulesCount <= 20) {
            sizeSegment = ClinicSize.From15To20
        } else if (user.schedulesCount <= 30) {
            sizeSegment = ClinicSize.From20To30
        } else if (user.schedulesCount <= 50) {
            sizeSegment = ClinicSize.From30To50
        } else {
            sizeSegment = ClinicSize.From50
        }

        return sizeSegment
    }
}

export default new SegmentationService()
