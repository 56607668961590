import httpAPI from '@/api/utils/httpClientAPI'

import { ReportsHintObject } from '../domain/interfaces/hints.interfaces'

const URL_BASE = 'reports'
const URL_HINTS = `${URL_BASE}/appointments/hints`

class HintsRepository {
    async getHint(columnTag: string): Promise<ReportsHintObject[]> {
        const { data } = await httpAPI.get(`${URL_HINTS}/${columnTag}`)
        return data
    }
}

export default new HintsRepository()
