import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { parseParams } from '@/utils/parseParams'

import {
    CreateVoucherType,
    PaginatedVoucherType,
    SellVoucherTypeParameters,
    VoucherSoldResponse,
    VoucherType,
    VoucherTypesParameters
} from '../domain/interfaces/voucher.interfaces'

const URL_BASE = 'vouchertypes'

const URL_VOUCHER_TYPE_ID = `${URL_BASE}/:voucherTypeId`
const URL_ACTIVATE = `${URL_VOUCHER_TYPE_ID}/activate`
const URL_DEACTIVATE = `${URL_VOUCHER_TYPE_ID}/deactivate`
const URL_SELL = `${URL_VOUCHER_TYPE_ID}/sell`

class VoucherTypeRepository {
    async get(voucherTypeId: number): Promise<VoucherType> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_TYPE_ID,
            params: {
                voucherTypeId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getVoucherTypes(params?: VoucherTypesParameters): Promise<VoucherType[]> {
        const { data } = await httpAPI.get(URL_BASE, { params })
        return data
    }

    async getVoucherTypesPaginated(params: VoucherTypesParameters, page: number): Promise<PaginatedVoucherType> {
        const query = parseParams({ ...params, page })
        const url = getUrlFromTemplate({
            template: URL_BASE,
            query
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async create(voucher: CreateVoucherType): Promise<VoucherType> {
        const { data } = await httpAPI.post(URL_BASE, voucher)
        return data
    }

    async update(voucherTypeId: number, voucher: VoucherType): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_TYPE_ID,
            params: {
                voucherTypeId
            }
        })
        await httpAPI.put(url, voucher)
        return true
    }

    async delete(voucherTypeId: number): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_TYPE_ID,
            params: {
                voucherTypeId
            }
        })
        await httpAPI.delete(url)
        return true
    }

    async activate(voucherTypeId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_ACTIVATE,
            params: {
                voucherTypeId
            }
        })
        const { data } = await httpAPI.post(url)
        return data
    }

    async deactivate(voucherTypeId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DEACTIVATE,
            params: {
                voucherTypeId
            }
        })
        const { data } = await httpAPI.post(url)
        return data
    }

    async sell(voucherTypeId: number, voucher: SellVoucherTypeParameters): Promise<VoucherSoldResponse> {
        const url = getUrlFromTemplate({
            template: URL_SELL,
            params: {
                voucherTypeId
            }
        })
        const { data } = await httpAPI.post(url, voucher)
        return data
    }
}

export default new VoucherTypeRepository()
