import { CampaignsRecipientsProperty } from '@/features/campaigns/domain/enums/campaignsRecipients.enums'
import { CampaignsRecipientsCols } from '@/features/campaigns/domain/interfaces/campaignsRecipients.interfaces'

export const CAMPAIGNS_RECIPIENTS_HEADERS_SMS = {
    property: CampaignsRecipientsProperty.Phone,
    name: 'campaigns-recipients-table-phone-number'
}

export const CAMPAIGNS_RECIPIENTS_HEADERS_EMAIL = {
    property: CampaignsRecipientsProperty.Email,
    name: 'campaigns-recipients-table-email'
}

export const CAMPAIGNS_RECIPIENTS_HEADERS_INSURANCE_NAME = {
    property: CampaignsRecipientsProperty.InsuranceName,
    name: 'campaigns-recipients-table-insurance'
}

export const CAMPAIGNS_RECIPIENTS_HEADERS_MEDICAL_CENTER = [
    {
        property: CampaignsRecipientsProperty.Speciality,
        name: 'campaigns-recipients-table-speciality'
    },
    {
        property: CampaignsRecipientsProperty.Specialist,
        name: 'campaigns-recipients-table-specialist'
    },
    {
        property: CampaignsRecipientsProperty.Service,
        name: 'campaigns-recipients-table-service'
    }
]

export const CAMPAIGNS_RECIPIENTS_HEADERS: CampaignsRecipientsCols = [
    {
        property: CampaignsRecipientsProperty.LastAppointmentDate,
        name: 'campaigns-post-landing-date'
    },
    {
        property: CampaignsRecipientsProperty.FullName,
        name: 'campaigns-recipients-table-patient'
    },
    {
        property: CampaignsRecipientsProperty.Age,
        name: 'campaigns-recipients-table-age'
    },
    {
        property: CampaignsRecipientsProperty.Gender,
        name: 'campaigns-recipients-table-gender'
    }
]
