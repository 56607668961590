import { ChatType, PatientChatCategory } from '@/features/chat/domain/enums/chat.enums'

import repository from '../api/conversationsV2.api'
import {
    ConversationsUnreadCounterResponse,
    PatientConversationList,
    PatientConversationListEntry
} from './interfaces/conversations.interfaces'

class ConversationsV2Service {
    getPatientConversationsList(payload: {
        category: PatientChatCategory
        hostUserId?: number | null
        limit: number
        patientRequestType?: string
        providerNextToken: string
    }): Promise<PatientConversationList> {
        return repository.getPatientConversationsList(payload)
    }

    getPatientConversationsListElement(
        providerId: string,
        hostId?: number | null
    ): Promise<PatientConversationListEntry> {
        return repository.getPatientConversationsListElement(providerId, hostId)
    }

    async getNumberOfUnreadConversations(
        types: ChatType[],
        hostId?: number
    ): Promise<ConversationsUnreadCounterResponse> {
        return repository.getNumberOfUnreadConversations(types, hostId)
    }
}

export default new ConversationsV2Service()
