import { NavigationGuardNext, Route } from 'vue-router'

import { ROUTE_PAYMENTS_ACTIVATION_NAME } from '../../../constants/routes.constants'
import { usePaymentsProfile } from '../../composables/usePaymentsProfile'

export const redirectionToLandingPage = async ({ to, next }: { next: NavigationGuardNext; to: Route }) => {
    const {
        hasAccessToPaymentsArea,
        hasPaymentsActive,
        hasPaymentsOnboarded,
        canActivatePayments,
        getPaymentsProfile
    } = usePaymentsProfile()

    try {
        await getPaymentsProfile()

        if (!hasAccessToPaymentsArea.value) {
            return next('/')
        }

        if (
            to.name !== ROUTE_PAYMENTS_ACTIVATION_NAME &&
            (canActivatePayments.value || (hasPaymentsOnboarded.value && !hasPaymentsActive.value))
        ) {
            return next({ name: ROUTE_PAYMENTS_ACTIVATION_NAME })
        }
    } catch {
        return next('/')
    }

    return next()
}
