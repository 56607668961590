import {
    CampaignsReportGroup,
    CampaignsReportGroupItemData,
    CampaignsReportRow
} from '@/features/campaigns/domain/interfaces/campaigns.interfaces'
import { CAMPAIGNS_CELL_COMPONENTS, CAMPAIGNS_COLS } from '@/features/campaigns/ui/constants/campaigns.constants'

export const mapCampaignItem = (data: CampaignsReportGroupItemData): CampaignsReportRow => ({
    id: data.id,
    cells: CAMPAIGNS_COLS.map(({ property }) => ({
        value: data[property],
        data,
        name: property,
        component: CAMPAIGNS_CELL_COMPONENTS[property]
    }))
})

const getRows = (items: CampaignsReportGroupItemData[]): CampaignsReportRow[] => items.map(mapCampaignItem)

export const getReportTableRows = (data: CampaignsReportGroup[]): CampaignsReportRow[] =>
    data.reduce((acc: CampaignsReportRow[], current: CampaignsReportGroup) => acc.concat(getRows(current.items)), [])
