import { RequestState } from './types/state'

export const getDefaultState = (): RequestState => ({
    isRequestFeatureEnabled: false,
    pageCount: 0,
    requests: [],
    unreadCount: 0,
    channel: null,
    categories: [],
    allowRequestsFromNewPatient: false
})

export const state = getDefaultState()
