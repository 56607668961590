import { ConsentFileType } from '@/features/privacyPolicy/domain/enums/privacyPolicy.enums'
import {
    GET_HAS_MARKETING_CONSENT,
    GET_HAS_PRIVACY_POLICY,
    GET_PRIVACY_FILE_ID,
    GET_SAVED_PRIVACY_FILE_ID
} from '@/features/privacyPolicy/ui/store/types/getters'

export const getters = {
    [GET_HAS_PRIVACY_POLICY]({ settings }) {
        return settings.hasPrivacyPolicy
    },
    [GET_HAS_MARKETING_CONSENT]({ settings }) {
        return settings.hasMarketingConsent
    },
    [GET_PRIVACY_FILE_ID]({ settings }) {
        const file = settings.privacyFileIds.find(({ fileType }) => fileType === ConsentFileType.PrivacyPolicy)

        return file?.idString
    },
    [GET_SAVED_PRIVACY_FILE_ID]({ savedSettings }) {
        const file = savedSettings?.privacyFileIds.find(({ fileType }) => fileType === ConsentFileType.PrivacyPolicy)

        return file?.idString
    }
}
