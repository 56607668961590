import { CalendarOrderType } from '../enums/calendar.enums'

export const CALENDAR_ORDERS = [
    {
        id: CalendarOrderType.Availability,
        name: 'general-settings-calendar-order-calendars-selector-availability'
    },
    {
        id: CalendarOrderType.Alphabetical,
        name: 'general-settings-calendar-order-calendars-selector-alphabetical'
    },
    {
        id: CalendarOrderType.Custom,
        name: 'general-settings-calendar-order-calendars-selector-custom'
    }
]

export const ORDER_DEFAULT = CalendarOrderType.Availability
