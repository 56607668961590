import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import httpMkplAPI from '@/api/utils/httpClientMkplAPI'
import { parseJSON } from '@/utils/parse-json'

const URL_SETTINGS = 'extra-features'
const URL_SETTINGS_DEACTIVATE = `${URL_SETTINGS}/deactivate`
const MKPL_DOCTOR_SETTINGS_LINK = 'internal/doctors/:mkplUserId/links/settings-page'
const MKPL_FACILITY_SETTINGS_LINK = 'internal/facilities/:mkplUserId/links/settings-page'

const transformMKPLResponse = data => {
    const { _items } = parseJSON(data, { _items: [] })
    return _items
}

export default () => {
    return {
        getExtraFeatures() {
            return httpAPI.get(URL_SETTINGS)
        },
        deactivate(props) {
            return httpAPI.put(URL_SETTINGS_DEACTIVATE, {
                id: props.id,
                isPlugin: props.isPlugin,
                reason: props.reason,
                comments: props.comments
            })
        },
        mkplDoctorSettingsLinks(mkplUserId) {
            const url = getUrlFromTemplate({
                template: MKPL_DOCTOR_SETTINGS_LINK,
                params: { mkplUserId }
            })
            return httpMkplAPI.get(url, {
                transformResponse: [transformMKPLResponse]
            })
        },
        mkplFacilitySettingsLinks(mkplUserId) {
            const url = getUrlFromTemplate({
                template: MKPL_FACILITY_SETTINGS_LINK,
                params: { mkplUserId }
            })
            return httpMkplAPI.get(url, {
                transformResponse: [transformMKPLResponse]
            })
        }
    }
}
