import dayjs, { Dayjs } from 'dayjs'

import { DateFormat, dateToUtc } from '@/core/ui/utils'
import CalendarBookingRequestsApi from '@/features/calendar/api/calendar.bookingRequests.api'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { COLOR_TRANSPARENT } from '@/features/calendar/domain/constants/colorSchemas.constants'
import { CalendarEventDisplayType, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { EventType } from '@/features/calendar/domain/enums/eventType.enums'

import { BookingRequestDTO } from '../api/interfaces/bookingRequests.interfaces'
import { BookingRequestEvent } from './interfaces/bookingRequest.interfaces'

class CalendarBookingRequestsService {
    async getBookingRequests({
        start,
        end,
        docplannerDoctorId
    }: {
        docplannerDoctorId: string
        end: string | Date | Dayjs
        start: string | Date | Dayjs
    }): Promise<BookingRequestDTO[]> {
        return CalendarBookingRequestsApi.getBookingRequests(start, end, docplannerDoctorId)
    }

    mapBookingRequestsCollectionToEvent(requests: BookingRequestDTO[]): BookingRequestEvent[] {
        return requests.map(request => this.mapBookingRequestToEvent(request))
    }

    mapBookingRequestToEvent(request: BookingRequestDTO): BookingRequestEvent {
        const patientFullName = `${request.patientFirstName} ${request.patientLastName}`
        const start = dateToUtc(request.requestDate).format(DateFormat.DateStringNoUtcOffset)
        const end = dateToUtc(request.endDate).format(DateFormat.DateStringNoUtcOffset)

        return {
            ...request,
            ...CalendarEventService.processStartEndToDate({
                start,
                end
            }),
            displayDefault: CalendarEventDisplayType.Auto,
            type: CalendarEventType.BookingRequest,
            eventType: EventType.External,
            id: request.id,
            editable: false,
            resourceId: request.scheduleId,
            color: COLOR_TRANSPARENT,
            title: patientFullName,
            className: [`booking-request-id-${request.id}`]
        }
    }

    getBookingRequestById(requestId: string, docplannerDoctorId: string): Promise<BookingRequestDTO> {
        return CalendarBookingRequestsApi.getBookingRequestById(requestId, docplannerDoctorId)
    }

    acceptBookingRequest(requestId: string, docplannerDoctorId: string): Promise<void> {
        return CalendarBookingRequestsApi.acceptBookingRequest(requestId, docplannerDoctorId)
    }

    rejectBookingRequest(requestId: string, docplannerDoctorId: string, content?: string): Promise<void> {
        return CalendarBookingRequestsApi.rejectBookingRequest(requestId, docplannerDoctorId, content)
    }
}

export default new CalendarBookingRequestsService()
