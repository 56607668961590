import httpAPI from '@/api/utils/httpClientAPI'
import { Facility, Place } from '@/features/patient/domain/interfaces/facility.interfaces'

const URL_BASE = 'facilities'
const URL_PLACES = `${URL_BASE}/places`

// NOTE: it may not belong to "patient" feature. It will be moved if a "facility" feature emerges.
class FacilityRepository {
    async getFacilities(): Promise<Facility[]> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }

    async getPlaces(): Promise<Place[]> {
        const { data } = await httpAPI.get(URL_PLACES)
        return data
    }
}

export default new FacilityRepository()
