import ReportsService from '@/features/reports/domain/reports.service'

import {
    GETTER_REPORTS_COLUMN_NAMES,
    GETTER_REPORTS_COLUMNS,
    GETTER_REPORTS_DATA_FETCH_PARAMETERS,
    GETTER_REPORTS_ENABLED_HINTS,
    GETTER_REPORTS_HEADERS_EMPTY,
    GETTER_REPORTS_ROWS
} from './types/getters'

const getters = {
    [GETTER_REPORTS_COLUMNS](state) {
        return ReportsService.buildColumns(state.headers)
    },
    [GETTER_REPORTS_ROWS](state) {
        return ReportsService.buildRows(state.page, state.headers, state.currentReport)
    },
    [GETTER_REPORTS_HEADERS_EMPTY](state) {
        return state.headers.length === 0
    },
    [GETTER_REPORTS_COLUMN_NAMES](state, otherGetters) {
        const columns = otherGetters[GETTER_REPORTS_COLUMNS]

        return columns.map(({ name }) => name)
    },
    [GETTER_REPORTS_ENABLED_HINTS](state, otherGetters) {
        const columns = otherGetters[GETTER_REPORTS_COLUMNS]

        return columns.reduce((array, { enabled, hint }) => {
            if (enabled) {
                array = array.concat(hint.toLowerCase())
            }
            return array
        }, [])
    },
    [GETTER_REPORTS_DATA_FETCH_PARAMETERS]({ fetchParameters, currentPage }) {
        return {
            ...fetchParameters,
            pageNumber: currentPage - 1
        }
    }
}

export default getters
