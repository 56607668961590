import type { SetupGuideTaskName as TaskName } from '../../domain'
import { setupGuideEventBus } from './bus'

enum TaskEvents {
    StartTask = 'StartTask'
}

export const onTaskStarted = (callback: (taskName: TaskName) => void) =>
    setupGuideEventBus.on(TaskEvents.StartTask, callback)
export const offTaskStarted = (callback: (taskName: TaskName) => void) =>
    setupGuideEventBus.off(TaskEvents.StartTask, callback)
export const emitTaskStarted = (taskName: TaskName) => setupGuideEventBus.emit(TaskEvents.StartTask, taskName)
export const createTaskStartedHandler = (fn: (taskName: TaskName) => void) => fn
