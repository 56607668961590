import EventBus from '@/core/ui/libs/eventBus'
import { SET_APP_STATE } from '@/store/mutation-types'

export default function appReady({ to, next, router, store }) {
    if (!store.getters.appState) {
        store.commit(SET_APP_STATE, true)
        EventBus.emit('appReady')
    }

    next()
}
