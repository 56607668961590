import { PaymentsColumnKeys, PreviousVisitsColumnKeys, SettingsColumnKeys } from '../enums'
import { TableColumn } from '../interfaces'
import { $t } from '.'

export const getPreviousVisitsColumnsData = (): TableColumn[] => [
    {
        title: $t('payments-section-overview-history-filter-date'),
        key: PreviousVisitsColumnKeys.Date,
        width: '10%'
    },
    {
        title: $t('payments-section-overview-history-filter-status'),
        key: PreviousVisitsColumnKeys.Status,
        width: '10%'
    },
    {
        title: $t('payments-section-overview-history-filter-type'),
        key: PreviousVisitsColumnKeys.Schedule,
        width: '25%'
    },
    {
        title: $t('payments-section-overview-history-filter-service'),
        key: PreviousVisitsColumnKeys.Service,
        width: '25%'
    },
    {
        title: $t('payments-section-overview-history-filter-patient'),
        key: PreviousVisitsColumnKeys.Patient,
        width: '20%'
    },
    {
        title: $t('payments-section-overview-history-filter-price'),
        key: PreviousVisitsColumnKeys.Price,
        width: '10%'
    }
]

export const getSettingsColumnsData = (): TableColumn[] => [
    {
        title: $t('payments-section-settings-list-name'),
        key: SettingsColumnKeys.Name
    },
    {
        title: $t('payments-section-settings-list-address'),
        key: SettingsColumnKeys.Address,
        width: '30%'
    },
    {
        title: $t('payments-section-settings-list-online-payments'),
        key: SettingsColumnKeys.Status
    },
    {
        title: '',
        key: SettingsColumnKeys.Actions,
        align: 'right'
    }
]

export const getPaymentsColumnsData = (): TableColumn[] => [
    {
        title: $t('payments-section-payments-requests-list-column-creation-date'),
        key: PaymentsColumnKeys.CreationDate,
        width: '10%'
    },
    {
        title: $t('payments-section-payments-requests-list-column-amount'),
        key: PaymentsColumnKeys.Amount,
        width: '10%'
    },
    {
        title: $t('payments-section-payments-requests-list-column-status'),
        key: PaymentsColumnKeys.Status,
        width: '10%'
    },
    {
        title: $t('payments-section-payments-requests-list-column-patient'),
        key: PaymentsColumnKeys.Patient,
        width: '20%'
    },
    {
        title: $t('payments-section-payments-requests-list-column-type'),
        key: PaymentsColumnKeys.Type,
        width: '10%'
    },
    {
        title: $t('payments-section-payments-requests-list-column-description'),
        key: PaymentsColumnKeys.Description,
        width: '30%'
    },
    {
        title: '',
        key: PaymentsColumnKeys.Actions,
        width: '10%'
    }
]
