export const TABLE_HEADERS = [
    'quotes-feature-create-new-services-name',
    'quotes-feature-create-new-services-tax-rate',
    'quotes-feature-create-new-services-quantity',
    'quotes-feature-create-new-services-unit-price',
    'quotes-feature-create-new-services-discount'
]

const PAID_METHOD_NAME = 'payment-online-consultation-status-selector-paid'

// Hidden payment methods in payment methods select
export const PAYMENT_METHODS_NOT_ALLOWED = [PAID_METHOD_NAME]

// Modal names
export const QUOTE_SEND_BY_EMAIL = 'QuoteSendByEmailModal'
