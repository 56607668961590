export const ACTION_UPDATE_PATIENT_DATA = 'ACTION_UPDATE_PATIENT_DATA'
export const ACTION_FETCH_PATIENT_DATA = 'ACTION_FETCH_PATIENT_DATA'
export const ACTION_SET_PATIENTS_PAGE_DATA = 'ACTION_SET_PATIENTS_PAGE_DATA'
export const ACTION_SET_SELECTED_PATIENT_ID = 'ACTION_SET_SELECTED_PATIENT_ID'
export const ACTION_UNSET_SELECTED_PATIENT_ID = 'ACTION_UNSET_SELECTED_PATIENT_ID'
export const ACTION_SET_PAGE_ALL_PATIENT_IDS = 'ACTION_SET_PAGE_ALL_PATIENT_IDS'
export const ACTION_RESET_SELECTED_PATIENT_IDS = 'ACTION_RESET_SELECTED_PATIENT_IDS'
export const ACTION_FETCH_MERGE_PATIENT_DATA = 'ACTION_FETCH_MERGE_PATIENT_DATA'
export const ACTION_MERGE_TWO_PATIENTS_WITH_OVERRIDES = 'ACTION_MERGE_TWO_PATIENTS_WITH_OVERRIDES'
export const ACTION_FETCH_EVENT_DATA = 'ACTION_FETCH_EVENT_DATA'
export const ACTION_FETCH_FUTURE_EVENT_DATA = 'ACTION_FETCH_FUTURE_EVENT_DATA'
export const ACTION_FETCH_NEXT_EVENT_DATA = 'ACTION_FETCH_NEXT_EVENT_DATA'
export const ACTION_FETCH_CHECKIN_INFO = 'ACTION_FETCH_CHECKIN_INFO'
export const ACTION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE = 'ACTION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE'
export const ACTION_FETCH_INSURANCES = 'ACTION_FETCH_INSURANCES'
export const ACTION_RESET_EVENT_STATE = 'ACTION_RESET_EVENT_STATE'
export const ACTION_FETCH_FINANCIAL_SUMMARY = 'ACTION_FETCH_FINANCIAL_SUMMARY'
export const ACTION_FETCH_PATIENT_COUNTERS = 'ACTION_FETCH_PATIENT_COUNTERS'
export const ACTION_SET_TABLE_EDIT_STATE = 'ACTION_SET_TABLE_EDIT_STATE'
export const ACTION_SET_ALL_PATIENTS_SELECTED = 'ACTION_SET_ALL_PATIENTS_SELECTED'
export const ACTION_FETCH_PATIENT_REVIEW_STATUS = 'ACTION_FETCH_PATIENT_REVIEW_STATUS'
export const ACTION_FETCH_APPOINTMENT_PROPOSALS = 'ACTION_FETCH_APPOINTMENT_PROPOSALS'
