export const GETTER_CALENDAR_LOADING = 'GETTER_CALENDAR_LOADING'
export const GETTER_CALENDAR_EVENTS = 'GETTER_CALENDAR_EVENTS'
export const GETTER_CALENDAR_EVENTS_METRICS = 'GETTER_CALENDAR_EVENTS_METRICS'
export const GETTER_CALENDAR_ROOMS = 'GETTER_CALENDAR_ROOMS'
export const GETTER_CALENDAR_PERIOD = 'GETTER_CALENDAR_PERIOD'
export const GETTER_HOLIDAYS = 'GETTER_HOLIDAYS'
export const GETTER_CALENDAR_SELECTED_DATE = 'GETTER_CALENDAR_SELECTED_DATE'
export const GETTER_USER_CALENDARS_ORDENED = 'GETTER_USER_CALENDARS_ORDENED'
export const GETTER_COLOR_SCHEMAS = 'GETTER_COLOR_SCHEMAS'
export const GETTER_PICKER_COLOR_SCHEMAS = 'GETTER_PICKER_COLOR_SCHEMAS'
export const GETTER_CALENDAR_CREATION_TYPE = 'GETTER_CALENDAR_CREATION_TYPE'
export const GETTER_CALENDAR_CREATION_ADDRESS = 'GETTER_CALENDAR_CREATION_ADDRESS'
export const GETTER_CALENDAR_CREATION_SERVICES = 'GETTER_CALENDAR_CREATION_SERVICES'
export const GETTER_CALENDAR_CREATION_COLOR = 'GETTER_CALENDAR_CREATION_COLOR'
export const GETTER_CALENDAR_CREATION_NAME = 'GETTER_CALENDAR_CREATION_NAME'
export const GETTER_CALENDAR_SHOW_QUICK_MODAL = 'GETTER_CALENDAR_SHOW_QUICK_MODAL'
export const GETTER_SELECTED_DATE_RANGE_BY_PERIOD = 'GETTER_SELECTED_DATE_RANGE_BY_PERIOD'
export const GETTER_SELECTED_DOCTORS = 'GETTER_SELECTED_DOCTORS'
export const GETTER_SELECTED_ROOMS = 'GETTER_SELECTED_ROOMS'
export const GETTER_CALENDAR_FILTER_SERVICES = 'GETTER_CALENDAR_FILTER_SERVICES'
export const GETTER_CALENDAR_FILTER_SELECTED_SERVICES = 'GETTER_CALENDAR_FILTER_SELECTED_SERVICES'
export const GETTER_SELECTED_FACILITY = 'GETTER_SELECTED_FACILITY'
export const GETTER_SELECTED_DOCTOR = 'GETTER_SELECTED_DOCTOR'
export const GETTER_SELECTED_STAFF = 'GETTER_SELECTED_STAFF'
export const GETTER_SELECTED_DOCTOR_SCHEDULES = 'GETTER_SELECTED_DOCTOR_SCHEDULES'
export const GETTER_SELECTED_DATE = 'GETTER_SELECTED_DATE'
export const GETTER_AGENDA_RANGE = 'GETTER_AGENDA_RANGE'
export const GETTER_FACILITIES = 'GETTER_FACILITIES'
export const GETTER_SPECIALITIES = 'GETTER_SPECIALITIES'
export const GETTER_DOCTORS = 'GETTER_DOCTORS'
export const GETTER_DOCTORS_LIST = 'GETTER_DOCTORS_LIST'
export const GETTER_DOCTORS_FILTERED_AND_ORDERED = 'GETTER_DOCTORS_FILTERED_AND_ORDERED'
export const GETTER_DOCTORS_WORKING_CURRENT_PERIOD = 'GETTER_DOCTORS_WORKING_CURRENT_PERIOD'
export const GETTER_DOCTORS_GROUPED_BY_SPECIALITY = 'GETTER_DOCTORS_GROUPED_BY_SPECIALITY'
export const GETTER_CALENDAR_CURRENT_RESOURCES = 'GETTER_CALENDAR_CURRENT_RESOURCES'
export const GETTER_CALENDAR_ORDERED_RESOURCES = 'GETTER_CALENDAR_ORDERED_RESOURCES'
export const GETTER_CALENDAR_ORDERED_RESOURCES_BY_AVAILABILITY = 'GETTER_CALENDAR_ORDERED_RESOURCES_BY_AVAILABILITY'
export const GETTER_CALENDAR_DEVICES = 'GETTER_CALENDAR_DEVICES'
export const GETTER_SELECTED_DEVICES = 'GETTER_SELECTED_DEVICES'
export const GETTER_CALENDAR_STAFF = 'GETTER_CALENDAR_STAFF'
export const GETTER_CALENDAR_MIN_TIME = 'GETTER_CALENDAR_MIN_TIME'
export const GETTER_CALENDAR_MAX_TIME = 'GETTER_CALENDAR_MAX_TIME'
export const GETTER_SELECTED_FREESLOTS_SCHEDULE_IDS = 'GETTER_SELECTED_FREESLOTS_SCHEDULE_IDS'
export const GETTER_HIDDEN_DAYS = 'GETTER_HIDDEN_DAYS'
export const GETTER_NON_DOCTOR_SCHEDULES = 'GETTER_NON_DOCTOR_SCHEDULES'
export const GETTER_CALENDAR_SCHEDULES_ORDER = 'GETTER_CALENDAR_SCHEDULES_ORDER'

export const GETTER_CALENDAR_COLORS_THEME_TYPE = 'GETTER_CALENDAR_COLORS_THEME_TYPE'
export const GETTER_CALENDAR_THEME_LIGHT = 'GETTER_CALENDAR_THEME_LIGHT'
export const GETTER_CALENDAR_HAS_SERVICE_BY_COLORS = 'GETTER_CALENDAR_HAS_SERVICE_BY_COLORS'
export const GETTER_CALENDAR_COLOR_BY_TYPE = 'GETTER_CALENDAR_COLOR_BY_TYPE'
export const GETTER_CALENDAR_DENSITY = 'GETTER_CALENDAR_DENSITY'
export const GETTER_CALENDAR_ORDER = 'GETTER_CALENDAR_ORDER'
export const GETTER_CALENDAR_CELL_SIZE = 'GETTER_CALENDAR_CELL_SIZE'
export const GETTER_CALENDAR_24_MODE = 'GETTER_CALENDAR_24_MODE'
export const GETTER_CALENDAR_ZOOM_LEVEL = 'GETTER_CALENDAR_ZOOM_LEVEL'
export const GETTER_FREE_SLOTS_SELECTED_SERVICE = 'GETTER_SELECTED_SERVICE'
export const GETTER_CALENDAR_FREE_SLOTS_SERVICE = 'GETTER_CALENDAR_FREE_SLOTS_SERVICE'

export const GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE = 'GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE'
export const GETTER_GET_DOCTORS_STORED = 'GETTER_GET_DOCTORS_STORED'
export const GETTER_SELECTED_SCHEDULES = 'GETTER_SELECTED_SCHEDULES'
export const GETTER_SHOW_RESOURCE_WEEK = 'GETTER_SHOW_RESOURCE_WEEK'
export const GETTER_SCHEDULES_WORKING_CURRENT_PERIOD = 'GETTER_SCHEDULES_WORKING_CURRENT_PERIOD'
export const GETTER_RESOURCES_ID_SELECTED = 'GETTER_RESOURCES_ID_SELECTED'
export const GETTER_CALENDAR_EVENTS_DATE_RANGE = 'GETTER_CALENDAR_EVENTS_DATE_RANGE'
export const GETTER_SCHEDULES_FILTERED_AND_ORDERED = 'GETTER_SCHEDULES_FILTERED_AND_ORDERED'
export const GETTER_CALENDAR_SCHEDULES = 'GETTER_CALENDAR_SCHEDULES'
export const GETTER_RESOURCES_CUSTOM_ORDER_SET = 'GETTER_RESOURCES_CUSTOM_ORDER_SET'
