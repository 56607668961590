import repository from '../api/requests.api'
import { RequestsIntegration } from '../ui/interfaces/requests.interfaces'
import { RequestCategoryDto, RequestsPaginated } from './interfaces/requests.interfaces'

class RequestsService {
    async getProfilePrescriptionRequestEnabled(doctorId: string, userId?: string): Promise<boolean> {
        try {
            return await repository.getProfilePrescriptionRequestEnabled(doctorId, userId)
        } catch {
            return false
        }
    }

    setRequestFilters(filters: string): Promise<void> {
        return repository.setRequestFilters(filters)
    }

    setRequestOrderIsAscending(value: boolean): Promise<void> {
        return repository.setRequestOrderIsAscending(value)
    }

    async updateProfilePrescriptionRequestEnabled(isEnabled: boolean, doctorId: string): Promise<boolean> {
        try {
            await repository.updateProfilePrescriptionRequestEnabled(isEnabled, doctorId)
            return isEnabled
        } catch (error) {
            return !isEnabled
        }
    }

    getRequestList(
        pageNumber: number,
        filterStatus: string,
        doctorId: string,
        typeIds?: number[],
        userId?: string,
        isAscending?: boolean
    ): Promise<RequestsPaginated> {
        return repository.getRequestList(pageNumber, filterStatus, doctorId, typeIds, userId, isAscending)
    }

    moveTo(id: string, status: boolean, doctorId: string, userId?: string): Promise<RequestsPaginated> {
        return repository.moveTo(id, status, doctorId, userId)
    }

    markAsRead(id: string, doctorId: string, userId?: string): Promise<RequestsPaginated> {
        return repository.markAsRead(id, doctorId, userId)
    }

    countUnreadRequests(doctorId: string, userId?: string): Promise<number> {
        return repository.countUnreadRequests(doctorId, userId)
    }

    getAgreementValue(doctorId: string, userId?: string): Promise<boolean> {
        return repository.getAgreementValue(doctorId, userId)
    }

    acceptAgreement(doctorId: string): Promise<boolean> {
        return repository.acceptAgreement(doctorId)
    }

    getRequestsIntegration(docplannerDoctorId: string): Promise<RequestsIntegration> {
        return repository.getRequestsIntegration(docplannerDoctorId)
    }

    async updateRequestCategories(docplannerDoctorId: string, categories: RequestCategoryDto[]): Promise<void> {
        return repository.updateRequestCategories(docplannerDoctorId, categories)
    }

    updateAllowRequestsFromNewPatients(
        docplannerDoctorId: string,
        allowRequestsFromNewPatient: boolean,
        isRequestFeatureEnabled: boolean
    ): Promise<void> {
        return repository.updateAllowRequestsFromNewPatients(
            docplannerDoctorId,
            allowRequestsFromNewPatient,
            isRequestFeatureEnabled
        )
    }
}

export default new RequestsService()
