import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { DateFormat, formatDate } from '@/core/ui/utils'

import {
    PaymentMethod,
    SecretaryAppointment,
    SecretaryAppointmentResponse,
    SecretaryHeader,
    SecretaryViewFilters,
    SecretaryViewUpdateServices,
    UpdateAppointmentPaymentStatus
} from '../domain/interfaces/secretaryView.interfaces'

const URL_BASE_SECRETARY_VIEW = 'secretaryview'
const URL_BASE_APPOINTMENTS = 'appointments'
const URL_BASE_PAYMENT_METHODS = 'paymentmethods'

const URL_SECRETARY_TABLE_HEADERS = `${URL_BASE_SECRETARY_VIEW}/headers`

const URL_SECRETARY_VIEW = `${URL_BASE_APPOINTMENTS}/${URL_BASE_SECRETARY_VIEW}`

const URL_UPDATE_CHECK_IN_STATUS = `v2/${URL_BASE_APPOINTMENTS}/:appointmentId/attendance/:attendance`
const URL_UPDATE_PAYMENT_METHOD = `${URL_BASE_APPOINTMENTS}/:appointmentId/paymentmethod/:paymentMethod`
const URL_DELETE_PAYMENT_METHOD = `${URL_BASE_APPOINTMENTS}/:appointmentId/paymentmethod`
const URL_UPDATE_PAYMENT_STATUS = `${URL_BASE_APPOINTMENTS}/:appointmentId/payment-status/:paymentStatus`
const URL_DELETE_APPOINTMENT = `${URL_BASE_APPOINTMENTS}/deleteappointment/:appointmentId`
const URL_PATCH_APPOINTMENT_COMMENT = `${URL_BASE_APPOINTMENTS}/:appointmentId/comments`
const URL_PATCH_APPOINTMENT_SERVICES = `${URL_BASE_APPOINTMENTS}/:appointmentId/event-services`
const URL_CONFIRM_APPOINTMENT = `${URL_BASE_APPOINTMENTS}/:appointmentId/manuallyConfirm`

class SecretaryViewRepository {
    async getAll(selectedDate: Date, filters?: SecretaryViewFilters): Promise<SecretaryAppointment[]> {
        const date = formatDate(selectedDate, DateFormat.DateDashedFormat)

        const url = getUrlFromTemplate({
            template: URL_SECRETARY_VIEW
        })
        const { data } = await httpAPI.post(url, { date, ...filters })
        return data
    }

    async getHeaders(): Promise<SecretaryHeader[]> {
        const { data } = await httpAPI.get(URL_SECRETARY_TABLE_HEADERS)
        return data
    }

    async saveColumns(headers: SecretaryHeader[]): Promise<void> {
        await httpAPI.put(URL_SECRETARY_TABLE_HEADERS, headers)
    }

    async updateCheckInStatus(appointmentId: number, attendance: number, selectedDate: Date): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_CHECK_IN_STATUS,
            params: { appointmentId, attendance }
        })
        const date = formatDate(selectedDate, DateFormat.DateDashedFormat)
        const currentTime = formatDate(new Date(), DateFormat.HourMinuteSecondFormat)

        // We cant use query option of getUrlFromTemplate because the time format has
        // `hh:mm` and the util things that is a parameter
        await httpAPI.patch(`${url}?date=${`${date}T${currentTime}`}`)
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        const { data } = await httpAPI.get(URL_BASE_PAYMENT_METHODS)
        return data
    }

    async updatePaymentMethods(appointmentId: number, paymentMethod: number): Promise<Record<string, number>> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_PAYMENT_METHOD,
            params: { appointmentId, paymentMethod }
        })
        const {
            data: { paymentStatus, amountPaid }
        } = await httpAPI.patch(url)
        return { paymentStatus, amountPaid }
    }

    async deletePaymentMethods(appointmentId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DELETE_PAYMENT_METHOD,
            params: { appointmentId }
        })
        await httpAPI.delete(url)
    }

    async updatePaymentStatus(
        appointmentId: number | string,
        paymentStatus: number
    ): Promise<UpdateAppointmentPaymentStatus> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_PAYMENT_STATUS,
            params: { appointmentId, paymentStatus }
        })
        const {
            data: { paymentStatus: paymentStatusId, paymentMethod, amountPaid }
        } = await httpAPI.patch(url)
        return { paymentStatus: paymentStatusId, paymentMethod, amountPaid }
    }

    async deleteAppointment(appointmentId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DELETE_APPOINTMENT,
            params: { appointmentId }
        })
        await httpAPI.delete(url)
    }

    async updateAppointmentComment(appointmentId: number, comment: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_PATCH_APPOINTMENT_COMMENT,
            params: { appointmentId }
        })
        // BE uses comments as a payload
        await httpAPI.patch(url, { comments: comment })
    }

    async updateAppointmentServices(
        appointmentId: number | string,
        params: SecretaryViewUpdateServices[]
    ): Promise<SecretaryAppointmentResponse> {
        const url = getUrlFromTemplate({
            template: URL_PATCH_APPOINTMENT_SERVICES,
            params: { appointmentId }
        })

        const { data } = await httpAPI.patch(url, params)
        return data
    }

    async confirmAppointment(appointmentId: number): Promise<SecretaryAppointmentResponse> {
        const url = getUrlFromTemplate({
            template: URL_CONFIRM_APPOINTMENT,
            params: { appointmentId }
        })
        const { data } = await httpAPI.patch(url)
        return data
    }
}

export default new SecretaryViewRepository()
