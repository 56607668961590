import { getUrlFromTemplate } from '@dp-vue/utils'

import { dataURLtoFile } from '@/api/utils/dataURLtoFile'
import httpAPI from '@/api/utils/httpClientAPI'
import { DATA_URL_PREFIX } from '@/constants/files'
import { PaginatedAppointmentEventData } from '@/features/calendar/domain/interfaces/appointment.interfaces'
import { Proposal, ProposalResponse } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import {
    ListPageRequest,
    MassiveDeleteRequest,
    PatientAppointmentsParams,
    PatientAvailableSlotsDTO,
    PatientContactInformationDTO,
    PatientListPage,
    PatientReviewStatusDTO,
    PatientSearchWithAppointmentsDTO,
    UTMParameters
} from '@/features/patient/api/interfaces/patient.interfaces'
import { PatientSlotsQuery } from '@/features/patient/api/types/patient.types'
import { ImportableFacility } from '@/features/patient/domain/interfaces/facility.interfaces'
import {
    CheckinInfo,
    CreatePatient,
    IFinancialSummaryResponse,
    IFinancialVisit,
    IFinancialVisitResponse,
    Patient,
    PatientConflicts,
    PatientEvent,
    PatientLogFilter,
    PatientScreenshot,
    PatientScreenshotProps,
    SearchCriteria,
    SearchPatient,
    SearchResultItem
} from '@/features/patient/domain/interfaces/patient.interfaces'
import { PAGE_SIZE } from '@/features/patient/ui/constants/finance.constants'
import { parseParams } from '@/utils/parseParams'

const URL_BASE_PATIENTS = 'patients'
const URL_BASE_PATIENTS_V2 = 'v2/patients'
const URL_BASE_DOCTOR_CHECK_IN = 'doctorcheckin'
const URL_BASE_PATIENT_ONLINE_BOOKING = 'patientonlinebooking'
const URL_BASE_CHAT = 'chat'

const URL_MASSIVE_DELETE = `${URL_BASE_PATIENTS}/massive-delete`
const URL_PAGED_LIST = `${URL_BASE_PATIENTS}/pagedList`
const URL_SUGGESTIONS = `${URL_BASE_PATIENTS}/search`
const URL_SEARCH_WITH_APPOINTMENTS = `${URL_BASE_PATIENTS}/search/withappointments`
const URL_CAN_IMPORT_TO = `${URL_BASE_PATIENTS}/import/for`
const URL_PICTURE = `${URL_BASE_PATIENTS}/:id/picture`
const URL_MERGE_COMPARISION = `${URL_BASE_PATIENTS}/:patient1Id/compare/:patient2Id`
const URL_MERGE_PATIENTS = `${URL_BASE_PATIENTS}/:patient1Id/merge/:patient2Id`
const URL_LOGS = `${URL_BASE_PATIENTS}/:id/logs`
const URL_LOGS_PATIENT = `${URL_LOGS}/data`

const URL_PATIENT_EVENTS = `${URL_BASE_PATIENTS_V2}/:patientId/events`
const URL_PATIENT_COUNTS = `${URL_BASE_PATIENTS_V2}/:patientId/counts`

const URL_PATIENT_ID = `${URL_BASE_PATIENTS}/:patientId`
const URL_REQUEST_PATIENT_REVIEW = `${URL_PATIENT_ID}/requestPatientReview`
const URL_APPOINTMENT_REMINDER_SINGLE = `${URL_PATIENT_ID}/appointmentReminder/:proposalId`
const URL_APPOINTMENT_REMINDER = `${URL_PATIENT_ID}/appointmentReminder`
const URL_UPLOAD_FILE = `${URL_PATIENT_ID}/files`
const URL_SEND_CHAT_MESSAGE = `${URL_PATIENT_ID}/messages`
const URL_FINANCIAL_VISITS = `${URL_PATIENT_ID}/financial`
const URL_FINANCIAL_SUMMARY = `${URL_PATIENT_ID}/financial/summary`
const URL_FINANCIAL_VISIT_SINGLE = `${URL_PATIENT_ID}/financial/:visitId`
const URL_EVENTS_INFO = `${URL_PATIENT_ID}/events/:eventId`
const URL_SLOTS = `${URL_PATIENT_ID}/slots`
const URL_CONTACT_INFORMATION = `${URL_PATIENT_ID}/contact-information`
const URL_PATIENT_DOCUMENTATION_DATA = `${URL_PATIENT_ID}/documentation-data`

const URL_BASE_DOCTOR_CHECK_IN_ID = `${URL_BASE_DOCTOR_CHECK_IN}/:checkinId`

const URL_PATIENT_ONLINE_BOOKING_BLOCK = `${URL_BASE_PATIENT_ONLINE_BOOKING}/block`
const URL_PATIENT_ONLINE_BOOKING_UNBLOCK = `${URL_BASE_PATIENT_ONLINE_BOOKING}/unblock`

const URL_BLOCK_CONVERSATION = `${URL_BASE_CHAT}/${URL_BASE_PATIENTS}/:patientId/conversation/block`
const URL_UNBLOCK_CONVERSATION = `${URL_BASE_CHAT}/${URL_BASE_PATIENTS}/:patientId/conversation/unblock`

const URL_GET_REVIEW_STATUS = `${URL_BASE_PATIENTS}/:patientId/reviewRequest`

class PatientRepository {
    // DOUBT: do we need default values for page properties?
    async getPatients(page: ListPageRequest): Promise<PatientListPage> {
        const { data } = await httpAPI.post(URL_PAGED_LIST, page)
        return data
    }

    async getPatient(patientId: number): Promise<Patient> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_ID,
            params: {
                patientId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getFinancialVisits(
        patientId: number,
        pageNumber = 0,
        pageSize: number = PAGE_SIZE
    ): Promise<IFinancialVisitResponse> {
        const url = getUrlFromTemplate({
            template: URL_FINANCIAL_VISITS,
            params: {
                patientId
            }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                pageNumber,
                pageSize
            }
        })

        return data
    }

    async getFinancialSummary(patientId: number): Promise<IFinancialSummaryResponse> {
        const url = getUrlFromTemplate({
            template: URL_FINANCIAL_SUMMARY,
            params: {
                patientId
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async getFinancialVisitById(patientId: number, visitId: number): Promise<IFinancialVisit> {
        const url = getUrlFromTemplate({
            template: URL_FINANCIAL_VISIT_SINGLE,
            params: {
                patientId,
                visitId
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    updatePatient(patientId: number, data: any): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_ID,
            params: { patientId: patientId.toString() }
        })
        return httpAPI.patch(url, data)
    }

    async createPatient(patient: CreatePatient): Promise<number> {
        const { data } = await httpAPI.post(URL_BASE_PATIENTS, patient)
        return data
    }

    async getPatientPicture(patientId: number): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_PICTURE,
            params: {
                id: patientId.toString()
            }
        })
        const { data } = await httpAPI.get(url, {
            responseType: 'arraybuffer'
        })
        return data
    }

    async changePatientPicture(patientId: number, fileName: string, image: File): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PICTURE,
            params: {
                id: patientId.toString()
            }
        })

        const formData = new FormData()
        formData.append('file', image)

        const { data } = await httpAPI.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Docplanner-File': `fileName=${fileName};source=patient`
            }
        })

        return data
    }

    async searchWithAppointments(filter: string, results: number): Promise<PatientSearchWithAppointmentsDTO[]> {
        const { data } = await httpAPI.get(URL_SEARCH_WITH_APPOINTMENTS, {
            params: {
                filter,
                results
            }
        })

        return data
    }

    async searchSuggestions(patient: SearchCriteria): Promise<SearchResultItem[]> {
        const { data } = await httpAPI.post(URL_SUGGESTIONS, patient)
        return data
    }

    async searchSuggestionsFilter(filter: string, options = {}): Promise<SearchPatient[]> {
        const { data } = await httpAPI.get(URL_SUGGESTIONS, {
            params: { filter, ...options }
        })
        return data
    }

    async getFacilitiesWhereCanImport(): Promise<ImportableFacility[]> {
        const { data } = await httpAPI.get(URL_CAN_IMPORT_TO)
        return data
    }

    async getPatientComparisionForMerge(patient1Id: string, patient2Id: string): Promise<PatientConflicts> {
        const url = getUrlFromTemplate({
            template: URL_MERGE_COMPARISION,
            params: {
                patient1Id,
                patient2Id
            }
        })

        const { data } = await httpAPI.post(url)
        return data
    }

    async mergePatients(patient1Id: string, patient2Id: string, overrides: string[]): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_MERGE_PATIENTS,
            params: {
                patient1Id,
                patient2Id
            }
        })

        const { data } = await httpAPI.post(url, {
            overrides
        })
        return data
    }

    async getCheckinInfo(checkinId: string): Promise<CheckinInfo> {
        const url = getUrlFromTemplate({
            template: URL_BASE_DOCTOR_CHECK_IN_ID,
            params: {
                checkinId
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async getLogs(patientId: number, filter: PatientLogFilter): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_LOGS,
            params: {
                id: patientId.toString()
            }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                filterType: filter.type,
                filterDate: filter.date
            }
        })

        return data
    }

    async getLogsPatient(patientId: number, filter: PatientLogFilter): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_LOGS_PATIENT,
            params: {
                id: patientId.toString()
            }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                filterType: filter.type,
                filterDate: filter.date
            }
        })

        return data
    }

    async getProposals(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_REMINDER,
            params: {
                patientId: patientId.toString()
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async createProposal(patientId: number, proposal: Proposal): Promise<ProposalResponse> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_REMINDER,
            params: {
                patientId: patientId.toString()
            }
        })
        const { data } = await httpAPI.post(url, proposal)
        return data
    }

    async deleteProposal(patientId: number, proposalId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_REMINDER_SINGLE,
            params: {
                patientId: patientId.toString(),
                proposalId: proposalId.toString()
            }
        })
        const { data } = await httpAPI.delete(url)
        return data
    }

    async updateProposal(proposalId: number, patientId: number, proposal: Proposal): Promise<ProposalResponse> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_REMINDER_SINGLE,
            params: {
                patientId: patientId.toString(),
                proposalId: proposalId?.toString()
            }
        })
        const { data } = await httpAPI.put(url, proposal)

        return data
    }

    async requestPatientReview(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_REQUEST_PATIENT_REVIEW,
            params: {
                patientId: patientId.toString()
            }
        })

        const { data } = await httpAPI.put(url)
        return data
    }

    async deletePatient(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_ID,
            params: { patientId: patientId.toString() }
        })
        const { data } = await httpAPI.delete(url)
        return data
    }

    deletePatients(patientsIds: string[]): Promise<void> {
        return httpAPI.delete(URL_BASE_PATIENTS, { data: patientsIds })
    }

    massiveDelete(data: MassiveDeleteRequest): Promise<void> {
        return httpAPI.delete(URL_MASSIVE_DELETE, { data })
    }

    async blockOnlineBooking(patientId: number): Promise<any> {
        const config = {
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        }
        const { data } = await httpAPI.post(URL_PATIENT_ONLINE_BOOKING_BLOCK, patientId, config)
        return data
    }

    async unBlockOnlineBooking(patientId: number): Promise<any> {
        const config = {
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        }
        const { data } = await httpAPI.post(URL_PATIENT_ONLINE_BOOKING_UNBLOCK, patientId, config)
        return data
    }

    async blockConversationOption(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_BLOCK_CONVERSATION,
            params: {
                patientId: patientId.toString()
            }
        })

        const { data } = await httpAPI.post(url, patientId)

        return data
    }

    async unblockConversationOption(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_UNBLOCK_CONVERSATION,
            params: {
                patientId: patientId.toString()
            }
        })

        const { data } = await httpAPI.post(url, patientId)

        return data
    }

    async savePatientScreenshot({
        patientId,
        imageData,
        source,
        fileName
    }: PatientScreenshotProps): Promise<PatientScreenshot> {
        const url = getUrlFromTemplate({
            template: URL_UPLOAD_FILE,
            params: {
                patientId
            }
        })

        const fileNameBase64 = btoa(fileName)
        const image = dataURLtoFile(DATA_URL_PREFIX + imageData)
        const formData = new FormData()

        formData.append('file', image, fileNameBase64)

        return httpAPI.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Docplanner-File': `fileName=${fileNameBase64};source=${source}`
            }
        })
    }

    buildUtmInfo(utm: UTMParameters): string {
        return encodeURI(`?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`)
    }

    async getEventBasicInfo(patientId: number, eventId: number, utm?: UTMParameters): Promise<PatientEvent> {
        const utmInfo = utm ? this.buildUtmInfo(utm) : ''
        const template = `${URL_EVENTS_INFO}${utmInfo}`
        const url = getUrlFromTemplate({
            template,
            params: {
                patientId,
                eventId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async sendChatMessage(patientId: number, comments: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SEND_CHAT_MESSAGE,
            params: {
                patientId
            }
        })

        await httpAPI.post(url, { content: comments })
    }

    async getNextPrevAvailableSlotByPatient(
        patientId: number,
        query?: PatientSlotsQuery
    ): Promise<PatientAvailableSlotsDTO> {
        const url = getUrlFromTemplate({
            template: URL_SLOTS,
            params: {
                patientId
            },
            query: parseParams({ ...query })
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getPatientContactInformation(patientId: number): Promise<PatientContactInformationDTO> {
        const url = getUrlFromTemplate({
            template: URL_CONTACT_INFORMATION,
            params: {
                patientId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getPatientAppointments(
        patientId: number,
        params: PatientAppointmentsParams
    ): Promise<PaginatedAppointmentEventData> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_EVENTS,
            params: { patientId }
        })
        const { page, pageSize, eventTime, date, orderingDirection, notCanceled } = params
        const { data } = await httpAPI.get(url, {
            params: {
                page,
                pageSize,
                [eventTime]: date,
                orderingDirection,
                notCanceled
            }
        })

        return data
    }

    async getPatientDocumentationData(patientId: number): Promise<Record<string, string>> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_DOCUMENTATION_DATA,
            params: { patientId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getPatientTabsCounts(patientId: number): Promise<Record<string, number>> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_COUNTS,
            params: { patientId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getPatientReviewStatus(patientId: number): Promise<PatientReviewStatusDTO> {
        const url = getUrlFromTemplate({
            template: URL_GET_REVIEW_STATUS,
            params: { patientId }
        })
        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new PatientRepository()
