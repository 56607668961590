import AuthService from '@/features/auth/domain/auth.service'
import { SSO_RENEW_STATE, TOKEN_TYPE } from '@/features/auth/domain/constants/auth.constants'
import { origin, redirectUri } from '@/utils/url'

async function SSORenew(code: string) {
    const { saasToken, marketplaceToken } = await AuthService.getWebToken(<string>code, redirectUri)
    window.parent.postMessage(
        {
            type: SSO_RENEW_STATE,
            payload: {
                saasToken,
                mkplToken: marketplaceToken
            }
        },
        origin
    )
}

export default SSORenew
