import { getDefaultState } from './state'
import { MutationTypes, SecretaryViewMutations } from './types'

export const mutations: SecretaryViewMutations = {
    [MutationTypes.SetSecretaryViewAppointments](state, appointments) {
        state.appointments = appointments
    },
    [MutationTypes.SetSecretaryViewHeaders](state, headers) {
        state.headers = headers
    },
    [MutationTypes.SetTableLoading](state, isTableLoading) {
        state.isTableLoading = isTableLoading
    },
    [MutationTypes.ResetState](state) {
        Object.assign(state, getDefaultState())
    },
    [MutationTypes.SetPaymentMethods](state, paymentMethods) {
        state.paymentMethods = paymentMethods
    },
    [MutationTypes.AddRowSelected](state, idSelected) {
        state.rowsSelected = [...state.rowsSelected, idSelected]
    },
    [MutationTypes.RemoveRowSelected](state, idSelected) {
        state.rowsSelected = state.rowsSelected.filter(id => id !== idSelected)
    },
    [MutationTypes.ToggleAllRowsSelected](state, shouldSelectAll = false) {
        if (shouldSelectAll) {
            state.rowsSelected = state.appointments.map(({ id }) => id)
        } else {
            state.rowsSelected = []
        }
    },
    [MutationTypes.SetFilters](state, filters) {
        state.filters = filters
    },
    [MutationTypes.SetIsChatPopoverOpen](state, isChatPopoverOpen) {
        state.isChatPopoverOpen = isChatPopoverOpen
    },
    [MutationTypes.SetIsBulkCancelationPopoverOpen](state, isBulkCancelationPopoverOpen) {
        state.isBulkCancelationPopoverOpen = isBulkCancelationPopoverOpen
    },
    [MutationTypes.UpdateSecretaryViewAppointment](state, { appointmentId, status, bookingStatusTooltip }) {
        const appointmentToUpdate = state.appointments.find(({ id }) => id === appointmentId)
        if (appointmentToUpdate) {
            appointmentToUpdate.status = status
            appointmentToUpdate.bookingStatusTooltip = bookingStatusTooltip
        }
    }
}
