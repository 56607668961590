import monitor from '@/bootstrap/monitor/monitor'
import { usePackaging } from '@/features/packaging/composables/usePackaging'

export default async function packageFeaturesMiddleware({ next }: any) {
    try {
        const { initializePackaging, packagingState, canUsePackaging } = usePackaging()

        if (canUsePackaging() && !packagingState.isInitialized) {
            await initializePackaging()
        }
    } catch (e: any) {
        e.message = `Package middleware error: ${e.message}`
        monitor.sendException(e)
    }

    next()
}
