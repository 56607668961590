import { getUrlFromTemplate } from '@dp-vue/utils'
import queryString from 'query-string'

import httpAPI from '@/api/utils/httpClientAPI'
import httpClientPatientRequestAPI from '@/api/utils/httpClientPatientRequestAPI'

import { RequestFilterStatus } from '../domain/enums/requests.enums'
import { RequestCategoryDto, RequestsIntegrationDto, RequestsPaginated } from '../domain/interfaces/requests.interfaces'

const URL_BASE_V2 = 'v2/doctors/:doctorId'
const URL_BASE_REQUESTS = `${URL_BASE_V2}/requests`
const URL_BASE_AGREEMENT = `${URL_BASE_V2}/agreement`
const URL_BASE_SETTINGS = `/settings`

const URL_REQUESTS_ENABLED = `${URL_BASE_REQUESTS}/enabled`
const URL_GET_REQUESTS = URL_BASE_REQUESTS
const URL_COUNT_UNREAD_REQUESTS = `${URL_BASE_REQUESTS}/unread/count`
const URL_MARK_PATIENT_REQUEST_AS_READ = `${URL_BASE_REQUESTS}/:requestId/read`
const URL_RESOLVE_PATIENT_REQUEST = `${URL_BASE_REQUESTS}/:requestId/resolve`

const URL_IS_AGREEMENT_ACCEPTED = URL_BASE_AGREEMENT
const URL_ACCEPT_AGREEMENT = URL_BASE_AGREEMENT

const URL_SET_REQUEST_FILTERS = `${URL_BASE_SETTINGS}/requestsFilters`
const URL_SET_REQUEST_ORDER_IS_ASCENDING = `${URL_BASE_SETTINGS}/requestsOrderIsAscending`

const URL_REQUESTS_INTEGRATION = `${URL_BASE_V2}/requests/integration`
const URL_REQUESTS_CATEGORIES = `${URL_BASE_V2}/categories`

const PAGE_SIZE = 20

class RequestsRepository {
    async getProfilePrescriptionRequestEnabled(doctorId: string, userId?: string): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_REQUESTS_ENABLED,
            params: { doctorId },
            query: userId ? { userId } : undefined
        })

        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async updateProfilePrescriptionRequestEnabled(isEnabled: boolean, doctorId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: `${URL_REQUESTS_ENABLED}/${isEnabled}`,
            params: { doctorId }
        })
        return httpClientPatientRequestAPI.put(url)
    }

    async getRequestList(
        pageNumber: number,
        filterStatus: string,
        doctorId: string,
        typeIds?: number[],
        userId?: string,
        isAscending?: boolean
    ): Promise<RequestsPaginated> {
        const templateUrl = getUrlFromTemplate({
            template: URL_GET_REQUESTS,
            params: { doctorId }
        })

        // add typeIds in the query obj only if any
        const query = {
            pageNumber,
            filterStatus,
            ...(typeIds && { typeIds }),
            pageSize: PAGE_SIZE,
            userId,
            isAscending
        }
        const url = `${templateUrl}?${queryString.stringify(query)}`
        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async moveTo(requestId: string, status: boolean, doctorId: string, userId?: string) {
        const statusSelected = status ? RequestFilterStatus.Resolved : RequestFilterStatus.Pending

        const url = getUrlFromTemplate({
            template: URL_RESOLVE_PATIENT_REQUEST,
            params: { requestId, doctorId },
            query: userId ? { rollbackresolution: statusSelected, userId } : { rollbackresolution: statusSelected }
        })
        const { data } = await httpClientPatientRequestAPI.put(url)
        return data
    }

    async setRequestFilters(filters: string): Promise<void> {
        await httpAPI.post(URL_SET_REQUEST_FILTERS, { value: filters })
        return httpAPI.get(URL_SET_REQUEST_FILTERS)
    }

    async setRequestOrderIsAscending(value: boolean): Promise<void> {
        await httpAPI.post(URL_SET_REQUEST_ORDER_IS_ASCENDING, { value })
        return httpAPI.get(URL_SET_REQUEST_ORDER_IS_ASCENDING)
    }

    async markAsRead(requestId: string, doctorId: string, userId?: string) {
        const url = getUrlFromTemplate({
            template: URL_MARK_PATIENT_REQUEST_AS_READ,
            params: { requestId, doctorId },
            query: userId ? { userId } : undefined
        })

        const { data } = await httpClientPatientRequestAPI.put(url)
        return data
    }

    async countUnreadRequests(doctorId: string, userId?: string) {
        const url = getUrlFromTemplate({
            template: URL_COUNT_UNREAD_REQUESTS,
            params: {
                doctorId
            },
            query: userId ? { userId } : undefined
        })

        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async getAgreementValue(doctorId: string, userId?: string): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_IS_AGREEMENT_ACCEPTED,
            params: { doctorId },
            query: userId ? { userId } : undefined
        })

        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async acceptAgreement(doctorId: string): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_ACCEPT_AGREEMENT,
            params: { doctorId }
        })

        const { data } = await httpClientPatientRequestAPI.put(url)
        return data
    }

    async getRequestsIntegration(docplannerDoctorId: string): Promise<RequestsIntegrationDto> {
        const url = getUrlFromTemplate({
            template: URL_REQUESTS_INTEGRATION,
            params: { doctorId: docplannerDoctorId }
        })

        const { data } = await httpClientPatientRequestAPI.get(url)
        return data
    }

    async updateRequestCategories(docplannerDoctorId: string, categories: RequestCategoryDto[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REQUESTS_CATEGORIES,
            params: { doctorId: docplannerDoctorId }
        })

        const { data } = await httpClientPatientRequestAPI.put(url, { categories })
        return data
    }

    async updateAllowRequestsFromNewPatients(
        docplannerDoctorId: string,
        allowRequestsFromNewPatient: boolean,
        isRequestFeatureEnabled: boolean
    ): Promise<void> {
        const url = getUrlFromTemplate({
            template: `${URL_REQUESTS_ENABLED}/${isRequestFeatureEnabled}`,
            params: { doctorId: docplannerDoctorId },
            query: {
                AllowRequestsFromNewPatient: allowRequestsFromNewPatient.toString()
            }
        })

        const { data } = await httpClientPatientRequestAPI.put(url)
        return data
    }
}

export default new RequestsRepository()
