import { QUERY_STRING_PREFIX } from '../constants'

export const getQueryStringValue = (keyToFind, queryString = '') => {
    const queries = queryString.substr(1).split('&')
    const actualKey = QUERY_STRING_PREFIX + keyToFind

    const found = queries.reduce((output, query) => {
        const [key, value] = query.split('=')

        if (key === actualKey) {
            return value
        }
        return output
    }, null)

    return found
}
