export const USER_ID = 'userId'
export const MOBILE_REDIRECT = 'mobileRedirect'
export const REDIRECT_URL = 'redirectUrl'
export const PATIENTS_LIST_ORDER = 'patient.list.sorting'
export const SELECTED_TRANSLATION = 'selected-translation'
export const OVERRIDE_SELECTED_COUNTRY_SETTINGS = 'override-selected-country-settings'
export const OVERRIDE_SELECTED_LOGGING = 'override-selected-logging'
export const LAST_SELECTED_PATIENT_TAB = 'last-selected-patient-tab'
export const IS_SIDEBAR_COLLAPSED = 'is-sidebar-collapsed'
export const STORAGE_KEY_E2E_ENVIRONMENT = 'is-e2e-environment'
export const BOOKING_ANSWERS_MORE_INFO = 'booking-answers-more-info'
export const SEEN_EPISODE_CREATED_EPISODE_IDS = 'seen-episode-created-episode-ids'
export const HIDE_SHARE_EPISODE_THROUGH_CHAT_MODAL = 'hide-share-episode-through-chat-modal'
export const COUNT_AUTO_SHARE_PRESCRIPTIONS_MODAL = 'count-auto-share-prescriptions-modal'
