import {
    GETTER_USERS_AVAILABLE_PLUGINS,
    GETTER_USERS_FACILITY_NOTIFICATIONS,
    GETTER_USERS_LOADING,
    GETTER_USERS_SCHEDULES,
    GETTER_USERS_USERS
} from './types/getter'

const getters = {
    [GETTER_USERS_FACILITY_NOTIFICATIONS](state) {
        return state.facilityNotifications
    },
    [GETTER_USERS_SCHEDULES](state) {
        return state.schedules
    },
    [GETTER_USERS_USERS](state) {
        return state.users
    },
    [GETTER_USERS_LOADING](state) {
        return state.usersLoading
    },
    [GETTER_USERS_AVAILABLE_PLUGINS](state) {
        return state.availablePlugins
    }
}

export default getters
