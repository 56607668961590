import {
    ScheduleExtended,
    Voucher,
    VouchersFilters,
    VoucherType
} from '@/features/voucher/domain/interfaces/voucher.interfaces'

export enum GetterTypes {
    GetCurrentPage = 'GET_CURRENT_PAGE',
    GetHasFullyRedeemedVoucher = 'GET_HAS_FULLY_REDEEMED_VOUCHER',
    GetLoading = 'GET_LOADING',
    GetLoadingVoucherTypes = 'GET_LOADING_VOUCHER_TYPES',
    GetScheduleList = 'GET_SCHEDULE_LIST',
    GetShowActive = 'GET_SHOW_ACTIVE',
    GetTotalPage = 'GET_TOTAL_PAGE',
    GetVoucherTypes = 'GET_VOUCHER_TYPES',
    GetVoucherTypesPaginated = 'GET_VOUCHER_TYPES_PAGINATED',
    GetVouchers = 'GET_VOUCHERS',
    GetVouchersFilters = 'GET_VOUCHERS_FILTERS'
}
export interface VouchersGetter {
    [GetterTypes.GetVouchers]: Voucher[]
    [GetterTypes.GetVoucherTypesPaginated]: VoucherType[]
    [GetterTypes.GetVoucherTypes]: VoucherType[]
    [GetterTypes.GetLoading]: boolean
    [GetterTypes.GetLoadingVoucherTypes]: boolean
    [GetterTypes.GetShowActive]: boolean
    [GetterTypes.GetCurrentPage]: number
    [GetterTypes.GetTotalPage]: number
    [GetterTypes.GetVouchersFilters]: VouchersFilters
    [GetterTypes.GetScheduleList]: ScheduleExtended[]
    [GetterTypes.GetHasFullyRedeemedVoucher]: boolean
}
