import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import PatientService from '@/features/patient/domain/patient.service'
import EHRTemplatesService from '@/features/pms/domain/ehrTemplates.service'
import PmsService from '@/features/pms/domain/pms.service'

import {
    ACTION_FETCH_EPISODE_TYPES,
    ACTION_FETCH_PATIENT,
    ACTION_FETCH_SERVICES,
    ACTION_FETCH_SNIPPETS,
    ACTION_FETCH_TEMPLATE_SMART_PROPERTIES,
    ACTION_FETCH_TEMPLATES
} from './types/actions'
import {
    MUTATION_SET_EPISODE_TYPES,
    MUTATION_SET_PATIENT,
    MUTATION_SET_SERVICES,
    MUTATION_SET_SNIPPETS,
    MUTATION_SET_TEMPLATE_SMART_PROPERTIES,
    MUTATION_SET_TEMPLATES
} from './types/mutations'

const actions = {
    async [ACTION_FETCH_PATIENT]({ commit, rootGetters }, patientId) {
        const patient = await PatientService.getPatient(patientId, {
            showNameBeforeSurname: rootGetters[AuthGetterTypes.ShowNameBeforeSurname]
        })
        commit(MUTATION_SET_PATIENT, patient)
        return patient
    },
    async [ACTION_FETCH_SERVICES]({ commit }) {
        const services = await PmsService.getServices()
        commit(MUTATION_SET_SERVICES, services)
    },
    async [ACTION_FETCH_SNIPPETS]({ commit }) {
        const snippets = await PmsService.getSnippets()
        commit(MUTATION_SET_SNIPPETS, snippets)
    },
    async [ACTION_FETCH_TEMPLATES]({ commit }) {
        const templates = await EHRTemplatesService.getTemplates()
        commit(MUTATION_SET_TEMPLATES, templates)
    },
    async [ACTION_FETCH_TEMPLATE_SMART_PROPERTIES]({ commit }) {
        const properties = await EHRTemplatesService.getSmartTextProperties()
        commit(MUTATION_SET_TEMPLATE_SMART_PROPERTIES, properties)
    },
    async [ACTION_FETCH_EPISODE_TYPES]({ commit }) {
        const episodeTypes = await PmsService.getEpisodeTypes()

        commit(MUTATION_SET_EPISODE_TYPES, episodeTypes)
    }
}

export default actions
