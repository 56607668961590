import SSOCookieService from '@/core/api/utils/ssoCookie.service'

const RENEW_TOKEN_ATTEMPTS = 3

class RenewToken {
    addClient(httpClient) {
        const retry = RENEW_TOKEN_ATTEMPTS
        let currentRetryAttempt = 0
        httpClient.addResponseInterceptor(null, error => {
            return new Promise((resolve, reject) => {
                if (error?.response?.status === 401) {
                    SSOCookieService.getNewToken()
                        .then(() => {
                            currentRetryAttempt += 1
                            if (currentRetryAttempt < retry) {
                                httpClient.instance
                                    .request(error.config)
                                    .then(response => {
                                        resolve(response)
                                    })
                                    .finally(() => {
                                        currentRetryAttempt = 0
                                    })
                            } else {
                                reject(error)
                            }
                        })
                        .catch(err => {
                            reject(err)
                        })
                } else {
                    reject(error)
                }
            })
        })
    }
}

export default new RenewToken()
