import tinycolor from 'tinycolor2'

import { date, DateFormat } from '@/core/ui/utils'
import { sanitize } from '@/core/ui/utils/sanitize.utils'
import { ColorSchemasEntity } from '@/features/rooms/domain/interfaces/rooms.interface'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'
import { ScheduleWorkPeriod, ScheduleWorkPeriods } from '@/features/settings/domain/interfaces/schedule.interfaces'
import {
    ADMITTED_PATIENTS_ALL,
    DAYS_NUMBER_OF_WEEK,
    DEFAULT_END_HOUR,
    DEFAULT_START_HOUR,
    INSURANCES_RADIO_BOTH,
    PERIODICITY_REPEAT_EVERY_WEEK
} from '@/features/settings/ui/constants'

import { VisibilityType } from '../../settings/domain/enums'
import calendarEventService from './calendar.event.service'
import { CALENDAR_PREVIEW_ID } from './constants/calendar.events.constants'
import { CalendarEventDisplayType, CalendarEventType } from './enums/calendarEvent.enums'
import { WorkPeriodDTO, WorkPeriodEvent } from './interfaces/workperiod.interfaces'

class CalendarWorkPeriodsService {
    prepareEmptyWorkperiodsData(): ScheduleWorkPeriods[] {
        return new Array(DAYS_NUMBER_OF_WEEK).fill(0).map((_, ind) => {
            const index = ind === 6 ? 0 : ind + 1
            const dayName = date().day(index).format(DateFormat.DayNameFormat)

            return {
                active: false,
                dayOfWeek: index,
                name: dayName,
                workPeriodItems: [
                    {
                        admitedPatients: ADMITTED_PATIENTS_ALL,
                        end: DEFAULT_END_HOUR,
                        insurances: [],
                        originalEnd: DEFAULT_END_HOUR,
                        originalStart: DEFAULT_START_HOUR,
                        patientInsuranceAccepted: INSURANCES_RADIO_BOTH,
                        periodicity: PERIODICITY_REPEAT_EVERY_WEEK,
                        nextDay: null,
                        serviceScheduleInsurances: [],
                        services: [],
                        start: DEFAULT_START_HOUR,
                        isPrivate: VisibilityType.Public
                    }
                ]
            }
        })
    }

    prepareWorkPeriods(payload: ScheduleWorkPeriods[]): ScheduleWorkPeriods[] {
        return payload.map((payloadItem: ScheduleWorkPeriods) => ({
            active: true,
            dayOfWeek: payloadItem.dayOfWeek,
            workPeriodItems: payloadItem.workPeriodItems.map((workperiodItem: ScheduleWorkPeriod) => {
                const {
                    admitedPatients,
                    end,
                    insurances,
                    originalEnd,
                    originalStart,
                    patientInsuranceAccepted,
                    periodicity,
                    nextDay,
                    serviceScheduleInsurances,
                    services,
                    start,
                    isPrivate
                } = workperiodItem

                return {
                    admitedPatients,
                    end,
                    insurances,
                    originalEnd,
                    originalStart,
                    patientInsuranceAccepted,
                    periodicity,
                    nextDay,
                    serviceScheduleInsurances,
                    services,
                    start,
                    isPrivate
                }
            })
        }))
    }

    mapWorkperiodsToEvents(
        workPeriods: WorkPeriodDTO[],
        schedules: Record<number, Schedule>,
        colorSchemas: Record<number, ColorSchemasEntity>
    ): WorkPeriodEvent[] {
        return workPeriods.map(workPeriod => {
            const schedule = schedules[workPeriod.scheduleId]
            const colorSchema = colorSchemas[schedule?.colorSchemas?.id || schedule.colorSchemaId]
            const workPeriodId =
                workPeriod.id || (workPeriod.isPreview ? CALENDAR_PREVIEW_ID : Math.random().toString(36).substr(2, 5))
            const workPeriodDisplay = workPeriod.isPreview
                ? CalendarEventDisplayType.Block
                : CalendarEventDisplayType.Background
            return {
                ...workPeriod,
                ...calendarEventService.processStartEndToDate(workPeriod),
                id: workPeriodId,
                display: workPeriodDisplay,
                displayDefault: workPeriodDisplay,
                color: tinycolor(colorSchema.baseColor).setAlpha(0.1).toString(),
                originalColor: colorSchema.baseColor,
                scheduleName: sanitize(schedule.displayName || schedule.name),
                scheduleId: schedule.id,
                resourceId: schedule.id,
                doctorId: schedule.doctor?.userId || null,
                specialityId: schedule.doctor?.specialityId || null,
                facilityId: schedule.facilityId,
                overlap: true,
                isWorkperiod: true,
                type: CalendarEventType.Workperiod,
                editable: !!workPeriod.isPreview,
                isPreview: workPeriod.isPreview,
                className: [`workperiod-id-${workPeriodId}`]
            }
        })
    }
}

export default new CalendarWorkPeriodsService()
