export const GET_PATIENT = 'GET_PATIENT'
export const GET_SELECTED_PATIENT_IDS = 'GET_SELECTED_PATIENT_IDS'
export const GET_UNSELECTED_PATIENT_IDS = 'GET_UNSELECTED_PATIENT_IDS'
export const GET_EVENT_DATA = 'GET_EVENT_DATA'
export const GET_FUTURE_EVENT_DATA = 'GET_FUTURE_EVENT_DATA'
export const GET_NEXT_EVENT_DATA = 'GET_NEXT_EVENT_DATA'
export const GET_CHECKIN_INFO = 'GET_CHECKIN_INFO'
export const GET_PATIENT_FULLNAME = 'GET_PATIENT_FULLNAME'
export const HAS_NOMORE_EVENTS = 'HAS_NOMORE_EVENTS'
export const GET_FINANCIAL_VISIT_SUMMARY = 'GET_FINANCIAL_VISIT_SUMMARY'
export const GET_PATIENT_COUNTERS = 'GET_PATIENT_COUNTERS'
export const GET_PATIENT_TABLE_EDIT_STATE = 'GET_PATIENT_TABLE_EDIT_STATE'
export const GET_TABLE_PAGE = 'GET_TABLE_PAGE'
export const GET_PATIENT_IS_TABLE_EDIT_STATE_MERGE = 'GET_PATIENT_IS_TABLE_EDIT_STATE_MERGE'
export const GET_PATIENT_IS_TABLE_EDIT_STATE_DELETE = 'GET_PATIENT_IS_TABLE_EDIT_STATE_DELETE'
export const GET_PATIENT_IS_TABLE_EDIT_STATE_CHANGE_OWNER = 'GET_PATIENT_IS_TABLE_EDIT_STATE_CHANGE_OWNER'
export const GET_IS_ALL_PATIENTS_SELECTED = 'GET_IS_ALL_PATIENTS_SELECTED'
export const GET_TOTAL_PATIENTS = 'GET_TOTAL_PATIENTS'
export const GET_TOTAL_PRIVATE_PATIENTS = 'GET_TOTAL_PRIVATE_PATIENTS'
export const GET_PAGE_TOTAL = 'GET_PAGE_TOTAL'
export const GET_PAGE_LENGTH = 'GET_PAGE_LENGTH'
export const GET_COUNTER_SELECTED_PATIENTS = 'GET_COUNTER_SELECTED_PATIENTS'
export const GET_IS_TABLE_BEING_EDITED = 'GET_IS_TABLE_BEING_EDITED'
export const GET_NEAREST_EVENT_WITHOUT_EPISODE = 'GET_NEAREST_EVENT_WITHOUT_EPISODE'
export const GET_PATIENT_REVIEW_STATUS = 'GET_PATIENT_REVIEW_STATUS'
export const GET_SELECTED_NEW_OWNER = 'GET_SELECTED_NEW_OWNER'
export const GET_INSURANCES = 'GET_INSURANCES'
export const GET_PROPOSALS = 'GET_PROPOSALS'
export const GET_PAGE_PATIENT_IDS = 'GET_PAGE_PATIENT_IDS'
