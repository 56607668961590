export const getDefaultState = () => ({
    users: [],
    usersLoading: false,
    schedules: [],
    facilityNotifications: [],
    availablePlugins: []
})

const state = {
    ...getDefaultState()
}

export default state
