var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.DpCircle,{ref:"el",style:({
        display: _setup.taskPendingQuantity > 0 ? 'block' : 'none',
        width: '18px',
        height: '18px',
        position: 'absolute',
        right: 0,
        top: 0,
        'line-height': '18px',
        'font-weight': 700,
        'letter-spacing': 0,
        'font-size': '11px',
        color: 'white'
    }),attrs:{"color":"danger","size":"xs"}},[_vm._v(_vm._s(_setup.taskPendingQuantity))])
}
var staticRenderFns = []

export { render, staticRenderFns }