import { GettersAdaptor } from '@/interfaces'

import { GetterTypes, RequestGetters } from './types'
import { RequestState } from './types/state'

export const getters: GettersAdaptor<RequestGetters, RequestState, any> = {
    [GetterTypes.GetIsRequestFeatureEnabled]: state => state.isRequestFeatureEnabled,
    [GetterTypes.GetPageCount]: state => state.pageCount,
    [GetterTypes.GetRequests]: state => state.requests,
    [GetterTypes.GetUnreadRequestsCount]: state => state.unreadCount,
    [GetterTypes.GetRequestChannel]: state => state.channel,
    [GetterTypes.GetRequestCategories]: state => state.categories,
    [GetterTypes.GetAllowRequestsFromNewPatient]: state => state.allowRequestsFromNewPatient
}

export default getters
