import { formatDate } from '@/core/ui/utils'
import { CampaignsRecipientsProperty } from '@/features/campaigns/domain/enums/campaignsRecipients.enums'
import {
    CampaignsRecipientsCell,
    CampaignsRecipientsHeader,
    CampaignsRecipientsHeadersOptions,
    CampaignsRecipientsPreviewPage,
    CampaignsRecipientsTable
} from '@/features/campaigns/domain/interfaces/campaignsRecipients.interfaces'
import { CAMPAIGNS_CELL_TEXT } from '@/features/campaigns/ui/constants/campaigns.constants'
import {
    CAMPAIGNS_RECIPIENTS_HEADERS,
    CAMPAIGNS_RECIPIENTS_HEADERS_EMAIL,
    CAMPAIGNS_RECIPIENTS_HEADERS_INSURANCE_NAME,
    CAMPAIGNS_RECIPIENTS_HEADERS_MEDICAL_CENTER,
    CAMPAIGNS_RECIPIENTS_HEADERS_SMS
} from '@/features/campaigns/ui/constants/campaignsRecipients.constants'

export const getRecipientsTableRows = (
    data: CampaignsRecipientsTable,
    headers: CampaignsRecipientsHeader[]
): CampaignsRecipientsCell[] =>
    data.recipientsPreview.page.map((item: CampaignsRecipientsPreviewPage) => ({
        cells: headers.map(({ property }: CampaignsRecipientsHeader) => {
            let value = item[property]
            if (property === CampaignsRecipientsProperty.LastAppointmentDate && item[property]) {
                value = formatDate(item[property] as string | Date)
            }

            const { id, email } = item

            return {
                value,
                data: { id, email },
                name: property,
                component: CAMPAIGNS_CELL_TEXT
            }
        })
    }))

export const getRecipientsTableHeaders = (data: CampaignsRecipientsHeadersOptions): CampaignsRecipientsHeader[] => {
    const { isTypeSms, showInsuranceFilterInCampaigns, managesMedicalCenter } = data

    return [
        ...CAMPAIGNS_RECIPIENTS_HEADERS,
        ...(isTypeSms ? [CAMPAIGNS_RECIPIENTS_HEADERS_SMS] : [CAMPAIGNS_RECIPIENTS_HEADERS_EMAIL]),
        ...(showInsuranceFilterInCampaigns ? [CAMPAIGNS_RECIPIENTS_HEADERS_INSURANCE_NAME] : []),
        ...(managesMedicalCenter ? [...CAMPAIGNS_RECIPIENTS_HEADERS_MEDICAL_CENTER] : [])
    ]
}
