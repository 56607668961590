import { CountryCodes } from '@/core/domain/enums/country.code.enum'

export const PAYMENTS_LOGO = {
    [CountryCodes.PL]: require('../../assets/images/payments-logo-pl.png'),
    [CountryCodes.IT]: require('../../assets/images/payments-logo-it.png'),
    [CountryCodes.ES]: require('../../assets/images/payments-logo-es.png'),
    [CountryCodes.MX]: require('../../assets/images/payments-logo-mx.png'),
    [CountryCodes.BR]: require('../../assets/images/payments-logo-br.png'),
    [CountryCodes.DE]: require('../../assets/images/payments-logo-de.png')
} as Record<CountryCodes, string>
