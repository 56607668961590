import { EditState } from '@/features/patient/domain/enums/patientEditState.enums'

import { getPatientFullName } from '../utils/getPatientFullName'
import {
    GET_CHECKIN_INFO,
    GET_COUNTER_SELECTED_PATIENTS,
    GET_EVENT_DATA,
    GET_FINANCIAL_VISIT_SUMMARY,
    GET_FUTURE_EVENT_DATA,
    GET_INSURANCES,
    GET_IS_ALL_PATIENTS_SELECTED,
    GET_IS_TABLE_BEING_EDITED,
    GET_NEAREST_EVENT_WITHOUT_EPISODE,
    GET_NEXT_EVENT_DATA,
    GET_PAGE_LENGTH,
    GET_PAGE_PATIENT_IDS,
    GET_PAGE_TOTAL,
    GET_PATIENT,
    GET_PATIENT_COUNTERS,
    GET_PATIENT_FULLNAME,
    GET_PATIENT_IS_TABLE_EDIT_STATE_CHANGE_OWNER,
    GET_PATIENT_IS_TABLE_EDIT_STATE_DELETE,
    GET_PATIENT_IS_TABLE_EDIT_STATE_MERGE,
    GET_PATIENT_REVIEW_STATUS,
    GET_PATIENT_TABLE_EDIT_STATE,
    GET_PROPOSALS,
    GET_SELECTED_NEW_OWNER,
    GET_SELECTED_PATIENT_IDS,
    GET_TABLE_PAGE,
    GET_TOTAL_PATIENTS,
    GET_TOTAL_PRIVATE_PATIENTS,
    GET_UNSELECTED_PATIENT_IDS,
    HAS_NOMORE_EVENTS
} from './types/getters'

const getters = {
    [GET_PATIENT](state) {
        return state.patient
    },
    [GET_SELECTED_PATIENT_IDS](state) {
        return state.selectedPatientIds
    },
    [GET_UNSELECTED_PATIENT_IDS](state) {
        return state.unselectedPatientIds
    },
    [GET_COUNTER_SELECTED_PATIENTS](state) {
        return state.counterSelectedPatients
    },
    [GET_TOTAL_PATIENTS](state) {
        return state.totalPatients
    },
    [GET_TOTAL_PRIVATE_PATIENTS](state) {
        return state.totalPrivatePatients
    },
    [GET_PAGE_TOTAL](state) {
        return state.pageTotal
    },
    [GET_PAGE_LENGTH](state) {
        return state.pageLength
    },
    [GET_PATIENT_IS_TABLE_EDIT_STATE_MERGE]: state => {
        return state.tableEditState === EditState.Merge
    },
    [GET_PATIENT_IS_TABLE_EDIT_STATE_DELETE]: state => {
        return state.tableEditState === EditState.Delete
    },
    [GET_PATIENT_IS_TABLE_EDIT_STATE_CHANGE_OWNER]: state => {
        return state.tableEditState === EditState.ChangeOwner
    },
    [GET_IS_TABLE_BEING_EDITED]: state => {
        return state.tableEditState !== EditState.Preview
    },
    [GET_IS_ALL_PATIENTS_SELECTED]: state => {
        return state.isAllPatientsSelected
    },
    [GET_PATIENT_TABLE_EDIT_STATE](state) {
        return state.tableEditState
    },
    [GET_TABLE_PAGE](state) {
        return state.page
    },
    [GET_PATIENT_FULLNAME](state, _getters, _rootState, rootGetters) {
        return getPatientFullName({
            showNameBeforeSurname: rootGetters?.authUser?.showNameBeforeSurname || false,
            patient: state.patient
        })
    },
    [GET_EVENT_DATA](state) {
        return state.events
    },
    [GET_FUTURE_EVENT_DATA](state) {
        return state.futureEvents
    },
    [GET_NEXT_EVENT_DATA](state) {
        return state.nextEvent
    },
    [GET_CHECKIN_INFO](state) {
        return state.checkinInfo
    },
    [HAS_NOMORE_EVENTS](state) {
        return state.noMoreEventsToLoad
    },
    [GET_FINANCIAL_VISIT_SUMMARY]({ financialVisitSummary = {} }) {
        return {
            balance: financialVisitSummary.balance || 0,
            totalVisits: financialVisitSummary.totalVisitCount || 0,
            totalPaid: financialVisitSummary.totalPaid || 0,
            paymentStatusCount: financialVisitSummary.paymentStatusCount || {},
            unpaidVisits: financialVisitSummary.paymentStatusCount?.unpaid?.count || 0
        }
    },
    [GET_PATIENT_COUNTERS]({ patientCounters = {} }) {
        return {
            episodeCounter: patientCounters.episodeCounter || 0,
            eventCounter: patientCounters.eventCounter || 0,
            paymentCounter: patientCounters.paymentCounter || 0,
            fileCounter: patientCounters.fileCounter || 0
        }
    },
    [GET_NEAREST_EVENT_WITHOUT_EPISODE](state) {
        return state.nearestEventWithoutEpisode
    },
    [GET_PATIENT_REVIEW_STATUS](state) {
        return state.patientReviewRequestStatus
    },
    [GET_SELECTED_NEW_OWNER](state) {
        return state.selectedNewOwner
    },
    [GET_INSURANCES](state) {
        return state.insurances
    },
    [GET_PROPOSALS](state) {
        return state.proposals
    },
    [GET_PAGE_PATIENT_IDS](state) {
        return state.pagePatientIds
    }
}

export default getters
