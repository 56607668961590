import { nameCapitalization } from '@/utils/string'

export const getPatientFullName = ({ patient, showNameBeforeSurname }) => {
    if (!patient) {
        return ''
    }

    const { firstName, lastName } = patient

    if (!firstName || !lastName) {
        return nameCapitalization(firstName) || nameCapitalization(lastName)
    }

    return showNameBeforeSurname
        ? `${nameCapitalization(firstName)} ${nameCapitalization(lastName)}`
        : `${nameCapitalization(lastName)} ${nameCapitalization(firstName)}`
}
