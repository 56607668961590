import { isAuthenticated } from '../../domain/utils/token.utils'

export default function noAuth({ next, to, from }) {
    const isUserAuthenticated = isAuthenticated()

    if (isUserAuthenticated) {
        return next({
            path: to.query.redirectUrl || to.query.redirect_uri || from.fullPath || '/'
        })
    }
    next()
}
