import {
    FileImportationImportContentId,
    FileImportationProperties
} from '@/features/settings/domain/enums/fileImportation.enums'
import {
    FileImportationSelectOptions,
    FileImportationTableComponents,
    FileImportationTableHeader
} from '@/features/settings/ui/interfaces/fileImportation.interfaces'

export const FILE_IMPORTATION_WIZARD_ENABLE = 'next-step-enable'

const FILE_IMPORTATION_ROUTE_HOME = 'file-importation'

export const FILE_IMPORTATION_ROUTES = {
    home: FILE_IMPORTATION_ROUTE_HOME,
    wizard: {
        import: 'file-importation-import'
    }
}

export const FILE_IMPORTATION_PATHS = {
    home: FILE_IMPORTATION_ROUTE_HOME,
    wizard: {
        import: 'import'
    }
}

export const FILE_IMPORTATION_TABLE_HEADERS_IMPORTS: FileImportationTableHeader[] = [
    {
        property: FileImportationProperties.FileName,
        title: 'importation-tool-data-table-column-file'
    },
    {
        property: FileImportationProperties.Content,
        title: 'importation-tool-patients-wizard-import-option-docplanner-uploading-table-column-data-type'
    },
    {
        property: FileImportationProperties.UserName,
        title: 'importation-tool-data-table-column-imported-by'
    },
    {
        property: FileImportationProperties.CreatedAt,
        title: 'importation-tool-data-table-column-date'
    },
    {
        property: FileImportationProperties.Status,
        title: 'importation-tool-data-table-column-status'
    }
]

export const FILE_IMPORTATION_TABLE_HEADERS_TO_IMPORT: FileImportationTableHeader[] = [
    {
        property: FileImportationProperties.FileName,
        title: 'importation-tool-patients-wizard-import-option-docplanner-uploading-table-column-file'
    },
    {
        property: FileImportationProperties.Content,
        title: 'importation-tool-patients-wizard-import-option-docplanner-uploading-table-column-data-type'
    },
    {
        property: FileImportationProperties.OriginSource,
        title: 'importation-tool-patients-wizard-import-option-docplanner-uploading-table-column-exported-from',
        tooltip: 'importation-tool-patients-wizard-import-option-docplanner-uploading-tooltip-exported-from'
    },
    {
        property: FileImportationProperties.Size,
        title: 'importation-tool-patients-wizard-import-option-docplanner-uploading-table-column-size'
    }
]

export const FILE_IMPORTATION_CONTENT_OPTIONS: FileImportationSelectOptions[] = [
    {
        id: FileImportationImportContentId.Appointments,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-appointments'
    },
    {
        id: FileImportationImportContentId.Patients,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-patients'
    },
    {
        id: FileImportationImportContentId.Services,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-services'
    },
    {
        id: FileImportationImportContentId.EHR,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-ehr'
    },
    {
        id: FileImportationImportContentId.WorkingHours,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-working-hours'
    },
    {
        id: FileImportationImportContentId.ImportationReport,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-importation-report'
    },
    {
        id: FileImportationImportContentId.DuplicatesReport,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-duplicates-report'
    },
    {
        id: FileImportationImportContentId.Other,
        label: 'importation-tool-patients-wizard-import-option-docplanner-type-of-data-other'
    }
]

const FILE_IMPORTATION_TABLE_CELL_DEFAULT = 'FileImportationTableCellDefault'
const FILE_IMPORTATION_TABLE_CELL_FILE = 'FileImportationTableCellFile'
const FILE_IMPORTATION_TABLE_CELL_CONTENT = 'FileImportationTableCellContent'
const FILE_IMPORTATION_TABLE_CELL_ORIGIN_SOURCE = 'FileImportationTableCellOriginSource'
const FILE_IMPORTATION_TABLE_CELL_STATUS = 'FileImportationTableCellStatus'

export const FILE_IMPORTATION_TABLE_CELL_COMPONENTS: FileImportationTableComponents = {
    default: FILE_IMPORTATION_TABLE_CELL_DEFAULT,
    [FileImportationProperties.FileName]: FILE_IMPORTATION_TABLE_CELL_FILE,
    [FileImportationProperties.Content]: FILE_IMPORTATION_TABLE_CELL_CONTENT,
    [FileImportationProperties.OriginSource]: FILE_IMPORTATION_TABLE_CELL_ORIGIN_SOURCE,
    [FileImportationProperties.Status]: FILE_IMPORTATION_TABLE_CELL_STATUS
}
