export enum QuoteStatus {
    Accepted = 1,
    Rejected = 2,
    Pending = 0
}

export enum HeaderNames {
    Address = 'address',
    CreatedAt = 'createdAt',
    Number = 'number',
    PatientName = 'patientName',
    Status = 'status',
    Total = 'total'
}
