import { HintsState } from '@/features/hints/ui/store/types/state'

import { MappedReportsHintObject } from '../../../domain/interfaces/hints.interfaces'

export enum MutationTypes {
    ResetHints = 'RESET_HINTS',
    SetHint = 'SET_HINT'
}

export type HintsMutations = {
    [MutationTypes.ResetHints]: (state: HintsState) => void
    [MutationTypes.SetHint]: (state: HintsState, payload: { data: MappedReportsHintObject[]; hint: string }) => void
}
