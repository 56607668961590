import { getDefaultState } from './state'
import {
    MUTATION_USERS_RESET_STATE,
    MUTATION_USERS_UPDATE_AVAILABLE_PLUGINS,
    MUTATION_USERS_UPDATE_FACILITY_NOTIFICATIONS,
    MUTATION_USERS_UPDATE_SCHEDULES,
    MUTATION_USERS_UPDATE_USERS,
    MUTATION_USERS_UPDATE_USERS_LOADING
} from './types/mutation'

const mutations = {
    [MUTATION_USERS_UPDATE_USERS_LOADING](state, usersLoading) {
        state.usersLoading = usersLoading
    },
    [MUTATION_USERS_UPDATE_FACILITY_NOTIFICATIONS](state, facilityNotifications) {
        state.facilityNotifications = facilityNotifications
    },
    [MUTATION_USERS_UPDATE_SCHEDULES](state, schedules) {
        state.schedules = schedules
    },
    [MUTATION_USERS_UPDATE_USERS](state, users) {
        state.users = users
    },
    [MUTATION_USERS_RESET_STATE](state) {
        state = { ...state, ...getDefaultState() }
    },
    [MUTATION_USERS_UPDATE_AVAILABLE_PLUGINS](state, plugins) {
        state.availablePlugins = plugins
    }
}

export default mutations
