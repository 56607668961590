import {
    PaymentsNotificationCookieEnum,
    PaymentsNotificationEnum,
    PaymentsNotificationFeatureToggleEnum
} from '../enums'
import { PaymentsNotification } from '../interfaces'

export const PAYMENTS_NOTIFICATIONS: PaymentsNotification[] = [
    {
        name: PaymentsNotificationEnum.UnpaidInvoicesBanner,
        component: () =>
            import(/* webpackChunkName: "unpaid-invoices-banner" */ '../modals/PaymentsUnpaidInvoicesBanner.vue'),
        featureToggleName: PaymentsNotificationFeatureToggleEnum.UnpaidInvoicesBanner,
        cookie: PaymentsNotificationCookieEnum.UnpaidInvoicesBanner
    },
    {
        name: PaymentsNotificationEnum.ActionRequiredModal,
        component: () =>
            import(
                /* webpackChunkName: "payments-action-required-modal" */ '../modals/PaymentsActionRequiredModal.vue'
            ),
        featureToggleName: PaymentsNotificationFeatureToggleEnum.ActionRequiredModal,
        cookie: PaymentsNotificationCookieEnum.ActionRequiredModal
    }
]
