export const ACTION_GET_CALENDAR_EVENTS = 'ACTION_GET_CALENDAR_EVENTS'
export const ACTION_FETCH_CALENDAR_EVENTS = 'ACTION_FETCH_CALENDAR_EVENTS'
export const ACTION_FETCH_CALENDAR_EVENTS_BY_DATE_RANGE = 'ACTION_FETCH_CALENDAR_EVENTS_BY_DATE_RANGE'
export const ACTION_SET_CALENDAR_CREATION_TYPE = 'ACTION_SET_CALENDAR_CREATION_TYPE'
export const ACTION_SET_CALENDAR_CREATION_ADDRESS = 'ACTION_SET_CALENDAR_CREATION_ADDRESS'
export const ACTION_MAP_CALENDAR_EVENTS = 'ACTION_MAP_CALENDAR_EVENTS'
export const ACTION_SET_CALENDAR_CREATION_SERVICES = 'ACTION_SET_CALENDAR_CREATION_SERVICES'
export const ACTION_SET_CALENDAR_CREATION_NAME = 'ACTION_SET_CALENDAR_CREATION_NAME'
export const ACTION_SET_CALENDAR_CREATION_COLOR = 'ACTION_SET_CALENDAR_CREATION_COLOR'
export const ACTION_SET_CALENDAR_CREATION_RESET = 'ACTION_SET_CALENDAR_CREATION_RESET'
export const ACTION_FETCH_CALENDAR_FILTER_SERVICES = 'ACTION_FETCH_CALENDAR_FILTER_SERVICES'
export const ACTION_FETCH_FACILITIES = 'ACTION_FETCH_FACILITIES'
export const ACTION_FETCH_FILTERS = 'ACTION_FETCH_FILTERS'
export const ACTION_SET_ONLY_DOCTORS_SELECTED = 'ACTION_SET_ONLY_DOCTORS_SELECTED'
export const ACTION_FETCH_CALENDAR_SCHEDULES = 'ACTION_FETCH_CALENDAR_SCHEDULES'
export const ACTION_FETCH_CALENDAR_SCHEDULES_ORDER = 'ACTION_FETCH_CALENDAR_SCHEDULES_ORDER'
export const ACTION_FETCH_CALENDAR_COLOR_SCHEMAS = 'ACTION_FETCH_CALENDAR_COLOR_SCHEMAS'
export const ACTION_SELECT_DOCTOR = 'ACTION_SELECT_DOCTOR'
export const ACTION_SELECT_DOCTOR_FOR_FREE_SLOTS_VIEW = 'ACTION_SELECT_DOCTOR_FOR_FREE_SLOTS_VIEW'
export const ACTION_SELECT_ALL_DOCTORS = 'ACTION_SELECT_ALL_DOCTORS'
export const ACTION_SELECT_FACILITY = 'ACTION_SELECT_FACILITY'
export const ACTION_SET_DOCTORS_WORKING_CURRENT_PERIOD = 'ACTION_SET_DOCTORS_WORKING_CURRENT_PERIOD'
export const ACTION_SELECT_ALL_ROOMS = 'ACTION_SELECT_ALL_ROOMS'
export const ACTION_SELECT_ALL_DEVICES = 'ACTION_SELECT_ALL_DEVICES'
export const ACTION_FETCH_AGENDA_EVENTS = 'ACTION_FETCH_AGENDA_EVENTS'
export const ACTION_SELECT_SPECIALITY = 'ACTION_SELECT_SPECIALITY'
export const ACTION_SELECT_ALL_STAFF = 'ACTION_SELECT_ALL_STAFF'
export const ACTION_SET_FREE_SLOTS_FILTER_DAYS = 'ACTION_SET_FREE_SLOTS_FILTER_DAYS'
export const ACTION_SET_FREE_SLOTS_FILTER_DAYTIME = 'ACTION_SET_FREE_SLOTS_FILTER_DAYTIME'
export const ACTION_SET_FREE_SLOTS_FILTER_INSURANCE = 'ACTION_SET_FREE_SLOTS_FILTER_INSURANCE'
export const ACTION_SET_FREE_SLOTS_SELECTED_SERVICE = 'ACTION_SET_FREE_SLOTS_SELECTED_SERVICE'
export const ACTION_SELECT_SCHEDULE = 'ACTION_SELECT_SCHEDULE'
export const ACTION_SELECT_ALL_SCHEDULES = 'ACTION_SELECT_ALL_SCHEDULES'
export const ACTION_SET_DOCTOR_SCHEDULES = 'ACTION_SET_DOCTOR_SCHEDULES'
export const ACTION_SET_CALENDAR_HIDE_BOOKED_SLOTS = 'ACTION_SET_CALENDAR_HIDE_BOOKED_SLOTS'
export const ACTION_SET_CALENDAR_SELECTED_SCHEDULES = 'ACTION_SET_CALENDAR_SELECTED_SCHEDULES'
export const ACTION_SET_CALENDAR_SELECTED_SCHEDULES_FOR_FREE_SLOTS_VIEW =
    'ACTION_SET_CALENDAR_SELECTED_SCHEDULES_FOR_FREE_SLOTS_VIEW'
export const ACTION_SET_ALL_DOCTORS_FOR_FREE_SLOTS_VIEW = 'ACTION_SET_ALL_DOCTORS_FOR_FREE_SLOTS_VIEW'
export const ACTION_SET_CALENDAR_SELECTED_SCHEDULES_WORKING_CURRENT_PERIOD =
    'ACTION_SET_CALENDAR_SELECTED_SCHEDULES_WORKING_CURRENT_PERIOD'
