import { ReportPathname } from '@/features/reports/domain/enums/reports.enums'
import { ReportsParameters, ReportsState } from '@/features/reports/domain/interfaces/reports.interfaces'

export const getDefaultState = (): ReportsState => ({
    fetchParameters: {} as ReportsParameters,
    headers: [],
    page: [],
    rows: [],
    numOfPages: 1,
    currentPage: 1,
    loading: true,
    currentReport: ReportPathname.Visits
})

const state = getDefaultState()

export default state
