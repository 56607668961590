import httpAPI from '@/api/utils/httpClientAPI'
import { ImportField } from '@/features/admin/domain/interfaces/admin.interfaces'

const URL_BASE = 'patients'

const URL_IMPORT = `${URL_BASE}/import`
const URL_FIELDS_PATIENT = `${URL_IMPORT}/fields/patient?userImporter=false`
const URL_FIELDS_EVENT = `${URL_IMPORT}/fields/event`
const URL_FIELDS_EHR = `${URL_IMPORT}/fields/ehr`

class AdminImportRepository {
    async getFieldPatient(): Promise<ImportField[]> {
        const { data } = await httpAPI.get(URL_FIELDS_PATIENT)

        return data
    }

    async getFieldEvent(): Promise<ImportField[]> {
        const { data } = await httpAPI.get(URL_FIELDS_EVENT)

        return data
    }

    async getFieldEHR(): Promise<ImportField[]> {
        const { data } = await httpAPI.get(URL_FIELDS_EHR)

        return data
    }
}

export default new AdminImportRepository()
