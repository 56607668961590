export const getDefaultState = () => ({
    importFacility: undefined,
    patientsFile: undefined,
    ehrsFile: undefined,
    eventsFile: undefined,
    servicesFile: undefined,
    serviceScheduleFile: undefined,
    patientsFilePreview: [],
    eventsFilePreview: [],
    ehrsFilePreview: [],
    servicesFilePreview: [],
    serviceScheduleFilePreview: [],
    patientMappings: undefined,
    serviceMappings: undefined,
    serviceScheduleMappings: undefined,
    eventMappings: undefined,
    ehrMappings: undefined,
    validationData: undefined,
    lastServiceImportUniqueId: undefined,
    fields: {
        patient: [],
        event: [],
        ehr: [],
        services: [],
        serviceSchedules: []
    }
})

export const state = getDefaultState()
