import { cloneDeep } from 'lodash'

export function swapProperty<T>(columns: T[], currentIndex: number, indexToSwap: number, property: keyof T): T[] {
    function isValidIndex(index: number, length: number) {
        return index > -1 && index < length
    }

    // we need to mutate the columns to easily swap 2 of them and avoid to modify the original one
    const columnsCloned = cloneDeep(columns)
    const length = columnsCloned?.length

    if (length && isValidIndex(currentIndex, length) && isValidIndex(indexToSwap, length)) {
        ;[columnsCloned[currentIndex][property], columnsCloned[indexToSwap][property]] = [
            columnsCloned[indexToSwap][property],
            columnsCloned[currentIndex][property]
        ]
    }

    return columnsCloned
}
