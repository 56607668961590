import { SetupGuideTaskDTO } from '../../api'
import { SetupGuideTaskState } from '../../enums'
import { SetupGuideTask } from '../interfaces'

/**
 * Map the given `SetupGuideTaskDTO` to `SetupGuideTask`
 * @param task - Task to map
 */
export const mapTaskFromDTO = (task: SetupGuideTaskDTO): SetupGuideTask => {
    const completed = task.state !== SetupGuideTaskState.NotCompleted

    return {
        completed,
        id: task.id,
        kind: task.kind,
        name: task.name,
        url: task.url
    }
}

/**
 * Map the given `SetupGuideTask` to `SetupGuideTaskDTO`
 * @param task - Task to map
 */
export const mapTaskToDTO = (task: SetupGuideTask): SetupGuideTaskDTO => {
    const state = task.completed ? SetupGuideTaskState.Completed : SetupGuideTaskState.NotCompleted

    return {
        state,
        id: task.id,
        kind: task.kind,
        name: task.name,
        url: task.url
    }
}
