import { formatNumber } from '@/utils/number-format'

export const decodeFileName = name => {
    return btoa(
        encodeURIComponent(name).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(parseInt(p1, 16))
        })
    )
}

export const formatFileSize = bytes => {
    if (!+bytes) {
        return '0 bytes'
    }
    const kb = 'kb'
    const kilobyte = 1024
    const units = ['bytes', kb, 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(kilobyte))
    const unit = units[unitIndex]

    return `${formatNumber(bytes / kilobyte ** unitIndex, unit !== kb, 1)} ${units[unitIndex]}`
}
