import configManager from '@/bootstrap/configurationManager'
import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'
import cultureLibrariesMap from '@/config/cultureLibrariesMap.json'
import EventBus from '@/core/ui/libs/eventBus'
import { finishAndTrackMeasure, startMeasure } from '@/core/ui/plugins/utils/monitoring'
import { locale as dayjsLocale } from '@/core/ui/utils'
import i18nPlugin from '@/plugins/i18n'
import { SET_CULTURE } from '@/store/mutation-types'
import { locale as numeraljsLocale } from '@/utils/number-format'

const setNumeralLocale = function (culture) {
    const cultureNumeral = cultureLibrariesMap[culture].numeraljs
    numeraljsLocale(cultureNumeral)
}

const setDayjsLocale = function (culture) {
    const cultureDay = cultureLibrariesMap[culture].dayjs
    dayjsLocale(cultureDay)
}

const measureIds = {
    measureName: 'MW_i18n',
    startMark: 'MW_i18n__start',
    endMark: 'MW_i18n__end'
}

export default async function i18n({ to, next, router, store }) {
    startMeasure(measureIds.startMark)

    /**
     * WARN: This code consumes a large amount of memory. Please read OAP-1543.
     * We don't have automated memory leak tests yet so please if you are going to modify this code, test it thoroughly
     * specially on mobile.
     */

    let { locale } = store.getters
    const { availableLocales } = i18nPlugin

    if (!locale) {
        try {
            const { data } = await store.dispatch('FETCH_CULTURE')
            locale = data
        } catch (error) {
            locale = navigator.language || navigator.userLanguage
        }

        store.commit(SET_CULTURE, locale)
    }

    if (!availableLocales.includes(locale)) {
        let translations
        let failedToGetTranslations = false
        try {
            const { data } = await store.dispatch('FETCH_TRANSLATIONS', locale)
            translations = data
        } catch (error) {
            failedToGetTranslations = true
            const isStatus0 = error.request.status === 0
            const prefix = isStatus0 ? '[Status 0] ' : ''
            monitor.send(`${prefix}Translations for a locale can't be found. Default culture will be used instead.`, {
                level: SeverityLevels.Info,
                extra: {
                    locale,
                    alternativeLocaleUsed: configManager.getValue('DEFAULT_CULTURE'),
                    status: error.request.status,
                    readyState: error.request.readyState
                }
            })
        }

        if (failedToGetTranslations) {
            locale = configManager.getValue('DEFAULT_CULTURE')
            store.commit(SET_CULTURE, locale)

            try {
                const { data } = await store.dispatch('FETCH_TRANSLATIONS', locale)
                translations = data
            } catch (error) {
                const isStatus0 = error.request.status === 0
                const prefix = isStatus0 ? '[Status 0] ' : ''
                monitor.send(`${prefix}Translations for culture can't be found. No translations will be applied.`, {
                    level: SeverityLevels.Info,
                    extra: {
                        locale,
                        status: error.request.status,
                        readyState: error.request.readyState
                    }
                })

                const data = {}
                data[locale] = {}
                translations = { ...data }
            }
        }

        i18nPlugin.setLocaleMessage(locale, Object.freeze(translations))
    }

    setNumeralLocale(locale)
    setDayjsLocale(locale)

    i18nPlugin.locale = locale

    finishAndTrackMeasure(router.app.$featureToggle, measureIds)

    next()
}
