import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import {
    BlacklistNumber,
    ConfigurationStatus,
    DoctorConfiguration,
    ResponseType
} from '@/features/settings/domain/interfaces/ voicemail.interfaces'
import { parseJSON } from '@/utils/parse-json'

const URL_BASE = 'voicemail/self-service'
const URL_GET_DOCTOR_CONFIGURATION = `${URL_BASE}/doctors/:doctorId/configuration`
const URL_ADD_DOCTOR_CONFIGURATION = `${URL_BASE}/doctors/:doctorId/configuration`
const URL_DEACTIVATE_DOCTOR_CONFIGURATION = `${URL_BASE}/doctors/:doctorId/configuration/deactivate`
const URL_INIT_CONFIG_CHECKER = `${URL_BASE}/doctors/:doctorId/configuration/checker`
const URL_GET_CONFIG_CHECKER_STATUS = `${URL_BASE}/doctors/:doctorId/configuration/checker`
const URL_GET_RESPONSE_TYPES = `${URL_BASE}/response-types`
const URL_GET_BLACKLIST_NUMBERS = `${URL_BASE}/doctors/:doctorId/blacklist-numbers`
const URL_ADD_BLACKLIST_NUMBERS = `${URL_BASE}/doctors/:doctorId/blacklist-numbers`
const URL_DELETE_BLACKLIST_NUMBERS = `${URL_BASE}/doctors/:doctorId/blacklist-numbers/:id`

class VoicemailSelfServiceRepository {
    getDoctorConfiguration(doctorId: string): Promise<DoctorConfiguration> {
        const url = getUrlFromTemplate({
            template: URL_GET_DOCTOR_CONFIGURATION,
            params: { doctorId }
        })
        return httpAPI.get(url, {
            validateStatus(status) {
                return (status >= 200 && status < 300) || status === 404
            },
            transformResponse: [
                function (data) {
                    const currentData = parseJSON(data)

                    if (currentData.status === 404) {
                        return null
                    }

                    return currentData
                }
            ]
        })
    }

    addDoctorConfiguration(
        doctorId: string,
        doctorPhone: string,
        responseTypeId: string,
        userId: string
    ): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_ADD_DOCTOR_CONFIGURATION,
            params: { doctorId }
        })
        return httpAPI.post(url, {
            doctorPhone,
            responseTypeId,
            userId
        })
    }

    deactivateDoctorConfiguration(doctorId: string, userId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DEACTIVATE_DOCTOR_CONFIGURATION,
            params: { doctorId }
        })
        return httpAPI.post(url, {
            userId
        })
    }

    initializeConfigurationChecker(doctorId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_INIT_CONFIG_CHECKER,
            params: { doctorId }
        })
        return httpAPI.post(url)
    }

    getConfigurationCheckerStatus(doctorId: string): Promise<ConfigurationStatus> {
        const url = getUrlFromTemplate({
            template: URL_GET_CONFIG_CHECKER_STATUS,
            params: { doctorId }
        })
        return httpAPI.get(url)
    }

    getResponseTypes(): Promise<ResponseType[]> {
        return httpAPI.get(URL_GET_RESPONSE_TYPES)
    }

    getBlacklistNumbers(doctorId: string): Promise<BlacklistNumber[]> {
        const url = getUrlFromTemplate({
            template: URL_GET_BLACKLIST_NUMBERS,
            params: { doctorId }
        })
        return httpAPI.get(url)
    }

    addBlacklistNumbers(doctorId: string, phoneNumber: string, userId: string): Promise<BlacklistNumber> {
        const url = getUrlFromTemplate({
            template: URL_ADD_BLACKLIST_NUMBERS,
            params: { doctorId }
        })
        return httpAPI.post(url, {
            phoneNumber,
            userId
        })
    }

    deleteBlacklistNumbers(doctorId: string, id: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DELETE_BLACKLIST_NUMBERS,
            params: { doctorId, id }
        })
        return httpAPI.delete(url)
    }
}

export default new VoicemailSelfServiceRepository()
