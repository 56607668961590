import { setupGuideEventBus } from './bus'

enum SetupGuideEvents {
    InvitationModalCTAClicked = 'InvitationModalCTAClicked',
    InvitationModalRefused = 'InvitationModalRefused',
    InvitationModalShown = 'InvitationModalShown'
}

export const onSetupGuideInvitationModalCTAClicked = (callback: () => void) =>
    setupGuideEventBus.on(SetupGuideEvents.InvitationModalCTAClicked, callback)
export const offSetupGuideInvitationModalCTAClicked = (callback: () => void) =>
    setupGuideEventBus.off(SetupGuideEvents.InvitationModalCTAClicked, callback)
export const emitSetupGuideInvitationModalCTAClicked = () =>
    setupGuideEventBus.emit(SetupGuideEvents.InvitationModalCTAClicked)
export const createOnSetupGuideInvitationModalCTAClickedHandler = (fn: () => void) => fn

export const onSetupGuideInvitationModalRefused = (callback: () => void) =>
    setupGuideEventBus.on(SetupGuideEvents.InvitationModalRefused, callback)
export const offSetupGuideInvitationModalRefused = (callback: () => void) =>
    setupGuideEventBus.off(SetupGuideEvents.InvitationModalRefused, callback)
export const emitSetupGuideInvitationModalRefused = () =>
    setupGuideEventBus.emit(SetupGuideEvents.InvitationModalRefused)
export const createOnSetupGuideInvitationModalRefused = (fn: () => void) => fn

export const onSetupGuideInvitationModalShown = (callback: () => void) =>
    setupGuideEventBus.on(SetupGuideEvents.InvitationModalShown, callback)
export const offSetupGuideInvitationModalShown = (callback: () => void) =>
    setupGuideEventBus.off(SetupGuideEvents.InvitationModalShown, callback)
export const emitSetupGuideInvitationModalShown = () => setupGuideEventBus.emit(SetupGuideEvents.InvitationModalShown)
export const createOnSetupGuideInvitationSystemShownHandler = (fn: () => void) => fn
