import {
    BookingStatusTooltip,
    PaymentMethod,
    SecretaryAppointment,
    SecretaryHeader,
    SecretaryViewFilters
} from '@/features/calendar/domain/interfaces/secretaryView.interfaces'

import { SecretaryViewState } from './state'

export enum MutationTypes {
    AddRowSelected = 'addRowSelected',
    RemoveRowSelected = 'removeRowSelected',
    ResetState = 'resetState',
    SetFilters = 'setFilters',
    SetIsBulkCancelationPopoverOpen = 'setIsBulkCancelationPopoverOpen',
    SetIsChatPopoverOpen = 'setIsChatPopoverOpen',
    SetPaymentMethods = 'setPaymentMethods',
    SetSecretaryViewAppointments = 'setSecretaryViewAppointments',
    SetSecretaryViewHeaders = 'setSecretaryViewHeaders',
    SetTableLoading = 'setTableLoading',
    ToggleAllRowsSelected = 'toggleAllRowsSelected',
    UpdateSecretaryViewAppointment = 'updateSecretaryViewAppointment'
}

export type SecretaryViewMutations = {
    [MutationTypes.SetSecretaryViewAppointments](state: SecretaryViewState, appointments: SecretaryAppointment[]): void
    [MutationTypes.SetSecretaryViewHeaders](state: SecretaryViewState, headers: SecretaryHeader[]): void
    [MutationTypes.SetTableLoading](state: SecretaryViewState, isTableLoading: boolean): void
    [MutationTypes.ResetState](state: SecretaryViewState): void
    [MutationTypes.SetPaymentMethods](state: SecretaryViewState, paymentMethods: PaymentMethod[]): void
    [MutationTypes.AddRowSelected](state: SecretaryViewState, idSelected: number): void
    [MutationTypes.RemoveRowSelected](state: SecretaryViewState, idSelected: number): void
    [MutationTypes.ToggleAllRowsSelected](state: SecretaryViewState, shouldSelectAll: boolean): void
    [MutationTypes.SetFilters](state: SecretaryViewState, filters: SecretaryViewFilters): void
    [MutationTypes.SetIsChatPopoverOpen](state: SecretaryViewState, isChatPopoverOpen: boolean): void
    [MutationTypes.SetIsBulkCancelationPopoverOpen](
        state: SecretaryViewState,
        isBulkCancelationPopoverOpen: boolean
    ): void
    [MutationTypes.UpdateSecretaryViewAppointment](
        state: SecretaryViewState,
        payload: { appointmentId: number; bookingStatusTooltip: BookingStatusTooltip; status: number }
    ): void
}
