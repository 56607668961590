import { date, DateUnit } from '@/core/ui/utils'
import { GettersAdaptor } from '@/interfaces'

import { ChatType } from '../../domain/enums/chat.enums'
import { ChatGetters, GetterTypes } from './types'
import { ChatState } from './types/state'

export const getters: GettersAdaptor<ChatGetters, ChatState, any> = {
    [GetterTypes.GetIsGlobalMessageBlockActive]: state => state.isGlobalBlockMessageActive,
    [GetterTypes.GetChatTotalUnreadConversationsCount](state) {
        // todo add length once patient chat unread notifications are migrated
        const unreadPatientConversations = state.unreadConversations[ChatType.PatientChat]
        const unreadSaasInternalConversations = state.unreadConversations[ChatType.SaasInternalChat].length
        return unreadPatientConversations + unreadSaasInternalConversations
    },
    [GetterTypes.GetUnreadPatientConversations](state) {
        return state.unreadConversations[ChatType.PatientChat]
    },
    [GetterTypes.GetUnreadSaasInternalConversations](state) {
        return state.unreadConversations[ChatType.SaasInternalChat]
    },
    [GetterTypes.GetChatSessionToken](state) {
        if (!state.sessionToken) {
            return null
        }

        const { sessionToken, expiresAt } = state.sessionToken
        if (!sessionToken || !expiresAt || date(expiresAt).add(1, DateUnit.Hour) < date()) {
            return null
        }

        return sessionToken
    },
    [GetterTypes.GetPatientHasUnread]: state => state.patientHasUnread,
    [GetterTypes.GetQuickReminders]: state => state.quickReminders,
    [GetterTypes.GetSaasInternalChatDisclaimer]: state => state.isAcceptedSaasInternalChatDisclaimer,
    [GetterTypes.GetIsContactFromProfileAllowed]: state => state.isContactFromProfileAllowed,
    [GetterTypes.GetChatTemplates]: state => state.chatTemplates
}

export default getters
