import { getUrlFromTemplate } from '@dp-vue/utils'
import { AxiosRequestConfig } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import { ConsentFileType } from '@/features/privacyPolicy/domain/enums/privacyPolicy.enums'
import { PrivacyPolicySettings } from '@/features/privacyPolicy/domain/interfaces/privacyPolicy.interfaces'

const URL_BASE = 'privacy-policy'
const URL_DOWNLOAD_FILE_TEMPLATE = `${URL_BASE}/static-files/:fileType`
const URL_ACTIVATE_FEATURE = `${URL_BASE}/activate`
const URL_USER_FILES = `${URL_BASE}/userfiles`

class PrivacyPolicyRepository {
    async uploadPrivacyPolicy(file: File, fileName: string): Promise<void> {
        const url = `${URL_USER_FILES}?type=1`

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Docplanner-File': `fileName=${fileName}`
            }
        }

        const formData = new FormData()
        formData.append('file', file)

        const { data } = await httpAPI.post(url, formData, config)
        return data
    }

    downloadFileTemplate(fileType: ConsentFileType) {
        const url = getUrlFromTemplate({
            template: URL_DOWNLOAD_FILE_TEMPLATE,
            params: { fileType }
        })

        const config: AxiosRequestConfig = { responseType: 'blob' as const }
        return httpAPI.get(url, config)
    }

    updateSettings(updatedSettings: PrivacyPolicySettings) {
        return httpAPI.post(URL_BASE, updatedSettings)
    }

    activateFeature() {
        return httpAPI.post(URL_ACTIVATE_FEATURE)
    }

    getSettings() {
        return httpAPI.get(URL_BASE)
    }
}

export default new PrivacyPolicyRepository()
