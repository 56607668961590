import { SetupGuideTaskKind } from '../../enums'

interface SetupGuideTranslationGroup {
    labelPlural: string
    labelSingle: string
}
interface SetupGuideTranslationGroupKind extends SetupGuideTranslationGroup {
    title: string
}

const TRANSLATION_GROUP: SetupGuideTranslationGroup = {
    labelSingle: 'saas-setup-guide-task-group-label-singular',
    labelPlural: 'saas-setup-guide-task-group-label-plural'
}

const TRANSLATION_GROUP_KIND_KNOW: SetupGuideTranslationGroupKind = {
    title: 'saas-setup-guide-group-know-header',
    ...TRANSLATION_GROUP
}
const TRANSLATION_GROUP_KIND_TRUST: SetupGuideTranslationGroupKind = {
    title: 'saas-setup-guide-group-trust-header',
    ...TRANSLATION_GROUP
}
const TRANSLATION_GROUP_KIND_BOOK: SetupGuideTranslationGroupKind = {
    title: 'saas-setup-guide-group-book-header',
    ...TRANSLATION_GROUP
}

const TRANSLATION_GROUP_KIND: Record<SetupGuideTaskKind, SetupGuideTranslationGroupKind> = {
    [SetupGuideTaskKind.Know]: TRANSLATION_GROUP_KIND_KNOW,
    [SetupGuideTaskKind.Trust]: TRANSLATION_GROUP_KIND_TRUST,
    [SetupGuideTaskKind.Book]: TRANSLATION_GROUP_KIND_BOOK
}

export const getTranslationGroupFromKind = (kind: SetupGuideTaskKind): SetupGuideTranslationGroupKind => {
    if (!TRANSLATION_GROUP_KIND[kind]) {
        return { title: '', ...TRANSLATION_GROUP }
    }

    return TRANSLATION_GROUP_KIND[kind]
}
