import {
    getRecipientsTableHeaders,
    getRecipientsTableRows
} from '@/features/campaigns/ui/utils/getRecipientsTable.util'

import repository from '../api/campaigns.api'
import {
    CampaignsRecipientsCell,
    CampaignsRecipientsHeader,
    CampaignsRecipientsHeadersOptions,
    CampaignsRecipientsParams,
    CampaignsRecipientsTable
} from './interfaces/campaignsRecipients.interfaces'

class CampaignsRecipientsService {
    getRecipients(params: CampaignsRecipientsParams): Promise<CampaignsRecipientsTable> {
        return repository.getRecipients(params)
    }

    buildRecipientsHeaders(data: CampaignsRecipientsHeadersOptions): CampaignsRecipientsHeader[] {
        return getRecipientsTableHeaders(data)
    }

    buildRecipientsRows(
        data: CampaignsRecipientsTable,
        headers: CampaignsRecipientsHeader[]
    ): CampaignsRecipientsCell[] {
        return getRecipientsTableRows(data, headers)
    }
}

export default new CampaignsRecipientsService()
