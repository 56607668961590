import dayjs, { Dayjs } from 'dayjs'

import { addToDate, DateFormat, DateUnit, formatDate, parseDate } from '@/core/ui/utils'

/**
 * Returns the given date updated by the given minutes
 * @param date - date to update
 * @param minutes - minutes to add
 */
export const getDateUpdatedByMinutes = (date: string | Date | Dayjs, minutes: number) => {
    return addToDate(date, minutes, DateUnit.Minute)
}

/**
 * Returns the given date updated by the given minutes formatted as YYYY-MM-DDTHH:mm:ss
 * @param date - date to update
 * @param minutes - minutes to add
 */
export const getDateUpdatedByMinutesFormatted = (date: string | Date | Dayjs, minutes: number) => {
    return formatDate(getDateUpdatedByMinutes(date, minutes), DateFormat.DateStringNoUtcOffset)
}

/**
 * Returns the time formatted as HH:mm
 * @param date - date to format
 */
export const getDateTimeFormatted = (date: string | Date | Dayjs) => {
    return formatDate(date, DateFormat.HourMinuteFormat)
}

/**
 * Returns the given date formatted as YYYY-MM-DDTHH:mm:ss
 * @param date - date to format
 */
export const parseDateStringLog = (date: string | Date | Dayjs) => {
    return parseDate(date, DateFormat.DateStringLogFormat)
}

/**
 * Returns the given date formatted as DD/MM/YYYY
 * @param date - date to format
 */
export const parseDateDayMonthYear = (date: string | Date | Dayjs) => {
    return parseDate(date, DateFormat.DateDayMonthYear)
}

/**
 * Returns if the given date is in the future
 * @param date - date to check
 */
export function isFuture(date: string | Date | Dayjs) {
    return dayjs(date).isAfter(new Date())
}

/**
 * Returns if the given date is in the future
 * @param date - date to check
 */
export function formatDateStringLogFormat(date: string | Date | Dayjs) {
    return formatDate(date, DateFormat.DateStringLogFormat)
}
