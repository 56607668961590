export const PERMISSIONS_LEVEL = {
    NONE: 0,
    READ: 1,
    WRITE: 2,
    ADMIN: 3,
    OWNER: 5
}

export const SECRETARY_TYPES = {
    RECEPTIONIST: 0,
    HEAD_RECEPTION: 1,
    ACCOUNTANT: 2,
    MARKETING: 3
}

export const USER_TYPES = {
    ...SECRETARY_TYPES,
    SPECIALIST: 4
}
