import { ensureToggleIsEnabled as ensure } from './utils/ensureToggleIsEnabled'
import { getActualToggles } from './utils/getActualToggles'
import { getQueryStringFromUrl } from './utils/getQueryStringFromUrl'

const actualToggles = getActualToggles()

export const ensureToggleIsEnabled = (key: string, value: boolean) => {
    return ensure({
        key,
        value,
        toggles: actualToggles,
        queryString: getQueryStringFromUrl()
    })
}
