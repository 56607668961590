import { SETTINGS_GENERAL } from '@/features/settings/ui/constants/router.constants'

export const SETTINGS_SERVICES_CONFIGURATION_ROUTES = {
    ADD: 'settings-services-add',
    LIST: 'settings-services-list',
    DETAIL: 'settings-services-details',
    COPY: 'settings-services-copy'
}

export { SETTINGS_GENERAL }
