import UrlParse from 'url-parse'

import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'

const { hostname } = new UrlParse()

let loaded = false
let config = {}

const load = () => {
    config = { ...window.ENV_CONFIG }
    loaded = true
}

const getValue = variable => {
    if (!loaded) {
        load()
    }

    const genericConfig = config.base[variable]
    const specificConfig = config[hostname] && config[hostname][variable]

    if (typeof genericConfig === 'undefined' && typeof specificConfig === 'undefined') {
        const noConfigValueError = `No configuration value found for an environment variable named %c${variable}%c, check %clocal.example%c file for missing ones.`
        monitor.send(noConfigValueError, {
            level: SeverityLevels.Error,
            extra: {
                isConfigLoaded: loaded,
                hostname,
                variable,
                genericValue: genericConfig,
                specificValue: specificConfig,
                genericVariables: Object.keys(config.base),
                specificVariables: config[hostname] && Object.keys(config[hostname])
            }
        })
        // eslint-disable-next-line no-console
        console.info(noConfigValueError, 'color: darkblue; font-weight: bold;', '', 'font-weight: bold;', '')
    }
    return typeof specificConfig !== 'undefined' ? specificConfig : genericConfig
}

const getSupportedDomains = () => {
    if (!loaded) {
        load()
    }
    return Object.keys(config).filter(key => key !== 'base')
}

const configManager = {
    getValue,
    getSupportedDomains
}

export default configManager
