import httpCacheAPI from '@/api/utils/httpClientCacheAPI'
import { ColorSchemasDTO } from '@/features/rooms/api/interfaces/rooms.DTO'

import { HTTP_REQUESTS_CACHE_TTL_60H } from './constants/httpClient.constants'

const URL_BASE = 'color-schemas'

class ColorSchemasApi {
    async getCalendarColorSchemas(): Promise<ColorSchemasDTO[]> {
        const { data } = await httpCacheAPI.get(URL_BASE, {
            // @ts-expect-error: cache is not a property of AxiosRequestConfig https://docplanner.atlassian.net/browse/WSTB-351
            cache: {
                ttl: HTTP_REQUESTS_CACHE_TTL_60H
            }
        })

        return data
    }
}

export default new ColorSchemasApi()
