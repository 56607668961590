import {
    CAMPAIGNS_ROUTE_NAME,
    CAMPAIGNS_ROUTE_NAME_REPORT,
    CAMPAIGNS_ROUTE_NAME_TITLE,
    CAMPAIGNS_WIZARD_ROUTE_NAME_LIST
} from '@/features/campaigns/ui/constants/campaignsRouter.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'

const CampaignsIndex = () =>
    import(/* webpackChunkName: "campaigns-index" */ '@/features/campaigns/ui/views/CampaignsIndex.vue')
const CampaignsReport = () =>
    import(/* webpackChunkName: "campaigns-report" */ '@/features/campaigns/ui/views/CampaignsReport.vue')
const CampaignsWizard = () =>
    import(/* webpackChunkName: "campaigns-wizard" */ '@/features/campaigns/ui/components/CampaignsWizard.vue')

const wizardRoutes = CAMPAIGNS_WIZARD_ROUTE_NAME_LIST.map(name => ({
    path: `/${CAMPAIGNS_ROUTE_NAME}-${name}`,
    name,
    component: CampaignsWizard
}))

export default {
    path: `/${CAMPAIGNS_ROUTE_NAME}`,
    component: CampaignsIndex,
    meta: {
        middleware: [uninav],
        title: CAMPAIGNS_ROUTE_NAME_TITLE,
        uninavName: CAMPAIGNS_ROUTE_NAME
    },
    children: [
        {
            path: '',
            name: CAMPAIGNS_ROUTE_NAME_REPORT,
            component: CampaignsReport
        },
        ...wizardRoutes
    ]
}
