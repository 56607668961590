import repository from '../api/conversations.api'
import { Conversations, ConversationsResponse, SessionToken } from './interfaces/conversations.interfaces'

class ConversationsService {
    getConversationsList(
        type: string,
        unread: string,
        limit: number,
        token: string,
        hostId?: number
    ): Promise<ConversationsResponse<Conversations>> {
        return repository.getConversationsList(type, unread, limit, token, hostId)
    }

    getNumberOfConversations(type: number): Promise<number> {
        return repository.getNumberOfConversations(type)
    }

    getNumberOfUnreadConversations(type: number, hostId?: string): Promise<number> {
        return repository.getNumberOfUnreadConversations(type, hostId)
    }

    getUserSessionToken(): Promise<SessionToken> {
        return repository.getUserSessionToken()
    }
}

export default new ConversationsService()
