import doctorRepository from '@/features/doctor/api/doctor.api'
import { Doctor } from '@/features/doctor/domain/interfaces/doctor.interfaces'
import { orderByAttribute } from '@/utils/array'

import repository from '../api/hints.api'
import {
    HINT_PAYMENT_METHODS,
    HINT_SCHEDULE,
    HINT_SERVICE,
    HINT_SPECIALIST,
    HINT_STATUS,
    STATUS_ORDER
} from '../ui/constants/hints.constants'
import { MappedReportsHintObject, ReportsHintObject } from './interfaces/hints.interfaces'

export class HintsService {
    async getHint(columnTag: string): Promise<MappedReportsHintObject[]> {
        const data = await repository.getHint(columnTag)
        return this.mapHint(data)
    }

    async getHintForAllSpecialists(): Promise<MappedReportsHintObject[]> {
        const data = await doctorRepository.getDoctors()
        return this.mapDoctorAsHint(data)
    }

    orderHintItems(columnTag: string, items: MappedReportsHintObject[] = []): MappedReportsHintObject[] {
        switch (columnTag) {
            case HINT_SPECIALIST:
            case HINT_SCHEDULE:
            case HINT_SERVICE:
                return orderByAttribute(items, 'text')
            case HINT_STATUS:
                return STATUS_ORDER.reduce((array: MappedReportsHintObject[], statusId) => {
                    const status = items.find(({ value }) => value === statusId)
                    if (status) {
                        array.push(status)
                    }
                    return array
                }, [])
            case HINT_PAYMENT_METHODS:
                return orderByAttribute(items, 'id')
            default:
                return items
        }
    }

    mapHint(data: ReportsHintObject[]): MappedReportsHintObject[] {
        return data.map(({ displayValue, filterValue, data: reportHintData }) => ({
            text: displayValue,
            value: filterValue,
            data: reportHintData
        }))
    }

    mapDoctorAsHint(data: Doctor[]): MappedReportsHintObject[] {
        return data.map(({ fullName: displayValue, id: filterValue, userSchedules: [{ facilityId }] }) => ({
            text: displayValue,
            value: String(filterValue),
            data: {
                facilities: [facilityId]
            }
        }))
    }
}

export default new HintsService()
