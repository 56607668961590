import { parse } from 'query-string'

import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'
import { getExpectedParam, routeBase } from '@/router/utils/helpers'
import { getDecodedUrl, isAbsolutePath, origin, removeQueryParams } from '@/utils/url'

import { REDIRECT_URL_QUERY_PARAM } from './redirection.constants'
import { isDestinationAllowed } from './redirection.utils'

export default async function redirection({ next }) {
    if (location?.search?.length) {
        let redirectTo = ''

        const params = parse(location.search.slice(1))

        redirectTo = params[getExpectedParam(params)] || ''

        if (redirectTo.length) {
            redirectTo += location.hash
            const decodedUrl = getDecodedUrl(redirectTo).charAt(0)

            if (decodedUrl !== '/' && decodedUrl !== '?' && !isAbsolutePath(redirectTo)) {
                redirectTo = origin + routeBase + redirectTo
            }

            if (isDestinationAllowed(redirectTo)) {
                location.replace(redirectTo)
            } else {
                monitor.send('Blocked redirect', {
                    level: SeverityLevels.Warning,
                    extra: {
                        url: redirectTo
                    }
                })

                removeQueryParams([REDIRECT_URL_QUERY_PARAM])
                return next('/')
            }

            return next(false)
        }
    }

    next()
}
