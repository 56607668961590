export enum ReportsCellComponent {
    AmountPaid = 'ReportsAmountPaidCell',
    Category = 'ReportsCategoryCell',
    EventServices = 'ReportsEventServicesCell',
    ExpensePaymentStatus = 'ReportsExpensePaymentStatusCell',
    Insurance = 'ReportsInsuranceCell',
    PaymentMethod = 'ReportsPaymentMethodCell',
    PaymentStatus = 'ReportsPaymentStatusCell',
    Price = 'ReportsPriceCell',
    Service = 'ReportsServiceCell',
    VisitStatus = 'ReportsVisitStatusCell'
}
