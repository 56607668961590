import { Request, RequestsIntegrationDto, RequestsPaginated } from '../../../../domain/interfaces/requests.interfaces'
import { RequestState } from './state'

export enum MutationTypes {
    Pages = 'PAGES',
    Requests = 'REQUESTS',
    UpdateAllowRequestsFromNewPatients = 'UPDATE_ALLOW_REQUESTS_FROM_NEW_PATIENTS',
    UpdateIsRequestFeatureEnabled = 'UPDATE_IS_REQUEST_FEATURE_ENABLED',
    UpdateRequestList = 'UPDATE_REQUEST_LIST',
    UpdateRequestsIntegration = 'UPDATE_REQUESTS_INTEGRATION',
    UpdateUnreadRequestCount = 'UPDATE_UNREAD_REQUESTS_COUNT'
}

export type RequestMutations = {
    [MutationTypes.UpdateRequestsIntegration](state: RequestState, requestsIntegration: RequestsIntegrationDto): void
    [MutationTypes.UpdateIsRequestFeatureEnabled](state: RequestState, isEnabled: boolean): void
    [MutationTypes.Pages](state: RequestState, pages: number): void
    [MutationTypes.Requests](state: RequestState, requests: RequestsPaginated): void
    [MutationTypes.UpdateRequestList](state: RequestState, requests: Request[]): void
    [MutationTypes.UpdateUnreadRequestCount](state: RequestState, unreadCount: number): void
    [MutationTypes.UpdateAllowRequestsFromNewPatients](state: RequestState, allowRequestsFromNewPatient: boolean): void
}
