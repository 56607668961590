import Storage from '@/store/storage'
import { IS_SIDEBAR_COLLAPSED } from '@/store/storage-types'

import { CalendarSidebarMutations, MutationTypes } from './types/mutations'

export const mutations: CalendarSidebarMutations = {
    [MutationTypes.SetCalendarSidebarCollapsed](state, isCollapsed) {
        Storage.set(IS_SIDEBAR_COLLAPSED, isCollapsed)
        state.isSidebarCollapsed = isCollapsed
    }
}
