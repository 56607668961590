export const CALENDAR_NAVIGATE_PREV = 'prev'
export const CALENDAR_NAVIGATE_NEXT = 'next'
export const CALENDAR_NAVIGATE_UNIT = {
    WEEK: 'week',
    DAY: 'day'
}

export const CALENDAR_AGENDA = 'agenda'
export const CALENDAR_VIEW = 'calendarView'
export const CALENDAR_VIEW_DAILY = 'timeGridDay'
export const CALENDAR_VIEW_CLINICS_DAILY = 'resourceTimeGridDay'
export const CALENDAR_VIEW_WEEKLY = 'timeGridWeek'
export const CALENDAR_VIEW_RESOURCE_WEEKLY = 'resourceTimeGridWeek'
export const CALENDAR_TRACKING_VIEW_DAY = 'daily'
export const CALENDAR_TRACKING_VIEW_WEEK = 'weekly'

export const ACTIVE_BUTTON_STATE = 'active'

export const FREE_SLOTS_TITLE_TEST_ID = 'freeSlotsTitle'
export const CALENDAR_DATE_TITLE_TEST_ID = 'calendarCurrentDate'

export const RIGHTBAR_OPENED_CLASS_NAME = 'rightbar-opened'

export const CALENDAR_SCROLL_TIME_OFFSET = 1000
export const CALENDAR_APPLY_FILTERS_DEBOUNCE_TIME = 100
export const CALENDAR_CHANGE_VIEW_DEBOUNCE_TIME = 100

export const NOTIFIED = true
export const APPOINMENT_NOTIFICATION_OPTIONS = [
    {
        value: NOTIFIED,
        label: 'returns-lightbox-be-notified-yes'
    },
    {
        value: !NOTIFIED,
        label: 'returns-lightbox-be-notified-no'
    }
]

export const COLOR_THEME_LIGHT = 'light'
export const COLOR_THEME_DARK = 'dark'

export const COLOR_BY_TYPE_SERVICE = 'service'
export const COLOR_BY_TYPE_AGENDA = 'agenda'

export const CALENDAR_TYPE = {
    DEVICE: 'device',
    STAFF: 'staff',
    SPECIALIST: 'specialist',
    ROOM: 'room'
}
export const CALENDAR_KIND = {
    doctor: 0,
    device: 1,
    staff: 2
}

export const DISABLED_CALENDAR_TYPES = [CALENDAR_TYPE.SPECIALIST, CALENDAR_TYPE.ROOM]

export const MIN_SERVICES_TO_SEARCH = 10

export const MAX_NUMBER_TIMES_COPY_CUT_TOAST = 3

export const PASTE_MODAL_MINUTE_RANGE = 60
export const PASTE_MODAL_SLOT_DURATION = 5

export const NO_RESOURCE_SELECTED_CSS_CLASS = 'no-resource-selected'
export const DEFAULT_DEBOUNCE_TIME_FREE_SLOTS = 500

export const STORE_CALENDAR_FILTERS_STORAGE_KEY = 'filters-state'

export const MODE_BOOKING_DRAWER_FREEZE_CLASSNAME = 'is-booking-drawer-mode-freeze'
export const MODE_EVENT_SELECTED_CLASSNAME = 'is-mode-selected'
