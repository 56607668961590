import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { parseParams } from '@/utils/parseParams'

import {
    FacilityRoomDTO,
    FacilityRoomsCheckNameQueryParamsDTO,
    FacilityRoomsWithAvailabilityQueryParamsDTO
} from './interfaces/rooms.DTO'

const URL_BASE = 'rooms'
const URL_ROOM_ID = `${URL_BASE}/:id`
const URL_CHECK_NAME = `${URL_BASE}/check`
const URL_AVAILABILITY = `${URL_BASE}/availability`

class RoomsRepository {
    async getRooms(): Promise<FacilityRoomDTO[]> {
        const { data } = await httpAPI.get<FacilityRoomDTO[]>(URL_BASE)
        return data
    }

    async checkName(queryParams: FacilityRoomsCheckNameQueryParamsDTO): Promise<boolean> {
        const url = getUrlFromTemplate({ template: URL_CHECK_NAME, query: parseParams(queryParams) })
        const { data } = await httpAPI.get<boolean>(url)
        return data
    }

    async createRoom(room: FacilityRoomDTO): Promise<number> {
        const { data } = await httpAPI.post<number>(URL_BASE, room)
        return data
    }

    async updateRoom(room: FacilityRoomDTO): Promise<void> {
        const url = getUrlFromTemplate({ template: URL_ROOM_ID, params: { id: room.id } })
        await httpAPI.put<void>(url, room)
    }

    async deleteRoom(room: FacilityRoomDTO): Promise<void> {
        const url = getUrlFromTemplate({ template: URL_ROOM_ID, params: { id: room.id } })
        await httpAPI.delete<void>(url)
    }

    async getRoomsWithAvailability(queryParams: FacilityRoomsWithAvailabilityQueryParamsDTO) {
        const url = getUrlFromTemplate({ template: URL_AVAILABILITY, query: parseParams(queryParams) })
        const { data } = await httpAPI.get<FacilityRoomDTO[]>(url)
        return data
    }
}

export default new RoomsRepository()
