import InvoicingEntities from '@/api/InvoicingEntities'
import AccountService from '@/features/auth/domain/account.service'

import { ACTION_FETCH_INVOICING_ENTITIES, ACTION_FETCH_TERMS_ACCEPTED } from './types/actions'
import { MUTATION_SET_INVOICING_ENTITIES, MUTATION_SET_TERMS_ACCEPTED } from './types/mutations'

const invoicingEntitiesAPI = InvoicingEntities()

const actions = {
    async [ACTION_FETCH_TERMS_ACCEPTED]({ commit, state }) {
        if (state.areTermsAndConditionsAccepted === undefined) {
            const areTermsAccepted = await AccountService.getInvoicingIntegrationTermsAccepted()
            commit(MUTATION_SET_TERMS_ACCEPTED, areTermsAccepted)
        }
    },
    async [ACTION_FETCH_INVOICING_ENTITIES]({ commit }) {
        const invoicingentities = await invoicingEntitiesAPI.getAll()
        commit(MUTATION_SET_INVOICING_ENTITIES, invoicingentities)
    }
}

export default actions
