export const REFRESH_PATIENTS_PROFILE = 'Vue.refreshPatientProfile'
export const REFRESH_PATIENT_PICTURE = 'Vue.refreshPatientPicture'

export const ATTENDANCE_STATUS = {
    UNKNOWN: 0,
    VISITED: 1,
    NOT_VISITED: 2,
    PAID: 5
}

export const APPOINTMENT_STATUS = {
    SCHEDULED: 0,
    CANCELED_BY_USER: 1,
    CANCELED_BY_PATIENT: 2,
    WAITING_FOR_CONFIRMATION: 3,
    CONFIRMED_BY_PATIENT: 4
}

export const EVENT_STATUS_NAMES = {
    visited: 'visited',
    paid: 'paid',
    cancelled: 'cancelled'
}

export const RESET_FORM_DATA = 'reset-form-data'
