import { AxiosError } from 'axios'

import { ErrorToTransform, MessageGenerator } from '@/api/utils/interceptors/types/interceptors.interfaces'

import { errorMessageGenerators } from './utils/generateErrorMessage'

const REQUEST_FAILED_404 = 'Request failed with status code 404'
const NETWORK_ERROR = 'Network Error'
const REQUEST_FAILED = 'Request failed'
const REQUEST_FAILED_400 = 'Request failed with status code 400'

const errorsToTransform: ErrorToTransform[] = [
    {
        errorMatch: NETWORK_ERROR,
        errorType: NETWORK_ERROR,
        generatorName: MessageGenerator.GenerateGenericErrorMessage
    },
    {
        errorMatch: REQUEST_FAILED_400,
        errorType: REQUEST_FAILED_400,
        generatorName: MessageGenerator.Generate400ErrorMessage
    },
    {
        errorMatch: REQUEST_FAILED_404,
        errorType: REQUEST_FAILED,
        generatorName: MessageGenerator.GenerateGenericErrorMessage,
        override: {
            statusCode: 404
        }
    }
]

export const handleErrorInterceptors = (error: AxiosError) => {
    const errorFound = errorsToTransform.find(({ errorMatch }) => error.message === errorMatch)
    if (errorFound) {
        return errorMessageGenerators[errorFound.generatorName](error, errorFound)
    }

    return null
}
