import { types } from '@docplanner/one-tracking'

import { ProfileEntity } from '@/features/auth/domain/interfaces/profile.interfaces'

import { EventObject, Properties, SuperPropertiesType, UserRecordsType } from '../types'

const getEventName = ({ category, action, label }: EventObject) => {
    let eventName = ''
    eventName += category || ''
    eventName += action && action !== '' ? ` ${action}` : ''
    eventName += label && label !== '' ? ` ${label}` : ''
    return eventName
}

export const initialize = (user: ProfileEntity) => {
    if (typeof window.mixpanel === 'undefined') return

    const { id, countryCode } = user ?? {}

    if (!id || !countryCode) {
        return
    }

    const trackId = ['user', id, user.countryCode.toLowerCase()].join('-')

    const superProperties: SuperPropertiesType = {
        distinct_id: trackId,
        saas: 'true',
        'User Agent': window.navigator?.userAgent,
        ...(window.mixPanelAdditionalInfo || {})
    }
    const userRecords: UserRecordsType = {
        $name: user.name,
        $email: user.username,
        country_user: user.countryCode,
        Type: user.userType,
        'Medical speciality': user.specialityName,
        'Native App': false,
        'Native Platform': 'web',
        'Docplanner user id': user.docplannerUserId,
        'Doctoralia id': user.doctoraliaId
    }

    window.mixpanel.identify(trackId)
    window.mixpanel.register(superProperties)
    window.mixpanel.people.set(userRecords)
}

export const trackEvent = (eventName: types.Event | types.ExtendedEvent, eventProperties?: Properties) => {
    if (typeof window.mixpanel === 'undefined') {
        return
    }

    if (typeof window.mixpanel.get_property === 'function') {
        if (!window.mixpanel.get_property('distinct_id')) {
            return
        }

        const name = getEventName(eventName)

        window.mixpanel.track(name, eventProperties)
    }
}
