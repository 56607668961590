import { Route } from 'vue-router'

import uninav from '@/features/navigation/ui/router/uninav.middleware'

import PluginsService from '../../domain/plugins.service'

const PluginStatic = () => import(/* webpackChunkName: "plugin-static" */ '../views/PluginStatic.vue')

export default [
    {
        path: 's/:id',
        name: 'static',
        component: PluginStatic,
        meta: {
            middleware: uninav,
            uninavName: 'logo'
        }
    },
    {
        path: 'plugins',
        name: 'plugins',
        beforeEnter: (to: Route, from: Route, next: any) => {
            PluginsService.getPluginsPath()
                .then(result => {
                    location.href = result
                })
                .catch(error => {
                    next({
                        name: 'error',
                        params: {
                            error
                        }
                    })
                })
        }
    }
]
