import { types } from '@docplanner/one-tracking'

import { HubSpotInternalEventName, Properties } from '../types'

const formatHubSpotInternalEventName = (event: types.Event, suffix?: string): HubSpotInternalEventName => {
    const hubSpotPortalId = process.env.HUBSPOT_PORTAL_ID || 'pe442271'
    const { category, action } = event

    const internalEventName = suffix
        ? `${hubSpotPortalId}_${category}__${action}_${suffix}`
        : `${hubSpotPortalId}_${category}__${action}`

    return internalEventName.toLowerCase()
}

/* eslint-disable no-underscore-dangle */
export const trackEvent = (event: types.Event, properties?: Properties) => {
    window._hsq = window._hsq || []
    const hubSpotInstance = window._hsq || []
    const name = formatHubSpotInternalEventName(event, properties?.hs_suffix)

    hubSpotInstance.push([
        'trackCustomBehavioralEvent',
        {
            name
        }
    ])
}
/* eslint-enable no-underscore-dangle */
