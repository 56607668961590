const bodyClass = (to, router) => {
    const classNamePrefix = 'page-'
    const newClassName = classNamePrefix + to.name
    const classes = [...document.body.classList]

    classes.forEach(name => {
        if (name.indexOf(classNamePrefix) === 0) {
            document.body.classList.remove(name)
        }
    })

    document.body.classList.add(newClassName)
}

export default bodyClass
