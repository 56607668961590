import { getTstName, isDevelopment, isTst } from '@/bootstrap/environment'
import toggles from '@/config/featureToggles.json'
import localEnvironmentToggles from '@/config/featureToggles.local.json'
import tstEnvironmentToggles from '@/config/featureToggles.test.json'

const environmentToggles = (() => {
    if (isTst) {
        const tstName = getTstName()

        return tstEnvironmentToggles[tstName] || {}
    }

    if (isDevelopment) {
        return localEnvironmentToggles
    }

    return {}
})()

export const getActualToggles = () => ({
    ...toggles,
    ...environmentToggles
})
