export enum PaymentsNotificationEnum {
    ActionRequiredModal = 'PaymentsActionRequiredModal',
    UnpaidInvoicesBanner = 'PaymentsUnpaidInvoicesBanner'
}

export enum PaymentsNotificationFeatureToggleEnum {
    ActionRequiredModal = 'StripePayinsNotification',
    UnpaidInvoicesBanner = 'UnpaidInvoicesNotification'
}

export enum PaymentsNotificationCookieEnum {
    ActionRequiredModal = 'payment_action_required',
    UnpaidInvoicesBanner = 'unpaid_invoices_banner'
}

export enum PaymentsNotificationUserSettingEnum {
    BannerModal = 'hidePaymentsBanner'
}
