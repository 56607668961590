import httpAPI from '@/api/utils/httpClientAPI'
import { ScheduleOrderDTO, ScheduleOrderPayload } from '@/features/calendar/api/interfaces/orderColumns.interfaces'

const URL_BASE = 'calendar'
const URL_SCHEDULES_ORDER = 'headers'

class CalendarRepository {
    async getAll(): Promise<any[]> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }

    async getSchedulesOrder(): Promise<ScheduleOrderDTO[]> {
        const { data } = await httpAPI.get(`${URL_BASE}/${URL_SCHEDULES_ORDER}`)

        return data
    }

    async saveSchedulesOrder(payload: ScheduleOrderPayload[]): Promise<void> {
        return httpAPI.post(`${URL_BASE}/${URL_SCHEDULES_ORDER}`, payload)
    }
}

export default new CalendarRepository()
