import Services from '@/api/Services'
import repository from '@/features/voucher/api/voucherType.api'

import {
    CreateVoucherType,
    PaginatedVoucherType,
    SellVoucherTypeParameters,
    ServiceApi,
    VoucherService,
    VoucherSoldResponse,
    VoucherType,
    VoucherTypesParameters
} from './interfaces/voucher.interfaces'

class VoucherTypeService {
    get(voucherId: number): Promise<VoucherType> {
        return repository.get(voucherId)
    }

    getVoucherTypes(params?: VoucherTypesParameters): Promise<VoucherType[]> {
        return repository.getVoucherTypes(params)
    }

    getVoucherTypesPaginated(params: VoucherTypesParameters, page = 0): Promise<PaginatedVoucherType> {
        return repository.getVoucherTypesPaginated(params, page)
    }

    create(voucher: CreateVoucherType): Promise<VoucherType> {
        return repository.create(voucher)
    }

    update(id: number, voucher: VoucherType): Promise<boolean> {
        return repository.update(id, voucher)
    }

    delete(voucherId: number): Promise<boolean> {
        return repository.delete(voucherId)
    }

    activate(voucherId: number): Promise<void> {
        return repository.activate(voucherId)
    }

    deactivate(voucherId: number): Promise<void> {
        return repository.deactivate(voucherId)
    }

    async getServices(): Promise<VoucherService[]> {
        const { data } = await Services().getServices()

        return data.reduce((services: VoucherService[], service: ServiceApi): VoucherService[] => {
            if (service.schedules?.length === 0) {
                return services
            }

            return [
                ...services,
                {
                    id: service.id,
                    value: service.id,
                    text: service.name,
                    price: service.price,
                    duration: service.duration,
                    configuration: service.configuration,
                    ownerId: service.ownerId
                }
            ]
        }, [])
    }

    sell(voucherTypeId: number, voucher: SellVoucherTypeParameters): Promise<VoucherSoldResponse> {
        return repository.sell(voucherTypeId, voucher)
    }
}

export default new VoucherTypeService()
