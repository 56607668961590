import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { DateFormat, formatDate } from '@/core/ui/utils'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { Attendance } from '@/features/calendar/domain/enums/attendance.enums'
import { CancelationReason } from '@/features/calendar/domain/enums/cancelationReason.enums'

import {
    AppointmentDTO,
    AppointmentEvent,
    AppointmentInvoice,
    CalendarAppointmentDTO,
    CancellationEvents,
    TodayAppointmentsDTO,
    VisitPaymentMethodInput,
    VisitPriceInput,
    VisitRemovePaymentMethodInput,
    VisitUpdatePaymentMethodsInput
} from '../domain/interfaces/appointment.interfaces'

const URL_BASE = 'appointments'

const URL_UPDATE_VISIT_PRICE = `${URL_BASE}/:visitId/price/:priceValue`
const URL_UPDATE_VISIT_PAYMENT_METHOD = `${URL_BASE}/:visitId/paymentmethod/:paymentMethod`
const URL_DELETE_VISIT_PAYMENT_METHOD = `${URL_BASE}/:visitId/paymentmethod`
const URL_MARK_ALL_PAID = `${URL_BASE}/paymentmethod/markallpaid`
const URL_APPOINTMENT_SUMMARY = `${URL_BASE}/:appointmentId/summary`
const URL_SEND_CANCELLATION_MESSAGE = `${URL_BASE}/bulk-cancellation`
const URL_APPOINTMENT_INVOICES = `${URL_BASE}/:appointmentId/invoices`
const URL_APPOINTMENT_DOCUMENTATION_DATA = `${URL_BASE}/:appointmentId/documentation-data`
const URL_TODAY_APPOINTMENTS = `${URL_BASE}/day/:day`

const URL_APPOINTMENT_ID = `${URL_BASE}/:id`

const URL_APPOINTMENTS_V2 = `v2/${URL_BASE}`
const URL_APPOINTMENTS_V1 = `v1/${URL_BASE}`
const URL_APPOINTMENT_ID_V2 = `${URL_APPOINTMENTS_V2}/:id`
const URL_COPY_APPOINTMENT = `${URL_APPOINTMENT_ID}/copy`
const URL_ATTENDANCE = `${URL_APPOINTMENT_ID_V2}/attendance/:attendance`

const URL_MOVE_APPOINTMENT = `${URL_APPOINTMENTS_V1}/:id/move`
const URL_UPDATE_APPOINTMENT_DURATION = `${URL_APPOINTMENTS_V1}/:id/duration`

class CalendarAppointmentsRepository {
    async getCalendarAppointment(appointmentId: number): Promise<CalendarAppointmentDTO> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_ID,
            params: {
                id: appointmentId
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getTodayAppointments(day: Date): Promise<TodayAppointmentsDTO[]> {
        const url = getUrlFromTemplate({
            template: URL_TODAY_APPOINTMENTS,
            params: {
                day: formatDate(day, DateFormat.DateDashedFormat)
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async updateAttendance(id: number, attendance: Attendance, date: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_ATTENDANCE,
            params: { id, attendance }
        })
        await httpAPI.patch(url, null, { params: { date } })
    }

    async moveVisitEvent(
        { id, scheduleId, start, end, roomId, eventServices, noShowProtection }: AppointmentEvent,
        notifyPatient: boolean,
        sendAdvanceAppointment: boolean,
        recurrentOperationMode: number | null
    ) {
        const url = getUrlFromTemplate({
            template: URL_MOVE_APPOINTMENT,
            params: { id }
        })

        const { start: startDateTime } = CalendarEventService.processStartEndToISODate({ start, end })

        const { data } = await httpAPI.patch<AppointmentDTO>(
            url,
            {},
            {
                params: {
                    scheduleId,
                    roomId,
                    startDateTime,
                    noShowProtection,
                    notifyPatient,
                    sendAdvanceAppointment,
                    recurrentOperationMode
                }
            }
        )
        return data
    }

    async updateVisitDuration(
        { id, scheduleId, start, end, roomId, eventServices, noShowProtection }: AppointmentEvent,
        recurrentOperationMode: number | null
    ) {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_APPOINTMENT_DURATION,
            params: { id }
        })

        const { start: startDateTime, end: endDateTime } = CalendarEventService.processStartEndToISODate({ start, end })
        const { data } = await httpAPI.patch<AppointmentDTO>(url, eventServices, {
            params: {
                scheduleId,
                roomId,
                startDateTime,
                endDateTime,
                noShowProtection,
                recurrentOperationMode
            }
        })
        return data
    }

    async updateVisitPrice({ visitId, priceValue }: VisitPriceInput): Promise<AppointmentDTO> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_VISIT_PRICE,
            params: { visitId, priceValue }
        })

        const { data } = await httpAPI.put(url, {})

        return data
    }

    async updateVisitPaymentMethod({ visitId, paymentMethod }: VisitPaymentMethodInput): Promise<AppointmentDTO> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_VISIT_PAYMENT_METHOD,
            params: { visitId, paymentMethod }
        })
        const { data } = await httpAPI.patch(url, {})

        return data
    }

    async removeVisitPaymentMethod({ visitId }: VisitRemovePaymentMethodInput): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DELETE_VISIT_PAYMENT_METHOD,
            params: { visitId }
        })
        const { data } = await httpAPI.delete(url)

        return data
    }

    async updateVisitsAsPaid({ visitIds, paymentMethodId }: VisitUpdatePaymentMethodsInput): Promise<void> {
        const { data } = await httpAPI.post(URL_MARK_ALL_PAID, {
            visitIds,
            paymentMethodId
        })

        return data
    }

    async getAppointmentSummary(appointmentId: number): Promise<AppointmentDTO> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_SUMMARY,
            params: { appointmentId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getAppointmentInvoices(appointmentId: number): Promise<AppointmentInvoice[]> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_INVOICES,
            params: { appointmentId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async copyAppointment(
        { id, scheduleId, start, end, roomId }: AppointmentEvent,
        notifyPatient: boolean
    ): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_COPY_APPOINTMENT,
            params: { id }
        })
        const { data } = await httpAPI.post(url, {
            scheduleId,
            roomId,
            notifyPatient,
            startDateTime: start,
            endDateTime: end
        })

        return data
    }

    async getAppointmentDocumentationData(appointmentId: number): Promise<Record<string, string>> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_DOCUMENTATION_DATA,
            params: { appointmentId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async sendCancellationMessage(
        appointmentIds: number[],
        reason: number,
        comments: string
    ): Promise<CancellationEvents> {
        const { data } = await httpAPI.delete(URL_SEND_CANCELLATION_MESSAGE, {
            data: {
                ids: appointmentIds,
                cancellationSetting: {
                    reason:
                        reason === CancelationReason.DoNotSendNotification ? CancelationReason.DoNotSpecify : reason,
                    comments,
                    sendAdvanceAppointment: false
                },
                recurrentOperationMode: 0,
                notifyPatient: reason !== CancelationReason.DoNotSendNotification
            }
        })

        return data
    }
}

export default new CalendarAppointmentsRepository()
