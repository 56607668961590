export const PATIENTS_LIST = 'patients-list'
export const PATIENT_EDIT_TAB = 'patient-tab-edit'
export const PATIENT_RECORD_TAB = 'patient-tab-record'
export const PATIENT_HISTORY_TAB = 'patient-tab-history'
export const PATIENT_FILES_TAB = 'patient-tab-files'
export const PATIENTS_FILES_TAB_AUTO_OPEN_QUERY_KEY = 'preview'
export const PATIENT_PROFILE = 'patient-profile'
export const PATIENT_MESSAGES_TAB_DEPRECATED = 'patient-tab-messages'
export const PATIENT_FINANCIAL_TAB = 'patient-tab-financial'
export const PATIENT_PLUGIN_TAB = 'patient-tab-plugin'
export const PATIENT_FILE_GENERATOR_TAB = 'patient-tab-files-generator'
