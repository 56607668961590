import { AutoFormatter } from '../types'

export const toUppercase: AutoFormatter = (value: string) => value.toUpperCase()

export const removeLeadingAndTradingSpaces: AutoFormatter = (value: string) => value.trim()

export const capitalize: AutoFormatter = (value: string) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`

export const lettersOnly: AutoFormatter = (value: string) => value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\\/,/-]/g, '')

export const digitsOnly: AutoFormatter = (value: string) => value.replace(/[^\d-]/g, '')

export const numbersOnly: AutoFormatter = (value: string) => value.replace(/[^\d.-]/g, '')

export const threeDecimals: AutoFormatter = (value: string) =>
    value.includes('.') && value.split('.')[1].length > 3 ? parseFloat(value).toFixed(3) : value
