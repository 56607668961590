import { DateFormat, formatDate } from '@/core/ui/utils'
import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import repository from '@/features/schedule/api/schedule.api'
import { SlotsNextAvailableDayQuery } from '@/features/schedule/api/types/schedule.types'
import {
    AvailabilityBoundaries,
    Schedule,
    ScheduleProperties
} from '@/features/schedule/domain/interfaces/schedule.interface'
import { AvailableSlotsParameters, AvailableSlotsResponse } from '@/features/schedule/domain/interfaces/slot.interface'

class ScheduleService extends CalendarFilter<Schedule> {
    getAvailableSlots(scheduleId: number, params: AvailableSlotsParameters): Promise<AvailableSlotsResponse> {
        return repository.getAvailableSlots(scheduleId, params)
    }

    async getSchedules(ownSchedule?: boolean) {
        return ownSchedule ? repository.getOwnSchedule() : repository.getSchedules()
    }

    getScheduleById(scheduleId: number): Promise<Schedule> {
        return repository.getScheduleById(scheduleId)
    }

    updateSchedules(scheduleId: number, schedule: Schedule): Promise<void> {
        return repository.updateSchedules(scheduleId, schedule)
    }

    getSchedulesIncludingDeleted() {
        return repository.getAllSchedulesIncludingDeleted()
    }

    getAvailabilityBoundaries(scheduleId: number): Promise<AvailabilityBoundaries> {
        return repository.getAvailabilityBoundaries(scheduleId)
    }

    sendAvailabilityBoundaries(scheduleId: number, payload: AvailabilityBoundaries): Promise<any> {
        return repository.sendAvailabilityBoundaries(scheduleId, payload)
    }

    getScheduleSlotsNextAvailableDay(
        scheduleId: number,
        query?: Omit<SlotsNextAvailableDayQuery, 'startDate'> & {
            startDate: Date
        }
    ): Promise<AvailableSlotsResponse> {
        return repository.getScheduleSlotsNextAvailableDay(scheduleId, {
            ...query,
            startDate: query?.startDate ? formatDate(query.startDate, DateFormat.DateStringNoUtcOffset) : undefined
        })
    }

    filterSchedulesByProperties(staff: Schedule[], properties: ScheduleProperties): Schedule[] {
        const regExp = new RegExp(properties.name, 'i')
        const facilitySchedules = staff.filter(el => {
            return (
                (properties.facilityId ? el.facilityId === parseInt(properties.facilityId, 10) : true) &&
                (properties.name?.length ? el.name.match(regExp) : true)
            )
        })

        return this.orderListBySelectedAndName(facilitySchedules)
    }
}

export default new ScheduleService()
