import { FacilityRoomEntity } from '../../../domain/interfaces/rooms.interface'
import { RoomsState } from './state'

export enum RoomsMutationTypes {
    CreateRoom = 'MUTATION_CREATE_ROOM',
    DeleteRoom = 'MUTATION_DELETE_ROOM',
    SetIsLoading = 'MUTATION_SET_IS_LOADING',
    SetRooms = 'MUTATION_SET_ROOMS',
    UpdateRoom = 'MUTATION_UPDATE_ROOM'
}

export type RoomsMutations = {
    [RoomsMutationTypes.SetRooms](state: RoomsState, rooms: FacilityRoomEntity[]): void
    [RoomsMutationTypes.SetIsLoading](state: RoomsState, isLoading: boolean): void
    [RoomsMutationTypes.DeleteRoom](state: RoomsState, room: FacilityRoomEntity): void
    [RoomsMutationTypes.CreateRoom](state: RoomsState, room: FacilityRoomEntity): void
    [RoomsMutationTypes.UpdateRoom](state: RoomsState, room: FacilityRoomEntity): void
}
