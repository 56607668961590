import { setupGuide } from '@docplanner/one-tracking'

import { track } from '@/plugins/tracking/index'

import {
    createOnSetupGuideInvitationModalCTAClickedHandler,
    createOnSetupGuideInvitationModalRefused,
    createOnSetupGuideInvitationSystemShownHandler,
    offSetupGuideInvitationModalCTAClicked,
    offSetupGuideInvitationModalRefused,
    offSetupGuideInvitationModalShown,
    onSetupGuideInvitationModalCTAClicked,
    onSetupGuideInvitationModalRefused,
    onSetupGuideInvitationModalShown
} from '../events'

export const startTrackingModal = () => {
    const handlerCTAClicked = createOnSetupGuideInvitationModalCTAClickedHandler(() => {
        track(setupGuide.startFromModal)

        offSetupGuideInvitationModalCTAClicked(handlerCTAClicked)
    })

    const handlerRefused = createOnSetupGuideInvitationModalRefused(() => {
        track(setupGuide.refuseModal)

        offSetupGuideInvitationModalRefused(handlerRefused)
    })

    const handlerShown = createOnSetupGuideInvitationSystemShownHandler(() => {
        track(setupGuide.invitationModal)

        offSetupGuideInvitationModalShown(handlerShown)
    })

    onSetupGuideInvitationModalCTAClicked(handlerCTAClicked)

    onSetupGuideInvitationModalRefused(handlerRefused)

    onSetupGuideInvitationModalShown(handlerShown)
}
