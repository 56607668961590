import { reactive } from 'vue'

import { getTaskList, isTaskAvailable, setTaskCompleted } from '../../domain'
import { SetupGuideContext } from '../interfaces'

const setupGuideContext = reactive<SetupGuideContext>({
    taskActive: '',
    taskFocused: '',
    taskList: [],
    showTaskModal: false
})

export function useSetupGuide() {
    const fetchTaskList = async () => {
        const { data, error } = await getTaskList()

        if (error) {
            return
        }

        /**
         * Filter out tasks that are not available
         */

        setupGuideContext.taskList = data.filter(isTaskAvailable)
    }

    const setTaskAsCompleted = async (taskName: string) => {
        const task = setupGuideContext.taskList.find(t => t.name === taskName)!

        if (task.completed) {
            return { data: task, error: false, message: 'Task already completed' }
        }

        const { error, message } = await setTaskCompleted(task.id)

        if (error) {
            return { error, message }
        }

        task.completed = true

        return { data: task, error, message }
    }

    return { setupGuideContext, fetchTaskList, setTaskAsCompleted }
}
