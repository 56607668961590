import { DateFormat, formatDate, toDate } from '@/core/ui/utils'
import repository from '@/features/admin/api/admin.import.service.api'
import {
    AdminImportServiceValidationObject,
    ImportField,
    ImportFieldOption
} from '@/features/admin/domain/interfaces/admin.interfaces'

import {
    ImportServiceFieldsMap,
    ServiceImportData,
    ServiceImportDto,
    ServiceImportFormated,
    ServiceImportValidateFormData,
    ServiceValidationResults
} from './interfaces/admin.import.service.interfaces'

const getFieldMap = ({ id, name = '', displayText = '' }: ImportField): ImportFieldOption => ({
    field: id,
    name: name.charAt(0).toLowerCase() + name.slice(1),
    displayText
})

class ServicesAdminImportService {
    async getFields(): Promise<ImportServiceFieldsMap> {
        try {
            const [serviceFields, serviceScheduleFields] = await Promise.all([
                repository.getFieldService(),
                repository.getFieldServiceSchedule()
            ])

            return {
                services: serviceFields.map(getFieldMap),
                serviceSchedules: serviceScheduleFields.map(getFieldMap)
            }
        } catch {
            return {
                services: [],
                serviceSchedules: []
            }
        }
    }

    getImportFileHeaders(file: File): Promise<string[][]> {
        return repository.getImportFileHeaders(file)
    }

    validateImport(validateForm: ServiceImportValidateFormData): Promise<ServiceValidationResults[]> {
        const formData = new FormData()

        const { index: serviceKey } = validateForm.serviceMappings.idKey
        formData.append('services', validateForm.services)
        formData.append('serviceMappings', JSON.stringify(validateForm.serviceMappings))
        formData.append('serviceKey', serviceKey)

        if (validateForm.serviceScheduleMappings !== undefined && validateForm.serviceSchedules !== undefined) {
            const { index: serviceScheduleKey } = validateForm.serviceScheduleMappings.idKey
            formData.append('serviceSchedules', validateForm.serviceSchedules)
            formData.append('serviceScheduleMappings', JSON.stringify(validateForm.serviceScheduleMappings))
            formData.append('serviceScheduleKey', serviceScheduleKey)
        }

        return repository.validateImport(formData)
    }

    import(importData: ServiceImportData): Promise<number[]> {
        return repository.import(importData)
    }

    getDetailOf(uniqueId: string): Promise<ServiceImportDto> {
        return repository.getServiceImport(uniqueId)
    }

    async getImports(): Promise<ServiceImportFormated[]> {
        const data = await repository.getServiceImports()

        return data.map(({ id, uniqueId, createdAt, services }) => ({
            id,
            uniqueId,
            createdAt: formatDate(
                toDate(createdAt, DateFormat.DateStringNoUtcOffset),
                DateFormat.DayMonthYearHourMonthSecondFormat
            ),
            services: services.length
        }))
    }

    informationListHasAnyBlockingError(validations: AdminImportServiceValidationObject[]): boolean {
        const errors = validations.reduce(
            (keys: string[], { informationList, serviceSchedules = [] }: AdminImportServiceValidationObject) => [
                ...keys,
                ...informationList,
                ...serviceSchedules.reduce((acc: string[], item) => [...acc, ...item.informationList], [])
            ],
            []
        )

        // TODO: complete BE INFORMATION_LIST_KEY_BLOCKERS.some(keyBlocker => errors.includes(keyBlocker))
        return errors.length > 0
    }
}

export default new ServicesAdminImportService()
