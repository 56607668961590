const PreCheckinSettings = () =>
    import(/* webpackChunkName: "pre-checking-settings" */ '@/components/Settings/PreCheckin/PreCheckinSettings.vue')

export default [
    {
        path: 'settings-pre-checkin',
        name: 'settings-pre-checkin',
        component: PreCheckinSettings
    }
]
