import httpAPI from '@/api/utils/httpClientAPI'
import { PhoneNumberDTO } from '@/core/api/interfaces/phoneNumber.DTO'

const URL_BASE = 'phoneNumber'

class PhoneNumber {
    async validatePhone(phone: string): Promise<PhoneNumberDTO> {
        const { data } = await httpAPI.post(URL_BASE, { phone })
        return data
    }
}

export default new PhoneNumber()
