import repository from '../api/auth.api'
import { AuthTokenEntity, ProviderEntity } from './interfaces/auth.interfaces'
import { ProfileEntity } from './interfaces/profile.interfaces'

class AuthService {
    getProfile(): Promise<ProfileEntity> {
        return repository.getProfile()
    }

    getProvider(): Promise<ProviderEntity> {
        return repository.getProvider()
    }

    getWebToken(code: string, redirectUri: string): Promise<any> {
        return repository.getWebToken(code, redirectUri)
    }

    login(userId: string): Promise<AuthTokenEntity> {
        return repository.login(userId)
    }
}

export default new AuthService()
