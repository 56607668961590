export enum TemplateFields {
    Text,
    Checkbox,
    Radio,
    Number,
    SmartText
}

export enum TemplateFieldTypes {
    Checkbox = 'Numeric Field',
    Number = 'Radio Field',
    Radio = 'Checkbox Field',
    SmartText = 'Smart Field',
    Text = 'Text Field'
}
