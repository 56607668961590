import { Quote, QuotesFilters } from '@/features/quotes/domain/quotes.interfaces'

import { QuoteHeader } from '../../interfaces/quotesList.interfaces'

export enum StateTypes {
    CurrentPage = 'currentPage',
    Filters = 'filters',
    Headers = 'headers',
    IsLoading = 'isLoading',
    TotalPages = 'totalPages'
}
export interface QuotesState {
    [StateTypes.CurrentPage]: number
    [StateTypes.TotalPages]: number
    [StateTypes.IsLoading]: boolean
    [StateTypes.Filters]: QuotesFilters
    [StateTypes.Headers]: QuoteHeader[]
    quotes: Quote[]
}
