import Module from '@/bootstrap/Module'
import modules from '@/bootstrap/modules.definitions'

export default class ModuleLoader {
    modules

    constructor() {
        this.prepareModules()
    }

    prepareModules() {
        this.modules = modules.map(module => new Module(module.name, module.package, module.inject))
    }

    async loadModules() {
        const modulesPromise = []

        this.modules.forEach(module => modulesPromise.push(module.loadModule()))

        const loadedModules = await Promise.all(modulesPromise)

        return loadedModules
    }

    getModule(name) {
        let requestedModule

        this.modules.forEach(module => {
            if (module.getName() === name) {
                requestedModule = module
            }
        })

        if (requestedModule === undefined) {
            throw new Error(`Module ${name} does not exist`)
        }

        return requestedModule
    }

    getInjectableModules() {
        return this.modules
            .filter(module => module.isInjectable())
            .reduce((list, module) => {
                return { ...list, [module.getName()]: module.getModule() }
            }, {})
    }
}
