import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
class TranslationFormatter {
    interpolate(message, values) {
        if (!values) {
            return [message]
        }

        Object.keys(values).forEach(key => {
            message = message.replaceAll(new RegExp(`{{1,2}(?:\\s+)?(${key})(?:\\s+)?}{1,2}`, 'g'), values[key])
        })

        return [message]
    }
}

const specialPlural = (locale, choice, choicesLength) => {
    if (choice === 0) {
        return 0
    }
    const pluralRules = new Intl.PluralRules(locale)
    const type = pluralRules.select(choice)

    if (type === 'one') return 1
    if (type === 'few') return 2

    return choicesLength < 4 ? 2 : 3
}

export const plPlural = (choice, choicesLength) => specialPlural('pl-PL', choice, choicesLength)
export const czPlural = (choice, choicesLength) => specialPlural('cs-CZ', choice, choicesLength)

const trackedKeys = {}

const i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: {},
    formatter: new TranslationFormatter(),
    pluralizationRules: {
        'pl-PL': plPlural,
        'cs-CZ': czPlural
    },
    postTranslation: (str, key) => {
        const isTrackingEnabled = Vue.prototype.$featureToggle.ensure('TrackTranslationsInSaaS', true)
        if (isTrackingEnabled && !trackedKeys[key]) {
            Vue.prototype.$tracking.trackWithProperties(
                {
                    category: 'ONE-TRANSLATIONS',
                    action: 'keys-in-use',
                    tools: {
                        mp: true
                    }
                },
                {
                    properties: {
                        translated_key: key
                    }
                }
            )
            trackedKeys[key] = true
        }
        return str
    }
})

export default i18n
