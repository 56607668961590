import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import store from '@/store'

export const getCountryCode = (): string => {
    return store.getters[AuthGetterTypes.GetCountryCode]
}

export const getHasBlockDistractors = (): boolean => {
    return store.getters[AuthGetterTypes.HasBlockDistractors]
}

export const getIsImpersonator = () => {
    return store.getters[AuthGetterTypes.IsImpersonator]
}

export const getDoctorName = (): string => {
    return store.getters[AuthGetterTypes.GetDoctorName]
}
