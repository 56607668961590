export const ROUTE_CALENDAR_DAY = 'calendarDay'
export const ROUTE_CALENDAR_WEEK = 'calendarWeek'
export const ROUTE_CALENDAR_AGENDA = 'calendarAgenda'
export const ROUTE_CALENDAR_FREE_SLOTS = 'calendarFreeTerms'
export const ROUTE_CALENDAR_SECRETARY_DAILY_LIST = 'calendarDailyList'

export const COOKIE_CALENDAR_VIEW_DAY = 'agendaDay'
export const COOKIE_CALENDAR_VIEW_WEEK = 'agendaWeek'
export const COOKIE_CALENDAR_VIEW_FREE_TERMS = 'freeTerms'
export const COOKIE_CALENDAR_VIEW_AGENDA = 'listWeek'

export const ROUTE_CALENDAR_BASE_NAME = 'calendar'
export const ROUTE_CALENDAR_BASE = '/calendar'
export const ROUTE_CALENDAR_BASE_CLINIC = '/calendar-clinic'
export const ROUTE_CALENDAR_PATH_DAY = 'day'
export const ROUTE_CALENDAR_PATH_WEEK = 'week'
export const ROUTE_CALENDAR_PATH_AGENDA = 'agenda'
export const ROUTE_CALENDAR_PATH_SLOTS = 'slots'
export const ROUTE_CALENDAR_PATH_SECRETARY_DAILY_LIST = 'daily-list'

export const ROUTE_CALENDAR_PATH_CALENDAR_DAY = '/day/:day'
export const ROUTE_CALENDAR_PATH_CALENDAR_DAY_EVENT = '/day/:day/event/:event'

export const ROUTE_PATIENT_PROFILE = 'patient-tab-edit'

export const HIDE_CALENDAR_SEARCH_ROUTES = [ROUTE_CALENDAR_SECRETARY_DAILY_LIST]

export const ROUTE_CALENDAR_CREATE_WIZARD_PATH = 'create-calendar'
export const ROUTE_CALENDAR_CREATE = 'create-calendar'

export const CALENDAR_VIEW_NAMES_MAP = {
    [ROUTE_CALENDAR_DAY]: COOKIE_CALENDAR_VIEW_DAY,
    [ROUTE_CALENDAR_WEEK]: COOKIE_CALENDAR_VIEW_WEEK,
    [ROUTE_CALENDAR_FREE_SLOTS]: COOKIE_CALENDAR_VIEW_FREE_TERMS,
    [ROUTE_CALENDAR_AGENDA]: COOKIE_CALENDAR_VIEW_AGENDA,
    [ROUTE_CALENDAR_SECRETARY_DAILY_LIST]: ROUTE_CALENDAR_SECRETARY_DAILY_LIST
}
