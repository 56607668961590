// API / user settings keys
export const BLOCK_OVERBOOK_BETWEEN_SCHEDULES_KEY = 'blockOverbookBetweenSchedules'
export const QUICK_MODAL_BOOKING_KEY = 'quickModalBooking'
export const SHOW_24_HOURS_KEY = 'show24hours'
export const CALENDAR_DENSITY_KEY = 'calendarDensity'
export const CALENDAR_ORDER_KEY = 'calendarOrder'
export const CALENDAR_CELL_SIZE_KEY = 'timeScheduleGrid'
export const DISPLAY_CANCELLED_EVENTS_BY_PATIENT_KEY = 'displayCancelledEventsByPatient'
export const DISPLAY_CANCELLED_EVENTS_BY_DOCTOR_KEY = 'displayCancelledEventsByDoctor'
export const WEEKDAYS_DISPLAY_KEY = 'weekDaysDisplay'
export const CALENDAR_COLOR_THEME_KEY = 'calendarColorsType'
export const CALENDAR_COLOR_BY_KEY = 'calendarColorsByType'
export const SHOW_RESOURCE_WEEK_KEY = 'showResourceWeek'
export const HIDE_BOOKED_SLOTS_KEY = 'hideBookedSlots'
export const STORE_CALENDAR_FILTERS_KEY = 'storeCalendarFilters'

export const NAV_TYPE_CALENDAR = 'calendar'
export const NAV_TYPE_VISIT_TYPE = 'visit_block'

// Events
export const GENERAL_SETTINGS = 'generalSettings'
export const COLOR_SETTINGS = 'colorSettings'
export const COLOR_BY_SETTINGS = 'colorBySettings'
export const DENSITY_SETTINGS = 'densitySettings'

export const EVENTS_OVERLAPPING_SETTINGS = 'eventsOverlappingSettings'
export const WEEKDAYS_DISPLAY_SETTINGS = 'weekDaysDisplaySettings'

export const COPY_CUT_COUNTER = 'copyCutCounter'
export const COPY_CUT_COUNTER_CONTEXTUAL_MENU = 'copyCutCounterContextualMenu'

export const APPOIMTMENT_PATIENT_OWNERSHIP = 'AppointmentPatientOwnership'

export const WEEKDAYS = [
    {
        name: 'lunes',
        isShown: true,
        inputName: 'Monday'
    },
    {
        name: 'martes',
        isShown: true,
        inputName: 'Tuesday'
    },
    {
        name: 'miercoles',
        isShown: true,
        inputName: 'Wednesday'
    },
    {
        name: 'jueves',
        isShown: true,
        inputName: 'Thursday'
    },
    {
        name: 'viernes',
        isShown: true,
        inputName: 'Friday'
    },
    {
        name: 'sabado',
        isShown: true,
        inputName: 'Saturday'
    },
    {
        name: 'domingo',
        isShown: true,
        inputName: 'Sunday'
    }
]
