import Vue from 'vue'

import { HintsMutations, MutationTypes } from './types'

export const mutations: HintsMutations = {
    [MutationTypes.SetHint](state, { hint, data }) {
        Vue.set(state, hint, data)
    },
    [MutationTypes.ResetHints](state) {
        state = {}
    }
}
