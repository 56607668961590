import { PAGES as CHAT_PAGES } from '@/features/chat/ui/constants/chat.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'
import {
    PATIENT_EDIT_TAB,
    PATIENT_FILE_GENERATOR_TAB,
    PATIENT_FILES_TAB,
    PATIENT_FINANCIAL_TAB,
    PATIENT_HISTORY_TAB,
    PATIENT_MESSAGES_TAB_DEPRECATED,
    PATIENT_PLUGIN_TAB,
    PATIENT_PROFILE,
    PATIENT_RECORD_TAB,
    PATIENTS_LIST
} from '@/features/patient/ui/constants/patient-tabs.constants'
import patientImport from '@/features/patientImport/ui/router/patientImport.router'

const PatientIndex = () =>
    import(/* webpackChunkName: "patients-index" */ '@/features/patient/ui/views/PatientIndex.vue')
const PatientsList = () =>
    import(/* webpackChunkName: "patients-list" */ '@/features/patient/ui/views/PatientsList.vue')
const PatientHistory = () =>
    import(/* webpackChunkName: "patient-history" */ '@/features/patient/ui/views/PatientHistory.vue')
const PatientProfile = () =>
    import(/* webpackChunkName: "patient-profile" */ '@/features/patient/ui/views/PatientProfile.vue')
const PatientEdit = () => import(/* webpackChunkName: "patient-edit" */ '@/features/patient/ui/views/PatientEdit.vue')
const PatientTabRecord = () =>
    import(/* webpackChunkName: "patient-tab-record" */ '@/features/patient/ui/views/PatientTabRecord.vue')
const PatientFiles = () =>
    import(/* webpackChunkName: "patient-tab-files" */ '@/features/patient/ui/views/PatientFiles.vue')
const PatientFileGenerator = () =>
    import(/* webpackChunkName: "patient-tab-files-generator" */ '@/features/patient/ui/views/PatientFileGenerator.vue')
const PatientTabFinancial = () =>
    import(/* webpackChunkName: "patient-tab-financial" */ '@/features/patient/ui/views/PatientTabFinancial.vue')

const PatientTabPlugin = () =>
    import(/* webpackChunkName: "patient-tab-plugin" */ '@/features/patient/ui/views/PatientTabPlugin.vue')

export default {
    name: 'patients', // Ignore warning https://docplanner.atlassian.net/browse/DOC-45111
    path: 'patients',
    component: PatientIndex,
    children: [
        {
            path: 'list',
            name: PATIENTS_LIST,
            component: PatientsList,
            meta: {
                title: 'patients',
                middleware: uninav,
                uninavName: 'patients'
            }
        },
        patientImport,
        {
            path: ':patientId',
            name: PATIENT_PROFILE,
            component: PatientProfile,
            meta: {
                title: 'patients',
                middleware: uninav,
                uninavName: 'patients'
            },
            children: [
                {
                    path: 'edit',
                    name: PATIENT_EDIT_TAB,
                    component: PatientEdit
                },
                {
                    path: 'history',
                    name: PATIENT_HISTORY_TAB,
                    component: PatientHistory
                },
                {
                    path: 'record',
                    name: PATIENT_RECORD_TAB,
                    component: PatientTabRecord
                },
                {
                    path: 'files',
                    name: PATIENT_FILES_TAB,
                    component: PatientFiles
                },
                {
                    path: 'messages',
                    name: PATIENT_MESSAGES_TAB_DEPRECATED,
                    redirect: to => ({
                        name: CHAT_PAGES.PATIENTS_CONVERSATION_LIST,
                        params: { patientId: to.params.patientId }
                    })
                },
                {
                    path: 'financial',
                    name: PATIENT_FINANCIAL_TAB,
                    component: PatientTabFinancial
                },
                {
                    path: 'plugin',
                    name: PATIENT_PLUGIN_TAB,
                    component: PatientTabPlugin
                }
            ]
        },
        {
            path: ':patientId/files/generate',
            name: PATIENT_FILE_GENERATOR_TAB,
            component: PatientFileGenerator
        },
        {
            path: '',
            redirect: {
                name: PATIENTS_LIST
            }
        }
    ]
}
