import { QuotesFilters } from '@/features/quotes/domain/quotes.interfaces'

export enum ActionTypes {
    DeleteQuote = 'deleteQuote',
    FetchQuotes = 'fetchQuotes',
    UpdateApprovalStatus = 'updateApprovalStatus'
}

export type QuotesActions = {
    [ActionTypes.FetchQuotes](payload: { filters: QuotesFilters; page: number }): Promise<void>
    [ActionTypes.UpdateApprovalStatus](payload: { quoteId: number; status: number }): Promise<void>
    [ActionTypes.DeleteQuote](quoteId: number): Promise<void>
}
