import { PaymentDTO } from '../../api/interfaces'
import { PaymentsData } from '../interfaces'

export const mapPaymentsFromDTO = (paymentDTO: PaymentDTO): PaymentsData => {
    const { page, ...pagination } = paymentDTO
    return {
        page,
        pagination
    }
}
