import { NavigationGuardNext, Route } from 'vue-router'

import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import { PRICING_TERMS_PATH_NAME } from '@/features/pricingTerms/ui/router/pricingTerms.router'
import { VIDEO_CONSULTATION as VIDEO_CONSULTATION_PATH } from '@/features/videoConsultation/ui/router/videoConsultation.router'
import storage from '@/store/storage'
import { MOBILE_REDIRECT } from '@/store/storage-types'
import { deviceIsMobile } from '@/utils/device'

type MiddlewareParams = {
    next: NavigationGuardNext
    to: Route
}

export const routesToExclude = [
    'migration',
    'doctor-invitation',
    'onboarding',
    'completemigration',
    `${ROUTE_SELECT_USER}`,
    `${VIDEO_CONSULTATION_PATH}`,
    PRICING_TERMS_PATH_NAME
]
export default function mobile({ next, to }: MiddlewareParams) {
    if (deviceIsMobile() && storage.get(MOBILE_REDIRECT) !== false && !routesToExclude.includes(to.name as string)) {
        return next({
            name: 'mobile'
        })
    }
    next()
}
