import { CampaignsTypeName } from '@/features/campaigns/domain/enums/campaigns.enums'

import {
    GETTER_CAMPAIGNS_RECIPIENTS_FILTERS,
    GETTER_CAMPAIGNS_RECIPIENTS_IS_TYPE_SMS,
    GETTER_CAMPAIGNS_RECIPIENTS_ROWS_TOTAL,
    GETTER_CAMPAIGNS_TRACK_TYPE
} from './types/getters'

export default {
    [GETTER_CAMPAIGNS_RECIPIENTS_FILTERS]: state => state.recipients.filters,
    [GETTER_CAMPAIGNS_RECIPIENTS_ROWS_TOTAL]: state => state.recipients.rowsIds.length,
    [GETTER_CAMPAIGNS_RECIPIENTS_IS_TYPE_SMS]: state => state.campaignTypeName === CampaignsTypeName.Sms,
    [GETTER_CAMPAIGNS_TRACK_TYPE]: (state, getters) =>
        getters[GETTER_CAMPAIGNS_RECIPIENTS_IS_TYPE_SMS] ? 'sms' : 'email'
}
