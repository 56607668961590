import dayjs, { Dayjs, OpUnitType } from 'dayjs'

import { DateFormat, DateRange, DateUnit, formatDate, parseDate, toDate } from '.'

const DATES_SPLIT_CHAR = '_'

export const serializeDateRanges = (date: { from: string | Date | Dayjs; to: string | Date | Dayjs }): string => {
    return [formatDate(date.from, DateFormat.DateDashedFormat), formatDate(date.to, DateFormat.DateDashedFormat)].join(
        DATES_SPLIT_CHAR
    )
}

export const deserializeDateRanges = (value: string) => {
    if (!value) {
        return {}
    }
    return value
        .split(DATES_SPLIT_CHAR)
        .splice(0, 2)
        .map(dateString => {
            const date = parseDate(dateString, DateFormat.DateDashedFormat)

            return date ? toDate(date) : null
        })
        .reduce((object, date, index) => {
            return {
                ...object,
                ...(index === 0 && date ? { from: date } : null),
                ...(index === 1 && date ? { to: date } : null)
            }
        }, {})
}

export const deserializeInteger = (value: string) => parseInt(value, 10)

export const getMillisToHours = (time: string | number | object) => dayjs.duration(time).asHours()
export const getMillisToDays = (time: string | number | object) => dayjs.duration(time).asDays()

export const getHourDifferenceFromNow = (startTime: string | Date | Dayjs) =>
    Number(dayjs.duration(dayjs().diff(startTime)).asHours().toFixed(2))

export const getLast12Months = () => {
    const from = dayjs().subtract(12, 'month').startOf('month')
    const to = dayjs()

    return {
        from: from.toDate(),
        to: to.toDate(),
        compare: {
            from: from.subtract(12, 'month').toDate(),
            to: to.subtract(12, 'month').toDate()
        }
    }
}

export const getCurrentYear = () => {
    const from = dayjs().startOf('year')
    const to = dayjs()

    const compareDate = to.subtract(1, 'year')

    return {
        from: from.toDate(),
        to: to.toDate(),
        compare: {
            from: compareDate.startOf('year').toDate(),
            to: compareDate.toDate()
        }
    }
}

export const getCurrentWeek = (period: DateUnit = DateUnit.Week, date = new Date()) => {
    const to = dayjs(date)
    const from = to.startOf(DateUnit.Week)

    let compareDate = to.subtract(1, period as OpUnitType)

    if (period === DateUnit.Year) {
        compareDate = compareDate.week(to.week())
    }

    const days = to.diff(from, DateUnit.Day)

    return {
        from: from.toDate(),
        to: to.toDate(),
        compare: {
            from: compareDate.startOf('week').toDate(),
            to: compareDate.startOf('week').add(days, DateUnit.Day).toDate()
        }
    }
}

export const getCurrentMonth = (period: DateUnit = DateUnit.Month, date = new Date()) => {
    const dayjsDate = dayjs(date)

    return {
        from: dayjsDate.startOf('month').toDate(),
        to: dayjsDate.toDate(),
        compare: {
            from: dayjsDate
                .subtract(1, period as OpUnitType)
                .startOf(DateUnit.Month)
                .toDate(),
            to: dayjsDate.subtract(1, period as OpUnitType).toDate()
        }
    }
}

export const getLastWeek = (period: DateUnit = DateUnit.Week, date = new Date()) => {
    const newDate = dayjs(date).subtract(1, DateUnit.Week)
    let compareDate = newDate.subtract(1, period as OpUnitType)

    if (period === DateUnit.Year) {
        compareDate = compareDate.week(newDate.week())
    }

    return {
        from: newDate.startOf(DateUnit.Week).toDate(),
        to: newDate.endOf(DateUnit.Week).toDate(),
        compare: {
            from: compareDate.startOf(DateUnit.Week).toDate(),
            to: compareDate.endOf(DateUnit.Week).toDate()
        }
    }
}

export const getLastMonth = (period: DateUnit = DateUnit.Month, date = new Date()) => {
    const dayjsDate = dayjs(date)

    return {
        from: dayjsDate.subtract(1, DateUnit.Month).startOf(DateUnit.Month).toDate(),
        to: dayjsDate.subtract(1, DateUnit.Month).endOf(DateUnit.Month).toDate(),
        compare: {
            from: dayjsDate
                .subtract(1, period as OpUnitType)
                .subtract(1, DateUnit.Month)
                .startOf(DateUnit.Month)
                .toDate(),
            to: dayjsDate
                .subtract(1, period as OpUnitType)
                .subtract(1, DateUnit.Month)
                .endOf(DateUnit.Month)
                .toDate()
        }
    }
}

export const getLastYearPeriodRange = (
    from: string | Date | Dayjs = new Date(),
    to: string | Date | Dayjs = new Date()
) => ({
    from: dayjs(from).subtract(1, DateUnit.Year).toDate(),
    to: dayjs(to).subtract(1, DateUnit.Year).toDate()
})

export const getPreviousPeriodRange = (
    from: string | Date | Dayjs = new Date(),
    to: string | Date | Dayjs = new Date()
) => {
    const numOfDays = dayjs(to).diff(dayjs(from), DateUnit.Day) + 1

    return {
        from: dayjs(from).subtract(numOfDays, DateUnit.Day).toDate(),
        to: dayjs(to).subtract(numOfDays, DateUnit.Day).toDate()
    }
}

export const dateIsBetweenDateRange = (
    date: string | Date | Dayjs,
    { from, to }: { from: string | Date | Dayjs; to: string | Date | Dayjs },
    granularity: DateUnit | null = null,
    inclusitivy = ''
) => dayjs(date).isBetween(from, to, granularity as OpUnitType, inclusitivy)

export const areDateRangesColliding = (
    { start: startA, end: endA }: DateRange,
    { start: startB, end: endB }: DateRange
): boolean => startA.getTime() <= endB.getTime() && endA.getTime() >= startB.getTime()

const getWeeksOfYear = (year: number) => {
    const totalNumberOfWeeks = dayjs(`${year}-01-01`).isoWeeksInYear()
    const arrayOfWeekWithFirstDay = []

    const isWeekLastOneOfPreviousYear = (weekNumber: number, monthNumber: number, dayNumber: number) =>
        weekNumber === 1 && monthNumber === 11 && dayNumber < 29

    const isWeekFirstOneOfNextYear = (weekNumber: number, dayNumber: number) =>
        weekNumber === totalNumberOfWeeks && [29, 30, 31].includes(dayNumber)

    let weekNumber = 1
    for (let i = 0; i <= totalNumberOfWeeks; i += 1) {
        const firstDayOfWeek = dayjs().year(year).month(1).day(1).isoWeek(i).day(1)
        const dayNumber = parseInt(firstDayOfWeek.format('D'), 10)
        const monthNumber = firstDayOfWeek.month()

        if (
            !(weekNumber > totalNumberOfWeeks || isWeekFirstOneOfNextYear(weekNumber, dayNumber)) &&
            !isWeekLastOneOfPreviousYear(weekNumber, monthNumber, dayNumber)
        ) {
            arrayOfWeekWithFirstDay.push({
                weekNumber,
                monthNumber
            })
            weekNumber += 1
        }
    }
    return arrayOfWeekWithFirstDay
}

export const getWeekNumbersGroupedByMonth = (year: number) => {
    const result: number[][] = new Array(12).fill(null).map(_ => [])
    const isFirstWeekOfYearInDecember = (index: number, monthNumber: number) => index === 0 && monthNumber === 11

    getWeeksOfYear(year).forEach(({ monthNumber, weekNumber }, index) => {
        const monthIndex = isFirstWeekOfYearInDecember(index, monthNumber) ? 0 : monthNumber
        result[monthIndex].push(weekNumber)
    })

    return result
}

export const getTotalWeeksFromGroupedByMonth = (weeksForYear: number[][]) => {
    const weeks = weeksForYear.reduce((acc, weekNumbers) => {
        return [...acc, ...weekNumbers]
    }, [])
    return weeks.length
}

export const filterDatesInsideRange = (range: DateRange) => {
    return (date: DateRange) => date.end.getTime() > range.start.getTime() || date.start.getTime() < range.end.getTime()
}

export function formatDateRange(
    dateRange: { from: string | Date | Dayjs; to: string | Date | Dayjs },
    dayMonthYearFormat: DateFormat = DateFormat.DayMonthAbbrYearFormat,
    dayMonthFormat: DateFormat = DateFormat.DayMonthAbbrFormat
): string {
    const from = dayjs(dateRange.from || new Date())
    const to = dayjs(dateRange.to || new Date())

    if (from.isSame(to, DateUnit.Day) && from.isSame(to, DateUnit.Month) && from.isSame(to, DateUnit.Year)) {
        return to.format(dayMonthYearFormat).toString()
    }

    if (from.isSame(to, DateUnit.Month) && from.isSame(to, DateUnit.Year)) {
        return `${from.format('DD')} - ${to.format(dayMonthYearFormat)}`
    }

    if (from.isSame(to, DateUnit.Year)) {
        return `${from.format(dayMonthFormat)} - ${to.format(dayMonthYearFormat)}`
    }

    return `${from.format(dayMonthYearFormat)} - ${to.format(dayMonthYearFormat)}`
}
