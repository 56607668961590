export const MUTATION_UPDATE_PATIENT_DATA = 'MUTATION_UPDATE_PATIENT_DATA'

export const MUTATION_FETCH_PATIENT_DATA = 'MUTATION_FETCH_PATIENT_DATA'
export const MUTATION_SET_PATIENTS_PAGE_DATA = 'MUTATION_SET_PATIENTS_PAGE_DATA'
export const MUTATION_SET_SELECTED_PATIENT_ID = 'MUTATION_SET_SELECTED_PATIENT_ID'
export const MUTATION_UNSET_SELECTED_PATIENT_ID = 'MUTATION_UNSET_SELECTED_PATIENT_ID'
export const MUTATION_SET_PAGE_ALL_PATIENT_IDS = 'MUTATION_SET_PAGE_ALL_PATIENT_IDS'
export const MUTATION_SET_ALL_PATIENTS_SELECTED = 'MUTATION_SET_ALL_PATIENTS_SELECTED'
export const MUTATION_RESET_SELECTED_PATIENT_IDS = 'MUTATION_RESET_SELECTED_PATIENT_IDS'
export const MUTATION_RESET_PATIENT_DATA = 'MUTATION_RESET_PATIENT_DATA'

export const MUTATION_FETCH_EVENT_DATA = 'MUTATION_FETCH_EVENT_DATA'
export const MUTATION_FETCH_FUTURE_EVENT_DATA = 'MUTATION_FETCH_FUTURE_EVENT_DATA'
export const MUTATION_FETCH_NEXT_EVENT_DATA = 'MUTATION_FETCH_NEXT_EVENT_DATA'
export const MUTATION_FETCH_CHECKIN_INFO = 'MUTATION_FETCH_CHECKIN_INFO'
export const MUTATION_FETCH_EVENT_DATA_CLEAR = 'MUTATION_FETCH_EVENT_DATA_CLEAR'
export const MUTATION_NOMORE_EVENT_DATA = 'MUTATION_NOMORE_EVENT_DATA'
export const MUTATION_FETCH_FINANCIAL_SUMMARY = 'MUTATION_FETCH_FINANCIAL_SUMMARY'
export const MUTATION_FETCH_PATIENT_COUNTERS = 'MUTATION_FETCH_PATIENT_COUNTERS'
export const MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE = 'MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE'
export const MUTATION_SET_TABLE_EDIT_STATE = 'MUTATION_SET_TABLE_EDIT_STATE'
export const MUTATION_SET_PATIENT_REVIEW_STATUS = 'MUTATION_SET_PATIENT_REVIEW_STATUS'
export const MUTATION_SET_SELECTED_NEW_OWNER = 'MUTATION_SET_SELECTED_NEW_OWNER'
export const MUTATION_FETCH_INSURANCES = 'MUTATION_FETCH_INSURANCES'
export const MUTATION_FETCH_APPOINTMENT_PROPOSALS = 'MUTATION_FETCH_APPOINTMENT_PROPOSALS'
