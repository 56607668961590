import { CountryCodes } from '@/core/domain/enums/country.code.enum'

export const getPaymentRequestMaxPriceByCountry = (countryCode: CountryCodes) => {
    switch (countryCode) {
        case CountryCodes.ES:
        case CountryCodes.IT:
        case CountryCodes.DE:
            return 1000 /* EUR */
        case CountryCodes.PL:
            return 5000 /* PLN */
        case CountryCodes.MX:
            return 20000 /* MXN */
        case CountryCodes.BR:
            return 6000 /* BRL */
        default:
            return 1000
    }
}
