import { groupBy } from '@dp-vue/utils'
import { AxiosResponse } from 'axios'

import { createDateFromTime, date, DateFormat } from '@/core/ui/utils'
import { CALENDAR_KIND } from '@/features/calendar/ui/constants/calendar.constants'
import repository from '@/features/settings/api/schedule.api'
import { NotificationSettings } from '@/features/settings/domain/enums/scheduleNotificationSettings.enums'
import {
    WorkPeriodsTemplatesEntity,
    WorkPeriodTemplateEntity
} from '@/features/settings/domain/interfaces/schedule.entity'
import {
    InsurancesPayload,
    NotificationSetting,
    OnlineConsultationSettingsChannelPayload,
    PeriodicityPayload,
    Schedule,
    ScheduleExceptions,
    ScheduleId,
    ScheduleInsurance,
    ScheduleNotificationEntity,
    ScheduleService,
    SchedulesMachedByService,
    ScheduleWorkPeriod,
    ScheduleWorkPeriods,
    ServicesPayload,
    SingleScheduleWorkPeriodTemplatePayload
} from '@/features/settings/domain/interfaces/schedule.interfaces'
import {
    ADMITTED_PATIENTS_ALL,
    DAYS_NUMBER_OF_WEEK,
    DEFAULT_END_HOUR,
    DEFAULT_START_HOUR,
    LATEST_END_HOUR,
    PERIODICITY_REPEAT_EVERY_WEEK,
    SERVICES_RADIO_ALL,
    SERVICES_RADIO_SOME
} from '@/features/settings/ui/constants'

import { VisibilityType } from './enums'
import { SchedulePatientsType } from './enums/scheduleInsurances.enums'

class SchedulesService {
    deleteSchedule(scheduleId: number): Promise<any> {
        return repository.deleteSchedule(scheduleId)
    }

    getSchedules(): Promise<AxiosResponse<Schedule[]>> {
        return repository.getSchedules()
    }

    getSchedule(scheduleId: number): Promise<Schedule> {
        return repository.getSchedule(scheduleId)
    }

    getScheduleByAddressId(addressId: string): Promise<Schedule> {
        return repository.getScheduleByAddressId(addressId)
    }

    getScheduleExceptions(scheduleId: number): Promise<ScheduleExceptions[]> {
        return repository.getScheduleExceptions(scheduleId)
    }

    getScheduleServices(scheduleId: number): Promise<ScheduleService[]> {
        return repository.getScheduleServices(scheduleId)
    }

    getWorkPeriods(scheduleId: number): Promise<ScheduleWorkPeriods[]> {
        return repository.getWorkPeriods(scheduleId)
    }

    getRandomSchemeColor(min = 2, max = 20): number {
        return Math.floor(Math.random() * (max - min) + min)
    }

    getWorkPeriodColor(value: number | null, defaultColor: number): number {
        if (!value) {
            return defaultColor
        }
        return value
    }

    getChannels(scheduleId: string): Promise<OnlineConsultationSettingsChannelPayload[]> {
        return repository.getChannels(scheduleId)
    }

    updateChannels(scheduleId: string, channels: any): Promise<void> {
        return repository.updateChannels(scheduleId, channels)
    }

    isDefaultTemplateNull(template: WorkPeriodTemplateEntity) {
        return !!template?.name
    }

    async getWorkPeriodsTemplates(scheduleId: number): Promise<WorkPeriodsTemplatesEntity> {
        const data = await repository.getWorkPeriodsTemplates(scheduleId)

        return {
            defaultTemplate: {
                ...data.defaultTemplate,
                templateWorkperiods: data.defaultTemplate?.templateWorkperiods ?? [],
                isDefault: true
            },
            emptyTemplate: {
                ...data.emptyTemplate,
                yearWeeks: data.emptyTemplate?.yearWeeks || [],
                translationKey: 'calendar-settings-no-office-hours',
                color: null
            },
            weekTemplates: data.weekTemplates?.length
                ? data.weekTemplates
                      .map(template => ({
                          ...template,
                          yearWeeks: template?.yearWeeks || [],
                          name: template?.name || '',
                          color: this.getWorkPeriodColor(template?.color, this.getRandomSchemeColor())
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))
                : []
        }
    }

    removeException(scheduleId: number, inputDate: string): Promise<any> {
        return repository.removeException(scheduleId, inputDate)
    }

    createException(payload: Record<string, any>): Promise<any> {
        return repository.createException(payload)
    }

    updateException(payload: Record<string, any>): Promise<any> {
        return repository.updateException(payload)
    }

    updateSchedule(scheduleId: number, data: Record<string, any>): Promise<any> {
        return repository.updateSchedule(scheduleId, data)
    }

    updateScheduleColor(scheduleId: number, colorSchemaId: number): Promise<void> {
        return repository.updateScheduleColor(scheduleId, colorSchemaId)
    }

    updateWorkPeriods(scheduleId: number, data: Record<string, any>[]): Promise<any> {
        return repository.updateWorkPeriods(scheduleId, data)
    }

    updateWorkPeriodTemplates(scheduleId: number, data: Record<string, any>[]): Promise<any> {
        return repository.updateWorkPeriodTemplates(scheduleId, data)
    }

    createWorkPeriodTemplate(scheduleId: number, data: SingleScheduleWorkPeriodTemplatePayload): Promise<void> {
        return repository.createWorkPeriodTemplate(scheduleId, data)
    }

    updateWorkPeriodTemplate(scheduleId: number, data: SingleScheduleWorkPeriodTemplatePayload): Promise<void> {
        return repository.updateWorkPeriodTemplate(scheduleId, data)
    }

    deleteWorkPeriodTemplate(scheduleId: number, templateId: string): Promise<void> {
        return repository.deleteWorkPeriodTemplate(scheduleId, templateId)
    }

    getDoctorKindSchedules(schedules: Schedule[]) {
        return schedules.filter(({ kind }) => kind === CALENDAR_KIND.doctor)
    }

    prepareSchedulesData(schedules: Schedule[]): Schedule[] {
        return schedules.map(({ displayName, name, ...rest }) => ({
            displayName: displayName || name,
            name,
            ...rest
        }))
    }

    async prepareServicesData(
        scheduleId: number,
        patientCanBookServiceEnabled: boolean
    ): Promise<Partial<ScheduleService>[]> {
        const data = await this.getScheduleServices(scheduleId)

        const servicesCollection = patientCanBookServiceEnabled
            ? data
            : data.filter(({ isCustom, showOnline }) => !isCustom && !!showOnline)

        return servicesCollection.map(
            ({
                id,
                isDefault,
                serviceName,
                duration,
                serviceScheduleInsurances,
                patientCanBook,
                showOnline,
                price,
                serviceId
            }) => {
                return {
                    duration,
                    id,
                    isDefault,
                    name: serviceName,
                    serviceScheduleInsurances,
                    patientCanBook,
                    showOnline,
                    price,
                    serviceId
                }
            }
        )
    }

    getDefaultWorkPeriod(index: number, dayName: string, schedulePatientInsuranceAccepted: SchedulePatientsType) {
        return {
            active: false,
            dayOfWeek: index,
            name: dayName,
            workPeriodItems: [
                {
                    admitedPatients: ADMITTED_PATIENTS_ALL,
                    end: DEFAULT_END_HOUR,
                    insurances: [],
                    originalEnd: DEFAULT_END_HOUR,
                    originalStart: DEFAULT_START_HOUR,
                    patientInsuranceAccepted: schedulePatientInsuranceAccepted,
                    periodicity: PERIODICITY_REPEAT_EVERY_WEEK,
                    nextDay: null,
                    serviceScheduleInsurances: [],
                    services: [],
                    start: DEFAULT_START_HOUR,
                    isPrivate: VisibilityType.Public
                }
            ]
        }
    }

    /**
     * For version 2 of work period templates
     * @param weekScheme
     * @returns
     */
    prepareWeekSchemeWorkPeriodsData(weekScheme: WorkPeriodTemplateEntity): any[] {
        return new Array(DAYS_NUMBER_OF_WEEK).fill(0).map((_, i) => {
            const index: number = i === 6 ? 0 : i + 1
            const dayName = date().day(index).format(DateFormat.DayNameFormat)
            const weekPeriodsOfTheDay = weekScheme.templateWorkperiods.filter(({ dayOfWeek }) => dayOfWeek === index)

            if (weekPeriodsOfTheDay.length) {
                return {
                    dayOfWeek: index,
                    active: true,
                    name: dayName,
                    workPeriodItems:
                        weekPeriodsOfTheDay.length > 0
                            ? weekPeriodsOfTheDay.sort((a, b) => a.start.localeCompare(b.start))
                            : [weekPeriodsOfTheDay]
                }
            }

            return this.getDefaultWorkPeriod(index, dayName, SchedulePatientsType.Both)
        })
    }

    async prepareWorkPeriodsData(
        scheduleId: number,
        schedulePatientInsuranceAccepted: SchedulePatientsType
    ): Promise<ScheduleWorkPeriods[]> {
        const data = await this.getWorkPeriods(scheduleId)

        return new Array(DAYS_NUMBER_OF_WEEK).fill(0).map((_, i) => {
            const index = i === 6 ? 0 : i + 1
            const dayName = date().day(index).format(DateFormat.DayNameFormat)

            const payload = data.find(({ dayOfWeek }) => dayOfWeek === index)

            if (payload) {
                return {
                    active: true,
                    name: dayName,
                    ...payload
                }
            }

            return this.getDefaultWorkPeriod(index, dayName, schedulePatientInsuranceAccepted)
        })
    }

    async prepareWeeklyGroupedExceptionsData(scheduleId: number): Promise<ScheduleExceptions[]> {
        const data = await this.getScheduleExceptions(scheduleId)

        const sortedData = data.sort((a, b) => date(a.exceptionDate).diff(date(b.exceptionDate)))
        const weeklyGroupedData = Object.values(groupBy(sortedData, ({ exceptionDate }) => date(exceptionDate).week()))

        return weeklyGroupedData
    }

    buildNextWorkPeriodItem(
        lastWorkPeriodItem: ScheduleWorkPeriod,
        scheduleServicesIds: number[],
        serviceScheduleInsurances: number[],
        scheduleInsurancesIds: number[],
        schedulePatientInsuranceAccepted: SchedulePatientsType
    ): ScheduleWorkPeriod | undefined {
        const { end, start } = lastWorkPeriodItem

        const diff = createDateFromTime(end).diff(createDateFromTime(start), 'm')
        const dateBefore = createDateFromTime(end)
        const dateAfter = createDateFromTime(end).add(diff, 'm')

        const finalEnd = dateBefore.isSame(dateAfter, 'day')
            ? dateAfter.format(DateFormat.HourMinuteFormat)
            : LATEST_END_HOUR

        if (finalEnd === end) {
            return
        }

        return {
            admitedPatients: ADMITTED_PATIENTS_ALL,
            end: finalEnd,
            insurances: scheduleInsurancesIds,
            originalEnd: finalEnd,
            originalStart: end,
            patientInsuranceAccepted: schedulePatientInsuranceAccepted,
            periodicity: PERIODICITY_REPEAT_EVERY_WEEK,
            nextDay: null,
            serviceScheduleInsurances,
            services: scheduleServicesIds,
            start: end,
            isPrivate: VisibilityType.Public
        }
    }

    buildServicesWithInsurancesSettings(scheduleServices: ScheduleService[]): ScheduleService[] {
        const servicesWithInsuranceSettings = scheduleServices.map(service => {
            if (!service.serviceScheduleInsurances || service.serviceScheduleInsurances.length === 0) {
                return service
            }

            return service.serviceScheduleInsurances.map(serviceInsurance => {
                const { insuranceName, id, selfPayer, duration } = serviceInsurance
                const { name } = service

                return {
                    ...service,
                    name: `${name} ${insuranceName}${selfPayer ? ' \u20AC' : ''}`,
                    insuranceSettingsId: id,
                    duration
                }
            })
        })

        return servicesWithInsuranceSettings.flat()
    }

    buildServicesPayload(
        editedWorkPeriod: ScheduleWorkPeriod,
        scheduleServices: ScheduleService[],
        showServiceScheduleSettingsByInsurance: boolean
    ): ServicesPayload {
        const { id: editedWorkPeriodId, services, serviceScheduleInsurances } = editedWorkPeriod

        if (showServiceScheduleSettingsByInsurance) {
            return {
                list: scheduleServices.filter(({ insuranceSettingsId }) =>
                    serviceScheduleInsurances.includes(insuranceSettingsId)
                ),
                type:
                    serviceScheduleInsurances.length === scheduleServices.length
                        ? SERVICES_RADIO_ALL
                        : SERVICES_RADIO_SOME
            }
        }

        if (services?.length > 0 || editedWorkPeriodId) {
            return {
                list: scheduleServices.filter(({ id }) => services.includes(id)),
                type: services.length === scheduleServices.length ? SERVICES_RADIO_ALL : SERVICES_RADIO_SOME
            }
        }

        return {
            list: [],
            type: SERVICES_RADIO_ALL
        }
    }

    prepareInsurancesPayload(
        editedWorkPeriod: ScheduleWorkPeriod,
        scheduleInsurances: ScheduleInsurance[],
        schedulePatientInsuranceAccepted: SchedulePatientsType,
        showInsuranceFilterInScheduleConfigurator: boolean
    ): InsurancesPayload {
        if (!showInsuranceFilterInScheduleConfigurator) {
            return {
                list: [],
                type: SchedulePatientsType.Both
            }
        }

        const {
            id: workPeriodId,
            insurances,
            patientInsuranceAccepted: workPeriodPatientInsuranceAccepted
        } = editedWorkPeriod

        const assignedInsurancesList = scheduleInsurances.filter(({ id }) => insurances.includes(id))

        // For every "new" work period we should always assign all possible insurances and set the type inherited from the schedule
        if (!workPeriodId) {
            return {
                list: scheduleInsurances,
                type: schedulePatientInsuranceAccepted
            }
        }

        // In every other case we should assign the insurances that are already assigned to this work period and use the type inherited from the workperiod
        return {
            list: assignedInsurancesList,
            type: workPeriodPatientInsuranceAccepted
        }
    }

    buildPeriodicityPayload(editedWorkPeriod: ScheduleWorkPeriod): PeriodicityPayload {
        const { nextDay, periodicity } = editedWorkPeriod

        return {
            nextDay: nextDay || null,
            periodicity: periodicity || 1
        }
    }

    getFirstAndLastWeekDay(day: string): string {
        const m = date(day)

        const startOfWeek = m.startOf('w').format(DateFormat.DefaultFormat)
        const endOfWeek = m.endOf('w').format(DateFormat.DefaultFormat)

        return `${startOfWeek} - ${endOfWeek}`
    }

    getNextWeekday(startDate: any, dayOfWeek: number) {
        const DEFAULT_SUNDAY_INDEX = 0
        const ISO_SUNDAY_INDEX = 7

        const isoDayOfWeek = dayOfWeek === DEFAULT_SUNDAY_INDEX ? ISO_SUNDAY_INDEX : dayOfWeek

        return date(startDate).isoWeekday(isoDayOfWeek)
    }

    getScheduleById(schedules: ScheduleId[], id: number): Partial<Schedule> | undefined {
        return schedules.find(schedule => schedule.id === id)
    }

    getSchedulesIds(schedules: ScheduleId[]): number[] {
        return schedules.map(({ id }) => id)
    }

    isDoctorCalendar(calendarType: number): boolean {
        return calendarType === CALENDAR_KIND.doctor
    }

    async getSchedulesForNotificationsSettings(): Promise<ScheduleNotificationEntity[]> {
        const { data } = await repository.getSchedules()

        return data.map(schedule => ({
            ...schedule,
            selectorName: this.getScheduleSelectorName(schedule),
            color: this.getScheduleColor(schedule)
        }))
    }

    getScheduleNameById(schedules: ScheduleId[], id: number): string {
        const schedule = this.getScheduleById(schedules, id)
        if (!schedule) return ''
        return schedule.name || ''
    }

    getScheduleColorById(schedules: ScheduleId[], id: number): string {
        const schedule = this.getScheduleById(schedules, id)
        if (!schedule) return ''
        return this.getScheduleColor(schedule)
    }

    getScheduleColor(schedule: any): string {
        return schedule.colorSchemas?.baseColor || schedule.guiColor
    }

    getScheduleSelectorNameById(schedules: ScheduleId[], id: number): string {
        const schedule = this.getScheduleById(schedules, id)
        if (!schedule) return ''
        return this.getScheduleSelectorName(schedule)
    }

    getScheduleSelectorName(schedule: any): string {
        return (
            schedule.name +
            (schedule.specialityName ? ` - ${schedule.specialityName}` : '') +
            (schedule.centreWithMultipleFacilities && schedule.facilityName ? ` - ${schedule.facilityName}` : '')
        )
    }

    featureService(scheduleId: number, serviceId: number, isFeatured: boolean): Promise<void> {
        return repository.featureService(scheduleId, serviceId, isFeatured)
    }

    removeService(scheduleId: number, serviceId: number): Promise<void> {
        return repository.removeService(scheduleId, serviceId)
    }

    updateService(scheduleId: number, service: ScheduleService): Promise<void> {
        return repository.updateService(scheduleId, service)
    }

    defaultService(scheduleId: number, serviceId: number): Promise<void> {
        return repository.defaultService(scheduleId, serviceId)
    }

    getMatchingSchedules(scheduleId: number, serviceId: number): Promise<SchedulesMachedByService[]> {
        return repository.getMatchingSchedules(scheduleId, serviceId)
    }

    getNotificationSettings(scheduleId: number): Promise<NotificationSetting[]> {
        return repository.getNotificationSettings(scheduleId)
    }

    async updateNotificationSettings(
        scheduleId: number,
        settingId: NotificationSettings,
        value: boolean,
        disableReason: string,
        propagate: boolean
    ): Promise<void> {
        await repository.updateNotificationSettings(scheduleId, settingId, value, disableReason, propagate)
    }

    isInsurancesConfigValid({
        displayInsurancesSettings,
        schedulePatientInsuranceAccepted,
        insurancesPayload,
        visibilityType
    }: {
        displayInsurancesSettings: boolean
        insurancesPayload: InsurancesPayload
        schedulePatientInsuranceAccepted: number
        visibilityType: VisibilityType
    }): boolean {
        if (!displayInsurancesSettings || visibilityType === VisibilityType.Private) {
            return true
        }

        const { type, list } = insurancesPayload
        const hasSelectedInsurances = !!list.length

        if (type === SchedulePatientsType.Private) {
            return true
        }

        if (schedulePatientInsuranceAccepted === SchedulePatientsType.Insurance) {
            return type === SchedulePatientsType.Insurance && hasSelectedInsurances
        }

        return hasSelectedInsurances
    }
}

export default new SchedulesService()
