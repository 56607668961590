export enum ReportPathname {
    Cash = 'reports-cash-register',
    Commissions = 'reports-doctors-commissions',
    Expenses = 'reports-expenses',
    Visits = 'reports-visits'
}

export enum ReportType {
    Commissions = 'commissions',
    Expenses = 'expenses',
    Payments = 'payments',
    Reports = 'reports'
}

export enum HeaderColumnMovementDirection {
    Backward = 'backward',
    Forward = 'forward'
}

export enum ReportExportType {
    Reports = 1,
    Payments = 32
}

export enum ReportQueryParam {
    Category = 'category',
    CheckboxFilters = 'checkboxFilters',
    Date = 'date',
    DateFilter = 'dateFilter',
    Insurances = 'insurances',
    Patient = 'patient',
    PaymentStatus = 'paymentStatus',
    Services = 'services'
}

export enum ReportStatisticName {
    Paid = 'paid',
    PaymentMethods = 'paymentMethods',
    Total = 'total',
    Unpaid = 'unpaid'
}

export enum ReportColumnProperty {
    AmountInDebt = 'amountInDebt',
    AmountPaid = 'amountPaid',
    Category = 'category',
    ClinicSplit = 'clinicSplit',
    DoctorSplit = 'doctorSplit',
    Insurance = 'insurance',
    PaymentMethod = 'paymentMethod',
    PaymentStatus = 'paymentStatus',
    Price = 'price',
    Service = 'service',
    Status = 'status'
}

export enum DoctorsCommissionsCellComponents {
    DefaultCell = 'DefaultTableRowCell',
    ServiceCommissions = 'ReportsCommissionsServiceCell'
}

export enum ReportColumnField {
    BookingCount = 'bookingCount',
    ClinicRevenue = 'clinicRevenue',
    Date = 'date',
    DoctorName = 'doctorName',
    DoctorRevenue = 'doctorRevenue',
    EventServices = 'eventServices',
    Insurance = 'insurance',
    Patient = 'patient',
    Revenue = 'revenue'
}

export enum CashReportCategory {
    Appointment = 1,
    Voucher = 2
}

export enum ReportsColumnsThatNeedUpdate {
    IncomeSplitClinicAmount = 'income-split-clinics-dashboard-column-title-clinic-amount',
    IncomeSplitDoctorAmount = 'income-split-clinics-dashboard-column-title-doctor-amount'
}
