import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import {
    ServiceImportData,
    ServiceImportDto,
    ServiceValidationResults
} from '../domain/interfaces/admin.import.service.interfaces'
import { ImportField } from '../domain/interfaces/admin.interfaces'

const URL_BASE = 'services'

const URL_IMPORT = `${URL_BASE}/import`
const URL_FIELDS_SERVICE = `${URL_IMPORT}/fields/service`
const URL_FIELDS_SERVICES_SCHEDULE = `${URL_IMPORT}/fields/serviceschedule`
const URL_HEAD = `${URL_IMPORT}/head`
const URL_VALIDATE = `${URL_IMPORT}/validate`
const URL_UNIQUE_ID = `${URL_IMPORT}/:uniqueId`

class AdminServiceImportRepository {
    async getFieldService(): Promise<ImportField[]> {
        const { data } = await httpAPI.get(URL_FIELDS_SERVICE)

        return data
    }

    async getFieldServiceSchedule(): Promise<ImportField[]> {
        const { data } = await httpAPI.get(URL_FIELDS_SERVICES_SCHEDULE)

        return data
    }

    async getImportFileHeaders(file: File): Promise<string[][]> {
        const formData = new FormData()
        formData.append('file', file)

        const { data } = await httpAPI.post(URL_HEAD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        return data
    }

    async validateImport(formData: FormData): Promise<ServiceValidationResults[]> {
        const { data } = await httpAPI.post(URL_VALIDATE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        return data
    }

    async import(importData: ServiceImportData): Promise<number[]> {
        const { data } = await httpAPI.post(URL_IMPORT, importData)
        return data
    }

    async getServiceImports(): Promise<ServiceImportDto[]> {
        const { data } = await httpAPI.get(URL_IMPORT)
        return data
    }

    async getServiceImport(uniqueId: string): Promise<ServiceImportDto> {
        const url = getUrlFromTemplate({
            template: URL_UNIQUE_ID,
            params: { uniqueId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new AdminServiceImportRepository()
