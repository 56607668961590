import httpAPI from '@/api/utils/httpClientAPI'

const Features = () => {
    const url = {
        path: 'features'
    }

    return {
        fetch() {
            return httpAPI.get(url.path)
        }
    }
}

export default Features
