import VideoConsultationTermsRepository from '../api/videoConsultationTerms.api'
import { TermsSource } from './enums/terms.enums'

class VideoConsultationTermsService {
    setVideoTermsAccepted(type: number): void {
        if (!(type in TermsSource)) {
            return
        }

        VideoConsultationTermsRepository.setVideoTermsAccepted(type)
    }
}

export default new VideoConsultationTermsService()
