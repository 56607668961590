import HttpClient from '@/api/utils/HttpClient'
import UserInfoData from '@/bootstrap/monitor/interfaces/userMetric.interface'

import { CLINIC_SIZE_HEADER, USER_TYPE_HEADER } from '../../api/constants/auth.constants'

export const setUserInfoHeaders = (httpClient: HttpClient, { clinicSize, userType }: UserInfoData) => {
    if (clinicSize) {
        httpClient.setCustomHeader(CLINIC_SIZE_HEADER, clinicSize)
    }

    if (userType) {
        httpClient.setCustomHeader(USER_TYPE_HEADER, userType)
    }
}
