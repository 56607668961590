import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { UnreadConversationsResponse } from '../domain/interfaces/conversations.interfaces'
import { Contact, Facility } from '../domain/interfaces/internalChat.interfaces'

const URL_BASE = 'chat/internal'

const URL_CONTACTS_FACILITY_ID = `${URL_BASE}/contacts/:facilityId`
const URL_FACILITIES = `${URL_BASE}/facilities`

const URL_CONVERSATIONS = `${URL_BASE}/conversations`
const URL_CONVERSATIONS_LIST = `${URL_CONVERSATIONS}/list`
const URL_CONVERSATIONS_UNREAD = `${URL_CONVERSATIONS}/unread`
const URL_CONVERSATIONS_CONVERSATION_ID = `${URL_CONVERSATIONS}/:conversationId`

class InternalChatRepository {
    async getConversationByConversationId(conversationId: string): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATIONS_CONVERSATION_ID,
            params: { conversationId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getConversationByContactAndFacilityId(contactUserId: number, facilityId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATIONS,
            query: { contactUserId, facilityId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getAllConversations(limit: number, token: string | null): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATIONS_LIST,
            query: {
                limit,
                ...(token && { providerNextToken: token })
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getContactsByFacilityId(facilityId: number): Promise<Contact[]> {
        const url = getUrlFromTemplate({
            template: URL_CONTACTS_FACILITY_ID,
            params: {
                facilityId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getUnreadConversations(): Promise<UnreadConversationsResponse> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATIONS_UNREAD
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getFacilities(): Promise<Facility[]> {
        const { data } = await httpAPI.get(URL_FACILITIES)
        return data
    }
}

export default new InternalChatRepository()
