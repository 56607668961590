export enum SetupGuideTaskKind {
    Know = 0,
    Trust,
    Book
}

export enum SetupGuideTaskScope {
    Booking = 'booking',
    MobileApp = 'mobile-app',
    Patient = 'patient',
    Profile = 'profile',
    Schedule = 'schedule',
    Service = 'service',
    User = 'user',
    VideoLibrary = 'video-library',
    Wizard = 'wizard'
}

export enum SetupGuideTaskAction {
    Create = 'create',
    Download = 'download',
    Import = 'import',
    Show = 'show',
    Update = 'update'
}

export enum SetupGuideTaskRouteType {
    Global = 'global',
    Local = 'local'
}

export enum SetupGuideTaskURLAppType {
    SaaS = 0,
    Marketplace,
    OnboardingWizard
}

export enum SetupGuideTaskState {
    NotCompleted = 0,
    Completed,
    CompletedByDefault
}
