import {
    SETTINGS_USERS_FORM,
    SETTINGS_USERS_LIST,
    SETTINGS_USERS_NEW
} from '@/features/settings/ui/constants/router.constants'

const UsersIndex = () =>
    import(/* webpackChunkName: "settings-users-index" */ '@/features/settings/ui/views/users/UsersIndex.vue')
const UserForm = () =>
    import(/* webpackChunkName: "settings-user-form-index" */ '@/features/settings/ui/views/users/UserForm.vue')
const UsersList = () =>
    import(/* webpackChunkName: "settings-users-list" */ '@/features/settings/ui/views/users/UsersList.vue')

export default {
    path: 'users',
    component: UsersIndex,
    children: [
        {
            name: SETTINGS_USERS_LIST,
            path: '/',
            component: UsersList
        },
        {
            path: 'new',
            name: SETTINGS_USERS_NEW,
            component: UserForm
        },
        {
            path: ':username',
            name: SETTINGS_USERS_FORM,
            component: UserForm,
            props: true
        }
    ]
}
