import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { BaseService, NewService, Service } from '@/features/servicesLegacy/domain/interfaces/services.interfaces'

import {
    AvailableSchedulesDTO,
    DuplicatedServiceDTO,
    PatchServiceScheduleDuration,
    PatchServiceSchedulePayload,
    PatchServiceSchedulePrice,
    PatchServiceScheduleShowOnline,
    ServiceDetailsDTO,
    ServiceDTO
} from './interfaces/services.interfaces'

const URL_BASE = 'services'
const URL_SERVICES = `${URL_BASE}/base`
const URL_BASE_V2 = 'v2/services'
const URL_SERVICE_SCHEDULE_UPDATE = `${URL_BASE_V2}/:serviceId/serviceSchedule/:serviceScheduleId`
const URL_SERVICE_SCHEDULE_UPDATE_DURATION = `${URL_SERVICE_SCHEDULE_UPDATE}/duration/:duration`
const URL_SERVICE = `${URL_BASE_V2}/:serviceId`
const URL_SERVICE_SCHEDULE_UPDATE_PRICE = `${URL_SERVICE_SCHEDULE_UPDATE}/price/:price`
const URL_SERVICE_SCHEDULE_REMOVE_PRICE = `${URL_SERVICE_SCHEDULE_UPDATE}/price`
const URL_SERVICE_SCHEDULE_UPDATE_SHOW_ONLINE = `${URL_SERVICE_SCHEDULE_UPDATE}/showOnline/:showOnline`
const URL_DUPLICATED_SERVICES = `${URL_BASE_V2}/duplicated`
const URL_SERVICE_AVAILABLE_SCHEDULES = `${URL_BASE_V2}/availableschedules`
const URL_SERVICE_ADD_SCHEDULES = `${URL_SERVICE}/schedule`
const URL_SERVICE_IN_USE = `${URL_BASE_V2}/service-schedules-in-use`

class ServicesRepository {
    async getServices(): Promise<Service[]> {
        const { data } = await httpAPI.get<Service[]>(URL_BASE)
        return data
    }

    async getBaseServices(name: string): Promise<BaseService[]> {
        const url = getUrlFromTemplate({
            template: URL_SERVICES,
            query: { filter: name }
        })
        const { data } = await httpAPI.get<BaseService[]>(url)
        return data
    }

    async getServicesV2(): Promise<ServiceDTO[]> {
        const { data } = await httpAPI.get(URL_BASE_V2)

        return data
    }

    async getServiceSchedulesInUse(): Promise<number[]> {
        const { data } = await httpAPI.get(URL_SERVICE_IN_USE)

        return data
    }

    addAgendasToService(serviceId: number, scheduleIds: number[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_ADD_SCHEDULES,
            params: { serviceId }
        })
        return httpAPI.post(url, {
            scheduleIds
        })
    }

    async getServiceById(serviceId: number): Promise<ServiceDetailsDTO> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE,
            params: { serviceId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getAvailableSchedules(serviceIds: number[]): Promise<AvailableSchedulesDTO> {
        const { data } = await httpAPI.post(URL_SERVICE_AVAILABLE_SCHEDULES, { serviceIds })

        return data
    }

    updateServiceScheduleDuration(payload: PatchServiceScheduleDuration): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULE_UPDATE_DURATION,
            params: {
                serviceId: payload.serviceId.toString(),
                serviceScheduleId: payload.serviceScheduleId.toString(),
                duration: payload.duration.toString()
            }
        })

        return httpAPI.patch(url)
    }

    updateServiceSchedule(serviceId: number, serviceObject: NewService): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE,
            params: { serviceId }
        })

        return httpAPI.put(url, serviceObject)
    }

    updateServiceSchedulePrice(payload: PatchServiceSchedulePrice): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULE_UPDATE_PRICE,
            params: {
                serviceId: payload.serviceId.toString(),
                serviceScheduleId: payload.serviceScheduleId.toString(),
                price: payload.price.toString()
            },
            query: {
                priceFrom: payload.priceFrom.toString()
            }
        })

        return httpAPI.patch(url)
    }

    removeServiceSchedulePrice(payload: PatchServiceSchedulePayload): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULE_REMOVE_PRICE,
            params: {
                serviceId: payload.serviceId.toString(),
                serviceScheduleId: payload.serviceScheduleId.toString()
            }
        })

        return httpAPI.patch(url)
    }

    updateServiceScheduleShowOnline(payload: PatchServiceScheduleShowOnline): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULE_UPDATE_SHOW_ONLINE,
            params: {
                serviceId: payload.serviceId.toString(),
                serviceScheduleId: payload.serviceScheduleId.toString(),
                showOnline: payload.showOnline.toString()
            }
        })

        return httpAPI.patch(url)
    }

    async getDuplicatedServices(name: string): Promise<DuplicatedServiceDTO> {
        const url = getUrlFromTemplate({
            template: URL_DUPLICATED_SERVICES,
            query: { filter: name }
        })
        const { data } = await httpAPI.get(url)

        return data
    }
}

export default new ServicesRepository()
