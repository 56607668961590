import Vue from 'vue'

import { getDefaultState } from './state'
import {
    MUTATION_INVOICES_RESET_STATE,
    MUTATION_SET_INVOICING_ENTITIES,
    MUTATION_SET_TERMS_ACCEPTED
} from './types/mutations'

const mutations = {
    [MUTATION_SET_TERMS_ACCEPTED](state, areTermsAndConditionsAccepted) {
        Vue.set(state, 'areTermsAndConditionsAccepted', areTermsAndConditionsAccepted)
    },
    [MUTATION_SET_INVOICING_ENTITIES](state, invoicingEntities) {
        state.invoicingEntities = invoicingEntities
    },
    [MUTATION_INVOICES_RESET_STATE](state) {
        state = getDefaultState()
    }
}

export default mutations
