import PrintLayoutService from '@/features/settings/domain/printLayouts.service'

import {
    ACTION_SET_LOGO_TO_UPLOAD,
    ACTIONS_SET_IS_CLINIC_SCHEDULE,
    ACTIONS_UPDATE_DATA_SETTINGS,
    ACTIONS_UPDATE_DEFAULT_VALUES,
    ACTIONS_UPDATE_LAYOUT_SETTINGS,
    ACTIONS_UPDATE_RESPONSE_OPTIONS
} from './types/actions'
import {
    MUTATION_SET_DATA_SETTINGS,
    MUTATION_SET_DEFAULTS,
    MUTATION_SET_IS_CLINIC_SCHEDULE,
    MUTATION_SET_LAYOUT_SETTINGS,
    MUTATION_SET_LOGO_TO_UPLOAD_AT_SAVE,
    MUTATION_SET_RESPONSE_OPTIONS
} from './types/mutations'

const actions = {
    [ACTIONS_UPDATE_LAYOUT_SETTINGS]({ commit, state }, { sectionId, id, value }) {
        const layoutSettings = state.layoutSettings || {}

        commit(MUTATION_SET_LAYOUT_SETTINGS, {
            ...layoutSettings,
            [sectionId]: {
                ...(layoutSettings[sectionId] || {}),
                [id]: value
            }
        })
    },

    [ACTIONS_UPDATE_DATA_SETTINGS]({ commit, state }, { id, value }) {
        const dataSettings = state.dataSettings || {}

        commit(MUTATION_SET_DATA_SETTINGS, {
            ...dataSettings,
            [id]: value
        })
    },

    [ACTIONS_UPDATE_DEFAULT_VALUES]({ commit }, defaults) {
        commit(MUTATION_SET_DEFAULTS, defaults)
    },

    [ACTIONS_UPDATE_RESPONSE_OPTIONS]({ commit }, responseOptions) {
        const structure = PrintLayoutService.buildDefaultStructure({
            layout: responseOptions.layout,
            data: responseOptions.data
        })

        commit(MUTATION_SET_RESPONSE_OPTIONS, {
            ...structure
        })
    },

    [ACTIONS_SET_IS_CLINIC_SCHEDULE]({ commit }, isClinicSchedule) {
        commit(MUTATION_SET_IS_CLINIC_SCHEDULE, isClinicSchedule)
    },

    [ACTION_SET_LOGO_TO_UPLOAD]({ commit }, { id, file, fileGuid, sectionId, logoIsLandscape }) {
        commit(MUTATION_SET_LOGO_TO_UPLOAD_AT_SAVE, {
            id,
            file,
            fileGuid,
            sectionId,
            logoIsLandscape
        })
    }
}

export default actions
