import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { ChatSessionResponse } from '../domain/interfaces/session.interface'

const URL_BASE = 'chat'
const URL_USER_SESSION_V1 = `/v1/${URL_BASE}/Users/current?includeSessionToken=true`
const URL_USER_SESSION_V1_WITH_HOST_ID = `/v1/${URL_BASE}/Users/host/:hostId`

const URL_USER_SESSION_V2 = `/v2/${URL_BASE}/Users/current?includeSessionToken=true`

class InternalChatRepository {
    async fetchSession(): Promise<ChatSessionResponse> {
        const { data } = await httpAPI.get(URL_USER_SESSION_V2)
        return data
    }

    async fetchImpersonatorSession(hostId?: number): Promise<ChatSessionResponse> {
        if (hostId) {
            const url = getUrlFromTemplate({
                template: URL_USER_SESSION_V1_WITH_HOST_ID,
                params: { hostId },
                query: {
                    includeSessionToken: 'true'
                }
            })
            const { data } = await httpAPI.get(url)
            return data
        }

        const { data } = await httpAPI.get(URL_USER_SESSION_V1)
        return data
    }
}

export default new InternalChatRepository()
