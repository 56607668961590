import {
    SecretaryPaymentMethod,
    SecretaryViewFilters,
    SecretaryViewUpdateServices
} from '@/features/calendar/domain/interfaces/secretaryView.interfaces'

export enum ActionTypes {
    ChangeColumnVisibility = 'changeColumnVisibility',
    ConfirmAppointment = 'confirmAppointment',
    DeleteAppointment = 'deleteAppointment',
    DeletePaymentMethod = 'deletePaymentMethod',
    FetchPaymentMethods = 'fetchPaymentMethods',
    FetchSecretaryAppointments = 'fetchSecretaryAppointments',
    FetchSecretaryHeaders = 'fetchSecretaryHeaders',
    RemoveAppointment = 'removeAppointment',
    SaveColumns = 'saveColumns',
    UpdateAppointmentComment = 'updateAppointmentComment',
    UpdateCheckingStatus = 'updateCheckingStatus',
    UpdatePatientPhoneNumber = 'updatePatientPhoneNumber',
    UpdatePaymentMethod = 'updatePaymentMethod',
    UpdatePaymentStatus = 'updatePaymentStatus',
    UpdatePriceAction = 'updatePriceAction',
    UpdateServices = 'updateServices'
}

export type SecretaryViewActions = {
    [ActionTypes.FetchSecretaryAppointments](payload: {
        filters?: SecretaryViewFilters
        selectedDate: Date
    }): Promise<void>
    [ActionTypes.FetchSecretaryHeaders](): void
    [ActionTypes.FetchPaymentMethods](): void
    [ActionTypes.SaveColumns](): Promise<void>
    [ActionTypes.ChangeColumnVisibility](payload: { hidden: boolean; tag: string }): void
    [ActionTypes.UpdateCheckingStatus](payload: {
        appointmentId: number
        attendance: number
        patientArrivalTime: Date
    }): void
    [ActionTypes.UpdateCheckingStatus](payload: {
        appointmentId: number
        attendance: number
        patientArrivalTime: Date
        patientExitTime: Date
    }): void
    [ActionTypes.UpdatePriceAction](payload: { appointmentId: number; price: number }): Promise<void>
    [ActionTypes.UpdatePaymentMethod](payload: {
        appointmentId: number
        paymentMethod: SecretaryPaymentMethod
        paymentMethodId: number
    }): Promise<void>
    [ActionTypes.DeletePaymentMethod](appintmentid: number): Promise<void>
    [ActionTypes.UpdatePaymentStatus](payload: {
        appointmentId: number | string
        paymentStatusId: number
    }): Promise<void>
    [ActionTypes.UpdatePatientPhoneNumber](payload: {
        appointmentId: number
        patientId: number
        patientPhoneNumber: string
    }): Promise<void>
    [ActionTypes.UpdateAppointmentComment](payload: { appointmentId: number; comment: string }): Promise<void>
    [ActionTypes.DeleteAppointment](appointmentId: number): Promise<void>
    [ActionTypes.UpdateServices](payload: {
        appointmentId: number | string
        servicesUpdate: SecretaryViewUpdateServices[]
    }): Promise<void> // not number bb
    [ActionTypes.ConfirmAppointment](appintmentid: number): Promise<void>
    [ActionTypes.RemoveAppointment](appointmentId: number): void
}
