import { HintVisitStatus } from '../../domain/enums/hints.enums'

export const HINT_SPECIALIST = 'specialist'
export const HINT_ALL_SPECIALIST = 'allspecialist'
export const HINT_SCHEDULE = 'schedule'
export const HINT_FACILITY = 'facility'
export const HINT_STATUS = 'status'
export const HINT_SERVICE = 'service'
export const HINT_PAYMENT_METHODS = 'paymentmethod'
export const HINT_PAYMENT_STATUS = 'paymentstatus'
export const HINT_INSURANCE = 'insurance'

export const STATUS_ORDER = [
    HintVisitStatus.Pending,
    HintVisitStatus.WaitingRoom,
    HintVisitStatus.Visiting,
    HintVisitStatus.Visited,
    HintVisitStatus.NoShow,
    HintVisitStatus.CanceledByUser,
    HintVisitStatus.CanceledByPatient
]

export const HINT_ONLINE_PAYMENT_METHOD_ID = '-1'
export const HINT_NONE_PAYMENT_METHOD_ID = '1'

export const HINT_UNPAID_PAYMENT_STATUS_ID = '0'
export const HINT_PAID_PAYMENT_STATUS_ID = '1'
export const HINT_PARTIALLY_PAID_PAYMENT_STATUS_ID = '4'

export const PAYMENT_STATUS_AVAILABLES_FOR_ONLINE_SCHEDULES = [
    HINT_PAID_PAYMENT_STATUS_ID,
    HINT_UNPAID_PAYMENT_STATUS_ID
]
export const PAYMENT_STATUS_AVAILABLES = [
    ...PAYMENT_STATUS_AVAILABLES_FOR_ONLINE_SCHEDULES,
    HINT_PARTIALLY_PAID_PAYMENT_STATUS_ID
]

export const PAYMENT_METHOD_PARTIAL_PAYMENT_ID = '20'
export const PAYMENT_METHODS_NOT_AVAILABLES_FOR_VOUCHERS = [PAYMENT_METHOD_PARTIAL_PAYMENT_ID]
