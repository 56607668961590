import repository from '@/features/patient/api/facility.api'

import { Facility, Place } from './interfaces/facility.interfaces'

// NOTE: it may not belong to "patient" feature. It will be moved if a "facility" feature emerges.
class FacilityService {
    async getFacilities(): Promise<Facility[]> {
        return repository.getFacilities()
    }

    getPlaces(): Promise<Place[]> {
        return repository.getPlaces()
    }
}

export default new FacilityService()
