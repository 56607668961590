export const getFileNameFromContentDisposition = ({ headers = {} }) => {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const utf8HeaderRegex = /=\?UTF-8\?B\?/gi
    const quotesRegex = /['"]/g

    let contentDisposition

    if ({}.toString.call(headers) === '[object Function]') {
        contentDisposition = headers('Content-Disposition')
    } else {
        contentDisposition = headers['content-disposition']
    }

    if (!contentDisposition || contentDisposition.indexOf('attachment') === -1) {
        return ''
    }

    const matches = fileNameRegex.exec(contentDisposition)
    if (matches == null || !matches[1]) {
        return ''
    }

    const filename = matches[1].replace(quotesRegex, '')
    if (utf8HeaderRegex.test(filename)) {
        return Buffer.from(filename.replace(utf8HeaderRegex, ''), 'base64').toString('utf8')
    }

    return decodeURIComponent(filename)
}
