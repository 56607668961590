import {
    createTaskStartedHandler,
    offTaskStarted,
    onSetupGuideMainPageExited,
    onSetupGuideMainPageLoaded,
    onTaskStarted
} from '../events'

export const startTrackingTask = () => {
    const handlerTaskStart = createTaskStartedHandler(taskName => {
        // TODO: Implement ok mechanism to trigger redirect after sending the tracking event
        // trackWithProperties(setupGuide.startAction, { properties: { sub_action: taskName } })
    })

    /**
     * Note: Temporary until we have the task events splitted by
     * start and end. This is to keep having the tracking isolated
     * from the ui and built reactive from the events
     */
    onSetupGuideMainPageLoaded(() => {
        onTaskStarted(handlerTaskStart)
    })

    onSetupGuideMainPageExited(() => {
        offTaskStarted(handlerTaskStart)
    })
}
