import Config from '@/api/Config'
import Culture from '@/api/Culture'
import Features from '@/api/Features'
import { addDataDogAction } from '@/bootstrap/monitor/datadog-poc/datadog'
import {
    COMMON_FETCH_API_CULTURE,
    COMMON_FETCH_COUNTRY_SETTINGS,
    COMMON_FETCH_TRANSLATIONS
} from '@/bootstrap/monitor/monitor.constants'
import { MODULE_ADMIN } from '@/features/admin/ui/store/types/module'
import { ADMIN_IMPORT_RESET } from '@/features/admin/ui/store/types/mutations'
import AuthService from '@/features/auth/domain/auth.service'
import { AuthMutationTypes } from '@/features/auth/ui/store/types'
import { MODULE_HINTS, MutationTypes } from '@/features/hints/ui/store/types'
import { MODULE_INVOICE } from '@/features/invoice/ui/store/types/module'
import { MUTATION_INVOICES_RESET_STATE } from '@/features/invoice/ui/store/types/mutations'
import { MODULE_SETTINGS_USERS } from '@/features/settings/ui/store/users/types/module'
import { MUTATION_USERS_RESET_STATE } from '@/features/settings/ui/store/users/types/mutation'
import { ACTION_SET_APP_STATE } from '@/store/actions-types'
import {
    RESET_MODULE_STORES,
    SET_APP_STATE,
    SET_AUTH_PROVIDER,
    SET_CULTURE,
    SET_DATE,
    SET_FEATURES
} from '@/store/mutation-types'

const FeaturesApi = Features()
const CultureApi = Culture()
const ConfigApi = Config()

const actions = {
    'calendar.action.goTo': function ({ commit }, value) {
        commit(SET_DATE, value)
    },
    [ACTION_SET_APP_STATE]({ commit }, appState) {
        commit(SET_APP_STATE, appState)
    },
    FETCH_FEATURES({ commit }) {
        return FeaturesApi.fetch()
            .then(result => {
                commit(SET_FEATURES, result.data)
                return Promise.resolve(result)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    FETCH_CULTURE({ commit }) {
        return CultureApi.fetch()
            .then(result => {
                commit(SET_CULTURE, result.data)
                addDataDogAction(COMMON_FETCH_API_CULTURE, { status: result.status, data: result.data })
                return Promise.resolve(result)
            })
            .catch(error => {
                addDataDogAction(COMMON_FETCH_API_CULTURE, { status: error.response?.status })
                return Promise.reject(error)
            })
    },
    FETCH_TRANSLATIONS({ commit }, lang) {
        return CultureApi.fetchLanguage(lang)
            .then(result => {
                addDataDogAction(COMMON_FETCH_TRANSLATIONS, { status: result.status, lang })
                return Promise.resolve(result)
            })
            .catch(error => {
                addDataDogAction(COMMON_FETCH_TRANSLATIONS, { status: error.response?.status, lang })
                return Promise.reject(error)
            })
    },
    FETCH_COUNTRY_SETTINGS({ commit }, culture) {
        return ConfigApi.fetchCountrySettings(culture)
            .then(result => {
                addDataDogAction(COMMON_FETCH_COUNTRY_SETTINGS, { status: result.status, culture })
                return Promise.resolve(result)
            })
            .catch(error => {
                addDataDogAction(COMMON_FETCH_COUNTRY_SETTINGS, { status: error.response?.status, culture })
                return Promise.reject(error)
            })
    },
    FETCH_AUTH_PROVIDERS({ commit }) {
        return AuthService.getProvider()
            .then(provider => {
                commit(SET_AUTH_PROVIDER, provider)
                return Promise.resolve(provider)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    [RESET_MODULE_STORES]({ commit }) {
        commit(AuthMutationTypes.ResetAuth)
        commit(`${MODULE_HINTS}/${MutationTypes.ResetHints}`)
        commit(`${MODULE_INVOICE}/${MUTATION_INVOICES_RESET_STATE}`)
        commit(`${MODULE_SETTINGS_USERS}/${MUTATION_USERS_RESET_STATE}`)
        commit(`${MODULE_ADMIN}/${ADMIN_IMPORT_RESET}`)
    }
}

export default actions
