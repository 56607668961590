import {
    PaymentMethod,
    SecretaryAppointment,
    SecretaryViewFilters
} from '@/features/calendar/domain/interfaces/secretaryView.interfaces'

import { PatientSelected, SecretaryColumns, secretaryRow } from '../../../interfaces/secretaryView.interfaces'

export enum GetterTypes {
    CalendarSelectedDayIsPast = 'calendarSelectedDayIsPast',
    GetAllRowsSelected = 'getAllRowsSelected',
    GetAppointmentComment = 'getAppointmentComment',
    GetFilters = 'getFilters',
    GetIsBulkCancelationPopoverOpen = 'getIsBulkCancelationPopoverOpen',
    GetIsChatPopoverOpen = 'getIsChatPopoverOpen',
    GetMaxRowsSelectable = 'getMaxRowSelectable',
    GetPatientsSelected = 'getPatientsSelected',
    GetPaymentMethods = 'getPaymentMethods',
    GetSecretaryAppointment = 'getSecretaryAppointment',
    GetSecretaryAppointments = 'getSecretaryAppointments',
    GetSecretaryViewHeader = 'getSecretaryViewHeader',
    GetTableLoading = 'getTableLoading'
}

export interface SecretaryViewGetters {
    [GetterTypes.GetSecretaryAppointments]: secretaryRow[]
    [GetterTypes.GetSecretaryAppointment]: (appointmentId: number | string) => SecretaryAppointment | undefined
    [GetterTypes.GetSecretaryViewHeader]: SecretaryColumns
    [GetterTypes.GetTableLoading]: boolean
    [GetterTypes.GetPaymentMethods]: PaymentMethod[]
    [GetterTypes.GetAllRowsSelected]: number[]
    [GetterTypes.GetPatientsSelected]: PatientSelected[]
    [GetterTypes.GetMaxRowsSelectable]: number
    [GetterTypes.GetAppointmentComment]: (appointmentId: number) => string | undefined
    [GetterTypes.GetFilters]: SecretaryViewFilters | undefined
    [GetterTypes.GetIsChatPopoverOpen]: boolean
    [GetterTypes.GetIsBulkCancelationPopoverOpen]: boolean
    [GetterTypes.CalendarSelectedDayIsPast]: boolean
}
