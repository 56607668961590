import numeral from 'numeral'

import { isPlainObject } from '@/utils/object'

const DEFAULT_FORMAT = '0,0'
const CURRENCY_FORMAT = '0,0[.]00 $'
const PREFIXED_FORMAT = '0,0[.]0a'
const CURRENCY_PREFIXED_FORMAT = '0,0[.]00a $'

const formatNumber = (number, withDecimals = false, numOfDecimals = 2) => {
    let format = DEFAULT_FORMAT

    if (withDecimals) {
        format += '[.]'
        for (let i = 0; i < numOfDecimals; i += 1) {
            format += '0'
        }
    }

    return numeral(Number(number)).format(format)
}

const formatAmount = (amount, format) => numeral(Number(amount)).format(format || CURRENCY_FORMAT)

const formatNumberPrefixed = number => numeral(Number(number)).format(PREFIXED_FORMAT)

const formatAmountPrefixed = (amount, format) => numeral(Number(amount)).format(format || CURRENCY_PREFIXED_FORMAT)

const formatPercent = (value, total) => {
    let result = 0
    if (total > 0 && value > 0) {
        result = Math.round(100 * (value / total))
    }
    return `${result}%`
}

const getPrefixedCurrencyFormat = format => {
    const firstChart = format[0]
    const lastChart = format[format.length - 1]

    if (!format.includes('$') || ![firstChart, lastChart].includes('$')) {
        return format
    }

    const isCurrencyBeforeAmount = firstChart === '$'

    if (!isCurrencyBeforeAmount) {
        return format.includes(' $') ? format.replace(' $', 'a $') : format.replace('$', 'a $')
    }

    return `${format}a`
}

const currency = culture => {
    if (!numeral.locales[culture]) {
        return '$'
    }
    return numeral.locales[culture].currency.symbol
}

const locale = cultureMapped => {
    const defaultCultureCode = 'en'
    const culture = (isPlainObject(cultureMapped) ? cultureMapped.baseCulture : cultureMapped) ?? defaultCultureCode
    const customCulture =
        isPlainObject(cultureMapped) && cultureMapped.baseCulture ? cultureMapped.customCulture : undefined
    const customCultureConfig = (isPlainObject(cultureMapped) ? cultureMapped.config : {}) ?? {}

    const registerAndSetCustomCulture = () => {
        numeral.register('locale', customCulture, {
            ...numeral.locales[culture],
            ...customCultureConfig
        })

        numeral.locale(customCulture)
    }

    const existsLocaleConfig = (cultureCode = '') => !!numeral.locales[cultureCode.toLowerCase()]

    if (customCulture && !existsLocaleConfig(customCulture) && existsLocaleConfig(culture)) {
        registerAndSetCustomCulture()
        return
    }

    if (existsLocaleConfig(customCulture) || existsLocaleConfig(culture)) {
        numeral.locale(customCulture ?? culture)
        return
    }

    if (culture === defaultCultureCode) {
        numeral.locale(defaultCultureCode)
        return
    }

    return import(
        /* webpackChunkName: "[request]-numeral-locale" */
        `numeral/locales/${culture}.js`
    )
        .then(_ => {
            if (customCulture) {
                registerAndSetCustomCulture()
                return
            }

            numeral.locale(culture)
        })
        .catch(e => {
            throw new Error(`Numeral "${culture}": ${e.message}`)
        })
}

export {
    currency,
    formatAmount,
    formatAmountPrefixed,
    formatNumber,
    formatNumberPrefixed,
    formatPercent,
    getPrefixedCurrencyFormat,
    locale,
    numeral
}
