import {
    CALENDAR_DEBUG_KEY,
    CUSTOMER_FEEDBACK_KEY,
    SENTRY_DEBUG_KEY,
    SIGNALR_NOTIFICATION_HANDLER_DISABLE_DEBUG_KEY,
    TRACKING_KEY
} from '@/plugins/debug/constants/logging.constants'
import Storage from '@/store/storage'
import { OVERRIDE_SELECTED_LOGGING } from '@/store/storage-types'

export function useDebugMode() {
    function reset() {
        Storage.remove(OVERRIDE_SELECTED_LOGGING)
    }
    function save(selectedLogs: string[]) {
        Storage.set(OVERRIDE_SELECTED_LOGGING, selectedLogs)
    }

    function getStorageKeys(): string[] {
        return Storage.get(OVERRIDE_SELECTED_LOGGING) || []
    }

    return {
        isTrackingEventsEnabled: getStorageKeys().includes(TRACKING_KEY),
        isCustomerFeedbackEnabled: getStorageKeys().includes(CUSTOMER_FEEDBACK_KEY),
        isCalendarDebugMode: getStorageKeys().includes(CALENDAR_DEBUG_KEY),
        isSentryLoggerEnabled: getStorageKeys().includes(SENTRY_DEBUG_KEY),
        isSignalRNotificationHandlerDisabled: () =>
            getStorageKeys().includes(SIGNALR_NOTIFICATION_HANDLER_DISABLE_DEBUG_KEY),
        getStorageKeys,
        reset,
        save
    }
}
