import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { UserFileType } from '@/features/settings/domain/enums/userFiles.enums'
import { UserFileGuid } from '@/features/settings/domain/interfaces/userFiles.interfaces'
import { getParametrizedUrl } from '@/utils/url'

const URL_BASE = 'userfiles'
const URL_USER_FILES_SINGLE = `${URL_BASE}/:id`

const DEFAULT_EXTENSION = 'jpg'
const FALLBACK_NAME = 'logo'
const UPLOAD_SOURCE = 'printLayout'

class UserFiles {
    fetchFileById(id: string) {
        const url = getUrlFromTemplate({
            template: URL_USER_FILES_SINGLE,
            params: { id }
        })

        return httpAPI.get(url, {
            responseType: 'blob'
        })
    }

    async uploadFile(file: File, guid?: string, type: UserFileType = 0): Promise<UserFileGuid> {
        const formData = new FormData()
        const extension = (file.name || DEFAULT_EXTENSION).split('.').pop()

        formData.append('file', file)

        const fileName = btoa(`${guid || FALLBACK_NAME}.${extension}`)

        if (guid) {
            const template = getParametrizedUrl(URL_USER_FILES_SINGLE, { type }, true)
            const url = getUrlFromTemplate({
                template,
                params: {
                    id: guid
                }
            })

            await httpAPI.put(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Docplanner-File': `fileName=${fileName};source=${UPLOAD_SOURCE}`
                }
            })

            return {
                guid
            }
        }

        const { data } = await httpAPI.post(getParametrizedUrl(URL_BASE, { type }), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Docplanner-File': `fileName=${fileName};source=${UPLOAD_SOURCE}`
            }
        })

        return {
            guid: data
        }
    }

    async removeFile(id: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_USER_FILES_SINGLE,
            params: { id }
        })
        await httpAPI.delete(url)
    }
}

export default new UserFiles()
