import { VouchersState } from './types/state'

export const state: VouchersState = {
    isLoading: true,
    isLoadingVoucherTypes: false,
    vouchers: [],
    vouchersFilters: {},
    voucherTypesPaginated: [],
    voucherTypes: [],
    schedules: [],
    page: 1,
    currentPage: 1,
    totalPage: 1,
    showActive: true,
    hasFullyRedeemedVouchers: false
}
