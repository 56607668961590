import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { Conversations, ConversationsResponse, SessionToken } from '../domain/interfaces/conversations.interfaces'

const URL_BASE = 'chat'

const URL_USER_SESSION_TOKEN = `${URL_BASE}/users/current-user/session-token`

const URL_CONVERSATIONS = `${URL_BASE}/conversations`
const URL_CONVERSATIONS_WITH_HOST_ID = `${URL_CONVERSATIONS}/:hostId`
const URL_CONVERSATIONS_COUNT = `${URL_CONVERSATIONS}/count`
const URL_CONVERSATIONS_UNREAD_COUNT = `${URL_CONVERSATIONS}/unread/count`
const URL_CONVERSATIONS_UNREAD_COUNT_WITH_HOST_ID = `${URL_CONVERSATIONS}/unread/count/:hostId`

class ConversationRepository {
    async getConversationsList(
        type: string,
        unread: string,
        limit: number,
        token: string,
        hostId?: number
    ): Promise<ConversationsResponse<Conversations>> {
        const url = getUrlFromTemplate({
            template: hostId ? URL_CONVERSATIONS_WITH_HOST_ID : URL_CONVERSATIONS,
            query: {
                type,
                unreadOnly: unread,
                limit,
                nextToken: token,
                withPatientDataOnly: 'false'
            },
            params: hostId ? { hostId } : undefined
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getNumberOfConversations(type: number): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATIONS_COUNT,
            query: {
                type
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    // TODO remove once we migrate patient chat to getUnreadConversations
    async getNumberOfUnreadConversations(type: number, hostId?: string): Promise<number> {
        const url = getUrlFromTemplate({
            template: hostId ? URL_CONVERSATIONS_UNREAD_COUNT_WITH_HOST_ID : URL_CONVERSATIONS_UNREAD_COUNT,
            query: {
                type
            },
            params: hostId ? { hostId } : undefined
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getUserSessionToken(): Promise<SessionToken> {
        const url = getUrlFromTemplate({
            template: URL_USER_SESSION_TOKEN
        })

        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new ConversationRepository()
