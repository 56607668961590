import { CalendarPerformanceMeasure, CalendarPerformanceSurveyState } from './state'

export enum MutationTypes {
    AddPerformanceMeasure = 'addPerformanceMeasure',
    FinishMeasurementPeriod = 'finishMeasurementPeriod',
    ReplacePerformanceMeasures = 'replacePerformanceMeasures',
    StartMeasurementPeriod = 'startMeasurementPeriod'
}

export type CalendarPerformanceSurveyMutations = {
    [MutationTypes.StartMeasurementPeriod](state: CalendarPerformanceSurveyState): void
    [MutationTypes.FinishMeasurementPeriod](state: CalendarPerformanceSurveyState): void
    [MutationTypes.AddPerformanceMeasure](
        state: CalendarPerformanceSurveyState,
        newMeasure: CalendarPerformanceMeasure
    ): void
    [MutationTypes.ReplacePerformanceMeasures](
        state: CalendarPerformanceSurveyState,
        measures: CalendarPerformanceMeasure[]
    ): void
}
