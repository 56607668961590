import { cloneDeep } from 'lodash'

import { CountryCodes } from '@/core/domain/enums/country.code.enum'
import { DateUnit, timeDifferent } from '@/core/ui/utils'
import CalendarAppointmentsRepository from '@/features/calendar/api/calendar.appointments.api'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { COLOR_TRANSPARENT } from '@/features/calendar/domain/constants/colorSchemas.constants'
import { AppointmentPatientsNotificationTypes } from '@/features/calendar/domain/enums/appointment.enums'
import { CalendarEventDisplayType, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { EventStatus } from '@/features/calendar/domain/enums/eventStatus.enums'
import { EventType } from '@/features/calendar/domain/enums/eventType.enums'
import {
    AppointmentDTO,
    AppointmentEvent,
    AppointmentInvoice,
    AppointmentScheduleProperties,
    CancellationEvent,
    getEventServicesDurationByDatesParams,
    TodayAppointmentsDTO,
    VisitPaymentMethodInput,
    VisitPriceInput,
    VisitRemovePaymentMethodInput,
    VisitUpdatePaymentMethodsInput
} from '@/features/calendar/domain/interfaces/appointment.interfaces'
import { addRoomPrefixToId } from '@/features/rooms/domain/utils/roomProperty.utils'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'
import { EventService } from '@/features/servicesLegacy/domain/interfaces/services.interfaces'
import { isArray } from '@/utils/array'
import { nameCapitalization, upperCaseFirstCharacter } from '@/utils/string'

import { ScheduledBy } from './enums/scheduledBy.enums'
import { Appointment, ColorSchema } from './interfaces/calendar.interfaces'

class CalendarAppointmentsService {
    private getTitle(event: AppointmentDTO): string | null {
        const patientName = event.patient
            ? `${event.patient.firstName || ''} ${event.patient.lastName || ''}`
            : event.title

        return patientName ? this.nameCapitalization(patientName) : null
    }

    private nameCapitalization(string: string): string {
        return nameCapitalization(string?.toLocaleLowerCase())
    }

    private upperCaseFirstCharacter(string: string): string {
        return upperCaseFirstCharacter(string?.toLocaleLowerCase())
    }

    private isEventType(event: AppointmentDTO): boolean {
        return event.eventType <= EventType.Unavailable
    }

    private getEmail(event: AppointmentDTO): string | null {
        return event.patientEmail || event.patient?.email || null
    }

    private getPhone(event: AppointmentDTO): string | null {
        return event.patientPhone || event.patient?.phone || null
    }

    private canNotifyPatient(event: AppointmentDTO) {
        const email = this.getEmail(event)
        const phone = this.getPhone(event)
        return (email && email.length > 0) || (phone && phone.length > 0) || false
    }

    private getFormattedAppointmentDatas(
        appointment: AppointmentDTO,
        shouldUppercaseFirstChar: boolean
    ): {
        comments: string
        eventServices: EventService[]
        roomName: string
        serviceName: string
    } {
        const service = appointment.service || null
        const serviceName = service?.name || appointment.serviceName || ''
        const roomName = appointment?.roomName || ''
        const eventServices = appointment.eventServices || []
        const comments = appointment?.comments || ''

        return {
            serviceName: shouldUppercaseFirstChar ? this.upperCaseFirstCharacter(serviceName) : serviceName,
            roomName: shouldUppercaseFirstChar ? this.upperCaseFirstCharacter(roomName) : roomName,
            eventServices: shouldUppercaseFirstChar ? this.normalizeEventServices(eventServices) : eventServices,
            comments: shouldUppercaseFirstChar ? this.upperCaseFirstCharacter(comments) : comments
        }
    }

    private getAppointmentServiceColorSchemaId(appointment: AppointmentDTO): number | null {
        const service = appointment.service || null

        return service?.colorSchema?.id || service?.colorSchemaId || appointment.serviceColorSchemaId
    }

    private getAppointmentScheduleInfos(
        appointment: AppointmentDTO,
        schedules?: Record<number, Schedule>,
        colorSchemas?: Record<number, ColorSchema>
    ) {
        const schedule =
            appointment.schedule || (appointment.scheduleId && schedules ? schedules[appointment.scheduleId] : null)
        const scheduleColorSchema =
            appointment.schedule?.colorSchemas ||
            (schedule && colorSchemas ? colorSchemas[schedule.colorSchemas?.id || schedule.colorSchemaId] : null)

        return {
            schedule,
            scheduleColorSchema
        }
    }

    processAppointment(
        appointment: AppointmentDTO,
        countryCode: string,
        colorSchemas?: Record<number, ColorSchema>,
        schedules?: Record<number, Schedule>
    ): AppointmentEvent[] | AppointmentEvent {
        const shouldUppercaseFirstChar = countryCode !== CountryCodes.DE
        const { schedule, scheduleColorSchema } = this.getAppointmentScheduleInfos(appointment, schedules, colorSchemas)

        const mappedAppointment: AppointmentEvent = {
            ...appointment,
            displayDefault: CalendarEventDisplayType.Auto,
            title: this.getTitle(appointment),
            className: [`event-id-${appointment.id}`],
            colorSchemas: scheduleColorSchema,
            color: COLOR_TRANSPARENT,
            groupId: `group-${appointment.id}`,
            serviceColorSchemaId: this.getAppointmentServiceColorSchemaId(appointment),
            type: CalendarEventType.Appointment,
            ...CalendarEventService.processStartEndToDate(appointment),
            ...this.getFormattedAppointmentDatas(appointment, shouldUppercaseFirstChar),
            ...this.patientRelatedAppointmentProps(appointment),
            ...this.cancelledRelatedAppointmentProps(appointment.status),
            ...this.appointmentScheduleProps(appointment, schedule)
        }

        return mappedAppointment.roomId
            ? this.createRoomAppointment(mappedAppointment, colorSchemas)
            : mappedAppointment
    }

    private appointmentScheduleProps(event: AppointmentDTO, schedule: Schedule | null): AppointmentScheduleProperties {
        return {
            scheduleName: schedule?.name || null,
            scheduleType: schedule?.scheduleType ?? null,
            hasWaitingRoom: event.hasWaitingRoom || !!(schedule?.hasWaitingRoom && this.isEventType(event)),
            hasIntegratedError: schedule?.integrated?.hasError,
            resourceId: schedule?.id,
            facilityId: schedule ? schedule.facilityId : null,
            specialityId: schedule ? schedule.specialityId || schedule.doctor?.specialityId || null : null,
            specialityName: schedule ? schedule.specialityName || schedule.doctor?.specialityName || null : null,
            isVirtual: schedule?.isVirtual,
            patientsNotificationType:
                schedule?.patientsNotificationType || AppointmentPatientsNotificationTypes.OnlyReminder
        }
    }

    private cancelledRelatedAppointmentProps(status: EventStatus) {
        const isCancelled = [
            EventStatus.CanceledByUser,
            EventStatus.CanceledByPatient,
            EventStatus.CanceledByTechnicalIssue
        ].includes(status)
        return {
            isCancelled,
            editable: !isCancelled,
            startEditable: !isCancelled,
            durationEditable: !isCancelled,
            resourceEditable: !isCancelled
        }
    }

    private patientRelatedAppointmentProps(appointment: AppointmentDTO) {
        return {
            hasPatient: appointment.patient !== null,
            patientArrivalTime: appointment.patientArrivalTime || null,
            canNotifyPatient:
                typeof appointment.canNotifyPatient === 'undefined'
                    ? this.canNotifyPatient(appointment)
                    : appointment.canNotifyPatient,
            patientId: appointment.patientId,
            patientPhone: this.getPhone(appointment),
            patientEmail: this.getEmail(appointment),
            insuranceName: appointment.insuranceName || appointment?.patient?.insurance?.name || null,
            hasVoucher: appointment.isEventWithVoucher
        }
    }

    private createRoomAppointment(
        appointment: AppointmentEvent,
        colorSchemas: Record<number, ColorSchema> = {}
    ): AppointmentEvent[] {
        const roomColorSchema = appointment.roomColorSchemas || colorSchemas[appointment.roomColorSchemaId!] || null
        const roomAppointment = cloneDeep(appointment)
        roomAppointment.id = addRoomPrefixToId(appointment.id)
        roomAppointment.resourceId = addRoomPrefixToId(appointment.roomId)
        roomAppointment.roomColorSchemas = roomColorSchema
        roomAppointment.colorSchemas = roomColorSchema
        roomAppointment.isRoomEvent = true
        return [roomAppointment, appointment]
    }

    processAppointmentAndReturnAnArray(
        appointment: AppointmentDTO,
        colorSchemas?: Record<number, ColorSchema>,
        schedules?: Record<number, Schedule>
    ): AppointmentEvent[] {
        const processedAppointment: AppointmentEvent[] | AppointmentEvent = this.processAppointment(
            appointment,
            appointment.countryCode,
            colorSchemas,
            schedules
        )

        return (isArray(processedAppointment) ? processedAppointment : [processedAppointment]) as AppointmentEvent[]
    }

    getTodayAppointments(selectedDate: Date): Promise<TodayAppointmentsDTO[]> {
        return CalendarAppointmentsRepository.getTodayAppointments(selectedDate)
    }

    async getAppointment(appointmentId: number): Promise<AppointmentDTO> {
        const data = await CalendarAppointmentsRepository.getCalendarAppointment(appointmentId)

        if (data.insurance) {
            const { insurance, ...rest } = data
            return {
                ...rest,
                insuranceId: insurance.id,
                insuranceName: insurance.name
            }
        }

        return { ...data, insuranceId: null, insuranceName: null }
    }

    wasScheduledByPatient(event: Appointment) {
        return event.scheduledBy !== ScheduledBy.Doctor
    }

    moveAppointment(
        event: AppointmentEvent,
        notifyPatient = false,
        sendAdvanceAppointment = false,
        recurrentOperationMode = null
    ): Promise<AppointmentDTO> {
        return CalendarAppointmentsRepository.moveVisitEvent(
            event,
            notifyPatient,
            sendAdvanceAppointment,
            recurrentOperationMode
        )
    }

    updateVisitDuration(event: AppointmentEvent, recurrentOperationMode = null): Promise<AppointmentDTO> {
        return CalendarAppointmentsRepository.updateVisitDuration(event, recurrentOperationMode)
    }

    updateVisitPrice({ visitId, priceValue }: VisitPriceInput): Promise<AppointmentDTO> {
        return CalendarAppointmentsRepository.updateVisitPrice({
            visitId,
            priceValue
        })
    }

    updateVisitPaymentMethod({ visitId, paymentMethod }: VisitPaymentMethodInput): Promise<AppointmentDTO> {
        return CalendarAppointmentsRepository.updateVisitPaymentMethod({
            visitId,
            paymentMethod
        })
    }

    removeVisitPaymentMethod({ visitId }: VisitRemovePaymentMethodInput): Promise<void> {
        return CalendarAppointmentsRepository.removeVisitPaymentMethod({
            visitId
        })
    }

    updateVisitsAsPaid({ visitIds, paymentMethodId }: VisitUpdatePaymentMethodsInput): Promise<void> {
        return CalendarAppointmentsRepository.updateVisitsAsPaid({
            visitIds,
            paymentMethodId
        })
    }

    async getAppointmentSummary(appointmentId: number): Promise<AppointmentDTO> {
        return CalendarAppointmentsRepository.getAppointmentSummary(appointmentId)
    }

    async getAppointmentInvoices(appointmentId: number): Promise<AppointmentInvoice[]> {
        return CalendarAppointmentsRepository.getAppointmentInvoices(appointmentId)
    }

    normalizeEventServices(eventServices: EventService[]): EventService[] {
        return eventServices.map(({ serviceName, ...service }) => ({
            ...service,
            serviceName: this.upperCaseFirstCharacter(serviceName)
        }))
    }

    getEventServicesDurationByDates({ eventServices, dateStart, dateEnd }: getEventServicesDurationByDatesParams) {
        const eventTotalDuration = timeDifferent(new Date(dateStart), new Date(dateEnd), DateUnit.Minute)
        const eventServicesTotalDuration = eventServices.reduce((total, { duration }) => total + duration, 0)
        let resizeDuration = eventTotalDuration - eventServicesTotalDuration

        return eventServices.map(service => {
            let serviceDuration = service.duration

            if (resizeDuration > 0) {
                serviceDuration += resizeDuration
                resizeDuration = 0
            }

            if (resizeDuration < 0) {
                const absoluteResizeDuration = Math.abs(resizeDuration)
                const isServiceDurationBiggerThanResizeDuration = service.duration > absoluteResizeDuration

                serviceDuration = isServiceDurationBiggerThanResizeDuration
                    ? service.duration - absoluteResizeDuration
                    : 0
                resizeDuration = isServiceDurationBiggerThanResizeDuration ? 0 : resizeDuration + service.duration
            }

            return { ...service, duration: serviceDuration }
        })
    }

    async sendCancellationMessage(ids: number[], reason: number, message: string): Promise<CancellationEvent[]> {
        const { cancellationEvents } = await CalendarAppointmentsRepository.sendCancellationMessage(
            ids,
            reason,
            message
        )
        return cancellationEvents
    }

    isConfirmed(event: AppointmentDTO): boolean {
        return [EventStatus.ConfirmedByPatient, EventStatus.ConfirmedByAdmin].includes(event.status)
    }

    copyAppointment(event: AppointmentEvent, notifyPatient: boolean): Promise<number> {
        return CalendarAppointmentsRepository.copyAppointment(event, notifyPatient)
    }

    async getAppointmentDocumentationData(appointmentId: number): Promise<Record<string, string>> {
        return CalendarAppointmentsRepository.getAppointmentDocumentationData(appointmentId)
    }
}

export default new CalendarAppointmentsService()
