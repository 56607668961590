import { getUrlFromTemplate } from '@dp-vue/utils'

import { get, post } from '@/api/utils/httpClientActivationSystemAPI'

import { SetupGuideTaskDTO } from '../interfaces'

const URL_BASE_SETUPGUIDE = 'setupguide'
const URL_TASK_LIST = `${URL_BASE_SETUPGUIDE}/tasks`
const URL_TASK_COMPLETE = `${URL_BASE_SETUPGUIDE}/tasks/complete/:taskId`

export const getTaskList = async () => {
    const url = getUrlFromTemplate({
        template: URL_TASK_LIST
    })
    return get<SetupGuideTaskDTO[]>(url)
}

export const setTaskCompleted = async (taskId: string) => {
    const url = getUrlFromTemplate({
        template: URL_TASK_COMPLETE,
        params: { taskId }
    })
    return post<boolean, string>(url)
}
