import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'
import { finishAndTrackMeasure, startMeasure } from '@/core/ui/plugins/utils/monitoring'
import { SET_COUNTRY_SETTINGS } from '@/store/mutation-types'

const measureIds = {
    measureName: 'MW_countrySettings',
    startMark: 'MW_countrySettings__start',
    endMark: 'MW_countrySettings__end'
}

export default async function countrySettings({ router, store, next, to, from }) {
    startMeasure(measureIds.startMark)

    const { culture } = store.getters

    if (!store.getters.authProvider) {
        try {
            await store.dispatch('FETCH_AUTH_PROVIDERS')
        } catch (error) {
            return next({
                name: 'error',
                params: {
                    error: error.response
                }
            })
        }
    }

    if (
        !(store.state.authProvider && culture && store.getters.countrySettings) ||
        culture !== store.getters.countrySettings.id
    ) {
        let common = null
        let country = null

        try {
            const { data } = await store.dispatch('FETCH_COUNTRY_SETTINGS')
            common = data
        } catch (error) {
            return next({
                name: 'error',
                params: {
                    error: error.response
                }
            })
        }

        try {
            const { data } = await store.dispatch('FETCH_COUNTRY_SETTINGS', store.getters.culture)
            country = data
        } catch (error) {
            const isStatus0 = error.request.status === 0
            const prefix = isStatus0 ? '[Status 0] ' : ''

            monitor.send(`${prefix}Settings for a culture can't be found. Default settings will be used instead.`, {
                level: SeverityLevels.Info,
                extra: {
                    culture: store.getters.culture,
                    status: error.request.status,
                    readyState: error.request.readyState
                }
            })
        }

        store.commit(SET_COUNTRY_SETTINGS, {
            ...common,
            ...country
        })
    }

    finishAndTrackMeasure(router.app.$featureToggle, measureIds)

    next()
}
