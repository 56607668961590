import { AxiosError } from 'axios'

import { PatientCanReplyToChat } from '@/features/chat/domain/interfaces/conversations.interfaces'
import repository from '@/features/settings/api/messages.api'
import {
    AUTOMATIC_MESSAGES,
    CUSTOM_TYPE,
    QUICK_REMINDER,
    TEMPLATES_CONTENT
} from '@/features/settings/ui/constants/templates.constants'
import { OutOfOfficeEvent } from '@/features/settings/ui/interfaces/disableNotifications.interfaces'
import { MultipleSchedule, Schedule } from '@/features/settings/ui/interfaces/doNotDisturbContent.interfaces'

import { DoNotDisturbEntity, OutOfOfficeEventEntity } from './interfaces/messages.entity'
import { ChatTemplate } from './interfaces/messages.interfaces'

class MessagesService {
    async getWelcomeMessageEnabled(): Promise<boolean> {
        try {
            return await repository.getWelcomeMessageEnabled()
        } catch {
            return false
        }
    }

    async updateWelcomeMessageEnabled(isEnabled: boolean): Promise<boolean> {
        try {
            return await repository.updateWelcomeMessageEnabled(isEnabled)
        } catch {
            return false
        }
    }

    async getProfileChatEnable(): Promise<boolean> {
        try {
            return await repository.getProfileChatEnable()
        } catch {
            return false
        }
    }

    async updateProfileChatEnable(isEnabled: boolean): Promise<boolean> {
        try {
            await repository.updateProfileChatEnable(isEnabled)
            return isEnabled
        } catch (error) {
            return !isEnabled
        }
    }

    async getReplyTime(id: number): Promise<number | null | undefined> {
        try {
            const { avgChatReplyRateMs: replyTime } = (await repository.getReplyTime(id)) || {}
            return replyTime
        } catch (error) {
            const err = error as AxiosError<{ message: string }>
            if (err?.response?.status === 404 && err?.response?.data?.message) {
                return null
            }
        }
    }

    async getTemplates(): Promise<ChatTemplate[]> {
        try {
            return await repository.getTemplates()
        } catch (error) {
            return []
        }
    }

    async addTemplate(template: any): Promise<boolean> {
        try {
            await repository.addTemplate(template)
            return true
        } catch (error) {
            return false
        }
    }

    async updateTemplate(id: number, template: any): Promise<boolean> {
        try {
            await repository.updateTemplate(id, template)
            return true
        } catch (error) {
            return false
        }
    }

    async deleteTemplate(id: number): Promise<boolean> {
        try {
            await repository.deleteTemplate(id)
            return true
        } catch (error) {
            return false
        }
    }

    getTemplatesByType(templateType: string, templates: ChatTemplate[]) {
        return (
            templates?.filter(template => {
                const { type, viewName } = template
                return (
                    `${type}`[0] === templateType &&
                    ((viewName && TEMPLATES_CONTENT[viewName]) || `${type}` === CUSTOM_TYPE)
                )
            }) ?? []
        )
    }

    getYourTemplates(templates: ChatTemplate[]) {
        return this.getTemplatesByType(CUSTOM_TYPE, templates)
    }

    getQuickReminders(templates: ChatTemplate[]) {
        return this.getTemplatesByType(QUICK_REMINDER, templates)
    }

    getAutomaticTemplates(templates: ChatTemplate[]) {
        return this.getTemplatesByType(AUTOMATIC_MESSAGES, templates)
    }

    // ========================
    // ON HOLIDAY NOTIFICATIONS
    // ========================

    getOutOfOfficeSilencedNotificationSetting(): Promise<OutOfOfficeEventEntity | null> {
        return repository.getOutOfOfficeSilencedNotificationSetting()
    }

    createOutOfOfficeEvent(event: OutOfOfficeEvent): Promise<OutOfOfficeEventEntity | null> {
        return repository.createOutOfOfficeEvent(event)
    }

    updateExistingOutOfOfficeEvent(event: OutOfOfficeEvent): Promise<OutOfOfficeEventEntity | null> {
        return repository.updateExistingOutOfOfficeEvent(event)
    }

    deleteExistingOutOfOfficeEvent(eventId: number): Promise<boolean> {
        return repository.deleteExistingOutOfOfficeEvent(eventId)
    }

    // ================
    // DO_NOT_DISTURB
    // ================

    getNotificationSchedule(): Promise<DoNotDisturbEntity> {
        return repository.getNotificationSchedule()
    }

    isScheduleAlwaysOn(): Promise<boolean> {
        return repository.isScheduleAlwaysOn()
    }

    createSchedule(schedule: Schedule | MultipleSchedule): Promise<DoNotDisturbEntity> {
        return repository.createSchedule(schedule)
    }

    deleteSchedule(): Promise<boolean> {
        return repository.deleteSchedule()
    }

    // ================
    // BLOCK INPUT CHAT TO PATIENT
    // ================

    getBlockPatientChat(docplannerDoctorId: string): Promise<PatientCanReplyToChat> {
        return repository.getBlockPatientChat(docplannerDoctorId)
    }

    updateBlockPatientChat(docplannerDoctorId: string, enableBlockToAllPatients: boolean): Promise<void> {
        return repository.updateBlockPatientChat(docplannerDoctorId, enableBlockToAllPatients)
    }
}

export default new MessagesService()
