import { createAgent } from '@/api/utils/agent'
import HttpClient from '@/api/utils/HttpClient'
import { apiUrl } from '@/utils/url'

const httpClientActivationSystemAPI = new HttpClient(
    {
        baseURL: `${apiUrl}/activationsystem/api`
    },
    {
        emitEventOnError: false
    }
)

export const { get, post, remove, put, patch } = createAgent(httpClientActivationSystemAPI.instance)

export default httpClientActivationSystemAPI
