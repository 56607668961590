import { parse, stringify } from 'query-string'
import UrlParse from 'url-parse'

import configManager from '@/bootstrap/configurationManager'

const { origin } = new UrlParse()

const redirectUri = origin
const apiUrl = configManager.getValue('API_URL')
const rtnUrl = configManager.getValue('RTN_URL')
const dwhUrl = configManager.getValue('DWH_API_URL')
const eventTrackerUrl = configManager.getValue('EVENT_TRACKER_URL')
const apiMkplUrl = configManager.getValue('MKPL_API_URL')
const apiDocumentationUrl = configManager.getValue('DOCUMENTATION_TEMPLATES_API_URL')
const apiFileImportationUrl = configManager.getValue('FILE_IMPORTATION_API_URL')
const apiActivationOnboardingUrl = configManager.getValue('ACTIVATION_ONBOARDING_API_URL')
const apiPatientRequestUrl = configManager.getValue('PATIENT_REQUEST_API_URL')
const apiPackageUrl = configManager.getValue('PACKAGE_API_URL')

const getQueryParamsFromLocationSearch = () => {
    const search = location.search.split('?')[1]

    return parse(search)
}

const removeQueryParams = (paramsToRemoveFromLocation, shouldRemoveParamsFromHash = false) => {
    const parsed = parse(location.search)
    const { hash } = location
    const hashString = hash?.split('#')?.[1] || ''
    let stringifiedHash = ''

    if (shouldRemoveParamsFromHash && hashString) {
        const splitHashArr = hashString.split('?') || []
        const hashPath = splitHashArr?.[0] || ''
        const hashParams = splitHashArr?.[1] || ''
        const parsedHash = parse(hashParams)
        paramsToRemoveFromLocation.forEach(param => delete parsedHash[param])
        stringifiedHash = stringify(parsedHash)
        stringifiedHash = `#${hashPath}${stringifiedHash ? `?${stringifiedHash}` : ''}`
    }

    paramsToRemoveFromLocation.forEach(param => delete parsed[param])

    const stringified = stringify(parsed)
    history.replaceState(
        {},
        document.title,
        location.pathname + (stringifiedHash || hash) + (stringified ? `?${stringified}` : '')
    )
}

const getDecodedUrl = url => {
    // eslint-disable-next-line
    const isUrlEncodedRegExp = new RegExp(/%[0-9a-f]{2}/, 'i')

    if (isUrlEncodedRegExp.test(url)) {
        return decodeURIComponent(url)
    }

    return url
}

const isAbsolutePath = path => {
    const absolutePathRegExp =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

    return absolutePathRegExp.test(getDecodedUrl(path))
}

const getParametrizedUrl = (url, params, beforeRegex = false) => {
    const searchParams = new URLSearchParams(params)

    const queryStringToken = beforeRegex ? '\\?' : '?'

    return searchParams ? `${url}${queryStringToken}${searchParams}` : url
}

const extractQueryStringParams = querystring => {
    const urlSearchParams = new URLSearchParams(querystring)
    return Object.fromEntries(urlSearchParams.entries())
}

export default apiUrl

export {
    apiActivationOnboardingUrl,
    apiDocumentationUrl,
    apiFileImportationUrl,
    apiMkplUrl,
    apiPackageUrl,
    apiPatientRequestUrl,
    apiUrl,
    dwhUrl,
    eventTrackerUrl,
    extractQueryStringParams,
    getDecodedUrl,
    getParametrizedUrl,
    getQueryParamsFromLocationSearch,
    isAbsolutePath,
    origin,
    redirectUri,
    removeQueryParams,
    rtnUrl
}
