import { h } from 'vue'

import { SETTINGS_PATHS, SETTINGS_ROUTES } from '../constants/router.constants'

const SchedulesList = () =>
    import(/* webpackChunkName: "schedules-list" */ '@/components/Settings/Schedules/SchedulesList.vue')
const SchedulesListWatson = () =>
    import(/* webpackChunkName: "schedules-list-watson" */ '@/features/schedule/ui/views/Schedules.vue')
const ScheduleItem = () =>
    import(/* webpackChunkName: "schedules-item" */ '@/components/Settings/Schedules/ScheduleItem.vue')
const CalendarsSchedule = () =>
    import(/* webpackChunkName: "calendars-schedule" */ '../views/calendars/CalendarsSchedule.vue')
const PaymentsSchedule = () =>
    import(/* webpackChunkName: "calendars-schedule" */ '@/features/payments/ui/views/PaymentsSchedule.vue')
const ScheduleNotificationsSettings = () =>
    import(
        /* webpackChunkName: "schedules-notifications-settings" */ '../views/calendars/ScheduleNotificationsSettings.vue'
    )
const DailyReport = () => import(/* webpackChunkName: "schedules-work-periods" */ '../views/schedule/DailyReport.vue')
const ScheduleServicesSettings = () =>
    import(
        /* webpackChunkName: "schedule-services-settings" */ '@/features/settings/ui/views/services/ScheduleServicesSettings.vue'
    )
const ScheduleServicesConfiguration = () =>
    import(
        /* webpackChunkName: "schedule-services-configuration" */ '@/features/service/ui/components/settings/ScheduleServicesConfiguration.vue'
    )
const ScheduleOnlineBooking = () =>
    import(
        /* webpackChunkName: "schedule-online-booking" */ '@/features/settings/ui/views/schedule/ScheduleOnlineBooking.vue'
    )
const ScheduleInfo = () => import(/* webpackChunkName: "schedules-work-periods" */ '../views/schedule/ScheduleInfo.vue')

const OnlineConsultationSchedule = () =>
    import(
        /* webpackChunkName: "online-consultation-settings" */ '../views/onlineConsultation/OnlineConsultationSettings.vue'
    )

export default [
    {
        name: SETTINGS_ROUTES.routes.schedules.home,
        path: SETTINGS_PATHS.schedules.home,
        component: {
            render(this: any, c: any): ReturnType<typeof h> {
                return c(
                    this.$root.$featureToggle.ensure('SaaSSchedulesListsWatson', true)
                        ? SchedulesListWatson
                        : SchedulesList
                )
            }
        },
        meta: { title: 'agendas' }
    },
    {
        name: SETTINGS_ROUTES.routes.schedules.schedule,
        path: SETTINGS_PATHS.schedules.schedule,
        component: ScheduleItem,
        children: [
            {
                name: SETTINGS_ROUTES.routes.schedules.payments,
                path: SETTINGS_PATHS.schedules.payments,
                component: PaymentsSchedule
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.videoConsultation,
                path: SETTINGS_PATHS.schedules.videoConsultations,
                component: OnlineConsultationSchedule
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.workTimePeriods,
                path: SETTINGS_PATHS.schedules.workTimePeriods,
                component: CalendarsSchedule
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.services,
                path: SETTINGS_PATHS.schedules.services,
                component: ScheduleServicesSettings
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.servicesConfiguration,
                path: SETTINGS_PATHS.schedules.servicesConfiguration,
                component: ScheduleServicesConfiguration
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.onlineBooking,
                path: SETTINGS_PATHS.schedules.onlineBooking,
                component: ScheduleOnlineBooking
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.notifications,
                path: SETTINGS_PATHS.schedules.notifications,
                component: ScheduleNotificationsSettings
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.dailyReport,
                path: SETTINGS_PATHS.schedules.dailyReport,
                component: DailyReport
            },
            {
                name: SETTINGS_ROUTES.routes.schedules.info,
                path: SETTINGS_PATHS.schedules.info,
                component: ScheduleInfo
            }
        ]
    }
]
