import * as i18n from '@/utils/i18n'

export const $t = (key: string, translationsVariables?: Record<string, string | number>) => {
    return i18n.$t(key, translationsVariables).toString()
}

export const $tc = (key: string, count: number, translationVariables?: Record<string, string | number>) => {
    return i18n.$tc(key, count, translationVariables).toString()
}

export const $te = (key: string, locale?: string) => {
    return i18n.$te(key, locale)
}
