import repository from '@/features/settings/api/printLayouts.api'
import userFilesRepository from '@/features/settings/api/userFiles.api'
import {
    AdditionalData,
    GetDefaultLayoutProps,
    GetPrintLayout,
    KeyValueMap,
    PrintLayout,
    PrintLayoutField,
    PrintLayoutOptionValues,
    PrintLayoutStructure,
    SavePrintLayout
} from '@/features/settings/domain/interfaces/printLayouts.interfaces'
import { TYPE_LAYOUT } from '@/features/settings/ui/constants/printLayout.constants'
import { parseJSON } from '@/utils/parse-json'

class PrintLayoutService {
    getPrintLayouts(): Promise<PrintLayout[]> {
        return repository.getPrintLayouts()
    }

    async getPrintLayout({ id }: GetPrintLayout): Promise<PrintLayout> {
        const data = await repository.getPrintLayout({ id })

        const options = parseJSON(data.jsonOptions, {})

        return {
            ...data,
            options
        }
    }

    async savePrintLayout({ options, id, logosToUpload }: SavePrintLayout): Promise<PrintLayout> {
        const updatedOptions = {
            ...options
        }

        if (logosToUpload?.logo) {
            const { file, fileGuid, logoIsLandscape } = logosToUpload.logo
            const { guid } = await userFilesRepository.uploadFile(file, fileGuid)

            if (!fileGuid && guid) {
                updatedOptions.layout.header.logo = guid
            }
            updatedOptions.layout.header.logoIsLandscape = logoIsLandscape
        }

        return repository.savePrintLayout({
            options: updatedOptions,
            id
        })
    }

    getDefaultLayoutForEpisode({ episodeId, patientId }: GetDefaultLayoutProps): Promise<boolean> {
        return repository.getDefaultLayoutForEpisode({
            episodeId,
            patientId
        })
    }

    getOnlyValuesFromAdditionalData(additionalData: AdditionalData[], dataKeys: KeyValueMap): string[] {
        return additionalData.reduce(
            (output: string[], item) => (dataKeys[item.id] ? [...output, item.value] : output),
            []
        )
    }

    buildDefaultStructure({ data, layout }: PrintLayoutStructure): PrintLayoutStructure {
        const clinicAdditionalData = data?.clinicAdditionalData || []
        const doctorAdditionalData = data?.doctorAdditionalData || []
        const clinicCustomData = data?.clinicCustomData || {}
        const doctorCustomData = data?.doctorCustomData || {}

        return {
            layout: {
                header: {
                    ...(layout?.header || {})
                },
                body: {
                    ...(layout?.body || {})
                },
                footer: {
                    ...(layout?.footer || {})
                }
            },
            data: {
                patient: {
                    ...(data?.patient || {})
                },
                doctor: {
                    ...(data?.doctor || {})
                },
                clinic: {
                    ...(data?.clinic || {})
                },
                clinicCustomData,
                doctorCustomData,
                clinicAdditionalData,
                doctorAdditionalData
            },
            computed: {
                clinicAdditionalData: this.getOnlyValuesFromAdditionalData(clinicAdditionalData, data?.clinic || {}),
                doctorAdditionalData: this.getOnlyValuesFromAdditionalData(doctorAdditionalData, data?.doctor || {})
            }
        }
    }

    handleFieldLogic(field: PrintLayoutField, scopeId: string, options: PrintLayoutOptionValues): boolean {
        if (!field.if) {
            return true
        }

        return Object.entries(field.if).every(([key, expected]) => {
            const [typeId, keyScopeId, fieldId] = key.split('.')

            if (typeId === TYPE_LAYOUT && keyScopeId === scopeId) {
                return options[fieldId] === expected
            }

            return false
        })
    }
}

export default new PrintLayoutService()
