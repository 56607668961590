import httpAPI from '@/api/utils/httpClientAPI'
import { Insurance } from '@/features/insurance/domain/interfaces/insurance.interfaces'

const URL_BASE = 'insurances'

// NOTE: it may not belong to "patient" feature. It will be moved if a "insurance" feature emerges.
class InsuranceRepository {
    async getAllInsurances(): Promise<Insurance[]> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }
}

export default new InsuranceRepository()
