import httpAPI from '@/api/utils/httpClientAPI'
import { Staff } from '@/features/staff/domain/interfaces/staff.interface'

const URL_BASE = 'schedules'
const URL_STAFF = `${URL_BASE}/staff`

class StaffRepository {
    async getStaff(): Promise<Staff[]> {
        const { data } = await httpAPI.get<Staff[]>(URL_STAFF)
        return data
    }
}

export default new StaffRepository()
