import repository from '../api/appointment.api'
import { ReportsDataPage } from './interfaces/reports.interfaces'

class AppointmentService {
    updateBookingStatus(appointmentId: string, bookingStatusId: string): Promise<ReportsDataPage> {
        return repository.updateBookingStatus(appointmentId, bookingStatusId)
    }

    updateService(appointmentId: string, serviceId: string): Promise<ReportsDataPage> {
        return repository.updateService(appointmentId, serviceId)
    }

    updatePrice(appointmentId: string, price: string): Promise<ReportsDataPage> {
        return repository.updatePrice(appointmentId, price)
    }

    updateInsurance(appointmentId: string, insuranceId: string): Promise<ReportsDataPage> {
        return repository.updateInsurance(appointmentId, insuranceId)
    }

    updateEventServices(appointmentId: number, serviceIds: number[]): Promise<ReportsDataPage> {
        return repository.updateEventServices(
            appointmentId,
            serviceIds.map(serviceId => ({ serviceId }))
        )
    }
}

export default new AppointmentService()
