import { CountryCodes } from '../enums/country.code.enum'

export const LATAM_COUNTRY_CODES = [
    CountryCodes.MX,
    CountryCodes.CO,
    CountryCodes.AR,
    CountryCodes.CL,
    CountryCodes.PE,
    CountryCodes.BR
]
