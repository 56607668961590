import { AuthActionTypes } from '@/features/auth/ui/store/types'
import CalendarZoomService from '@/features/calendar/domain/calendar.zoom.service'
import { ActionsAdaptor } from '@/interfaces'

import { CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY } from '../../constants/calendar.zoom.constants'
import { GETTER_CALENDAR_ZOOM_LEVEL, MODULE_CALENDAR } from '../types'
import { ActionTypes, CalendarZoomActions } from './types/actions'

const actions: ActionsAdaptor<CalendarZoomActions, any, void> = {
    [ActionTypes.CalendarZoomIn]({ dispatch, rootGetters }, resourceDomElementWidth: number) {
        const zoomLevel = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_ZOOM_LEVEL}`]

        dispatch(
            AuthActionTypes.SetUserSettingsByKey,
            {
                key: CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY,
                value: CalendarZoomService.zoomIn(zoomLevel, resourceDomElementWidth)
            },
            { root: true }
        )
    },

    [ActionTypes.CalendarZoomOut]({ dispatch, rootGetters }) {
        const zoomLevel = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_ZOOM_LEVEL}`]

        dispatch(
            AuthActionTypes.SetUserSettingsByKey,
            {
                key: CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY,
                value: CalendarZoomService.zoomOut(zoomLevel)
            },
            { root: true }
        )
    }
}

export default actions
