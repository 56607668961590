import { ChatMutations, MutationTypes } from './types'

export const mutations: ChatMutations = {
    [MutationTypes.SetPatientHasUnread](state, { patientId, hasUnread }) {
        state.patientHasUnread = {
            ...state.patientHasUnread,
            [patientId]: hasUnread
        }
    },
    [MutationTypes.UpdateUnreadConversations](state, { chatType, unreadConversations }) {
        state.unreadConversations[chatType] = unreadConversations
    },
    [MutationTypes.UpdateCurrentUserSessionToken](state, sessionToken) {
        state.sessionToken = sessionToken
    },
    [MutationTypes.SetSaasInternalChatDisclaimer](state, approval) {
        state.isAcceptedSaasInternalChatDisclaimer = approval
    },
    [MutationTypes.SetIsContactFromProfileAllowed](state, isAllowed) {
        state.isContactFromProfileAllowed = isAllowed
    },
    [MutationTypes.SetChatTemplates](state, chatTemplates) {
        state.chatTemplates = chatTemplates
    },
    [MutationTypes.UpdateStatusGlobalMessageBlock](state, shouldActivateBlock) {
        state.isGlobalBlockMessageActive = shouldActivateBlock
    }
}
