import { BrowserTracing, GlobalHandlers } from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'
import { vueRouterInstrumentation } from '@sentry/vue'

export const integrations = (router, apiUrls) => [
    new CaptureConsole({
        levels: []
    }),
    new GlobalHandlers({
        onunhandledrejection: false,
        onerror: false
    }),
    new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
        tracePropagationTargets: apiUrls.map(url => new RegExp(url)),
        traceXHR: false,
        traceFetch: false
    })
]

const openTokErrors = [
    "Cannot read properties of null (reading 'getStats')",
    "Cannot read property 'getStats' of null",
    "null is not an object (evaluating 'e.getStats')",
    "null is not an object (evaluating 'n.session._')"
]

const libsErrorsToIgnore = [...openTokErrors]

export const ignoredHttpErrorMessages = ["invalid-fiscal-number PATCH:'/patients/x'"]

export const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    "Cannot read property '_avast_submit' of undefined",
    'Request failed with status code 403',
    'Request failed with status code 405',
    'Request failed with status code 401',
    'No configuration value found for an environment variable',
    ...libsErrorsToIgnore
]
