/* eslint-disable import/extensions */
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { getCookie, setCookie } from '@/core/ui/utils/cookies.utils.ts'

import { MKPL_COOKIE, SAAS_COOKIE, TOKEN_TYPE } from '../constants/auth.constants.ts'
/* eslint-enable import/extensions */

const setTokenType = token => {
    if (token.includes(TOKEN_TYPE)) {
        return token
    }

    if (token.split(' ').length > 1) {
        return `${TOKEN_TYPE} ${token.split(' ')[1]}`
    }

    return `${TOKEN_TYPE} ${token}`
}

const setToken = (cookieName, token, expires) => {
    if (!token) {
        throw Error(`token cannot be empty`)
    }

    const expiresInDays = expires ? expires / 60 / 60 / 24 : undefined

    const tokenWithType = setTokenType(token).trimStart()
    setCookie(cookieName, tokenWithType, expiresInDays)
}

export const getMKPLToken = () => getCookie(MKPL_COOKIE)
export const getSAASToken = () => {
    if (ensureToggleIsEnabled('UseSSOTokenOnSaaS', true)) {
        return getMKPLToken()
    }

    return getCookie(SAAS_COOKIE)
}
export const setSAASToken = (token, expires) => {
    setToken(SAAS_COOKIE, token, expires)
}
export const setMKPLToken = (token, expires) => {
    setToken(MKPL_COOKIE, token, expires)
}

/**
 *
 * @returns {string}
 */
export const getMKPLTokenWithoutType = () => {
    const token = getMKPLToken()

    if (token && token.includes(TOKEN_TYPE)) {
        return token.replace(TOKEN_TYPE, '').trim()
    }

    return token
}

/**
 *
 * @returns {string}
 */
export const getSAASTokenWithoutType = () => {
    const token = getSAASToken()

    return (token || '').split(' ').pop().trim() || token
}

export function isAuthenticated() {
    return !!getSAASToken()
}
