import vatNumberService from '@/core/domain/vatNumber.service'

export async function isVatNumberValid(value: string): Promise<boolean> {
    try {
        await vatNumberService.validateVatNumber(value)
        return true
    } catch {
        return false
    }
}
