const InvoicingSettings = () =>
    import(/* webpackChunkName: "invoicing-settings" */ '@/components/Settings/Invoicing/InvoicingSettings.vue')

export default [
    {
        path: 'settings-invoicing',
        name: 'settings-invoicing',
        component: InvoicingSettings
    }
]
