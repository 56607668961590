import uninav from '@/features/navigation/ui/router/uninav.middleware'

const Holidays = () => import(/* webpackChunkName: "holidays-list" */ '../views/Holidays.vue')

export default {
    path: 'holidays/list',
    name: 'holidays',
    component: Holidays,
    meta: {
        middleware: [uninav],
        uninavName: 'calendar',
        title: 'bloqueos-y-vacaciones'
    }
}
