import { ensureToggleIsEnabled } from './utils/ensureToggleIsEnabled'
import { getActualToggles } from './utils/getActualToggles'
import { getQueryStringFromUrl } from './utils/getQueryStringFromUrl'

const actualToggles = getActualToggles()

export const plugin = {
    install(Vue) {
        Vue.mixin({
            provide() {
                return {
                    $featureToggle: this.$featureToggle
                }
            }
        })

        Vue.prototype.$featureToggle = {
            ensure(key, value) {
                return ensureToggleIsEnabled({
                    key,
                    value,
                    toggles: actualToggles,
                    queryString: getQueryStringFromUrl()
                })
            }
        }

        Vue.component('FeatureToggle', () => import(/* webpackChunkName: "feature-toggle" */ './FeatureToggle.vue'))
    }
}
