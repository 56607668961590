import { SetupGuideTaskKind } from '../../enums'

interface SetupGuideTranslationModalWelcomeGroup {
    description: string
    title: string
}

interface SetupGuideTranslationModalWelcomeHeader {
    title: string
}

interface SetupGuideTranslationModalWelcomeBody {
    group: Record<SetupGuideTaskKind, SetupGuideTranslationModalWelcomeGroup>
    title: string
}

interface SetupGuideTranslationModalWelcomeFooter {
    ok: string
    optOut: string
    skip: string
}

interface SetupGuideTranslationModalWelcome {
    body: SetupGuideTranslationModalWelcomeBody
    footer: SetupGuideTranslationModalWelcomeFooter
    header: SetupGuideTranslationModalWelcomeHeader
}

const TRANSLATION_MODAL_WELCOME_GROUP_KNOW: SetupGuideTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-know-header',
    description: 'saas-setup-guide-welcome-banner-descr-know-descr'
}
const TRANSLATION_MODAL_WELCOME_GROUP_TRUST: SetupGuideTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-trust-header',
    description: 'saas-setup-guide-welcome-banner-descr-trust-descr'
}
const TRANSLATION_MODAL_WELCOME_GROUP_BOOK: SetupGuideTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-book-header',
    description: 'saas-setup-guide-welcome-banner-descr-book-descr'
}

const TRANSLATION_MODAL_WELCOME_HEADER: SetupGuideTranslationModalWelcomeHeader = {
    title: 'saas-setup-guide-welcome-banner-modal-header'
}

const TRANSLATION_MODAL_WELCOME_BODY: SetupGuideTranslationModalWelcomeBody = {
    title: 'saas-setup-guide-welcome-banner-header',
    group: {
        [SetupGuideTaskKind.Know]: TRANSLATION_MODAL_WELCOME_GROUP_KNOW,
        [SetupGuideTaskKind.Trust]: TRANSLATION_MODAL_WELCOME_GROUP_TRUST,
        [SetupGuideTaskKind.Book]: TRANSLATION_MODAL_WELCOME_GROUP_BOOK
    }
}

const TRANSLATION_MODAL_WELCOME_FOOTER: SetupGuideTranslationModalWelcomeFooter = {
    optOut: 'saas-setup-guide-welcome-banner-opt-out-descr',
    ok: 'saas-setup-guide-welcome-banner-cta-go-to-setup-guide',
    skip: 'saas-setup-guide-welcome-banner-cta-not-now'
}

const TRANSLATION_MODAL_WELCOME: SetupGuideTranslationModalWelcome = {
    header: TRANSLATION_MODAL_WELCOME_HEADER,
    footer: TRANSLATION_MODAL_WELCOME_FOOTER,
    body: TRANSLATION_MODAL_WELCOME_BODY
}

export const getTranslationModalWelcome = () => {
    return TRANSLATION_MODAL_WELCOME
}
