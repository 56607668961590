import QuotesService from '@/features/quotes/domain/quotes.service'
import { ActionsAdaptor } from '@/interfaces'

import { ActionTypes, MutationTypes, QuotesActions, QuotesMutations, QuotesState } from './types'

export const actions: ActionsAdaptor<QuotesActions, QuotesMutations, QuotesState> = {
    async [ActionTypes.FetchQuotes]({ commit }, { filters, page }) {
        const { quotes, totalPages, currentPage } = await QuotesService.getQuotes(filters, page)

        commit(MutationTypes.SetQuotes, quotes)
        commit(MutationTypes.SetTotalPages, totalPages)
        commit(MutationTypes.SetCurrentPage, currentPage)
    },
    async [ActionTypes.UpdateApprovalStatus]({ commit, state: { quotes } }, { quoteId, status }) {
        await QuotesService.updateApprovalStatus(quoteId, status)
        const quotesUpdated = quotes.map(quote => (quote.id === quoteId ? { ...quote, status } : quote))

        commit(MutationTypes.SetQuotes, quotesUpdated)
    },
    async [ActionTypes.DeleteQuote]({ state: { currentPage }, dispatch }, quoteId) {
        await QuotesService.deleteQuote(quoteId)

        // We need to get fresh data from BE istead changing the store locally
        // This is because when we remove a row sometimes we have to recalulate the page, pageIndex and total Page
        dispatch(ActionTypes.FetchQuotes, currentPage)
    }
}
