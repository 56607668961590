import { DynamicFormFieldValidator } from '@lib/dynamicForm/interfaces/dynamic.form.interfaces'

import repository from '../api/voucher.api'
import { VoucherPaymentStatus } from '../ui/constants/voucher.enum'
import {
    Voucher,
    VoucherFile,
    VoucherPatient,
    VoucherSendEmail,
    VouchersFilters,
    VouchersResponse
} from './interfaces/voucher.interfaces'

class VoucherService {
    getAll(params: VouchersFilters, page = 0): Promise<VouchersResponse> {
        return repository.getAll(params, page)
    }

    get(voucherId: number): Promise<Voucher> {
        return repository.get(voucherId)
    }

    async delete(voucherId: number): Promise<void> {
        await repository.delete(voucherId)
    }

    changePaymentStatus(voucherId: number, paymentStatus: VoucherPaymentStatus): Promise<void> {
        return repository.changePaymentStatus(voucherId, paymentStatus)
    }

    async shareVoucher(voucherId: number, patients: number[]): Promise<void> {
        await repository.shareVoucher(voucherId, patients)
    }

    setExpirationDate(voucherId: number, expirationDate: string): Promise<void> {
        return repository.setExpirationDate(voucherId, expirationDate)
    }

    updateAmountPaid(voucherId: number, amountPaid: number): Promise<void> {
        return repository.updateAmountPaid(voucherId, amountPaid)
    }

    isVoucherShared(patients: VoucherPatient[]) {
        return patients.length > 1
    }

    countVoucherPatientsSharedWithoutOwner(patients: VoucherPatient[]) {
        return patients.length - 1
    }

    getVoucherPatientsNamesList(patients: VoucherPatient[]): string {
        return patients.map(({ patientName }) => patientName).join(', ')
    }

    getVoucherFile(voucherId: number): Promise<VoucherFile> {
        return repository.getVoucherFile(voucherId)
    }

    getVoucherMail(voucherId: number): Promise<VoucherSendEmail> {
        return repository.getVoucherMail(voucherId)
    }

    async sendVoucherMail(voucherId: number, payload: VoucherSendEmail): Promise<void> {
        await repository.sendVoucherMail(voucherId, payload)
    }

    updateVoucherPrice(voucherId: number, price: number): Promise<void> {
        return repository.updateVoucherPrice(voucherId, price)
    }

    updateVoucherReferenceId(voucherId: number, referenceId: number): Promise<void> {
        return repository.updateVoucherReferenceId(voucherId, referenceId)
    }

    getVoucherMailValidators(): Promise<DynamicFormFieldValidator[]> {
        return repository.getVoucherMailValidators()
    }

    validatePrice(value: any): number | null {
        return value !== '' && Number(value) >= 0 ? Number(value) : null
    }
}

export default new VoucherService()
