import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'
import { PRIVACY_POLICY_ACTIVATE_CHECKIN_ROUTE } from '@/features/privacyPolicy/ui/constants/route.constants'

export default function hasAccessToPrivacyPolicySettings({ store, next }) {
    const canAccess =
        store.getters[AuthGetterTypes.HasAccessToSettings] && store.getters[AuthGetterTypes.HasPrivacyPolicyEnabled]

    if (canAccess && store.getters[AuthGetterTypes.CanActivateCheckIn]) {
        return next({ name: PRIVACY_POLICY_ACTIVATE_CHECKIN_ROUTE })
    }

    if (canAccess) {
        return next()
    }

    return next({ name: ROUTE_CALENDAR_BASE_NAME })
}
