import { DateFormat } from '@/core/ui/utils'

export const CONFIRM_MODAL_ACTION = 1
export const LINK_COPY_SUCCESS_TIMEOUT = 5000
export const MEDICAL_BACKGROUND = {
    ALLERGIES: 'allergies',
    MEDICATION: 'medication',
    PRECEDENTS: 'precedents',
    OTHER: 'other'
}
export const EPISODE_DATE_FORMAT = DateFormat.DateStringLogFormat

export const NEW_EPISODE_ID = 'new'
export const LAST_EPISODE_SYNC_OPTIONS = 'last_episode_sync_options'
