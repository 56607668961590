export enum CheckinStatusValues {
    Scheduled = 0,
    Waiting = 3,
    Visiting = 4,
    Visited = 1,
    NoShowedUp = 2,
    Canceled = 6
}

export enum StatusColors {
    Calm = 'calm',
    Danger = 'danger',
    Gray300 = 'gray-300',
    Success = 'success',
    Warning = 'warning'
}

export enum CheckinStatusMapTranslationKeys {
    Canceled = 'calendar-status-canceled',
    NoShowedUp = 'calendar-status-did-not-come',
    Scheduled = 'calendar-status-scheduled',
    Visited = 'calendar-status-visited',
    Visiting = 'calendar-status-being-visited',
    WaitingRoom = 'calendar-status-in-the-waiting-room'
}

export enum CheckinStatusMapTrackingEvents {
    Canceled = 'canceled',
    NoShowedUp = 'noshow',
    Scheduled = 'scheduled',
    Visited = 'visited',
    Visiting = 'visiting',
    WaitingRoom = 'waiting-room'
}

export enum PaymentStatusTranslationKeys {
    Paid = 'secretary-view-tag-payment-status-paid',
    PartiallyPaid = 'partially-paid-generic',
    Refunded = 'payments-section-overview-history-filter-status-refunded',
    RefundedFailed = 'payments-section-overview-history-filter-status-refund-failed',
    Unpaid = 'secretary-view-tag-payment-status-unpaid'
}

export enum VisitStatusTranslationKeys {
    CanceledByDoctor = 'secretary-view-filters-visit-confirmation-canceled-by-staff',
    CanceledByPatient = 'secretary-view-filters-visit-confirmation-canceled-by-patient',
    ConfirmationSent = 'secretary-view-filters-visit-confirmation-requested',
    ConfirmedByAdmin = 'secretary-view-filters-visit-confirmation-confirmed-by-staff',
    ConfirmedByPatient = 'secretary-view-filters-visit-confirmation-confirmed-by-patient',
    Scheduled = 'secretary-view-filters-visit-confirmation-not-requested-soon'
}

export enum VisitStatusCellTranslationKeys {
    Canceled = 'secretary-view-confirmation-status-canceled',
    ConfirmationSent = 'secretary-view-confirmation-status-requested',
    Confirmed = 'secretary-view-confirmation-status-confirmed',
    ExtraReminderSent = 'secretary-view-confirmation-status-requested-booking-status-scheduled',
    NotRequested = 'secretary-view-confirmation-status-not-requested',
    Scheduled = 'secretary-view-confirmation-status-scheduled'
}

/* eslint-disable @typescript-eslint/naming-convention */
// NOTE: enumMembers must match SecretaryHeaderPropsAllowed
export enum CellMap {
    amountInDebt = 'AmountInDebt',
    amountPaid = 'AmountPaid',
    attendance = 'CheckInStatusCell',
    comments = 'CommentCell',
    insuranceId = 'InsuranceCell',
    insuranceName = 'InsuranceNameCell',
    patientArrivalTime = 'ArrivalCell',
    patientExitTime = 'ExitTimeCell',
    patientName = 'PatientNameCell',
    patientPhoneNumber = 'PhoneCell',
    paymentMethod = 'PaymentMethodsCell',
    paymentStatus = 'PaymentStatusCell',
    price = 'PriceCell',
    schedule = 'ScheduleCell',
    serviceName = 'ServicesCell',
    startDateTime = 'TimeCell',
    status = 'VisitStatusCell'
}

export enum PageMessageStates {
    Initial = 0,
    Loading = 1,
    ErrorSentMessages = 2,
    WarningPatientWithoutContacts = 3
}

export enum PageCancelationStates {
    Initial,
    Confirm,
    Loading,
    ErrorSentMessages,
    WarningPatientWithoutContacts
}

export enum MessageTypes {
    MANUAL = 'manual',
    TEMPLATE = 'template'
}

export enum ActionBarActions {
    Invoices = 'ActionBarInvoices',
    Payment = 'ActionBarPayment',
    UpdatePatientData = 'ActionBarUpdatePatientData'
}

export enum ActionBarActionsIds {
    Invoices = 'invoices',
    Payment = 'payment',
    UpdatePatientData = 'update-patient-data'
}

export enum ActionBarPatientEditRuleNames {
    MaxLength = 'maxLength',
    Required = 'required'
}

export enum ActionBarPatientEditErrorTranslationKeys {
    FirstNameMaxLength = 'first-name-too-large',
    LastNameMaxLength = 'last-name-too-large',
    PhoneMaxLength = 'phone-too-large',
    RequiredField = 'el-campo-es-obligatorio'
}

export enum ActionBarInvoicesActions {
    Download = 'download',
    SendAllInvoices = 'send-all-invoices',
    SendInvoice = 'send-invoice'
}
