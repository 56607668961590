import { setupGuideEventBus } from './bus'

enum SetupGuideEvents {
    MainPageExited = 'MainPageExited',
    MainPageLoaded = 'MainPageLoaded'
}

export const onSetupGuideMainPageLoaded = (callback: () => void) =>
    setupGuideEventBus.on(SetupGuideEvents.MainPageLoaded, callback)
export const offSetupGuideMainPageLoaded = (callback: () => void) =>
    setupGuideEventBus.off(SetupGuideEvents.MainPageLoaded, callback)
export const emitSetupGuideMainPageLoaded = () => setupGuideEventBus.emit(SetupGuideEvents.MainPageLoaded)
export const createSetupGuideMainPageLoadedHandler = (fn: () => void) => fn

export const onSetupGuideMainPageExited = (callback: () => void) =>
    setupGuideEventBus.on(SetupGuideEvents.MainPageExited, callback)
export const offSetupGuideMainPageExited = (callback: () => void) =>
    setupGuideEventBus.off(SetupGuideEvents.MainPageExited, callback)
export const emitSetupGuideMainPageExited = () => setupGuideEventBus.emit(SetupGuideEvents.MainPageExited)
export const createSetupGuideMainPageExitedHandler = (fn: () => void) => fn
