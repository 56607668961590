import { DateFormat, formatDate, parseDate, toDate } from '.'

const DATES_SPLIT_CHAR = '_'

export const serializeDateRanges = date => {
    return [formatDate(date.from, DateFormat.DateDashedFormat), formatDate(date.to, DateFormat.DateDashedFormat)].join(
        DATES_SPLIT_CHAR
    )
}

export const deserializeDateRanges = (string = '') => {
    return string
        .split(DATES_SPLIT_CHAR)
        .splice(0, 2)
        .map(dateString => {
            const date = parseDate(dateString, DateFormat.DateDashedFormat)

            return date ? toDate(date) : null
        })
        .reduce((object, date, index) => {
            return {
                ...object,
                ...(index === 0 && date ? { from: date } : null),
                ...(index === 1 && date ? { to: date } : null)
            }
        }, {})
}

export const deserializeInteger = value => parseInt(value, 10)
