export enum AccessScopes {
    Patients = 0,
    Messages = 1,
    Dashboard = 2,
    Report = 3,
    ReportDaily = 4,
    Invoices = 5,
    Campaigns = 6,
    Settings = 7,
    PatientFinancialTab = 8
}
