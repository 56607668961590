import { isPlainObject } from '@/utils/object'

import { GAUserRecords, GTMObject } from '../types'

window.dataLayer = window.dataLayer || []

export const track = (gTMObject: GTMObject) => {
    if (!isPlainObject(gTMObject) || !window.dataLayer) return

    window.dataLayer.push(gTMObject)
}

export const trackPage = (path: string) => {
    if (!path) return

    track({
        event: 'Pageview',
        url: path
    })
}

export const trackUser = (userRecords: GAUserRecords) => {
    track({
        event: 'IdentifyUser',
        ...userRecords
    })
}
