const WARNING_EXCEPTIONS = [
    {
        tag: 'DpDatepicker',
        message: 'computed property "id" is already defined as a prop'
    }
]

export default function (message, component, trace) {
    const tag = component?.$vnode?.componentOptions?.tag

    const { message: exceptionMsg } = WARNING_EXCEPTIONS.find(exception => exception.tag === tag) || {}

    if (!message.includes(exceptionMsg)) {
        // eslint-disable-next-line no-console
        console.warn(message, trace)
    }
}
