const state = {
    status: '',
    user: null,
    userProfileCopyLinkUrl: '',
    userProfileEmailUrl: '',
    userProfileWhatsappUrl: '',
    accountUsers: [],
    saasOnly: null,
    saasNavigation: {}
}

export default state
