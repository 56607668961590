import { PhoneNumber } from '@/core/domain/interfaces/phoneNumber.entity'

import repository from '../api/phoneNumber.api'

class PhoneNumberService {
    validatePhone(phone: string): Promise<PhoneNumber> {
        return repository.validatePhone(phone)
    }
}

export default new PhoneNumberService()
