export const INFORMATION_LIST_KEY_BLOCKERS = [
    'import-patients-error-invalid-insurance-id',
    'import-events-error-schedule-id',
    'import-events-error-start-date-time',
    'import-events-error-end-date-time',
    'import-events-error-insurance-id',
    'import-events-error-payment-method-id',
    'import-events-error-service-id',
    'import-healthrecord-error-service-id',
    'import-service-error-name'
]
