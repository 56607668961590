import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import {
    GetDefaultLayoutProps,
    GetPrintLayout,
    PrintLayout,
    SavePrintLayout
} from '@/features/settings/domain/interfaces/printLayouts.interfaces'

const URL_BASE = 'printLayout'
const URL_PRINT_LAYOUT_SINGLE = `${URL_BASE}/:id`
const URL_PRINT_LAYOUT_DEFAULT_EPISODE = `${URL_BASE}/default/:episodeId`

class PrintLayouts {
    async getPrintLayouts(): Promise<PrintLayout[]> {
        const { data } = await httpAPI.get(URL_BASE)

        return data
    }

    async getPrintLayout({ id }: GetPrintLayout): Promise<PrintLayout> {
        const url = getUrlFromTemplate({
            template: URL_PRINT_LAYOUT_SINGLE,
            params: { id }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    savePrintLayout({ options, id }: SavePrintLayout): Promise<PrintLayout> {
        const url = getUrlFromTemplate({
            template: URL_PRINT_LAYOUT_SINGLE,
            params: { id }
        })

        return httpAPI.patch(url, {
            options: JSON.stringify(options)
        })
    }

    async getDefaultLayoutForEpisode({ episodeId, patientId }: GetDefaultLayoutProps): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_PRINT_LAYOUT_DEFAULT_EPISODE,
            params: {
                episodeId
            },
            query: {
                patientId
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }
}

export default new PrintLayouts()
