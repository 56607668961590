export enum UserInvitationStatus {
    Active,
    Pending,
    Expired
}

export enum UserAccesses {
    Patients = 0,
    PatientMessages = 1,
    Dashboard = 2,
    VisitsReports = 3,
    EarningsReports = 4,
    Invoices,
    Campaigns,
    Settings,
    Payments
}
