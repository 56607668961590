import hasAccessToPricingTerms from '../middlewares/hasAccessToPricingTerms.middleware'

export const PRICING_TERMS_PATH_NAME = 'pricing-terms'
export const PRICING_TERMS_PATH = `/${PRICING_TERMS_PATH_NAME}`
const PricingTerms = () => import(/* webpackChunkName: "pricing-terms" */ '../views/PricingTerms.vue')

export default {
    path: PRICING_TERMS_PATH,
    name: PRICING_TERMS_PATH_NAME,
    component: PricingTerms,
    meta: {
        middleware: [hasAccessToPricingTerms]
    }
}
