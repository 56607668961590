const Migration = () => import(/* webpackChunkName: "migration" */ '@/features/migration/ui/views/Migration.vue')

export default [
    {
        path: 'migration',
        name: 'migration',
        component: Migration,
        props: {
            isCompleteMigration: false
        },
        meta: {
            title: 'mobile-migration-activation-tab-title'
        }
    },
    {
        path: 'completemigration',
        name: 'completemigration',
        component: Migration,
        props: {
            isCompleteMigration: true
        },
        meta: {
            title: 'mobile-migration-activation-tab-title'
        }
    }
]
