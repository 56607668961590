import {
    PRIVACY_POLICY_ACTIVATE_CHECKIN_ROUTE,
    PRIVACY_POLICY_SETTINGS_ROUTE
} from '@/features/privacyPolicy/ui/constants/route.constants'
import hasAccessToCheckinActivation from '@/features/privacyPolicy/ui/middleware/hasAccessToCheckinActivation.middleware'
import hasAccessToPrivacyPolicySettings from '@/features/privacyPolicy/ui/middleware/hasAccessToPrivacyPolicySettings.middleware'

const PrivacyPolicySettings = () =>
    import(
        /* webpackChunkName: "privacy-policy-settings" */ '@/features/privacyPolicy/ui/views/PrivacyPolicySettings.vue'
    )
const PrivacyPolicyCheckinActivation = () =>
    import(
        /* webpackChunkName: "privacy-policy-checkin-activation" */ '@/features/privacyPolicy/ui/views/PrivacyPolicyCheckinActivation.vue'
    )

export default [
    {
        path: '/settings/privacy-policy',
        name: PRIVACY_POLICY_SETTINGS_ROUTE,
        component: PrivacyPolicySettings,
        meta: {
            middleware: hasAccessToPrivacyPolicySettings
        }
    },
    {
        path: '/settings/privacy-policy-activate-checkin',
        name: PRIVACY_POLICY_ACTIVATE_CHECKIN_ROUTE,
        component: PrivacyPolicyCheckinActivation,
        meta: {
            middleware: hasAccessToCheckinActivation
        }
    }
]
