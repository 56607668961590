export enum MessageGenerator {
    Generate400ErrorMessage = 'generate400ErrorMessage',
    GenerateGenericErrorMessage = 'generateGenericErrorMessage'
}
export interface ErrorToTransform {
    errorMatch: string
    errorType: string
    generatorName: MessageGenerator
    override?: {
        statusCode?: number
    }
}
