import fiscalCodeService from '@/core/domain/fiscalCode.service'

export async function isFiscalCodeValid(value: string): Promise<boolean> {
    try {
        await fiscalCodeService.validateFiscalCode(value)
        return true
    } catch {
        return false
    }
}
