import { formatDate } from '@/core/ui/utils'
import {
    CampaignsReportRow,
    CampaignsSummaryParams,
    CampaignsSummaryPreview,
    MonthlyCampaignMetrics
} from '@/features/campaigns/domain/interfaces/campaigns.interfaces'
import { getReportTableRows, mapCampaignItem } from '@/features/campaigns/ui/utils/getReportTableRows.util'

import repository from '../api/campaigns.api'
import { CampaignsTypeId } from './enums/campaigns.enums'

class CampaignsService {
    async getCampaignPreview(campaignId: number): Promise<CampaignsSummaryPreview> {
        const { campaignType, sendDate, smsMessage, emailSubject, emailTitle, emailText } =
            await repository.getCampaign(campaignId)

        const showEmail = campaignType === CampaignsTypeId.Email

        return {
            date: sendDate ? formatDate(sendDate) : null,
            showEmail,
            channel: showEmail ? 'campaigns-one-time-type-subtitle-email' : 'campaigns-one-time-type-subtitle-sms',
            textContent: {
                smsMessage: smsMessage || '',
                emailSubject: emailSubject || '',
                emailTitle: emailTitle || '',
                emailText: emailText || ''
            }
        }
    }

    async getReportRows(): Promise<CampaignsReportRow[]> {
        const campaigns = await repository.getCampaigns()

        return getReportTableRows(campaigns)
    }

    async getReportRow(campaignId: number): Promise<CampaignsReportRow> {
        const row = await repository.getCampaignItem(campaignId)

        return mapCampaignItem(row)
    }

    async postCampaigns(params: CampaignsSummaryParams) {
        await repository.postCampaigns(params)
    }

    getMonthlyCampaignMetrics(): Promise<MonthlyCampaignMetrics> {
        return repository.getMonthlyCampaignMetrics()
    }

    getCanCreateCampaign(monthlyCampaignMetrics: MonthlyCampaignMetrics): {
        hasPendingCampaign: boolean
        reachedMonthlyCampaignLimit: boolean
    } {
        const { messageLimit, pendingMessages, sentMessages } = monthlyCampaignMetrics

        return {
            hasPendingCampaign: pendingMessages > 0,
            reachedMonthlyCampaignLimit: sentMessages > messageLimit
        }
    }

    getAvailableRecipientsLimit(monthlyCampaignMetrics: MonthlyCampaignMetrics) {
        const { messageLimit, sentMessages } = monthlyCampaignMetrics || {}

        return messageLimit - sentMessages
    }

    getFilters(): Promise<any> {
        return repository.getFilters()
    }
}

export default new CampaignsService()
