export enum AppointmentPaymentType {
    PaymentTypeOptional,
    PaymentTypeMandatory
}

export enum AppointmentPaymentStatus {
    StatusNotPaid,
    StatusPaid,
    StatusRefunded,
    StatusRefundedFailed
}

export enum AppointmentConsultationChannels {
    Phone = 1,
    CustomVideo,
    Plugin,
    DPVideo,
    Chat
}

export enum AppointmentScheduleTypes {
    UserSchedule,
    Block,
    PublicHolidays
}

export enum AppointmentPatientsNotificationTypes {
    OnlyReminder,
    ReminderWithManagementOptions
}
