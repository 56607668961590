import httpAPI from '@/api/utils/httpClientAPI'
import { Device } from '@/features/devices/domain/interfaces/devices.interface'

const URL_BASE = 'schedules'
const URL_DEVICE = `${URL_BASE}/device`

class DevicesRepository {
    async getDevices(): Promise<Device[]> {
        const { data } = await httpAPI.get<Device[]>(URL_DEVICE)
        return data
    }
}

export default new DevicesRepository()
