import { MutationTree } from 'vuex'

import { MutationTypes, VouchersMutations } from './types/mutations'
import { VouchersState } from './types/state'

export const mutations: MutationTree<VouchersState> & VouchersMutations = {
    [MutationTypes.SetLoading](state, isLoading) {
        state.isLoading = isLoading
    },
    [MutationTypes.SetLoadingVoucherTypes](state, isLoadingVoucherTypes: boolean) {
        state.isLoadingVoucherTypes = isLoadingVoucherTypes
    },
    [MutationTypes.ResetPagination](state) {
        state.currentPage = 1
        state.totalPage = 1
    },
    [MutationTypes.SetVouchersFilters](state, filters) {
        state.vouchersFilters = { ...filters }
    },
    [MutationTypes.SetAllVoucherTypes](state, voucherTypes) {
        state.voucherTypes = voucherTypes
    },
    [MutationTypes.SetVoucherTypesPaginated](state, voucherTypesPaginated) {
        state.voucherTypesPaginated = voucherTypesPaginated
    },
    [MutationTypes.SetVoucherTypesShowActive](state, showActive) {
        state.showActive = showActive
    },
    [MutationTypes.SetCurrentPage](state, currentPage) {
        state.currentPage = currentPage
    },
    [MutationTypes.SetHasFullyRedeemedVouchers](state, hasFullyRedeemedVouchers) {
        state.hasFullyRedeemedVouchers = hasFullyRedeemedVouchers
    },
    [MutationTypes.SetTotalPage](state, pageAmount) {
        state.totalPage = pageAmount
    },
    [MutationTypes.SetSchedules](state, schedules) {
        state.schedules = schedules
    },
    [MutationTypes.SetVouchers](state, vouchers) {
        state.vouchers = vouchers
    }
}
