export const prepareRemoteToggles = toggles => {
    return Object.entries(toggles).reduce(
        (output, [key, { isRemote, value }]) => {
            if (isRemote) {
                return {
                    togglesToLoad: [...output.togglesToLoad, key],
                    defaultToggles: {
                        ...output.defaultToggles,
                        [key]: value
                    }
                }
            }
            return output
        },
        {
            togglesToLoad: [],
            defaultToggles: {}
        }
    )
}
