const ExportData = () =>
    import(/* webpackChunkName: "export-data-settings" */ '@/components/Settings/ExportData/ExportDataSettings.vue')

export default [
    {
        path: 'settings-exportdata',
        name: 'settings-exportdata',
        component: ExportData
    }
]
