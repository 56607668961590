import { CountryCodes } from '@/core/domain/enums/country.code.enum'
import { CURRENT_MONTH, CUSTOM_RANGE, LAST_MONTH } from '@/core/ui/constants/dateRangesSelector.constants'
import { serializeDateRanges } from '@/core/ui/utils/dateRangesSelector.utils'
import { HINT_STATUS } from '@/features/hints/ui/constants/hints.constants'
import { ReportsCellComponent } from '@/features/reports/ui/enums/reports.enum'
import { orderByAttribute, orderByAttributes } from '@/utils/array'
import { formatAmount } from '@/utils/number-format'
import { isPlainObject } from '@/utils/object'

import repository from '../api/reports.api'
import {
    FIRST_COLUMN,
    REPORT_TYPE_BY_PATH_NAME,
    TABLE_COLUMN_STYLES_BY_PROPERTY,
    UNCHECKED_STATUS_BY_DEFAULT
} from '../ui/constants/reports.constants'
import { getVoucherService } from '../ui/utils/getVoucherService'
import { isAppointmentCategory } from '../ui/utils/isAppointmentCategory'
import { isFixedColumn } from '../ui/utils/isFixedColumn'
import { URL_DATA_VISITS_REPORT_DE } from './constans/reports.constants'
import {
    HeaderColumnMovementDirection,
    ReportColumnProperty,
    ReportExportType,
    ReportPathname
} from './enums/reports.enums'
import {
    CashRegisterQueryParameters,
    ReportsData,
    ReportsDataPage,
    ReportsDefaultQueryParameters,
    ReportsHeader,
    ReportsParameters,
    ReportsParametersFilter,
    ReportsTableCell,
    ReportsTableColumnHeader,
    ReportsTableColumnStyles,
    ReportsTableRow
} from './interfaces/reports.interfaces'

class ReportsService {
    getData(args: ReportsParameters, routeName: string, countryCode?: CountryCodes): Promise<ReportsData> {
        if (routeName === ReportPathname.Visits) {
            if (countryCode && countryCode === CountryCodes.DE) {
                routeName = URL_DATA_VISITS_REPORT_DE
            }
        }
        return repository.getData(args, routeName)
    }

    getHeaders(reportPathName: ReportPathname): Promise<ReportsHeader[]> {
        const reportType = REPORT_TYPE_BY_PATH_NAME[reportPathName]
        return repository.getHeaders(reportType)
    }

    getExportDocument(reportType: ReportExportType, args: ReportsParameters): Promise<Blob> {
        return repository.getExportDocument(reportType, args)
    }

    updateHeaders(headers: ReportsHeader[], reportPathName: ReportPathname): Promise<ReportsHeader[]> {
        const reportType = REPORT_TYPE_BY_PATH_NAME[reportPathName]
        return repository.updateHeaders(headers, reportType)
    }

    checkboxFiltersSerializer(filters: ReportsParametersFilter[]): string | null {
        if (filters) {
            return filters.map(({ tag, values, exclude }) => `${tag}_${values}${exclude ? '_true' : ''}`).join('~')
        }

        return null
    }

    checkboxFiltersDeserializer(text: string): ReportsParametersFilter[] {
        if (text && text.length) {
            return text.split('~').map(filter => {
                const values = filter.split('_')
                return {
                    tag: values[0],
                    values: values[1].split(','),
                    ...(values[2] ? { exclude: true } : {})
                }
            })
        }

        return [
            {
                tag: HINT_STATUS,
                values: UNCHECKED_STATUS_BY_DEFAULT,
                exclude: true
            }
        ]
    }

    getCashRegisterDefaultQueryParameters(): CashRegisterQueryParameters {
        const rangeForToday = {
            from: new Date(),
            to: new Date()
        }
        return {
            dateFilter: CUSTOM_RANGE,
            date: serializeDateRanges(rangeForToday)
        }
    }

    getExpensesDefaultQueryParameters(): ReportsDefaultQueryParameters {
        return {
            dateFilter: CURRENT_MONTH
        }
    }

    getReportsDefaultQueryParameters(): ReportsDefaultQueryParameters {
        return {
            dateFilter: LAST_MONTH
        }
    }

    getDefaultQueryParametersByPathname(
        reportPathName: ReportPathname
    ): CashRegisterQueryParameters | ReportsDefaultQueryParameters | undefined {
        switch (reportPathName) {
            case ReportPathname.Cash: {
                return this.getCashRegisterDefaultQueryParameters()
            }
            case ReportPathname.Expenses: {
                return this.getExpensesDefaultQueryParameters()
            }
            case ReportPathname.Visits:
            case ReportPathname.Commissions: {
                return this.getReportsDefaultQueryParameters()
            }
            default:
                return undefined
        }
    }

    getCellByReportCashVisits(property: ReportColumnProperty, cell: ReportsTableCell) {
        const { categoryId } = cell.data

        switch (property) {
            case ReportColumnProperty.Category:
                cell.component = ReportsCellComponent.Category
                break
            case ReportColumnProperty.ClinicSplit:
            case ReportColumnProperty.DoctorSplit:
            case ReportColumnProperty.AmountInDebt:
                cell.value = formatAmount(cell.value)
                break
            case ReportColumnProperty.Price:
                cell.component = ReportsCellComponent.Price
                break
            case ReportColumnProperty.Status:
                cell.component = ReportsCellComponent.VisitStatus
                break
            case ReportColumnProperty.PaymentStatus:
                // @todo: Remove retro compatibility (check task: CFO-2259)
                if (isPlainObject(cell.value)) {
                    cell.component = ReportsCellComponent.PaymentStatus
                }
                break
            case ReportColumnProperty.PaymentMethod:
                cell.component = ReportsCellComponent.PaymentMethod
                break
            case ReportColumnProperty.Service:
                if (isAppointmentCategory(categoryId)) {
                    cell.component = ReportsCellComponent.EventServices
                    break
                }
                cell.value = getVoucherService(cell.value)
                break
            case ReportColumnProperty.Insurance:
                // @todo: Remove retro compatibility (check task: CFO-2259)
                if (isPlainObject(cell.value)) {
                    cell.component = ReportsCellComponent.Insurance
                }
                break
            case ReportColumnProperty.AmountPaid:
                cell.component = ReportsCellComponent.AmountPaid
                break
            // no default
        }
        return cell
    }

    getCellByReportExpenses(property: ReportColumnProperty, cell: ReportsTableCell) {
        // NOTE: switch statement for next iterations
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (property) {
            case ReportColumnProperty.PaymentStatus:
                cell.component = ReportsCellComponent.ExpensePaymentStatus
                break
            // no default
        }
        return cell
    }

    getRowsByPathname(
        page: ReportsDataPage[],
        headers: ReportsTableColumnHeader[],
        reportPathName: ReportPathname
    ): ReportsTableRow[] {
        return page.map((data: ReportsDataPage) => {
            const row = [] as any

            headers.forEach((column: ReportsTableColumnHeader) => {
                const { property, order } = column
                let cell = {
                    component: 'DefaultTableRowCell',
                    value: data[property],
                    data,
                    order: isFixedColumn(property) ? FIRST_COLUMN : order,
                    column,
                    origin: reportPathName
                }
                switch (reportPathName) {
                    case ReportPathname.Cash:
                    case ReportPathname.Visits:
                        cell = this.getCellByReportCashVisits(property, cell)
                        break
                    case ReportPathname.Expenses:
                        cell = this.getCellByReportExpenses(property, cell)
                        break
                    // no default
                }

                row.push(cell)
            })

            return row
        }, [])
    }

    getTableColumnStylesByProperty(property: string): ReportsTableColumnStyles {
        return {
            bold: TABLE_COLUMN_STYLES_BY_PROPERTY.bold.includes(property),
            ellipsis: TABLE_COLUMN_STYLES_BY_PROPERTY.ellipsis.includes(property),
            textCapitalize: TABLE_COLUMN_STYLES_BY_PROPERTY.textCapitalize.includes(property)
        }
    }

    buildColumns(headers: ReportsHeader[]): ReportsTableColumnHeader[] {
        const columns = headers.map(({ name, translatedName, property, order, hidden, tag, hasFilterHints }) => ({
            id: name,
            property,
            order: isFixedColumn(property) ? FIRST_COLUMN : order,
            hidden,
            tag,
            name: translatedName,
            hint: tag,
            enabled: hasFilterHints,
            isFixed: isFixedColumn(property),
            ...this.getTableColumnStylesByProperty(property)
        }))

        return orderByAttribute(columns, 'order')
    }

    buildRows(
        page: ReportsDataPage[],
        headers: ReportsTableColumnHeader[],
        reportPathName: ReportPathname
    ): Record<string, any>[] {
        const rows = this.getRowsByPathname(page, headers, reportPathName)

        return rows.map(cells => orderByAttribute(cells, 'order'))
    }

    sortHeadersByHiddenAndOrder(headers: ReportsTableColumnHeader[]): ReportsTableColumnHeader[] {
        return orderByAttributes(headers, ['hidden', 'order']).map(
            (column: ReportsTableColumnHeader, index: number) => ({
                ...column,
                order: index + 1
            })
        )
    }

    changeColumnVisibility(
        headers: ReportsTableColumnHeader[],
        columnTag: string,
        hidden: boolean
    ): ReportsTableColumnHeader[] {
        return this.sortHeadersByHiddenAndOrder(
            headers.map(header => ({
                ...header,
                hidden: header.tag === columnTag ? hidden : header.hidden
            }))
        )
    }

    moveColumn(
        headers: ReportsTableColumnHeader[],
        columnTag: string,
        direction: HeaderColumnMovementDirection
    ): ReportsTableColumnHeader[] {
        const column = headers.find(headerColumn => headerColumn.tag === columnTag) as ReportsTableColumnHeader

        switch (direction) {
            case HeaderColumnMovementDirection.Forward:
                column.order += 1.5
                break
            case HeaderColumnMovementDirection.Backward:
                column.order -= 1.5
                break
            // no default
        }

        return this.sortHeadersByHiddenAndOrder(headers)
    }

    async updatePaymentMethod(
        cashReportId: string,
        paymentMethodId: string,
        categoryId: number
    ): Promise<ReportsDataPage> {
        if (paymentMethodId) {
            return repository.updatePaymentMethod(cashReportId, paymentMethodId, categoryId)
        }

        return repository.deletePaymentMethod(cashReportId, categoryId)
    }

    async updateAmountPaid(cashReportId: string, amountPaid: number, categoryId: number): Promise<ReportsDataPage> {
        return repository.updateAmountPaid(cashReportId, amountPaid, categoryId)
    }

    async updatePaymentStatus(
        cashReportId: string,
        paymentStatusId: string,
        categoryId: number
    ): Promise<ReportsDataPage> {
        return repository.updatePaymentStatus(cashReportId, paymentStatusId, categoryId)
    }

    getTrackEventCategory(categoryId: number, cashReportId: number): Record<string, number> {
        return isAppointmentCategory(categoryId) ? { booking_id: cashReportId } : { voucher_id: cashReportId }
    }
}

export default new ReportsService()
