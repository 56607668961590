import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { START_GUIDE_ROOT_ROUTE } from '../constants/startGuide.router.constans'

const StartGuide = () => import(/* webpackChunkName: "start-guide" */ '../views/StartGuide.vue')

export default {
    path: `/${START_GUIDE_ROOT_ROUTE}`,
    name: START_GUIDE_ROOT_ROUTE,
    component: StartGuide,
    meta: {
        middleware: [uninav],
        title: 'uninav-start_guide-icon-tooltip',
        uninavName: START_GUIDE_ROOT_ROUTE
    }
}
