import { Patient } from '@/features/patient/domain/interfaces/patient.interfaces'

import { BookingEvent, BookingState } from './state'

export enum MutationTypes {
    ClearBookingState = 'clearBookingState',
    SetBookingEvent = 'setBookingEvent',
    SetBookingPatient = 'setBookingPatient',
    SetToastId = 'SetToastId'
}

export type BookingMutations = {
    [MutationTypes.SetBookingPatient](state: BookingState, patient: Patient): void
    [MutationTypes.SetBookingEvent](state: BookingState, event: BookingEvent): void
    [MutationTypes.ClearBookingState](state: BookingState): void
    [MutationTypes.SetToastId](state: BookingState, toastId: string): void
}
