import generateTitle from './generateTitle'

const headerTitle = (to, router) => {
    const { $i18n } = router.app
    const { matched } = to
    const { currentRoute } = router

    const title = generateTitle({
        matched,
        currentRoute,
        translate: key => $i18n.t(key)
    })

    document.title = title
}

export default headerTitle
