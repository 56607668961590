import { repository } from '../../api'

export const getMarketplaceProfileUrl = async () => {
    const { data, error, message } = await repository.getMarketplaceProfileUrl()

    if (error) {
        return { data: null, error, message }
    }

    const { doctorUrl } = data

    return { data: doctorUrl, error, message }
}
