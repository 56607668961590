import { GettersAdaptor } from '@/interfaces'

import hintsService from '../../domain/hints.service'
import { GetterTypes, HintsGetters, HintsState } from './types'

export const getters: GettersAdaptor<HintsGetters, HintsState, any> = {
    [GetterTypes.GetHint]: state => {
        return (hint: string) => hintsService.orderHintItems(hint, state[hint] || [])
    },
    [GetterTypes.GetHintReady]: state => {
        return (hints = []) => hints.every(hint => state[hint])
    }
}
