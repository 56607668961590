import { createAgent } from '@/api/utils/agent'
import HttpClient from '@/api/utils/HttpClient'
import { apiUrl } from '@/utils/url'

const httpClientAPIAnonymous = new HttpClient({
    baseURL: `${apiUrl}/api/`
})

export const { get, post, remove, put } = createAgent(httpClientAPIAnonymous.instance)

export default httpClientAPIAnonymous
