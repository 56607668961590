import { captureException, captureMessage, getCurrentScope } from '@sentry/browser'
import { init } from '@sentry/vue'
import Vue from 'vue'

import { ignoredHttpErrorMessages, ignoreErrors, integrations } from './sentry.config'

class Monitor {
    constructor() {
        this.enabled = false
        this.messageQueue = []
    }

    initialize({ version, dsn, environment, allowUrls, router, apiUrls, debug, ensureToggleIsEnabled }) {
        this.initializeSentry({
            version,
            dsn,
            environment,
            allowUrls,
            router,
            apiUrls,
            debug,
            ensureToggleIsEnabled
        })
        this.enable()
    }

    initializeSentry({ version, dsn, environment, allowUrls, router, apiUrls, debug, ensureToggleIsEnabled }) {
        init({
            Vue,
            tracingOptions: {
                trackComponents: true
            },
            dsn,
            environment,
            integrations: integrations(router, apiUrls),
            ignoreErrors,
            allowUrls,
            tracesSampler: this.sentryTracesSampler,
            beforeSend: this.sentryBeforeSend,
            debug,
            beforeSendTransaction(event) {
                if (
                    event.transaction === 'CalendarPrevNextWeek' &&
                    ensureToggleIsEnabled('SentryMonitorCalendarWeekChange', false)
                ) {
                    return null
                }

                return event
            }
        })
        getCurrentScope().setTag('version', version)
    }

    isEnabled() {
        return this.enabled
    }

    setUserId(id) {
        getCurrentScope().setUser({
            id
        })
    }

    setTags(tags) {
        getCurrentScope().setTags(tags)
    }

    send(message, captureContext = undefined) {
        if (this.enabled) {
            captureMessage(message, captureContext)
        } else {
            this.messageQueue.push({
                message,
                captureContext
            })
        }
    }

    sendException(exception) {
        captureException(exception)
    }

    enable() {
        this.enabled = true
        this.messageQueue.forEach(({ message, captureContext }) => captureMessage(message, captureContext))
        this.messageQueue = []
    }

    sentryTracesSampler(transactionToSend) {
        return transactionToSend?.customSampleRate ?? 0
    }

    sentryBeforeSend(event, hint) {
        const error = hint.originalException
        const isHttpError = error?.response?.status

        if (ignoredHttpErrorMessages.includes(error.message)) return

        if (isHttpError && error.message) {
            event.fingerprint = [error.message]
        }
        return event
    }
}

export default new Monitor()
