import httpAPI from '@/api/utils/httpClientAPI'
import mapSettingsResponse from '@/core/api/utils/mapSettingResponse'

const URL_BASE_SETTINGS = 'settings'
const URL_BASE_MANAGED_FROM_MEDICAL_CENTER = 'settingsManagedFromMedicalCenter'

class CalendarSettingsRepository {
    addSetting(key: string, value: number | string | boolean) {
        return httpAPI.post(`${URL_BASE_SETTINGS}/${key}`, { key, value })
    }

    async getSetting(key: string): Promise<any> {
        const { data } = await httpAPI.get(`${URL_BASE_SETTINGS}/${key}`)
        return mapSettingsResponse(data)
    }

    addMedicalCenterSetting(key: string, dataObject: Record<string, any>) {
        return httpAPI.post(`${URL_BASE_MANAGED_FROM_MEDICAL_CENTER}/${key}`, dataObject)
    }

    async getMedicalCenterSetting(key: string): Promise<any> {
        const { data } = await httpAPI.get(`${URL_BASE_MANAGED_FROM_MEDICAL_CENTER}/${key}`)
        return mapSettingsResponse(data)
    }
}

export default new CalendarSettingsRepository()
