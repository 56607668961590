import { Schedule } from '@/features/settings/domain/interfaces/schedule.interfaces'
import { Voucher, VouchersFilters, VoucherType } from '@/features/voucher/domain/interfaces/voucher.interfaces'

import { VouchersState } from './state'

export enum MutationTypes {
    ResetPagination = 'RESET_PAGINATION',
    SetAllVoucherTypes = 'SET_ALL_VOUCHER_TYPES',
    SetCurrentPage = 'SET_CURRENT_PAGE',
    SetHasFullyRedeemedVouchers = 'SET_HAS_FULLY_REDEEMED_VOUCHERS',
    SetLoading = 'SET_LOADING',
    SetLoadingVoucherTypes = 'SET_LOADING_VOUCHER_TYPES',
    SetSchedules = 'SET_SCHEDULES',
    SetTotalPage = 'SET_TOTAL_PAGE',
    SetVoucherTypesPaginated = 'SET_VOUCHER_TYPES_PAGINATED',
    SetVoucherTypesShowActive = 'SET_VOUCHER_TYPES_SHOW_ACTIVE',
    SetVouchers = 'SET_VOUCHERS',
    SetVouchersFilters = 'SET_VOUCHERS_FILTERS'
}

export type VouchersMutations<S = VouchersState> = {
    [MutationTypes.SetLoading](state: S, isLoading: boolean): void
    [MutationTypes.SetLoadingVoucherTypes](state: S, isLoading: boolean): void
    [MutationTypes.SetVouchersFilters](state: S, filters: VouchersFilters): void
    [MutationTypes.SetAllVoucherTypes](state: S, voucherTypes: VoucherType[]): void
    [MutationTypes.ResetPagination](state: S): void
    [MutationTypes.SetVoucherTypesPaginated](state: S, voucherTypesPaginated: VoucherType[]): void
    [MutationTypes.SetVoucherTypesShowActive](state: S, showActive: boolean): void
    [MutationTypes.SetCurrentPage](state: S, currentPage: number): void
    [MutationTypes.SetHasFullyRedeemedVouchers](state: S, hasFullyRedeemedVouchers: boolean): void
    [MutationTypes.SetTotalPage](state: S, pageAmount: number): void
    [MutationTypes.SetSchedules](state: S, schedules: Schedule[]): void
    [MutationTypes.SetVouchers](state: S, vouchers: Voucher[]): void
}
