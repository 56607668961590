const ROOM_RESOURCE_PREFIX = 'R-'

export const addRoomPrefixToId = (roomId: any): string => {
    const id = roomId.toString()
    return id.indexOf(ROOM_RESOURCE_PREFIX) === 0 ? id : ROOM_RESOURCE_PREFIX + id
}

export const removeRoomPrefixFromId = (roomId: string): number => {
    if (!roomId) {
        return -1
    }

    if (roomId.startsWith(ROOM_RESOURCE_PREFIX)) {
        return parseInt(roomId.replace(ROOM_RESOURCE_PREFIX, ''), 10)
    }
    return parseInt(roomId.toString(), 10)
}

export const isRoomResource = (resourceId: string): boolean => {
    return resourceId?.indexOf(ROOM_RESOURCE_PREFIX) === 0
}
