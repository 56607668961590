import HttpClient from '@/api/utils/HttpClient'
import { apiUrl } from '@/utils/url'

const httpClientCacheAPI = new HttpClient(
    {
        baseURL: `${apiUrl}/api/`
    },
    {
        cache: true
    }
)

export default httpClientCacheAPI
