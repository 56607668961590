import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'

interface Response<T> {
    data: T
    error: false
    message: string
    status: number
}

interface ResponseErrorData {
    message: string
}

interface ResponseError {
    data: null
    error: true
    message: string
    status: number
}

const isAxiosError = <ResponseType>(error: unknown): error is AxiosError<ResponseType> => {
    return axios.isAxiosError(error)
}

export const get = async <T>(path: string): Promise<Response<T> | ResponseError> => {
    try {
        const { data, status } = await httpAPI.get<T>(path)

        return { error: false, data, message: '', status }
    } catch (error: unknown) {
        if (!isAxiosError<ResponseErrorData>(error)) {
            return { error: true, message: 'ERROR_UNKNOWN', status: -1, data: null }
        }

        const { response } = error

        if (!response) {
            return { error: true, message: error.message, status: error.status || -1, data: null }
        }

        return {
            error: true,
            data: null,
            message: response.data.message,
            status: response.status
        }
    }
}

export const post = async <T, P>(
    path: string,
    payload?: P,
    config?: AxiosRequestConfig<P>
): Promise<Response<T> | ResponseError> => {
    try {
        const { data, status } = payload ? await httpAPI.post<T>(path, payload, config) : await httpAPI.post<T>(path)

        return { error: false, data, message: '', status }
    } catch (error: unknown) {
        if (!isAxiosError<ResponseErrorData>(error)) {
            return { error: true, message: 'ERROR_UNKNOWN', status: -1, data: null }
        }

        const { response } = error

        if (!response) {
            return { error: true, message: error.message, status: error.status || -1, data: null }
        }

        return {
            error: true,
            data: null,
            message: response.data.message,
            status: response.status
        }
    }
}

export const put = async <T, P>(path: string, payload: P | null = null): Promise<Response<T> | ResponseError> => {
    try {
        const { data, status } = payload ? await httpAPI.put<T>(path, payload) : await httpAPI.put<T>(path)

        return { error: false, data, message: '', status }
    } catch (error: unknown) {
        if (!isAxiosError<ResponseErrorData>(error)) {
            return { error: true, message: 'ERROR_UNKNOWN', status: -1, data: null }
        }

        const { response } = error

        if (!response) {
            return { error: true, message: error.message, status: error.status || -1, data: null }
        }

        return {
            error: true,
            data: null,
            message: response.data.message,
            status: response.status
        }
    }
}

export const remove = async <T, P>(path: string, payload: P | null = null): Promise<Response<T> | ResponseError> => {
    try {
        const { data, status } = payload
            ? await httpAPI.delete<T>(path, {
                  data: payload
              })
            : await httpAPI.delete<T>(path)

        return { error: false, data, message: '', status }
    } catch (error: unknown) {
        if (!isAxiosError<ResponseErrorData>(error)) {
            return { error: true, message: 'ERROR_UNKNOWN', status: -1, data: null }
        }

        const { response } = error

        if (!response) {
            return { error: true, message: error.message, status: error.status || -1, data: null }
        }

        return {
            error: true,
            data: null,
            message: response.data.message,
            status: response.status
        }
    }
}
