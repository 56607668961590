import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { canShowVoicemailSelfService, voicemailConfiguration } from '../middlewares/voicemail'

const VoicemailIndex = () =>
    import(/* webpackChunkName: "voicemail-index" */ '@/features/settings/ui/views/voicemail/VoicemailIndex.vue')
const VoicemailConfiguration = () =>
    import(
        /* webpackChunkName: "voicemail-configuration" */ '@/features/settings/ui/views/voicemail/VoicemailConfiguration.vue'
    )
const VoicemailNew = () =>
    import(/* webpackChunkName: "voicemail-new" */ '@/features/settings/ui/views/voicemail/VoicemailNew.vue')

export default [
    {
        path: 'voicemail',
        component: VoicemailIndex,
        beforeEnter: canShowVoicemailSelfService,
        meta: {
            middleware: uninav,
            title: 'voicemail-tab-title',
            uninavName: 'voicemail'
        },
        children: [
            {
                path: '',
                name: 'voicemail-configuration',
                component: VoicemailConfiguration,
                beforeEnter: voicemailConfiguration,
                meta: {
                    middleware: uninav,
                    uninavName: 'voicemail'
                }
            },
            {
                path: 'new-configuration',
                name: 'voicemail-new-configuration',
                component: VoicemailNew,
                meta: {
                    middleware: uninav,
                    uninavName: 'voicemail'
                }
            }
        ]
    }
]
