import { DateFormat } from '@/core/ui/utils'

export const CHAT_ORIGIN = {
    VIDEOCONSULTATION: 'videoconsultation_chat',
    EHR: 'ehr',
    PATIENTS_CHAT: 'patients_chat',
    UNDEFINED: 'undefined'
} as const

export const CHAT_PLATFORM = 'saas_web'

export const PATIENT_TYPE_LEAD = 'lead'

export const SAAS_SCHEDULED_EVENT_STATUS = {
    SCHEDULED: 0,
    CANCELED_BY_USER: 1,
    CANCELED_BY_PATIENT: 2,
    WAITING_FOR_CONFIRMATION: 3,
    CONFIRMED_BY_PATIENT: 4,
    SENT_TO_THIRD_PARTY: 5,
    CONFIRMED_BY_ADMIN: 6,
    CANCELED_BY_TECHNICAL_ISSUES: 7
} as const

export const CHAT_VISIT_DATE_FORMAT = DateFormat.ChatVisitDateFormat

export const CHAT_CONVERSATION_TAG_LEAD = 'lead'

export const PAGES = {
    PATIENTS_CONVERSATION_LIST: 'chat.patients-conversation-list',
    REQUESTS_MESSAGES_LIST: 'chat.requests-message-list',
    SAAS_INTERNAL_CONVERSATION_LIST: 'chat.saas-internal-conversation-list',
    PATIENT_CHAT_CONVERSATION: 'chat.patients-conversation',
    RESOLVE_ALL_PATIENTS_REQUEST: 'chat.resolve-all-patients-requests'
} as const

export const QUICK_REPLIES_TYPES = {
    TEMPLATE_NON_DEPENDANT: 0,
    TEMPLATE_FUTURE_EVENT: 1,
    TEMPLATE_ONLINE_BOOKING: 2,
    TEMPLATE_RESULTS: 3
} as const

export const CONVERSATION_TYPE = {
    DEFAULT: 0,
    DONE: 1
} as const

export const CHAT_TYPE = {
    PATIENTS: 'PATIENTS'
} as const

export const DEFAULT_CONVERSATION_TYPE_FOR_CHAT_TYPE = {
    [CHAT_TYPE.PATIENTS]: CONVERSATION_TYPE.DEFAULT
} as const

const CUSTOM_TEMPLATE = 0
const REQUEST_CONFIRMATION_TEMPLATE = 1
const SUGGEST_ANOTHER_VISIT_TEMPLATE = 2
const ASK_FOR_RESULTS_TEMPLATE = 3

export const TEMPLATE_TYPES = {
    [CUSTOM_TEMPLATE]: 'custom',
    [REQUEST_CONFIRMATION_TEMPLATE]: 'request_confirmation',
    [SUGGEST_ANOTHER_VISIT_TEMPLATE]: 'suggest_another_visit',
    [ASK_FOR_RESULTS_TEMPLATE]: 'ask_for_results'
} as const

export const QUICK_REMINDERS = {
    ATTENDANCE_CONFIRMATION: 'attendance-confirmation'
} as const

export const INBOX = 'INBOX'
export const RESOLVED = 'RESOLVED'

export const TEAM_MAIN_NAVIGATION_ELEMENTS = [
    {
        id: INBOX,
        title: 'team-inbox-navigation-item-inbox',
        icon: 'leads'
    }
] as const

export const PATIENTS_MAIN_NAVIGATION_ELEMENTS = [
    {
        id: INBOX,
        title: 'inbox-navigation-item-inbox',
        icon: 'leads'
    },
    {
        id: RESOLVED,
        title: 'inbox-navigation-item-resolved',
        icon: 'check-filled'
    }
] as const

export const EMPTY_STATE = {
    INBOX: {
        title: 'inbox-open-conversations-empty-state-header',
        description: 'inbox-open-conversations-empty-state-body',
        buttonText: null
    },
    RESOLVED: {
        title: 'inbox-resolved-conversations-empty-state-header',
        description: 'inbox-resolved-conversations-empty-state-body',
        buttonText: 'inbox-resolved-conversations-empty-state-cta'
    },
    CATEGORY: {
        title: 'inbox-filtered-categories-empty-state-header',
        description: 'inbox-filtered-categories-empty-state-body',
        buttonText: 'inbox-filtered-categories-empty-state-cta'
    }
} as const

export const MAX_CHAT_SESSION_RESTART_COUNT = 3
