export enum NewServiceMethodValue {
    Mixed = 'mixed',
    New = 'new',
    Select = 'select'
}

export enum ServiceType {
    DuplicatedService = 'duplicatedService',
    NonDuplicatedService = 'nonDuplicatedService'
}
