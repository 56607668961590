import { AxiosError } from 'axios'

import { ErrorToTransform, MessageGenerator } from '@/api/utils/interceptors/types/interceptors.interfaces'
import { getNormalisedPath } from '@/api/utils/interceptors/utils/getNormalisedPath'

type MessageGeneratorFunction = (error: AxiosError, errorToTransform: ErrorToTransform) => AxiosError

export const errorMessageGenerators: Record<MessageGenerator, MessageGeneratorFunction> = {
    generateGenericErrorMessage: (error, errorFound) => {
        const sourcePath = getNormalisedPath(error.config?.url || '')
        const sourceMethod = error?.config?.method || 'unknown'
        const statusCode = errorFound.override?.statusCode || error?.response?.status || ''
        error.message = `${errorFound.errorType} ${statusCode} method:${sourceMethod} '/${sourcePath}'`
        return error
    },
    generate400ErrorMessage: (error: AxiosError<any, any>, errorFound) => {
        const name = `HTTP Error: ${errorFound.errorType}`
        const sourcePath = getNormalisedPath(error.config?.url || '')
        const sourceMethod = error?.config?.method || 'unknown'
        const description = `${
            error.response?.data.message || error.message
        } ${sourceMethod.toUpperCase()}:'/${sourcePath}'`

        error.name = name
        error.message = description
        return error
    }
}
