import { ROUTE_CALENDAR_BASE } from '@/features/calendar/ui/constants/routes.constants'

export const CHAT_COMPONENT_SIZE_MINIMIZED = 2
export const CHAT_COMPONENT_SIZE_SMALL = 3
export const CHAT_COMPONENT_SIZE_LARGE = 4

export const CHAT_COMPONENT_SIZE_MINIMIZED_PX = 400
export const CHAT_COMPONENT_SIZE_SMALL_PX = 400
export const CHAT_COMPONENT_SIZE_LARGE_PX = 600

export const ROUTES_MINIMIZING_CHAT_COMPONENTS = [ROUTE_CALENDAR_BASE, '/settings', '/messages-settings']

export const EVENT_LAUNCH_CHAT_COMPONENT = 'LAUNCH_CHAT_COMPONENT'
export const EVENT_MINIMIZE_CHAT_COMPONENT = 'MINIMIZE_CHAT_COMPONENT'
export const EVENT_REMOVE_ALL_CHAT_COMPONENTS = 'REMOVE_ALL_CHAT_COMPONENTS'
export const EVENT_CHAT_UPDATE_UNREAD_COUNTER = 'CHAT_UPDATE_UNREAD_COUNTER'

export const SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE = 'saasInternalChatDisclaimer'

export const EVENT_SWITCH_CHAT_IMPERSONATOR_SESSION = 'EVENT_SWITCH_CHAT_IMPERSONATOR_SESSION'
