import {
    GET_CHAT_IMPERSONATOR_SESSION,
    GET_CHAT_IMPERSONATOR_USER,
    GET_CHAT_SESSION,
    GET_CHAT_USER,
    GET_IS_CHAT_SESSION_LOADING
} from '@/features/chat/ui/store/session/types/getters'

const getters = {
    [GET_CHAT_IMPERSONATOR_SESSION]: state => state.chatImpersonatorSession,
    [GET_CHAT_IMPERSONATOR_USER]: state => state.chatImpersonatorUser,
    [GET_CHAT_SESSION]: state => state.chatSession,
    [GET_CHAT_USER]: state => state.chatUser,
    [GET_IS_CHAT_SESSION_LOADING]: state => state.isChatSessionLoading
}

export default getters
