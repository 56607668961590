import VueRouter, { NavigationGuardNext, Route } from 'vue-router'

import { ONBOARDING_PROPERTY_NAME } from '@/features/onboardings/ui/components/constants'
import { ReportPathname, ReportQueryParam } from '@/features/reports/domain/enums/reports.enums'
import ReportsService from '@/features/reports/domain/reports.service'
import { REPORT_QUERY_PARAM_KEYS } from '@/features/reports/ui/constants/reports.constants'

export const setDefaultReportsQueryParams = ({
    to,
    next,
    router
}: {
    next: NavigationGuardNext
    router: VueRouter
    to: Route
}) => {
    const { name, query } = to

    const queryParams = Object.keys(query || {}).filter(key =>
        REPORT_QUERY_PARAM_KEYS.includes(key as ReportQueryParam)
    )

    if (name && queryParams.length === 0) {
        const defaultQueryByPathName = ReportsService.getDefaultQueryParametersByPathname(name as ReportPathname)

        return next({
            replace: true,
            name,
            query: {
                ...defaultQueryByPathName,
                [ONBOARDING_PROPERTY_NAME]: query[ONBOARDING_PROPERTY_NAME]
            }
        })
    }

    return next()
}
