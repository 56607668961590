import { MappedReportsHintObject } from '../../../domain/interfaces/hints.interfaces'

export enum GetterTypes {
    GetHint = 'GET_HINT',
    GetHintReady = 'GET_HINT_READY'
}

export type HintsGetters = {
    [GetterTypes.GetHint]: (hint: string) => MappedReportsHintObject[]
    [GetterTypes.GetHintReady]: () => boolean
}
