import MessagesService from '@/features/settings/domain/messages.service'
import { ActionsAdaptor } from '@/interfaces'

import {
    ActionTypes,
    MessagesNotificationsSettingsActions as Actions,
    MessagesNotificationsSettingsMutations as Mutations,
    MessagesNotificationsSettingsState as State,
    MutationTypes
} from './types'

export const actions: ActionsAdaptor<Actions, Mutations, State> = {
    async [ActionTypes.CreateOutOfOfficeEvent]({ commit, dispatch }, { event }) {
        try {
            const outOfOfficeEvent = await MessagesService.createOutOfOfficeEvent(event)
            dispatch(ActionTypes.UpdateEventStore, outOfOfficeEvent)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.DeleteOutOfOfficeEvent]({ commit, dispatch }, { eventId }) {
        try {
            await MessagesService.deleteExistingOutOfOfficeEvent(eventId)
            dispatch(ActionTypes.FetchOutOfOfficeEvent)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.FetchOutOfOfficeEvent]({ commit, dispatch }) {
        try {
            const event = await MessagesService.getOutOfOfficeSilencedNotificationSetting()
            dispatch(ActionTypes.UpdateEventStore, event)
        } catch (error) {
            dispatch(ActionTypes.UpdateEventStore, null)
        }
    },
    async [ActionTypes.UpdateOutOfOfficeEvent]({ commit, dispatch }, { event }) {
        try {
            const outOfOfficeEvent = await MessagesService.updateExistingOutOfOfficeEvent(event)
            dispatch(ActionTypes.UpdateEventStore, outOfOfficeEvent)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.UpdateEventStore]({ commit }, event) {
        commit(MutationTypes.UpdateOutOfOfficeEvent, event)
    },

    // =======================
    // DO_NOT_DISTURB FEATURE
    // =======================

    async [ActionTypes.AddSchedule]({ dispatch }, schedule) {
        try {
            await MessagesService.createSchedule(schedule)
            dispatch(ActionTypes.FetchScheduleNotifications)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.DeleteSchedule]({ dispatch }) {
        try {
            await MessagesService.deleteSchedule()
            dispatch(ActionTypes.FetchScheduleNotifications)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.FetchScheduleNotifications]({ commit, dispatch }) {
        try {
            const schedule = await MessagesService.getNotificationSchedule()
            dispatch(ActionTypes.UpdateSessionSchedule, schedule)
        } catch (error) {
            dispatch(ActionTypes.UpdateSessionSchedule, null)
        }
    },
    async [ActionTypes.UpdateSchedule]({ dispatch }, schedule) {
        try {
            await MessagesService.deleteSchedule()
            dispatch(ActionTypes.AddSchedule, schedule)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [ActionTypes.UpdateSessionSchedule]({ commit }, schedule) {
        if (schedule.length > 0) {
            const isAlwaysOn = await MessagesService.isScheduleAlwaysOn()
            commit(MutationTypes.UpdateIsAlwaysOn, isAlwaysOn)
        }
        commit(MutationTypes.UpdateSessionSchedule, schedule)
    },
    async [ActionTypes.UpdateOnEditMode]({ commit }, { isOnEditMode }) {
        commit(MutationTypes.UpdateOnEditMode, isOnEditMode)
    }
}
