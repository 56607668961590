import Vue from 'vue'

import { getDefaultState } from '@/features/privacyPolicy/ui/store/state'
import {
    CLEAR_STATE,
    DELETE_PRIVACY_POLICY_FILE,
    SAVE_SETTINGS,
    SET_SETTINGS
} from '@/features/privacyPolicy/ui/store/types/mutations'

export const mutations = {
    [SET_SETTINGS](state, updatedSettings) {
        state.settings = {
            ...state.settings,
            ...updatedSettings
        }
    },

    [DELETE_PRIVACY_POLICY_FILE]({ settings }) {
        settings.privacyFileIds = []
    },
    [SAVE_SETTINGS](state, settings) {
        Vue.set(state, 'savedSettings', { ...settings })
    },
    [CLEAR_STATE](state) {
        Object.assign(state, getDefaultState())
    }
}
