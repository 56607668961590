export enum CountryCodes {
    AR = 'AR',
    BR = 'BR',
    CL = 'CL',
    CO = 'CO',
    CZ = 'CZ',
    DE = 'DE',
    ES = 'ES',
    IT = 'IT',
    MX = 'MX',
    PE = 'PE',
    PL = 'PL',
    PT = 'PT',
    TR = 'TR'
}
