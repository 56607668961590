import { OutOfOfficeEvent } from '@/features/settings/ui/interfaces/disableNotifications.interfaces'
import { Schedule } from '@/features/settings/ui/interfaces/doNotDisturbContent.interfaces'

import { MessagesNotificationsSettingsState as State } from './state'

export enum MutationTypes {
    UpdateIsAlwaysOn = 'UPDATE_IS_ALWAYS_ON',
    UpdateOnEditMode = 'UPDATE_ON_EDIT_MODE',
    UpdateOutOfOfficeEvent = 'UPDATE_OUT_OF_OFFICE_EVENT',
    UpdateSessionSchedule = 'UPDATE_SCHEDULED_MESSAGES_NOTIFICATIONS'
}

export type MessagesNotificationsSettingsMutations = {
    [MutationTypes.UpdateIsAlwaysOn](state: State, isAlwaysOn: boolean): void
    [MutationTypes.UpdateOnEditMode](state: State, isOnEditMode: boolean): void
    [MutationTypes.UpdateOutOfOfficeEvent](state: State, event: OutOfOfficeEvent | null): void
    [MutationTypes.UpdateSessionSchedule](state: State, schedule: Schedule[]): void
}
