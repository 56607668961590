import { types } from '@docplanner/one-tracking'
import { tools } from '@docplanner/one-tracking/dist/tools'

import { ProfileEntity } from '@/features/auth/domain/interfaces/profile.interfaces'
/* eslint-enable import/extensions */
import { useDebugMode } from '@/plugins/debug/composables/useDebugMode'

/* eslint-disable import/extensions */
// @ts-expect-error: can't resolve, module not found when angular+vue build
import * as ga from './providers/ga.ts'
// @ts-expect-error: can't resolve, module not found when angular+vue build
import * as getSiteControl from './providers/getSiteControl.ts'
// @ts-expect-error: can't resolve, module not found when angular+vue build
import * as hubspot from './providers/hubspot.ts'
// @ts-expect-error: can't resolve, module not found when angular+vue build
import * as mixpanel from './providers/mixpanel.ts'
import { EventProperties } from './types'
// @ts-expect-error: can't resolve, module not found when angular+vue build
export { trackPage, track as trackTagManager } from './providers/gtm.ts'

const defaultEventProperties = {
    properties: {}
}

const { isTrackingEventsEnabled } = useDebugMode()

export const track = (event: types.ExtendedEvent | types.Event) => {
    if (isTrackingEventsEnabled) {
        // eslint-disable-next-line no-console
        console.log('Event Tracking: ', event)
    }

    if (tools.getIsToolAllowed({ event, toolId: types.ToolTypes.MixPanel })) {
        mixpanel.trackEvent(event)
    }

    if (tools.getIsToolAllowed({ event, toolId: types.ToolTypes.Hubspot })) {
        hubspot.trackEvent(event)
    }

    if (
        tools.getIsToolAllowed({
            event,
            toolId: types.ToolTypes.GoogleAnalytics
        })
    ) {
        ga.trackEvent(event)
    }
}

export const trackWithProperties = (
    event: types.ExtendedEvent | types.Event,
    { properties }: EventProperties = defaultEventProperties
) => {
    if (isTrackingEventsEnabled) {
        // eslint-disable-next-line no-console
        console.log('Event Tracking with properties: ', event, properties)
    }

    if (tools.getIsToolAllowed({ event, toolId: types.ToolTypes.MixPanel })) {
        mixpanel.trackEvent(event, properties)
    }

    if (tools.getIsToolAllowed({ event, toolId: types.ToolTypes.Hubspot })) {
        hubspot.trackEvent(event, properties)
    }

    ga.trackEvent(event, { properties })
}

export const initialize = (user: ProfileEntity) => {
    if (isTrackingEventsEnabled) {
        // eslint-disable-next-line no-console
        console.log('Setup Event Tracking user: ', user)
    }

    mixpanel.initialize(user)
    ga.initialize(user)
    getSiteControl.initialize(user)
}
