import { get, merge, set } from 'lodash'

import { isArray } from '@/utils/array'

export const isPlainObject = object => {
    return object !== null && object !== undefined && object.constructor === Object && typeof object === 'object'
}

export const areEqualPlainObjects = (a, b) => {
    return isPlainObject(a) && isPlainObject(b) && JSON.stringify(a) === JSON.stringify(b)
}

export const mergeDeep = (target, source) => {
    if (!isPlainObject(target) || !isPlainObject(source)) {
        return source
    }

    return merge(target, source)
}

export const getValueFromPath = (object, path, defaultValue = null) => {
    return get(object, path, defaultValue)
}

export const setValueFromPath = (object, path, value) => {
    return set(object, path, value)
}

export const deepCopy = input => {
    if (!isPlainObject(input)) {
        return input
    }

    const output = {}

    Object.keys(input).forEach(key => {
        const value = input[key]
        output[key] = isArray(value) ? value.map(deepCopy) : deepCopy(value)
    })

    return output
}

export const combineObjects = ({ entities = [], key }) =>
    entities.reduce(
        (output, entity) => ({
            ...output,
            ...((entity || {})[key] || {})
        }),
        {}
    )

export const notEmptyObject = object => {
    return Object.keys(object).length > 0
}
