import { truncate } from '@/utils/string'

import internalChatRepository from '../api/internalChat.api'
import {
    ConversationsResponse,
    SaasInternalConversation,
    UnreadConversationsResponse
} from './interfaces/conversations.interfaces'
import { Contact } from './interfaces/internalChat.interfaces'

class InternalChatService {
    getContactsByFacilityId(facilityId: number): Promise<Contact[]> {
        return internalChatRepository.getContactsByFacilityId(facilityId)
    }

    async getFacilities(): Promise<any> {
        const facilities = await internalChatRepository.getFacilities()
        return facilities.map(({ name, address, id }) => ({
            label: `${truncate(name, 30)} ${address}`,
            id
        }))
    }

    async getConversationByConversationId(conversationId: string): Promise<any> {
        const { members, providerId } = await internalChatRepository.getConversationByConversationId(conversationId)

        return {
            members: this.mapMembers(members),
            channelUrl: providerId
        }
    }

    async getConversationByContactAndFacilityId(contactUserId: number, facilityId: number): Promise<any> {
        const { members, providerId } = await internalChatRepository.getConversationByContactAndFacilityId(
            contactUserId,
            facilityId
        )

        return {
            members: this.mapMembers(members),
            channelUrl: providerId
        }
    }

    mapMembers(members: Array<any>) {
        return members.map(({ displayName, avatar, providerId, userId }: any) => ({
            name: displayName,
            id: providerId,
            avatar,
            userId
        }))
    }

    async getAllConversations(
        limit: number,
        token: string | null
    ): Promise<ConversationsResponse<SaasInternalConversation>> {
        const { conversations, providerNextToken } = await internalChatRepository.getAllConversations(limit, token)
        const normalizedConversations = conversations.map(({ id, isUnread, recipients, lastMessage }: any) => {
            const recipientsNames = recipients.map(({ displayName }: any) => displayName).join(', ')
            const chatMessengerId = recipients[0]?.userId || id
            return {
                id,
                isUnread, // todo remove once patient chat is migrated
                chatMessengerId,
                actions: [],
                props: {
                    recipientsNames: truncate(recipientsNames, 65),
                    tag: '',
                    createdAt: lastMessage.createdAt,
                    previewMessage: lastMessage.content
                }
            }
        })
        return {
            conversations: normalizedConversations,
            nextToken: providerNextToken
        }
    }

    getUnreadConversations(): Promise<UnreadConversationsResponse> {
        return internalChatRepository.getUnreadConversations()
    }
}

export default new InternalChatService()
