import {
    DocumentationGTemplatesRoutePaths,
    DocumentationTemplatesRouteNames
} from '@/features/settings/ui/enums/documentation.templates.enums'

const DocumentationTemplatesIndex = () =>
    import(
        /* webpackChunkName: "documentation-templates-index" */ '@/features/settings/ui/views/documentationTemplates/DocumentationTemplatesIndex.vue'
    )
const DocumentationTemplatesList = () =>
    import(
        /* webpackChunkName: "documentation-templates-list" */ '@/features/settings/ui/views/documentationTemplates/DocumentationTemplatesList.vue'
    )
const DocumentationTemplatesView = () =>
    import(
        /* webpackChunkName: "documentation-templates-view" */ '@/features/settings/ui/views/documentationTemplates/DocumentationTemplatesView.vue'
    )
const DocumentationTemplatesEditor = () =>
    import(
        /* webpackChunkName: "documentation-templates-list" */ '@/features/settings/ui/views/documentationTemplates/DocumentationTemplatesEditor.vue'
    )
const DocumentationTemplatesClinicData = () =>
    import(
        /* webpackChunkName: "documentation-templates-clinic-data" */ '@/features/settings/ui/views/documentationTemplates/DocumentationTemplatesClinicData.vue'
    )

export default {
    path: DocumentationGTemplatesRoutePaths.Index,
    component: DocumentationTemplatesIndex,
    children: [
        {
            name: DocumentationTemplatesRouteNames.List,
            path: DocumentationGTemplatesRoutePaths.List,
            component: DocumentationTemplatesList
        },
        {
            name: DocumentationTemplatesRouteNames.New,
            path: DocumentationGTemplatesRoutePaths.New,
            component: DocumentationTemplatesEditor
        },
        {
            name: DocumentationTemplatesRouteNames.Edit,
            path: DocumentationGTemplatesRoutePaths.Edit,
            component: DocumentationTemplatesEditor
        },
        {
            name: DocumentationTemplatesRouteNames.View,
            path: DocumentationGTemplatesRoutePaths.View,
            component: DocumentationTemplatesView
        },
        {
            name: DocumentationTemplatesRouteNames.ClinicData,
            path: DocumentationGTemplatesRoutePaths.ClinicData,
            component: DocumentationTemplatesClinicData
        }
    ]
}
