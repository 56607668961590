import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { ReportsDataPage } from '../domain/interfaces/reports.interfaces'

const URL_BASE = 'reports'

const URL_APPOINTMENT_ID = `${URL_BASE}/appointment/:id`
const URL_APPOINTMENT_BOOKING_STATUS = `${URL_APPOINTMENT_ID}/bookingstatus/:bookingStatusId`
const URL_APPOINTMENT_SERVICE = `${URL_APPOINTMENT_ID}/service/:serviceId`
const URL_APPOINTMENT_PRICE = `${URL_APPOINTMENT_ID}/price`
const URL_APPOINTMENT_PRICE_AMOUNT = `${URL_APPOINTMENT_PRICE}/:price`
const URL_APPOINTMENT_INSURANCE = `${URL_APPOINTMENT_ID}/insurance`
const URL_APPOINTMENT_INSURANCE_ID = `${URL_APPOINTMENT_INSURANCE}/:insuranceId`
const URL_APPOINTMENT_SERVICES = `${URL_APPOINTMENT_ID}/event-services`

class AppointmentRepository {
    async updateBookingStatus(appointmentId: string, bookingStatusId: string): Promise<ReportsDataPage> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_BOOKING_STATUS,
            params: {
                id: appointmentId,
                bookingStatusId
            }
        })

        const { data } = await httpAPI.put(url)

        return data
    }

    async updateService(appointmentId: string, serviceId: string): Promise<ReportsDataPage> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_SERVICE,
            params: {
                id: appointmentId,
                serviceId
            }
        })

        const { data } = await httpAPI.put(url)

        return data
    }

    async updatePrice(appointmentId: string, price: string): Promise<ReportsDataPage> {
        if (price) {
            const url = getUrlFromTemplate({
                template: URL_APPOINTMENT_PRICE_AMOUNT,
                params: {
                    id: appointmentId,
                    price
                }
            })

            const { data } = await httpAPI.put(url)

            return data
        }

        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_PRICE,
            params: {
                id: appointmentId
            }
        })

        const { data } = await httpAPI.delete(url)

        return data
    }

    async updateInsurance(appointmentId: string, insuranceId: string): Promise<ReportsDataPage> {
        if (insuranceId) {
            const url = getUrlFromTemplate({
                template: URL_APPOINTMENT_INSURANCE_ID,
                params: {
                    id: appointmentId,
                    insuranceId
                }
            })

            const { data } = await httpAPI.put(url)

            return data
        }

        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_INSURANCE,
            params: {
                id: appointmentId
            }
        })

        const { data } = await httpAPI.delete(url)

        return data
    }

    async updateEventServices(id: number, serviceIds: { serviceId: number }[]): Promise<ReportsDataPage> {
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENT_SERVICES,
            params: { id }
        })

        const { data } = await httpAPI.put(url, { eventServices: serviceIds })

        return data
    }
}

export default new AppointmentRepository()
