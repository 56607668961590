import Vue from 'vue'

import {
    SET_CHAT_IMPERSONATOR_SESSION,
    SET_CHAT_IMPERSONATOR_USER,
    SET_CHAT_SESSION,
    SET_CHAT_SESSION_LOADING,
    SET_CHAT_USER
} from '@/features/chat/ui/store/session/types/mutations'

const mutations = {
    [SET_CHAT_IMPERSONATOR_USER](state, payload) {
        Vue.set(state, 'chatImpersonatorUser', payload)
    },
    [SET_CHAT_IMPERSONATOR_SESSION](state, payload) {
        Vue.set(state, 'chatImpersonatorSession', payload)
    },
    [SET_CHAT_USER](state, payload) {
        Vue.set(state, 'chatUser', payload)
    },
    [SET_CHAT_SESSION](state, payload) {
        Vue.set(state, 'chatSession', payload)
    },
    [SET_CHAT_SESSION_LOADING](state, isLoading) {
        state.isChatSessionLoading = isLoading
    }
}

export default mutations
