import { ReportStatisticItem } from './reports.interfaces'

export enum PaymentStatus {
    Paid = 1,
    Unpaid = 0
}

export interface PaymentStatusOption {
    text: string
    value: PaymentStatus
}

export interface Expense {
    amount: number
    category: number
    description: string
    doctor: number
    name: string
    paymentDate: string
    paymentStatus: PaymentStatus
}

export interface ExpenseCategory {
    id: number
    name: string
}

export interface ExpenseCategoryOption {
    isDefault: boolean
    text: string
    value: number
}

export interface ExpenseStatistics {
    paid: ReportStatisticItem
    total: ReportStatisticItem
    unpaid: ReportStatisticItem
}
