import { REPORTS_CATEGORY_TYPE_KEY } from '@/features/reports/ui/constants/reports.constants'

import repository from '../api/expenses.api'
import { Expense, ExpenseCategoryOption, ExpenseStatistics } from './interfaces/expenses.interfaces'
import { ReportsParameters } from './interfaces/reports.interfaces'

class ExpensesService {
    async postExpense({ payload }: { payload: Expense }): Promise<void> {
        await repository.postExpense(payload)
    }

    async putExpense({ expenseId, payload }: { expenseId: string; payload: Expense }): Promise<void> {
        await repository.putExpense(expenseId, payload)
    }

    async deleteExpense({ expenseId }: { expenseId: string }): Promise<void> {
        await repository.deleteExpense(expenseId)
    }

    async getExpensesCategories(): Promise<ExpenseCategoryOption[]> {
        const data = await repository.getExpensesCategories()

        return data.map(({ id, name }) => ({
            value: id,
            text: name,
            isDefault: name.includes(REPORTS_CATEGORY_TYPE_KEY)
        }))
    }

    postExpensesCategories(categoryName: string): Promise<void> {
        return repository.postExpensesCategories(categoryName)
    }

    deleteExpensesCategories(categoryId: string): Promise<void> {
        return repository.deleteExpensesCategories(categoryId)
    }

    getExpensesAggregates(args: ReportsParameters): Promise<ExpenseStatistics> {
        return repository.getExpensesAggregates(args)
    }
}

export default new ExpensesService()
