import { PMS_ROUTES } from '@/features/pms/ui/constants/routes.constants'

const Pms = () => import(/* webpackChunkName: "patient-manager-system" */ '@/features/pms/ui/views/PmsIndex.vue')
const PmsEpisodeEdit = () =>
    import(/* webpackChunkName: "patient-episode-edit" */ '@/features/pms/ui/views/PmsEpisodeEdit.vue')
const PmsEpisodeCreate = () =>
    import(/* webpackChunkName: "patient-episode-create" */ '@/features/pms/ui/views/PmsEpisodeCreate.vue')

export default {
    name: 'pms',
    path: 'patient-manager-system/patient/:patientId',
    component: Pms,
    children: [
        {
            path: 'episode/new',
            name: PMS_ROUTES.NEW_EPISODE_NO_EVENT,
            component: PmsEpisodeCreate
        },
        {
            path: 'episode/new/event/:eventId',
            name: PMS_ROUTES.NEW_EPISODE,
            component: PmsEpisodeCreate
        },
        {
            path: 'episode/:episodeId',
            name: PMS_ROUTES.EDIT_EPISODE_NO_EVENT,
            component: PmsEpisodeEdit
        },
        {
            path: 'episode/:episodeId/event/:eventId',
            name: PMS_ROUTES.EDIT_EPISODE,
            component: PmsEpisodeEdit
        }
    ]
}
