import { GettersAdaptor } from '@/interfaces'

import {
    GetterTypes,
    MessagesNotificationsSettingsGetters as Getters,
    MessagesNotificationsSettingsState as State
} from './types'

export const getters: GettersAdaptor<Getters, State, void> = {
    [GetterTypes.IsOnHolidayNotificationsSilenced]: state => {
        const { outOfOfficeEvent } = state
        return !!outOfOfficeEvent && outOfOfficeEvent.enabled
    },
    [GetterTypes.IsStatusScheduleNotificationActive]: state => {
        return state.notificationsSchedule.length > 0
    }
}
