import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { ENTITY_SCOPES, LAYOUT_SCOPES } from '@/features/settings/ui/constants/printLayout.constants'
import { combineArrayObjects } from '@/utils/array'
import { combineObjects } from '@/utils/object'

import {
    GETTER_BODY_OPTION_VALUES,
    GETTER_DATA_SETTINGS,
    GETTER_DATA_VALUES,
    GETTER_FOOTER_OPTION_VALUES,
    GETTER_HAS_PERMISSION_PRINT_LAYOUT,
    GETTER_HEADER_OPTION_VALUES,
    GETTER_IS_READ_ONLY,
    GETTER_LAYOUT_SETTINGS,
    GETTER_LOGOS_TO_UPLOAD_AT_SAVE
} from './types/getters'

const getters = {
    [GETTER_LAYOUT_SETTINGS]: state => state.layoutSettings,
    [GETTER_DATA_SETTINGS]: state => state.dataSettings,

    [GETTER_IS_READ_ONLY]: (state, _getters, _rootState, rootGetters) => {
        if (state.isClinicSchedule) {
            return !rootGetters[AuthGetterTypes.CanManageMedicalCenter]
        }

        return !rootGetters.hasAccessToEditPrintLayout
    },

    [GETTER_HAS_PERMISSION_PRINT_LAYOUT]: (state, _getters, _rootState, rootGetters) =>
        rootGetters[AuthGetterTypes.IsFakeClinic] && !state.isClinicSchedule,
    [GETTER_HEADER_OPTION_VALUES]: ({ defaultValues, layoutSettings, responseOptions }) =>
        combineObjects({
            entities: [defaultValues?.layout, responseOptions?.layout, layoutSettings],
            key: LAYOUT_SCOPES.HEADER
        }),

    [GETTER_FOOTER_OPTION_VALUES]: ({ defaultValues, layoutSettings, responseOptions }) =>
        combineObjects({
            entities: [defaultValues?.layout, responseOptions?.layout, layoutSettings],
            key: LAYOUT_SCOPES.FOOTER
        }),

    [GETTER_BODY_OPTION_VALUES]: ({ defaultValues, layoutSettings, responseOptions }) =>
        combineObjects({
            entities: [defaultValues?.layout, responseOptions?.layout, layoutSettings],
            key: LAYOUT_SCOPES.BODY
        }),

    [GETTER_DATA_VALUES]: ({ defaultValues, dataSettings, responseOptions }) => {
        const clinic = combineObjects({
            entities: [defaultValues?.data, responseOptions?.data, dataSettings],
            key: ENTITY_SCOPES.CLINIC
        })

        return {
            patient: combineObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.PATIENT
            }),

            doctor: combineObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.DOCTOR
            }),

            clinic,

            clinicCustomData: combineObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.CLINIC_CUSTOM_DATA
            }),

            doctorCustomData: combineObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.DOCTOR_CUSTOM_DATA
            }),

            clinicAdditionalData: combineArrayObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.CLINIC_ADDITIONAL_DATA
            }),

            doctorAdditionalData: combineArrayObjects({
                entities: [defaultValues?.data, responseOptions?.data, dataSettings],
                key: ENTITY_SCOPES.DOCTOR_ADDITIONAL_DATA
            })
        }
    },

    [GETTER_LOGOS_TO_UPLOAD_AT_SAVE]: state => state.logoImagesToUpload
}

export default getters
