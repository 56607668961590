import { FacilityRoomEntity } from '../../../domain/interfaces/rooms.interface'

export enum RoomsActionTypes {
    CreateRoom = 'ACTION_CREATE_ROOM',
    DeleteRoom = 'ACTION_DELETE_ROOM',
    FetchRooms = 'ACTION_FETCH_ROOMS',
    UpdateRoom = 'ACTION_UPDATE_ROOM'
}

export type RoomsActions = {
    [RoomsActionTypes.FetchRooms](payload: { addPrefixToId: boolean }): Promise<void>
    [RoomsActionTypes.DeleteRoom](room: FacilityRoomEntity): Promise<void>
    [RoomsActionTypes.CreateRoom](room: FacilityRoomEntity): Promise<number>
    [RoomsActionTypes.UpdateRoom](room: FacilityRoomEntity): Promise<void>
}
