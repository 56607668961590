import { RumActionEventDomainContext } from '@datadog/browser-rum'

const ATTRIBUTES_LIST = ['data-testid', 'class']

const getAttributeName = (target: HTMLElement | null) => {
    const attribute = ATTRIBUTES_LIST.find(name => target?.hasAttribute(name)) || ''
    return {
        name: attribute,
        value: target?.getAttribute(attribute)
    }
}

const getAttribute = (target: HTMLElement | null) => {
    if (!getAttributeName(target).name || !target) {
        return null
    }
    const { tagName } = target
    return `${tagName}[${getAttributeName(target).name}='${getAttributeName(target).value}']`
}

export const getTargetName = (context: RumActionEventDomainContext) => {
    if (!context?.events?.length) {
        return ''
    }

    const target = context?.events[0].target as HTMLElement
    return getAttribute(target) || getAttribute(target.closest('[data-testid], [class]'))
}
