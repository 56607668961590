const Notifications = () =>
    import(/* webpackChunkName: "notifications-settings" */ '../views/notifications/NotificationsSettings.vue')

export default [
    {
        path: 'settings-notifications',
        name: 'settings-notifications',
        component: Notifications
    }
]
