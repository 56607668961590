import repository from '@/features/pms/api/ehrTemplates.api'
import { TemplateFields } from '@/features/pms/domain/enums/ehrTemplates.enums'
import { ehrTemplate, ehrTemplateField } from '@/features/pms/domain/interfaces/ehrTemplates.interfaces'

class EHRTemplatesService {
    async getTemplates(): Promise<any> {
        const { data: templates } = await repository.getTemplates()
        return templates
    }

    createTemplate(templateData: ehrTemplate): Promise<any> {
        return repository.createTemplate(templateData)
    }

    getTemplate(templateId: number): Promise<any> {
        return repository.getTemplate(templateId)
    }

    updateTemplate(templateId: number, templateData: ehrTemplate): Promise<any> {
        return repository.updateTemplate(templateId, templateData)
    }

    removeTemplate(templateId: number): Promise<any> {
        return repository.removeTemplate(templateId)
    }

    getTemplateForm(templateId: number, patientId: number, episodeId: number, eventId: number): Promise<any> {
        return repository.getTemplateForm(templateId, patientId, episodeId, eventId)
    }

    getSmartTextProperties() {
        return repository.getSmartTextProperties()
    }

    getDocumentFieldValue(field: ehrTemplateField) {
        const isSmartText = field.type === TemplateFields.SmartText
        return isSmartText ? field.config?.text : null
    }
}

export default new EHRTemplatesService()
