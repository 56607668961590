import { ROUTE_DASHBOARD, ROUTE_DASHBOARD_BASE_NAME } from '@/features/dashboard/ui/constants/routes.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'
import viewCounter from '@/features/reports/ui/middlewares/viewCounter'

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/features/dashboard/ui/views/Dashboard.vue')

export default {
    path: ROUTE_DASHBOARD_BASE_NAME,
    name: ROUTE_DASHBOARD,
    component: Dashboard,
    beforeEnter: viewCounter,
    meta: {
        title: 'report-dashboard-clinics-dashboard-main-title',
        middleware: uninav,
        uninavName: 'dashboard',
        trackView: true
    }
}
