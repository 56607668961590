import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import {
    CampaignsReportGroup,
    CampaignsReportGroupItemData,
    CampaignsSummaryParams,
    CampaignsSummaryResponse,
    MonthlyCampaignMetrics
} from '@/features/campaigns/domain/interfaces/campaigns.interfaces'
import {
    CampaignsRecipientsParams,
    CampaignsRecipientsTable
} from '@/features/campaigns/domain/interfaces/campaignsRecipients.interfaces'
import { parseParams } from '@/utils/parseParams'

const URL_BASE = 'campaigns'
const URL_CAMPAIGN_ID = `${URL_BASE}/:id`
const URL_CAMPAIGN_ITEM_ID = `${URL_BASE}/item/:itemId`
const URL_RECIPIENTS = `${URL_BASE}/recipients`
const URL_MONTHLY_CAMPAIGN_METRICS = `${URL_BASE}/get-sent-campaign-info`
const URL_FILTERS = `${URL_BASE}/filters`

class CampaignsRepository {
    async getCampaign(campaignId: number): Promise<CampaignsSummaryResponse> {
        const url = getUrlFromTemplate({
            template: URL_CAMPAIGN_ID,
            params: {
                id: campaignId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getCampaigns(): Promise<CampaignsReportGroup[]> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }

    async getCampaignItem(campaignItemId: number): Promise<CampaignsReportGroupItemData> {
        const url = getUrlFromTemplate({
            template: URL_CAMPAIGN_ITEM_ID,
            params: {
                itemId: campaignItemId
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getFilters(): Promise<any> {
        const { data } = await httpAPI.get(URL_FILTERS)
        return data
    }

    async postCampaigns(params: CampaignsSummaryParams) {
        await httpAPI.post(URL_BASE, params)
    }

    async getRecipients(params: CampaignsRecipientsParams): Promise<CampaignsRecipientsTable> {
        const url = getUrlFromTemplate({
            template: URL_RECIPIENTS,
            query: parseParams({ ...params })
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getMonthlyCampaignMetrics(): Promise<MonthlyCampaignMetrics> {
        const { data } = await httpAPI.get(URL_MONTHLY_CAMPAIGN_METRICS)
        return data
    }
}

export default new CampaignsRepository()
