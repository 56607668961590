import httpAPI from '@/api/utils/httpClientAPI'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'

import { AuthTokenDTO, ProviderDTO } from './interfaces/auth.DTO'
import { ProfileDTO } from './interfaces/profile.DTO'

const URL_BASE_AUTH = 'auth'
const URL_BASE_ACCOUNT = 'account'
const URL_BASE_PROFILE = 'profile'

const URL_PROVIDER = `${URL_BASE_AUTH}/provider`
const URL_WEB_TOKEN_LOGIN = `${URL_BASE_ACCOUNT}/webtoken-login`
const URL_LOGIN = `${URL_BASE_ACCOUNT}/login`
const URL_LOGIN_V2 = `v2/${URL_BASE_ACCOUNT}/login`

class AuthRepository {
    async getProfile(): Promise<ProfileDTO> {
        const { data } = await httpAPI.get(URL_BASE_PROFILE)
        return data
    }

    async getProvider(): Promise<ProviderDTO> {
        const { data } = await httpAPI.get(URL_PROVIDER)
        return data
    }

    async getWebToken(code: string, redirectUri: string): Promise<any> {
        const { data } = await httpAPI.get(URL_WEB_TOKEN_LOGIN, {
            params: { code, redirectUri }
        })
        return data
    }

    async login(userId: string): Promise<AuthTokenDTO> {
        const url = ensureToggleIsEnabled('UseSSOTokenOnSaaS', true) ? URL_LOGIN_V2 : URL_LOGIN

        const { data } = await httpAPI.post(url, { userId })
        return data
    }
}

export default new AuthRepository()
