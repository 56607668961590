import { UserSettingsEntity } from '@/features/auth/domain/interfaces/profile.interfaces'

import { COLOR_BY_TYPE_AGENDA, COLOR_THEME_LIGHT } from '../ui/constants/calendar.constants'
import { WEEKDAYS } from '../ui/constants/calendar.settings.constants'
import { CELL_SIZE_DEFAULT } from './constants/calendar.cellsizes.constants'
import { DENSITY_DEFAULT } from './constants/calendar.density.constants'
import { ORDER_DEFAULT } from './constants/calendar.order.constants'
import { CalendarOrderType } from './enums/calendar.enums'

class CalendarSettingsService {
    getCalendarDensity({ calendarDensity }: UserSettingsEntity): string {
        return calendarDensity || DENSITY_DEFAULT
    }

    getCalendarOrder({ calendarOrder }: UserSettingsEntity, hasCustomOrderSet = false): string {
        if (!calendarOrder && hasCustomOrderSet) {
            return CalendarOrderType.Custom
        }

        return calendarOrder || ORDER_DEFAULT
    }

    getCalendarCellSize({ timeScheduleGrid }: UserSettingsEntity): number {
        return timeScheduleGrid || CELL_SIZE_DEFAULT
    }

    getCalendarColorsThemeType({ calendarColorsType }: UserSettingsEntity) {
        if (calendarColorsType) {
            return {
                name: calendarColorsType,
                isDefault: calendarColorsType === COLOR_THEME_LIGHT
            }
        }

        return {
            name: COLOR_THEME_LIGHT,
            isDefault: true
        }
    }

    getCalendarColorsByType({ calendarColorsByType }: UserSettingsEntity) {
        return calendarColorsByType || COLOR_BY_TYPE_AGENDA
    }

    getDefaultWeekdaysVisibility(): Array<boolean> {
        return WEEKDAYS.map(({ isShown }) => isShown)
    }

    getWeekdaysVisibilityArray(data: number): Array<boolean> {
        const weekdaysVisibilityArray = Number(data)
            .toString(2)
            .split('')
            .map(value => parseInt(value, 10) === 1)
        while (weekdaysVisibilityArray.length < 7) {
            weekdaysVisibilityArray.unshift(false)
        }
        return weekdaysVisibilityArray
    }

    processWeekdays(data: number) {
        const weekdaysVisibilityArray = this.getWeekdaysVisibilityArray(data)

        return WEEKDAYS.map((day, index) => {
            return {
                ...day,
                isShown: weekdaysVisibilityArray[index]
            }
        })
    }
}

export default new CalendarSettingsService()
