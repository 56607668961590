import httpAPI from '@/api/utils/httpClientAPI'

import { Appointment, DateRange } from '../domain/interfaces/calendar.interfaces'

const URL_BASE = 'calendarevents'

class CalendarEventsRepository {
    async getCalendarEvents({ from, to }: DateRange, schedules: number[]): Promise<Appointment[]> {
        const { data } = await httpAPI.post(URL_BASE, { from, to, schedules })

        return data
    }
}

export default new CalendarEventsRepository()
