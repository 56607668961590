import { Insurance } from '@/features/insurance/domain/interfaces/insurance.interfaces'
import repository from '@/features/patient/api/insurance.api'
import { InsuranceState } from '@/features/patient/domain/enums/patient-options.enums'

// NOTE: it may not belong to "patient" feature. It will be moved if a "insurance" feature emerges.
class InsuranceService {
    getAllInsurances(): Promise<Insurance[]> {
        return repository.getAllInsurances()
    }

    getInsuranceState(insuranceId: number | string | null): number {
        if (!insuranceId || typeof insuranceId !== 'number') {
            return InsuranceState.Unknown
        }

        if (insuranceId === InsuranceState.Private) {
            return InsuranceState.Private
        }

        return InsuranceState.Insured
    }

    getIsInsured(insuranceId: number | string | null): boolean {
        return this.getInsuranceState(insuranceId) === InsuranceState.Insured
    }

    getIsPrivate(insuranceId: number | string | null): boolean {
        return this.getInsuranceState(insuranceId) === InsuranceState.Private
    }
}

export default new InsuranceService()
