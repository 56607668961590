const Index = () => import(/* webpackChunkName: "patient-import-index" */ '../views/Index.vue')

export default {
    path: 'import',
    name: 'patient-import',
    meta: {
        uninavName: 'patients'
    },
    component: Index
}
