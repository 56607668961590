import { ActionsAdaptor } from '@/interfaces'

import RoomsService from '../../domain/rooms.service'
import { RoomsActions, RoomsActionTypes, RoomsMutations, RoomsMutationTypes, RoomsState } from './types'

const actions: ActionsAdaptor<RoomsActions, RoomsMutations, RoomsState> = {
    async [RoomsActionTypes.FetchRooms]({ commit }, { addPrefixToId }) {
        commit(RoomsMutationTypes.SetIsLoading, true)

        try {
            const rooms = await RoomsService.getRooms({ addPrefixToId })
            commit(RoomsMutationTypes.SetRooms, rooms)
        } catch (error) {
            return Promise.reject(error)
        } finally {
            commit(RoomsMutationTypes.SetIsLoading, false)
        }
    },
    async [RoomsActionTypes.DeleteRoom]({ commit }, room) {
        await RoomsService.deleteRoom(room)
        commit(RoomsMutationTypes.DeleteRoom, room)
    },
    async [RoomsActionTypes.CreateRoom]({ commit }, room) {
        const roomId = await RoomsService.createRoom(room)
        commit(RoomsMutationTypes.CreateRoom, { ...room, id: roomId })
        return roomId
    },
    async [RoomsActionTypes.UpdateRoom]({ commit }, room) {
        await RoomsService.updateRoom(room)
        commit(RoomsMutationTypes.UpdateRoom, room)
    }
}

export default actions
