import { AxiosResponse } from 'axios'

import { Schedule } from '@/features/settings/domain/interfaces/schedule.interfaces'
import SchedulesService from '@/features/settings/domain/schedule.service'
import VoucherService from '@/features/voucher/domain/voucher.service'
import VoucherTypeService from '@/features/voucher/domain/voucherType.service'
import { ActionsAdaptor } from '@/interfaces'

import { ActionTypes, VouchersActions } from './types/actions'
import { MutationTypes, VouchersMutations } from './types/mutations'
import { VouchersState } from './types/state'

export const actions: ActionsAdaptor<VouchersActions, VouchersMutations, VouchersState> = {
    async [ActionTypes.FetchVoucherTypes]({ commit }) {
        commit(MutationTypes.SetLoadingVoucherTypes, true)
        const voucherTypesArrays = await VoucherTypeService.getVoucherTypes()
        commit(MutationTypes.SetAllVoucherTypes, voucherTypesArrays)
        commit(MutationTypes.SetLoadingVoucherTypes, false)
    },
    async [ActionTypes.FetchVoucherTypesPaginated]({ state, commit }) {
        commit(MutationTypes.SetLoading, true)
        const currentpageIndex = state.currentPage - 1
        const { pagesAmount, page } = await VoucherTypeService.getVoucherTypesPaginated(
            {
                active: state.showActive
            },
            currentpageIndex
        )
        commit(MutationTypes.SetTotalPage, pagesAmount)
        commit(MutationTypes.SetVoucherTypesPaginated, page)
        commit(MutationTypes.SetLoading, false)
    },
    async [ActionTypes.FetchVouchers]({ state, commit }) {
        commit(MutationTypes.SetLoading, true)
        const currentpageIndex = state.currentPage - 1
        const { pagesAmount, page, hasFullyRedeemedVouchers } = await VoucherService.getAll(
            state.vouchersFilters,
            currentpageIndex
        )
        commit(MutationTypes.SetHasFullyRedeemedVouchers, hasFullyRedeemedVouchers)
        commit(MutationTypes.SetTotalPage, pagesAmount)
        commit(MutationTypes.SetVouchers, page)
        commit(MutationTypes.SetLoading, false)
    },
    async [ActionTypes.GoToPage]({ commit, dispatch }, { page, action }) {
        commit(MutationTypes.SetCurrentPage, page)
        await dispatch(action)
    },
    async [ActionTypes.ChangeFilter]({ commit, dispatch }, { showActive }) {
        commit(MutationTypes.SetCurrentPage, 1)
        commit(MutationTypes.SetVoucherTypesShowActive, showActive)
        await dispatch(ActionTypes.FetchVoucherTypesPaginated)
    },
    async [ActionTypes.FetchSchedules]({ commit }) {
        const { data }: AxiosResponse<Schedule[]> = await SchedulesService.getSchedules()
        commit(MutationTypes.SetSchedules, data)
    }
}
