export enum FileImportationImportContentId {
    Other = 0,
    Appointments,
    EHR,
    Patients,
    Services,
    WorkingHours,
    ImportationReport,
    DuplicatesReport
}

export enum FileImportationImportMethodId {
    Manual = 1,
    Auto = 2
}

export enum FileImportationImportStatusId {
    None = 0,
    Loading,
    ReadyToImport,
    InProgress,
    Completed,
    Cancelled,
    Error
}

export enum FileImportationProperties {
    Content = 'content',
    CreatedAt = 'createdAt',
    FileName = 'fileName',
    OriginSource = 'originSource',
    Size = 'size',
    Status = 'status',
    UserName = 'userName'
}
