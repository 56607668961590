const STORAGE_NAME = 'dp-setup-guide-welcome-dismissed'
const storage = window.localStorage

export const getLocalStorageValue = (): boolean => {
    const value = storage.getItem(STORAGE_NAME)
    return value ? JSON.parse(value) : false
}

export const setLocalStorageValue = (value: boolean) => {
    try {
        storage.setItem(STORAGE_NAME, JSON.stringify(value))
    } catch (e) {
        storage.clear()
    }
}
