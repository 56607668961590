interface SetupGuideTranslationProgress {
    completed: string
    few: string
    many: string
    one: string
    start: string
}

const TRANSLATION_PROGRESS: SetupGuideTranslationProgress = {
    start: 'saas-setup-guide-progress-tracker-start',
    one: 'saas-setup-guide-progress-tracker-1-task',
    few: 'saas-setup-guide-progress-tracker-few-tasks',
    many: 'saas-setup-guide-progress-tracker-many-tasks',
    completed: 'saas-setup-guide-progress-tracker-all-tasks'
}

export const getTranslationProgress = (): SetupGuideTranslationProgress => {
    return TRANSLATION_PROGRESS
}
