import { importRemote } from '@module-federation/utilities/src/utils/importRemote'

import { environment, getOnDemandTestingName, isDevelopment } from '@/bootstrap/environment'
import { DEFAULT_REMOTE_ENTRY } from '@/features/moduleFederation/domain/constants/remoteEntry.constant'
import { Environment } from '@/features/moduleFederation/domain/interfaces/federationModules.interfece'
import { DpRemote, FederatedModule, UrlEntry } from '@/features/moduleFederation/domain/types/federatedModules.types'
import { apiMkplUrl } from '@/utils/url'

export default class MFLoader {
    remoteEntryFileName = DEFAULT_REMOTE_ENTRY

    domain: string = apiMkplUrl

    testingName: string = getOnDemandTestingName()

    env: Environment = environment as Environment

    urls: Record<string, Promise<string>> = Object.create(null)

    private url({ app, scope, version, domain, testingName }: UrlEntry) {
        return {
            development: `https://${domain}/${app}/${scope}`,
            production: `https://d33106nj6xnu75.cloudfront.net/${app}/${scope}/${version}`,
            tst: `https://${testingName}.${app}.tst.docplanner.com/${scope}`
        }[environment]!
    }

    private fallbackUrl({ app, scope, version }: FederatedModule) {
        return {
            production: undefined,
            development: `https://dp-dev-microfrontends.s3.eu-central-1.amazonaws.com/${app}/${scope}/${version}`,
            tst: `https://dp-dev-microfrontends.s3.eu-central-1.amazonaws.com/__testing__/${app}/${scope}/${version}`
        }[this.env]
    }

    public async load<R = unknown>(url: string, { module, scope }: FederatedModule) {
        const mf = await importRemote<R | { default: any }>({
            url,
            module,
            scope,
            remoteEntryFileName: this.remoteEntryFileName
        })

        return 'default' in mf ? mf.default : mf
    }

    async importRemote(module: FederatedModule): Promise<DpRemote> {
        const urlEntry: UrlEntry = { ...module, domain: this.domain, testingName: this.testingName }
        const url = this.url(urlEntry)
        try {
            return await this.load(url, module)
        } catch (err) {
            // TODO temporary solution until we finished proxy for handling microfrontends on dev;
            const fallbackUrl = this.fallbackUrl(module)

            if (fallbackUrl) {
                return this.load(fallbackUrl, module)
            }

            throw err
        }
    }
}
