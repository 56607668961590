import { getUrlFromTemplate } from '@dp-vue/utils'
import { AxiosResponse } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import { NotificationSettingDTO } from '@/features/settings/api/interfaces/notifications.DTO'
import { NotificationSettings } from '@/features/settings/domain/enums/scheduleNotificationSettings.enums'
import {
    OnlineConsultationSettingsChannelPayload,
    Schedule,
    ScheduleExceptions,
    ScheduleService,
    SchedulesMachedByService,
    ScheduleWorkPeriods,
    ScheduleWorkPeriodsTemplates,
    SingleScheduleWorkPeriodTemplatePayload
} from '@/features/settings/domain/interfaces/schedule.interfaces'

const URL_BASE = 'schedules'

const URL_SCHEDULE_ID = `${URL_BASE}/:scheduleId`
const URL_SCHEDULE_BY_DOCPLANNER_ID_URL = `${URL_BASE}/docplannerId/:addressId`
const URL_SCHEDULES_V2 = `v2/${URL_BASE}`
const URL_SCHEDULE_ID_V2 = `${URL_SCHEDULES_V2}/:scheduleId`
const URL_EXCEPTIONS = `${URL_SCHEDULE_ID_V2}/exceptions`
const URL_EXCEPTIONS_DATE = `${URL_SCHEDULE_ID_V2}/exceptions/:date`
const URL_WORKPERIODS = `${URL_SCHEDULE_ID_V2}/workperiods`
const URL_TEMPLATE_WORKPERIODS = `${URL_BASE}/:scheduleId/templateWorkperiods`
const URL_TEMPLATE_WORKPERIODS_UPDATE_SINGLE_TEMPLATE = `${URL_TEMPLATE_WORKPERIODS}/workperiods`
const URL_TEMPLATE_WORKPERIODS_DELETE_SINGLE_TEMPLATE = `${URL_TEMPLATE_WORKPERIODS}/:templateId`
const URL_SERVICE_SCHEDULES = `${URL_SCHEDULE_ID_V2}/serviceschedules`
const URL_SCHEDULE_COLOR = `${URL_SCHEDULE_ID}/color/:colorSchemaId`
const URL_SERVICE_SCHEDULES_SERVICE_ID = `${URL_SERVICE_SCHEDULES}/:serviceId`
const URL_FEATURE_SERVICE = `${URL_SERVICE_SCHEDULES_SERVICE_ID}/featured`
const URL_DEFAULT_SERVICE = `${URL_SERVICE_SCHEDULES_SERVICE_ID}/default`
const URL_MATCHING_SCHEDULES = `${URL_SCHEDULE_ID_V2}/serviceschedules/:serviceScheduleId/matching-schedules`
const URL_NOTIFICATIONS_SETTINGS = `${URL_SCHEDULE_ID}/notificationSettings`
const URL_NOTIFICATIONS_SETTINGS_SETTING_ID = `${URL_SCHEDULE_ID}/notificationSettings/:settingId`
const URL_SCHEDULES_CHANNELS_ONLINE_CONSULTATION = `videoConsultation/schedule/:scheduleId/communicationsettings`

class ScheduleRepository {
    deleteSchedule(scheduleId: number) {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_ID,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.delete(url)
    }

    getSchedules(): Promise<AxiosResponse<Schedule[]>> {
        return httpAPI.get(URL_BASE)
    }

    async getSchedule(scheduleId: number): Promise<Schedule> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_ID,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getScheduleByAddressId(addressId: string): Promise<Schedule> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_BY_DOCPLANNER_ID_URL,
            params: { addressId }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getScheduleExceptions(scheduleId: number): Promise<ScheduleExceptions[]> {
        const url = getUrlFromTemplate({
            template: URL_EXCEPTIONS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getScheduleServices(scheduleId: number): Promise<ScheduleService[]> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULES,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getWorkPeriods(scheduleId: number): Promise<ScheduleWorkPeriods[]> {
        const url = getUrlFromTemplate({
            template: URL_WORKPERIODS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async getWorkPeriodsTemplates(scheduleId: number): Promise<ScheduleWorkPeriodsTemplates> {
        const url = getUrlFromTemplate({
            template: URL_TEMPLATE_WORKPERIODS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    removeException(scheduleId: number, date: string): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_EXCEPTIONS_DATE,
            params: {
                scheduleId: scheduleId.toString(),
                date: date.toString()
            }
        })
        return httpAPI.delete(url)
    }

    createException(payload: Record<string, any>): Promise<any> {
        const { data, date, scheduleId } = payload
        const url = getUrlFromTemplate({
            template: URL_EXCEPTIONS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })

        return httpAPI.post(url, {
            exceptionDate: date.toString(),
            exceptions: data
        })
    }

    updateException(payload: Record<string, any>): Promise<any> {
        const { data, date, scheduleId } = payload
        const url = getUrlFromTemplate({
            template: URL_EXCEPTIONS_DATE,
            params: {
                scheduleId: scheduleId.toString(),
                date: date.toString()
            }
        })

        return httpAPI.put(url, {
            exceptionDate: date.toString(),
            exceptions: data
        })
    }

    updateSchedule(scheduleId: number, data: Record<string, any>): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_ID,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.put(url, data)
    }

    async updateScheduleColor(scheduleId: number, colorSchemaId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_COLOR,
            params: {
                scheduleId: scheduleId.toString(),
                colorSchemaId: colorSchemaId.toString()
            }
        })
        return httpAPI.patch(url)
    }

    updateWorkPeriods(scheduleId: number, data: Record<string, any>[]): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_WORKPERIODS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.put(url, data)
    }

    updateWorkPeriodTemplates(scheduleId: number, data: any): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_TEMPLATE_WORKPERIODS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.put(url, data)
    }

    createWorkPeriodTemplate(scheduleId: number, data: SingleScheduleWorkPeriodTemplatePayload): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_TEMPLATE_WORKPERIODS_UPDATE_SINGLE_TEMPLATE,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.post(url, data)
    }

    updateWorkPeriodTemplate(scheduleId: number, data: SingleScheduleWorkPeriodTemplatePayload): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_TEMPLATE_WORKPERIODS_UPDATE_SINGLE_TEMPLATE,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        return httpAPI.put(url, data)
    }

    deleteWorkPeriodTemplate(scheduleId: number, templateId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_TEMPLATE_WORKPERIODS_DELETE_SINGLE_TEMPLATE,
            params: {
                scheduleId: scheduleId.toString(),
                templateId
            }
        })
        return httpAPI.delete(url)
    }

    async featureService(scheduleId: number, serviceId: number, isFeatured: boolean): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_FEATURE_SERVICE,
            params: {
                scheduleId: scheduleId.toString(),
                serviceId: serviceId.toString()
            }
        })
        return httpAPI.patch(url, !isFeatured, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async removeService(scheduleId: number, serviceId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULES_SERVICE_ID,
            params: {
                scheduleId: scheduleId.toString(),
                serviceId: serviceId.toString()
            }
        })
        return httpAPI.delete(url)
    }

    async updateService(scheduleId: number, service: ScheduleService): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SERVICE_SCHEDULES_SERVICE_ID,
            params: {
                scheduleId: scheduleId.toString(),
                serviceId: service.id.toString()
            }
        })
        return httpAPI.put(url, service)
    }

    async defaultService(scheduleId: number, serviceId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DEFAULT_SERVICE,
            params: {
                scheduleId: scheduleId.toString(),
                serviceId: serviceId.toString()
            }
        })
        return httpAPI.put(url)
    }

    async getMatchingSchedules(scheduleId: number, serviceId: number): Promise<SchedulesMachedByService[]> {
        const url = getUrlFromTemplate({
            template: URL_MATCHING_SCHEDULES,
            params: {
                scheduleId: scheduleId.toString(),
                serviceScheduleId: serviceId.toString()
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getNotificationSettings(scheduleId: number): Promise<NotificationSettingDTO[]> {
        const url = getUrlFromTemplate({
            template: URL_NOTIFICATIONS_SETTINGS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async updateNotificationSettings(
        scheduleId: number,
        settingId: NotificationSettings,
        value: boolean,
        disableReason: string,
        propagate: boolean
    ): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_NOTIFICATIONS_SETTINGS_SETTING_ID,
            params: {
                scheduleId: scheduleId.toString(),
                settingId: settingId.toString()
            },
            query: {
                value: value ? 'true' : 'false',
                disableReason,
                propagate: propagate.toString()
            }
        })
        return httpAPI.patch(url)
    }

    async getChannels(scheduleId: string): Promise<OnlineConsultationSettingsChannelPayload[]> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_CHANNELS_ONLINE_CONSULTATION,
            params: {
                scheduleId
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async updateChannels(scheduleId: string, channels: any): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_CHANNELS_ONLINE_CONSULTATION,
            params: {
                scheduleId
            }
        })
        return httpAPI.put(url, channels)
    }
}

export default new ScheduleRepository()
