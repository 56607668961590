import { orderBy } from 'lodash'

// eslint-disable-next-line import/extensions
import { isNumber } from './number.ts'
import { isString } from './string'

const safeValue = (value, hasStrings) => (hasStrings ? String(value).toLowerCase() : value)

const sortArrayByAttribute = (array, attribute, orderAsc, hasStrings) => {
    return array.slice(0).sort((a, b) => {
        const aSafe = safeValue(a[attribute], hasStrings)
        const bSafe = safeValue(b[attribute], hasStrings)

        if (aSafe < bSafe) {
            return orderAsc ? -1 : 1
        }
        if (aSafe > bSafe) {
            return orderAsc ? 1 : -1
        }

        return 0
    })
}

export const orderByAttribute = (array, attribute, orderAsc = true) => {
    if (!array || !attribute) {
        return array
    }

    let hasStrings = false
    let hasNumbers = false
    let hasOtherTypes = false
    const invalidInput = () => hasOtherTypes || (hasStrings && hasNumbers)
    array.some(el => {
        if (isString(el[attribute])) {
            hasStrings = true
        } else if (isNumber(el[attribute])) {
            hasNumbers = true
        } else {
            hasOtherTypes = true
        }
        return invalidInput()
    })

    if (invalidInput()) {
        return array
    }

    return sortArrayByAttribute(array, attribute, orderAsc, hasStrings)
}

export const orderByAttributes = (array, iteratees, orders) => {
    return orderBy(array, iteratees, orders)
}

export const arrayToObject = (array, id) => {
    return array.reduce((map, item, index) => {
        let key = null

        if (item[id]) {
            key = item[id]
        } else if (!id) {
            key = item.id
        }

        if (!key && !item.id) {
            map[index] = item
        } else if (key) {
            map[key] = item
        }

        return map
    }, {})
}

export const isArray = array => {
    return Array.isArray(array)
}

export const areArraysEquals = (array1, array2) => {
    return array1.sort().join(',') === array2.sort().join(',')
}

export const isArrayStrings = array => {
    return isArray(array) && array.every(i => typeof i === 'string')
}

export const serializeArray = array => {
    if (isArrayStrings(array)) {
        return array.join(',')
    }
    return null
}

export const deserializeArray = str => {
    if (isString(str) && str.length) {
        return str.split(',')
    }
    return null
}

export const combineArrayObjects = ({ entities = [], key }) => {
    const uniqueObject = entities.reduce((output, entity = {}) => {
        const item = entity[key] || []

        item.forEach(inner => {
            if (output[inner.id]) {
                output[inner.id] = {
                    ...inner
                }
            } else if (inner) {
                output = {
                    ...output,
                    [inner.id]: {
                        ...inner
                    }
                }
            }
        })

        return output
    }, {})

    return Object.values(uniqueObject)
}

export const sortByArrayStrings = (array, sortingArray) => {
    if (isArrayStrings(array) && isArrayStrings(sortingArray)) {
        return array.sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b))
    }
    return array
}
