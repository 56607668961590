import { setDataDogUser } from '@/bootstrap/monitor/datadog-poc/datadog'
import monitor from '@/bootstrap/monitor/monitor'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'

export default function monitoring({ next, store }) {
    monitor.setUserId(store.getters[AuthGetterTypes.GetUserId])
    monitor.setTags(store.getters[AuthGetterTypes.GetUserInfoForSentryTags])

    // TODO: refactor to integrate with monitor (WSTB-503)
    setDataDogUser({
        id: store.getters[AuthGetterTypes.GetUserId],
        ...store.getters[AuthGetterTypes.GetUserInfoForSentryTags]
    })

    next()
}
