import uninav from '@/features/navigation/ui/router/uninav.middleware'
import { VOUCHER_DETAIL_ROUTE, VOUCHER_LIST_ROUTE } from '@/features/voucher/ui/constants/router.constants'
import isVoucherEnabled from '@/features/voucher/ui/middleware/isVoucherEnabled.middleware'

const VouchersIndex = () =>
    import(/* webpackChunkName: "vouchers-index" */ '@/features/voucher/ui/views/VouchersIndex.vue')
const Vouchers = () => import(/* webpackChunkName: "vouchers" */ '@/features/voucher/ui/views/Vouchers.vue')
const VoucherDetail = () =>
    import(/* webpackChunkName: "voucher-detail" */ '@/features/voucher/ui/views/VoucherDetail.vue')

export default {
    path: 'vouchers',
    component: VouchersIndex,
    meta: {
        title: 'vouchers-navigation-title',
        middleware: [isVoucherEnabled, uninav],
        uninavName: 'vouchers'
    },
    children: [
        {
            path: '',
            name: VOUCHER_LIST_ROUTE,
            component: Vouchers
        },
        {
            path: ':voucherId',
            name: VOUCHER_DETAIL_ROUTE,
            component: VoucherDetail
        }
    ]
}
