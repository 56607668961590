import { GETTER_INVOICING_ENTITIES, GETTER_INVOICING_ENTITY_BY_ID, GETTER_TERMS_ACCEPTED } from './types/getters'

const getters = {
    [GETTER_TERMS_ACCEPTED]: state => {
        return state.areTermsAndConditionsAccepted
    },
    [GETTER_INVOICING_ENTITIES]: state => {
        return state.invoicingEntities || []
    },
    [GETTER_INVOICING_ENTITY_BY_ID]: state => invoicingEntityId => {
        return state.invoicingEntities.find(({ id }) => invoicingEntityId === id)
    }
}

export default getters
