import { CampaignsTypeName } from '@/features/campaigns/domain/enums/campaigns.enums'
import { PatientsTypeId } from '@/features/campaigns/domain/enums/campaignsRecipients.enums'
import { CampaignsState } from '@/features/campaigns/domain/interfaces/campaigns.interfaces'

export const getDefaultState = (): CampaignsState => ({
    campaignTypeName: undefined,
    isRecipientsVisited: false,
    recipients: {
        rows: [],
        headers: [],
        rowsIds: [],
        rowsSelected: [],
        rowsTotal: 0,
        filters: {
            campaignType: CampaignsTypeName.Email,
            patientsType: PatientsTypeId.All,
            from: null,
            to: null,
            ageFrom: null,
            ageTo: null,
            ageType: 0,
            gender: null,
            serviceId: null,
            specialityId: null,
            doctorId: null,
            insuranceId: null,
            scheduleId: null,
            isPublic: null,
            pageNumber: 1,
            pageSize: 20,
            pagesAmount: 0
        }
    },
    monthlyCampaignMetrics: {
        messageLimit: 0,
        notDeliveredMessages: 0,
        pendingMessages: 0,
        sentMessages: 0
    },
    isMonthlyCampaignMetricsUpdated: false,
    preview: null
})

const state = getDefaultState()

export default state
