import {
    MUTATION_SET_DATA_SETTINGS,
    MUTATION_SET_DEFAULTS,
    MUTATION_SET_IS_CLINIC_SCHEDULE,
    MUTATION_SET_LAYOUT_SETTINGS,
    MUTATION_SET_LOGO_TO_UPLOAD_AT_SAVE,
    MUTATION_SET_RESPONSE_OPTIONS
} from './types/mutations'

const mutations = {
    [MUTATION_SET_LAYOUT_SETTINGS](state, newLayoutSettings) {
        state.layoutSettings = {
            ...newLayoutSettings
        }
    },
    [MUTATION_SET_DATA_SETTINGS](state, newDataSettings) {
        state.dataSettings = {
            ...newDataSettings
        }
    },
    [MUTATION_SET_RESPONSE_OPTIONS](state, responseOptions) {
        state.responseOptions = {
            ...responseOptions
        }
    },
    [MUTATION_SET_DEFAULTS](state, defaults = {}) {
        state.responseOptions = {}
        state.layoutSettings = {
            header: {},
            body: {},
            footer: {}
        }
        state.dataSettings = {
            doctor: {},
            clinic: {},
            patient: {},
            clinicAdditionalData: [],
            doctorAdditionalData: []
        }
        state.defaultValues = {
            ...defaults
        }
        state.isClinicSchedule = false
        state.logoImagesToUpload = {}
    },
    [MUTATION_SET_IS_CLINIC_SCHEDULE](state, isClinicSchedule) {
        state.isClinicSchedule = isClinicSchedule
    },
    [MUTATION_SET_LOGO_TO_UPLOAD_AT_SAVE](state, { id, file, fileGuid, sectionId, logoIsLandscape }) {
        state.logoImagesToUpload = {
            ...state.logoImagesToUpload,
            [id]: {
                file,
                fileGuid,
                sectionId,
                logoIsLandscape
            }
        }
    }
}

export default mutations
