import { PhotoMkplDTO, UserIdMkplDTO } from '@/features/auth/api/interfaces/profile.DTO'

import repository from '../api/profile.api'
import {
    DoctorUrlEntity,
    HostUrlEntity,
    PhotoEntity,
    PhotoMkplEntity,
    SaasOnlyEntity,
    UserIdEntity,
    UserIdMkplEntity,
    UTMEntity
} from './interfaces/profile.interfaces'

class ProfileService {
    photo(userId: UserIdEntity, smallVersion: boolean): Promise<PhotoEntity> {
        return repository.photo(userId, smallVersion)
    }

    doctorUrl(userId: UserIdEntity, utmEntity: UTMEntity): Promise<DoctorUrlEntity> {
        const utmDTO = this.mapUtmToDTO(utmEntity)
        return repository.doctorUrl(userId, utmDTO)
    }

    hostUrl(utmEntity: UTMEntity): Promise<HostUrlEntity> {
        const utmDTO = this.mapUtmToDTO(utmEntity)
        return repository.hostUrl(utmDTO)
    }

    getSaasOnly(): Promise<SaasOnlyEntity> {
        return repository.getSaasOnly()
    }

    getProfileMKPL(patientId: UserIdMkplEntity): Promise<PhotoMkplEntity> {
        return repository.getProfileMKPL(patientId)
    }

    getOpinionsURL(): Promise<string> {
        return repository.getOpinionURL()
    }

    private mapUtmToDTO(utmEntity: UTMEntity) {
        return {
            utm_source: utmEntity.source,
            utm_medium: utmEntity.medium,
            utm_campaign: utmEntity.campaign
        }
    }
}

export default new ProfileService()
