import Storage from '@/store/storage'
import { IS_SIDEBAR_COLLAPSED } from '@/store/storage-types'

import { CalendarSidebarState } from './types/state'

const getDefaultState = (): CalendarSidebarState => ({
    isSidebarCollapsed: Storage.get(IS_SIDEBAR_COLLAPSED) ?? false
})

export default getDefaultState()
