import { getDefaultState } from './state'
import {
    ADMIN_IMPORT_REMOVE_SERVICE_SCHEDULE_FILE,
    ADMIN_IMPORT_REMOVE_SERVICES_FILE,
    ADMIN_IMPORT_RESET,
    ADMIN_IMPORT_SET_EHRS_FILE,
    ADMIN_IMPORT_SET_EHRS_FILE_PREVIEW,
    ADMIN_IMPORT_SET_EHRS_MAPPING,
    ADMIN_IMPORT_SET_EVENTS_FILE,
    ADMIN_IMPORT_SET_EVENTS_FILE_PREVIEW,
    ADMIN_IMPORT_SET_EVENTS_MAPPING,
    ADMIN_IMPORT_SET_FACILITY,
    ADMIN_IMPORT_SET_FIELDS,
    ADMIN_IMPORT_SET_PATIENTS_FILE,
    ADMIN_IMPORT_SET_PATIENTS_FILE_PREVIEW,
    ADMIN_IMPORT_SET_PATIENTS_MAPPING,
    ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE,
    ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE_PREVIEW,
    ADMIN_IMPORT_SET_SERVICE_SCHEDULE_MAPPING,
    ADMIN_IMPORT_SET_SERVICES_FIELDS,
    ADMIN_IMPORT_SET_SERVICES_FILE,
    ADMIN_IMPORT_SET_SERVICES_FILE_PREVIEW,
    ADMIN_IMPORT_SET_SERVICES_IMPORT_UNIQUE_ID,
    ADMIN_IMPORT_SET_SERVICES_MAPPING,
    ADMIN_IMPORT_SET_VALIDATION_DATA
} from './types/mutations'

const mutations = {
    [ADMIN_IMPORT_SET_FACILITY](state, facility) {
        state.importFacility = facility
    },
    [ADMIN_IMPORT_SET_PATIENTS_MAPPING](state, mapping) {
        state.patientMappings = mapping
    },
    [ADMIN_IMPORT_SET_EVENTS_MAPPING](state, mapping) {
        state.eventMappings = mapping
    },
    [ADMIN_IMPORT_SET_EHRS_MAPPING](state, mapping) {
        state.ehrMappings = mapping
    },
    [ADMIN_IMPORT_SET_PATIENTS_FILE_PREVIEW](state, filePreview) {
        state.patientsFilePreview = filePreview
    },
    [ADMIN_IMPORT_SET_PATIENTS_FILE](state, patientsFile) {
        state.patientsFile = patientsFile
    },
    [ADMIN_IMPORT_SET_SERVICES_MAPPING](state, mapping) {
        state.serviceMappings = mapping
    },
    [ADMIN_IMPORT_SET_SERVICE_SCHEDULE_MAPPING](state, mapping) {
        state.serviceScheduleMappings = mapping
    },
    [ADMIN_IMPORT_SET_SERVICES_FILE](state, servicesFile) {
        state.servicesFile = servicesFile
    },
    [ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE](state, servicesFile) {
        state.serviceScheduleFile = servicesFile
    },
    [ADMIN_IMPORT_SET_SERVICES_FILE_PREVIEW](state, filePreview) {
        state.servicesFilePreview = filePreview
    },
    [ADMIN_IMPORT_REMOVE_SERVICES_FILE](state) {
        state.servicesFile = undefined
        state.servicesFilePreview = []
    },
    [ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE_PREVIEW](state, filePreview) {
        state.serviceScheduleFilePreview = filePreview
    },
    [ADMIN_IMPORT_REMOVE_SERVICE_SCHEDULE_FILE](state) {
        state.serviceScheduleFile = undefined
        state.serviceScheduleFilePreview = []
    },
    [ADMIN_IMPORT_SET_SERVICES_FIELDS](state, fields) {
        state.fields = fields
    },
    [ADMIN_IMPORT_SET_SERVICES_IMPORT_UNIQUE_ID](state, uniqueId) {
        state.lastServiceImportUniqueId = uniqueId
    },
    [ADMIN_IMPORT_SET_EHRS_FILE_PREVIEW](state, filePreview) {
        state.ehrsFilePreview = filePreview
    },
    [ADMIN_IMPORT_SET_EHRS_FILE](state, patientsFile) {
        state.ehrsFile = patientsFile
    },
    [ADMIN_IMPORT_SET_EVENTS_FILE_PREVIEW](state, filePreview) {
        state.eventsFilePreview = filePreview
    },
    [ADMIN_IMPORT_SET_EVENTS_FILE](state, patientsFile) {
        state.eventsFile = patientsFile
    },
    [ADMIN_IMPORT_SET_VALIDATION_DATA](state, validationData) {
        state.validationData = validationData
    },
    [ADMIN_IMPORT_RESET](state) {
        Object.assign(state, getDefaultState())
    },
    [ADMIN_IMPORT_SET_FIELDS](state, fields) {
        state.fields = fields
    }
}

export default mutations
