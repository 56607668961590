import { DateFormat, formatDate } from '@/core/ui/utils'
import calendarRepository from '@/features/calendar/api/calendar.api'
import { ScheduleOrderDTO, ScheduleOrderPayload } from '@/features/calendar/api/interfaces/orderColumns.interfaces'
import { CalendarPeriod } from '@/features/calendar/domain/enums/calendar.enums'
import { DateRange } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import {
    CalendarHeaderEntity,
    FacilityRoomEntity,
    FormattedResourceEntity,
    ScheduleEntity
} from '@/features/calendar/domain/interfaces/orderColumns.interfaces'
import { Resource } from '@/features/calendar/domain/interfaces/resource.interfaces'
import { CALENDAR_KIND } from '@/features/calendar/ui/constants/calendar.constants'
import { orderByAttribute, orderByAttributes } from '@/utils/array'

import { swapProperty } from './utils/swapProperty'

const ORDER_BY_ATTRIBUTE = 'order'

class CalendarOrderColumnsService {
    async getSchedulesOrder(): Promise<ScheduleOrderDTO[]> {
        return calendarRepository.getSchedulesOrder()
    }

    async saveSchedulesOrder(payload: ScheduleOrderPayload[]): Promise<void> {
        return calendarRepository.saveSchedulesOrder(payload)
    }

    getFormattedRoomsData(rooms: FacilityRoomEntity[]): FormattedResourceEntity[] {
        return rooms.map(({ facilityName, formattedId, userId, name }) => ({
            address: facilityName,
            isRoom: true,
            columnId: formattedId,
            userId,
            name
        }))
    }

    getFormattedSchedulesData(schedules: ScheduleEntity[]): FormattedResourceEntity[] {
        return schedules.map(({ address, id, userId, kind, name }) => ({
            address,
            isRoom: false,
            columnId: id,
            columnKind: kind,
            userId,
            name
        }))
    }

    getMergedSchedulesOrderData(rooms: FacilityRoomEntity[], schedules: ScheduleEntity[]): FormattedResourceEntity[] {
        return [...this.getFormattedSchedulesData(schedules), ...this.getFormattedRoomsData(rooms)]
    }

    mapSchedulesOrderData(
        formattedSchedulesData: FormattedResourceEntity[],
        schedulesOrderData: ScheduleOrderDTO[]
    ): CalendarHeaderEntity[] {
        const highestOrder =
            schedulesOrderData.length > 0
                ? Math.max(...schedulesOrderData.map(({ order }) => (isNaN(order) ? 0 : order)))
                : 0

        let numberOfNotMatchingSchedules = 0

        const mappedData = formattedSchedulesData.map(schedule => {
            const matchingSchedule = schedulesOrderData.find(({ columnId }) => schedule.columnId === columnId)

            if (!matchingSchedule) {
                numberOfNotMatchingSchedules += 1

                return {
                    ...schedule,
                    order: highestOrder + numberOfNotMatchingSchedules
                }
            }

            return {
                ...schedule,
                id: matchingSchedule.id,
                order: matchingSchedule.order
            }
        })

        return orderByAttribute(mappedData, ORDER_BY_ATTRIBUTE)
    }

    getOrderedSchedulesData(currentResources: Resource[], schedulesOrderData: ScheduleOrderDTO[]): Resource[] {
        const resources = currentResources.map(resource => {
            const { formattedId, id, isRoom } = resource
            const resourceId = isRoom ? formattedId : id

            const matchingResource = schedulesOrderData.find(({ columnId }) => resourceId === columnId)

            if (!matchingResource) {
                return resource
            }

            return {
                ...resource,
                order: matchingResource.order
            }
        })

        return orderByAttribute(resources, 'order')
    }

    getOrderedSchedulesByAvailability(resources: Resource[], selectedDateRange: DateRange): Resource[] {
        const currentResources = resources.map(resource => {
            const currentDate = resource.availableDates?.find(
                ({ date }) => date === formatDate(selectedDateRange.from, DateFormat.DateDashedFormat)
            )

            return {
                ...resource,
                isDoctor: resource.kind === CALENDAR_KIND.doctor,
                initTime: currentDate ? currentDate.time : null,
                title: resource.title || resource.displayName || resource.name
            }
        })

        const calendarDoctors = currentResources.filter(({ isDoctor }) => isDoctor)
        const otherResources = currentResources.filter(({ isDoctor }) => !isDoctor)

        const orderedDoctors = orderByAttributes(calendarDoctors, ['initTime', 'title', 'facilityName'])
        const orderedOtherResources = orderByAttributes(otherResources, ['title', 'facilityName'])

        return [...orderedDoctors, ...orderedOtherResources]
    }

    getOrderedSchedulesPayload(orderedSchedules: CalendarHeaderEntity[]): ScheduleOrderPayload[] {
        return orderedSchedules.map(({ id, userId, columnId, isRoom, order }) => ({
            userId,
            columnId,
            columnKind: isRoom ? 1 : 0,
            order,
            hidden: false,
            ...(id && { id })
        }))
    }

    sortHeaders(headers: CalendarHeaderEntity[]) {
        return orderByAttribute(headers, ORDER_BY_ATTRIBUTE).map((column: CalendarHeaderEntity, index: number) => ({
            ...column,
            order: index + 1
        }))
    }

    swapColumns(headers: CalendarHeaderEntity[], currentColumnIndex: number, columnIndexToSwap: number) {
        if (currentColumnIndex === columnIndexToSwap) {
            return headers
        }

        const swappedHeaders = swapProperty(headers, currentColumnIndex, columnIndexToSwap, ORDER_BY_ATTRIBUTE)

        return this.sortHeaders(swappedHeaders)
    }
}

export default new CalendarOrderColumnsService()
