let middleware = []

const nextFactory = (context, index, callMiddlewareFn) => {
    if (!middleware[index]) return context.next
    return (...parameters) => {
        if (Object.keys({ ...parameters }).length) {
            return context.next(...parameters)
        }
        callMiddlewareFn(context, index)
    }
}

const callMiddleware = (context, index) => {
    middleware[index]({
        ...context,
        next: nextFactory(context, index + 1, callMiddleware)
    })
}

const middlewares = context => {
    if (context.to.matched.some(record => record.meta.middleware)) {
        middleware = context.to.matched.map(({ meta }) => meta.middleware).filter(Boolean)

        middleware = [].concat(...middleware)

        middleware = Array.isArray(middleware) ? middleware : [middleware]

        if (middleware.length) {
            callMiddleware(
                {
                    ...context
                },
                0
            )
        } else {
            context.next()
        }
    }
}

export default middlewares
