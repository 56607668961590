export enum PatientStatus {
    Active,
    Deceased,
    NotActive
}

export enum DocumentTypes {
    Passport,
    DNI,
    NIF,
    NIE
}

export enum PatientGender {
    Female,
    Male,
    NonBinary
}

export enum EducationLevelEnum {
    Undefined,
    None,
    ElementarySchool,
    IncompleteElementarySchool,
    HighSchool,
    UniversityEducation,
    IncompleteHigherEducation,
    Postgraduate,
    MasterDegree,
    DoctorateDegree,
    Preparatory,
    IncompletePreparatory
}

export enum MaritalStatusEnum {
    Undefined,
    NotMarried,
    Married,
    Separate,
    Divorced,
    Widower,
    StableUnion
}

export enum InsuranceState {
    Private = -1,
    Unknown,
    Insured,
    Public
}
