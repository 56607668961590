export const isNumber = (value: any): boolean => typeof value === 'number' && isFinite(value)

export const getRandomIntInclusive = (min: number, max: number): number => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Number(Math.floor(Math.random() * (max - min + 1) + min))
}

export const roundTwoDecimals = (number: number): number => {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export const getNumberOfPages = (totalHits: number, pageSize: number): number => {
    const pages = Math.floor(totalHits / pageSize)
    const additionalPage = pages > 0 && totalHits % pageSize !== 0 ? 1 : 0

    return pages + additionalPage
}

export const getDecimalFromPercent = (percentValue: number): number => percentValue / 100

export const getPercentageFromDecimal = (decimalValue: number): number => Math.floor(decimalValue * 100)
