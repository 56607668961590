import httpAPI from '@/api/utils/httpClientAPI'

import { AgendaDateRange, AgendaSlots } from '../domain/interfaces/calendar.interfaces'

const URL_BASE = 'slots-agenda'

class AgendaRepository {
    async getAgendaSlots(params: AgendaDateRange): Promise<AgendaSlots> {
        const { data } = await httpAPI.get(URL_BASE, { params })

        return data
    }
}

export default new AgendaRepository()
