export const MUTATION_ADD_CALENDAR_EVENTS = 'MUTATION_ADD_CALENDAR_EVENTS'
export const MUTATION_REMOVE_CALENDAR_EVENTS = 'MUTATION_REMOVE_CALENDAR_EVENTS'
export const MUTATION_SET_CALENDAR_LOADING = 'SET_CALENDAR_LOADING'
export const MUTATION_SET_CALENDAR_SELECTED_DATE = 'SET_CALENDAR_SELECTED_DATE'
export const MUTATION_SET_CALENDAR_ROUTE_PATH = 'SET_CALENDAR_ROUTE_PATH'
export const MUTATION_SET_CALENDAR_SELECTED_ROOMS = 'SET_CALENDAR_SELECTED_ROOMS'
export const MUTATION_SET_CALENDAR_FILTER_SELECTED_SERVICES = 'MUTATION_SET_CALENDAR_FILTER_SELECTED_SERVICES'
export const MUTATION_SET_CALENDAR_EVENTS = 'MUTATION_SET_CALENDAR_EVENTS'
export const MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE = 'MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE'
export const MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER = 'MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER'
export const MUTATION_SET_CALENDAR_COLOR_SCHEMAS = 'MUTATION_SET_CALENDAR_COLOR_SCHEMAS'
export const MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY = 'MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY'
export const MUTATION_SET_CALENDAR_CREATION_TYPE = 'MUTATION_SET_CALENDAR_CREATION_TYPE'
export const MUTATION_SET_CALENDAR_CREATION_ADDRESS = 'MUTATION_SET_CALENDAR_CREATION_ADDRESS'
export const MUTATION_SET_CALENDAR_CREATION_SERVICES = 'MUTATION_SET_CALENDAR_CREATION_SERVICES'
export const MUTATION_SET_CALENDAR_CREATION_COLOR = 'MUTATION_SET_CALENDAR_CREATION_COLOR'
export const MUTATION_SET_CALENDAR_CREATION_NAME = 'MUTATION_SET_CALENDAR_CREATION_NAME'
export const MUTATION_SET_CALENDAR_CREATION_RESET = 'MUTATION_SET_CALENDAR_CREATION_RESET'
export const MUTATION_SET_CALENDAR_ROOMS = 'MUTATION_SET_CALENDAR_ROOMS'
export const MUTATION_SET_CALENDAR_FILTER_SERVICES = 'MUTATION_SET_CALENDAR_FILTER_SERVICES'
export const MUTATION_SET_AGENDA_EVENTS = 'MUTATION_SET_AGENDA_EVENTS'
export const MUTATION_SET_AGENDA_EXPAND = 'MUTATION_SET_AGENDA_EXPAND'
export const MUTATION_SET_SELECTED_FACILITY = 'MUTATION_SET_SELECTED_FACILITY'
export const MUTATION_SET_SELECTED_SPECIALITY = 'MUTATION_SET_SELECTED_SPECIALITY'
export const MUTATION_SET_SELECTED_SPECIALITY_GROUP = 'MUTATION_SET_SELECTED_SPECIALITY_GROUP'
export const MUTATION_SET_FACILITIES = 'MUTATION_SET_FACILITIES'
export const MUTATION_SET_SPECIALITIES = 'MUTATION_SET_SPECIALITIES'
export const MUTATION_SET_CALENDAR_SCHEDULES = 'MUTATION_SET_CALENDAR_SCHEDULES'
export const MUTATION_SET_CALENDAR_SCHEDULES_ORDER = 'MUTATION_SET_CALENDAR_SCHEDULES_ORDER'
export const MUTATION_SET_DOCTORS = 'MUTATION_SET_DOCTORS'
export const MUTATION_SET_SCHEDULES = 'MUTATION_SET_SCHEDULES'
export const MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD = 'MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD'
export const MUTATION_UPDATE_DOCTORS = 'MUTATION_UPDATE_DOCTORS'
export const MUTATION_UPDATE_CALENDAR_EVENTS = 'MUTATION_UPDATE_CALENDAR_EVENTS'
export const MUTATION_UPDATE_DOCTORS_SELECTED = 'MUTATION_UPDATE_DOCTORS_SELECTED'
export const MUTATION_SET_ONLY_DOCTORS_SELECTED = 'MUTATION_SET_ONLY_DOCTORS_SELECTED'
export const MUTATION_UPDATE_SEARCH_CALENDARS_QUERY = 'MUTATION_UPDATE_SEARCH_CALENDARS_QUERY'
export const MUTATION_UPDATE_SEARCH_SERVICES_QUERY = 'MUTATION_UPDATE_SEARCH_SERVICES_QUERY'

export const MUTATION_SET_CALENDAR_DEVICES = 'MUTATION_SET_CALENDAR_DEVICES'
export const MUTATION_SET_AGENDA_SELECTED_PATIENT = 'MUTATION_SET_AGENDA_SELECTED_PATIENT'
export const MUTATION_SET_AGENDA_SELECTED_DURATION = 'MUTATION_SET_AGENDA_SELECTED_DURATION'
export const MUTATION_SET_FREE_SLOTS_AVAILABLE_DAYS = 'MUTATION_SET_FREE_SLOTS_AVAILABLE_DAYS'
export const MUTATION_SET_FREE_SLOTS_SELECTED_DATE_END = 'MUTATION_SET_FREE_SLOTS_SELECTED_DATE_END'

export const MUTATION_SET_CALENDAR_STAFF = 'MUTATION_SET_CALENDAR_STAFF'
export const MUTATION_ADD_CALENDAR_WORKPERIODS = 'MUTATION_ADD_CALENDAR_WORKPERIODS'
export const MUTATION_SET_CALENDAR_CLIPBOARD = 'MUTATION_SET_CALENDAR_CLIPBOARD'
export const MUTATION_RESET_CALENDAR_CLIPBOARD = 'MUTATION_RESET_CALENDAR_CLIPBOARD'
export const MUTATION_SET_FREE_SLOTS_FILTER_DAYS = 'MUTATION_SET_FREE_SLOTS_FILTER_DAYS'
export const MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME = 'MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME'
export const MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE = 'MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE'
export const MUTATION_SET_DISPLAYED_EVENTS_SERVICES_IDS = 'MUTATION_SET_DISPLAYED_EVENTS_SERVICES_IDS '

export const MUTATION_TOGGLE_CALENDAR_RESOURCE_WEEK_VIEW = 'MUTATION_TOGGLE_CALENDAR_RESOURCE_WEEK_VIEW'
export const MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS = 'MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS'
export const MUTATION_SET_DOCTOR_SCHEDULE_SELECTED = 'MUTATION_SET_DOCTOR_SCHEDULE_SELECTED'
export const MUTATION_SET_DOCTOR_SCHEDULES = 'MUTATION_SET_DOCTOR_SCHEDULES'
export const MUTATION_CLEAN_CALENDAR_EVENTS = 'MUTATION_CLEAN_CALENDAR_EVENTS'
export const MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE = 'MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE'
export const MUTATION_RESET_FREE_SLOTS_FILTERS = 'MUTATION_RESET_FREE_SLOTS_FILTERS'
export const MUTATION_HIDE_PATIENT_DATAS = 'MUTATION_HIDE_PATIENT_DATAS'
export const MUTATION_RESET_RIGHT_SIDEBAR_PROPS = 'MUTATION_RESET_RIGHT_SIDEBAR_PROPS'
export const MUTATION_SET_RIGHT_SIDEBAR_PROPS = 'MUTATION_SET_RIGHT_SIDEBAR_PROPS'
export const MUTATION_SET_CALENDAR_SELECTED_SCHEDULES = 'MUTATION_SET_CALENDAR_SELECTED_SCHEDULES'
export const MUTATION_SET_FETCH_SCHEDULES_SELECTED = 'MUTATION_SET_FETCH_SCHEDULES_SELECTED'

export const MUTATION_RESET_APPOINTMENT_INTEGRATED_STATE = 'MUTATION_RESET_APPOINTMENT_INTEGRATED_STATE'
