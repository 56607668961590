const state = {
    patient: null,
    patientCounters: {},
    page: [],
    selectedPatientIds: [],
    unselectedPatientIds: [],
    counterSelectedPatients: 0,
    isAllPatientsSelected: false,
    pagePatientIds: [],
    totalPatients: 0,
    totalPrivatePatients: 0,
    pageTotal: undefined,
    pageLength: 0,
    events: [],
    futureEvents: {
        count: 0,
        items: []
    },
    nextEvent: null,
    checkinInfo: null,
    noMoreEventsToLoad: false,
    financialVisitSummary: {},
    tableEditState: 'preview',
    nearestEventWithoutEpisode: null,
    patientReviewRequestStatus: {},
    selectedNewOwner: null,
    insurances: [],
    proposals: []
}

export default state
