import {
    CALENDAR_MAX_ZOOM_LEVEL,
    CALENDAR_RESOURCE_MIN_WIDTH,
    CALENDAR_RESOURCE_WIDTH_STEP,
    CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY
} from '../ui/constants/calendar.zoom.constants'
import CalendarSettingsDoctorService from './calendar.settings.doctor.service'

class CalendarZoomService {
    zoomIn(currentZoomLevel: number, resourceDomElementWidth: number): number {
        let zoomLevel = Math.min(currentZoomLevel + 1, CALENDAR_MAX_ZOOM_LEVEL)

        for (; zoomLevel <= CALENDAR_MAX_ZOOM_LEVEL; zoomLevel += 1) {
            const singleResourceWidth = CALENDAR_RESOURCE_MIN_WIDTH + zoomLevel * CALENDAR_RESOURCE_WIDTH_STEP

            if (singleResourceWidth >= resourceDomElementWidth) {
                break
            }
        }

        this.updateZoomLevelSetting(zoomLevel)
        return zoomLevel
    }

    zoomOut(currentZoomLevel: number): number {
        const zoomLevel = Math.max(currentZoomLevel - 1, 0)
        this.updateZoomLevelSetting(zoomLevel)
        return zoomLevel
    }

    updateZoomLevelSetting(zoomLevel: number) {
        CalendarSettingsDoctorService.addSetting(CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY, zoomLevel)
    }

    getZoomedResourceWidth(zoomLevel: number): number {
        return CALENDAR_RESOURCE_MIN_WIDTH + zoomLevel * CALENDAR_RESOURCE_WIDTH_STEP
    }

    isZoomInDisabled(zoomLevel: number): boolean {
        return zoomLevel === CALENDAR_MAX_ZOOM_LEVEL
    }

    isZoomOutDisabled(zoomLevel: number): boolean {
        return zoomLevel === 0
    }
}

export default new CalendarZoomService()
