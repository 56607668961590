import {
    SETTINGS_BOOKING_QUESTIONS_ROUTE,
    SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_ROUTE
} from '@/features/settings/ui/constants/router.constants'

const BookingRulesIndex = () =>
    import(/* webpackChunkName: "booking-rules-index" */ '@/features/settings/ui/views/BookingRulesIndex.vue')
const BookingNotes = () =>
    import(
        /* webpackChunkName: "booking-rules-notes" */ '@/features/settings/ui/components/bookingRules/notes/BookingNotes.vue'
    )
const BookingQuestions = () =>
    import(
        /* webpackChunkName: "booking-questions" */ '@/features/settings/ui/components/bookingQuestions/BookingQuestions.vue'
    )
const NoteSetEdition = () =>
    import(
        /* webpackChunkName: "booking-rules-note-set-edition" */ '@/features/settings/ui/components/bookingRules/notes/NoteSetEdition.vue'
    )
const QuestionSetEdition = () =>
    import(
        /* webpackChunkName: "booking-rules-question-set-edition" */ '@/features/settings/ui/components/bookingQuestions/QuestionSetEdition.vue'
    )

export default [
    {
        path: 'booking-rules',
        component: BookingRulesIndex,
        redirect: { name: SETTINGS_BOOKING_RULES_ROUTE },
        children: [
            {
                name: SETTINGS_BOOKING_RULES_ROUTE,
                path: 'notes',
                component: BookingNotes
            },
            {
                name: SETTINGS_BOOKING_QUESTIONS_ROUTE,
                path: 'questions',
                component: BookingQuestions
            }
        ]
    },
    {
        name: SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
        path: 'booking-rules/notes/note-set/:id?',
        component: NoteSetEdition
    },
    {
        path: 'booking-rules/questions/question-set/:id?',
        name: SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
        component: QuestionSetEdition
    }
]
