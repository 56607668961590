import { BookingMutations, MutationTypes } from './types'

export const mutations: BookingMutations = {
    [MutationTypes.ClearBookingState](state) {
        state.patient = undefined
        state.event = undefined
    },
    [MutationTypes.SetBookingPatient](state, patient) {
        state.patient = patient
    },
    [MutationTypes.SetBookingEvent](state, event) {
        state.event = event
    },
    [MutationTypes.SetToastId](state, toastId) {
        state.toastId = toastId
    }
}
