import uninav from '@/features/navigation/ui/router/uninav.middleware'
import { PRINT_LAYOUTS_ROUTE_NAMES } from '@/features/settings/ui/constants/printLayout.constants'

const PrintLayoutsIndex = () =>
    import(
        /* webpackChunkName: "print-layout-settings-index" */ '@/features/settings/ui/views/printLayouts/PrintLayoutsIndex.vue'
    )
const PrintLayoutsList = () =>
    import(
        /* webpackChunkName: "print-layout-settings-list" */ '@/features/settings/ui/views/printLayouts/PrintLayoutsList.vue'
    )
const PrintLayoutsEditor = () =>
    import(
        /* webpackChunkName: "print-layout-settings-editor" */ '@/features/settings/ui/views/printLayouts/PrintLayoutsEditor.vue'
    )

const PRINT_LAYOUTS = 'print-layouts'

export default [
    {
        path: PRINT_LAYOUTS,
        component: PrintLayoutsIndex,
        meta: {
            middleware: uninav,
            uninavName: PRINT_LAYOUTS
        },
        children: [
            {
                name: PRINT_LAYOUTS_ROUTE_NAMES.list,
                path: '/',
                component: PrintLayoutsList
            }
        ]
    },
    {
        path: 'print-layouts/:id',
        name: PRINT_LAYOUTS_ROUTE_NAMES.editor,
        component: PrintLayoutsEditor,
        meta: {
            middleware: uninav,
            uninavName: PRINT_LAYOUTS
        }
    }
]
