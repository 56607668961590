export enum EventStatus {
    Scheduled,
    CanceledByUser,
    CanceledByPatient,
    WaitingForConfirmation,
    ConfirmedByPatient,
    SentToThirdParty,
    ConfirmedByAdmin,
    CanceledByTechnicalIssue
}
