export const DEFAULT_START_HOUR = '08:00'
export const DEFAULT_END_HOUR = '12:00'
export const LATEST_END_HOUR = '23:59'

export const DAY_NUMBER_OF_HOURS = 24
export const DEFAULT_HOURS_DURATION = 6
export const DEFAULT_SLOT_DURATION = 30
export const DAYS_NUMBER_OF_WEEK = 7

export const PERIODICITIES = [
    { value: 1, name: 'cada-semana' },
    { value: 2, name: '2-semanas' },
    { value: 3, name: '3-semanas' },
    { value: 4, name: '4-semanas' }
]

export const PERIODICITY_REPEAT_EVERY_WEEK = 1

export const SERVICES_RADIO_ALL = 0
export const SERVICES_RADIO_SOME = 1

export const SERVICES_TRACKING_VALUES = {
    [SERVICES_RADIO_ALL]: 'all_services',
    [SERVICES_RADIO_SOME]: 'specific_services'
}

export const INSURANCES_RADIO_CASH_ONLY = 0
export const INSURANCES_RADIO_INSURANCES_ONLY = 1
export const INSURANCES_RADIO_BOTH = 2

export const ADMITTED_PATIENTS_ALL = 0
export const ADMITTED_PATIENTS_NEW = 1
export const ADMITTED_PATIENTS_OLD = 2

export const ADMITTED_PATIENTS_TRACKING_VALUES = {
    [ADMITTED_PATIENTS_ALL]: 'all_patients',
    [ADMITTED_PATIENTS_NEW]: 'new_patients',
    [ADMITTED_PATIENTS_OLD]: 'old_patients'
}
