import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { apiActivationOnboardingUrl } from '@/utils/url'

function getActivationOnboardingLocation(query) {
    const isContextOnline = query.context === 'online'
    const isNotNewDoctor = query['new-doctor'] === 'false'

    let apiActivationOnboardingLocation = apiActivationOnboardingUrl

    if (isContextOnline && isNotNewDoctor) {
        apiActivationOnboardingLocation = `${apiActivationOnboardingUrl}/addresscreation?isOnline`
    } else if (isContextOnline) {
        apiActivationOnboardingLocation = `${apiActivationOnboardingUrl}/addresslocation`
    }

    return apiActivationOnboardingLocation
}

export default function onboardingRedirect({ next, router, store, to: { query } }) {
    if (store.getters[AuthGetterTypes.IsDoctor]) {
        window.location.replace(getActivationOnboardingLocation(query))
        return
    }

    return router.push({ name: 'calendar' }).catch(() => {})
}
