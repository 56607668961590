import { addToDate, DateFormat, DateUnit, formatDate } from '@/core/ui/utils'

import { PaymentsReportsRepository, PaymentsRepository } from '../api'
import { PaymentsRequestDTO } from '../api/interfaces'
import { isFuture, parseDateStringLog } from '../ui/utils'
import { SortOrder } from './enums'
import {
    PaymentInstructions,
    PaymentsData,
    PaymentsProfile,
    PaymentsSchedule,
    PaymentsSettingsScheduleAdminBookingsPayload,
    PaymentsSettingsSchedulePatientBookingsPayload
} from './interfaces/payments.interfaces'
import { PaymentRequestsCreatePayload, PaymentRequestsSendNotification } from './interfaces/paymentsRequests.interfaces'
import { PaymentsReportsDataPage, ReportsParameters } from './interfaces/reports.interfaces'
import { Visit } from './interfaces/visits.interfaces'
import { mapPaymentsFromDTO } from './mappers/payments.mapper'
import { mapVisitFromDTO } from './mappers/visits.mapper'
import { getHistoryVisitsRequestFilters, getUpcomingVisitsRequestFilters } from './utils'

class PaymentsService {
    async getPayments(pageNumber: number, pageSize: number): Promise<PaymentsData> {
        const payments = await PaymentsRepository.getPayments(pageNumber, pageSize)
        return mapPaymentsFromDTO(payments)
    }

    async getPaymentsProfile({ cache } = { cache: true }): Promise<PaymentsProfile> {
        const {
            accountType,
            crmCustomerHasUnpaidInvoices,
            marketplacePaymentAccountActionRequired,
            fee,
            marketplacePaymentAccountCanOnboard,
            marketplacePaymentAccountConnectedCustomers,
            areIntegratedPaymentsTermsAccepted
        } = await PaymentsRepository.getPaymentsProfile({ cache })

        return {
            accountType,
            areIntegratedPaymentsTermsAccepted,
            canOnboard: marketplacePaymentAccountCanOnboard,
            hasUnpaidInvoices: !!crmCustomerHasUnpaidInvoices,
            isPaymentsActionRequired: !!marketplacePaymentAccountActionRequired,
            fee: {
                isAllowedToMigrate: !!fee.marketplacePaymentAccountIsAllowedToMigrate,
                migrationPaymentFee: fee.marketplacePaymentAccountMigrationPaymentFee,
                paymentFee: fee.marketplacePaymentAccountPaymentFee
            },
            customerIds: marketplacePaymentAccountConnectedCustomers
        }
    }

    getSchedules(): Promise<PaymentsSchedule[]> {
        return PaymentsRepository.getSchedules()
    }

    async activateFeature(): Promise<void> {
        await PaymentsRepository.activateFeature()
    }

    async hasPaymentConfigured(patientId: number): Promise<boolean> {
        const { canBeUsed: hasPaymentConfigured } = await PaymentsRepository.isAvailableForPayments(patientId)
        return hasPaymentConfigured
    }

    async getPaymentInstructions(patientId: number): Promise<string> {
        const { instructions } = await PaymentsRepository.getPaymentInstructions(patientId)
        return instructions
    }

    setPaymentInstructions(patientId: number, data: PaymentInstructions): Promise<void> {
        return PaymentsRepository.setPaymentInstructions(patientId, data)
    }

    async activateSchedule(scheduleId: number): Promise<boolean> {
        const valid = await PaymentsRepository.activateSchedule(scheduleId)

        if (!valid) {
            throw new Error()
        }

        return valid
    }

    async updateScheduleAdminBookingUpfrontPaymentsSettings(
        scheduleId: number,
        payload: PaymentsSettingsScheduleAdminBookingsPayload
    ): Promise<void> {
        await PaymentsRepository.updateScheduleAdminBookingUpfrontPaymentsSettings(scheduleId, payload)
    }

    async updateSchedulePatientBookingUpfrontPaymentsSettings(
        scheduleId: number,
        payload: PaymentsSettingsSchedulePatientBookingsPayload
    ): Promise<void> {
        await PaymentsRepository.updateSchedulePatientBookingUpfrontPaymentsSettings(scheduleId, payload)
    }

    async deactivateSchedule(scheduleId: number): Promise<void> {
        await PaymentsRepository.deactivateSchedule(scheduleId)
    }

    async sendPaymentLinkToPatient(appointmentId: number) {
        const { error, message, status } = await PaymentsRepository.sendPaymentLinkToPatient(appointmentId.toString())

        if (error) {
            return { data: null, error, message }
        }

        return { data: status === 200, error, message }
    }

    async getUpcomingVisits(schedulesIds: string[]) {
        const filters = getUpcomingVisitsRequestFilters(schedulesIds)
        const args: ReportsParameters = {
            filters,
            pageNumber: 0,
            from: formatDate(new Date().toISOString(), DateFormat.DateDashedFormat),
            to: formatDate(addToDate(new Date().toISOString(), 1, DateUnit.Day), DateFormat.DateDashedFormat)
        }
        const { data, error, message } = await PaymentsReportsRepository.getData(args)

        if (error) {
            return { error, message, data: null }
        }
        const processedData = data.page.reduce((visits: Visit[], curr: PaymentsReportsDataPage) => {
            const startDate = parseDateStringLog(`${curr.date} ${curr.appointmentTime}`)
            if (startDate && isFuture(startDate)) {
                visits.push(mapVisitFromDTO(curr))
            }
            return visits
        }, [])
        return { data: processedData, error, message }
    }

    async getVisitsHistory(pageNumber: number, schedulesIds: string[]) {
        const filters = getHistoryVisitsRequestFilters(schedulesIds)
        const args: ReportsParameters = {
            filters,
            pageNumber,
            pageSize: 15,
            to: formatDate(new Date().toISOString(), DateFormat.DateStringNoUtcOffset),
            fromToIncludeTime: true,
            sort: 'date',
            order: SortOrder.Desc
        }

        const { data, error, message } = await PaymentsReportsRepository.getData(args)

        if (error) {
            return { error, message, data: null }
        }

        return {
            data: {
                ...data,
                page: data.page.map(mapVisitFromDTO)
            },
            error,
            message
        }
    }

    createPaymentLink(payload: PaymentRequestsCreatePayload): Promise<PaymentsRequestDTO> {
        return PaymentsRepository.createPaymentLink(payload)
    }

    sendPaymentLinkNotification(payload: PaymentRequestsSendNotification): Promise<void> {
        return PaymentsRepository.sendPaymentLinkNotification(payload)
    }
}

export default new PaymentsService()
