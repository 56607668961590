export const MUTATION_CAMPAIGNS_RESET_STATE = 'MUTATION_CAMPAIGNS_RESET_STATE'
export const MUTATION_CAMPAIGNS_SET_TYPE = 'MUTATION_CAMPAIGNS_SET_TYPE'
export const MUTATION_CAMPAIGNS_ADD_ROW_SELECTED = 'MUTATION_CAMPAIGNS_ADD_ROW_SELECTED'
export const MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED = 'MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED'
export const MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED = 'MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED'
export const MUTATION_CAMPAIGNS_SET_SELECTED_ROWS = 'MUTATION_CAMPAIGNS_SET_SELECTED_ROWS'
export const MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS = 'MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS'
export const MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS = 'MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS'
export const MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE = 'MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE'
export const MUTATION_CAMPAIGNS_APPLY_FILTER = 'MUTATION_CAMPAIGNS_APPLY_FILTER'
export const MUTATION_CAMPAIGNS_PREVIEW_SET = 'MUTATION_CAMPAIGNS_PREVIEW_SET'
export const MUTATION_CAMPAIGNS_PREVIEW_UPDATE = 'MUTATION_CAMPAIGNS_PREVIEW_UPDATE'
export const MUTATION_CAMPAIGNS_SET_PAGE_NUMBER = 'MUTATION_CAMPAIGNS_SET_PAGE_NUMBER'
export const MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED = 'MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED'
export const MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT =
    'MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT'

export const MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS = 'MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS'
