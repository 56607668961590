import { WAITING_LIST_ROUTE_ONBOARDING_NAME } from '../constants/routes.constants'

export default function waitingListMiddleware({ next, store }: any) {
    const { isWaitingListEnabled } = store.getters

    if (isWaitingListEnabled) {
        return next()
    }

    return next({ name: WAITING_LIST_ROUTE_ONBOARDING_NAME })
}
