import repository from '@/features/speciality/api/speciality.api'

import { Speciality } from './interfaces/speciality.interfaces'

class SpecialityService {
    getSpecialities(): Promise<Speciality[]> {
        return repository.getSpecialities()
    }
}

export default new SpecialityService()
