import { HolidayEvent } from '@/features/calendar/domain/interfaces/holiday.interfaces'

const HOLIDAY_EVENT_PREFIX = 'H-'
const HOLIDAY_ID_SCHEDULE_SEPARATION = ':'

export const composeHolidayId = (holiday: Pick<HolidayEvent, 'id' | 'scheduleId'>): string => {
    if (String(holiday.id).indexOf(HOLIDAY_EVENT_PREFIX) === 0) {
        return String(holiday.id)
    }
    return `${HOLIDAY_EVENT_PREFIX}${holiday.id}${HOLIDAY_ID_SCHEDULE_SEPARATION}${holiday.scheduleId}`
}

export const breakDownHolidayId = (id: string): string => {
    if (id.indexOf(HOLIDAY_EVENT_PREFIX) === -1) {
        return id
    }

    return id.split(HOLIDAY_EVENT_PREFIX)[1].split(HOLIDAY_ID_SCHEDULE_SEPARATION)[0]
}
