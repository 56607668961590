export const getDefaultState = () => ({
    settings: {
        hasPrivacyPolicy: false,
        hasMarketingConsent: false,
        privacyFileIds: []
    },
    savedSettings: null
})

const state = getDefaultState()

export default state
