import { DateFormat, formatDate } from '@/core/ui/utils'
import CalendarRemindersRepository from '@/features/calendar/api/calendar.reminders.api'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { CALENDAR_EVENT_DEFAULT_RESOURCE_ID } from '@/features/calendar/domain/constants/calendar.events.constants'
import {
    DEFAULT_DURATION_MINUTES,
    REMINDER_EVENT_TYPE
} from '@/features/calendar/domain/constants/calendar.reminders.constants'
import { COLOR_TRANSPARENT } from '@/features/calendar/domain/constants/colorSchemas.constants'
import { CalendarEventDisplayType, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { CalendarReminder, ReminderDTO, ReminderEvent } from '@/features/calendar/domain/interfaces/reminder.interfaces'
import { PatientCalendarEvent } from '@/features/patient/domain/interfaces/patient.interfaces'

class CalendarRemindersService {
    async createReminder(reminder: CalendarReminder): Promise<CalendarReminder> {
        return CalendarRemindersRepository.createReminder(reminder)
    }

    async getReminder(id: number): Promise<CalendarReminder> {
        return CalendarRemindersRepository.getReminder(id)
    }

    async updateReminder(
        id: number,
        reminder: CalendarReminder,
        { date, time = '00:00' }: { date: string; time?: string }
    ): Promise<void> {
        const newDate = `${formatDate(date, DateFormat.DateDashedFormat)}T${time}`

        await CalendarRemindersRepository.updateReminder(id, {
            ...reminder,
            start: newDate
        })
    }

    async deleteReminder(id: number): Promise<void> {
        await CalendarRemindersRepository.deleteReminder(id)
    }

    async toggleReminderAsRead(id: number, done: boolean): Promise<void> {
        await CalendarRemindersRepository.toggleReminderAsRead(id, done)
    }

    mapRemindersCollectionToEvent(reminders: ReminderDTO[]): ReminderEvent[] {
        return reminders.map(reminder => {
            return this.mapReminderToEvent(reminder)
        })
    }

    mapReminderToEvent(reminder: ReminderDTO): ReminderEvent {
        return {
            ...reminder,
            ...CalendarEventService.processStartEndToDate(reminder, DEFAULT_DURATION_MINUTES),
            displayDefault: CalendarEventDisplayType.Auto,
            eventType: REMINDER_EVENT_TYPE,
            type: CalendarEventType.Reminder,
            isReminder: true,
            hasPatient: !!reminder.patientId,
            patient: this.getPatientReminderEvent(reminder),
            comments: reminder.description,
            groupId: '',
            className: [`event-id-reminder-${reminder.id}`],
            color: COLOR_TRANSPARENT,
            resourceId: CALENDAR_EVENT_DEFAULT_RESOURCE_ID,
            editable: true,
            durationEditable: false,
            duration: DEFAULT_DURATION_MINUTES
        }
    }

    getPatientReminderEvent(reminder: ReminderDTO): Omit<PatientCalendarEvent, 'fullName'> | null {
        if (reminder.patientId) {
            return {
                id: reminder.patientId,
                firstName: reminder.patientFirstName,
                lastName: reminder.patientLastName,
                phone: reminder.patientPhone,
                email: reminder.patientEmail,
                insurance: reminder.patientInsuranceName ? { name: reminder.patientInsuranceName } : undefined
            }
        }

        return null
    }
}

export default new CalendarRemindersService()
