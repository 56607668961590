import httpAPI from '@/api/utils/httpClientAPI'
import { NavigationResponse } from '@/features/navigation/domain/interfaces/navigation.interfaces'

const URL_BASE = 'navigation'
const URL_MARKETPLACE_PROFILE = `${URL_BASE}/marketplace-profile`

class NavigationRepository {
    async getMarketplaceProfileUrl(): Promise<string> {
        const { data } = await httpAPI.get(URL_MARKETPLACE_PROFILE)
        return data
    }

    async navigation(): Promise<NavigationResponse> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }
}

export default new NavigationRepository()
