import { startTrackingModal } from './modal'
import { startTrackingPage } from './page'
import { startTrackingTask } from './task'

export const startTracking = () => {
    startTrackingPage()

    startTrackingTask()

    startTrackingModal()
}
