import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { SETTINGS_SERVICES_CONFIGURATION_ROUTES } from '@/features/service/ui/router/routes.constants'

import { SETTINGS_SERVICES_ROUTES } from './routes.constants'

const Index = () => import(/* webpackChunkName: "services-index" */ '../views/Index.vue')
const ServicesList = () => import(/* webpackChunkName: "services-list" */ '../views/SettingsServices.vue')
const ServiceItem = () =>
    import(/* webpackChunkName: "services-item" */ '@/components/Settings/Services/ServiceItem.vue')
const ServiceNew = () => import(/* webpackChunkName: "services-new" */ '@/components/Settings/Services/ServiceNew.vue')

export default [
    {
        path: 'services',
        component: Index,
        meta: {
            middleware: featureToggleMiddleware({
                key: 'ServiceConfiguration',
                value: 'false',
                fallbackRouteName: SETTINGS_SERVICES_CONFIGURATION_ROUTES.LIST
            })
        },
        children: [
            {
                path: '',
                name: SETTINGS_SERVICES_ROUTES.LIST,
                component: ServicesList
            },
            {
                path: ':id(\\d+)',
                name: SETTINGS_SERVICES_ROUTES.DETAIL,
                component: ServiceItem
            },
            {
                path: ':id(\\d+)/schedule/:scheduleId(\\d+)',
                name: SETTINGS_SERVICES_ROUTES.SERVICE_SCHEDULE,
                component: ServiceItem
            },
            {
                path: 'new',
                name: SETTINGS_SERVICES_ROUTES.NEW,
                component: ServiceNew
            }
        ]
    }
]
