import {
    MUTATION_FETCH_APPOINTMENT_PROPOSALS,
    MUTATION_FETCH_CHECKIN_INFO,
    MUTATION_FETCH_EVENT_DATA,
    MUTATION_FETCH_EVENT_DATA_CLEAR,
    MUTATION_FETCH_FINANCIAL_SUMMARY,
    MUTATION_FETCH_FUTURE_EVENT_DATA,
    MUTATION_FETCH_INSURANCES,
    MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE,
    MUTATION_FETCH_NEXT_EVENT_DATA,
    MUTATION_FETCH_PATIENT_COUNTERS,
    MUTATION_FETCH_PATIENT_DATA,
    MUTATION_NOMORE_EVENT_DATA,
    MUTATION_RESET_PATIENT_DATA,
    MUTATION_RESET_SELECTED_PATIENT_IDS,
    MUTATION_SET_ALL_PATIENTS_SELECTED,
    MUTATION_SET_PAGE_ALL_PATIENT_IDS,
    MUTATION_SET_PATIENT_REVIEW_STATUS,
    MUTATION_SET_PATIENTS_PAGE_DATA,
    MUTATION_SET_SELECTED_NEW_OWNER,
    MUTATION_SET_SELECTED_PATIENT_ID,
    MUTATION_SET_TABLE_EDIT_STATE,
    MUTATION_UNSET_SELECTED_PATIENT_ID,
    MUTATION_UPDATE_PATIENT_DATA
} from './types/mutations'

const mutations = {
    [MUTATION_UPDATE_PATIENT_DATA](state, data) {
        state.patient = { ...state.patient, [data.property]: data.value }
    },
    [MUTATION_FETCH_PATIENT_DATA](state, data) {
        state.patient = data
    },
    [MUTATION_RESET_PATIENT_DATA](state) {
        state.patient = null
    },
    [MUTATION_NOMORE_EVENT_DATA](state, noMoreEvents) {
        state.noMoreEventsToLoad = noMoreEvents
    },
    [MUTATION_SET_SELECTED_PATIENT_ID](state, patientId) {
        if (!state.isAllPatientsSelected) {
            if (state.selectedPatientIds.includes(patientId)) {
                return
            }

            state.selectedPatientIds = [...state.selectedPatientIds, patientId]
        } else {
            state.unselectedPatientIds = state.unselectedPatientIds.filter(id => id !== patientId)
        }

        state.counterSelectedPatients += 1
    },
    [MUTATION_UNSET_SELECTED_PATIENT_ID](state, patientId) {
        if (!state.isAllPatientsSelected) {
            state.selectedPatientIds = state.selectedPatientIds.filter(id => id !== patientId)
        } else {
            if (state.unselectedPatientIds.includes(patientId)) {
                return
            }

            state.unselectedPatientIds = [...state.unselectedPatientIds, patientId]
        }

        state.counterSelectedPatients -= 1
    },
    [MUTATION_SET_PATIENTS_PAGE_DATA](state, { page, pagesAmount, total, totalPrivatePatients }) {
        state.page = page
        state.pagePatientIds = page.map(item => item.id)
        state.totalPatients = total
        state.totalPrivatePatients = totalPrivatePatients
        state.pageTotal = pagesAmount
        state.pageLength = page.length
    },
    [MUTATION_SET_PAGE_ALL_PATIENT_IDS](state, shouldSelectAll = false) {
        if (!state.isAllPatientsSelected) {
            state.selectedPatientIds = shouldSelectAll
                ? [...state.pagePatientIds, ...state.selectedPatientIds]
                : state.selectedPatientIds.filter(id => !state.pagePatientIds.includes(id))
            state.counterSelectedPatients = state.selectedPatientIds.length
        } else {
            state.unselectedPatientIds = [...state.unselectedPatientIds, state.pagePatientIds]
            state.counterSelectedPatients -= state.unselectedPatientIds.length
        }
    },
    [MUTATION_SET_ALL_PATIENTS_SELECTED](state) {
        state.isAllPatientsSelected = true
        state.selectedPatientIds = state.pagePatientIds
        state.counterSelectedPatients = state.totalPatients
    },
    [MUTATION_RESET_SELECTED_PATIENT_IDS](state) {
        state.selectedPatientIds = []
        state.unselectedPatientIds = []
        state.isAllPatientsSelected = false
        state.counterSelectedPatients = 0
    },
    [MUTATION_FETCH_EVENT_DATA_CLEAR](state) {
        state.events = []
        state.futureEvents = {
            count: 0,
            items: []
        }
    },
    [MUTATION_FETCH_EVENT_DATA](state, newEvents) {
        state.events = [...state.events, ...newEvents]
    },
    [MUTATION_SET_TABLE_EDIT_STATE](state, payload) {
        state.tableEditState = payload
    },
    [MUTATION_FETCH_FINANCIAL_SUMMARY](state, visitSummary) {
        state.financialVisitSummary = {
            ...visitSummary
        }
    },
    [MUTATION_FETCH_PATIENT_COUNTERS](state, { episodeCounter, eventCounter, paymentCounter, fileCounter }) {
        state.patientCounters = {
            episodeCounter,
            eventCounter,
            paymentCounter,
            fileCounter
        }
    },
    [MUTATION_FETCH_FUTURE_EVENT_DATA](state, newEvents) {
        state.futureEvents = {
            ...state.futureEvents,
            ...newEvents,
            items: [...(newEvents?.items || []), ...(state.futureEvents?.items || [])]
        }
    },
    [MUTATION_FETCH_INSURANCES](state, data) {
        state.insurances = data
    },
    [MUTATION_FETCH_NEXT_EVENT_DATA](state, nextEvent) {
        state.nextEvent = nextEvent
    },
    [MUTATION_FETCH_CHECKIN_INFO](state, checkinInfo) {
        state.checkinInfo = checkinInfo
    },
    [MUTATION_FETCH_NEAREST_EVENT_WITHOUT_EPISODE](state, event) {
        state.nearestEventWithoutEpisode = event
    },
    [MUTATION_SET_PATIENT_REVIEW_STATUS](state, data) {
        state.patientReviewRequestStatus = data
    },
    [MUTATION_SET_SELECTED_NEW_OWNER](state, data) {
        state.selectedNewOwner = data
    },
    [MUTATION_FETCH_APPOINTMENT_PROPOSALS](state, data) {
        state.proposals = data
    }
}

export default mutations
