import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'

import { ReportFiltersIds, VisitStatus } from '../enums'

export const isFeatureEnabledPaymenstArea = () => {
    return ensureToggleIsEnabled('PaymentsArea', true)
}

export const getUpcomingVisitsRequestFilters = (schedulesIds: string[]) => {
    return [
        {
            tag: ReportFiltersIds.Schedule,
            values: schedulesIds
        },
        {
            tag: ReportFiltersIds.Status,
            values: [VisitStatus.Pending]
        }
    ]
}

export const getHistoryVisitsRequestFilters = (schedulesIds: string[]) => {
    return [
        {
            tag: ReportFiltersIds.Schedule,
            values: schedulesIds
        },
        {
            tag: ReportFiltersIds.Status,
            values: [VisitStatus.Visited, VisitStatus.NoShow, VisitStatus.Pending]
        }
    ]
}
