const state = {
    responseOptions: {},
    defaultValues: {},
    layoutSettings: {
        header: {},
        body: {},
        footer: {}
    },
    dataSettings: {
        doctor: {},
        clinic: {},
        patient: {},
        clinicCustomData: {},
        doctorCustomData: {},
        clinicAdditionalData: [],
        doctorAdditionalData: []
    },
    isClinicSchedule: false,
    logoImagesToUpload: {}
}

export default state
