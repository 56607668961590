import { groupBy } from '@dp-vue/utils'

import { DateFormat, dateIsBetweenDateRange, DateUnit, parseDate } from '@/core/ui/utils/date'
import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import { DateRange } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import { Resource } from '@/features/calendar/domain/interfaces/resource.interfaces'
import repository from '@/features/doctor/api/doctor.api'
import { Doctor, DoctorsGrouped } from '@/features/doctor/domain/interfaces/doctor.interfaces'

const doctorMapping = (doctor: Doctor) => ({
    ...doctor,
    color: doctor.doctorColor,
    selected: false
})

class DoctorService extends CalendarFilter<Doctor> {
    async getDoctors(): Promise<Doctor[]> {
        const doctors = await repository.getDoctors()
        return doctors.map(doctorMapping)
    }

    getAvailableDoctorsWorkingCurrentPeriod(
        resources: Resource[],
        dateRange: DateRange,
        selectedFacility: number | undefined
    ): number[] {
        return resources.reduce((ids: number[], { doctorId, availableDates, facilityId }) => {
            const isAvailable =
                availableDates.some(({ date }) => {
                    const parsedDate = parseDate(date, DateFormat.DateDashedFormat)

                    if (!parsedDate) {
                        return false
                    }

                    return dateIsBetweenDateRange(parsedDate, dateRange, DateUnit.Day, '[]')
                }) && (selectedFacility ? selectedFacility === facilityId : true)

            if (isAvailable) {
                ids.push(doctorId)
            }

            return ids
        }, [])
    }

    filterDoctorsByProperties(doctors: Doctor[], properties: Doctor): Doctor[] {
        const { facilityId, specialityId, name } = properties
        const regExp = new RegExp(properties.name, 'i')
        const facilityDoctors = doctors.filter(el => {
            const hasFacilityId = facilityId ? el.userSchedules.some(item => item.facilityId === facilityId) : true

            const hasSpecialityId = specialityId ? el.specialityId === specialityId : true

            const hasNameLength = name?.length ? (el.name || '').match(regExp) : true

            return hasFacilityId && hasSpecialityId && hasNameLength
        })

        return this.orderListBySelectedAndName(facilityDoctors)
    }

    getSelectedDoctor(doctors: Doctor[]): Doctor | null {
        const selectedDoctors = doctors.filter(({ selected }) => selected)
        const [selectedDoctor = null] = selectedDoctors.length === 1 ? selectedDoctors : []

        return selectedDoctor
    }

    groupDoctorsBySpeciality(doctors: Doctor[] = []): Array<DoctorsGrouped> {
        const doctorsWithSpeciality = doctors.flatMap(doctor => {
            if (!doctor.specialities?.length) {
                return [doctor]
            }

            return doctor.specialities.map(({ id, name }) => ({
                ...doctor,
                specialityId: id,
                specialityName: name
            }))
        })

        const group = groupBy(doctorsWithSpeciality, 'specialityId')
        const specialityList: Array<DoctorsGrouped> = []

        Object.keys(group).forEach(key => {
            specialityList.push({
                id: parseInt(key, 10),
                name: group[key][0].specialityName,
                doctorIds: group[key].map((doctor: Doctor) => doctor.id)
            })
        })

        return specialityList
    }

    getDoctorSchedulesIds(doctors: Doctor[] = []): number[] {
        return doctors?.flatMap(doctor => this.getIndividualDoctorSchedulesIds(doctor))
    }

    getIndividualDoctorSchedulesIds(doctor: Doctor): number[] {
        return doctor?.userSchedules.map(({ id }) => id)
    }
}

export default new DoctorService()
