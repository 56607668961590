import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'

import {
    ROUTE_LANDING_PAGE_BASE,
    ROUTE_LANDING_PAGE_BASE_NAME,
    ROUTE_LANDING_PAGE_E_PRESCRIPTION,
    ROUTE_LANDING_PAGE_E_PRESCRIPTION_NAME
} from '../constants/routes.constants'

const Eprescription = () => import(/* webpackChunkName: "landing-eprescription" */ '../views/Eprescription.vue')
const Landing = () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue')

export default [
    {
        path: ROUTE_LANDING_PAGE_BASE,
        name: ROUTE_LANDING_PAGE_BASE_NAME,
        component: Landing,
        redirect: ROUTE_CALENDAR_BASE_NAME,
        children: [
            {
                path: ROUTE_LANDING_PAGE_E_PRESCRIPTION,
                name: ROUTE_LANDING_PAGE_E_PRESCRIPTION_NAME,
                component: Eprescription
            }
        ]
    }
]
