export class TabCounter {
    state = {
        episodeCounter: 0,
        eventCounter: 0,
        paymentCounter: 0,
        fileCounter: 0
    }

    setEpisodeCounter(value: number) {
        this.state.episodeCounter = value
    }

    setEventCounter(value: number) {
        this.state.eventCounter = value
    }

    setPaymentCounter(value: number) {
        this.state.paymentCounter = value
    }

    setFileCounter(value: number) {
        this.state.fileCounter = value
    }

    getAll() {
        return this.state
    }
}
