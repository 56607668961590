import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { QuotesRoutes } from '../enums/route.enums'

const QuotesIndex = () => import(/* webpackChunkName: "quotes-index" */ '@/features/quotes/ui/views/QuotesIndex.vue')
const QuotesList = () => import(/* webpackChunkName: "quotes-list" */ '@/features/quotes/ui/views/QuotesList.vue')
const QuotesForm = () => import(/* webpackChunkName: "quotes-form" */ '@/features/quotes/ui/views/QuotesForm.vue')
const QuotesDetail = () => import(/* webpackChunkName: "quotes-detail" */ '@/features/quotes/ui/views/QuotesDetail.vue')

export default {
    path: 'quotes',
    component: QuotesIndex,
    meta: {
        title: 'new-settings-screen-extra-features-quotes-title',
        middleware: [uninav],
        uninavName: 'billing_main'
    },
    children: [
        {
            path: '',
            name: QuotesRoutes.QuotesList,
            component: QuotesList
        },
        {
            path: 'create-new-quote/:quoteNumber',
            name: QuotesRoutes.QuotesCreate,
            component: QuotesForm
        },
        {
            path: 'edit-quote/:quoteId',
            name: QuotesRoutes.QuotesEdit,
            component: QuotesForm
        },
        {
            path: ':quoteId',
            name: QuotesRoutes.QuotesDetail,
            component: QuotesDetail
        }
    ]
}
