import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

const URL_INVOICING_ENTITIES = 'invoicingentities'
const URL_INVOICING_ENTITY_UPDATE = `${URL_INVOICING_ENTITIES}/:invoicingEntityId`
const URL_VALIDATORS = `${URL_INVOICING_ENTITIES}/validators`
const URL_TAX_REGIMES = `${URL_INVOICING_ENTITIES}/taxregimes`
const URL_WITH_HOLDING_REASONS = `${URL_INVOICING_ENTITIES}/withholdingreasons`
const URL_WELFARE_FUNDS = `${URL_INVOICING_ENTITIES}/welfarefunds`
const URL_EXISTS_INVOICE_NUMBER = `${URL_INVOICING_ENTITIES}/:invoicingEntityId/invoices/:year/:invoiceNumber`
const URL_INVOICING_ENTITY_LOGO = `${URL_INVOICING_ENTITIES}/:invoicingEntityId/logo`

const InvoicingEntities = () => {
    return {
        async getAll() {
            const { data: entities } = await httpAPI.get(URL_INVOICING_ENTITIES)
            return entities
        },
        async createInvoicingEntity(data) {
            const { data: id } = await httpAPI.post(URL_INVOICING_ENTITIES, data)
            return id
        },
        updateInvoicingEntity(id, data) {
            const url = getUrlFromTemplate({
                template: URL_INVOICING_ENTITY_UPDATE,
                params: { invoicingEntityId: id }
            })
            return httpAPI.put(url, data)
        },
        deleteInvoicingEntity(id, forceDelete = false) {
            const url = getUrlFromTemplate({
                template: URL_INVOICING_ENTITY_UPDATE + (forceDelete ? '/force' : ''),
                params: { invoicingEntityId: id }
            })
            return httpAPI.delete(url)
        },
        async getValidators() {
            const { data: validators } = await httpAPI.get(URL_VALIDATORS)
            return validators
        },
        async getTaxRegimes() {
            const { data: taxRegimes } = await httpAPI.get(URL_TAX_REGIMES)
            return taxRegimes
        },
        async getWithHoldingReasons() {
            const { data: withHoldingReasons } = await httpAPI.get(URL_WITH_HOLDING_REASONS)
            return withHoldingReasons
        },
        async getWelfareFunds() {
            const { data: welfareFunds } = await httpAPI.get(URL_WELFARE_FUNDS)
            return welfareFunds
        },
        async existsInvoiceNumber(invoicingEntityId, invoiceNumber, year) {
            const url = getUrlFromTemplate({
                template: URL_EXISTS_INVOICE_NUMBER,
                params: {
                    invoicingEntityId,
                    invoiceNumber: btoa(invoiceNumber),
                    year
                }
            })

            const { data: exists } = await httpAPI.get(url)
            return exists
        },
        async getLogo(id) {
            const url = getUrlFromTemplate({
                template: URL_INVOICING_ENTITY_LOGO,
                params: {
                    invoicingEntityId: id
                }
            })
            const { data } = await httpAPI.get(url)
            return data
        }
    }
}

export default InvoicingEntities
