import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { AvailablePlugin, PluginsCode } from '../domain/interfaces/plugins.interfaces'

const URL_BASE = 'plugins'
const URL_PLUGINS_SINGLE = `${URL_BASE}/:id`
const URL_PLUGINS_SINGLE_STATUS = `${URL_PLUGINS_SINGLE}/status`
const URL_AVAILABLE_PLUGINS = `${URL_BASE}/available-plugins`

class PluginsRepository {
    async getPluginsCode(): Promise<PluginsCode> {
        const { data } = await httpAPI.post(URL_BASE)

        return data
    }

    async getPluginsPath({ pluginId, redirectUrl }: { pluginId?: number; redirectUrl?: string } = {}): Promise<string> {
        if (pluginId) {
            const url = getUrlFromTemplate({
                template: URL_PLUGINS_SINGLE,
                params: { id: pluginId }
            })

            return httpAPI.get(url, {
                params: {
                    redirectUrl
                }
            })
        }

        const { data } = await httpAPI.get(URL_BASE)

        return data
    }

    async getActivatePluginUrl(id: number, redirectUrl: string = location.href): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_PLUGINS_SINGLE,
            params: { id }
        })

        const { data } = await httpAPI.get(url, {
            params: {
                redirectUrl
            }
        })

        return data
    }

    async getPluginStatus({ pluginId }: { pluginId: string }): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_PLUGINS_SINGLE_STATUS,
            params: { id: pluginId }
        })

        const { data } = await httpAPI.get(url)

        return data
    }

    async availablePlugins(): Promise<AvailablePlugin[]> {
        const { data } = await httpAPI.get(URL_AVAILABLE_PLUGINS)

        return data?.plugins || []
    }
}

export default new PluginsRepository()
