import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import { COLOR_TRANSPARENT } from '@/features/calendar/domain/constants/colorSchemas.constants'
import { CalendarEventDisplayType, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { ColorSchema } from '@/features/calendar/domain/interfaces/calendar.interfaces'
import { HolidayDTO, HolidayEvent } from '@/features/calendar/domain/interfaces/holiday.interfaces'
import { composeHolidayId } from '@/features/calendar/domain/utils/calendar.holiday.util'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'

class CalendarHolidaysService {
    mapHolidaysToEvents(
        holidays: HolidayDTO[],
        schedules: Record<number, Schedule>,
        colorSchemas: Record<number, ColorSchema>
    ): HolidayEvent[] {
        return holidays.map(holiday => {
            const schedule = schedules[holiday.scheduleId]
            const colorSchema = colorSchemas[schedule.colorSchemaId]

            const holidayId = composeHolidayId({
                id: holiday.externalId || holiday.id.toString(),
                scheduleId: holiday.scheduleId
            })

            return {
                ...holiday,
                ...CalendarEventService.processStartEndToDate(holiday),
                displayDefault: CalendarEventDisplayType.Auto,
                id: holidayId,
                type: CalendarEventType.Holiday,
                color: COLOR_TRANSPARENT,
                editable: false,
                startEditable: false,
                durationEditable: false,
                scheduleId: schedule?.id,
                facilityId: schedule.facilityId,
                specialityId: schedule.specialityId,
                colorSchemas: colorSchema || null,
                className: [`event-id-${holidayId}`],
                resourceId: schedule.id,
                scheduleName: schedule?.name
            }
        })
    }
}

export default new CalendarHolidaysService()
