import { PaymentsReportsDataPage } from '../interfaces/reports.interfaces'
import { Visit } from '../interfaces/visits.interfaces'

export const mapVisitFromDTO = ({
    id,
    appointmentTime,
    paymentStatus: { filterValue },
    date,
    price,
    patient,
    schedule,
    eventServices
}: PaymentsReportsDataPage): Visit => {
    const [{ serviceName, duration }] = eventServices
    return {
        id,
        appointmentTime,
        date,
        paymentStatus: Number(filterValue),
        schedule,
        serviceName,
        price,
        duration,
        patientName: patient
    }
}
