import { ensureToggleIsEnabled } from './utils/ensureToggleIsEnabled'
import { getActualToggles } from './utils/getActualToggles'
import { getQueryStringFromUrl } from './utils/getQueryStringFromUrl'

const actualToggles = getActualToggles()

export const middleware = ({ key, value, fallbackRouteName }) => {
    return ({ next }) => {
        const isToggleEnabled = ensureToggleIsEnabled({
            key,
            value,
            toggles: actualToggles,
            queryString: getQueryStringFromUrl()
        })

        if (isToggleEnabled) {
            return next()
        }

        return next({ name: fallbackRouteName })
    }
}
