export enum ChatType {
    PatientChat = 'PatientChat',
    SaasInternalChat = 'SaasInternalChat'
}

export enum ChatTrackingType {
    PatientChat = 'external',
    SaasInternalChat = 'internal'
}

export enum ChatSessionType {
    DefaultSession = 'DefaultSession',
    ImpersonatorSession = 'ImpersonatorSession'
}

export enum PatientChatCategory {
    Archived = 'Archived',
    Main = 'Main',
    Unread = 'Unread'
}

export enum PatientChatFolder {
    Done = 'done',
    Inbox = 'inbox'
}

export enum ConversationListUpdateAction {
    MessageSent = 'MessageSent',
    Read = 'Read',
    WSNotification = 'WSNotification'
}

export enum ChatUserType {
    Clinic = 'Clinic',
    Doctor = 'Doctor',
    MarketplaceUser = 'MarketplaceUser',
    OfflinePatient = 'OfflinePatient',
    OnlinePatient = 'OnlinePatient'
}
