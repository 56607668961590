import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import { Insurance } from '@/features/insurance/domain/interfaces/insurance.interfaces'
import InsuranceService from '@/features/patient/domain/insurance.service'
import ServicesRepository from '@/features/servicesLegacy/api/services.api'
import { ServiceType } from '@/features/servicesLegacy/domain/enums/services.enums'
import {
    AvailableSchedulesEntity,
    BaseService,
    DuplicatedServiceEntity,
    GroupedServiceScheduleEntity,
    LinkedScheduleData,
    NewService,
    NewServiceWithServiceSchedulesId,
    Service,
    ServiceDetailsEntity,
    ServiceDetailsServiceSchedulesEntity,
    ServiceEntity,
    ServiceSchedule,
    ServiceScheduleEntity,
    ServicesFilters,
    ServiceWithServiceSchedules
} from '@/features/servicesLegacy/domain/interfaces/services.interfaces'
import {
    BaseServiceWithType,
    DuplicatedService
} from '@/features/servicesLegacy/ui/interfaces/components/ServiceInput.interfaces'
import ScheduleRepository from '@/features/settings/api/schedule.api'
import {
    Schedule,
    ScheduleInsurancePrice,
    ScheduleService,
    ScheduleServiceForEditing,
    ServiceScheduleInsurancePayload
} from '@/features/settings/domain/interfaces/schedule.interfaces'

import {
    PatchServiceScheduleDuration,
    PatchServiceSchedulePayload,
    PatchServiceSchedulePrice,
    PatchServiceScheduleShowOnline
} from '../api/interfaces/services.interfaces'
import { DEFAULT_CONFIGURATION_DURATION, FILTER_DEFAULT_SERVICE_ONLINE } from './constants/services.constants'

const serviceMapping = (service: Service) => ({
    ...service,
    selected: false,
    hidden: false
})

class ServicesService extends CalendarFilter<Service> {
    async getServices(): Promise<Service[]> {
        const roomDTOs = await ServicesRepository.getServices()
        return roomDTOs.map(serviceMapping)
    }

    getServicesV2(): Promise<ServiceEntity[]> {
        return ServicesRepository.getServicesV2()
    }

    getServiceById(serviceId: number): Promise<ServiceDetailsEntity> {
        return ServicesRepository.getServiceById(serviceId)
    }

    getServiceSchedulesInUse(): Promise<number[]> {
        return ServicesRepository.getServiceSchedulesInUse()
    }

    addAgendasToService(serviceId: number, scheduleIds: number[]): Promise<void> {
        return ServicesRepository.addAgendasToService(serviceId, scheduleIds)
    }

    updateServiceScheduleDuration(payload: PatchServiceScheduleDuration): Promise<void> {
        return ServicesRepository.updateServiceScheduleDuration(payload)
    }

    updateServiceSchedule(serviceId: number, serviceObject: NewService): Promise<void> {
        return ServicesRepository.updateServiceSchedule(serviceId, serviceObject)
    }

    updateServiceSchedulePrice(payload: PatchServiceSchedulePrice): Promise<void> {
        return ServicesRepository.updateServiceSchedulePrice(payload)
    }

    removeServiceSchedulePrice(payload: PatchServiceSchedulePayload): Promise<void> {
        return ServicesRepository.removeServiceSchedulePrice(payload)
    }

    updateServiceScheduleShowOnline(payload: PatchServiceScheduleShowOnline): Promise<void> {
        return ServicesRepository.updateServiceScheduleShowOnline(payload)
    }

    getAvailableSchedules(serviceIds: number[]): Promise<AvailableSchedulesEntity> {
        return ServicesRepository.getAvailableSchedules(serviceIds)
    }

    getDefaultServiceConfiguration(
        scheduleId: number,
        serviceId: number,
        serviceName: string,
        docplannerServiceDictionaryId?: number | null
    ): ServiceDetailsServiceSchedulesEntity {
        return {
            description: '',
            docplannerServiceDictionaryId,
            duration: DEFAULT_CONFIGURATION_DURATION,
            id: null,
            integrationServiceId: '',
            isDefault: false,
            isUsed: false,
            patientCanBook: null,
            price: null,
            priceFrom: false,
            scheduleId,
            serviceBaseId: null,
            serviceId,
            serviceName,
            showOnline: false
        }
    }

    getGroupedServiceSchedule(
        services: ServiceEntity[],
        serviceSchedulesInUse: number[],
        filters: ServicesFilters
    ): {
        data: GroupedServiceScheduleEntity[]
        filters: {
            addresses: Set<string>
            agendas: Set<string>
        }
    } {
        const preparedServices = {} as Record<string, GroupedServiceScheduleEntity>
        const addresses = new Set<string>()
        const agendas = new Set<string>()

        services.forEach(service => {
            if (!service.schedules.length) {
                return
            }

            if (!Object.prototype.hasOwnProperty.call(preparedServices, service.name)) {
                preparedServices[service.name] = {
                    name: service.name,
                    isCustom: service.isCustom,
                    items: []
                } as GroupedServiceScheduleEntity
            }

            const serviceSchedules = service.schedules.flatMap(schedule =>
                schedule.serviceSchedules
                    .map(serviceSchedule => {
                        if (schedule?.address) {
                            addresses.add(schedule.address)
                        }

                        if (schedule?.name) {
                            agendas.add(schedule.name)
                        }

                        return {
                            ...serviceSchedule,
                            priceFrom: serviceSchedule.priceFrom ?? false,
                            showOnline: serviceSchedule.showOnline ?? false,
                            serviceName: service.name,
                            serviceId: service.id,
                            serviceColorSchemaId: service.colorSchemaId,
                            name: schedule.name,
                            address: schedule.address,
                            isOwner: service.isOwner,
                            isCustom: service.isCustom,
                            scheduleId: schedule.id,
                            isVirtual: schedule.isVirtual,
                            isPaymentEnabled: schedule.isPaymentEnabled,
                            features: schedule.features,
                            scheduleKind: schedule.kind,
                            isUsed: serviceSchedulesInUse.includes(serviceSchedule.id)
                        }
                    })
                    .filter(serviceSchedule => this.doesServiceScheduleMatchFilters(serviceSchedule, filters))
            )

            if (serviceSchedules.length) {
                preparedServices[service.name].items.push(...serviceSchedules)
            }
        })

        const data = Object.keys(preparedServices).reduce((acc, serviceName): GroupedServiceScheduleEntity[] => {
            return [...acc, ...(preparedServices[serviceName].items.length ? [preparedServices[serviceName]] : [])]
        }, [] as GroupedServiceScheduleEntity[])

        return {
            data,
            filters: {
                agendas,
                addresses
            }
        }
    }

    doesServiceScheduleMatchFilters(serviceSchedule: ServiceScheduleEntity, filters: ServicesFilters): boolean {
        if (filters.online !== FILTER_DEFAULT_SERVICE_ONLINE && !serviceSchedule.showOnline === filters.online) {
            return false
        }

        if (filters.addresses.length && !filters.addresses.includes(serviceSchedule.address)) {
            return false
        }

        if (filters.agendas.length && !filters.agendas.includes(serviceSchedule.name)) {
            return false
        }

        if (
            filters?.text?.trim().length &&
            !serviceSchedule.serviceName.toLowerCase().includes(filters.text.trim().toLowerCase())
        ) {
            return false
        }

        return true
    }

    getBaseServices(name: string): Promise<BaseService[]> {
        return ServicesRepository.getBaseServices(name)
    }

    async getServicesByScheduleId(scheduleId: number): Promise<ScheduleService[]> {
        return ScheduleRepository.getScheduleServices(scheduleId)
    }

    getDuplicatedServices(name: string): Promise<DuplicatedServiceEntity> {
        return ServicesRepository.getDuplicatedServices(name)
    }

    isNonDuplicatedService(name: string, duplicatedServices: DuplicatedService[]) {
        const duplicatedServiceNames = duplicatedServices.map(({ name: serviceName }) => serviceName)

        return !duplicatedServiceNames.includes(name)
    }

    async setDuplicatedServices(query: string): Promise<DuplicatedService[]> {
        const duplicatedData = await this.getDuplicatedServices(query)

        if (duplicatedData?.isDuplicated) {
            return duplicatedData.serviceIds.map(item => ({
                ...item,
                type: ServiceType.DuplicatedService
            }))
        }

        return []
    }

    async setServices(query: string, duplicatedServices: DuplicatedService[]): Promise<BaseServiceWithType[]> {
        const services = await this.getBaseServices(query)

        const nonDuplicatedServices = services
            .filter(({ name }) => this.isNonDuplicatedService(name, duplicatedServices))
            .map(item => ({
                ...item,
                type: ServiceType.NonDuplicatedService
            }))

        return nonDuplicatedServices
    }

    isCustomService(service: BaseService | Service): boolean {
        return (
            !service ||
            service.isCustom ||
            ((!service.serviceBaseId || service.serviceBaseId <= 0) &&
                (!service.docplannerServiceDictionaryId || service.docplannerServiceDictionaryId <= 0))
        )
    }

    isServiceSwitchDisabled(service: ScheduleService, isDecustomizeServicesOn: boolean): boolean {
        const { isDefault, isUsed, isCustom, showOnline } = service

        return (!!isDefault && showOnline) || !!isUsed || (isDecustomizeServicesOn && !!isCustom && !showOnline)
    }

    canSelectSchedule(schedule: ServiceSchedule, service: Service): boolean {
        const hasScheduleSpecialities = schedule.allSpecialityIds && schedule.allSpecialityIds.length > 0

        if (!service.specialityId || !hasScheduleSpecialities) {
            return true
        }

        const serviceSpecialityIds = [service.specialityId, ...service.clinicsSpecialitiesIds]

        return serviceSpecialityIds.some(id => schedule.allSpecialityIds.includes(id))
    }

    canAccessSchedule(schedule: ServiceSchedule, service: Service): boolean {
        const isCustomService = !!(service.docplannerServiceDictionaryId || service.serviceBaseId)
        const scheduleBlockedForCustomServices = schedule.isOnlinePaymentEnabled

        return !scheduleBlockedForCustomServices || isCustomService
    }

    setServiceScheduleIds(schedule: ServiceSchedule, service: Service): ServiceSchedule {
        if (this.isCustomService(service)) {
            schedule.serviceBaseId = null
            schedule.docplannerServiceDictionaryId = null
            schedule.showOnline = false
            schedule.patientCanBook = false
        } else {
            schedule.isCustom = false
            if (service.docplannerServiceDictionaryId) {
                schedule.serviceBaseId = null

                schedule.docplannerServiceDictionaryId = service.docplannerServiceDictionaryId
            } else {
                schedule.serviceBaseId = service.serviceBaseId
                schedule.docplannerServiceDictionaryId = null
            }
        }
        return schedule
    }

    initServiceSchedule(
        schedule: Schedule,
        patientCanBookServiceEnabled: boolean,
        isDoctorCalendarKind: boolean
    ): ServiceSchedule {
        return {
            ...schedule,
            serviceName: '',
            isSelected: false,
            scheduleId: schedule.id,
            duration: 20,
            price: null,
            priceFrom: false,
            showOnline: isDoctorCalendarKind,
            patientCanBook: !patientCanBookServiceEnabled,
            hasErrors: false,
            serviceScheduleInsurances: []
        }
    }

    mergeServiceSchedule(
        schedule: Schedule,
        serviceSchedule: ServiceSchedule,
        patientCanBookServiceEnabled: boolean
    ): ServiceSchedule {
        return {
            ...schedule,
            ...serviceSchedule,
            priceFrom: !!serviceSchedule.priceFrom,
            showOnline: !!serviceSchedule.showOnline,
            patientCanBook: patientCanBookServiceEnabled
                ? serviceSchedule.patientCanBook
                : !!serviceSchedule.showOnline,
            hasErrors: false,
            isSelected: true
        }
    }

    generateServicePayload(
        service: Service,
        schedules: Array<ServiceSchedule>,
        patientCanBookServiceEnabled: boolean,
        colorId?: number
    ): NewService {
        const { scheduleInsuranceServices = [] } = service

        const selectedScheduleIds = schedules.filter(schedule => schedule.isSelected).map(schedule => schedule.id)

        return {
            ...service,
            colorSchemaId: typeof colorId !== 'undefined' ? colorId : service.colorSchemaId,
            scheduleInsuranceServices: scheduleInsuranceServices.filter(({ scheduleId }) =>
                selectedScheduleIds.includes(scheduleId)
            ),
            serviceSchedules: schedules
                .filter(({ isSelected, isUsed, isDefault }) => isSelected || isUsed || isDefault)
                .map(schedule => ({
                    scheduleId: schedule.id,
                    duration: schedule.duration,
                    price: schedule.price,
                    priceFrom: schedule.priceFrom,
                    showOnline: schedule.showOnline,
                    patientCanBook: patientCanBookServiceEnabled ? schedule.patientCanBook : schedule.showOnline,
                    description: schedule.description,
                    serviceBaseId: schedule.serviceBaseId,
                    docplannerServiceDictionaryId: schedule.docplannerServiceDictionaryId,
                    integrationServiceId: schedule.integrationServiceId,
                    serviceScheduleInsurances: schedule.serviceScheduleInsurances
                }))
        }
    }

    generateServiceSchedulePayload(
        service: ServiceWithServiceSchedules,
        schedules: Array<ServiceSchedule>,
        selectedScheduleId: number,
        colorId?: number
    ): NewServiceWithServiceSchedulesId {
        const { scheduleInsuranceServices = [] } = service
        const selectedScheduleService = schedules.find(schedule => schedule.scheduleId === selectedScheduleId)
        if (!selectedScheduleService) {
            throw new Error()
        }
        const foreignConfigurations = service.serviceSchedules.filter(
            configuration => configuration.scheduleId !== selectedScheduleId
        )
        const serviceSchedules = selectedScheduleService.serviceSchedules.concat(foreignConfigurations)

        return {
            ...service,
            colorSchemaId: colorId ?? service.colorSchemaId,
            scheduleInsuranceServices,
            serviceSchedules
        }
    }

    generateServiceSettingsByInsuranceData(
        serviceScheduleInsurances: ServiceScheduleInsurancePayload[],
        insurances: Insurance[],
        isNewService: boolean
    ): ServiceScheduleInsurancePayload[] {
        return insurances.map(({ name, id }) => {
            const matchingInsurance = serviceScheduleInsurances.find(({ insuranceId }) => insuranceId === id)

            if (!matchingInsurance) {
                return {
                    insuranceId: id,
                    name,
                    duration: 20,
                    leadTime: 0,
                    selfPayer: false,
                    selected: isNewService
                }
            }

            return {
                ...matchingInsurance,
                name,
                selected: true
            }
        })
    }

    generateServiceSettingsByInsurancePayload(
        insurancesData: ServiceScheduleInsurancePayload[]
    ): ServiceScheduleInsurancePayload[] {
        return insurancesData.map(({ duration, insuranceId, leadTime, selfPayer }) => ({
            duration,
            insuranceId,
            leadTime,
            selfPayer
        }))
    }

    updateScheduleInsuranceServices(
        scheduleInsuranceServices: Array<ScheduleInsurancePrice>,
        scheduleInsurance: ScheduleInsurancePrice
    ): Array<ScheduleInsurancePrice> {
        const { scheduleId: schId, insuranceId: insId, price: insurancePrice } = scheduleInsurance

        const exists = scheduleInsuranceServices.some(
            ({ scheduleId, insuranceId }) => scheduleId === schId && insuranceId === insId
        )

        if (!exists) {
            return [...scheduleInsuranceServices, scheduleInsurance]
        }

        return scheduleInsuranceServices.reduce((acum: Array<ScheduleInsurancePrice>, item: ScheduleInsurancePrice) => {
            const { scheduleId, insuranceId } = item

            if (scheduleId === schId && insuranceId === insId) {
                return insurancePrice ? [...acum, scheduleInsurance] : acum
            }

            return [...acum, item]
        }, [])
    }

    mergeEnabledInsurancesWithScheduleInsuranceServices(
        scheduleId: number,
        enabledInsurances: Array<Insurance>,
        scheduleInsuranceServices: Array<ScheduleInsurancePrice>
    ): Array<ScheduleInsurancePrice> {
        return enabledInsurances
            .filter(({ id: insuranceId }) => insuranceId > 0)
            .map(({ id: insuranceId, name: insuranceName }: Insurance): ScheduleInsurancePrice => {
                const scheduleInsuranceService = scheduleInsuranceServices.find(
                    item => item.insuranceId === insuranceId && item.scheduleId === scheduleId
                )

                return {
                    scheduleId,
                    insuranceId,
                    insuranceName,
                    price: null,
                    ...scheduleInsuranceService
                }
            })
    }

    applyPricesToSelectedSchedules(
        schedules: Array<ServiceSchedule>,
        scheduleInsuranceServices: Array<ScheduleInsurancePrice>,
        scheduleInsuranceServicesToCopy: Array<ScheduleInsurancePrice>,
        price: number,
        priceFrom: boolean
    ): {
        updatedScheduleInsuranceServices: Array<ScheduleInsurancePrice>
        updatedSchedules: Array<ServiceSchedule>
    } {
        const updatedScheduleInsuranceServices: Array<ScheduleInsurancePrice> = []

        const updatedSchedules = schedules.map(schedule => {
            const { id: scheduleId, isSelected, enabledInsurances = [] } = schedule

            if (isSelected) {
                enabledInsurances.forEach(({ id: insuranceId }) => {
                    const scheduleInsuranceToCopy = scheduleInsuranceServicesToCopy.find(
                        item => item.insuranceId === insuranceId
                    )

                    if (scheduleInsuranceToCopy) {
                        updatedScheduleInsuranceServices.push({
                            ...scheduleInsuranceToCopy,
                            scheduleId
                        })
                    } else {
                        const scheduleInsurance = scheduleInsuranceServices.find(
                            item => item.insuranceId === insuranceId
                        )

                        if (scheduleInsurance) {
                            updatedScheduleInsuranceServices.push(scheduleInsurance)
                        }
                    }
                })
            }

            return {
                ...schedule,
                price: isSelected ? price : schedule.price,
                priceFrom: isSelected ? priceFrom : schedule.priceFrom
            }
        })

        return { updatedSchedules, updatedScheduleInsuranceServices }
    }

    filterServicesByProperties(services: Service[], properties: Service): Service[] {
        const regExp = new RegExp(properties.name, 'i')
        const servicesList = services.filter(({ name, schedules }) => {
            const isMatchingName = properties.name.length ? name.match(regExp) : true

            if (properties.schedules) {
                const facilityScheduleIds = properties.schedules.map(schedule => schedule.id)
                const isServiceInFacility = schedules.some(schedule => facilityScheduleIds.includes(schedule.id))
                return isMatchingName && isServiceInFacility
            }

            return isMatchingName
        })

        return this.orderListBySelectedAndNameNormalized(servicesList)
    }

    pricesByInsuranceAreDifferent(
        source: Array<ScheduleInsurancePrice> = [],
        target: Array<ScheduleInsurancePrice> = []
    ): boolean {
        const differentLength = source.length !== target.length

        return (
            differentLength ||
            source.some(({ scheduleId, insuranceId, price }) => {
                return !target.find(
                    sI => sI.scheduleId === scheduleId && sI.insuranceId === insuranceId && sI.price === price
                )
            })
        )
    }

    async getServicesByScheduleIdFilteredForEditing(scheduleId: number): Promise<ScheduleServiceForEditing[]> {
        const services = await this.getServicesByScheduleId(scheduleId)

        const filteredServices = services.map(({ serviceId, serviceName, price, scheduleInsuranceServices }) => ({
            serviceId,
            serviceName,
            price,
            scheduleInsuranceServices
        }))

        return filteredServices
    }

    findServicePriceForInsuranceId(service: ScheduleServiceForEditing, insuranceId: number): number {
        const insuranceService = service.scheduleInsuranceServices.find(
            ({ insuranceId: currentInsuranceId }) => currentInsuranceId === insuranceId
        )
        return insuranceService?.price ?? service.price
    }

    async prepareInsurancesDataForServiceConfigurator(): Promise<Insurance[]> {
        const insurances = await InsuranceService.getAllInsurances()

        // Because we have insurance that is called "Without insurance" with id: -1
        return insurances.filter(({ id }) => id > 0)
    }

    affectedScheduleIdsByColorOrNameChange(
        selectedService: ServiceDetailsEntity,
        selectedScheduleId: number
    ): number[] {
        return selectedService.serviceSchedules
            .filter(item => item.scheduleId !== selectedScheduleId)
            .map(schedule => schedule.scheduleId)
    }

    linkedSchedulesByColorOrNameChange(
        schedules: ServiceSchedule[],
        affectedSchedules: number[]
    ): LinkedScheduleData[] {
        const uniqueAffectedScheduleIds = [...new Set(affectedSchedules)]
        return uniqueAffectedScheduleIds.reduce((acc: LinkedScheduleData[], elem) => {
            const schedule = schedules.find(({ scheduleId }) => scheduleId === elem)

            if (schedule) {
                acc.push({
                    id: schedule.id,
                    name: schedule.name || '',
                    displayName: schedule.displayName || '',
                    address: schedule.address
                })
            }

            return acc
        }, [])
    }
}

export default new ServicesService()
