import { isRemoteToggleEnabled } from '@/core/ui/plugins/featureToggle/providers/unleash'
import { getActualToggles } from '@/core/ui/plugins/featureToggle/utils/getActualToggles'

const toggles = getActualToggles()

export const $featureToggle = {
    ensure: (key: string, value: boolean) => {
        const stringValue = `${value}`

        const foundToggle = toggles[key]
        if (typeof foundToggle === 'undefined') return false

        return isRemoteToggleEnabled(key, stringValue)
    }
}
