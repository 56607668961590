import { SetupGuideGroupTask, SetupGuideTask } from '../interfaces'

/**
 * Map the given `SetupGuideTask` to `SetupGuideGroupTask`
 * @param task - Task to map
 */
export const mapGroupTaskFromTask = (task: SetupGuideTask): SetupGuideGroupTask => {
    return {
        completed: task.completed,
        id: task.id,
        name: task.name,
        url: task.url
    }
}
