import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { PaymentsPatientVisitPayload } from '../../domain/interfaces'
import { PaymentsPatientSuggestionDTO, PaymentsPatientVisitDTO } from '../interfaces'

const URL_BASE_PATIENTS = 'patients'
const URL_BASE_PATIENTS_V2 = 'v2/patients'

const URL_PATIENT_SEARCH = `${URL_BASE_PATIENTS}/search`
const URL_PATIENT_EVENTS = `${URL_BASE_PATIENTS_V2}/:patientId/events`

const MAX_NUMBER_OF_PATIENTS = 10

class PaymentsPatientsRepository {
    getPatientSuggestions(filter: string): Promise<{ data: PaymentsPatientSuggestionDTO[]; error: unknown }> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_SEARCH,
            query: {
                filter,
                results: MAX_NUMBER_OF_PATIENTS
            }
        })

        return httpAPI.get(url)
    }

    async getPatientVisits(patientId: number, payload: PaymentsPatientVisitPayload): Promise<PaymentsPatientVisitDTO> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_EVENTS,
            params: { patientId }
        })
        const { data } = await httpAPI.get(url, { params: payload })

        return data
    }
}

export default new PaymentsPatientsRepository()
