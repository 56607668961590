import Vue from 'vue'

import Storage from '@/store/storage'

import { REPORT_DASHBOARD_VIEW_COUNT } from '../constants/reports.constants'

export default function viewCounter({ name, meta: { trackView = false } }, from, next) {
    if (trackView) {
        const counter = Storage.get(REPORT_DASHBOARD_VIEW_COUNT) ? Storage.get(REPORT_DASHBOARD_VIEW_COUNT) + 1 : 1
        Storage.set(REPORT_DASHBOARD_VIEW_COUNT, counter)
        Vue.prototype.$tracking.trackTagManager({
            name: REPORT_DASHBOARD_VIEW_COUNT,
            counter
        })
    }
    next()
}
