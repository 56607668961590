import { SendbirdProviderV4 } from '@dp-chat/sdk'

import configManager from '@/bootstrap/configurationManager'
import { DateFormat, formatDate } from '@/core/ui/utils'
import ProfileService from '@/features/auth/domain/profile.service'
import {
    CHAT_FETCH_UNREAD_CONVERSATION_INTERVAL_IN_MINS,
    CHAT_LAST_FETCHED_UNREAD_CONVERSATIONS_TIMESTAMP
} from '@/features/chat/domain/constants/chat.constants'
import ConversationsService from '@/features/chat/domain/conversations.service'
import ConversationsV2Service from '@/features/chat/domain/conversationsV2.service'
import InternalChatService from '@/features/chat/domain/internalChat.service'
import PatientsConversationsService from '@/features/chat/domain/patientsConversations.service'
import { composeSendbirdProviderOptions } from '@/features/chat/domain/utils/composeSendbirdProviderOptions'
import { SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE } from '@/features/chat-overlay/ui/constants/constants'
import PatientService from '@/features/patient/domain/patient.service'
import MessagesService from '@/features/settings/domain/messages.service'
import VideoConsultationService from '@/features/videoConsultation/domain/videoConsultation.service'
import { ActionsAdaptor } from '@/interfaces'
import Storage from '@/store/storage'

import { ChatType } from '../../domain/enums/chat.enums'
import { UnreadConversation } from '../../domain/interfaces/conversations.interfaces'
import { QUICK_REPLIES_TYPES } from '../constants/chat.constants'
import { MODULE_CHAT_SESSION } from './session/types/module'
import { ActionTypes, ChatActions, ChatMutations, ChatState, MutationTypes } from './types'

const TEMPLATE_EVENT_DATA = {
    address: '{{Address}}',
    profileUrl: '{{ProfileUrl}}',
    confirmationUrl: '{{ConfirmationUrl}}'
}

export const actions: ActionsAdaptor<ChatActions, ChatMutations, ChatState> = {
    async [ActionTypes.ChatCreateProvider](
        state,
        { userId, recipientId, sessionToken, channelUrl, thumbnail, updateReadReceipt = true }
    ) {
        const appId = configManager.getValue('SENDBIRD_APP_ID')
        const customApiHost = configManager.getValue('SENDBIRD_CUSTOM_API_HOST')
        const customWsHost = configManager.getValue('SENDBIRD_CUSTOM_WS_HOST')

        const options: any = {}

        if (thumbnail) {
            options.imageThumbnailSize = thumbnail.size
        }

        options.updateReadReceipt = updateReadReceipt

        return new SendbirdProviderV4(
            composeSendbirdProviderOptions({
                appId,
                userId,
                recipientId,
                accessToken: sessionToken,
                channelUrl,
                options,
                customApiHost,
                customWsHost
            })
        )
    },
    async [ActionTypes.ChatMoveConversationToFolder](
        state,
        { category, id, providerId, hostId, resolvePatientRequests }
    ) {
        return PatientsConversationsService.moveConversationToFolder(
            category,
            id,
            providerId,
            hostId,
            resolvePatientRequests
        )
    },
    async [ActionTypes.FetchConversationByPatientRecipient]({ commit }, { id }) {
        const conversation = await PatientsConversationsService.getConversationByPatientId(id)

        commit(MutationTypes.SetPatientHasUnread, {
            patientId: id,
            hasUnread: conversation?.unreadMessageCount > 0
        })

        return conversation
    },
    [ActionTypes.FetchConversationByPatientToken](state, { token }) {
        return VideoConsultationService.getConversationByPatientToken(token)
    },
    async [ActionTypes.FetchCurrentUserSessionToken]({ commit }) {
        const sessionToken = await ConversationsService.getUserSessionToken()
        commit(MutationTypes.UpdateCurrentUserSessionToken, sessionToken)
    },
    async [ActionTypes.FetchGlobalMessageBlockStatus]({ commit }, { docplannerDoctorId }) {
        const { canReplyToChat } = await MessagesService.getBlockPatientChat(docplannerDoctorId)
        const shouldActivateBlock = !canReplyToChat
        commit(MutationTypes.UpdateStatusGlobalMessageBlock, shouldActivateBlock)
    },
    async [ActionTypes.FetchPatientSessionToken]({ commit }, { token }) {
        const sessionToken = await VideoConsultationService.getPatientSessionToken(token)
        commit(MutationTypes.UpdateCurrentUserSessionToken, sessionToken)
    },
    async [ActionTypes.FormatQuickReply](state, { template, userId, patient }) {
        let message = template.text.replace('{{PatientName}}', patient.fullName)

        if (template.type === QUICK_REPLIES_TYPES.TEMPLATE_FUTURE_EVENT) {
            const nextEvent = await PatientService.getEventBasicInfo(patient.id, patient.futureEventId)

            if (nextEvent) {
                message = message
                    .replace('{{Date}}', formatDate(nextEvent.startDateTime, DateFormat.DaySMonthFormat))
                    .replace('{{Hour}}', formatDate(nextEvent.startDateTime, DateFormat.HourMinuteFormat))
                    .replace(TEMPLATE_EVENT_DATA.address, nextEvent.appoinmentAddress)
                    .replace(TEMPLATE_EVENT_DATA.confirmationUrl, nextEvent.confirmationUrl)
            }
        }

        if (template.type === QUICK_REPLIES_TYPES.TEMPLATE_ONLINE_BOOKING) {
            const doctorUrlUtm = {
                source: 'website_desktop',
                medium: 'chat',
                campaign: 'invitation_for_user_booking'
            }

            const doctorUrl = await ProfileService.doctorUrl(userId, doctorUrlUtm)
            if (doctorUrl) {
                message = message.replace(TEMPLATE_EVENT_DATA.profileUrl, doctorUrl)
            }
        }

        return message
    },
    async [ActionTypes.AddConversationToUnread](
        { commit, state },
        { chatType, unreadConversation: { conversationId, providerConversationId, sender } }
    ) {
        const isAlreadyUnread = (state.unreadConversations[chatType] as UnreadConversation[]).some(
            unreadConversation => unreadConversation.conversationId === conversationId
        )

        if (isAlreadyUnread) {
            const unreadConversations = (state.unreadConversations[chatType] as UnreadConversation[]).map(
                conversation => {
                    const isAddedConversation = conversation.conversationId === conversationId
                    if (isAddedConversation) {
                        return {
                            ...conversation,
                            count: conversation.count! + 1,
                            date: new Date(),
                            sender
                        }
                    }
                    return conversation
                }
            )
            commit(MutationTypes.UpdateUnreadConversations, {
                chatType,
                unreadConversations
            })
            return
        }

        const newUnreadConversation = {
            conversationId,
            providerConversationId,
            count: 1,
            date: new Date(),
            sender
        }
        commit(MutationTypes.UpdateUnreadConversations, {
            chatType,
            unreadConversations: [...state.unreadConversations[chatType], newUnreadConversation]
        })
    },
    async [ActionTypes.RemoveConversationsFromUnread]({ commit, state }, { chatType, providerConversationId }) {
        const unreadConversations = (state.unreadConversations[chatType] as UnreadConversation[]).filter(
            conversation => conversation.providerConversationId !== providerConversationId
        )
        commit(MutationTypes.UpdateUnreadConversations, {
            chatType,
            unreadConversations
        })
    },
    async [ActionTypes.FetchUnreadPatientConversations](
        { commit, rootState },
        { hostId, skipIfRecentlyTriggered = false }
    ) {
        // do not fetch unread conversations before getting session tokens for chat
        if (rootState[MODULE_CHAT_SESSION].isChatSessionLoading) {
            return
        }

        // we don't want to load this endpoint every time the user opens the chat page
        const lastFetchedTimestamp = Storage.get(CHAT_LAST_FETCHED_UNREAD_CONVERSATIONS_TIMESTAMP)
        if (skipIfRecentlyTriggered && lastFetchedTimestamp) {
            const diff = new Date().getTime() - new Date(lastFetchedTimestamp).getTime()
            const minutes = Math.floor(diff / 1000 / 60)
            if (minutes <= CHAT_FETCH_UNREAD_CONVERSATION_INTERVAL_IN_MINS) {
                return
            }
        }
        Storage.set(CHAT_LAST_FETCHED_UNREAD_CONVERSATIONS_TIMESTAMP, new Date())

        const { counters } = await ConversationsV2Service.getNumberOfUnreadConversations([ChatType.PatientChat], hostId)
        commit(MutationTypes.UpdateUnreadConversations, {
            chatType: ChatType.PatientChat,
            unreadConversations: counters[ChatType.PatientChat]
        })
    },
    async [ActionTypes.FetchUnreadSaasInternalConversations]({ commit, rootState }) {
        // do not fetch unread conversations before getting session tokens for chat
        if (rootState[MODULE_CHAT_SESSION].isChatSessionLoading) {
            return
        }
        // todo handle here also patient chat once endpoint is ready
        const { saasInternalChat: unreadConversations } = await InternalChatService.getUnreadConversations()
        commit(MutationTypes.UpdateUnreadConversations, {
            chatType: ChatType.SaasInternalChat,
            unreadConversations: unreadConversations.map(conversation => ({
                ...conversation,
                count: 1
            }))
        })
    },
    async [ActionTypes.AcceptSaasInternalChatDisclaimer]({ commit }) {
        const saasInternalDisclaimer = Storage.get(SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE) || false
        const wasAlreadyAccepted = Boolean(saasInternalDisclaimer)

        if (!wasAlreadyAccepted) {
            Storage.set(SAAS_INTERNAL_CHAT_DISCLAIMER_STORAGE, 'true')
        }

        commit(MutationTypes.SetSaasInternalChatDisclaimer, true)
    },
    async [ActionTypes.FetchIsContactFromProfileAllowed]({ commit }) {
        const isContactFromProfileAllowed = await MessagesService.getProfileChatEnable()

        commit(MutationTypes.SetIsContactFromProfileAllowed, isContactFromProfileAllowed)
    },
    async [ActionTypes.UpdateIsContactFromProfileAllowed]({ commit }, { isAllowed }) {
        const isContactFromProfileAllowed = await MessagesService.updateProfileChatEnable(isAllowed)

        commit(MutationTypes.SetIsContactFromProfileAllowed, isContactFromProfileAllowed)
    },
    async [ActionTypes.FetchChatTemplates]({ commit, state }) {
        const templatesFetchNeeded = state.chatTemplates === null
        if (templatesFetchNeeded) {
            // initialize chat template, prevents duplicate calls
            commit(MutationTypes.SetChatTemplates, [])
        }

        const chatTemplates = await MessagesService.getTemplates()

        commit(MutationTypes.SetChatTemplates, chatTemplates)
    },
    async [ActionTypes.UpdateStatusGlobalMessageBlock]({ commit }, { doctorId, shouldActivateBlock }) {
        await MessagesService.updateBlockPatientChat(doctorId, !shouldActivateBlock)
        commit(MutationTypes.UpdateStatusGlobalMessageBlock, shouldActivateBlock)
    }
}
