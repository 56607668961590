export const CUSTOM_TYPE = '0'
export const QUICK_REMINDER = '1'
export const AUTOMATIC_MESSAGES = '2'
export const WELCOME_MESSAGES = '3'

// TEMPLATES
export const ATTENDANCE_CONFIRMATION_TEMPLATE = 'CONFIRM_ATTENDANCE'
export const APPOINTMENT_RESCHEDULATION_TEMPLATE = 'RESCHEDULED_BOOKING'
export const APPOINTMENT_CANCELATION_TEMPLATE = 'CANCELLED_BOOKING'
export const APPOINTMENT_BOOKING_TEMPLATE = 'REQUEST_BOOKING'
export const BOOKING_CONFIRMATION_TEMPLATE = 'CREATED_BOOKING'
export const BOOKING_CONFIRMATION_TEMPLATE_WITH_CHECK_IN = 'CREATED_BOOKING_WITH_CHECK_IN'
export const CHECKIN_SURVEY_TEMPLATE = 'REQUEST_CHECKIN'
export const BOOKING_SERVICE_FILES = 'BOOKING_SERVICE_FILES'
export const PATIENT_WELCOME_MESSAGE = 'PATIENT_WELCOME_MESSAGE'
export const PATIENT_CHECKIN_FINISHED = 'PATIENT_CHECKIN_FINISHED'

export const TEMPLATES_CONTENT = {
    [ATTENDANCE_CONFIRMATION_TEMPLATE]: {
        title: 'messages-settings-page-automatic-messages-attendance-confirmation',
        description: 'messages-settings-page-automatic-messages-attendance-confirmation-descr',
        automaticDescription: 'messages-settings-page-automatic-messages-appointment-reminder-descr',
        component: 'AttendanceConfirmation'
    },
    [APPOINTMENT_RESCHEDULATION_TEMPLATE]: {
        title: 'messages-settings-page-automatic-messages-rescheduled-appointment',
        description: 'messages-settings-page-automatic-messages-rescheduled-appointment-desc',
        automaticDescription: 'messages-settings-page-automatic-messages-rescheduled-appointment-desc',
        component: 'AppointmentReschedulation'
    },
    [APPOINTMENT_CANCELATION_TEMPLATE]: {
        title: 'messages-settings-page-automatic-messages-cancelled-appointment',
        description: 'messages-settings-page-automatic-messages-cancelled-appointment-descr',
        component: 'AppointmentCancelation'
    },
    [APPOINTMENT_BOOKING_TEMPLATE]: {
        preview: 'BookNextAppointment',
        title: 'mobile-send-message-templates-next-time-book-online-title',
        description: 'mobile-send-message-templates-suggest-another-visit-descr',
        component: 'AppointmentBooking'
    },
    [BOOKING_CONFIRMATION_TEMPLATE]: {
        icon: 'DpIconCalendarWithCheck',
        title: 'messages-settings-page-automatic-messages-booking-confirmation',
        description: 'messages-settings-page-automatic-messages-booking-confirmation-descr',
        automaticDescription: 'messages-settings-page-automatic-messages-booking-confirmation-descr',
        component: 'AttendanceConfirmation'
    },
    [BOOKING_CONFIRMATION_TEMPLATE_WITH_CHECK_IN]: {
        icon: 'DpIconCalendarWithCheck',
        title: 'messages-settings-page-automatic-messages-pre-checkin',
        description: 'messages-settings-page-automatic-messages-pre-checkin-descr',
        component: 'BookingConfirmationWithCheckIn'
    },
    [CHECKIN_SURVEY_TEMPLATE]: {
        title: 'messages-settings-page-automatic-messages-check-in-request',
        description: 'messages-settings-page-automatic-messages-check-in-request-descr',
        component: 'CheckinSurvey'
    },
    [BOOKING_SERVICE_FILES]: {
        title: 'messages-settings-page-automatic-messages-appointment-confirmation-with-document',
        description: 'messages-settings-page-automatic-messages-appointment-confirmation-with-document-descr',
        component: 'BookingServiceFiles',
        customUrl: 'documentation-templates-list',
        customLinkTranslationKey:
            'messages-settings-page-automatic-messages-appointment-confirmation-with-document-hiperlink'
    },
    [PATIENT_WELCOME_MESSAGE]: {
        title: 'communications-to-patients-on-off-notifications-messages-settings-header',
        description: 'communications-to-patients-on-off-notifications-messages-settings-descr',
        component: 'PatientWelcome'
    }
}
