import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'
import hasAccessToDoctorInvitation from '@/features/doctorInvitation/ui/middleware/hasAccessToDoctorInvitation'
import { DOCTOR_INVITATION_ROUTE_NAME } from '@/features/doctorInvitation/ui/router/doctorInvitation.router.constants'

const DoctorInvitation = () =>
    import(/* webpackChunkName: "doctor-invitation" */ '@/features/doctorInvitation/ui/views/DoctorInvitation.vue')

export default {
    path: 'doctor-invitation/:invitationId',
    name: DOCTOR_INVITATION_ROUTE_NAME,
    component: DoctorInvitation,
    props: true,
    meta: {
        middleware: [
            hasAccessToDoctorInvitation,
            featureToggleMiddleware({
                key: 'clinicDoctorInvite',
                value: 'true',
                fallbackRouteName: ROUTE_CALENDAR_BASE_NAME
            })
        ]
    }
}
