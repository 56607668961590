<template>
    <Transition name="fade" @after-leave="emitAfterLeave">
        <div v-if="show" class="screen-overlay-loader text-center">
            <div v-if="isUserAuthenticated()">
                <div id="dp-u-menu">
                    <div class="dp-u-menu__placeholder">
                        <div class="dp-u-menu">
                            <div class="dp-u-menu__placeholder-container">
                                <div
                                    class="dp-u-menu__placeholder-menu placeholder placeholder-avatar placeholder-animated"
                                />
                            </div>
                            <div class="dp-u-menu__placeholder-container">
                                <div
                                    class="dp-u-menu__placeholder-menu placeholder placeholder-avatar placeholder-animated"
                                />
                            </div>
                            <div class="dp-u-menu__placeholder-container">
                                <div
                                    class="dp-u-menu__placeholder-menu placeholder placeholder-avatar placeholder-animated"
                                />
                            </div>
                            <div class="dp-u-menu__placeholder-container">
                                <div
                                    class="dp-u-menu__placeholder-menu placeholder placeholder-avatar placeholder-animated"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DpIconDocplanner class="mt-9" :size="64" color="primary" />
            <br />
            <div v-if="isUserAuthenticated()" class="row">
                <div class="col-2 offset-5">
                    <div class="col-10 offset-1">
                        <DpProgress :min="0" :max="100" :value="loadingProgress" size="sm" class="mt-2" />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
    import { DpIconDocplanner } from '@dp-ui-kit/icons'
    import { DpProgress } from '@dp-ui-kit/vue'

    import { isAuthenticated } from '@/features/auth/domain/utils/token.utils'

    export default {
        name: 'ScreenOverlayLoader',

        components: {
            DpIconDocplanner,
            DpProgress
        },

        props: {
            show: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        computed: {
            loadingProgress() {
                return this.$root.loadingProgress
            }
        },

        methods: {
            emitAfterLeave() {
                this.$emit('after-leave')
            },
            isUserAuthenticated() {
                return isAuthenticated()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@dp-ui-kit/scss/src/bootstrap/variables';
    @import '~@dp-ui-kit/scss/src/bootstrap/mixins';

    .screen-overlay-loader {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba($gray-300, 0.2);
        z-index: 9999;
    }
</style>
