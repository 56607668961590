import EventBus from '@/core/ui/libs/eventBus'

export default function uninav({ from, to, next, router, store }) {
    if (to?.name === from?.name) {
        next()
        return
    }

    const nearestRouteWithMetaUninavName = to.matched.find(item => {
        return item.meta && item.meta.uninavName
    })
    EventBus.emit('uninav', nearestRouteWithMetaUninavName.meta.uninavName)
    next()
}
