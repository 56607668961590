import { IAppointmentPaymentMethod } from '@/features/calendar/domain/interfaces/appointment.interfaces'
import { SecretaryPaymentMethod } from '@/features/calendar/domain/interfaces/secretaryView.interfaces'

export const getPaymentMethodCellData = (
    { id, name = undefined }: Pick<IAppointmentPaymentMethod, 'id' | 'name'>,
    isEditable = true
): SecretaryPaymentMethod => ({
    filterValue: id,
    displayValue: name || '',
    isEditable
})
