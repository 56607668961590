import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import RoomsRepository from '@/features/rooms/api/rooms.api'
import {
    FacilityRoomDTO,
    FacilityRoomEntity,
    FacilityRoomsCheckNameQueryParams,
    FacilityRoomsWithAvailabilityQueryParams
} from '@/features/rooms/domain/interfaces/rooms.interface'
import { addRoomPrefixToId, isRoomResource } from '@/features/rooms/domain/utils/roomProperty.utils'

const roomMapping = (room: FacilityRoomDTO, addPrefixToId: boolean): FacilityRoomEntity => ({
    ...room,
    id: addPrefixToId ? addRoomPrefixToId(room.id) : room.id,
    selected: false,
    isRoom: true,
    hidden: false,
    title: room.name
})

class RoomsService extends CalendarFilter<FacilityRoomEntity> {
    async getRooms({ addPrefixToId } = { addPrefixToId: true }): Promise<FacilityRoomEntity[]> {
        const roomDTOs = await RoomsRepository.getRooms()
        return roomDTOs.map(room => roomMapping(room, addPrefixToId))
    }

    async getRoomsWithAvailability(
        queryParams: FacilityRoomsWithAvailabilityQueryParams
    ): Promise<FacilityRoomEntity[]> {
        const roomDTOs = await RoomsRepository.getRoomsWithAvailability(queryParams)
        return roomDTOs.map(room => roomMapping(room, false))
    }

    checkName(queryParams: FacilityRoomsCheckNameQueryParams): Promise<boolean> {
        return RoomsRepository.checkName(queryParams)
    }

    createRoom(room: FacilityRoomEntity): Promise<number> {
        return RoomsRepository.createRoom(room)
    }

    updateRoom(room: FacilityRoomEntity): Promise<void> {
        return RoomsRepository.updateRoom(room)
    }

    deleteRoom(room: FacilityRoomEntity): Promise<void> {
        return RoomsRepository.deleteRoom(room)
    }

    filterRoomsByProperties(rooms: FacilityRoomEntity[], properties: FacilityRoomEntity): FacilityRoomEntity[] {
        const regExp = new RegExp(properties.name, 'i')
        const facilityRooms = rooms.filter(el => {
            return (
                (properties.facilityId ? el.facilityId === properties.facilityId : true) &&
                (properties.name?.length ? el.name.match(regExp) : true)
            )
        })

        return this.orderListBySelectedAndName(facilityRooms)
    }

    isRoomEvent(event: any) {
        const { resourceId } = event

        if (!resourceId) {
            return false
        }

        return isRoomResource(resourceId)
    }
}

export default new RoomsService()
