export enum UserType {
    Doctor = 'doctor',
    FakeSecretary = 'fakesecretary',
    MedicalCenter = 'medicalcenter',
    Secretary = 'secretary',
    SecretaryMedicalCenter = 'secretarymedicalcenter'
}

export enum ClinicSize {
    From0To10 = '0-10',
    From10To15 = '10-15',
    From15To20 = '15-20',
    From20To30 = '20-30',
    From30To50 = '30-50',
    From50 = '50+'
}

export enum DoctorType {
    ClinicDoctor = 'clinicDoctor',
    IndividualDoctor = 'individualDoctor'
}
