import { ProfileEntity } from '@/features/auth/domain/interfaces/profile.interfaces'

/* eslint-disable no-underscore-dangle */
export const initialize = (user: ProfileEntity) => {
    if (typeof window._gscq !== 'object' || !user) return
    const manager = window._gscq
    manager.push(['user', 'id', user.id])
    manager.push(['targeting', 'specialityName', user.specialityName])
    manager.push(['targeting', 'userType', user.userType])
}
/* eslint-enable no-underscore-dangle */
