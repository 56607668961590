export enum PreviousVisitsColumnKeys {
    Date = 'date',
    Patient = 'patient',
    Price = 'price',
    Schedule = 'schedule',
    Service = 'serviceDisplayName',
    Status = 'paymentStatus'
}

export enum SettingsColumnKeys {
    Actions = 'actions',
    Address = 'address',
    Name = 'displayName',
    Status = 'isOnlinePaymentEnabled'
}

export enum PaymentsColumnKeys {
    Actions = 'actions',
    Amount = 'amount',
    CreationDate = 'creationDate',
    Description = 'description',
    Patient = 'patient',
    Status = 'paymentStatus',
    Type = 'type'
}
