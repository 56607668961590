import repository from '@/features/doctorInvitation/api/doctorInvitation.api'
import { DoctorInvitationInterface } from '@/features/doctorInvitation/domain/doctorInvitation.interface'
import { DoctorAddress } from '@/features/settings/domain/interfaces/addresses.interfaces'

class DoctorInvitationService {
    createDoctorInvitations(emails: string[], addressIds: string[]): Promise<void> {
        return repository.createDoctorInvitations(emails, addressIds)
    }

    async removeDoctorInvitation(invitationId: string): Promise<void> {
        await repository.removeDoctorInvitation(invitationId)
    }

    resendDoctorInvitation(invitationId: string): Promise<void> {
        return repository.resendDoctorInvitation(invitationId)
    }

    getDoctorInvitation(doctorInvitationId: string): Promise<DoctorInvitationInterface> {
        return repository.getDoctorInvitation(doctorInvitationId)
    }

    acceptDoctorInvitation(invitationId: string, docplannerDoctorAddressId: string | null = null): Promise<void> {
        return repository.acceptDoctorInvitation(invitationId, docplannerDoctorAddressId)
    }

    getSpecialistList(): Promise<any> {
        return repository.getSpecialistList()
    }

    async getDoctorInvitationAddresses(invitationId: string): Promise<DoctorAddress[]> {
        const addresses = (await repository.getDoctorInvitationAddresses(invitationId)) || []
        return addresses.map(address => {
            const { cityName, name, street } = address
            const label = [name, street, cityName].filter(value => !!value).join(', ')

            return {
                ...address,
                label
            }
        })
    }
}

export default new DoctorInvitationService()
