import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { ChatType, PatientChatCategory } from '../domain/enums/chat.enums'
import {
    ConversationsUnreadCounterResponse,
    PatientConversationList,
    PatientConversationListEntry
} from '../domain/interfaces/conversations.interfaces'

const URL_BASE = 'v2/chat'
const URL_BASE_PATIENT = `${URL_BASE}/patients`

const GRUPPO_URL_BASE = `${URL_BASE}/gruppo`

const URL_CONVERSATIONS = `${URL_BASE_PATIENT}/conversations`
const URL_CONVERSATION_LIST_ELEMENT = `${URL_BASE_PATIENT}/conversations/provider-id/:providerId`
const URL_CONVERSATIONS_UNREAD_COUNT = `${URL_BASE}/users/unread-counter`

const GRUPPO_URL_CONVERSATIONS = `${GRUPPO_URL_BASE}/patients/conversations`
const GRUPPO_URL_CONVERSATION_LIST_ELEMENT = `${GRUPPO_URL_BASE}/patients/conversations/provider-id/:providerId`
const GRUPPO_URL_CONVERSATIONS_UNREAD_COUNT = `${GRUPPO_URL_BASE}/patients/unread-counter`

class ConversationV2Repository {
    async getPatientConversationsList({
        category,
        limit,
        providerNextToken,
        hostUserId,
        patientRequestType
    }: {
        category: PatientChatCategory
        hostUserId?: number | null
        limit?: number
        patientRequestType?: string
        providerNextToken: string
    }): Promise<PatientConversationList> {
        const query: any = {
            category,
            limit,
            providerNextToken,
            withPatientDataOnly: 'false',
            hostUserId,
            patientRequestType
        }

        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_CONVERSATIONS : URL_CONVERSATIONS,
            query
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getPatientConversationsListElement(
        providerId: string,
        hostUserId?: number | null
    ): Promise<PatientConversationListEntry> {
        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_CONVERSATION_LIST_ELEMENT : URL_CONVERSATION_LIST_ELEMENT,
            query: {
                hostUserId: hostUserId as number
            },
            params: {
                providerId
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getNumberOfUnreadConversations(
        types: ChatType[],
        hostUserId?: number
    ): Promise<ConversationsUnreadCounterResponse> {
        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_CONVERSATIONS_UNREAD_COUNT : URL_CONVERSATIONS_UNREAD_COUNT,
            query: {
                types: types.join(','),
                hostUserId: hostUserId as number
            }
        })

        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new ConversationV2Repository()
