export default class Module {
    name

    package

    module

    inject

    constructor(name, pkg, inject) {
        this.name = name
        this.package = pkg
        this.inject = inject
    }

    async loadModule() {
        const module = await this.package()

        this.module = module.default
    }

    getName() {
        return this.name
    }

    isInjectable() {
        return this.inject
    }

    getModule() {
        if (this.module === undefined) {
            throw new Error(`Module ${this.getName()} is not loaded`)
        }

        return this.module
    }
}
