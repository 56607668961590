// TODO: just pass Sentry enum when using TS, extend this object until then.
export const SeverityLevels = {
    Error: 'error',
    Warning: 'warning',
    Info: 'info'
}

export const COMMON_INITIAL_LOAD_MARK = 'COMMON_initialLoad'
export const COMMON_ON_DATADOG_RUM_READY = 'COMMON_onDatadogRumReady'
export const COMMON_FETCH_API_CULTURE = 'COMMON_fetchApiCulture'
export const COMMON_FETCH_TRANSLATIONS = 'COMMON_fetchTranslations'
export const COMMON_FETCH_COUNTRY_SETTINGS = 'COMMON_fetchCountrySettings'
