import { COLUMNS } from '../quotes.constants'
import { QuotesState } from './types/state'

export function getDefaultState(): QuotesState {
    return {
        isLoading: true,
        quotes: [],
        headers: COLUMNS,
        currentPage: 0,
        totalPages: 0,
        filters: {}
    }
}

export const state = getDefaultState()
