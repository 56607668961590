import httpAPI from '@/api/utils/httpClientAPI'
import httpCDN from '@/api/utils/httpClientCDN'

const Culture = () => {
    const url = {
        path: 'culture',
        translationsPath: 'translations'
    }

    return {
        fetch() {
            return httpAPI.get(url.path)
        },
        fetchLanguage(lang) {
            return httpCDN.get(`${url.translationsPath}/${lang}.json`)
        }
    }
}

export default Culture
