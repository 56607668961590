export enum ActionTypes {
    ChangeFilter = 'CHANGE_FILTER',
    FetchSchedules = 'FETCH_SCHEDULES',
    FetchVoucherTypes = 'FETCH_VOUCHER_TYPES',
    FetchVoucherTypesPaginated = 'FETCH_VOUCHER_TYPES_PAGINATED',
    FetchVouchers = 'FETCH_VOUCHER',
    GoToPage = 'GO_TO_PAGE'
}

export type VouchersActions = {
    [ActionTypes.FetchVoucherTypes]: () => Promise<void>
    [ActionTypes.FetchVoucherTypesPaginated]: () => Promise<void>
    [ActionTypes.FetchVouchers]: () => Promise<void>
    [ActionTypes.GoToPage]: (payload: { action: string; page: number }) => Promise<void>
    [ActionTypes.ChangeFilter]: (payload: { showActive: boolean }) => Promise<void>
    [ActionTypes.FetchSchedules]: () => Promise<void>
}
