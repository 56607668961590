import repository from '../api/account.api'
import { QRCodeEntity } from './interfaces/account.interfaces'

class AccountService {
    create(): Promise<any> {
        return repository.create()
    }

    getQRCodeImage(): Promise<QRCodeEntity> {
        return repository.getQRCodeImage()
    }

    getAccountLogo(): Promise<any> {
        return repository.getAccountLogo()
    }

    getInvoicingIntegrationTermsAccepted(): Promise<any> {
        return repository.getInvoicingIntegrationTermsAccepted()
    }

    patchInvoicingIntegrationTermsAccepted(): Promise<any> {
        return repository.patchInvoicingIntegrationTermsAccepted()
    }

    getSoftwareVersion(): Promise<string> {
        return repository.getSoftwareVersion()
    }

    getShortDLink(): Promise<string> {
        return repository.getShortDLink()
    }
}

export default new AccountService()
