import { FILTERS_INITIAL_VALUES } from '../../constants/secretaryView.constants'
import { SecretaryViewState } from './types/state'

export function getDefaultState(): SecretaryViewState {
    return {
        headers: [],
        appointments: [],
        isTableLoading: true,
        paymentMethods: [],
        rowsSelected: [],
        filters: FILTERS_INITIAL_VALUES,
        isChatPopoverOpen: false,
        isBulkCancelationPopoverOpen: false
    }
}

export const state = getDefaultState()
