import { ActionsAdaptor } from '@/interfaces'
import { getNumberOfPages } from '@/utils/number'

import RequestsService from '../../../domain/requests.service'
import { ActionTypes, MutationTypes, RequestActions, RequestMutations, RequestState } from './types'

export const actions: ActionsAdaptor<RequestActions, RequestMutations, RequestState> = {
    async [ActionTypes.UpdateRequestCategories]({ commit, dispatch }, { docplannerDoctorId, categories }) {
        Promise.all([
            await RequestsService.updateRequestCategories(docplannerDoctorId, categories),
            await dispatch(ActionTypes.FetchRequestsIntegration, { docplannerDoctorId })
        ])
    },
    async [ActionTypes.FetchRequestsIntegration]({ commit }, { docplannerDoctorId }) {
        try {
            const requestsIntegration = await RequestsService.getRequestsIntegration(docplannerDoctorId)
            commit(MutationTypes.UpdateRequestsIntegration, requestsIntegration)
        } catch (error) {
            commit(MutationTypes.UpdateRequestsIntegration, {
                isEnabled: false,
                categories: [],
                channel: null,
                allowRequestsFromNewPatient: true
            })
            throw new Error(error as string)
        }
    },
    async [ActionTypes.FetchIsRequestFeatureEnabled]({ commit }, { doctorId, userId }) {
        const isPrescriptionFeatureEnabled = await RequestsService.getProfilePrescriptionRequestEnabled(
            doctorId,
            userId
        )
        commit(MutationTypes.UpdateIsRequestFeatureEnabled, isPrescriptionFeatureEnabled)
    },
    async [ActionTypes.FetchRequests]({ commit }, { pageNumber, filterRequestType, doctorId, userId, isAscending }) {
        const requests = await RequestsService.getRequestList(
            pageNumber,
            filterRequestType,
            doctorId,
            undefined,
            userId,
            isAscending
        )
        const pages = getNumberOfPages(requests.total, requests.pageSize)
        commit(MutationTypes.Pages, pages)
        commit(MutationTypes.UpdateRequestList, requests.page)
    },
    async [ActionTypes.FetchUnreadRequestCount]({ commit }, { doctorId, userId }) {
        const unreadCount = await RequestsService.countUnreadRequests(doctorId, userId)
        commit(MutationTypes.UpdateUnreadRequestCount, unreadCount)
    },
    async [ActionTypes.FilteredRequests](
        { commit, dispatch },
        { pageNumber, filterRequestType, typeIds, doctorId, userId, isAscending }
    ) {
        const filteredRequests = await RequestsService.getRequestList(
            pageNumber,
            filterRequestType,
            doctorId,
            typeIds,
            userId,
            isAscending
        )

        await dispatch(ActionTypes.FetchRequests, { pageNumber, filterRequestType, doctorId, userId })
        const pages = getNumberOfPages(filteredRequests.total, filteredRequests.pageSize)
        commit(MutationTypes.Pages, pages)

        const requests = filteredRequests.page.map(request => {
            return {
                ...request,
                wasRead: !!request.requestReadDate
            }
        })
        return requests
    },
    async [ActionTypes.MarkAsRead]({ dispatch }, { requestId, doctorId, userId }) {
        Promise.all([
            await RequestsService.markAsRead(requestId, doctorId, userId),
            await dispatch(ActionTypes.FetchUnreadRequestCount, { doctorId, userId })
        ])
    },
    async [ActionTypes.RequestMoveTo]({ commit, state }) {
        commit(MutationTypes.UpdateRequestList, state.requests)
    },
    async [ActionTypes.UpdateIsRequestFeatureEnabled]({ commit }, { isEnabled, doctorId }) {
        const isPrescriptionFeatureEnabled = await RequestsService.updateProfilePrescriptionRequestEnabled(
            isEnabled,
            doctorId
        )
        commit(MutationTypes.UpdateIsRequestFeatureEnabled, isPrescriptionFeatureEnabled)
    },
    async [ActionTypes.UpdateAllowRequestsFromNewPatients](
        { commit },
        { docplannerDoctorId, allowRequestsFromNewPatient, isRequestFeatureEnabled }
    ) {
        await RequestsService.updateAllowRequestsFromNewPatients(
            docplannerDoctorId,
            allowRequestsFromNewPatient,
            isRequestFeatureEnabled
        )
        commit(MutationTypes.UpdateAllowRequestsFromNewPatients, allowRequestsFromNewPatient)
    }
}
