interface SetupGuideTranslationPageHome {
    descriptionCompleted: string
    descriptionStart: string
    header: string
}

interface SetupGuideTranslationPageComingSoon {
    header: string
}

const TRANSLATION_PAGE_HOME: SetupGuideTranslationPageHome = {
    header: 'saas-setup-guide-header',
    descriptionStart: 'saas-setup-guide-subheader-start',
    descriptionCompleted: 'saas-setup-guide-completed-descr'
}

const TRANSLATION_PAGE_COMING_SOON: SetupGuideTranslationPageComingSoon = {
    header: 'saas-setup-guide-header'
}

export const getTranslationPageHome = (): SetupGuideTranslationPageHome => {
    return TRANSLATION_PAGE_HOME
}

export const getTranslationPageComingSoon = (): SetupGuideTranslationPageComingSoon => {
    return TRANSLATION_PAGE_COMING_SOON
}
