import { EHR_SETTINGS_ROUTE_NAMES } from '@/features/settings/ui/constants/ehr/routes.constants'

const EhrSettings = () =>
    import(/* webpackChunkName: "ehr-settings" */ '@/features/settings/ui/views/ehr/EhrSettings.vue')
const EhrTemplateList = () =>
    import(
        /* webpackChunkName: "ehr-settings-templates" */ '@/features/settings/ui/components/ehr/templates/list/EhrTemplateList.vue'
    )
const EhrTemplate = () =>
    import(/* webpackChunkName: "ehr-settings-template" */ '@/features/settings/ui/views/ehr/EhrTemplate.vue')
const EhrEpisodeTemplateFields = () =>
    import(
        /* webpackChunkName: "ehr-settings-episode-template-fields" */ '@/features/settings/ui/views/ehr/EhrEpisodeTemplateFields.vue'
    )

export default [
    {
        path: 'settings-ehr',
        name: 'settings-ehr',
        redirect: { name: EHR_SETTINGS_ROUTE_NAMES.ehrTemplateList },
        component: EhrSettings,
        children: [
            {
                path: 'templates',
                name: EHR_SETTINGS_ROUTE_NAMES.ehrTemplateList,
                component: EhrTemplateList
            },
            {
                path: 'episode-fields',
                name: EHR_SETTINGS_ROUTE_NAMES.ehrEpisodeTemplateFields,
                component: EhrEpisodeTemplateFields
            }
        ]
    },
    {
        path: 'settings-ehr/templates/create',
        name: EHR_SETTINGS_ROUTE_NAMES.ehrTemplateCreate,
        component: EhrTemplate
    },
    {
        path: 'settings-ehr/templates/edit/:templateId',
        name: EHR_SETTINGS_ROUTE_NAMES.ehrTemplateEdit,
        component: EhrTemplate
    }
]
