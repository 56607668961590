import httpAPI from '@/api/utils/httpClientAPI'

const Facility = () => {
    const url = {
        getFacilities: 'facilities'
    }

    return {
        getFacilities() {
            return httpAPI.get(url.getFacilities)
        }
    }
}

export default Facility
