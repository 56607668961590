export enum CampaignsTypeId {
    Sms = 1,
    Email
}

export enum CampaignsTypeName {
    Email = 'email',
    Sms = 'sms'
}

export enum CampaignsProperty {
    AppointmentBooked = 'appointmentBooked',
    CampaignType = 'campaignType',
    Clicked = 'clicked',
    Delivered = 'delivered',
    HasOtherBookingNotFromCampaign = 'hasOtherBookingNotFromCampaign',
    Opened = 'opened',
    Recipients = 'recipients',
    SendDate = 'sendDate',
    Title = 'title'
}

export enum CampaignsTrackAction {
    AddRecipient = 'addRecipient',
    AllRecipients = 'allRecipients',
    Back = 'back',
    Continue = 'continue',
    RemoveRecipient = 'removeRecipient',
    Send = 'send',
    Unknown = 'unknown'
}
