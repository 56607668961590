import repository from '../api/plugins.api'
import { AvailablePlugin, PluginsCode } from './interfaces/plugins.interfaces'

class PluginsService {
    getPluginsCode(): Promise<PluginsCode> {
        return repository.getPluginsCode()
    }

    getPluginsPath(): Promise<string> {
        return repository.getPluginsPath()
    }

    getActivatePluginUrl(id: number, redirectUrl: string): Promise<string> {
        return repository.getActivatePluginUrl(id, redirectUrl)
    }

    getPluginStatus({ pluginId }: { pluginId: string }): Promise<string> {
        return repository.getPluginStatus({ pluginId })
    }

    availablePlugins(): Promise<AvailablePlugin[]> {
        return repository.availablePlugins()
    }
}

export default new PluginsService()
