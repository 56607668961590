import httpAPI from '@/api/utils/httpClientAPI'

const Services = function () {
    const url = {
        getServices: 'services',
        getOwnedServices: 'services/owned',
        updateServiceColor: (id, colorSchemaId) => `services/${id}/color/${colorSchemaId}`,
        getBaseServices: 'services/base'
    }

    return {
        getServices() {
            return httpAPI.get(url.getServices)
        },
        getOwnedServices() {
            return httpAPI.get(url.getOwnedServices)
        },
        getService(id) {
            return httpAPI.get(`${url.getServices}/${id}`)
        },
        updateService(id, serviceObject) {
            return httpAPI.put(`${url.getServices}/${id}`, serviceObject)
        },
        deleteService(id) {
            return httpAPI.delete(`${url.getServices}/${id}`)
        },
        async updateServiceColor(id, colorSchemaId) {
            return httpAPI.patch(url.updateServiceColor(id, colorSchemaId))
        },
        getBaseServices(query) {
            return httpAPI.get(`${url.getBaseServices}?filter=${query}`)
        },
        createService(service) {
            return httpAPI.post(url.getServices, service)
        }
    }
}

export default Services
