import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import httpClientDWH from '@/api/utils/httpClientDWH'
import { PatientCanReplyToChat } from '@/features/chat/domain/interfaces/conversations.interfaces'
import { ReplyTime } from '@/features/settings/domain/interfaces/messages.interfaces'
import { OutOfOfficeEvent } from '@/features/settings/ui/interfaces/disableNotifications.interfaces'
import { MultipleSchedule, Schedule } from '@/features/settings/ui/interfaces/doNotDisturbContent.interfaces'

import { PERIODIC_SETTING } from './constants/messages.contants'
import { DoNotDisturbDTO, OutOfOfficeEventDTO } from './interfaces/messages.DTO'

const URL_BASE_PROFILE = 'profile'
const URL_BASE_CHAT = 'chat'
const URL_BASE_CHAT_DOCTOR_STATS = 'chat-doctor-stats'

const URL_PROFILE_CHAT_ENABLED = `${URL_BASE_PROFILE}/chat-enabled`

const URL_CHAT_USER_TEMPLATES = `${URL_BASE_CHAT}/user-templates`
const URL_CHAT_USER_TEMPLATES_ID = `${URL_CHAT_USER_TEMPLATES}/:id`
const URL_MESSAGE_NOTIFICATIONS = `${URL_BASE_CHAT}/doctorOutOfOffice`

const URL_SCHEDULE_MESSAGE_NOTIFICATIONS = 'settingsperiodic'
const URL_SCHEDULE_ALWAYS_ON = `${URL_SCHEDULE_MESSAGE_NOTIFICATIONS}/alwayson`

const URL_REPLY_TIME = `/${URL_BASE_CHAT_DOCTOR_STATS}/:id`
const URL_BLOCK_PATIENT_CHAT = `patients/communicationsettings/globalcanreplytochat` as const

const URL_WELCOME_MESSAGE = 'schedules/welcomeMessage'

class MessagesRepository {
    async getProfileChatEnable(): Promise<boolean> {
        const { data } = await httpAPI.get(URL_PROFILE_CHAT_ENABLED)
        return data
    }

    async updateProfileChatEnable(isEnabled: boolean): Promise<void> {
        return httpAPI.put(URL_PROFILE_CHAT_ENABLED, { isEnabled })
    }

    async getReplyTime(id: number): Promise<ReplyTime> {
        const url = getUrlFromTemplate({
            template: URL_REPLY_TIME,
            params: { id }
        })
        const { data } = await httpClientDWH.get(url)
        return data
    }

    async getTemplates(): Promise<any> {
        const { data } = await httpAPI.get(URL_CHAT_USER_TEMPLATES)
        return data
    }

    async addTemplate(template: any) {
        const { data } = await httpAPI.post(URL_CHAT_USER_TEMPLATES, template)
        return data
    }

    async updateTemplate(id: number, template: any) {
        const url = getUrlFromTemplate({
            template: URL_CHAT_USER_TEMPLATES_ID,
            params: { id }
        })
        const { data } = await httpAPI.put(url, template)
        return data
    }

    async deleteTemplate(id: number) {
        const url = getUrlFromTemplate({
            template: URL_CHAT_USER_TEMPLATES_ID,
            params: { id }
        })
        const { data } = await httpAPI.delete(url)
        return data
    }

    // ==============================
    // HOLIDAY NOTIFICATION SCHEDULE
    // ==============================

    async getOutOfOfficeSilencedNotificationSetting(): Promise<OutOfOfficeEventDTO> {
        const { data } = await httpAPI.get(URL_MESSAGE_NOTIFICATIONS)
        return data
    }

    async createOutOfOfficeEvent(event: OutOfOfficeEvent): Promise<OutOfOfficeEventDTO> {
        const { data } = await httpAPI.post(URL_MESSAGE_NOTIFICATIONS, event)
        return data
    }

    async updateExistingOutOfOfficeEvent(event: OutOfOfficeEvent): Promise<OutOfOfficeEventDTO> {
        const { data } = await httpAPI.put(URL_MESSAGE_NOTIFICATIONS, event)
        return data
    }

    async deleteExistingOutOfOfficeEvent(id: number): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_MESSAGE_NOTIFICATIONS,
            query: { id }
        })
        const { data } = await httpAPI.delete(url)
        return data
    }

    // ====================================
    // DO_NOT_DISTURB NOTIFICATION SCHEDULE
    // ====================================

    async getNotificationSchedule(): Promise<DoNotDisturbDTO[]> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_MESSAGE_NOTIFICATIONS,
            query: { setting: PERIODIC_SETTING }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async isScheduleAlwaysOn(): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_ALWAYS_ON,
            query: { setting: PERIODIC_SETTING }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async createSchedule(schedule: Schedule | MultipleSchedule): Promise<DoNotDisturbDTO[]> {
        const { data } = await httpAPI.post(URL_SCHEDULE_MESSAGE_NOTIFICATIONS, schedule)
        return data
    }

    async deleteSchedule(): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_MESSAGE_NOTIFICATIONS,
            query: { setting: PERIODIC_SETTING }
        })

        const { data } = await httpAPI.delete(url)
        return data
    }

    async getWelcomeMessageEnabled(): Promise<boolean> {
        const { data } = await httpAPI.get(URL_WELCOME_MESSAGE)
        return data
    }

    async updateWelcomeMessageEnabled(value: boolean): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_WELCOME_MESSAGE,
            query: {
                value: value ? 'true' : 'false'
            }
        })
        const { data } = await httpAPI.patch(url)
        return data
    }

    async getBlockPatientChat(doctorId: string): Promise<PatientCanReplyToChat> {
        const url = getUrlFromTemplate({
            template: URL_BLOCK_PATIENT_CHAT,
            params: { doctorId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async updateBlockPatientChat(doctorId: string, canReplyToChat: boolean): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_BLOCK_PATIENT_CHAT,
            params: { doctorId }
        })

        const body: PatientCanReplyToChat = {
            canReplyToChat
        }

        const { data } = await httpAPI.put(url, body)
        return data
    }
}

export default new MessagesRepository()
