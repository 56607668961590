import CalendarFilter from '@/features/calendar/domain/calendar.filter.service'
import { CALENDAR_KIND } from '@/features/calendar/ui/constants/calendar.constants'
import DevicesRepository from '@/features/devices/api/devices.api'
import { Device } from '@/features/devices/domain/interfaces/devices.interface'

const deviceMapping = (device: Device) => ({
    ...device,
    id: device.id,
    selected: false,
    isDevice: true,
    kind: CALENDAR_KIND.device,
    hidden: false,
    title: device.name
})

class DevicesService extends CalendarFilter<Device> {
    async getDevices(): Promise<Device[]> {
        const deviceDTOs = await DevicesRepository.getDevices()
        return deviceDTOs.map(deviceMapping)
    }

    filterDevicesByProperties(devices: Device[], properties: Device): Device[] {
        const regExp = new RegExp(properties.name, 'i')
        const facilityDevices = devices.filter(el => {
            return (
                (properties.facilityId ? el.facilityId === properties.facilityId : true) &&
                (properties.name?.length ? el.name.match(regExp) : true)
            )
        })

        return this.orderListBySelectedAndName(facilityDevices)
    }
}

export default new DevicesService()
