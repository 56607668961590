import { StatusColors } from '@/features/calendar/ui/enums/secretaryView.enums'

import { HeaderNames, QuoteStatus } from '../domain/quotes.enum'
import { ActionsCommands, ActionsTranslationsKeys, StatusTranslationKeys } from './enums/quotes.enums'
import { QuoteAction, QuoteColumns, StatusMap } from './interfaces/quotesList.interfaces'

export const COLUMNS: QuoteColumns = [
    {
        property: HeaderNames.Number,
        name: 'quotes-feature-listing-number'
    },
    {
        property: HeaderNames.Address,
        name: 'quotes-feature-listing-entity'
    },
    {
        property: HeaderNames.CreatedAt,
        name: 'quotes-feature-listing-issue-date'
    },
    {
        property: HeaderNames.PatientName,
        name: 'quotes-feature-listing-number-patient-name'
    },
    {
        property: HeaderNames.Total,
        name: 'quotes-feature-listing-amount'
    },
    {
        property: HeaderNames.Status,
        name: 'quotes-feature-listing-status'
    }
]

export const APPROVAL_STATUS_MAP: StatusMap[] = [
    {
        id: QuoteStatus.Rejected,
        translationKey: StatusTranslationKeys.Rejected,
        color: StatusColors.Danger
    },
    {
        id: QuoteStatus.Accepted,
        translationKey: StatusTranslationKeys.Accepted,
        color: StatusColors.Success
    },
    {
        id: QuoteStatus.Pending,
        translationKey: StatusTranslationKeys.Pending,
        color: StatusColors.Gray300
    }
]

export const QUOTE_ROW_ACTIONS: QuoteAction[] = [
    {
        translationKey: ActionsTranslationsKeys.DownloadPDF,
        command: ActionsCommands.DownloadPDF
    },
    {
        translationKey: ActionsTranslationsKeys.SendByChat,
        command: ActionsCommands.SendByChat
    },
    {
        translationKey: ActionsTranslationsKeys.SendByEmail,
        command: ActionsCommands.SendByEmail
    },
    {
        translationKey: ActionsTranslationsKeys.Edit,
        command: ActionsCommands.Edit
    },
    {
        translationKey: ActionsTranslationsKeys.Duplicate,
        command: ActionsCommands.Duplicate
    },
    {
        translationKey: ActionsTranslationsKeys.Delete,
        command: ActionsCommands.Delete
    }
]
