import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { Expense, ExpenseCategory, ExpenseStatistics } from '../domain/interfaces/expenses.interfaces'
import { ReportsParameters } from '../domain/interfaces/reports.interfaces'

const URL_BASE = 'expenses'

const URL_EXPENSES_AGGREGATES = `${URL_BASE}/aggregates`
const URL_EXPENSES_ID = `${URL_BASE}/:expenseId`

const URL_EXPENSES_CATEGORIES = `${URL_BASE}/categories`
const URL_EXPENSES_CATEGORIES_NAME = `${URL_EXPENSES_CATEGORIES}/:categoryName`
const URL_EXPENSES_CATEGORIES_ID = `${URL_EXPENSES_CATEGORIES}/:categoryId`

class ExpensesRepository {
    async postExpense(payload: Expense): Promise<void> {
        await httpAPI.post(URL_BASE, payload)
    }

    async putExpense(expenseId: string, payload: Expense): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_EXPENSES_ID,
            params: {
                expenseId
            }
        })
        await httpAPI.put(url, payload)
    }

    async deleteExpense(expenseId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_EXPENSES_ID,
            params: {
                expenseId
            }
        })
        await httpAPI.delete(url)
    }

    async getExpensesCategories(): Promise<ExpenseCategory[]> {
        const { data } = await httpAPI.get(URL_EXPENSES_CATEGORIES)
        return data
    }

    async postExpensesCategories(categoryName: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_EXPENSES_CATEGORIES_NAME,
            params: {
                categoryName
            }
        })
        await httpAPI.post(url)
    }

    async deleteExpensesCategories(categoryId: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_EXPENSES_CATEGORIES_ID,
            params: {
                categoryId
            }
        })
        await httpAPI.delete(url)
    }

    async getExpensesAggregates(args: ReportsParameters): Promise<ExpenseStatistics> {
        const { data } = await httpAPI.post(URL_EXPENSES_AGGREGATES, args)
        return data
    }
}

export default new ExpensesRepository()
