import { getMarketplaceProfileUrl, getPatientImportFacilities, getTaskList, setTaskCompleted } from './repositories'

export type { SetupGuideTaskDTO } from './interfaces'

export const repository = {
    getMarketplaceProfileUrl,
    getTaskList,
    setTaskCompleted,
    getPatientImportFacilities
}
