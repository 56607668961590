import repository from '../api/aggregate.api'
import { CommissionsResponse, PaymentsResponse } from './interfaces/aggregate.interfaces'
import { ReportsParameters } from './interfaces/reports.interfaces'

class AggregateService {
    getPayments(args: ReportsParameters): Promise<PaymentsResponse> {
        return repository.getPayments(args)
    }

    getCommissions(args: ReportsParameters): Promise<CommissionsResponse[]> {
        return repository.getCommissions(args)
    }

    getCommissionExport(doctorId: string, args: ReportsParameters): Promise<Blob> {
        return repository.getCommissionExport(doctorId, args)
    }
}

export default new AggregateService()
