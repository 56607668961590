import httpAPI from '@/api/utils/httpClientAPI'
import { Doctor } from '@/features/doctor/domain/interfaces/doctor.interfaces'

const URL_BASE = 'centers'
const URL_DOCTORS = `${URL_BASE}/doctors`

class DoctorRepository {
    async getDoctors(): Promise<Doctor[]> {
        const { data } = await httpAPI.get(URL_DOCTORS)
        return data
    }
}

export default new DoctorRepository()
