import download from 'downloadjs'

import Schedules from '@/api/Schedules'
import repository from '@/features/admin/api/admin.api'
import { NinOptions } from '@/features/admin/domain/enums/schedule.enums'
import { Schedule, ScheduleAuditLog } from '@/features/schedule/domain/interfaces/schedule.interface'
import ScheduleService from '@/features/schedule/domain/schedule.service'
import { orderByAttribute } from '@/utils/array'
import { getFileNameFromContentDisposition } from '@/utils/http'

import {
    DeletedSchedule,
    GruppoSettings,
    HubspotInfo,
    ItemPriceRenewal,
    OwnershipMigration,
    ScheduleFeature
} from './interfaces/admin.interfaces'

const SchedulesAPI = Schedules()

class AdminService {
    syncScheduleServices(scheduleId: number): Promise<any> {
        return repository.syncScheduleServices(scheduleId)
    }

    async getImpersonatedUserSchedules(): Promise<any[]> {
        const { data } = await SchedulesAPI.getAllSchedules()
        return data
    }

    getImpersonatedUserSchedulesIncludingDeleted(): Promise<Schedule[]> {
        return ScheduleService.getSchedulesIncludingDeleted()
    }

    async getPmsSyncRollbackInfo(userId: number, countryCode: string): Promise<any> {
        const { data } = await repository.getPmsSyncRollbackInfo(userId, countryCode)
        return data
    }

    async startPmsSyncRollback(userId: number, countryCode: string): Promise<void> {
        await repository.startPmsSyncRollback(userId, countryCode)
    }

    async deletePmsSyncRollback(userId: number, countryCode: string): Promise<void> {
        await repository.deletePmsSyncRollback(userId, countryCode)
    }

    async getClinicInvitesLogs(): Promise<any> {
        const { data } = await repository.getClinicInvitesLogs()
        return data
    }

    undeletePatient(patientId: number): Promise<any> {
        return repository.undeletePatient(patientId)
    }

    async isPatientBlocked(patientUrl: string): Promise<any> {
        const isPatientBlocked = await repository.isPatientBlocked(patientUrl)
        return isPatientBlocked
    }

    parseIclinicFile(file: File): Promise<any> {
        return repository.parseIclinicFile(file)
    }

    setPatientsVisibility(hideVisibility: boolean, confirm: boolean): Promise<any> {
        return hideVisibility
            ? repository.markClinicAsHidePatientData(confirm)
            : repository.markClinicAsSharePatientData(confirm)
    }

    requestUnmaskingAccess(message: string, timeLimit: number): Promise<any> {
        return repository.requestUnmaskingAccess(message, timeLimit)
    }

    transferAccess(email: string): Promise<any> {
        return repository.transferAccess(email)
    }

    async getUnmaskingRequests(): Promise<any> {
        const { data } = await repository.getUnmaskingRequests()
        return orderByAttribute(data, 'lastModifiedAt', false)
    }

    removeAccess(id: number): Promise<any> {
        return repository.removeAccess(id)
    }

    removeAccessFromBackOfficeTool(): Promise<any> {
        return repository.removeAccessFromBackOfficeTool()
    }

    getHubspotInfo(): Promise<HubspotInfo> {
        return repository.getHubspotInfo()
    }

    updateHubspotInfo(contactId?: number, companyId?: number): Promise<any> {
        return repository.updateHubspotInfo(contactId, companyId)
    }

    getScheduleFeatures(scheduleId: number): Promise<ScheduleFeature[]> {
        return repository.getScheduleFeatures(scheduleId)
    }

    async updateScheduleFeatures(scheduleId: number, feature: ScheduleFeature) {
        await repository.updateScheduleFeatures(scheduleId, [feature])
    }

    async updateNinRequirement(scheduleId: number, ninOption: NinOptions) {
        await repository.updateNinRequirement(scheduleId, ninOption)
    }

    async activateSchedule(scheduleId: number) {
        await repository.activateSchedule(scheduleId)
    }

    async sendPatientsExport() {
        await repository.sendPatientsDataExport()
    }

    async sendAppointmentsExport() {
        await repository.sendAppointmentsExport()
    }

    async sendEhrsExport() {
        await repository.sendEhrsExport()
    }

    async enableGCal() {
        await repository.enableGCal()
    }

    unlinkUser(userId: number): Promise<void> {
        return repository.unlinkUser(userId)
    }

    getCurrentUserDeletedSchedules(): Promise<DeletedSchedule[]> {
        return repository.getCurrentUserDeletedSchedules()
    }

    recoverSchedule(scheduleId: number): Promise<void> {
        return repository.recoverDeletedSchedule(scheduleId)
    }

    calendarMappings(scheduleId: number, from: string, to: string): Promise<void> {
        return repository.calendarMappings(scheduleId, from, to)
    }

    synchronizeBusyPeriods(
        scheduleId: number,
        from: string,
        to: string,
        syncBookings: boolean,
        syncBlocks: boolean
    ): Promise<void> {
        return repository.synchronizeBusyPeriods(scheduleId, from, to, syncBookings, syncBlocks)
    }

    getScheduleActivationHistory(scheduleId: number): Promise<ScheduleAuditLog[]> {
        return repository.getScheduleActivationHistory(scheduleId)
    }

    getGruppoSettings(): Promise<GruppoSettings> {
        return repository.getGruppoSettings()
    }

    setGruppoSettings(enabled: boolean): Promise<GruppoSettings> {
        return repository.setGruppoSettings(enabled)
    }

    async downloadGruppoDoctorsCsv(): Promise<void> {
        const response = await repository.downloadGruppoDoctorsCsv()
        download(response.data, getFileNameFromContentDisposition(response))
    }

    async setIsPublicPractitioner(isPublicPractitioner: boolean): Promise<void> {
        await repository.setIsPublicPractitioner(isPublicPractitioner)
    }

    getIsPublicPractitioner(): Promise<string | boolean> {
        return repository.getIsPublicPractitioner()
    }

    async setBookingRequests(allowBookingRequests: boolean): Promise<void> {
        await repository.setBookingRequests(allowBookingRequests)
    }

    revertClinicInviteByInvitationId(doctorInvitationId: string): Promise<void> {
        return repository.revertClinicInviteByInvitationId(doctorInvitationId)
    }

    revertClinicInviteByScheduleId(scheduleId: number): Promise<void> {
        return repository.revertClinicInviteByScheduleId(scheduleId)
    }

    getSchedulesFromClinicNotLogged(): Promise<any> {
        return repository.getSchedulesFromClinicNotLogged()
    }

    disconnectPrivateSchedule(scheduleId: number): Promise<void> {
        return repository.disconnectPrivateSchedule(scheduleId)
    }

    getOwnershipMigrations(): Promise<OwnershipMigration[]> {
        return repository.getOwnershipMigrations()
    }

    revertOwnershipMigration(migrationId: string): Promise<boolean> {
        return repository.revertOwnershipMigration(migrationId)
    }

    uploadPriceTable(content: ItemPriceRenewal[]): Promise<void> {
        return repository.uploadPriceTable(content)
    }

    invalidatePackageAppCache(): Promise<void> {
        return repository.invalidatePackageAppCache()
    }

    setupGuideReset(): Promise<void> {
        return repository.setupGuideReset()
    }
}

export default new AdminService()
