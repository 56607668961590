export enum SassLocation {
    PatientInfo = 1,
    VisitInfo = 2,
    MedicalBackground = 3
}

export enum PmsCustomField {
    ShortText = 1,
    LongText = 2,
    Diagnosis = 3,
    Examination = 4,
    Document = 5,
    PrivateNote = 6,
    AINote = 7
}

export enum PmsCustomFieldState {
    Active = 1,
    Removed = 2,
    Document = 3
}

export enum EpisodeSyncState {
    Done = 'Done',
    Error = 'Error',
    InProgress = 'InProgress',
    NotSynced = 'NotSynced',
    WillNotSync = 'WillNoSync',
    WillSync = 'WillSync'
}
