import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'

import { CommissionsResponse, PaymentsResponse } from '../domain/interfaces/aggregate.interfaces'
import { ReportsParameters } from '../domain/interfaces/reports.interfaces'

const URL_BASE = 'reports'

const URL_AGGREGATES = `${URL_BASE}/aggregates`
const URL_PAYMENTS = `${URL_AGGREGATES}/payment`
const URL_COMMISSIONS = `${URL_AGGREGATES}/commissions`
const URL_COMMISSIONS_EXPORT = `${URL_AGGREGATES}/commissions/export/:doctorId`

class AggregateRepository {
    async getPayments(args: ReportsParameters): Promise<PaymentsResponse> {
        const { data } = await httpAPI.post(URL_PAYMENTS, args)
        return data
    }

    async getCommissions(args: ReportsParameters): Promise<CommissionsResponse[]> {
        const { data } = await httpAPI.post(URL_COMMISSIONS, args)
        return data
    }

    async getCommissionExport(doctorId: string, args: ReportsParameters): Promise<Blob> {
        const url = getUrlFromTemplate({
            template: URL_COMMISSIONS_EXPORT,
            params: {
                doctorId
            }
        })

        const { data } = await httpAPI.post(url, args, {
            responseType: 'blob'
        })
        return data
    }
}

export default new AggregateRepository()
