import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { parseParams } from '@/utils/parseParams'

import { AvailabilityBoundaries, Schedule } from '../domain/interfaces/schedule.interface'
import { AvailableSlotsParameters, AvailableSlotsResponse } from '../domain/interfaces/slot.interface'
import { SlotNextAvailableDateDTO } from './interfaces/schedule.interfaces'
import { SlotsNextAvailableDayQuery } from './types/schedule.types'

const URL_BASE = 'schedules'
const URL_SCHEDULE = `${URL_BASE}/:scheduleId`
const URL_AVAILABILITY_BOUNDARIES = `${URL_SCHEDULE}/availability-boundaries`
const URL_OWN_SCHEDULE = `${URL_BASE}?owner=true'`
const URL_AVAILABLE_SLOTS = `${URL_SCHEDULE}/slots`
const URL_NEXT_AVAILABLE_DAY = `${URL_AVAILABLE_SLOTS}/nextavailableday`
const URL_OWN_SCHEDULE_INCLUDING_DELETED = `${URL_BASE}?includeDeleted=true`

class ScheduleRepository {
    async getAvailableSlots(scheduleId: number, params: AvailableSlotsParameters): Promise<AvailableSlotsResponse> {
        const url = getUrlFromTemplate({
            template: URL_AVAILABLE_SLOTS,
            params: { scheduleId }
        })
        const { data } = await httpAPI.get(url, {
            params: {
                StartDate: params.startDate,
                EndDate: params.endDate,
                Duration: params.duration
            }
        })
        return data
    }

    async getSchedules(): Promise<Schedule[]> {
        const { data } = await httpAPI.get(URL_BASE)
        return data
    }

    async getScheduleById(scheduleId: number): Promise<Schedule> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE,
            params: { scheduleId }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async updateSchedules(scheduleId: number, payload: Schedule): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE,
            params: { scheduleId }
        })
        const { data } = await httpAPI.put(url, payload)
        return data
    }

    async getOwnSchedule(): Promise<Schedule[]> {
        const { data } = await httpAPI.get(URL_OWN_SCHEDULE)
        return data
    }

    async getAvailabilityBoundaries(scheduleId: number): Promise<AvailabilityBoundaries> {
        const url = getUrlFromTemplate({
            template: URL_AVAILABILITY_BOUNDARIES,
            params: { scheduleId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async sendAvailabilityBoundaries(scheduleId: number, payload: AvailabilityBoundaries): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_AVAILABILITY_BOUNDARIES,
            params: { scheduleId }
        })

        return httpAPI.post(url, payload)
    }

    async getAllSchedulesIncludingDeleted(): Promise<Schedule[]> {
        const { data } = await httpAPI.get(URL_OWN_SCHEDULE_INCLUDING_DELETED)
        return data
    }

    async getScheduleSlotsNextAvailableDay(
        scheduleId: number,
        query?: SlotsNextAvailableDayQuery
    ): Promise<SlotNextAvailableDateDTO> {
        const url = getUrlFromTemplate({
            template: URL_NEXT_AVAILABLE_DAY,
            params: {
                scheduleId: scheduleId.toString()
            },
            query: parseParams({ ...query })
        })

        const { data } = await httpAPI.get(url)
        return data
    }
}

export default new ScheduleRepository()
