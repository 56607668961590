import appReady from '@/middleware/appReady'

const VideoConsultation = () => import(/* webpackChunkName: "video-consultation" */ '../views/VideoConsultation.vue')
const TermsAndConditions = () =>
    import(/* webpackChunkName: "video-terms-and-conditions" */ '../views/TermsAndConditions.vue')

const VIDEO_CONSULTATION_TERMS = 'terms-and-conditions-video'
export const VIDEO_CONSULTATION = 'video-consultation'
const PATIENT_VIDEO_CONSULTATION = 'patient-video-consultation'

const doctorVideoConsultation = {
    path: VIDEO_CONSULTATION,
    name: VIDEO_CONSULTATION,
    meta: {
        title: 'online-consultation-videocall-title-status-before'
    },
    component: VideoConsultation
}

const patientVideoConsultation = {
    path: PATIENT_VIDEO_CONSULTATION,
    name: PATIENT_VIDEO_CONSULTATION,
    meta: {
        title: 'online-consultation-videocall-title-status-before',
        middleware: [appReady]
    },
    component: VideoConsultation,
    props: {
        isDoctor: false
    }
}

const videoConsultationTerms = {
    path: VIDEO_CONSULTATION_TERMS,
    name: VIDEO_CONSULTATION_TERMS,
    meta: {
        title: VIDEO_CONSULTATION_TERMS,
        middleware: [appReady]
    },
    component: TermsAndConditions,
    props: {
        isDoctor: false
    }
}

export { doctorVideoConsultation, patientVideoConsultation, videoConsultationTerms }
