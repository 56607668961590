export enum PatientCreatedFrom {
    AdminBooking,
    PatientsTab,
    BackOffice,
    Migration,
    MarketPlace,
    PhoneContactImport
}

export enum PatientListOrder {
    AlphabeticalFirstName = 'firstname',
    AlphabeticalLastName = 'lastname',
    LastAppointment = 'lastAppointmentDate',
    NextAppointment = 'nextAppointmentDate',
    ReferenceId = 'referenceId'
}

export enum PaymentStatusFilterKeys {
    Paid = 'paid',
    Unpaid = 'unpaid'
}
