import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

interface Response<T> {
    data: T
    entityId?: null
    error: false
    exception?: null
    exceptionId?: null
    message: string
    status: number
}

interface ResponseErrorData {
    detail?: string
    entityId?: string
    exception?: string
    exceptionId?: string
    instance?: string
    message: string
    title?: string
    type?: string
}

interface ResponseError {
    data: null
    detail?: string
    entityId?: string
    error: true
    exception?: string
    exceptionId?: string
    message: string
    status: number
    type?: string
}

const isAxiosError = <ResponseType>(error: unknown): error is AxiosError<ResponseType> => {
    return axios.isAxiosError(error)
}

const handleError = (error: unknown): ResponseError => {
    if (!isAxiosError<ResponseErrorData>(error)) {
        return { error: true, message: 'ERROR_UNKNOWN', status: -1, data: null }
    }

    const { response } = error

    if (!response) {
        return { error: true, message: error.message, status: error.status || -1, data: null }
    }

    const message = typeof response.data === 'string' ? response.data : response.data.message

    return {
        error: true,
        data: null,
        message,
        exception: response.data?.exception,
        exceptionId: response.data?.exceptionId || response.data?.detail,
        status: response.status,
        type: response.data?.type,
        entityId: response.data?.entityId,
        detail: response.data?.detail
    }
}

const get = (httpClient: AxiosInstance) => {
    return async <T>(path: string): Promise<Response<T> | ResponseError> => {
        try {
            const { data, status } = await httpClient.get<T>(path)

            return { error: false, data, message: '', status }
        } catch (error: unknown) {
            return handleError(error)
        }
    }
}

const patch = (httpClient: AxiosInstance) => {
    return async <T, P>(path: string, payload: P | null = null): Promise<Response<T> | ResponseError> => {
        try {
            const { data, status } = payload
                ? await httpClient.patch<T>(path, payload)
                : await httpClient.patch<T>(path)

            return { error: false, data, message: '', status }
        } catch (error: unknown) {
            return handleError(error)
        }
    }
}

const post = (httpClient: AxiosInstance) => {
    return async <T, P>(
        path: string,
        payload: P | null = null,
        headers: AxiosRequestConfig | undefined = undefined
    ): Promise<Response<T> | ResponseError> => {
        try {
            const { data, status } = payload
                ? await httpClient.post<T>(path, payload, headers)
                : await httpClient.post<T>(path)

            return { error: false, data, message: '', status }
        } catch (error: unknown) {
            return handleError(error)
        }
    }
}

const put = (httpClient: AxiosInstance) => {
    return async <T, P>(path: string, payload: P | null = null): Promise<Response<T> | ResponseError> => {
        try {
            const { data, status } = payload ? await httpClient.put<T>(path, payload) : await httpClient.put<T>(path)

            return { error: false, data, message: '', status }
        } catch (error: unknown) {
            return handleError(error)
        }
    }
}

const remove = (httpClient: AxiosInstance) => {
    return async <T, P>(path: string, payload: P | null = null): Promise<Response<T> | ResponseError> => {
        try {
            const { data, status } = payload
                ? await httpClient.delete<T>(path, payload)
                : await httpClient.delete<T>(path)

            return { error: false, data, message: '', status }
        } catch (error: unknown) {
            return handleError(error)
        }
    }
}

export const createAgent = (httpClient: AxiosInstance) => ({
    get: get(httpClient),
    patch: patch(httpClient),
    post: post(httpClient),
    put: put(httpClient),
    remove: remove(httpClient)
})
