import httpAPI from '@/api/utils/httpClientAPI'

const URL_BASE = 'account'
const URL_TERMS_ACCEPTED = `${URL_BASE}/videoconsultation/termsaccepted`

class VideoConsultationTermsRepository {
    async setVideoTermsAccepted(type: number): Promise<void> {
        return httpAPI.patch(`${URL_TERMS_ACCEPTED}?from=${type}`)
    }
}

export default new VideoConsultationTermsRepository()
