export enum CampaignsRecipientsFilterName {
    AgeFrom = 'ageFrom',
    AgeTo = 'ageTo',
    AgeType = 'ageType',
    CampaignType = 'campaignType',
    DoctorId = 'doctorId',
    FacilityId = 'facilityId',
    From = 'from',
    Gender = 'gender',
    InsuranceId = 'insuranceId',
    IsPublic = 'isPublic',
    PageNumber = 'pageNumber',
    PageSize = 'pageSize',
    PagesAmount = 'pagesAmount',
    PatientsType = 'patientsType',
    ScheduleId = 'scheduleId',
    ServiceId = 'serviceId',
    SpecialityId = 'specialityId',
    To = 'to'
}

export enum CampaignsRecipientsFilterGroupName {
    AgeData = 'ageData',
    Range = 'range'
}

export enum CampaignsRecipientsFilterValueAgeType {
    Years = 0,
    Months,
    Days
}

export enum CampaignsRecipientsFilterValueGender {
    Female = 0,
    Male,
    Other
}

export enum CampaignsRecipientsProperty {
    Age = 'age',
    Email = 'email',
    FullName = 'fullName',
    Gender = 'gender',
    InsuranceName = 'insuranceName',
    LastAppointmentDate = 'lastAppointmentDate',
    Phone = 'phone',
    Service = 'service',
    Specialist = 'specialist',
    Speciality = 'speciality'
}

export enum PatientsTypeId {
    All = 1,
    BookedAppointments,
    VisitedLastTime,
    AssignedToDoctorOnly
}

export enum CampaignsRecipientsTracking {
    AddRecipient = 'add_recipient_filter',
    Age = 'age_filter',
    AllRecipients = 'all_recipients_filter',
    Date = 'daterange_filter',
    DoctorId = 'doctor_filter',
    Gender = 'gender_filter',
    InsuranceId = 'insurance_filter',
    IsPublic = 'isPublic_filter',
    PatientsType = 'patientsType_filter',
    ScheduleId = 'schedule_filter',
    ServiceId = 'service_filter',
    SpecialityId = 'speciality_filter'
}
