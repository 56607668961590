import { GettersAdaptor } from '@/interfaces'

import { FacilityRoomEntity } from '../../domain/interfaces/rooms.interface'
import { RoomsGetters, RoomsGetterTypes } from './types'

const getters: GettersAdaptor<RoomsGetters, any, any> = {
    [RoomsGetterTypes.GetRoomById]: state => (roomId: string | number) => {
        return state.rooms.find((room: FacilityRoomEntity) => room.id === roomId)
    }
}

export default getters
