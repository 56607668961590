import { SETTINGS_MESSAGES_ROUTE } from '@/features/settings/ui/constants/router.constants'

const MessagesSettings = () =>
    import(/* webpackChunkName: "messages-settings" */ '@/features/settings/ui/views/messages/MessagesSettings.vue')

export default [
    {
        path: 'messages-settings',
        name: SETTINGS_MESSAGES_ROUTE,
        component: MessagesSettings,
        props: true
    }
]
