import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { PatientChatCategory } from '@/features/chat/domain/enums/chat.enums'

import {
    PatientCanReplyToChat,
    PatientChatAppMigrationStatus,
    PatientChatConversation,
    PatientChatPatient,
    PatientConversation
} from '../domain/interfaces/conversations.interfaces'

const URL_BASE_CHAT = 'chat'
const URL_BASE_CHAT_V2 = 'v2/chat'
const URL_BASE_PATIENTS = 'patients'
const URL_BASE_CHAT_PATIENTS = `${URL_BASE_CHAT}/${URL_BASE_PATIENTS}`
const URL_BASE_CHAT_V2_PATIENTS = `${URL_BASE_CHAT_V2}/${URL_BASE_PATIENTS}`
const URL_PATIENT_CONVERSATION = `${URL_BASE_CHAT_PATIENTS}/:patientId/conversation`
const URL_PATIENT_CONVERSATION_CATEGORY = `${URL_BASE_CHAT_PATIENTS}/conversation/category`
const URL_PATIENT_CONVERSATION_WITH_HOST_ID = `${URL_BASE_CHAT_PATIENTS}/:patientId/conversation/:hostId`
const URL_PATIENT_CONVERSATION_BY_PROVIDER_ID = `${URL_BASE_CHAT_PATIENTS}/conversation/provider-id/:providerId`
const URL_CONVERSATION_INVOICES = `${URL_PATIENT_CONVERSATION}/invoices`
const URL_CONVERSATION_QUOTES = `${URL_PATIENT_CONVERSATION}/quotes`
const URL_CONVERSATION_VOUCHERS = `${URL_PATIENT_CONVERSATION}/vouchers`

const URL_PATIENT_IS_MIGRATED = `${URL_BASE_CHAT_V2}/patients/:patientId/migration-status`
const URL_PATIENT_CHAT_CONVERSATION = `${URL_BASE_CHAT_V2}/patients/:patientId/conversation`
const URL_PATIENT_CHAT_PATIENT = `${URL_BASE_CHAT_V2}/patients/:patientId/patient-data`
const URL_PATIENT_CHAT_MOVE_TO_CATEGORY = `${URL_BASE_CHAT_V2}/patients/:patientId/conversation/category`

const URL_RESYNC_SENDBIRD = `${URL_BASE_PATIENTS}/create-from-chat`
const URL_RESYNC_SENDBIRD_BY_CHANNEL = `${URL_BASE_PATIENTS}/create-from-chat/:channelUrl`

const GRUPPO_URL_BASE = `${URL_BASE_CHAT_V2}/gruppo`

const GRUPPO_URL_PATIENT_CHAT_CONVERSATION = `${GRUPPO_URL_BASE}/patients/:patientId/conversation`
const GRUPPO_URL_PATIENT_CHAT_PATIENT = `${GRUPPO_URL_BASE}/patients/:patientId/patient-data`
const GRUPPO_URL_PATIENT_CHAT_MOVE_TO_CATEGORY = `${GRUPPO_URL_BASE}/patients/:patientId/conversation/category`

const URL_PATIENT_IS_CALL_CENTER_OPERATOR = `${URL_BASE_PATIENTS}/:patientId/role/iscallcenter`
const URL_CAN_REPLY_TO_CHAT = `${URL_BASE_PATIENTS}/:patientId/communicationsettings/canreplytochat`

const CONVERSATION_FOLDER_TYPE: Record<string, number> = {
    inbox: 0,
    done: 1,
    muted: 2,
    deleted: 3
}

class PatientsConversationsRepository {
    async getConversationByPatientId(patientId: number): Promise<PatientConversation> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_CONVERSATION,
            params: { patientId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getConversationByChatId(chatId: number, hostId?: number): Promise<PatientConversation> {
        const url = getUrlFromTemplate({
            template: hostId ? URL_PATIENT_CONVERSATION_WITH_HOST_ID : URL_PATIENT_CONVERSATION,
            params: hostId ? { patientId: chatId, hostId } : { patientId: chatId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getConversationByProviderId(providerId: string): Promise<PatientConversation> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_CONVERSATION_BY_PROVIDER_ID,
            params: { providerId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async moveConversationToCategory(
        category: PatientChatCategory,
        patientId: number,
        hostUserId?: number
    ): Promise<any> {
        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_PATIENT_CHAT_MOVE_TO_CATEGORY : URL_PATIENT_CHAT_MOVE_TO_CATEGORY,
            params: {
                patientId
            }
        })

        return httpAPI.put(url, hostUserId ? { category, hostUserId } : { category })
    }

    async moveConversationToCategoryByProvider(
        category: PatientChatCategory,
        conversationProviderId: string,
        resolvePatientRequests = false
    ): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_CONVERSATION_CATEGORY
        })

        return httpAPI.put(url, {
            conversationProviderId,
            category,
            resolvePatientRequests
        })
    }

    async moveConversationToFolder(
        type: string,
        patientId: number,
        hostId?: number,
        resolvePatientRequests = false
    ): Promise<any> {
        const url = getUrlFromTemplate({
            template: hostId ? URL_PATIENT_CONVERSATION_WITH_HOST_ID : URL_PATIENT_CONVERSATION,
            params: hostId
                ? {
                      patientId: patientId.toString(),
                      hostId
                  }
                : { patientId: patientId.toString() }
        })

        return httpAPI.put(url, {
            conversationType: CONVERSATION_FOLDER_TYPE[type],
            resolvePatientRequests
        })
    }

    async sendInvoicesToPatient(patientId: number, invoicesIds: number[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATION_INVOICES,
            params: {
                patientId: patientId.toString()
            }
        })

        await httpAPI.post(url, invoicesIds)
    }

    async sendQuotesToPatient(patientId: number, quotesIds: number[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATION_QUOTES,
            params: {
                patientId: patientId.toString()
            }
        })

        await httpAPI.post(url, quotesIds)
    }

    async sendVouchersToPatient(patientId: number, quotesIds: number[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CONVERSATION_VOUCHERS,
            params: {
                patientId: patientId.toString()
            }
        })

        await httpAPI.post(url, quotesIds)
    }

    resyncSendbird(): Promise<void> {
        return httpAPI.post(URL_RESYNC_SENDBIRD)
    }

    async resyncSendbirdByChannelUrl(channelUrl: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_RESYNC_SENDBIRD_BY_CHANNEL,
            params: {
                channelUrl
            }
        })

        return httpAPI.post(url)
    }

    async fetchPatientChatAppMigration(patientId: number): Promise<PatientChatAppMigrationStatus> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_IS_MIGRATED,
            params: { patientId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async fetchChatConversationData(patientId: number, hostUserId?: number): Promise<PatientChatConversation> {
        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_PATIENT_CHAT_CONVERSATION : URL_PATIENT_CHAT_CONVERSATION,
            params: { patientId },
            query: hostUserId ? { hostUserId } : undefined
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async fetchChatPatientData(patientId: number, hostUserId?: number): Promise<PatientChatPatient> {
        const url = getUrlFromTemplate({
            template: hostUserId ? GRUPPO_URL_PATIENT_CHAT_PATIENT : URL_PATIENT_CHAT_PATIENT,
            params: { patientId },
            query: hostUserId ? { hostUserId } : undefined
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async getIsPatientCallCenterOperator(patientId: number): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_PATIENT_IS_CALL_CENTER_OPERATOR,
            params: { patientId }
        })
        const { data } = await httpAPI.get(url)
        return data || false
    }

    async getAllowPatientReplyToggleValue(patientId: number, hostId?: number): Promise<PatientCanReplyToChat> {
        const url = getUrlFromTemplate({
            template: URL_CAN_REPLY_TO_CHAT,
            params: { patientId },
            query: hostId ? { hostId } : undefined
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async updateAllowPatientReplyToggleValue(
        patientId: number,
        canReplyToChat: boolean,
        hostId?: number
    ): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CAN_REPLY_TO_CHAT,
            params: { patientId },
            query: hostId ? { hostId } : undefined
        })

        const body: PatientCanReplyToChat = {
            canReplyToChat
        }

        await httpAPI.put(url, body)
    }
}

export default new PatientsConversationsRepository()
