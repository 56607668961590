import httpAPI from '@/api/utils/httpClientAPI'

const Schedules = () => {
    const url = {
        getAllUrl: 'schedules',
        getMySchedulesUrl: 'schedules?owner=true',
        getSchedule: 'schedules',
        getSchedules: 'schedules',
        deleteSchedule: 'schedules',
        updateSchedule: 'schedules',
        scheduleServiceUrl: scheduleId => `v2/schedules/${scheduleId}/serviceschedules`
    }

    return {
        getSchedules() {
            return httpAPI.get(url.getAllUrl)
        },
        getAllSchedules() {
            return httpAPI.get(url.getSchedules)
        },
        getAll(onlyMy) {
            const getAllUrl = onlyMy ? url.getMySchedulesUrl : url.getAllUrl
            return httpAPI.get(getAllUrl)
        },
        getServices(id) {
            return httpAPI.get(`${url.getSchedule}/${id}/serviceschedules`)
        },
        getSchedule(id) {
            return httpAPI.get(`${url.getSchedule}/${id}`)
        },
        updateSchedule(id, d) {
            return httpAPI.put(`${url.updateSchedule}/${id}`, d)
        },
        getSchedulesWithFeatureUrl(feature) {
            return httpAPI.get(`${url.getSchedules}?featureFilter=${feature}`)
        },
        deleteSchedule(id) {
            return httpAPI.delete(`${url.deleteSchedule}/${id}`)
        },
        getSchedulesByDoctor(id) {
            return httpAPI.get(`${url.getSchedules}?doctorId=${id}`)
        },
        createServiceForSchedule(scheduleId, service) {
            return httpAPI.post(url.scheduleServiceUrl(scheduleId), service)
        },
        updateServiceForSchedule(scheduleId, service) {
            return httpAPI.put(`${url.scheduleServiceUrl(scheduleId)}/${service.id}`, service)
        }
    }
}

export default Schedules
