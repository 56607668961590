import { SchedulePaymentUpfrontOptionValue, SettingsTabIds, UpfrontPaymentsMandatorySetting } from '../enums'
import { SettingsTab } from '../interfaces'

export const SETTINGS_TABS: SettingsTab[] = [
    {
        label: 'payments-section-settings-tab-1',
        value: SettingsTabIds.Schedules,
        iconName: 'calendar',
        isActive: true
    },
    {
        label: 'payments-section-settings-tab-2',
        value: SettingsTabIds.ActiveSchedules,
        iconName: 'docplanner'
    }
]

export const SETTINGS_ADMIN_BOOKINGS_OPTIONS = {
    [UpfrontPaymentsMandatorySetting.ExistingPatients]: {
        newPatientsValue: SchedulePaymentUpfrontOptionValue.Optional,
        existingPatientsValue: SchedulePaymentUpfrontOptionValue.Mandatory
    },
    [UpfrontPaymentsMandatorySetting.NewPatients]: {
        newPatientsValue: SchedulePaymentUpfrontOptionValue.Mandatory,
        existingPatientsValue: SchedulePaymentUpfrontOptionValue.Optional
    },
    [UpfrontPaymentsMandatorySetting.None]: {
        newPatientsValue: SchedulePaymentUpfrontOptionValue.Optional,
        existingPatientsValue: SchedulePaymentUpfrontOptionValue.Optional
    },
    [UpfrontPaymentsMandatorySetting.AllPatients]: {
        newPatientsValue: SchedulePaymentUpfrontOptionValue.Mandatory,
        existingPatientsValue: SchedulePaymentUpfrontOptionValue.Mandatory
    }
}
