import repository from '@/features/admin/api/admin.import.api'
import { INFORMATION_LIST_KEY_BLOCKERS } from '@/features/admin/domain/constants/admin.import.constants'
import {
    AdminImportValidationObject,
    ImportField,
    ImportFieldOption,
    ImportFieldsMap
} from '@/features/admin/domain/interfaces/admin.interfaces'
import { PmsField } from '@/features/pms/domain/interfaces/ehr.interfaces'
import PmsService from '@/features/pms/domain/pms.service'

const getFieldMap = ({ id, name = '', displayText = '' }: ImportField): ImportFieldOption => ({
    field: id,
    name: name.charAt(0).toLowerCase() + name.slice(1),
    displayText
})

class AdminImportService {
    async getFields(): Promise<ImportFieldsMap> {
        try {
            const [patientFields, eventFields, ehrFields, customEhrFields] = await Promise.all([
                repository.getFieldPatient(),
                repository.getFieldEvent(),
                repository.getFieldEHR(),
                PmsService.getAdminPmsFields()
            ])

            return {
                patient: patientFields.map(getFieldMap),
                event: eventFields.map(getFieldMap),
                ehr: [
                    ...ehrFields.map(getFieldMap),
                    ...customEhrFields.map(({ id, label }: PmsField) => ({
                        field: id as number,
                        name: label,
                        displayText: label
                    }))
                ]
            }
        } catch {
            return {
                patient: [],
                event: [],
                ehr: []
            }
        }
    }

    informationListHasAnyBlockingError(validations: AdminImportValidationObject[]): boolean {
        const errors = validations.reduce((keys: string[], { informationList }: AdminImportValidationObject) => {
            return [...new Set([...keys, ...informationList])]
        }, [])

        return INFORMATION_LIST_KEY_BLOCKERS.some(keyBlocker => errors.includes(keyBlocker))
    }
}

export default new AdminImportService()
