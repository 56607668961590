import { NavigationGuardNext } from 'vue-router'
import { Store as VuexStore } from 'vuex'

import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'
import Store from '@/store'

type MiddlewareParams = {
    next: NavigationGuardNext
    store: VuexStore<typeof Store>
}

export default function hasAccessToDoctorInvitation({ store, next }: MiddlewareParams) {
    if (store.getters[AuthGetterTypes.IsDoctor]) {
        return next()
    }

    return next({ name: ROUTE_CALENDAR_BASE_NAME })
}
