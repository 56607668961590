import { RouteConfig } from 'vue-router'

import { SETUP_GUIDE_ROUTE_NAME, SETUP_GUIDE_ROUTE_PATH } from '../constants'

const SetupGuide = () => import(/* webpackChunkName: "setup-guide-home" */ '../views').then(module => module.SetupGuide)

export const SETUP_GUIDE_ROUTE: RouteConfig = {
    path: SETUP_GUIDE_ROUTE_PATH,
    name: SETUP_GUIDE_ROUTE_NAME,
    component: SetupGuide,
    meta: {
        uninavName: 'activation-system'
    }
}
