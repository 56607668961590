import repository from '@/features/settings/api/users.api'
import { UNAVAILABLE_USER_ACCESSES_PER_COUNTRY } from '@/features/settings/domain/constants/users.constants'
import {
    IAccountUser,
    ISecretaryTypes,
    IUser,
    IUserAccess
} from '@/features/settings/domain/interfaces/users.interfaces'

class UsersService {
    updatePermissions(userId: number, payload: any): Promise<any> {
        return repository.updatePermissions(userId, payload)
    }

    getRelatedUsers(): Promise<IUser[]> {
        return repository.getRelatedUsers()
    }

    getMedicalCenterRelatedUsers(): Promise<IUser[]> {
        return repository.getMedicalCenterRelatedUsers()
    }

    getSecretaryTypes(): Promise<ISecretaryTypes[]> {
        return repository.getSecretaryTypes()
    }

    getAvailableUserAccesses(userAccesses: IUserAccess[], countryCode: string) {
        const unavailableUserAccesses = UNAVAILABLE_USER_ACCESSES_PER_COUNTRY[countryCode] || []
        return userAccesses.map(userAccess => {
            const isUnavailable = unavailableUserAccesses.includes(userAccess.accessId)

            return {
                ...userAccess,
                show: !isUnavailable,
                enabled: isUnavailable ? false : userAccess.enabled
            }
        })
    }

    getAccountUsers({ includeCurrentUser } = { includeCurrentUser: false }): Promise<IAccountUser[]> {
        return repository.getAccountUsers({ includeCurrentUser })
    }

    getUserDocumentationData(userId: number): Promise<Record<string, string>> {
        return repository.getUserDocumentationData(userId)
    }
}

export default new UsersService()
