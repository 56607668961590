import { setupGuide } from '@docplanner/one-tracking'

import { track } from '@/plugins/tracking/index'

import {
    createSetupGuideMainPageLoadedHandler,
    // offSetupGuideMainPageLoaded,
    onSetupGuideMainPageLoaded
} from '../events'

export const startTrackingPage = () => {
    const handlerPageShown = createSetupGuideMainPageLoadedHandler(() => {
        track(setupGuide.mainPage)

        /**
         * Note: Temporary disable to review the approach
         * We want to track all the landings in the page
         * Disabling the disconnection to achieve that.
         * When we navigate outside of the application since it
         * will be another app, the tracking will be disconnected
         */
        // offSetupGuideMainPageLoaded(handlerPageShown)
    })

    onSetupGuideMainPageLoaded(handlerPageShown)
}
