export const CAMPAIGNS_ROUTE_NAME = 'campaigns'
export const CAMPAIGNS_ROUTE_NAME_TITLE = 'campaigns-navigation-title'
export const CAMPAIGNS_ROUTE_NAME_REPORT = 'campaigns-report'
export const CAMPAIGNS_ROUTE_NAME_SELECT_TYPE = 'select-type'
export const CAMPAIGNS_ROUTE_NAME_RECIPIENTS = 'recipients'
export const CAMPAIGNS_ROUTE_NAME_SEND_SMS = 'send-sms'
export const CAMPAIGNS_ROUTE_NAME_SEND_EMAIL = 'send-email'
export const CAMPAIGNS_ROUTE_NAME_AUTO = 'auto-campaign'

export const CAMPAIGNS_WIZARD_ROUTE_NAME_LIST = [
    CAMPAIGNS_ROUTE_NAME_SELECT_TYPE,
    CAMPAIGNS_ROUTE_NAME_RECIPIENTS,
    CAMPAIGNS_ROUTE_NAME_SEND_EMAIL,
    CAMPAIGNS_ROUTE_NAME_SEND_SMS,
    CAMPAIGNS_ROUTE_NAME_AUTO
]
