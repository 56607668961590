import { isTstOrDevelopment } from '@/bootstrap/environment'
import { isPast, parseDate } from '@/core/ui/utils'

import { isRemoteToggleEnabled as getUnleashVariantIsEnabled } from '../providers/unleash'
import { getQueryStringValue } from './getQueryStringValue'

const debugMessageShown = {}

export const ensureToggleIsEnabled = ({ key, value, toggles, queryString }) => {
    const actualValue = `${value}`

    // feature toggle service

    const variantIsEnabled = getUnleashVariantIsEnabled(key, actualValue)

    // static file toggle system

    const foundToggle = toggles[key]

    if (typeof foundToggle === 'undefined') {
        return false
    }

    if (foundToggle.isRemote) {
        return variantIsEnabled
    }

    if (isTstOrDevelopment && !debugMessageShown[key]) {
        debugMessageShown[key] = true
        const expireDate = foundToggle.devExpire ? parseDate(foundToggle.devExpire) : null

        if (expireDate) {
            if (isPast(expireDate)) {
                /* eslint-disable-next-line no-console */
                console.warn(`The Feature toggle '${key}' has expired, please clean it up`)
            }
        } else {
            /* eslint-disable-next-line no-console */
            console.warn(`The Feature toggle '${key}' has no expiry date, please add one`)
        }
    }

    const queryStringValue = getQueryStringValue(key, queryString)
    const queryParamEnabled = foundToggle.queryParam === true

    return queryStringValue
        ? queryParamEnabled && queryStringValue === actualValue
        : `${foundToggle.value}` === actualValue
}
