import { GettersAdaptor } from '@/interfaces'

import { GetterTypes, VouchersGetter } from './types'
import { VouchersState } from './types/state'

export const getters: GettersAdaptor<VouchersGetter, VouchersState, any> = {
    [GetterTypes.GetVouchers]: state => state.vouchers,
    [GetterTypes.GetVoucherTypesPaginated]: state => state.voucherTypesPaginated,
    [GetterTypes.GetVoucherTypes]: state => state.voucherTypes,
    [GetterTypes.GetLoading]: state => state.isLoading,
    [GetterTypes.GetLoadingVoucherTypes]: state => state.isLoadingVoucherTypes,
    [GetterTypes.GetShowActive]: state => state.showActive,
    [GetterTypes.GetCurrentPage]: state => state.currentPage,
    [GetterTypes.GetHasFullyRedeemedVoucher]: state => state.hasFullyRedeemedVouchers,
    [GetterTypes.GetTotalPage]: state => state.totalPage,
    [GetterTypes.GetVouchersFilters]: state => state.vouchersFilters,
    [GetterTypes.GetScheduleList]: state =>
        state.schedules.map(schedule => ({
            ...schedule,
            text: schedule.displayName || schedule.name,
            value: schedule.id
        }))
}
