import repository from '@/features/settings/api/voicemail.api'
import {
    BlacklistNumber,
    ConfigurationStatus,
    DoctorConfiguration,
    ResponseType
} from '@/features/settings/domain/interfaces/ voicemail.interfaces'

class VoicemailService {
    getDoctorConfiguration(doctorId: string): Promise<DoctorConfiguration> {
        return repository.getDoctorConfiguration(doctorId)
    }

    addDoctorConfiguration(
        doctorId: string,
        doctorPhone: string,
        responseTypeId: string,
        userId: string
    ): Promise<void> {
        return repository.addDoctorConfiguration(doctorId, doctorPhone, responseTypeId, userId)
    }

    deactivateDoctorConfiguration(doctorId: string, userId: string): Promise<void> {
        return repository.deactivateDoctorConfiguration(doctorId, userId)
    }

    initializeConfigurationChecker(doctorId: string): Promise<void> {
        return repository.initializeConfigurationChecker(doctorId)
    }

    getConfigurationCheckerStatus(doctorId: string): Promise<ConfigurationStatus> {
        return repository.getConfigurationCheckerStatus(doctorId)
    }

    getResponseTypes(): Promise<ResponseType[]> {
        return repository.getResponseTypes()
    }

    getBlacklistNumbers(doctorId: string): Promise<BlacklistNumber[]> {
        return repository.getBlacklistNumbers(doctorId)
    }

    addBlacklistNumbers(doctorId: string, phoneNumber: string, userId: string): Promise<BlacklistNumber> {
        return repository.addBlacklistNumbers(doctorId, phoneNumber, userId)
    }

    deleteBlacklistNumbers(doctorId: string, id: string): Promise<void> {
        return repository.deleteBlacklistNumbers(doctorId, id)
    }
}

export default new VoicemailService()
