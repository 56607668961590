export enum ReportFiltersIds {
    PaymentStatus = 'paymentStatus',
    Schedule = 'schedule',
    Status = 'status'
}

export enum PaymentStatus {
    Unpaid = 0,
    Paid,
    Refunded,
    RefundFailed,
    PartiallyPaid
}

export enum VisitStatus {
    CanceledByPatient = '8',
    CanceledByUser = '7',
    NoShow = '3',
    Pending = '1',
    Visited = '5',
    Visiting = '2',
    WaitingRoom = '4'
}

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc'
}
