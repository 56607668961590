import { HINT_SPECIALIST } from '@/features/hints/ui/constants/hints.constants'
import { ActionsAdaptor } from '@/interfaces'

import hintsService from '../../domain/hints.service'
import { ActionTypes, HintsActions, HintsMutations, HintsState, MutationTypes } from './types'

export const actions: ActionsAdaptor<HintsActions, HintsMutations, HintsState> = {
    async [ActionTypes.FetchHints]({ commit, state }, hint) {
        if (!state[hint]) {
            const data = await hintsService.getHint(hint)
            commit(MutationTypes.SetHint, { hint, data })
        }
    },

    async [ActionTypes.FetchHintsForAllSpecialists]({ commit, state }, hint) {
        if (!state[hint]) {
            const data = await hintsService.getHintForAllSpecialists()
            commit(MutationTypes.SetHint, { hint, data })
        }
    }
}
