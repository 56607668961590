import { MutationTypes, RequestMutations } from './types'

export const mutations: RequestMutations = {
    [MutationTypes.UpdateIsRequestFeatureEnabled](state, isEnabled) {
        state.isRequestFeatureEnabled = isEnabled
    },
    [MutationTypes.Pages](state, pages) {
        state.pageCount = pages
    },
    [MutationTypes.Requests](state, requests) {
        state.requests = requests.page
    },
    [MutationTypes.UpdateRequestList](state, requests) {
        state.requests = requests.map(request => {
            return {
                ...request,
                wasRead: !!request.requestReadDate
            }
        })
    },
    [MutationTypes.UpdateUnreadRequestCount](state, unreadCount) {
        state.unreadCount = unreadCount
    },
    [MutationTypes.UpdateRequestsIntegration](state, requestsIntegration) {
        const { channel, isEnabled, categories, allowRequestsFromNewPatient } = requestsIntegration
        state.channel = channel
        state.isRequestFeatureEnabled = isEnabled
        state.categories = categories
        state.allowRequestsFromNewPatient = allowRequestsFromNewPatient
    },
    [MutationTypes.UpdateAllowRequestsFromNewPatients](state, allowRequestsFromNewPatient) {
        state.allowRequestsFromNewPatient = allowRequestsFromNewPatient
    }
}
