import { unifiedNavigation } from '@docplanner/one-tracking'

import { ROUTE_PAYMENTS_BASE_NAME, ROUTE_PAYMENTS_OVERVIEW_PATH } from '../../constants/routes.constants'
import { $t } from './i18n.utils'

const pathname = `#/${ROUTE_PAYMENTS_BASE_NAME}/${ROUTE_PAYMENTS_OVERVIEW_PATH}`

export const getPaymentsNavigationOption = () => ({
    id: 'payments',
    pathname,
    link: pathname,
    label: $t('online-payments-header'),
    icon: 'bank-note',
    position: 'top',
    options: [],
    params: {
        id: 'payments',
        'data-testid': 'payments'
    },
    dataTracking: unifiedNavigation.openPayments
})
