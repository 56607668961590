import { User as DataDogUser } from '@datadog/browser-core'
import {
    datadogRum,
    RumActionEventDomainContext,
    RumEventDomainContext,
    RumXhrResourceEventDomainContext
} from '@datadog/browser-rum'
import { RumActionEvent, RumEvent } from '@datadog/browser-rum-core'
import versions from '@public/config/versions.json'

import configManager from '@/bootstrap/configurationManager'
import { extractQueryStringParams } from '@/utils/url'

import { COMMON_ON_DATADOG_RUM_READY } from '../monitor.constants'
import { botNavigatorRegex, excludedActivityUrls } from './datadog.constants'
import { DatadogCustomActionName, DatadogCustomMetricName, DatadogFakeCustomActionName } from './datadog.types'
import { getTargetName } from './datadog.utils'

type PropagatorType = 'tracecontext' | 'b3' | 'datadog' | 'b3multi'

// NOTE: Do not increase this value without OK from Security team (due to privacy issues)
const DATADOG_MAX_SESSION_REPLAY_SAMPLE_RATE = 0

// NOTE: Do not extend these supported options without OK from Security team
enum SupportedPrivacyLevelSessionReplay {
    Mask = 'mask'
}

const dataDogEnabled = !!parseInt(configManager.getValue('DATADOG_ENABLED'), 10)

let isInitialized = false

const getDataDogAllowedTracingUrls = () => {
    const allowedTracingUrls: { match: string; propagatorTypes: PropagatorType[] }[] = []

    const oneAppUrl = configManager.getValue('API_URL')
    if (oneAppUrl) {
        allowedTracingUrls.push({ match: oneAppUrl, propagatorTypes: ['tracecontext'] })
    }

    return allowedTracingUrls
}

const getSessionSampleRate = () => {
    const isBotSession = botNavigatorRegex.test(navigator.userAgent)
    return isBotSession ? 0 : parseInt(configManager.getValue('DATADOG_SESSION_SAMPLE_RATE'), 10)
}

const getSessionReplaySampleRate = () =>
    Math.min(
        parseInt(configManager.getValue('DATADOG_SESSION_REPLAY_SAMPLE_RATE'), 10),
        DATADOG_MAX_SESSION_REPLAY_SAMPLE_RATE
    )

const paramsToMask = ['code', 'token', 'invitationToken', 'email', 'filter', 'auth']
const MASKED_VALUE = 'MASKED'

const getUrlFiltersMasked = (url: string) => {
    const queryStringContents = url.split('?')[1]

    if (!queryStringContents) {
        return url
    }

    const params = extractQueryStringParams(queryStringContents) as Record<string, string>

    paramsToMask.forEach(param => {
        if (params[param]) {
            params[param] = MASKED_VALUE
        }
    })

    const newQueryString = `?${Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&')}`

    const newUrl = url.split('?')[0] + newQueryString
    return newUrl
}

// TODO: refactor to integrate with monitor (WSTB-503)
if (dataDogEnabled) {
    const tstId = configManager.getValue('DATADOG_TAG_TST_ID')

    if (tstId) {
        datadogRum.setGlobalContextProperty('docplanner.tst.id', tstId)
    }

    const EVENTS_TO_HIDE_TARGET_NAME = ['click']

    const beforeSend = (event: RumEvent, context: RumEventDomainContext) => {
        // Add extra data to understand XHRs with status code 0
        if (event.type === 'resource' && event.resource.type === 'xhr') {
            const typedContext = context as RumXhrResourceEventDomainContext

            event.context!.XHRstatus = typedContext.xhr.status
            event.context!.XHRreadyState = typedContext.xhr.readyState
        }

        const resourceTypeToMask = ['document', 'image']
        if (event.type === 'resource' && resourceTypeToMask.includes(event.resource.type)) {
            event.resource.url = getUrlFiltersMasked(event.resource.url)
        }

        if (event.type === 'view') {
            event.view.url = getUrlFiltersMasked(event.view.url)
            if (event.view.referrer) {
                event.view.referrer = getUrlFiltersMasked(event.view.referrer)
            }
        }

        const rumAction = event.action as RumActionEvent
        const rumTarget = rumAction?.target as { name: string | null }

        if (rumAction && EVENTS_TO_HIDE_TARGET_NAME.includes(rumAction.type)) {
            rumTarget.name = getTargetName(context as RumActionEventDomainContext)
        }

        // Return true to allow sending the event
        return true
    }

    datadogRum.onReady(() => {
        datadogRum.addTiming(COMMON_ON_DATADOG_RUM_READY)
    })

    datadogRum.init({
        allowedTracingUrls: getDataDogAllowedTracingUrls(),
        excludedActivityUrls,
        applicationId: configManager.getValue('DATADOG_APPLICATION_ID'),
        clientToken: configManager.getValue('DATADOG_CLIENT_TOKEN'),
        site: configManager.getValue('DATADOG_SITE'),
        service: configManager.getValue('DATADOG_SERVICE'),
        env: configManager.getValue('DATADOG_ENV'),
        version: versions && versions.latest,
        sessionSampleRate: getSessionSampleRate(),
        sessionReplaySampleRate: getSessionReplaySampleRate(),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackViewsManually: true,
        defaultPrivacyLevel: SupportedPrivacyLevelSessionReplay.Mask,
        beforeSend
    })

    isInitialized = true
}

export const setDataDogUser = (user: DataDogUser) => {
    // TODO: review if guard clause should be moved to monitoring middleware, as we're applying "undefined" data to Sentry as well (WSTB-506)
    if (!user.id) {
        return
    }

    if (isInitialized) {
        datadogRum.setUser(user)
    }
}

export const getDataDogUser = () => {
    if (isInitialized) {
        return datadogRum.getUser()
    }
}

export const clearDataDogUser = () => {
    if (isInitialized) {
        datadogRum.clearUser()
    }
}

export const addDataDogAction = (
    name: DatadogCustomActionName | DatadogFakeCustomActionName,
    context?: object | undefined
) => {
    if (isInitialized) {
        if (context) {
            datadogRum.addAction(name, context)
        } else {
            datadogRum.addAction(name)
        }
    }
}

export const addDataDogTiming = (name: DatadogCustomMetricName) => {
    if (isInitialized) {
        datadogRum.addTiming(name)
    }
}
