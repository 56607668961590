import { RequestIntegrationChannel } from '../types/requests.types'

export const INTEGRATION_CHANNEL: Record<string, string> = {
    chat: 'chat',
    m2000: 'm2000',
    dpPhone: 'dpPhone'
} as const

export const REQUEST_INTEGRATION_CHANNEL: RequestIntegrationChannel = {
    [INTEGRATION_CHANNEL.chat]: 0,
    [INTEGRATION_CHANNEL.dpPhone]: 1,
    [INTEGRATION_CHANNEL.m2000]: 2
} as const

export const REQUEST_ORDER_OPTIONS = [
    {
        id: 0,
        value: false,
        translationKey: 'requests-management-panel-filter-sort-by-newest'
    },
    {
        id: 1,
        value: true,
        translationKey: 'requests-management-panel-filter-sort-by-oldest'
    }
] as const
