import Vue from 'vue'

import { getDefaultState } from '@/features/reports/ui/store/state'
import {
    MUTATION_RESET_STATE,
    MUTATION_SET_CURRENT_PAGE_NUMBER,
    MUTATION_SET_CURRENT_REPORT_PATHNAME,
    MUTATION_SET_FETCH_PARAMETERS,
    MUTATION_SET_HEADERS,
    MUTATION_SET_LOADING,
    MUTATION_SET_NUM_OF_PAGES,
    MUTATION_SET_PAGE,
    MUTATION_UPDATE_PAGE_ITEM
} from '@/features/reports/ui/store/types/mutations'

const mutations = {
    [MUTATION_SET_FETCH_PARAMETERS](state, fetchParameters) {
        state.fetchParameters = fetchParameters
    },
    [MUTATION_SET_HEADERS](state, headers) {
        state.headers = headers
    },
    [MUTATION_SET_PAGE](state, page) {
        state.page = page
    },
    [MUTATION_UPDATE_PAGE_ITEM](state, item) {
        const index = state.page.findIndex(({ id }) => id === item.id)

        Vue.set(state.page, index, item)
    },
    [MUTATION_SET_NUM_OF_PAGES](state, numOfPages) {
        state.numOfPages = numOfPages
    },
    [MUTATION_SET_CURRENT_PAGE_NUMBER](state, currentPage) {
        state.currentPage = currentPage
    },
    [MUTATION_SET_LOADING](state, loading) {
        state.loading = loading
    },
    [MUTATION_RESET_STATE](state) {
        Object.assign(state, getDefaultState())
    },
    [MUTATION_SET_CURRENT_REPORT_PATHNAME](state, reportPathName) {
        state.currentReport = reportPathName
    }
}

export default mutations
