import AdminImportService from '@/features/admin/domain/admin.import.service'
import AdminServiceImportService from '@/features/admin/domain/admin.services.import.service'

import { FETCH_FIELDS, FETCH_SERVICE_FIELDS, RESET_STATE } from './types/actions'
import { ADMIN_IMPORT_RESET, ADMIN_IMPORT_SET_FIELDS, ADMIN_IMPORT_SET_SERVICES_FIELDS } from './types/mutations'

const actions = {
    async [RESET_STATE]({ commit }) {
        commit(ADMIN_IMPORT_RESET)
    },

    async [FETCH_FIELDS]({ commit }) {
        const fields = await AdminImportService.getFields()

        commit(ADMIN_IMPORT_SET_FIELDS, fields)
    },

    async [FETCH_SERVICE_FIELDS]({ commit }) {
        const fields = await AdminServiceImportService.getFields()

        commit(ADMIN_IMPORT_SET_SERVICES_FIELDS, fields)
    }
}

export default actions
