import CalendarZoomService from '@/features/calendar/domain/calendar.zoom.service'
import { GettersAdaptor } from '@/interfaces'

import { GETTER_CALENDAR_CURRENT_RESOURCES, GETTER_CALENDAR_ZOOM_LEVEL, MODULE_CALENDAR } from '../types'
import { CalendarZoomGetters, GetterTypes } from './types/getters'

const getters: GettersAdaptor<CalendarZoomGetters, void, any> = {
    [GetterTypes.GetCalendarSingleResourceWidth](_state, otherGetters, _rootState, rootGetters) {
        const zoomLevel = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_ZOOM_LEVEL}`]

        return CalendarZoomService.getZoomedResourceWidth(zoomLevel)
    },

    [GetterTypes.IsCalendarZoomDisabled](_state, _getters, _rootState, rootGetters) {
        const resources = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_CURRENT_RESOURCES}`]

        return resources.length === 0
    },

    [GetterTypes.IsCalendarZoomInDisabled](_state, otherGetters, _rootState, rootGetters) {
        const zoomLevel = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_ZOOM_LEVEL}`]

        return CalendarZoomService.isZoomInDisabled(zoomLevel) || otherGetters[GetterTypes.IsCalendarZoomDisabled]
    },

    [GetterTypes.IsCalendarZoomOutDisabled](_state, otherGetters, _rootState, rootGetters) {
        const zoomLevel = rootGetters[`${MODULE_CALENDAR}/${GETTER_CALENDAR_ZOOM_LEVEL}`]

        return CalendarZoomService.isZoomOutDisabled(zoomLevel) || otherGetters[GetterTypes.IsCalendarZoomDisabled]
    }
}

export default getters
