export const ACTION_REPORTS_RESET_MODULE = 'ACTION_REPORTS_RESET_MODULE'
export const ACTION_REPORTS_UPDATE_BOOKING_STATUS = 'ACTION_REPORTS_UPDATE_BOOKING_STATUS'
export const ACTION_REPORTS_UPDATE_PAYMENT_METHOD = 'ACTION_REPORTS_UPDATE_PAYMENT_METHOD'
export const ACTION_REPORTS_UPDATE_PAYMENT_STATUS = 'ACTION_REPORTS_UPDATE_PAYMENT_STATUS'
export const ACTION_REPORTS_UPDATE_SERVICE = 'ACTION_REPORTS_UPDATE_SERVICE'
export const ACTION_REPORTS_UPDATE_PRICE = 'ACTION_REPORTS_UPDATE_PRICE'
export const ACTION_REPORTS_UPDATE_INSURANCE = 'ACTION_REPORTS_UPDATE_INSURANCE'
export const ACTION_CHANGE_COLUMN_VISIBILITY = 'ACTION_CHANGE_COLUMN_VISIBILITY'
export const ACTION_MOVE_COLUMN = 'ACTION_MOVE_COLUMN'
export const ACTION_REPORTS_UPDATE_AMOUNT_PAID = 'ACTION_REPORTS_UPDATE_AMOUNT_PAID'
export const ACTION_REPORTS_UPDATE_EVENT_SERVICES = 'ACTION_REPORTS_UPDATE_EVENT_SERVICES'
