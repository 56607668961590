import { Quote } from '@/features/quotes/domain/quotes.interfaces'
import { GettersAdaptor } from '@/interfaces'

import { CellMap } from '../enums/quotes.enums'
import { QuoteHeader, QuoteRow, QuotesHeaderCustomPropsAllowed } from '../interfaces/quotesList.interfaces'
import { GetterTypes, QuotesGetters, QuotesState } from './types'

const DEFAULT_COMPONENT = 'DefaultQuoteCell'

function buildRows(rows: Quote[], headers: QuoteHeader[]): QuoteRow[] {
    return rows.map(data => {
        const cellsPerRow = headers.map(({ property }) => {
            return {
                value: data[property] !== undefined ? data[property] : null,
                data,
                component: CellMap[property as QuotesHeaderCustomPropsAllowed] || DEFAULT_COMPONENT
            }
        })

        return {
            rowId: data.id,
            cells: cellsPerRow,
            patientCanBeContacted: data.patientCanBeContacted,
            patientId: data.patientId
        }
    }, [])
}

export const getters: GettersAdaptor<QuotesGetters, QuotesState, any> = {
    [GetterTypes.GetRows]: ({ quotes, headers }) => buildRows(quotes, headers)
}
