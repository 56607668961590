export enum GetterTypes {
    GetCalendarSingleResourceWidth = 'getCalendarSingleResourceWidth',
    IsCalendarZoomDisabled = 'isCalendarZoomDisabled',
    IsCalendarZoomInDisabled = 'isCalendarZoomInDisabled',
    IsCalendarZoomOutDisabled = 'isCalendarZoomOutDisabled'
}

export type CalendarZoomGetters = {
    [GetterTypes.GetCalendarSingleResourceWidth]: number
    [GetterTypes.IsCalendarZoomDisabled]: boolean
    [GetterTypes.IsCalendarZoomInDisabled]: boolean
    [GetterTypes.IsCalendarZoomOutDisabled]: boolean
}
