export enum DocumentationTemplatesRouteNames {
    ClinicData = 'documentation-templates-clinic-data',
    Edit = 'documentation-templates-edit',
    Index = 'documentation-templates',
    List = 'documentation-templates-list',
    New = 'documentation-templates-new',
    View = 'documentation-templates-view'
}

export enum DocumentationGTemplatesRoutePaths {
    ClinicData = 'clinic-data',
    Edit = 'edit/:templateId',
    Index = 'documentation-templates',
    List = '/',
    New = 'new',
    View = ':userId/:templateId'
}

export enum DocumentationTemplatesQueryNames {
    OpenTab = 'open-tab'
}

export enum DocumentationTemplatesQueryValues {
    Files = 'files',
    Sharing = 'sharing',
    Templates = 'templates'
}
