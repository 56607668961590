export const ADMIN_IMPORT_SET_FACILITY = 'ADMIN_IMPORT_SET_FACILITY'
export const ADMIN_IMPORT_SET_VALIDATION_DATA = 'ADMIN_IMPORT_SET_VALIDATION_DATA'
export const ADMIN_IMPORT_RESET = 'ADMIN_IMPORT_RESET'
export const ADMIN_IMPORT_SET_FIELDS = 'ADMIN_IMPORT_SET_FIELDS'
export const ADMIN_IMPORT_SET_SERVICES_FIELDS = 'ADMIN_IMPORT_SET_SERVICES_FIELDS'

export const ADMIN_IMPORT_SET_PATIENTS_FILE_PREVIEW = 'ADMIN_IMPORT_SET_PATIENTS_FILE_PREVIEW'
export const ADMIN_IMPORT_SET_PATIENTS_FILE = 'ADMIN_IMPORT_SET_PATIENTS_FILE'
export const ADMIN_IMPORT_SET_PATIENTS_MAPPING = 'ADMIN_IMPORT_SET_PATIENTS_MAPPING'

export const ADMIN_IMPORT_SET_EHRS_FILE_PREVIEW = 'ADMIN_IMPORT_SET_EHRS_FILE_PREVIEW'
export const ADMIN_IMPORT_SET_EHRS_FILE = 'ADMIN_IMPORT_SET_EHRS_FILE'
export const ADMIN_IMPORT_SET_EHRS_MAPPING = 'ADMIN_IMPORT_SET_EHRS_MAPPING'

export const ADMIN_IMPORT_SET_EVENTS_FILE_PREVIEW = 'ADMIN_IMPORT_SET_EVENTS_FILE_PREVIEW'
export const ADMIN_IMPORT_SET_EVENTS_FILE = 'ADMIN_IMPORT_SET_EVENTS_FILE'
export const ADMIN_IMPORT_SET_EVENTS_MAPPING = 'ADMIN_IMPORT_SET_EVENTS_MAPPING'

export const ADMIN_IMPORT_SET_SERVICES_FILE = 'ADMIN_IMPORT_SET_SERVICES_FILE'
export const ADMIN_IMPORT_REMOVE_SERVICES_FILE = 'ADMIN_IMPORT_REMOVE_SERVICES_FILE'
export const ADMIN_IMPORT_SET_SERVICES_FILE_PREVIEW = 'ADMIN_IMPORT_SET_SERVICES_FILE_PREVIEW'
export const ADMIN_IMPORT_SET_SERVICES_MAPPING = 'ADMIN_IMPORT_SET_SERVICES_MAPPING'
export const ADMIN_IMPORT_SET_SERVICES_IMPORT_UNIQUE_ID = 'ADMIN_IMPORT_SET_SERVICES_IMPORT_UNIQUE_ID'
export const ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE = 'ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE'
export const ADMIN_IMPORT_REMOVE_SERVICE_SCHEDULE_FILE = 'ADMIN_IMPORT_REMOVE_SERVICE_SCHEDULE_FILE'
export const ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE_PREVIEW = 'ADMIN_IMPORT_SET_SERVICE_SCHEDULE_FILE_PREVIEW'
export const ADMIN_IMPORT_SET_SERVICE_SCHEDULE_MAPPING = 'ADMIN_IMPORT_SET_SERVICE_SCHEDULE_MAPPING'
