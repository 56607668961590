import {
    getCurrentMonth,
    getCurrentWeek,
    getCurrentYear,
    getLast12Months,
    getLastMonth,
    getLastWeek,
    getLastYearPeriodRange,
    getPreviousPeriodRange
} from '@/core/ui/utils/date'

export const LAST_TWELVE_MONTHS = 'last-twelve-months'
export const CURRENT_YEAR = 'current-year'
export const CURRENT_MONTH = 'current-month'
export const CURRENT_WEEK = 'current-week'
export const LAST_MONTH = 'last-month'
export const LAST_WEEK = 'last-week'
export const CUSTOM_RANGE = 'custom-range'

export const RANGE_OPTIONS = [
    {
        id: LAST_TWELVE_MONTHS,
        label: 'visits-report-filter-date-last-twelve-months',
        getFilterRangeAndComparison: getLast12Months,
        trackingName: 'last12Months'
    },
    {
        id: CURRENT_YEAR,
        label: 'invoice-table-filter-date-choose-current-year',
        getFilterRangeAndComparison: getCurrentYear,
        trackingName: 'currentYear'
    },
    {
        id: CURRENT_MONTH,
        label: 'visits-report-filter-date-current-month',
        getFilterRangeAndComparison: getCurrentMonth,
        trackingName: 'thisMonth'
    },
    {
        id: CURRENT_WEEK,
        label: 'visits-report-filter-date-current-week',
        getFilterRangeAndComparison: getCurrentWeek,
        trackingName: 'thisWeek'
    },
    {
        id: LAST_MONTH,
        label: 'visits-report-filter-date-last-month',
        getFilterRangeAndComparison: getLastMonth,
        trackingName: 'lastMonth'
    },
    {
        id: LAST_WEEK,
        label: 'visits-report-filter-date-last-week',
        getFilterRangeAndComparison: getLastWeek,
        trackingName: 'lastWeek'
    },
    {
        id: CUSTOM_RANGE,
        label: 'visits-report-filter-date-date-range',
        trackingName: 'customPeriod'
    }
]

export const PREVIOUS_PERIOD = 'previous-period'
export const PREVIOUS_YEAR = 'previous-year'
export const CUSTOM_COMPARISON = 'custom-comparison'

export const COMPARE_OPTIONS = [
    {
        id: PREVIOUS_PERIOD,
        label: 'visits-report-filter-date-compare-previous-period',
        getFilterRangeAndComparison: getPreviousPeriodRange,
        trackingName: 'previousPeriod'
    },
    {
        id: PREVIOUS_YEAR,
        label: 'visits-report-filter-date-compare-previous-year',
        getFilterRangeAndComparison: getLastYearPeriodRange,
        trackingName: 'lastYear'
    },
    {
        id: CUSTOM_COMPARISON,
        label: 'visits-report-filter-date-date-range',
        trackingName: 'customPeriod'
    }
]
