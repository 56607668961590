import {
    capitalize,
    digitsOnly,
    lettersOnly,
    numbersOnly,
    removeLeadingAndTradingSpaces,
    threeDecimals,
    toUppercase
} from '../utils/autoFormatters'
import { AutoFormatter } from '.'
import { AutoFormatNames } from './autoFormat.enum'

export const FORM_BLUEPRINTS = 'form-blueprints'

export const AUTO_FORMATTERS: Record<AutoFormatNames, AutoFormatter> = {
    [AutoFormatNames.ToUppercase]: toUppercase,
    [AutoFormatNames.NoLeadingOrTrailingSpaces]: removeLeadingAndTradingSpaces,
    [AutoFormatNames.Capitalize]: capitalize,
    [AutoFormatNames.LettersOnly]: lettersOnly,
    [AutoFormatNames.NumbersOnly]: numbersOnly,
    [AutoFormatNames.ThreeDecimals]: threeDecimals,
    [AutoFormatNames.DigitsOnly]: digitsOnly
}
