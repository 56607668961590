export enum PaymentStatus {
    Unpaid = 0,
    Paid = 1,
    Refunded = 2,
    RefundedFailed = 3,
    PartiallyPaid = 4
}

export enum OnlinePaymentStatus {
    Paid = 1,
    RefundFailed = 3
}
