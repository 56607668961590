import { post } from '@/features/payments/api/agent'
import { PaymentsReportsData } from '@/features/payments/domain/interfaces/reports.interfaces'
import { ReportsParameters } from '@/features/reports/domain/interfaces/reports.interfaces'

const URL_BASE_REPORTS = 'reports'
const URL_REPORTS_DATA = `${URL_BASE_REPORTS}/data`

class PaymentsReportsRepositoryApi {
    async getData(args: ReportsParameters) {
        return post<PaymentsReportsData, ReportsParameters>(URL_REPORTS_DATA, args)
    }
}

export default new PaymentsReportsRepositoryApi()
