export const NAME_PREFIX = 'dp-storage-'
const storage = window.localStorage

const getParamName = name => NAME_PREFIX + name

const get = name => JSON.parse(storage.getItem(getParamName(name)))

const set = (name, value) => {
    try {
        storage.setItem(getParamName(name), JSON.stringify(value))
    } catch (e) {
        storage.clear()
    }
    return !!get(getParamName(name))
}

const remove = name => storage.removeItem(getParamName(name))

const clear = () => {
    Object.keys(storage).forEach(element => {
        if (element.indexOf(NAME_PREFIX) === 0) {
            const elementInstance = element.split(NAME_PREFIX)[1]
            remove(elementInstance)
        }
    })
}

export default {
    get,
    set,
    remove,
    clear,
    getParamName
}
