/* eslint-disable simple-import-sort/imports */
import Vue from 'vue'
import Vuex from 'vuex'

import { PRODUCTION } from '@/bootstrap/environment'
import admin from '@/features/admin/ui/store'
import { MODULE_ADMIN } from '@/features/admin/ui/store/types/module'
import auth from '@/features/auth/ui/store'
import calendar from '@/features/calendar/ui/store'

import calendarPlugin from '@/features/calendar/ui/store/plugin'
import saveCalendarFiltersPlugin from '@/features/calendar/ui/store/saveCalendarFiltersPlugin'
import calendarSecretaryView from '@/features/calendar/ui/store/secretaryView'
import { MODULE_CALENDAR_SECRETARY_VIEW } from '@/features/calendar/ui/store/secretaryView/types'
import { MODULE_CALENDAR } from '@/features/calendar/ui/store/types'
import campaigns from '@/features/campaigns/ui/store'
import { MODULE_CAMPAIGNS } from '@/features/campaigns/ui/store/types/module'
import chat from '@/features/chat/ui/store'
import request from '@/features/chat/ui/store/request'
import { MODULE_REQUEST } from '@/features/chat/ui/store/request/types/module'
import chatSession from '@/features/chat/ui/store/session'
import { MODULE_CHAT_SESSION } from '@/features/chat/ui/store/session/types/module'
import { MODULE_CHAT } from '@/features/chat/ui/store/types/module'
import hints from '@/features/hints/ui/store'
import { MODULE_HINTS } from '@/features/hints/ui/store/types'
import invoice from '@/features/invoice/ui/store'
import { MODULE_INVOICE } from '@/features/invoice/ui/store/types/module'
import patient from '@/features/patient/ui/store'
import { MODULE_PATIENT } from '@/features/patient/ui/store/types/module'
import pms from '@/features/pms/ui/store'
import { MODULE_PMS } from '@/features/pms/ui/store/types/module'
import privacyPolicy from '@/features/privacyPolicy/ui/store'
import { MODULE_PRIVACY_POLICY } from '@/features/privacyPolicy/ui/store/types/module'
import quotes from '@/features/quotes/ui/store'
import { MODULE_QUOTES } from '@/features/quotes/ui/store/types/module'
import reports from '@/features/reports/ui/store'
import { MODULE_REPORTS } from '@/features/reports/ui/store/types/module'
import printLayouts from '@/features/settings/ui/store/printLayouts'
import { MODULE_PRINT_LAYOUTS } from '@/features/settings/ui/store/printLayouts/types/module'
import userSettings from '@/features/settings/ui/store/users'
import { MODULE_SETTINGS_USERS } from '@/features/settings/ui/store/users/types/module'
import voucher from '@/features/voucher/ui/store'
import { MODULE_VOUCHER } from '@/features/voucher/ui/store/types/module'
import booking from '@/features/booking/ui/store'
import { MODULE_BOOKING } from '@/features/booking/ui/store/types/module'
import { MODULE_ROOMS } from '@/features/rooms/ui/store/types/module'
import rooms from '@/features/rooms/ui/store'
import { MODULE_DISABLE_MESSAGE_NOTIFICATIONS_SETTINGS } from '@/features/settings/ui/store/disableMessagesNotifications/types/module'
import disableMessageNotifications from '@/features/settings/ui/store/disableMessagesNotifications'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)

const modules = {
    auth,
    [MODULE_SETTINGS_USERS]: userSettings,
    [MODULE_CHAT]: chat,
    [MODULE_DISABLE_MESSAGE_NOTIFICATIONS_SETTINGS]: disableMessageNotifications,
    [MODULE_CHAT_SESSION]: chatSession,
    [MODULE_REQUEST]: request,
    [MODULE_HINTS]: hints,
    [MODULE_INVOICE]: invoice,
    [MODULE_PMS]: pms,
    [MODULE_PRINT_LAYOUTS]: printLayouts,
    [MODULE_PATIENT]: patient,
    [MODULE_REPORTS]: reports,
    [MODULE_CAMPAIGNS]: campaigns,
    [MODULE_ADMIN]: admin,
    [MODULE_CALENDAR]: calendar,
    [MODULE_CALENDAR_SECRETARY_VIEW]: calendarSecretaryView,
    [MODULE_VOUCHER]: voucher,
    [MODULE_PRIVACY_POLICY]: privacyPolicy,
    [MODULE_QUOTES]: quotes,
    [MODULE_BOOKING]: booking,
    [MODULE_ROOMS]: rooms
}

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== PRODUCTION,
    state,
    getters,
    mutations,
    actions,
    modules,
    plugins: [calendarPlugin, saveCalendarFiltersPlugin]
})
