const mapSettingsResponse = (data: boolean | string): boolean | string => {
    if (!data) {
        return false
    }

    if (typeof data === 'boolean') {
        return data
    }

    if (typeof data === 'string') {
        const lowerData = data.toLowerCase()
        if (lowerData === 'true' || lowerData === 'false') {
            return lowerData === 'true'
        }
    }

    return data
}

export default mapSettingsResponse
