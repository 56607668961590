import Vue from 'vue'

import { getDefaultState } from '@/features/campaigns/ui/store/state'

import {
    MUTATION_CAMPAIGNS_ADD_ROW_SELECTED,
    MUTATION_CAMPAIGNS_APPLY_FILTER,
    MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED,
    MUTATION_CAMPAIGNS_PREVIEW_SET,
    MUTATION_CAMPAIGNS_PREVIEW_UPDATE,
    MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED,
    MUTATION_CAMPAIGNS_RESET_STATE,
    MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS,
    MUTATION_CAMPAIGNS_SET_PAGE_NUMBER,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE,
    MUTATION_CAMPAIGNS_SET_SELECTED_ROWS,
    MUTATION_CAMPAIGNS_SET_TYPE,
    MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED
} from './types/mutations'

const mutations = {
    [MUTATION_CAMPAIGNS_RESET_STATE](state) {
        Object.assign(state, getDefaultState())
    },
    [MUTATION_CAMPAIGNS_SET_PAGE_NUMBER](state, payload) {
        state.recipients.filters.pageNumber = payload
    },
    [MUTATION_CAMPAIGNS_SET_TYPE](state, payload) {
        state.campaignTypeName = payload
        state.recipients.filters.campaignType = payload
    },
    [MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS](state, payload) {
        state.recipients.headers = payload
    },
    [MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS](state, payload) {
        state.recipients.rows = payload
    },
    [MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE](state, payload) {
        const {
            data: {
                recipientsIds,
                recipientsPreview: { pageNumber, pageSize, pagesAmount }
            },
            nextPage
        } = payload

        Vue.set(state, 'recipients', {
            ...state.recipients,
            rowsIds: recipientsIds,
            ...(!nextPage ? { rowsSelected: recipientsIds } : {}),
            rowsTotal: recipientsIds.length,
            filters: {
                ...state.recipients.filters,
                pageNumber,
                pageSize,
                pagesAmount
            }
        })
    },
    [MUTATION_CAMPAIGNS_ADD_ROW_SELECTED](state, idSelected) {
        state.recipients.rowsSelected = [...state.recipients.rowsSelected, idSelected]
    },
    [MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED](state, idSelected) {
        state.recipients.rowsSelected = state.recipients.rowsSelected.filter(id => id !== idSelected)
    },
    [MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED](state, shouldSelectAll = false) {
        state.recipients.rowsSelected = shouldSelectAll ? state.recipients.rowsIds : []
    },
    [MUTATION_CAMPAIGNS_SET_SELECTED_ROWS](state, newSelectedRowsIds) {
        state.recipients.rowsSelected = newSelectedRowsIds
    },
    [MUTATION_CAMPAIGNS_APPLY_FILTER](state, payload) {
        state.recipients.filters = {
            ...state.recipients.filters,
            ...payload
        }
    },
    [MUTATION_CAMPAIGNS_PREVIEW_SET](state, payload) {
        state.preview = payload
    },
    [MUTATION_CAMPAIGNS_PREVIEW_UPDATE](state, payload) {
        state.preview = {
            ...state.preview,
            ...payload
        }
    },
    [MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED](state) {
        state.isRecipientsVisited = true
    },
    [MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT](state, recipientsLimit) {
        if (recipientsLimit >= state.recipients.rowsIds.length) {
            state.recipients.rowsSelected = state.recipients.rowsIds
        } else {
            state.recipients.rowsSelected = state.recipients.rowsSelected.slice(0, recipientsLimit)
        }
    },
    [MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS](state, metrics) {
        state.monthlyCampaignMetrics = metrics
        state.isMonthlyCampaignMetricsUpdated = true
    }
}

export default mutations
