import { DefaultFailSafeStrategy } from '../../domain/interfaces/features.interfaces'

export const defaultFailSafeStrategyByCountry: DefaultFailSafeStrategy[] = [
    {
        country: 'ar',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'br',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.enriched',
            'post-office:reminders.enriched',
            'post-office:reschedule.enriched',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.aiassistant',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'cl',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.enriched',
            'post-office:reminders.enriched',
            'post-office:reschedule.enriched',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'co',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.enriched',
            'post-office:reminders.enriched',
            'post-office:reschedule.enriched',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'cz',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'de',
        features: ['saas:schedule.telemedicine.dp-video']
    },
    {
        country: 'es',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'it',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.aiassistant',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'mx',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.enriched',
            'post-office:reminders.enriched',
            'post-office:reschedule.enriched',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.aiassistant',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'pe',
        features: ['saas:calendar.bulk-operations', 'saas:schedule.telemedicine.dp-video']
    },
    {
        country: 'pl',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.aiassistant',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'pt',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:schedule.advance-appointment',
            'saas:schedule.telemedicine.dp-video'
        ]
    },
    {
        country: 'tr',
        features: [
            'integrations:plugin-manager',
            'post-office:confirmation.standard',
            'post-office:reminders.standard',
            'post-office:reschedule.standard',
            'saas:calendar.bulk-operations',
            'saas:campaigns.messages.limit-5000',
            'saas:patient.ehr.read',
            'saas:patient.ehr.write',
            'saas:schedule.advance-appointment'
        ]
    }
]
