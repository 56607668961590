export class ToggleStore {
    constructor() {
        this.toggles = {}
    }

    set(toggles) {
        this.toggles = toggles
    }

    get(key) {
        return this.toggles[key]
    }
}
