import { SAAS_NAVIGATION_CHANGED } from '@/core/ui/constants/navigation.constants'
import EventBus from '@/core/ui/libs/eventBus'
import { AuthMutationTypes } from '@/features/auth/ui/store/types'
import { CALENDAR_EVENTS_READY } from '@/features/calendar/ui/constants/events.constants'

// import { ACTION_FETCH_FREE_SLOTS_AVAILABLE_DAYS } from './types/actions'
import { MODULE_CALENDAR } from './types/module'
import { MUTATION_SET_CALENDAR_EVENTS } from './types/mutations'

export default store => {
    store.subscribe(({ type, payload }) => {
        switch (type) {
            case AuthMutationTypes.SetSaasNavigation:
                EventBus.emit(SAAS_NAVIGATION_CHANGED, payload)
                break
            case `${MODULE_CALENDAR}/${MUTATION_SET_CALENDAR_EVENTS}`:
                EventBus.emit(CALENDAR_EVENTS_READY, payload)
                break
            default:
                break
        }
    })
}
