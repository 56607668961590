import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { ehrTemplate } from '@/features/pms/domain/interfaces/ehrTemplates.interfaces'

const URL_BASE = 'healthrecords'

const URL_EHR_TEMPLATES = `${URL_BASE}/templates`
const URL_EHR_TEMPLATE = `${URL_EHR_TEMPLATES}/:templateId`
const URL_EHR_TEMPLATE_FIELDS = `${URL_EHR_TEMPLATES}/fields`
const URL_EHR_TEMPLATE_FILL_FORM = `${URL_EHR_TEMPLATES}/:templateId/fillForm`
const URL_EHR_SMART_PROPERTIES = `${URL_EHR_TEMPLATE_FIELDS}/smartproperties`

class EHRTemplatesRepository {
    getTemplates(): Promise<any> {
        return httpAPI.get(URL_EHR_TEMPLATES)
    }

    createTemplate(templateData: ehrTemplate): Promise<any> {
        return httpAPI.post(URL_EHR_TEMPLATES, templateData)
    }

    async getTemplate(templateId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_EHR_TEMPLATE,
            params: {
                templateId
            }
        })

        const { data: template } = await httpAPI.get(url)

        return template
    }

    updateTemplate(templateId: number, templateData: ehrTemplate): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_EHR_TEMPLATE,
            params: {
                templateId
            }
        })

        return httpAPI.put(url, templateData)
    }

    removeTemplate(templateId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_EHR_TEMPLATE,
            params: {
                templateId
            }
        })

        return httpAPI.delete(url)
    }

    async getTemplateForm(templateId: number, patientId: number, episodeId: number, eventId?: number): Promise<any> {
        const baseQueryString = { patientId, episodeId }
        const query = eventId ? { ...baseQueryString, eventId } : baseQueryString

        const url = getUrlFromTemplate({
            template: URL_EHR_TEMPLATE_FILL_FORM,
            params: {
                templateId
            },
            query
        })
        const { data: form } = await httpAPI.get(url)

        return form
    }

    async getSmartTextProperties() {
        const { data } = await httpAPI.get(URL_EHR_SMART_PROPERTIES)

        return data
    }
}
export default new EHRTemplatesRepository()
