import { DynamicFormFieldValidator } from '@lib/dynamicForm/interfaces/dynamic.form.interfaces'

import { InvoiceVatRate } from '@/features/invoice/domain/interfaces/invoices.interfaces'
import { PAYMENT_METHODS_NOT_ALLOWED } from '@/features/quotes/ui/constants/quotes.constants'

import QuotesRepository from '../api/quotes.api'
import { QuotesFiltersAddressOption } from '../ui/interfaces/quotesList.interfaces'
import {
    PaymentMethod,
    QuoteDetails,
    QuoteDetailsPayload,
    QuoteFile,
    QuoteList,
    QuoteSchedule,
    QuoteSendEmail,
    QuotesFilters
} from './quotes.interfaces'

class QuotesService {
    getQuotes(filters: QuotesFilters, page: number): Promise<QuoteList> {
        return QuotesRepository.getQuotes(filters, page)
    }

    getQuote(quoteId: number): Promise<QuoteDetails> {
        return QuotesRepository.getQuote(quoteId)
    }

    async updateApprovalStatus(quoteId: number, status: number): Promise<void> {
        await QuotesRepository.updateApprovalStatus(quoteId, status)
    }

    async deleteQuote(quoteId: number): Promise<void> {
        await QuotesRepository.deleteQuote(quoteId)
    }

    getQuoteFile(quoteId: number): Promise<QuoteFile> {
        return QuotesRepository.getQuoteFile(quoteId)
    }

    async getSchedulesAddress(): Promise<QuoteSchedule[]> {
        const schedules = await QuotesRepository.getSchedulesAddress()
        return schedules.map(schedule => ({
            ...schedule,
            label: schedule.displayName || schedule.name
        }))
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        const paymentMethods = await QuotesRepository.getPaymentMethods()
        return paymentMethods.filter(({ name }) => !PAYMENT_METHODS_NOT_ALLOWED.includes(name))
    }

    getQuoteNumber(): Promise<number> {
        return QuotesRepository.getQuoteNumber()
    }

    saveQuote(quote: QuoteDetailsPayload, quoteId?: number): Promise<QuoteDetails> {
        return QuotesRepository.saveQuote(quote, quoteId)
    }

    getVatRates(): Promise<InvoiceVatRate[]> {
        return QuotesRepository.getVatRates()
    }

    getQuoteMail(quoteId: number): Promise<QuoteSendEmail> {
        return QuotesRepository.getQuoteMail(quoteId)
    }

    async sendQuoteMail(quoteId: number, payload: QuoteSendEmail): Promise<void> {
        await QuotesRepository.sendQuoteMail(quoteId, payload)
    }

    getQuoteMailValidators(): Promise<DynamicFormFieldValidator[]> {
        return QuotesRepository.getQuoteMailValidators()
    }

    async getQuoteAddresses(): Promise<QuotesFiltersAddressOption[]> {
        const addresses = await QuotesRepository.getQuoteAddresses()
        return addresses.map(address => ({
            value: address,
            text: address
        }))
    }
}

export default new QuotesService()
