import { ref } from 'vue'

import { SetupGuideGroupTask } from '../../domain'

const activeTask = ref<string | null>(null)

export function useTaskCollapsable() {
    const setActiveTask = (id: string | null) => {
        activeTask.value = id
    }

    const toggleTaskCollapsable = (opens: boolean, { id }: SetupGuideGroupTask) => {
        if (opens) setActiveTask(id)
        else if (activeTask.value === id) setActiveTask(null)
    }

    return {
        activeTask,
        setActiveTask,
        toggleTaskCollapsable
    }
}
