export const ACTION_CAMPAIGNS_RESET_MODULE = 'ACTION_CAMPAIGNS_RESET_MODULE'
export const ACTION_CAMPAIGNS_SET_TYPE = 'ACTION_CAMPAIGNS_SET_TYPE'
export const ACTION_CAMPAIGNS_ADD_ROW_SELECTED = 'ACTION_CAMPAIGNS_ADD_ROW_SELECTED'
export const ACTION_CAMPAIGNS_REMOVE_ROW_SELECTED = 'ACTION_CAMPAIGNS_REMOVE_ROW_SELECTED'
export const ACTION_CAMPAIGNS_SET_SELECTED_ROWS = 'ACTION_CAMPAIGNS_SET_SELECTED_ROWS'
export const ACTION_CAMPAIGNS_TOGGLE_ROWS_SELECTED = 'ACTION_CAMPAIGNS_TOGGLE_ROWS_SELECTED'
export const ACTION_CAMPAIGNS_SET_RECIPIENTS_HEADERS = 'ACTION_CAMPAIGNS_SET_RECIPIENTS_HEADERS'
export const ACTION_CAMPAIGNS_SET_RECIPIENTS_TABLE = 'ACTION_CAMPAIGNS_SET_RECIPIENTS_TABLE'
export const ACTION_CAMPAIGNS_APPLY_FILTER = 'ACTION_CAMPAIGNS_APPLY_FILTER'
export const ACTION_CAMPAIGNS_SET_PREVIEW = 'ACTION_CAMPAIGNS_SET_PREVIEW'
export const ACTION_CAMPAIGNS_UPDATE_PREVIEW = 'ACTION_CAMPAIGNS_UPDATE_PREVIEW'
export const ACTION_CAMPAIGNS_SET_RECIPIENTS_VISITED = 'ACTION_CAMPAIGNS_SET_RECIPIENTS_VISITED'
export const ACTION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT = 'ACTION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT'

export const ACTION_CAMPAIGNS_SET_MONTHLY_METRICS = 'ACTION_CAMPAIGNS_SET_MONTHLY_METRICS'
