import { ROUTE_ROOM_NAME, ROUTE_ROOM_PATH, ROUTE_ROOMS_NAME, ROUTE_ROOMS_PATH } from '../constants/routes.constants'

const RoomsList = () => import(/* webpackChunkName: "rooms-list" */ '@/features/rooms/ui/views/RoomsList.vue')
const RoomsDetail = () => import(/* webpackChunkName: "rooms-item" */ '@/features/rooms/ui/views/RoomsDetail.vue')

export default [
    {
        path: ROUTE_ROOMS_PATH,
        name: ROUTE_ROOMS_NAME,
        component: RoomsList,
        meta: { title: 'rooms-general-settings-title', uninavName: 'settings' }
    },
    {
        path: ROUTE_ROOM_PATH,
        name: ROUTE_ROOM_NAME,
        component: RoomsDetail,
        meta: { title: 'rooms-general-settings-title', uninavName: 'settings' },
        props: true
    }
]
