import { CalendarPerformanceSurveyMutations, MutationTypes } from './types/mutations'

export const mutations: CalendarPerformanceSurveyMutations = {
    [MutationTypes.StartMeasurementPeriod](state) {
        state.isCalendarPerformanceSurveyMeasurementPeriodActive = true
    },
    [MutationTypes.FinishMeasurementPeriod](state) {
        state.isCalendarPerformanceSurveyMeasurementPeriodActive = false
        state.calendarPerformanceSurveyMeasures = []
    },
    [MutationTypes.AddPerformanceMeasure](state, newMeasure) {
        state.calendarPerformanceSurveyMeasures.push(newMeasure)
    },
    [MutationTypes.ReplacePerformanceMeasures](state, measures) {
        state.calendarPerformanceSurveyMeasures = measures
    }
}
