import { DateUnit, endOf, startOfDate, subtractToDate } from '@/core/ui/utils'

import repository from '../api/events.api'
import { ListPageRequest } from '../api/interfaces/patient.interfaces'
import { EpisodeEvent, PatientEvent } from './interfaces/event.interface'

class EventsService {
    getEvents(page: ListPageRequest, patientId: string): Promise<EpisodeEvent[]> {
        return repository.getEvents(page, patientId)
    }

    getFutureEvents(page: ListPageRequest, patientId: string): Promise<EpisodeEvent[]> {
        return repository.getFutureEvents(page, patientId)
    }

    getNextEvent(patientId: string): Promise<PatientEvent> {
        return repository.getNextEvent(patientId)
    }

    async getNearestEventWithoutEpisode(patientId: string): Promise<PatientEvent> {
        const now = new Date()
        const startOfYesterday = startOfDate(subtractToDate(now, 1, DateUnit.Day))
        const endOfToday = endOf(now, DateUnit.Day)

        const events = await repository.getEventsForDates(
            patientId,
            startOfYesterday.toISOString(),
            endOfToday.toISOString()
        )

        return events.filter(event => event.episode === null)[0]
    }
}

export default new EventsService()
