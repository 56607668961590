import { Request, RequestCategoryDto } from '../../../../domain/interfaces/requests.interfaces'

export enum ActionTypes {
    FetchIsRequestFeatureEnabled = 'FETCH_IS_REQUEST_FEATURE_ENABLED',
    FetchRequests = 'REQUESTS',
    FetchRequestsIntegration = 'FETCH_REQUESTS_INTEGRATION',
    FetchUnreadRequestCount = 'FETCH_UNREAD_REQUEST_COUNT',
    FilteredRequests = 'FETCH_FILTERED_REQUESTS',
    MarkAsRead = 'MARK_AS_READ',
    RequestMoveTo = 'REQUEST_MOVE_TO',
    UpdateAllowRequestsFromNewPatients = 'UPDATE_ALLOW_REQUESTS_FROM_NEW_PATIENTS',
    UpdateIsRequestFeatureEnabled = 'UPDATE_IS_REQUEST_FEATURE_ENABLED',
    UpdateRequestCategories = 'UPDATE_REQUEST_CATEGORIES'
}

export type RequestActions = {
    [ActionTypes.UpdateAllowRequestsFromNewPatients]: (payload: {
        allowRequestsFromNewPatient: boolean
        docplannerDoctorId: string
        isRequestFeatureEnabled: boolean
    }) => Promise<void>
    [ActionTypes.FetchRequestsIntegration]: (payload: { docplannerDoctorId: string }) => Promise<void>
    [ActionTypes.FetchIsRequestFeatureEnabled]: (payload: { doctorId: string; userId?: string }) => Promise<void>
    [ActionTypes.FetchUnreadRequestCount]: (payload: { doctorId: string; userId?: string }) => Promise<void>
    [ActionTypes.RequestMoveTo]: (payload: {
        currentPage: number
        doctorId: string
        filterRequestType: string
    }) => Promise<void>
    [ActionTypes.FetchRequests]: (payload: {
        doctorId: string
        filterRequestType: string
        isAscending?: boolean
        pageNumber: number
        userId?: string
    }) => Promise<void>
    [ActionTypes.FilteredRequests]: (payload: {
        doctorId: string
        filterRequestType: string
        isAscending?: boolean
        pageNumber: number
        typeIds: number[]
        userId?: string
    }) => Promise<Request[]>
    [ActionTypes.MarkAsRead]: (payload: { doctorId: string; requestId: string; userId?: string }) => Promise<void>
    [ActionTypes.UpdateIsRequestFeatureEnabled]: (payload: { doctorId: string; isEnabled: boolean }) => Promise<void>
    [ActionTypes.UpdateRequestCategories]: (payload: {
        categories: RequestCategoryDto[]
        docplannerDoctorId: string
    }) => Promise<void>
}
