import { RoomsMutations, RoomsMutationTypes } from './types/mutations'

const mutations: RoomsMutations = {
    [RoomsMutationTypes.SetRooms](state, rooms) {
        state.rooms = rooms
    },
    [RoomsMutationTypes.SetIsLoading](state, isLoading) {
        state.isLoading = isLoading
    },
    [RoomsMutationTypes.DeleteRoom](state, room) {
        state.rooms = state.rooms.filter(currentRoom => currentRoom.id !== room.id)
    },
    [RoomsMutationTypes.CreateRoom](state, room) {
        state.rooms = [room, ...state.rooms]
    },
    [RoomsMutationTypes.UpdateRoom](state, room) {
        state.rooms = state.rooms.map(currentRoom => {
            if (currentRoom.id === room.id) {
                return {
                    ...currentRoom,
                    ...room
                }
            }
            return currentRoom
        })
    }
}

export default mutations
