import hasAccessToVouchersSettings from '@/features/voucher/ui/middleware/hasAccessToVouchersSettings.middleware'

import { VOUCHER_TYPE_DETAIL_ROUTE, VOUCHER_TYPE_SETTINGS_ROUTE } from '../constants/router.constants'

const VoucherSettings = () =>
    import(/* webpackChunkName: "voucher-settings" */ '@/features/voucher/ui/views/VoucherSettings.vue')
const VoucherTypeDetail = () =>
    import(/* webpackChunkName: "voucher-type-detail" */ '@/features/voucher/ui/views/VoucherTypeDetail.vue')

export default [
    {
        path: '/settings/voucher',
        name: VOUCHER_TYPE_SETTINGS_ROUTE,
        component: VoucherSettings,
        meta: {
            middleware: hasAccessToVouchersSettings
        }
    },
    {
        path: '/settings/voucher/:voucherTypeId',
        name: VOUCHER_TYPE_DETAIL_ROUTE,
        component: VoucherTypeDetail,
        meta: {
            middleware: hasAccessToVouchersSettings
        }
    }
]
