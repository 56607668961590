import { CalendarDensityType } from '@/features/calendar/domain/enums/calendar.enums'

export const CalendarDensity = [
    {
        type: CalendarDensityType.Compact,
        name: 'general-settings-calendar-density-option-compact',
        size: 64
    },
    {
        type: CalendarDensityType.Default,
        name: 'general-settings-calendar-density-option-default',
        size: 96
    },
    {
        type: CalendarDensityType.Comfortable,
        name: 'general-settings-calendar-density-option-comfortable',
        size: 224
    }
]

export const DENSITY_DEFAULT = CalendarDensityType.Default
