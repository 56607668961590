import { getUrlFromTemplate } from '@dp-vue/utils'
import { DynamicFormFieldValidator } from '@lib/dynamicForm/interfaces/dynamic.form.interfaces'
import { AxiosRequestConfig } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import {
    Voucher,
    VoucherFile,
    VoucherSendEmail,
    VouchersFilters,
    VouchersResponse
} from '@/features/voucher/domain/interfaces/voucher.interfaces'

import { VoucherPaymentStatus } from '../ui/constants/voucher.enum'

const URL_BASE = 'vouchers'

const URL_ALL = `${URL_BASE}/all`
const URL_MAIL_VALIDATORS = `${URL_BASE}/mail/validators`

const URL_VOUCHER_ID = `${URL_BASE}/:voucherId`
const URL_CHANGE_VOUCHER_PAYMENT_STATUS = `${URL_VOUCHER_ID}/payment-status`
const URL_LINK_PATIENTS = `${URL_VOUCHER_ID}/link-patients`
const URL_EXPIRATION_DATE = `${URL_VOUCHER_ID}/expiration-date`
const URL_VOUCHER_AMOUNT_PAID = `${URL_VOUCHER_ID}/amount-paid`
const URL_VOUCHER_PDF = `${URL_VOUCHER_ID}/pdf`
const URL_PATCH_PRICE = `${URL_VOUCHER_ID}/price`
const URL_REFERENCE_ID = `${URL_VOUCHER_ID}/reference-id`
const URL_MAIL = `${URL_VOUCHER_ID}/mail`

class VoucherRepository {
    async getAll(params: VouchersFilters, page: number): Promise<VouchersResponse> {
        const payload = { ...params, page }
        const { data } = await httpAPI.post(URL_ALL, payload)
        return data
    }

    async get(voucherId: number): Promise<Voucher> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_ID,
            params: { voucherId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async delete(voucherId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_ID,
            params: {
                voucherId
            }
        })

        const { data } = await httpAPI.delete(url)
        return data
    }

    changePaymentStatus(voucherId: number, paymentStatus: VoucherPaymentStatus): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_CHANGE_VOUCHER_PAYMENT_STATUS,
            params: {
                voucherId
            }
        })

        return httpAPI.patch(url, { paymentStatus })
    }

    async shareVoucher(voucherId: number, patientIds: number[]): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_LINK_PATIENTS,
            params: {
                voucherId
            }
        })
        const payload = { voucherId, patientIds }
        await httpAPI.put(url, payload)
    }

    setExpirationDate(voucherId: number, expirationDate: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_EXPIRATION_DATE,
            params: {
                voucherId
            }
        })
        const payload = { expirationDate }
        return httpAPI.patch(url, payload)
    }

    async updateAmountPaid(voucherId: number, amountPaid: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_AMOUNT_PAID,
            params: {
                voucherId
            }
        })

        return httpAPI.patch(url, { amountPaid })
    }

    async getVoucherFile(voucherId: number): Promise<VoucherFile> {
        const url = getUrlFromTemplate({
            template: URL_VOUCHER_PDF,
            params: {
                voucherId
            }
        })
        const config: AxiosRequestConfig = { responseType: 'blob' as const }
        return httpAPI.get(url, config)
    }

    async getVoucherMail(voucherId: number): Promise<VoucherSendEmail> {
        const url = getUrlFromTemplate({
            template: URL_MAIL,
            params: { voucherId }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    async sendVoucherMail(voucherId: number, payload: VoucherSendEmail): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_MAIL,
            params: { voucherId }
        })

        await httpAPI.post(url, payload)
    }

    updateVoucherPrice(voucherId: number, price: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_PATCH_PRICE,
            params: { voucherId }
        })
        return httpAPI.patch(url, { price })
    }

    updateVoucherReferenceId(voucherId: number, referenceId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REFERENCE_ID,
            params: { voucherId: voucherId.toString() }
        })
        return httpAPI.patch(url, { referenceId })
    }

    async getVoucherMailValidators(): Promise<DynamicFormFieldValidator[]> {
        const { data } = await httpAPI.get(URL_MAIL_VALIDATORS)
        return data
    }
}

export default new VoucherRepository()
