import CampaignsRecipientsService from '@/features/campaigns/domain/campaignsRecipients.service'

import CampaignsService from '../../domain/campaigns.service'
import {
    ACTION_CAMPAIGNS_ADD_ROW_SELECTED,
    ACTION_CAMPAIGNS_APPLY_FILTER,
    ACTION_CAMPAIGNS_REMOVE_ROW_SELECTED,
    ACTION_CAMPAIGNS_RESET_MODULE,
    ACTION_CAMPAIGNS_SET_MONTHLY_METRICS,
    ACTION_CAMPAIGNS_SET_PREVIEW,
    ACTION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT,
    ACTION_CAMPAIGNS_SET_RECIPIENTS_HEADERS,
    ACTION_CAMPAIGNS_SET_RECIPIENTS_TABLE,
    ACTION_CAMPAIGNS_SET_RECIPIENTS_VISITED,
    ACTION_CAMPAIGNS_SET_SELECTED_ROWS,
    ACTION_CAMPAIGNS_SET_TYPE,
    ACTION_CAMPAIGNS_TOGGLE_ROWS_SELECTED,
    ACTION_CAMPAIGNS_UPDATE_PREVIEW
} from './types/actions'
import { GETTER_CAMPAIGNS_RECIPIENTS_IS_TYPE_SMS } from './types/getters'
import {
    MUTATION_CAMPAIGNS_ADD_ROW_SELECTED,
    MUTATION_CAMPAIGNS_APPLY_FILTER,
    MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED,
    MUTATION_CAMPAIGNS_PREVIEW_SET,
    MUTATION_CAMPAIGNS_PREVIEW_UPDATE,
    MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED,
    MUTATION_CAMPAIGNS_RESET_STATE,
    MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS,
    MUTATION_CAMPAIGNS_SET_PAGE_NUMBER,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS,
    MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE,
    MUTATION_CAMPAIGNS_SET_SELECTED_ROWS,
    MUTATION_CAMPAIGNS_SET_TYPE,
    MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED
} from './types/mutations'

const actions = {
    [ACTION_CAMPAIGNS_RESET_MODULE]({ commit }) {
        commit(MUTATION_CAMPAIGNS_RESET_STATE)
    },
    [ACTION_CAMPAIGNS_SET_TYPE]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_SET_TYPE, payload)
    },
    async [ACTION_CAMPAIGNS_SET_RECIPIENTS_HEADERS]({ commit, getters, rootGetters }) {
        const { managesMedicalCenter, showInsuranceFilterInCampaigns } = rootGetters

        const headers = await CampaignsRecipientsService.buildRecipientsHeaders({
            isTypeSms: getters[GETTER_CAMPAIGNS_RECIPIENTS_IS_TYPE_SMS],
            managesMedicalCenter,
            showInsuranceFilterInCampaigns
        })

        commit(MUTATION_CAMPAIGNS_SET_RECIPIENTS_HEADERS, headers)
    },
    [ACTION_CAMPAIGNS_SET_RECIPIENTS_TABLE]({ commit, state }, payload) {
        const rows = CampaignsRecipientsService.buildRecipientsRows(payload.data, state.recipients.headers)

        commit(MUTATION_CAMPAIGNS_SET_RECIPIENTS_ROWS, rows)
        commit(MUTATION_CAMPAIGNS_SET_RECIPIENTS_TABLE, payload)
    },
    [ACTION_CAMPAIGNS_ADD_ROW_SELECTED]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_ADD_ROW_SELECTED, payload)
    },
    [ACTION_CAMPAIGNS_REMOVE_ROW_SELECTED]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_REMOVE_ROW_SELECTED, payload)
    },
    [ACTION_CAMPAIGNS_TOGGLE_ROWS_SELECTED]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_TOGGLE_ROWS_SELECTED, payload)
    },
    [ACTION_CAMPAIGNS_SET_SELECTED_ROWS]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_SET_SELECTED_ROWS, payload)
    },
    [ACTION_CAMPAIGNS_APPLY_FILTER]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_SET_PAGE_NUMBER, 1)
        commit(MUTATION_CAMPAIGNS_APPLY_FILTER, payload)
    },
    [ACTION_CAMPAIGNS_SET_PREVIEW]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_PREVIEW_SET, payload)
    },
    [ACTION_CAMPAIGNS_UPDATE_PREVIEW]({ commit }, payload) {
        commit(MUTATION_CAMPAIGNS_PREVIEW_UPDATE, payload)
    },
    [ACTION_CAMPAIGNS_SET_RECIPIENTS_VISITED]({ commit }) {
        commit(MUTATION_CAMPAIGNS_IS_RECIPIENTS_VISITED)
    },
    [ACTION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT]({ commit }, recipientsLimit) {
        commit(MUTATION_CAMPAIGNS_SET_RECIPIENTS_AT_AVAILABLE_LIMIT, recipientsLimit)
    },
    async [ACTION_CAMPAIGNS_SET_MONTHLY_METRICS]({ commit }) {
        const metrics = await CampaignsService.getMonthlyCampaignMetrics()
        commit(MUTATION_CAMPAIGNS_SET_MONTHLY_METRICS, metrics)
    }
}

export default actions
