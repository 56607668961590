import type { NavigationGuardNext, RouteRecord } from 'vue-router'
import VueRouter, { Route } from 'vue-router'

import {
    ROUTE_PAYMENTS_BASE_PATH,
    ROUTE_PAYMENTS_OVERVIEW_PATH,
    ROUTE_PAYMENTS_REQUESTS_NAME
} from '../../../constants'
import { usePaymentsProfile } from '../../composables/usePaymentsProfile'

const { isPaymentsRequestsEnabled, getPaymentsProfile } = usePaymentsProfile()

export const pathPermissions = [
    {
        names: [ROUTE_PAYMENTS_REQUESTS_NAME],
        shouldRedirect: () => !isPaymentsRequestsEnabled.value,
        redirectTo: ROUTE_PAYMENTS_OVERVIEW_PATH
    }
]

export default async ({ to, next }: { next: NavigationGuardNext; router: VueRouter; to: Route }) => {
    let nextLocation
    await getPaymentsProfile()
    pathPermissions.some(path => {
        const matchPath = to.matched.find(({ name }: RouteRecord) => name && path.names.includes(name))

        if (matchPath && path.shouldRedirect()) {
            nextLocation = `/${ROUTE_PAYMENTS_BASE_PATH}/${path.redirectTo}`
            return true
        }

        return false
    })

    if (nextLocation) {
        next(nextLocation)
    } else {
        next()
    }
}
