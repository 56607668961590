import { OutOfOfficeEvent } from '@/features/settings/ui/interfaces/disableNotifications.interfaces'
import { MultipleSchedule, Schedule } from '@/features/settings/ui/interfaces/doNotDisturbContent.interfaces'

export enum ActionTypes {
    AddSchedule = 'ADD_SCHEDULE',
    CreateOutOfOfficeEvent = 'CREATE_OUT_OF_OFFICE_EVENT',
    DeleteOutOfOfficeEvent = 'DElETE_OUT_OF_OFFICE_EVENT',
    DeleteSchedule = 'DElETE_SCHEDULE',
    FetchOutOfOfficeEvent = 'FETCH_OUT_OF_OFFICE_EVENT',
    FetchScheduleNotifications = 'FETCH_SCHEDULE_NOTIFICATIONS',
    UpdateEventStore = 'UPDATE_EVENT_STORE',
    UpdateOnEditMode = 'UPDATE_ON_EDIT_MODE',
    UpdateOutOfOfficeEvent = 'UPDATE_OUT_OF_OFFICE_EVENT',
    UpdateSchedule = 'UPDATE_SCHEDULE',
    UpdateSessionSchedule = 'UPDATE_SESSION_SCHEDULE'
}

export type MessagesNotificationsSettingsActions = {
    [ActionTypes.AddSchedule]: (schedule: Schedule | MultipleSchedule) => Promise<void>
    [ActionTypes.CreateOutOfOfficeEvent]: (payload: { event: OutOfOfficeEvent }) => Promise<void>
    [ActionTypes.DeleteOutOfOfficeEvent]: (payload: { eventId: number }) => Promise<void>
    [ActionTypes.DeleteSchedule]: () => Promise<void>
    [ActionTypes.FetchOutOfOfficeEvent]: () => Promise<void>
    [ActionTypes.FetchScheduleNotifications]: () => Promise<void>
    [ActionTypes.UpdateOnEditMode]: (payload: { isOnEditMode: boolean }) => Promise<void>
    [ActionTypes.UpdateOutOfOfficeEvent]: (payload: { event: OutOfOfficeEvent }) => Promise<void>
    [ActionTypes.UpdateEventStore]: (event: OutOfOfficeEvent | null) => Promise<void>
    [ActionTypes.UpdateSchedule]: (payload: { schedule: Schedule | MultipleSchedule }) => Promise<void>
    [ActionTypes.UpdateSessionSchedule]: (schedule: Schedule[]) => Promise<void>
}
