import { PatientType, RequestType } from '@/features/chat/domain/enums/requests.enums'

import { PatientTypeSetting } from '../../interfaces/patientTypes.interfaces'

export const TYPES_OF_PATIENTS: PatientTypeSetting = {
    New: {
        id: PatientType.Existing,
        settingLabel: 'messages-settings-page-categorised-requests-new-existing-answer1'
    },
    NewAndExisting: {
        id: PatientType.NewAndExisting,
        settingLabel: 'messages-settings-page-categorised-requests-new-existing-answer2'
    }
} as const
