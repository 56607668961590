export const isString = value => typeof value === 'string' || value instanceof String

export const capitalize = string => {
    if (typeof string !== 'string') return ''
    return string.toLowerCase().replace(/(^|[\s-])\S/g, function (match) {
        return match.toUpperCase()
    })
}

export const upperCaseFirstCharacter = string => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const lowerCaseFirstCharacter = string => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toLowerCase() + string.slice(1)
}

export const nameCapitalization = string => {
    if (typeof string !== 'string') return ''

    if (string !== string.toUpperCase() && string !== string.toLowerCase() && !string.includes('-')) {
        return string
    }

    return capitalize(string)
}

export const getInitials = (text, numberOfInitials) => {
    const splittedText = text.split(' ')
    const initials = []

    splittedText.forEach(name => {
        initials.push(name.substring(0, 1).toUpperCase())
    })

    if (initials.length < numberOfInitials) {
        numberOfInitials = initials.length
    }

    return initials.slice(0, numberOfInitials).join('')
}

export const truncate = (text, chars, exact = false) => {
    const omission = '...'

    if (!chars || text.length <= chars) {
        return text
    }

    chars = exact ? chars : Math.max(chars - omission.length, 0)

    return `${text.slice(0, chars).trim()}${omission}`
}

export const toString = text => {
    return typeof text !== 'undefined' ? `${text}` : ''
}

export const normalizeString = string => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const getStringWithoutExtension = string =>
    string.includes('.') ? string.split('.').slice(0, -1).join('.') : string

export const doesStringMatchWithCaseInsensitive = ({ filter, text }) => {
    return new RegExp(filter, 'i').test(text)
}

export const numberToString = (number, digits = 2) => {
    return number.toString().padStart(digits, 0)
}

export const oneLineString = string =>
    string
        .split(/(?:\r\n|\n|\r)/)
        .map(line => line.replace(/^\s+/gm, ''))
        .join('')
        .trim()

export const removeAllWhiteSpaces = string => string.replace(/\s+/g, '')
