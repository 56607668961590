import { PaymentStatus } from '@/enums/paymentStatus.enums'
import { EventStatus } from '@/features/calendar/domain/enums/eventStatus.enums'

import { SecretaryViewFilters } from '../../domain/interfaces/secretaryView.interfaces'
import {
    ActionBarActions,
    ActionBarActionsIds,
    ActionBarPatientEditErrorTranslationKeys,
    ActionBarPatientEditRuleNames,
    CheckinStatusMapTrackingEvents,
    CheckinStatusMapTranslationKeys,
    CheckinStatusValues,
    PaymentStatusTranslationKeys,
    StatusColors,
    VisitStatusCellTranslationKeys,
    VisitStatusTranslationKeys
} from '../enums/secretaryView.enums'
import { ActionBarAction } from '../interfaces/secretaryActionBar.interfaces'
import { CheckinStatusMap, PaymentStatusMap } from '../interfaces/secretaryView.interfaces'

export const CHECK_IN_STATUS_MAP: CheckinStatusMap[] = [
    {
        id: CheckinStatusValues.Scheduled,
        translationKey: CheckinStatusMapTranslationKeys.Scheduled,
        mixpanelEventName: CheckinStatusMapTrackingEvents.Scheduled,
        color: StatusColors.Gray300
    },
    {
        id: CheckinStatusValues.Waiting,
        translationKey: CheckinStatusMapTranslationKeys.WaitingRoom,
        mixpanelEventName: CheckinStatusMapTrackingEvents.WaitingRoom,
        color: StatusColors.Calm
    },
    {
        id: CheckinStatusValues.Visiting,
        translationKey: CheckinStatusMapTranslationKeys.Visiting,
        mixpanelEventName: CheckinStatusMapTrackingEvents.Visiting,
        color: StatusColors.Warning
    },
    {
        id: CheckinStatusValues.Visited,
        translationKey: CheckinStatusMapTranslationKeys.Visited,
        mixpanelEventName: CheckinStatusMapTrackingEvents.Visited,
        color: StatusColors.Success
    },
    {
        id: CheckinStatusValues.NoShowedUp,
        translationKey: CheckinStatusMapTranslationKeys.NoShowedUp,
        mixpanelEventName: CheckinStatusMapTrackingEvents.NoShowedUp,
        color: StatusColors.Danger
    },
    {
        id: CheckinStatusValues.Canceled,
        translationKey: CheckinStatusMapTranslationKeys.Canceled,
        mixpanelEventName: CheckinStatusMapTrackingEvents.Canceled,
        color: StatusColors.Gray300
    }
]

export const VISIT_STATUS_FILTERS_MAP = [
    {
        id: EventStatus.Scheduled,
        translationKey: VisitStatusTranslationKeys.Scheduled
    },
    {
        id: EventStatus.CanceledByUser,
        translationKey: VisitStatusTranslationKeys.CanceledByDoctor
    },
    {
        id: EventStatus.CanceledByPatient,
        translationKey: VisitStatusTranslationKeys.CanceledByPatient
    },
    {
        id: EventStatus.WaitingForConfirmation,
        translationKey: VisitStatusTranslationKeys.ConfirmationSent
    },
    {
        id: EventStatus.ConfirmedByPatient,
        translationKey: VisitStatusTranslationKeys.ConfirmedByPatient
    },
    {
        id: EventStatus.ConfirmedByAdmin,
        translationKey: VisitStatusTranslationKeys.ConfirmedByAdmin
    }
]

export const VISIT_STATUS_CELL_MAP = [
    {
        id: EventStatus.Scheduled,
        translationKey: VisitStatusCellTranslationKeys.Scheduled
    },
    {
        id: EventStatus.CanceledByUser,
        translationKey: VisitStatusCellTranslationKeys.Canceled
    },
    {
        id: EventStatus.CanceledByPatient,
        translationKey: VisitStatusCellTranslationKeys.Canceled
    },
    {
        id: EventStatus.WaitingForConfirmation,
        translationKey: VisitStatusCellTranslationKeys.ConfirmationSent
    },
    {
        id: EventStatus.ConfirmedByPatient,
        translationKey: VisitStatusCellTranslationKeys.Confirmed
    },
    {
        id: EventStatus.ConfirmedByAdmin,
        translationKey: VisitStatusCellTranslationKeys.Confirmed
    },
    {
        id: EventStatus.CanceledByTechnicalIssue,
        translationKey: VisitStatusCellTranslationKeys.Canceled
    }
]

export const PAYMENT_STATUS_MAP = {
    [PaymentStatus.Unpaid]: {
        value: PaymentStatus.Unpaid,
        translationKey: PaymentStatusTranslationKeys.Unpaid,
        color: StatusColors.Gray300
    },
    [PaymentStatus.Paid]: {
        value: PaymentStatus.Paid,
        translationKey: PaymentStatusTranslationKeys.Paid,
        color: StatusColors.Success
    },
    [PaymentStatus.Refunded]: {
        value: PaymentStatus.Refunded,
        translationKey: PaymentStatusTranslationKeys.Refunded,
        color: StatusColors.Gray300
    },
    [PaymentStatus.RefundedFailed]: {
        value: PaymentStatus.RefundedFailed,
        translationKey: PaymentStatusTranslationKeys.RefundedFailed,
        color: StatusColors.Gray300
    },
    [PaymentStatus.PartiallyPaid]: {
        value: PaymentStatus.PartiallyPaid,
        translationKey: PaymentStatusTranslationKeys.PartiallyPaid,
        color: StatusColors.Gray300
    }
}

export const MIN_COLUMNS = 1

export const FILTERS_INITIAL_VALUES: SecretaryViewFilters = {
    doctors: [],
    bookingStatus: [],
    bookingAttendance: [],
    patientData: '',
    paymentStatus: [],
    schedules: []
}

export const UPDATE_COMMENTS_DEBOUNCE = 500

export const COMMENTS_SAVE_TIMEOUT = 3000

export const PATIENT_DATA_SAVE_TIMEOUT = 3000

export const SAVING_CHANGES_EVENT = 'saving-changes'

export const ACTION_BAR_ACTIONS: ActionBarAction[] = [
    {
        id: ActionBarActionsIds.UpdatePatientData,
        translationKey: 'action-bar-header-actions-update-data',
        component: ActionBarActions.UpdatePatientData,
        errorTranslationKey: 'action-bar-header-actions-update-data-hover',
        testId: 'actionbar-menu-update-patient-data'
    },
    {
        id: ActionBarActionsIds.Payment,
        translationKey: 'action-bar-header-actions-collect-payment',
        component: ActionBarActions.Payment,
        testId: 'actionbar-menu-register-payment'
    },
    {
        id: ActionBarActionsIds.Invoices,
        translationKey: 'action-bar-header-actions-issue-invoice',
        component: ActionBarActions.Invoices,
        testId: 'actionbar-menu-issue-invoice'
    }
]

export const ACTION_BAR_ACTION_SELECTED_COMMAND = 'set-component'

export const ACTION_BAR_UPDATE_PATIENT_NAME_MAX_LENGTH = 90
export const ACTION_BAR_UPDATE_PATIENT_PHONE_MAX_LENGTH = 35

export const ACTION_BAR_UPDATE_PATIENT_ERROR_MESSAGES = {
    firstName: [
        {
            name: ActionBarPatientEditRuleNames.Required,
            errorMessage: ActionBarPatientEditErrorTranslationKeys.RequiredField
        },
        {
            name: ActionBarPatientEditRuleNames.MaxLength,
            errorMessage: ActionBarPatientEditErrorTranslationKeys.FirstNameMaxLength
        }
    ],
    lastName: [
        {
            name: ActionBarPatientEditRuleNames.Required,
            errorMessage: ActionBarPatientEditErrorTranslationKeys.RequiredField
        },
        {
            name: ActionBarPatientEditRuleNames.MaxLength,
            errorMessage: ActionBarPatientEditErrorTranslationKeys.LastNameMaxLength
        }
    ],
    phone: [
        {
            name: ActionBarPatientEditRuleNames.MaxLength,
            errorMessage: ActionBarPatientEditErrorTranslationKeys.PhoneMaxLength
        }
    ]
}

export const ACTION_BAR_WIDTH = 300

export const PATIENT_CHAT_POSITION = {
    initial: 0,
    withActionBarOpen: ACTION_BAR_WIDTH
}
