import { CampaignsProperty } from '@/features/campaigns/domain/enums/campaigns.enums'
import { CampaignsCols } from '@/features/campaigns/domain/interfaces/campaigns.interfaces'

export const CAMPAIGNS_FEATURE_NAME = 'campaigns'

export const CAMPAIGNS_COLS: CampaignsCols = [
    {
        property: CampaignsProperty.CampaignType,
        name: 'campaigns-one-time-dashboard-type'
    },
    {
        property: CampaignsProperty.SendDate,
        name: 'campaigns-one-time-dashboard-send-date'
    },
    {
        property: CampaignsProperty.Title,
        name: 'campaigns-one-time-dashboard-message'
    },
    {
        property: CampaignsProperty.Recipients,
        name: 'campaigns-progress-bar-recipients'
    },
    {
        property: CampaignsProperty.Delivered,
        name: 'campaigns-post-landing-delivered',
        tooltip: 'campaigns-post-landing-delivered-tooltip'
    },
    {
        property: CampaignsProperty.Opened,
        name: 'campaigns-post-landing-opened'
    },
    {
        property: CampaignsProperty.Clicked,
        name: 'campaigns-post-landing-clicked'
    },
    {
        property: CampaignsProperty.AppointmentBooked,
        name: 'one-time-campaigns-stats-campaign-patients-label',
        tooltip: 'one-time-campaigns-stats-direct-patients-tooltip'
    },
    {
        property: CampaignsProperty.HasOtherBookingNotFromCampaign,
        name: 'one-time-campaigns-stats-indirect-patients-label',
        tooltip: 'one-time-campaigns-stats-indirect-patients-tooltip'
    }
]

export const CAMPAIGNS_CELL_TYPE = 'CampaignsCellType'
export const CAMPAIGNS_CELL_SEND_DATE = 'CampaignsCellSendDate'
export const CAMPAIGNS_CELL_TITLE = 'CampaignsCellTitle'
export const CAMPAIGNS_CELL_VALUE = 'CampaignsCellValue'
export const CAMPAIGNS_CELL_TEXT = 'CampaignsCellText'
export const CAMPAIGNS_CELL_OTHER_BOOKING_NOT_FROM_CAMPAIGN = 'CampaignsCellOtherBookingNotFromCampaign'

export const CAMPAIGNS_CELL_COMPONENTS = {
    campaignType: CAMPAIGNS_CELL_TYPE,
    sendDate: CAMPAIGNS_CELL_SEND_DATE,
    title: CAMPAIGNS_CELL_TITLE,
    recipients: CAMPAIGNS_CELL_VALUE,
    delivered: CAMPAIGNS_CELL_VALUE,
    opened: CAMPAIGNS_CELL_VALUE,
    clicked: CAMPAIGNS_CELL_VALUE,
    appointmentBooked: CAMPAIGNS_CELL_VALUE,
    hasOtherBookingNotFromCampaign: CAMPAIGNS_CELL_OTHER_BOOKING_NOT_FROM_CAMPAIGN
}

export const CAMPAIGNS_MODAL_CONFIRMATION = 'CAMPAIGNS_MODAL_CONFIRMATION'
export const CAMPAIGNS_MODAL_PREVIEW = 'CAMPAIGNS_MODAL_PREVIEW'
export const CAMPAIGNS_MODAL_SELF_TEST = 'CAMPAIGNS_MODAL_SELF_TEST'

export const CAMPAIGNS_MODAL_EXCEEDED_RECIPIENT_NUMBER = 'CAMPAIGNS_MODAL_EXCEEDED_RECIPIENT_NUMBER'

export const CAMPAIGNS_WIZARD_ENABLE = 'next-step-enable'
export const CAMPAIGNS_WIZARD_NEXT_STEP_CLICK = 'next-step-click'

export const CAMPAIGNS_MAX_LINK_LENGTH = 36
export const CAMPAIGNS_SENDER_MIN_LENGTH = 20
export const CAMPAIGNS_MAX_MESSAGE_LENGTH = 158 - CAMPAIGNS_MAX_LINK_LENGTH
export const CAMPAIGNS_MIN_MESSAGE_LENGTH = 15
export const CAMPAIGNS_MAX_EMAIL_LENGTH = 4000 - CAMPAIGNS_MAX_LINK_LENGTH

export const CAMPAIGNS_TRACKING_CLONE_SOURCE = 'clone_source'
