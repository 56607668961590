import httpAPI from '@/api/utils/httpClientAPI'

const URL_BASE = 'patients/validate/vat-number'

class VatNumberRepository {
    validateVatNumber(vatNumber: string): Promise<void> {
        return httpAPI.post(URL_BASE, { vatNumber })
    }
}

export default new VatNumberRepository()
