// @ts-expect-error: added .ts extension to allow import in angular
// eslint-disable-next-line import/extensions
import { CountryCodes } from '@/core/domain/enums/country.code.enum.ts'
import { apiMkplUrl } from '@/utils/url'

export default {
    [CountryCodes.PL]: `${apiMkplUrl}/platnosci/onboarding`,
    [CountryCodes.IT]: `${apiMkplUrl}/pagamenti/onboarding`,
    [CountryCodes.ES]: `${apiMkplUrl}/pagos/onboarding`,
    [CountryCodes.MX]: `${apiMkplUrl}/pagos/onboarding`,
    [CountryCodes.BR]: `${apiMkplUrl}/pagamentos/onboarding`,
    [CountryCodes.DE]: `${apiMkplUrl}/payments/onboarding`
} as Record<CountryCodes, string>
