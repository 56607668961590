import AppointmentService from '@/features/reports/domain/appointment.service'
import ReportsService from '@/features/reports/domain/reports.service'

import {
    ACTION_CHANGE_COLUMN_VISIBILITY,
    ACTION_MOVE_COLUMN,
    ACTION_REPORTS_RESET_MODULE,
    ACTION_REPORTS_UPDATE_AMOUNT_PAID,
    ACTION_REPORTS_UPDATE_BOOKING_STATUS,
    ACTION_REPORTS_UPDATE_EVENT_SERVICES,
    ACTION_REPORTS_UPDATE_INSURANCE,
    ACTION_REPORTS_UPDATE_PAYMENT_METHOD,
    ACTION_REPORTS_UPDATE_PAYMENT_STATUS,
    ACTION_REPORTS_UPDATE_PRICE,
    ACTION_REPORTS_UPDATE_SERVICE
} from './types/actions'
import { MUTATION_RESET_STATE, MUTATION_SET_HEADERS, MUTATION_UPDATE_PAGE_ITEM } from './types/mutations'

const actions = {
    [ACTION_REPORTS_RESET_MODULE]({ commit }) {
        commit(MUTATION_RESET_STATE)
    },
    async [ACTION_REPORTS_UPDATE_PAYMENT_METHOD]({ commit }, { cashReportId, paymentMethodId, categoryId }) {
        const updatedRow = await ReportsService.updatePaymentMethod(cashReportId, paymentMethodId, categoryId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_PAYMENT_STATUS]({ commit }, { cashReportId, paymentStatusId, categoryId }) {
        const updatedRow = await ReportsService.updatePaymentStatus(cashReportId, paymentStatusId, categoryId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_BOOKING_STATUS]({ commit }, { appointmentId, bookingStatusId }) {
        const updatedRow = await AppointmentService.updateBookingStatus(appointmentId, bookingStatusId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_SERVICE]({ commit }, { appointmentId, serviceId }) {
        const updatedRow = await AppointmentService.updateService(appointmentId, serviceId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)

        return updatedRow
    },
    async [ACTION_REPORTS_UPDATE_PRICE]({ commit }, { appointmentId, price }) {
        const updatedRow = await AppointmentService.updatePrice(appointmentId, price)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_INSURANCE]({ commit }, { appointmentId, insuranceId }) {
        const updatedRow = await AppointmentService.updateInsurance(appointmentId, insuranceId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_AMOUNT_PAID]({ commit }, { cashReportId, amountPaid, categoryId }) {
        const updatedRow = await ReportsService.updateAmountPaid(cashReportId, amountPaid, categoryId)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)
    },
    async [ACTION_REPORTS_UPDATE_EVENT_SERVICES]({ commit }, { appointmentId, serviceIds }) {
        const updatedRow = await AppointmentService.updateEventServices(appointmentId, serviceIds)
        commit(MUTATION_UPDATE_PAGE_ITEM, updatedRow)

        return updatedRow
    },
    [ACTION_CHANGE_COLUMN_VISIBILITY]({ commit, state }, { tag, hidden }) {
        const headers = ReportsService.changeColumnVisibility([...state.headers], tag, hidden)
        commit(MUTATION_SET_HEADERS, headers)
    },
    [ACTION_MOVE_COLUMN]({ commit, state }, { tag, direction }) {
        const headers = ReportsService.moveColumn(
            state.headers.map(column => ({ ...column })),
            tag,
            direction
        )
        commit(MUTATION_SET_HEADERS, headers)
    }
}

export default actions
