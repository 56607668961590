import uninav from '@/features/navigation/ui/router/uninav.middleware'
import {
    VIDEO_TUTORIALS_ROUTE_NAME,
    VIDEO_TUTORIALS_ROUTE_NAME_HOME,
    VIDEO_TUTORIALS_ROUTE_NAME_VIDEO
} from '@/features/videoTutorials/ui/constants/videoTutorialsRouter.constants'

const VideoTutorialsIndex = () =>
    import(/* webpackChunkName: "video-tutorials-index" */ '@/features/videoTutorials/ui/views/VideoTutorialsIndex.vue')
const VideoTutorialsHome = () =>
    import(/* webpackChunkName: "video-tutorials-home" */ '@/features/videoTutorials/ui/views/VideoTutorialsHome.vue')
const VideoTutorialsVideo = () =>
    import(/* webpackChunkName: "video-tutorials-video" */ '@/features/videoTutorials/ui/views/VideoTutorialsVideo.vue')

export default {
    path: `/${VIDEO_TUTORIALS_ROUTE_NAME}`,
    component: VideoTutorialsIndex,
    meta: {
        title: 'onboarding-cs-video-tutorial-title',
        middleware: [uninav],
        uninavName: 'onboarding-cs-video-tutorial-title'
    },
    children: [
        {
            path: '',
            name: VIDEO_TUTORIALS_ROUTE_NAME_HOME,
            component: VideoTutorialsHome
        },
        {
            name: VIDEO_TUTORIALS_ROUTE_NAME_VIDEO,
            path: ':name',
            component: VideoTutorialsVideo
        }
    ]
}
